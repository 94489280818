import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import NoDocumentsPdf from '../../../assests/pdf/NoDocument.pdf'; // Adjust the path accordingly
import Constants from '../../../assests/Constants/constants';
import { beautifyNumber } from '../../../Utils/FormatNumber';
// Styled DataGrid component
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    width: '100%',
    '& .MuiAutocomplete-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center"

    },
    '& *': {
        boxSizing: 'border-box !important',

    },
    '& .MuiDataGrid-columnHeaders': {
        // opacity: 0.7,
        backgroundColor: '#BFC8FF',
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#BFC8FF',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        color: '#304FFF',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    '& .MuiDataGrid-cell': {
        borderTop: '1px solid rgba(191, 191, 193, 0.56)',
        backgroundColor: 'rgba(234, 237, 255, 0.32)',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: 'rgba(191, 200, 255, 0.3)',
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-cell': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center"
    },
    '& .disableUser': {
        pointerEvents: 'none',
        opacity: 0.5,
    },
    // New styles for edit mode
    '& .MuiDataGrid-cell--editing': {
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '8px',
        paddingRight: '8px',
    },
    '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
        borderRight: 'none',
    },
    '& .MuiDataGrid-row--editing .MuiDataGrid-cell:not(:last-child)': {
        borderRight: '8px solid transparent',
    },
    '& .MuiDataGrid-cell--editing': {
        padding: 0,
        backgroundColor: 'inherit',
    },
}));
const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};
const TransactionDocumentDataGridComponent = (props) => {
    const { i18n, t } = useTranslation();
    const {
        data,
        openPdfInNewTab,
        direction,
    } = props;
    const [rows, setRows] = useState([]);

    // Initialize rows with data prop
    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data, i18n.language]);

    // Define columns
    const columns = [
        // Document Column
        {
            field: 'document',
            headerName: t('Document'),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <div
                    className="iframe-container"
                    onClick={() => openPdfInNewTab(params.row.documentUrl)}
                >
                    <iframe
                        className="zoom-iframe"
                        src={
                            params.row.documentUrl
                                ? `${params.row.documentUrl}#page=1`
                                : `${NoDocumentsPdf}#page=1`
                        }
                        width="100px"
                        height="50px"
                        title={params.row.title}
                        style={{ pointerEvents: 'none' }}
                    />
                </div>
            ),
        },
        {
            field: 'transactionDocumentType',
            headerName: t('DocumentType'),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            type: 'string',
            valueFormatter: (params) => {
                return (
                    Constants.TransactionDocuments[params]
                )

            }
        },
        // Client Name Column
        {
            field: 'accountCodeName',
            headerName: t('Client Name'),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            type: 'string',
        },
        // Document Date Column
        {
            field: 'documentDate',
            headerName: t('Document Date'),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            type: 'date',
            valueFormatter: (params) =>
                params ? formatDate(params) : '',
        },

        // CompanyId Column
        {
            field: 'accountCaseId',
            headerName: t('CompanyId'),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            type: 'string',
        },
        // Reference Column
        {
            field: 'reference',
            headerName: t('Reference'),
            headerAlign: 'center',
            align: 'center',
            width: 100,
            type: 'number',
        },
        // Description Column
        {
            field: 'description',
            headerName: t('Description'),
            headerAlign: 'center',
            align: 'center',
            width: 200,
            type: 'string',
        },
        // Amount Column
        {
            field: 'amount',
            headerName: t('Amount'),
            headerAlign: 'center',
            align: 'center',
            width: 100,
            type: 'number',
            valueFormatter: (params) =>
                beautifyNumber(params),
        },
        // Document Type Column

    ];

    return (
        <div className={`${direction}`} style={{ width: '100%', height: '60vh' }}>
            <StyledDataGrid
                rows={rows}
                columns={columns}
                disableSelectionOnClick
                getRowId={(row) => row.id}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                pagination
                sortingOrder={['asc', 'desc']}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'documentDate', sort: 'asc' }],
                    },
                }}
            />
        </div>
    );
};

export default TransactionDocumentDataGridComponent;
