import React, { useState, useEffect, useRef } from 'react';
import {
    TextField,
    FormControl,
    Select,
    MenuItem,
    Tooltip,
    IconButton,
    Autocomplete,
    Button,
    Popper,
} from '@mui/material';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';
import { toast } from 'react-toastify';
import SaveIcon from '@mui/icons-material/SaveAsRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/system';
import AssetService from '../../../Services/assets.service';

const StyledContainer = styled('div')({
    position: 'absolute',
    top: '10%',
    left: '20%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '16px',
    minHeight: '60vh',
    maxHeight: '85vh',
    overflowY: 'auto',
    minWidth: '700px',
    zIndex: 1300,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
    border: '1px solid #304fff',
});

const StyledSelect = styled(Select)({
    height: '48px',
    width: '100%',
    borderRadius: '8px',
    background: '#F5F5F6',
    border: '1px solid transparent',
    '&:focus-within': {
        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
    },
    '& .MuiSelect-select': {
        padding: '10px 12px',
        textAlign: 'left',
    }
});

const StyledListItem = styled('li')({
    '&:hover': {
        backgroundColor: '#F5F5F6', // Hover color
        cursor: 'pointer', // Optional: Changes the cursor to pointer
    },
});

const StyledFormRow = styled('div')({
    display: 'flex',
    flexDirection: 'row-reveresed',
    justifyContent: 'space-between',
    gap: '20px',
    marginBottom: '16px',
});

const EditAsset = ({ caseId, token, asset, assetGroups, open, onClose, onSave, onDelete, viewDocument }) => {
    const { t, i18n } = useTranslation();
    const nodeRef = useRef(null);
    const [editedAsset, setEditedAsset] = useState({ ...asset });
    const [direction, setDirection] = useState('rtl');
    const [searchTerm, setSearchTerm] = useState('');
    const [isSaveDisabled, setIsSaveDisabled] = useState(true); // State to manage save button disabled state



    useEffect(() => {

        if (asset) {
            setEditedAsset({ ...asset });
        }
    }, [asset]);

    useEffect(() => {
        // Update direction based on the current language
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);

    // Validation function to check if required fields are filled
    const validateFields = () => {
        const { name, originalPrice, depreciationPercentage, purchaseDate } = editedAsset;

        return (
            name?.trim() !== '' &&
            !isNaN(originalPrice) && originalPrice !== null && originalPrice !== undefined &&
            !isNaN(depreciationPercentage) && depreciationPercentage !== null && depreciationPercentage !== undefined &&
            purchaseDate !== null
        );
    };

    // Update validation state whenever editedAsset changes
    useEffect(() => {
        setIsSaveDisabled(!validateFields());
    }, [editedAsset]);

    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        },
    };

    const StyledPopper = styled(Popper)(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 'var(--Spacing-md, 8px)',
            background: 'var(--white, #FFF)',
            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.32)',
        },
        '& .MuiAutocomplete-listbox': {
            padding: theme.spacing(1),
        },
        '& .MuiAutocomplete-option': {
            borderRadius: 'var(--Spacing-sm, 4px)',
            '&[aria-selected="true"], &.Mui-focused, &:hover': {
                background: 'var(--orange, #F9AA2A) !important',
            },
        },
    }));

    const inputStyle = {
        style: {
            textAlign: direction === 'rtl' ? 'right' : 'left', // Align based on direction
            direction: direction === 'rtl' ? 'rtl' : 'ltr', // Set direction explicitly
        },
    };

    const handleChange = (field, value) => {
        if (field === 'assetGroup') {
            const assetGId = value?.assetGroupId;
            setEditedAsset(prev => ({ ...prev, ['depreciationPercentage']: value.depreciationPercentage }));
            setEditedAsset(prev => ({ ...prev, ['assetGroupId']: assetGId }));
        } else {
            setEditedAsset(prev => ({ ...prev, [field]: value }));
        }
    };

    const handleSave = async () => {
        if (!validateFields()) {
            toast.error(t('Please fill all required fields'));
            return;
        }

        try {
            const updatedAsset = {
                Id: editedAsset.id,
                CaseId: caseId,
                AssetNumber: editedAsset.assetNumber,
                Name: editedAsset.name,
                Description: editedAsset.description || "",
                PurchaseDate: editedAsset.purchaseDate,
                SellDate: editedAsset.sellDate || null,
                OriginalPrice: parseFloat(editedAsset.originalPrice),
                DepreciationPercentage: parseFloat(editedAsset.depreciationPercentage),
                AssetGroupId: editedAsset.assetGroupId || null,
                ExpenseId: editedAsset.expenseId || null,
                JournalEntryId: editedAsset.journalEntryId || null,
            };

            const response = await AssetService.UpdateAsset(editedAsset, token);
            if (response.success) {
                toast.success(t('Asset updated successfully'));
                onSave(editedAsset);
            } else {
                toast.error(t('Failed to update asset'));
            }
        } catch (error) {
            console.error(error);
            toast.error(t('An error occurred while updating asset'));
        }
    };

    if (!open) return null;

    const titleStyle = {
        textAlign: 'right', // Ensures the title is aligned to the right
        width: '100%', // Makes sure it covers the full width
    };

    return (
        <Draggable handle=".handle" nodeRef={nodeRef}>
            <StyledContainer ref={nodeRef}>
                <div
                    className="handle"
                    style={{
                        cursor: 'move',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        marginBottom: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Tooltip title={t('Exit')}>
                        <IconButton onClick={onClose}>
                            <CancelIcon style={{ color: '#304FFF' }} />
                        </IconButton>
                    </Tooltip>

                    <div className="edit-title">{t('EditAsset')}</div>

                    <div>
                        {/* <Tooltip title={t('Save')}>
                            <IconButton onClick={handleSave}>
                                <SaveIcon style={{ color: '#304FFF' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('Delete')}>
                            <IconButton onClick={() => onDelete(editedAsset.id)}>
                                <DeleteIcon style={{ color: '#E57C22' }} />
                            </IconButton>
                        </Tooltip> */}
                    </div>
                </div>

                {/* Row 1 */}
                <div className="form-row-2">
                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            <span className="required-asterisk">*</span>
                            {t('AssetName')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('AssetName')}
                            value={editedAsset.name || ''}
                            onChange={(e) => handleChange('name', e.target.value)}
                            variant="standard"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                        />
                    </div>

                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            <span className="required-asterisk">*</span>
                            {t('OriginalPrice')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('OriginalPrice')}
                            type="number"
                            value={editedAsset.originalPrice || ''}
                            onChange={(e) => handleChange('originalPrice', e.target.value)}
                            variant="standard"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                        />
                    </div>
                </div>

                {/* Row 2 */}
                <div className="form-row-2">
                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            <span className="required-asterisk">*</span>
                            {t('DepreciationPercentage')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('DepreciationPercentage')}
                            type="number"
                            value={editedAsset.depreciationPercentage || ''}
                            onChange={(e) => handleChange('depreciationPercentage', e.target.value)}
                            variant="standard"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                        />
                    </div>

                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            <span className="required-asterisk">*</span>
                            {t('PurchaseDate')}
                        </div>
                        <CustomDatePicker
                            date={editedAsset.purchaseDate ? new Date(editedAsset.purchaseDate) : null}
                            onDateChange={(date) => handleChange('purchaseDate', date.toISOString())}
                            height="48px"
                        />
                    </div>
                </div>

                {/* Row 3 */}
                <div className="form-row-2">
                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            {t('Description')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('Description')}
                            value={editedAsset.description || ''}
                            onChange={(e) => handleChange('description', e.target.value)}
                            variant="standard"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                        />
                    </div>

                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            {t('SellDate')}
                        </div>
                        <CustomDatePicker
                            date={editedAsset.sellDate ? new Date(editedAsset.sellDate) : null}
                            onDateChange={(date) => handleChange('sellDate', date.toISOString())}
                            height="48px"
                        />
                    </div>
                </div>

                {/* Row 4 */}
                <div className="form-row-2">
                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            {t('AssetGroup')}
                        </div>
                        <Autocomplete
                            options={assetGroups}
                            getOptionLabel={(option) => `${option.assetGroupId} - ${option.name}`}
                            value={assetGroups.find((option) => option.assetGroupId === editedAsset.assetGroupId) || null}
                            onChange={(event, newValue) => {
                                handleChange('assetGroup', newValue ? newValue : '');
                            }}
                            onInputChange={(event, inputValue) => {
                                setSearchTerm(inputValue);
                            }}
                            noOptionsText={
                                <strong>{t('NoOptions')}</strong>
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={t('Select Asset Group')}
                                    variant="standard"
                                    InputProps={{
                                        ...params.InputProps,
                                        ...inputPropsStyle,
                                    }}
                                />
                            )}
                            renderOption={(props, option) => (
                                <Tooltip
                                    title={t('RightClickToEdit')}
                                    placement="top"
                                >
                                    <StyledListItem {...props}>
                                        {`${option.assetGroupId} - ${option.name}`}
                                    </StyledListItem>
                                </Tooltip>
                            )}
                            PopperComponent={(props) => (
                                <StyledPopper
                                    {...props}
                                    style={{
                                        ...props.style,
                                        direction: direction,
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            <span className="required-asterisk">*</span>
                            {t('ActivationDate')}
                        </div>
                        <CustomDatePicker
                            date={editedAsset.activationDate ? new Date(editedAsset.activationDate) : null}
                            onDateChange={(date) => handleChange('activationDate', date.toISOString())}
                            height="48px"
                        />
                    </div>

                </div>

                {/* Row 5 */}
                <div className="form-row-2">
                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            {t('Document')}
                        </div>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                height: '35px',
                                width: '90%',
                                background: '#304FFF',
                                color: '#FFF',
                                borderRadius: '8px',
                                textTransform: 'none',
                            }}
                            onClick={() => viewDocument(editedAsset)}
                        >
                            {t('ViewDocument')}
                        </Button>
                    </div>
                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            {t('SerialNumber')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('SerialNumber')}
                            value={editedAsset.assetNumber || ''}
                            onChange={(e) => handleChange('assetNumber', e.target.value)}
                            variant="standard"
                            type="number"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                        />
                    </div>

                </div>

                {/* Actions */}
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                    <Tooltip title={t('Save')}>
                        <IconButton
                            onClick={handleSave}
                            disabled={isSaveDisabled}
                            style={{
                                opacity: isSaveDisabled ? 0.5 : 1,
                                pointerEvents: isSaveDisabled ? 'none' : 'auto',
                                cursor: isSaveDisabled ? 'not-allowed' : 'pointer',
                            }}
                        >
                            <SaveIcon style={{ color: '#304FFF' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Delete')}>
                        <IconButton onClick={() => onDelete(editedAsset.id)}>
                            <DeleteIcon style={{ color: 'red' }} />
                        </IconButton>
                    </Tooltip>
                </div>
            </StyledContainer>
        </Draggable>
    );
};

export default EditAsset;