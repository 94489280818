import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Modal, Box, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReportsService from '../../../Services/reports.service';
import CustomYearDatePicker from '../CustomDates/CustomYearDatePicker/CustomYearDatePicker';

function Download856Modal({ client, token, open, onClose }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [yearDate, setYearDate] = useState(dayjs()); // default to now

    const handleDownload = async () => {
        if (!client?.caseId) {
            console.warn('No client or CaseId found');
            return;
        }
        setLoading(true);

        try {
            // Convert the selected year to an integer or keep as string
            const selectedYear = yearDate;

            // Build the request body
            const requestBody = {
                caseId: client.caseId,
                // If your backend expects StartDate or something, adjust accordingly:
                // e.g. "StartDate": `01/01/${selectedYear}`,
                // or you can pass "TaxYear" if your endpoint is set up that way:
                startDate: selectedYear,
                accountantId: client.accountantId
            };

            // 1) Download the zip blob
            const zipBlob = await ReportsService.download856Combined(requestBody, token);

            // 2) Create a blob URL & force the browser to download
            const blobUrl = window.URL.createObjectURL(zipBlob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `856_Combined_${selectedYear}.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error(error);
            // If you use a toast or something:
            // toast.error(t('reportDownloadFailure'));
            alert('Error downloading the 856');
        }
        setLoading(false);
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="download-856-modal" aria-describedby="download-856-modal-desc">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    minWidth: 320,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent:'center',
                    alignItems: 'center',    
                    gap: 2
                }}
            >
                <h2 id="download-856-modal">{t('Download856Files')}</h2>
                <p id="download-856-modal-desc">
                    {t('SelectTheYearFor856Files')}
                </p>

                {/* YEAR PICKER */}
                <CustomYearDatePicker
                    date={yearDate}          // pass a plain JS date
                    onDateChange={(dateValue) => setYearDate(dayjs(dateValue))}
                    label={t('SelectYear')}
                    height="48px"
                />


                {/* Loading spinner */}
                {loading && <CircularProgress />}

                {/* ACTIONS */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant="contained" onClick={handleDownload}>
                        {t('DownloadPDFAndTXT')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default Download856Modal;
