import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { isOlderThanSixMonths, isValidIDOrCompanyID, isValidReference } from './validationHelpers';
import Constants from '../assests/Constants/constants';
import '../App.css'
/**
 * Validates the document before processing or updating
 * @param {Object} document - The document object (editedItem)
 * @param {Function} t - Translation function
 * @param {Object} client - The client object (to check account management type)
 * @returns {boolean} - Returns `true` if validation passes, otherwise `false`
 */
export async function validateDocumentBeforeProcessing(document, t, client) {

    
    // ✅ 1) Check if the document date is older than 6 months
    if (isOlderThanSixMonths(document.reportingMonthDate,document.documentDate,client.reportingType)) {
        const { isConfirmed } = await Swal.fire({
            title: t('Warning'),
            text: t('DateOlderThanSixMonths'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('YesProceed'),
            cancelButtonText: t('NoCancel'),
            reverseButtons: true,
            heightAuto: false,
            customClass: {
                container: 'swal-high-zindex'
            }
        });

        if (!isConfirmed) return false;
    }

    // ✅ 2) Validate Company ID / Israeli ID
    if (!isValidIDOrCompanyID(document.accountCaseId)) {
        toast.error(t('InvalidCompanyOrIDNumber'));
        return false;
    }

    // ✅ 3) Ensure Reference is provided
    if (!isValidReference(document.reference)) {
        toast.error(t('ReferenceIsRequired'));
        return false;
    }
    
    // ✅ 4) Ensure required fields are filled based on account management type
    if (
        client.accountManagement?.trim() === Constants.AccountManagementOptions[0].name
        && (!document.sortCodeId || !document.creditAccount || !document.debitAccount)
    ) {
        toast.info(t('DocumentMustHaveSortCodeCreditDebitAccountToMarkAsProcessed'));
        return false;
    }
    else if (!document.sortCodeId) {
        toast.info(t('DocumentMustHaveSortCodeToMarkAsProcessed'));
        return false;
    }

    // ✅ Validation passed
    return true;
}


/**
 * Validates that each Payment Acceptance item has the required fields.
 * @param {Object} document - The Payment Acceptance document object
 * @param {Function} t - Translation function
 * @returns {boolean} - Returns `true` if validation passes, otherwise `false`
 */
export async function validatePaymentAcceptanceItems(document, t, client) {
    
    // 1. Check the top-level date (if your use-case requires it)
    if (isOlderThanSixMonths(document.reportingMonthDate,document.documentDate,client.reportingType)) {
        const { isConfirmed } = await Swal.fire({
            title: t('Warning'),
            text: t('DateOlderThanSixMonths'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('YesProceed'),
            cancelButtonText: t('NoCancel'),
            reverseButtons: true,
            heightAuto: false,
            customClass: {
                container: 'swal-high-zindex'
            }
        });

        if (!isConfirmed) return false;
    }

    // 2. Validate each checks item
    for (const checkItem of document.checks || []) {
        if (!checkItem.debitAccount || !checkItem.creditAccount) {
            toast.error(t('CheckItemMissingAccount'));
            return false;
        }
        if (!checkItem.paymentDate) {
            toast.error(t('CheckItemMissingPaymentDate'));
            return false;
        }
    }

    // 3. Validate each creditCards item
    for (const card of document.creditCards || []) {
        if (!card.debitAccount || !card.creditAccount) {
            toast.error(t('CreditCardItemMissingAccount'));
            return false;
        }
        if (!card.paymentDate) {
            toast.error(t('CreditCardItemMissingPaymentDate'));
            return false;
        }
    }

    // 4. Validate each cash item
    for (const cashItem of document.cash || []) {
        if (!cashItem.debitAccount || !cashItem.creditAccount) {
            toast.error(t('CashItemMissingAccount'));
            return false;
        }
        if (!cashItem.paymentDate) {
            toast.error(t('CashItemMissingPaymentDate'));
            return false;
        }
    }

    // 5. Validate each bankTransactions item
    for (const bankTrans of document.bankTransactions || []) {
        if (!bankTrans.debitAccount || !bankTrans.creditAccount) {
            toast.error(t('BankTransactionItemMissingAccount'));
            return false;
        }
        if (!bankTrans.paymentDate) {
            toast.error(t('BankTransactionItemMissingPaymentDate'));
            return false;
        }
    }
    
    // If all checks passed, return true
    return true;
}