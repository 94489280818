import React, { useEffect, useState, useContext } from 'react';
import { Modal, Button, Typography, Box, CircularProgress, Link } from '@mui/material';
import PolicyService from '../../../Services/policy.service';
import { useTranslation } from 'react-i18next';
const PrivacyPolicyModal = ({ visible, onAccept }) => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const [loading, setLoading] = useState(true);
    const [policyContent, setPolicyContent] = useState('');
    const [policyLink, setPolicyLink] = useState('');
    const language = i18n.language;

    useEffect(() => {
        const fetchPolicy = async () => {
            try {
                
                const response = await PolicyService.getPolicy();
                if (response.success) {
                    const { contentEn, contentHe, contentAr, link } = response.data;
                    const contentByLanguage = { en: contentEn, he: contentHe, ar: contentAr };
                    setPolicyContent(contentByLanguage[language] || contentEn);
                    setPolicyLink(link);
                }
            } catch (error) {
                console.error('Error fetching privacy policy:', error);
            } finally {
                setLoading(false);
            }
        };

        if (visible) {
            fetchPolicy();
        }
    }, [visible, language]);

    const acceptPolicy = async () => {
        setLoading(true);
        try {
            await PolicyService.acceptPolicy();
            onAccept();
        } catch (error) {
            console.error('Error accepting privacy policy:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal open={visible} aria-labelledby="privacy-policy-modal">
            <Box
                className={`${direction}`}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography variant="h6" gutterBottom>
                    {t('privacyPolicy.title')}
                </Typography>

                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            {policyContent || t('privacyPolicy.loading')}
                        </Typography>
                        {policyLink && (
                            <Link href={policyLink} target="_blank" rel="noopener noreferrer">
                                {t('privacyPolicy.readFull')}
                            </Link>
                        )}
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={acceptPolicy}
                            disabled={loading}
                            sx={{ mt: 2 , boxSizing: 'border-box !important' }}
                        >
                            {loading ? t('privacyPolicy.processing') : t('privacyPolicy.accept')}
                        </Button>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default PrivacyPolicyModal;
