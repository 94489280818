import React, { useState, useEffect, useMemo } from 'react';
import { TextField, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Constants from '../../../assests/Constants/constants';
import { toast } from 'react-toastify';
const AccountantDetailsModals = ({
    isPopupOpen,
    value,
    handleValueChange,
    isEditMode,
    handleSaveChange: parentHandleSaveChange,
    handleClose,
    contentKey,
    //custome data option
    formattedSortCodesGroupNamesOptions,
    formattedSortCodeVatType,
    formattedPalCodeCategoryOptions,
    formattedPalCodeAccountingGroupOptions,


    existingOtherDocumentsType = [],
    existingSortCodes = [],
    existingSortCodeGroups = [],
    existingPalCodes = [],
    existingPalCodeCategories = [],
    existingAccountingGroups = [],
}) => {
    const { i18n, t } = useTranslation();
    const [direction, setDirection] = useState('rtl'); // Default to 'rtl'

    
    useEffect(() => {
        // Update direction based on the current language
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);

    // -----------------------------
    // Helper to check required fields
    // -----------------------------
    const isSaveDisabled = useMemo(() => {
        const val = value || {};

        switch (contentKey) {
            case 'OtherDocumentsType':
                // Document Type is required => we check `value`
                return !val; // or (!val.trim()) if it's always a string

            case 'SortingCode':
                // Required fields: sortCodeId, sortCodeName, sortCodeVatType
                return (
                    !val.sortCodeId ||
                    !val.sortCodeName ||
                    !val.sortCodeVatType
                );

            case 'SortingCodeGroup':
                // Required field: sortCodeGroupName
                return (
                    !val.sortCodeGroupListNumber ||
                    !val.sortCodeGroupName
                );

            case 'PalCode':
                return (
                    !val.palCodeType ||
                    !val.palCodeId ||
                    !val.palCodeCategory
                );

            case 'PalCodeCategory':
                return (
                    !val.palCodeCategoryId ||
                    !val.orderNumber ||
                    !val.accountingGroup
                );
            case 'AccountingGroup':
                return (
                    !val.accountingGroupClassifaction ||
                    !val.accountingGroupName ||
                    !val.orderNumber
                );

            default:
                // If we don't recognize the contentKey, do not disable
                return false;
        }
    }, [contentKey, value]);


    /**
  * Auto-increment logic:
  * When the modal opens in "New" mode, find the last ID/number/serial, increment it by 1, 
  * and put it in the form. 
  * The user can still override it manually afterward.
  */

    useEffect(() => {
        if (!isEditMode && isPopupOpen) {
            switch (contentKey) {

                case 'SortingCode': {
                    const lastCodeId = existingSortCodes.length
                        ? Math.max(...existingSortCodes.map((item) => Number(item.sortCodeId) || 0))
                        : 0;
                    handleValueChange({
                        ...value,
                        sortCodeId: String(lastCodeId + 1),
                    });
                    break;
                }
                case 'SortingCodeGroup': {
                    const numericListNumbers = existingSortCodeGroups.map((item) => parseInt(item.sortCodeListNumber, 10) || 0);
                    const maxNum = numericListNumbers.length ? Math.max(...numericListNumbers) : 0;
                    handleValueChange({
                        ...value,
                        sortCodeGroupListNumber: (maxNum + 1).toString()
                    });
                    break;
                }
                case 'PalCodeCategory': {

                    // Same for orderNumber if you want to auto-increment:
                    const lastOrderNumber = existingPalCodeCategories.length
                        ? Math.max(...existingPalCodeCategories.map((item) => Number(item.orderNumber) || 0))
                        : 0;
                    handleValueChange((prev) => ({
                        ...prev,
                        orderNumber: lastOrderNumber + 1,
                    }));
                    break;
                }
                case 'AccountingGroup': {
                    // for "orderNumber"
                    const lastOrder = existingAccountingGroups.length
                        ? Math.max(...existingAccountingGroups.map((item) => Number(item.orderNumber) || 0))
                        : 0;
                    handleValueChange({
                        ...value,
                        orderNumber: lastOrder + 1,
                    });
                    break;
                }
                default:
                    break;
            }
        }
    }, [isEditMode, isPopupOpen, contentKey]);

    /**
     * Validation logic: 
     *  - Check if the numeric/ID you care about already exists in the relevant array.
     *  - If it does, show an error and prevent saving.
     */
    const checkForDuplicates = () => {
        switch (contentKey) {
            case 'OtherDocumentsType': {
                // Example check: does the same "Document Type" text exist?
                // Adjust to match how your "OtherDocumentsType" is stored 
                const isDuplicate = existingOtherDocumentsType.some(
                    (item) => item.name?.trim() === value?.trim(),
                );
                if (isDuplicate) {
                    toast.info(t('DocumentTypeAlreadyExists'));
                    return true;
                }
                break;
            }
            case 'SortingCode': {
                // Check `sortCodeId` and `sortCodeName` uniqueness
                const isDuplicate = existingSortCodes.some((item) => {

                    // Skip the current item if in edit mode and ID matches
                    if (isEditMode && String(item.id) === String(value.id)) return false;

                    const itemSortCodeId = item.sortCodeId ? String(item.sortCodeId).trim() : "";
                    const valueSortCodeId = value.sortCodeId ? String(value.sortCodeId).trim() : "";
                    const originalSortCodeId = value.originalSortCodeId ? String(value.originalSortCodeId).trim() : "";

                    const itemSortCodeName = item.sortCodeName ? item.sortCodeName.trim().toLowerCase() : "";
                    const valueSortCodeName = value.sortCodeName ? value.sortCodeName.trim().toLowerCase() : "";

                    // Check if `sortCodeId` is changed and exists in another item
                    if (valueSortCodeId !== originalSortCodeId) {
                        if (existingSortCodes.some((existing) => String(existing.sortCodeId).trim() === valueSortCodeId)) {
                            toast.info(t('SortCodeIdAlreadyExists'));
                            return true;
                        }
                    }

                    // If `sortCodeId` is unchanged, check if `sortCodeName` is a duplicate
                    if (existingSortCodes.some((existing) => String(existing.sortCodeName).trim().toLowerCase() === valueSortCodeName)) {
                        toast.info(t('SortCodeNameAlreadyExists'));
                        return true;
                    }

                    return false;
                });

                if (isDuplicate) {
                    return true;
                }
                break;
            }
            case 'SortingCodeGroup': {
                // Check `sortCodeGroupListNumber` and `sortCodeGroupName` uniqueness
                const isDuplicate = existingSortCodeGroups.some((item) => {

                    if (isEditMode) return false;


                    return (
                        Number(item.sortCodeGroupListNumber) === Number(value.sortCodeGroupListNumber) ||
                        item.sortCodeGroup.trim().toLowerCase() === value.sortCodeGroupName.trim().toLowerCase()
                    );
                });

                if (isDuplicate) {
                    toast.info(t('GroupListNumberAlreadyExists'));
                    return true;
                }
                break;
            }

            case 'PalCode': {
                // Check `palCodeId`
                const isDuplicate = existingPalCodes.some((item) => {

                    if (isEditMode) return false;
                    return String(item.palCodeId).trim() === String(value.palCodeId).trim();
                });
                if (isDuplicate) {
                    toast.info(t('BalanceClassificationNameAlreadyExists'));
                    return true;
                }
                break;
            }
            case 'PalCodeCategory': {
                // Check `palCodeCategoryId` or also `orderNumber` if you want
                const catDuplicate = existingPalCodeCategories.some((item) => {
                    if (isEditMode) return false;
                    return String(item.palCodeCategoryId).trim() === String(value.palCodeCategoryId).trim();
                });
                if (catDuplicate) {
                    toast.info(t('BalanceClassificationCategoryAlreadyExists'));
                    return true;
                }
                // Also check orderNumber if you want it unique
                const orderDuplicate = existingPalCodeCategories.some((item) => {
                    if (isEditMode) return false;
                    return Number(item.orderNumber) === Number(value.orderNumber);
                });
                if (orderDuplicate) {
                    toast.info(t('SerialOrderNumberAlreadyExists'));
                    return true;
                }
                break;
            }
            case 'AccountingGroup': {
                // Typically might check `orderNumber` uniqueness
                const isDuplicate = existingAccountingGroups.some((item) => {
                    if (isEditMode) return false;
                    return Number(item.orderNumber) === Number(value.orderNumber);
                });
                if (isDuplicate) {
                    toast.info(t('SerialNumberExistsInAccountingGroups'));
                    return true;
                }
                break;
            }
            default:
                break;
        }
        // No duplicates found
        return false;
    };


    /**
   * Wrap your parent handleSaveChange in a local check:
   */
    const handleSaveChange = () => {
        const hasDuplicates = checkForDuplicates();
        if (hasDuplicates) {
            // Stop here if there is a duplicate
            return;
        }
        // Otherwise, proceed with your parent's save logic
        parentHandleSaveChange();
    };

    const customStyles = {
        container: provided => ({
            ...provided,
            width: '100% !important'
        }),
        control: (provided) => ({
            ...provided,
            minHeight: '48px',
            width: '100% !important',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: 'none',
            boxShadow: 'none',
            padding: '0 10px',
            textAlign: 'left',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            textAlign: 'left',
            color: 'black'
        }),
        singleValue: (provided) => ({
            ...provided,
            textAlign: 'left',
            color: 'black',
        }),
        menu: (provided) => ({
            ...provided,
            textAlign: 'left',
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? '#F9AA2A'
                : state.isFocused
                    ? '#ebebeb'
                    : null,
            color: 'black',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: state.isFocused ? 'darkgray' : 'black',
            '&:hover': {
                color: 'darkgray',
            },
        }),
    };

    const contentConfig = {
        OtherDocumentsType: {
            headerContent: (
                <h2 className='centered-heading'>
                    {isEditMode ? t("Edit OtherDocumentsType") : t("New OtherDocumentsType")}
                </h2>
            ),
            bodyContent: (
                <div>
                    <div className="form-row-item-title">
                        {t('Document Type')}<span className="required-asterisk">*</span>
                    </div>
                    <TextField
                        fullWidth
                        placeholder={t('Document Type')}
                        value={value}
                        onChange={(e) => handleValueChange(e.target.value)}
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                            sx: {
                                height: '48px',
                                padding: '0 10px',
                                borderRadius: '8px',
                                background: '#F5F5F6',
                                border: '1px solid transparent',
                                '&:focus-within': {
                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                },
                            },
                        }}
                        inputProps={{
                            style: {
                                textAlign: direction === 'ltr' ? 'left' : 'right',
                            },
                        }}
                    />
                </div>
            ),
        },
        SortingCode: {
            headerContent: (
                <h2 className='centered-heading'>
                    {isEditMode ? t("EditSortCode") : t("NewSortCode")}
                </h2>
            ),
            bodyContent: ({ value, handleValueChange, direction }) => (
                <div className={`${direction}`} style={{ width: '100%' }} >
                    {/* SortingCode ID */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('SortCode Id')}<span className="required-asterisk">*</span>
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('SortCode Id')}
                            value={value?.sortCodeId || ''}
                            onChange={(e) => handleValueChange({ ...value, sortCodeId: e.target.value })}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                    {/* SortCode Name */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('SortCode Name')}<span className="required-asterisk">*</span>
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('SortCode Name')}
                            value={value.sortCodeName || ''}
                            onChange={(e) => handleValueChange({ ...value, sortCodeName: e.target.value })}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                    {/* SortCode Group */}
                    <div className="form-row-item profile-full-width" style={{ marginTop: '30px' }}>
                        <div className="form-row-item-title">
                            {t('SelectSortCodeGroupName')}<span className="required-asterisk">*</span>
                        </div>
                        <Select
                            placeholder={t('SelectSortCodeGroupName')}
                            options={formattedSortCodesGroupNamesOptions}
                            isSearchable={true}
                            value={formattedSortCodesGroupNamesOptions.find(item => item.value === value.sortCodeGroup) || null}
                            onChange={(selectedOption) => handleValueChange({ ...value, sortCodeGroup: selectedOption.value })}
                            styles={customStyles}
                        />
                    </div>

                    {/* SortCode VAT Type */}
                    <div className="form-row-item profile-full-width" style={{ marginTop: '30px' }}>
                        <div className="form-row-item-title">
                            {t('SelectSortCodeVatType')}<span className="required-asterisk">*</span>
                        </div>
                        <Select
                            placeholder={t('SelectSortCodeVatType')}
                            options={formattedSortCodeVatType}
                            isSearchable={true}
                            value={formattedSortCodeVatType.find(item => item.value === value.sortCodeVatType) || null}
                            onChange={(selectedOption) => handleValueChange({ ...value, sortCodeVatType: selectedOption.value })}
                            styles={customStyles}
                        />
                    </div>
                    {/* Debit Account */}
                    <div className="form-row-item profile-full-width">
                        <Tooltip title={t('AutoSelectAccountCardWithSortCode')} arrow placement="top">
                            <div className="form-row-item-title">
                                {t('Debit Account')}
                            </div>
                        </Tooltip>

                        <TextField
                            fullWidth
                            placeholder={t('Debit Account')}
                            value={value.sortCodeDebit || ''}
                            onChange={(e) => handleValueChange({ ...value, sortCodeDebit: e.target.value })}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>

                    {/* Credit Account */}
                    <div className="form-row-item profile-full-width">
                        <Tooltip title={t('AutoSelectAccountCardWithSortCode')} arrow placement="top">

                            <div className="form-row-item-title">
                                {t('Credit Account')}
                            </div>
                        </Tooltip>

                        <TextField
                            fullWidth
                            placeholder={t('Credit Account')}
                            value={value.sortCodeCredit || ''}
                            onChange={(e) => handleValueChange({ ...value, sortCodeCredit: e.target.value })}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>




                </div>
            ),
        },
        SortingCodeGroup: {
            headerContent: (
                <h2 className='centered-heading'>
                    {isEditMode ? t("EditSortCodeGroup") : t("AddSortCodeGroupName")}
                </h2>
            ),
            bodyContent: ({ value, handleValueChange, direction }) => (
                <div className={`${direction}`} style={{ width: '100%' }} >
                    {/* SortCode Group Name */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('sortCodeGroupName')}<span className="required-asterisk">*</span>
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('sortCodeGroupName')}
                            value={value.sortCodeGroupName || ''}
                            onChange={(e) => handleValueChange({ ...value, sortCodeGroupName: e.target.value })}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid #304FFF',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                    {/* SortCode Group List Number */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('SortCodeGroupListNumber')}<span className="required-asterisk">*</span>
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('SortCodeGroupListNumber')}
                            value={value.sortCodeGroupListNumber || ''}
                            onChange={(e) => handleValueChange({ ...value, sortCodeGroupListNumber: e.target.value })}
                            variant="standard"
                            type="number"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid #304FFF',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                    {/* Profit Name */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('ProfitName')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('ProfitName')}
                            value={value.profitName || ''}
                            onChange={(e) => handleValueChange({ ...value, profitName: e.target.value })}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid #304FFF',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                </div>
            ),
        },
        PalCode: {
            headerContent: (
                <h2 className='centered-heading'>
                    {isEditMode ? t("EditBalanceClassification") : t("NewBalanceClassification")}
                </h2>
            ),
            bodyContent: ({ value, handleValueChange, direction }) => (
                <div className={`${direction}`} style={{ width: '100%' }} >
                    {/* Balance Classification Type */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('BalanceClassificationType')}<span className="required-asterisk">*</span>
                        </div>
                        <Select
                            placeholder={t('BalanceClassificationType')}
                            options={Constants.PalCodeType.map(type => ({
                                value: type,
                                label: t(type),
                            }))}
                            value={
                                Constants.PalCodeType.map(type => ({
                                    value: type,
                                    label: t(type),
                                })).find(option => option.value === value.palCodeType) || null
                            }
                            onChange={(selectedOption) => handleValueChange({ ...value, palCodeType: selectedOption.value })}
                            styles={customStyles}
                        />
                    </div>
                    {/* Balance Classification Name */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('BalanceClassificationName')}<span className="required-asterisk">*</span>
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('BalanceClassificationName')}
                            value={value.palCodeId || ''}
                            onChange={(e) => handleValueChange({ ...value, palCodeId: e.target.value })}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid #304FFF',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                    {/* Balance Classification Category */}
                    <div className="form-row-item profile-full-width" style={{ marginTop: '30px' }}>
                        <div className="form-row-item-title">
                            {t('SelectBalanceClassificationCategory')}<span className="required-asterisk">*</span>
                        </div>
                        <Select
                            placeholder={t('SelectBalanceClassificationCategory')}
                            options={formattedPalCodeCategoryOptions.map((item) => ({
                                name: item.palCodeCategoryId,
                                label: item.palCodeCategoryId,
                                value: item.palCodeCategoryId,
                            }))}
                            isSearchable={true}
                            value={formattedPalCodeCategoryOptions.map((item) => ({
                                name: item.palCodeCategoryId,
                                label: item.palCodeCategoryId,
                                value: item.palCodeCategoryId,
                            })).find(item => item.value === value.palCodeCategory) || null}
                            onChange={(selectedOption) => handleValueChange({ ...value, palCodeCategory: selectedOption.value })}
                            styles={customStyles}
                        />
                    </div>
                </div>
            ),
        },
        PalCodeCategory: {
            headerContent: (
                <h2 className='centered-heading'>
                    {isEditMode ? t("EditBalanceItem") : t("NewBalanceItem")}
                </h2>
            ),
            bodyContent: ({ value, handleValueChange, direction }) => (
                <div className={`${direction}`} style={{ width: '100%' }} >
                    {/* Balance Classification Category */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('BalanceClassificationCategory')}<span className="required-asterisk">*</span>
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('BalanceClassificationCategory')}
                            value={value.palCodeCategoryId || ''}
                            onChange={(e) => handleValueChange({ ...value, palCodeCategoryId: e.target.value })}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid #304FFF',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                    {/* Serial Number */}
                    <div className="form-row-item profile-full-width">
                        <Tooltip title={t('SerialNumberInTrialBalancePage')} arrow placement="top">

                            <div className="form-row-item-title">
                                {t('SerialNumber')}<span className="required-asterisk">*</span>
                            </div>
                        </Tooltip>

                        <TextField
                            fullWidth
                            placeholder={t('SerialNumber')}
                            value={value.orderNumber || ''}
                            onChange={(e) => handleValueChange({ ...value, orderNumber: e.target.value })}
                            variant="standard"
                            type="number"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid #304FFF',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                    {/* Accounting Group */}
                    <div className="form-row-item profile-full-width" style={{ marginTop: '30px' }}>
                        <div className="form-row-item-title">
                            {t('SelectAccountingGroup')}<span className="required-asterisk">*</span>
                        </div>
                        <Select
                            placeholder={t('SelectAccountingGroup')}
                            options={formattedPalCodeAccountingGroupOptions.map((item) => ({
                                name: item.accountingGroupName,
                                label: item.accountingGroupName,
                                value: item.accountingGroupName,
                            }))}
                            isSearchable={true}
                            value={formattedPalCodeAccountingGroupOptions.map((item) => ({
                                name: item.accountingGroupName,
                                label: item.accountingGroupName,
                                value: item.accountingGroupName,
                            })).find(item => item.value === value.accountingGroup) || null}
                            onChange={(selectedOption) => handleValueChange({ ...value, accountingGroup: selectedOption.value })}
                            styles={customStyles}
                        />
                    </div>
                </div>
            ),
        },
        AccountingGroup: {
            headerContent: (
                <h2 className='centered-heading'>
                    {isEditMode ? t("EditAccountingGroup") : t("NewAccountingGroup")}
                </h2>
            ),
            bodyContent: ({ value, handleValueChange, direction }) => (
                <div className={`${direction}`} style={{ width: '100%' }} >
                    {/* Attribute */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('Attribute')}<span className="required-asterisk">*</span>
                        </div>
                        <Select
                            placeholder={t('Attribute')}
                            options={Constants.PalCodeClassification.map(type => ({
                                value: type,
                                label: t(type),
                            }))}
                            value={
                                Constants.PalCodeClassification.map(type => ({
                                    value: type,
                                    label: t(type),
                                })).find(option => option.value === value.accountingGroupClassifaction) || null
                            }
                            onChange={(selectedOption) => handleValueChange({ ...value, accountingGroupClassifaction: selectedOption.value })}
                            styles={customStyles}
                        />
                    </div>
                    {/* Accounting Group Name */}
                    <div className="form-row-item profile-full-width">
                        <div className="form-row-item-title">
                            {t('AccountingGroup')}<span className="required-asterisk">*</span>
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('AccountingGroup')}
                            value={value.accountingGroupName || ''}
                            onChange={(e) => handleValueChange({ ...value, accountingGroupName: e.target.value })}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid #304FFF',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                    {/* Serial Number */}
                    <div className="form-row-item profile-full-width">
                        <Tooltip title={t('SerialNumberInTrialBalancePage')} arrow placement="top">

                            <div className="form-row-item-title">
                                {t('SerialNumber')}<span className="required-asterisk">*</span>
                            </div>
                        </Tooltip>
                        <TextField
                            fullWidth
                            placeholder={t('SerialNumber')}
                            value={value.orderNumber || ''}
                            onChange={(e) => handleValueChange({ ...value, orderNumber: e.target.value })}
                            variant="standard"
                            type="number"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    height: '48px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid #304FFF',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                    </div>
                </div>
            ),
        },
    };

    const { headerContent, bodyContent } = contentConfig[contentKey] || {};

    if (!isPopupOpen) return null;

    return (
        <div className="sub-popup-hamburger-overlay" onClick={handleClose}>
            <div className="sub-add-client__container" onClick={(e) => e.stopPropagation()}>
                <div className={`sub-add-client-content ${direction}`}>
                    {/* Dynamic Header Content */}
                    <div className="title-and-buttons">
                        {headerContent}
                    </div>

                    {/* Dynamic Body Content */}
                    <div className="form-row-item subAcc-full-width">
                        {typeof bodyContent === 'function' ? bodyContent({ value, handleValueChange, direction }) : bodyContent}
                    </div>

                    {/* Buttons */}
                    <div className="sub-button-container">
                        <button className="sub-close-subAcc-content-button" onClick={handleClose}>
                            {t('close')}
                        </button>
                        <button
                            className={`sub-add-subAcc-content-button ${isSaveDisabled ? 'disabled-button' : ''}`}
                            onClick={handleSaveChange}
                            disabled={isSaveDisabled} // <-- disable logic here

                        >
                            {t('save')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountantDetailsModals;
