import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import 'moment/locale/he'; // Hebrew
import 'moment/locale/en-gb'; // English (Great Britain)
import 'moment/locale/ar'; // Arabic
import { toast } from "react-toastify";
import AuthService from '../../../Services/auth.service';
import UserService from '../../../Services/user.service';
import { LanguageSelector } from '../../Login/SubComponents/LanguageSelector';
import Styles from './UpperPanelDetails.module.css';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
const isPresignedUrlExpired = url => {
    try {

        // Check if the URL is null, undefined, or empty
        if (!url || url.trim() === "") {
            console.warn("The URL is null, empty, or undefined.");
            return false; 
        }

        const urlParams = new URLSearchParams(url.split('?')[1]);
        const expiresInSeconds = parseInt(urlParams.get('X-Amz-Expires'), 10);
        const amzDate = urlParams.get('X-Amz-Date');

        if (!expiresInSeconds || !amzDate) {
            console.warn("Missing necessary parameters in the URL.");
            return true;
        }

        // Convert X-Amz-Date (which is in UTC time) to a JavaScript Date object
        const expirationTime = new Date(amzDate.substring(0, 8) + 'T' + amzDate.substring(9, 15) + 'Z');

        // Add the expiration time in seconds to the X-Amz-Date to get the exact expiration time
        expirationTime.setSeconds(expirationTime.getSeconds() + expiresInSeconds);

        // Get the current time
        const currentTime = new Date();

        // Return true if the URL has expired
        return currentTime > expirationTime;
    } catch (error) {
        console.error("Error checking URL expiration:", error);
        return false; // If there's an error, treat the URL as expired to force a reload
    }
};

function UpperPanelDetails({ client = null }) {
    const { i18n, t } = useTranslation();
    const [direction, setDirection] = useState('rtl'); // Default to 'rtl'
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [isAccountant, setIsAccountant] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);

    // Set the locale in Moment.js based on the current language
    moment.locale(i18n.language);

    useEffect(() => {
        // Check for saved language in localStorage
        const currentLanguage = localStorage.getItem('language') || 'he'; // Default to 'he' if no language is stored
        i18n.changeLanguage(currentLanguage);
    }, [i18n]);

    useEffect(() => {
        // Update direction based on the current language
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);

    const handleLogout = async () => {
        try {
            await AuthService.logout(); // Replace with your actual logout logic
            navigate('/login'); // Navigate to login page after successful logout
        } catch (error) {
            // Optionally handle any errors, like showing a notification to the user
        }
    };

    const checkLogoutTime = async () => {
        try {
            const Token = await UserService.getCurrentToken();
            const validTokenDate = await AuthService.CheckTokenValidation(Token);
            if (!validTokenDate.isValid) {
                toast.info(t('YouNeedToLogInAgain'));
                handleLogout();
            }
        } catch (error) {
            toast.info(t('YouNeedToLogInAgain'));
            handleLogout();
        }
    };

    const fetchUserData = async () => {
        try {
            setLoading(true);
            const Token = await UserService.getCurrentToken();
            const validTokenDate = await AuthService.CheckTokenValidation(Token);

            if (!validTokenDate.isValid) {
                handleLogout();
            }
            let User = await UserService.getCurrentUser();
            const userType = await UserService.getCurrentUserType();
            if (User && Token && userType) {

                if (isPresignedUrlExpired(User.profilePicture)) {
                    User = await UserService.UpdateUser(User.accountantId, Token);
                }
                setUser(User);

                if (userType.toString() === '2') {
                    setIsAccountant(true);
                }


            } 

        } catch (error) {
            handleLogout();
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchUserData();
        checkLogoutTime();
    }, []);

    const getGreeting = () => {
        const currentHour = moment().tz('Asia/Jerusalem').hour();

        if (currentHour < 12) {
            return t('greetingMorning');
        } else if (currentHour < 18) {
            return t('greetingAfternoon');
        } else {
            return t('greetingEvening');
        }
    };

    useEffect(() => {
        if (user && user.profilePicture) {
            const img = new Image();
            img.src = user.profilePicture;
            img.onload = () => {
                setImageLoaded(true);
            };
            img.onerror = () => {
                setImageLoaded(false);
            };

        } else {
            setImageLoaded(false);
        }
    }, [user]);

    const currentDate = moment().tz('Asia/Jerusalem').format('LL'); // Localized date without time

    return (
        <div className={Styles.UpperPanelDetailsContainer} dir={direction}>
            {client && <div className={`${direction}`} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className={`main-title-black ${direction}`}>{client.name}</div>
            </div>}

            {!client &&
                <div className={Styles.UpperPanelDetailsGreetingContainer}>
                    <div>{getGreeting()} {user && user.name}</div>
                    <div>{currentDate}</div>
                </div>
            }

            <div className={Styles.UpperPanelDetailsOperationsContainer}>
                {client &&
                    <div className={Styles.UpperPanelDetailsGreetingContainerNoJustify}>
                        <div>{getGreeting()} {user && user.name}</div>
                        <div>{currentDate}</div>
                    </div>
                }
                <div className={client ? Styles.profileContainerMargin : Styles.profileContainer}>
                    <svg
                        style={{ borderRadius: '48px', cursor: 'pointer' }}
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        onClick={() => setIsLightboxOpen(true)}
                    >
                        {imageLoaded ? (
                            <>
                                <defs>
                                    <clipPath id="circleView">
                                        <circle cx="20" cy="20" r="20" />
                                    </clipPath>
                                </defs>
                                <image
                                    x="0"
                                    y="0"
                                    width="40"
                                    height="40"
                                    href={user && user.profilePicture}
                                    clipPath="url(#circleView)"
                                    preserveAspectRatio="xMidYMid slice"
                                />
                            </>
                        ) : (
                            <circle cx="20" cy="20" r="20" fill="#f0f0f0" />
                        )}
                    </svg>
                    {!client &&
                        <div className={Styles.userName}>{user && user.name}</div>
                    }
                </div>
                <LanguageSelector />
            </div>

            {/* Lightbox Popup */}
            {isLightboxOpen && user && user.profilePicture && (
                <Lightbox
                    open={isLightboxOpen}
                    close={() => setIsLightboxOpen(false)}
                    slides={[{ src: user.profilePicture, title: user.name }]}
                />
            )}
        </div>
    );
}

export default UpperPanelDetails;
