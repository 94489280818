import React, { useRef, useEffect, useState } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { IoDocumentTextOutline } from 'react-icons/io5';
import './ChatMessagesList.css';
import { useTranslation } from 'react-i18next';
import ChatApiService from '../../../../Services/chat.service';
import UserAvatar from '../../../Widgets/UserAvatar/UserAvatar';
import { Menu, MenuItem, ListItemIcon, ListItemText, Checkbox } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import MoreVertIcon from '@mui/icons-material/MoreVert';
const ChatMessagesList = ({
  messages = [],
  containerHeight = 600,
  onClickImage,
  onScroll,
  highestPageLoaded,
  lowestPageLoaded,
  isTyping,
  getAllForReceiver,
  refreshMessages
}) => {
  const parentRef = useRef(null);
  const { i18n, t } = useTranslation();
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedMessages, setSelectedMessages] = useState(new Set());
  const [multiSelectMode, setMultiSelectMode] = useState(false);
  useEffect(() => {
    const parent = parentRef.current;
    if (!parent || !onScroll) return;

    const handleScroll = () => {
      const scrollTop = parent.scrollTop;
      const scrollHeight = parent.scrollHeight;
      const clientHeight = parent.clientHeight;
      
      // Pass the scroll data to the onScroll handler
      onScroll({ scrollTop, scrollHeight, clientHeight });
    };

    parent.addEventListener('scroll', handleScroll);
    return () => parent.removeEventListener('scroll', handleScroll);
  }, [onScroll]);

  const handleRightClick = (event, message) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
      message,
    });
    handleToggleMessageSelection(message.id);
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleToggleMessageSelection = (messageId) => {
    const newSelectedMessages = new Set(selectedMessages);
    if (newSelectedMessages.has(messageId)) {
      newSelectedMessages.delete(messageId);
    } else {
      newSelectedMessages.add(messageId);
    }
    setSelectedMessages(newSelectedMessages);
  };

  const handleEnterMultiSelectMode = () => {
    setMultiSelectMode(true);
    setSelectedMessages(new Set([contextMenu.message.id])); // Start with the right-clicked message
    handleCloseContextMenu();
  };

  const handleDeleteSelectedMessages = async () => {
    try {
      if (!selectedMessages || selectedMessages.size === 0) {
        console.warn("No messages selected for deletion.");
        return;
      }

      await Promise.all(
        Array.from(selectedMessages).map((messageId) => {
          if (!messageId) {
            console.warn("Encountered an undefined message ID.");
            return Promise.resolve(); // Skip undefined IDs
          }
          return ChatApiService.deleteMessage(messageId);
        })
      );

      setSelectedMessages(new Set());
      setMultiSelectMode(false);
      refreshMessages(false);
    } catch (error) {
      console.error("Error deleting messages:", error);
    }
  };

  const handleExitMultiSelectMode = () => {
    setMultiSelectMode(false);
    setSelectedMessages(new Set()); // Clear all selected messages
  };
  // Setup the virtualizer
  const virtualizer = useVirtualizer({
    count: messages.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 100, // Initial size estimate
    overscan: 5,
    measureElement: (el) => el.getBoundingClientRect().height,
  });

  // Scroll to the bottom when the component loads or the messages update
  useEffect(() => {
    if (virtualizer && highestPageLoaded === 1 && lowestPageLoaded === 1) {
      setTimeout(() => {
        virtualizer.scrollToIndex(messages.length - 1, {
          align: 'end',
          behavior: 'smooth',
        });
      }, 100); // Allow time for DOM updates
    }
  }, [messages, virtualizer]);
  return (
    <div
      ref={parentRef}
      className="chat-messages-container"
      style={{
        height: containerHeight,
        overflowY: 'auto',
      }}
    >
      <div
        style={{
          height: virtualizer.getTotalSize(),
          width: '100%',
          position: 'relative',
        }}
      >

        {virtualizer.getVirtualItems().map((virtualRow) => {
          const message = messages[virtualRow.index];
          if (!message) return null;

          return (
            <div
              key={virtualRow.key}
              data-index={virtualRow.index}
              ref={virtualizer.measureElement}
              className="chat-message-row"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualRow.start}px)`,
              }}
              onContextMenu={(e) => handleRightClick(e, message)}

            >
              {multiSelectMode && (
                <Checkbox
                  checked={selectedMessages.has(message.id)}
                  onChange={() => handleToggleMessageSelection(message.id)}
                  style={{
                    position: 'absolute',
                    [message.position === 'right' ? 'left' : 'right']: '10px', // Dynamic positioning
                    top: '10px',
                  }}
                />
              )}
              <MessageBubble message={message} onClickImage={onClickImage} t={t} getAllForReceiver={getAllForReceiver} />
            </div>
          );
        })}
      </div>
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {!multiSelectMode && (
          <MenuItem onClick={handleEnterMultiSelectMode}>
            <ListItemIcon>
              <SelectAllIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('SelectMultipleMessages')}</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handleDeleteSelectedMessages}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" sx={{ color: 'red' }} />
          </ListItemIcon>
          <ListItemText>{t('DeleteSelected')}</ListItemText>
        </MenuItem>
      </Menu>
      {multiSelectMode && selectedMessages.size > 0 && (
        <div className="bulk-actions">
          <button onClick={handleDeleteSelectedMessages} className="bulk-delete-button">
            {t('DeleteSelected')} ({selectedMessages.size})
          </button>
          <button onClick={handleExitMultiSelectMode} className="exit-multi-select-button">
            {t('ExitSelectionMode')}
          </button>
        </div>
      )}
    </div>
  );
};

export default ChatMessagesList;

/* --------------------------------------
   MessageBubble: Renders a single message
   -------------------------------------- */

const renderReadAt = (isRight, receiverReadAt, t) => {
  if (receiverReadAt && isRight) {
    const readDate = new Date(receiverReadAt);
    const now = new Date();
    const isDifferentDay =
      readDate.getDate() !== now.getDate() ||
      readDate.getMonth() !== now.getMonth() ||
      readDate.getFullYear() !== now.getFullYear();

    const formattedDate = isDifferentDay
      ? `${readDate.getDate().toString().padStart(2, '0')}:${(readDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')} ${readDate
          .getHours()
          .toString()
          .padStart(2, '0')}:${readDate.getMinutes().toString().padStart(2, '0')}`
      : `${readDate.getHours().toString().padStart(2, '0')}:${readDate
        .getMinutes()
        .toString()
        .padStart(2, '0')}`;

    return (
      <div className="read-at-container">
        <span className="read-at-text">
          {t('ReadAt')}: {formattedDate}
        </span>
      </div>
    );
  }
  return null;
};



function MessageBubble({ message, onClickImage, t, getAllForReceiver }) {
  const { position, title, dateString, sending, receiverReadAt } = message;
  const isRight = position === 'right';

  return (
    <div
      className={`bubble-row ${isRight ? 'bubble-row-left' : 'bubble-row-right'}`}
      style={{ display: 'flex', columnGap: '10px' }}
    >

      <div>
        <div className={`bubble-container ${isRight ? 'bubble-right' : 'bubble-left'}`}>
          {renderMessageContent(message, onClickImage, t)}
          <div className="bubble-footer">
            <div className={isRight ? 'bubble-timestamp' : 'bubble-timestamp-receiver'}>
              {dateString}
            </div>
            {isRight && (
              <div className="bubble-status">
                {sending === true && <span className="status-sending">{t('Sending')}</span>}
                {/* {sending === false && <span className="status-success">✔ Sent</span>} */}
                {sending === 'failed' && <span className="status-failed">{t('FailedToSend')}</span>}
              </div>
            )}
          </div>

        </div>
        {renderReadAt(isRight, receiverReadAt, t)}
      </div>
      {(!isRight && getAllForReceiver) && (

        <div className="sender-name2">
          <UserAvatar name={title || 'Unknown'} size={25} withName={false} />
          <div className="sender-name">
            {title}
          </div>
        </div>
      )}
    </div>
  );
}

function renderMessageContent(message, onClickImage, t) {
  const { type, text, data } = message;

  switch (type) {
    case 'text':
      return <TextBubble text={text} />;
    case 'photo':
      return <PhotoBubble uris={data?.uris || []} onClickImage={onClickImage} />;
    case 'file':
      return <FileBubble fileURLs={data?.fileURLs || []} t={t} />;
    case 'audio':
      return <AudioBubble audioURLs={data?.audioURLs || []} t={t} />;
    default:
      return <TextBubble text={text} />;
  }
}

/* --------------------------------
   Helper Functions
   -------------------------------- */
function TextBubble({ text = '' }) {

  return (
    <div className="bubble-content text-bubble" style={{ whiteSpace: 'pre-wrap' }}>
      {text}
    </div>
  );
}

function PhotoBubble({ uris, onClickImage }) {

  return (
    <div className="bubble-content photo-bubble">
      {uris.map((uri, idx) => (
        <img
          key={`${uri}-${idx}`}
          src={uri}
          alt={`img-${idx}`}
          className="photo-image"
          onClick={() => onClickImage?.(uri)}
        />
      ))}
    </div>
  );
}

/* --------------------------------
   Updated FileBubble with FilePreview
   -------------------------------- */
function FileBubble({ fileURLs, t }) {
  return (
    <div className="bubble-content file-bubble">
      {fileURLs.map((fileUrl, idx) => (
        <FilePreview key={`${fileUrl}-${idx}`} fileUrl={fileUrl} t={t} />
      ))}
    </div>
  );
}
/* FilePreview Component */
function FilePreview({ fileUrl: initialFileUrl, t }) {

  // const fileName = extractFileName(fileUrl);
  const { fileUrl, fileName } = extractFileDetails(initialFileUrl);
  const fileExt = getFileExtension(fileName).toLowerCase();

  const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'].includes(fileExt);
  const isPdf = fileExt === 'pdf';
  const isVideo = ['mp4', 'webm', 'ogg'].includes(fileExt);
  const isAudio = ['mp3', 'wav', 'ogg'].includes(fileExt);
  const isText = ['txt', 'csv'].includes(fileExt);

  const handleFileOpen = () => {

    window.open(fileUrl, '_blank');
  };

  return (
    <div className="file-item" >
      {isImage && (
        <img
          src={fileUrl}
          alt={fileName}
          className="file-preview image-preview"
          onClick={handleFileOpen}
        />
      )}
      {isPdf && (
        <div className="file-preview pdf-preview">
          <iframe
            title={`pdf-preview-${fileName}`}
            src={fileUrl}
            className="iframe-preview"
          />
          <div
            className="chat-iframe-overlay"
            onClick={handleFileOpen}
            style={{ cursor: 'pointer' }}
            title="Click to open PDF"
          />
        </div>

      )}
      {isVideo && (
        <video
          controls
          src={fileUrl}
          className="file-preview video-preview"
          onClick={handleFileOpen}
        >
          {t('BrowserDoesNotSupportVideo')}
        </video>
      )}
      {/* {isAudio && (
        <audio
          controls
          src={fileUrl}
          className="file-preview audio-preview"
          onClick={handleFileOpen}
        >
          {t('BrowserDoesNotSupportAudio')}
        </audio>
      )} */}
      {isText && (
        <div
          className="file-preview text-preview"
          onClick={handleFileOpen}
          title={fileName}
        >
          <IoDocumentTextOutline size={30} color="#304FFF" />
          <span className="file-name">{fileName}</span>
        </div>
      )}
      {!isImage && !isPdf && !isVideo && !isAudio && !isText && (
        <div
          className="file-preview unsupported-preview"
          onClick={handleFileOpen}
        >
          <IoDocumentTextOutline size={30} color="#304FFF" />
          <span className="file-name">{fileName}</span>
        </div>
      )}
    </div>
  );
}

function AudioBubble({ audioURLs, t }) {
  return (
    <div className="bubble-content audio-bubble">
      {audioURLs.map((audioUrl, idx) => (
        <audio key={`${audioUrl}-${idx}`} controls src={audioUrl} className="audio-player">
          {t('BrowserDoesNotSupportElement')} <code>{t('Audio')}</code>
        </audio>
      ))}
    </div>
  );
}

function extractFileName(url) {
  if (url.startsWith('blob:') && url.includes('|')) {
    // Split the URL by the '|' character
    const parts = url.split('|');
    // Return the part after the '|', which contains the file name
    return parts[1];
  }

  const parts = url.split('/');
  return parts[parts.length - 1].split('?')[0] || 'Unknown file';
}

function extractFileDetails(fileUrl) {
  let fileName = ''; // Default file name extraction
  if (fileUrl.startsWith('blob:') && fileUrl.includes('|')) {
    const parts = fileUrl.split('|');
    fileUrl = parts[0]; // Extract the blob URL
    fileName = parts[1]; // Extract the file name
  }
  else {
    const parts = fileUrl.split('/');
    fileName = parts[parts.length - 1].split('?')[0] || 'Unknown file';
  }
  return { fileUrl, fileName };
}

function getFileExtension(fileName) {
  return fileName.split('.').pop().toLowerCase();
}

