import config from '../config';
import selectedEnvironment from '../env';

const baseURL = config[selectedEnvironment].API_URL;

const SortCodes_API_URL = `${baseURL}/SortCodes`;
const SortCodesGroup_API_URL = `${baseURL}/SortCodesGroup`;
const Providers_API_URL = `${baseURL}/Providers`;
const OtherDocumentsTypes_API_URL = `${baseURL}/OtherDocumentsTypes`;
const OtherDocuments_API_URL = `${baseURL}/OtherDocuments`;
const ChecksTypes_API_URL = `${baseURL}/ChecksTypes`;
const CaseTypes_API_URL = `${baseURL}/CaseTypes`;
const AccountCards_API_URL = `${baseURL}/AccountCards`;
const ClientItems_API_URL = `${baseURL}/ClientItems`;
const PalCodeCategories_API_URL = `${baseURL}/PalCodeCategories`;
const PalCodes_API_URL = `${baseURL}/PalCodes`;
const PalCodeAccountingGroups_API_URL = `${baseURL}/PalCodeAccountingGroups`;
const TBanks_API_URL = `${baseURL}/TBanks`;
const Ledgers_API_URL = `${baseURL}/Ledgers`;
const API_URL = baseURL;

const GetAccountCardsByCaseId = async (CaseId, auth) => {
    const response = await fetch(`${AccountCards_API_URL}/ByCaseId/${CaseId}`, {
        method: 'GET',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
    });

    return response;
};

const GetAccountCardByCardId = async (CaseId, CardId, auth) => {
    const response = await fetch(`${AccountCards_API_URL}/GetAccountCardByCardId`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CaseId: CaseId,
            CardId: CardId
        })
    });

    return response;
};

const GetCaseTypesByByAccountantId = async (AccountantId, auth) => {
    const response = await fetch(`${CaseTypes_API_URL}/ByAccountantId/${AccountantId}`, {
        method: 'GET',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
    });

    return response;
};

const GetChecksTypesByAccountantId = async (AccountantId, auth) => {
    const response = await fetch(`${ChecksTypes_API_URL}/ByAccountantId/${AccountantId}`, {
        method: 'GET',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
    });

    return response;
};

const GetOtherDocumentsTypesByAccountantId = async (AccountantId, auth) => {
    const response = await fetch(`${OtherDocumentsTypes_API_URL}/ByAccountantId/${AccountantId}`, {
        method: 'GET',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
    });

    return response;
};

const GetOtherDocumentsCountsByCaseId = async (caseId, auth) => {

    const response = await fetch(`${OtherDocuments_API_URL}/GetOtherDocumentCountsByType/${caseId}`, {
        method: 'GET',
        headers: {
            "Authorization": `Bearer ${auth}`,
            "Content-Type": "application/json"
        },
    });

    return response; // Parse and return the JSON response
};


const GetProvidersBycaseId = async (CaseId, auth) => {
    const response = await fetch(`${Providers_API_URL}/GetAllBycaseId/${CaseId}`, {
        method: 'GET',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
    });

    return response;
};

const GetSortCodesByAccountantId = async (accountantId, auth) => {
    const response = await fetch(`${SortCodes_API_URL}/ByAccountantId/${accountantId}`, {
        method: 'GET',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
    });

    return response;
};

const GetSortCodesGroupByAccountantId = async (accountantId, auth) => {
    const response = await fetch(`${SortCodesGroup_API_URL}/ByAccountantId/${accountantId}`, {
        method: 'GET',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
    });

    return response;
};
const AddProvider = async (CaseId, ProviderId, ProviderName, auth) => {
    const response = await fetch(`${Providers_API_URL}/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, ProviderId, ProviderName })
    });

    return response;
};

const AddAccountCard = async (CaseId, CardId, Name, AccountCaseId, PalCode, auth, phoneNumber = null, location = null, email = null, sourceDeductionAssociation = null,
    IsEditable = true, ExactOccupation = null, DeductionType = null, TaxAssessor = null, DeductionValidity = null) => {
    const response = await fetch(`${AccountCards_API_URL}/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, CardId, Name, AccountCaseId, PalCode, phoneNumber, location, email, sourceDeductionAssociation, IsEditable, ExactOccupation, DeductionType, TaxAssessor, DeductionValidity })
    });

    return response;
};

const UpdateAccountCardCaseId = async (CaseId, CardId, AccountCaseId, email, phoneNumber, location, auth) => {
    const OldCardId = CardId;

    const response = await fetch(`${AccountCards_API_URL}/Update`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, CardId, OldCardId, AccountCaseId, email, phoneNumber, location })
    });
    return response;
};
const EditInBulk = async (CaseId, AccountCardCodes, NewPalCode, auth) => {
    const response = await fetch(`${AccountCards_API_URL}/EditInBulk`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, AccountCardCodes, NewPalCode })
    });

    return response;
};


const AddCaseType = async (AccountantId, Name, auth) => {
    const response = await fetch(`${CaseTypes_API_URL}/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ AccountantId, Name })
    });

    return response;
};

const AddChecksType = async (AccountantId, Name, auth) => {
    const response = await fetch(`${ChecksTypes_API_URL}/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ AccountantId, Name })
    });

    return response;
};

const AddOtherDocumentsType = async (AccountantId, Name, auth) => {
    const response = await fetch(`${OtherDocumentsTypes_API_URL}/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ AccountantId, Name })
    });

    return response;
};

const AddSortCode = async (AccountantId, SortCodeName, SortCodeId, sortCodeGroup, creditAccount = null, debitAccount = null, SortCodeVatType = null, auth) => {
    const response = await fetch(`${SortCodes_API_URL}/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ AccountantId, SortCodeName, SortCodeId, sortCodeGroup, creditAccount, debitAccount, SortCodeVatType })
    });

    return response;
};

const AddSortCodeGroup = async (AccountantId, SortCodeGroup, SortCodeListNumber, SortCodeGroupProfitName, auth) => {
    const response = await fetch(`${SortCodesGroup_API_URL}/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ AccountantId, SortCodeGroup, SortCodeListNumber, SortCodeGroupProfitName })
    });

    return response;
};

const UpdateProvider = async (CaseId, newProviderId, ProviderId, ProviderName, auth) => {
    const response = await fetch(`${Providers_API_URL}/Update`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, newProviderId, ProviderId, ProviderName })
    });

    return response;
};


const UpdateAccountCard = async (CaseId, OldCardId, OldName, CardId, Name, AccountCaseId, PalCode, auth
    , phoneNumber = null, location = null, email = null, sourceDeductionAssociation = null, oldAccountCaseId = ''
    , isEditable = true, ExactOccupation = null, DeductionType = null, taxAssesor = null, DeductionValidity = null) => {

    console.log(sourceDeductionAssociation);

    const response = await fetch(`${AccountCards_API_URL}/Update`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CaseId, OldCardId, CardId, OldName, Name, AccountCaseId, PalCode,
            phoneNumber, location, email, sourceDeductionAssociation,
            isEditable, oldAccountCaseId, ExactOccupation, DeductionType, TaxAssessor: taxAssesor ? taxAssesor.toString() : null
            , DeductionValidity
        })
    });

    return response;
};

const UpdateAccountCardFewDetails = async (CaseId, OldCardId, OldName, CardId, Name, AccountCaseId, oldAccountCaseId, PalCode = '', auth) => {
    const response = await fetch(`${AccountCards_API_URL}/Update`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, OldCardId, CardId, OldName, Name, AccountCaseId, PalCode, oldAccountCaseId })
    });

    return response;
};

const MergeAccountCards = async (CaseId, PrimaryCardId, SecondaryCardId, auth) => {
    const response = await fetch(`${AccountCards_API_URL}/Merge`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, PrimaryCardId, SecondaryCardId })
    });

    return response;
};

const UpdateCaseType = async (AccountantId, Name, OldName, auth) => {
    const response = await fetch(`${CaseTypes_API_URL}/Update`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ AccountantId, Name, OldName })
    });

    return response;
};

const UpdateCheckType = async (AccountantId, Name, OldName, auth) => {
    const response = await fetch(`${ChecksTypes_API_URL}/Update`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ AccountantId, Name, OldName })
    });

    return response;
};

const UpdateOtherDocumentsType = async (AccountantId, Name, OldName, auth) => {
    const response = await fetch(`${OtherDocumentsTypes_API_URL}/Update`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ AccountantId, Name, OldName })
    });

    return response;
};

const UpdateSortCode = async (AccountantId, OldSortCodeId, SortCodeId, SortCodeName, sortCodeGroup, CreditAccount, DebitAccount, sortCodeVatType = null, auth) => {
    const response = await fetch(`${SortCodes_API_URL}/Update`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ AccountantId, OldSortCodeId, SortCodeId, SortCodeName, sortCodeGroup, CreditAccount, DebitAccount, sortCodeVatType })
    });

    return response;
};

const UpdateSortCodeGroup = async (AccountantId, SortCodeGroup, SortCodeListNumber, sortCodeGroupProfitName, Id, auth) => {
    const response = await fetch(`${SortCodesGroup_API_URL}/Update`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ AccountantId, Id, SortCodeGroup, SortCodeListNumber, sortCodeGroupProfitName })
    });

    return response;
};

const DeleteAccountCardsbyCaseId = async (CaseId, cardId, auth) => {
    const response = await fetch(`${AccountCards_API_URL}/Delete`, {
        method: 'Delete',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, cardId })
    });

    return response;
};

const DeleteProviderbyCaseId = async (CaseId, providerId, auth) => {
    const response = await fetch(`${Providers_API_URL}/Delete`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, providerId })
    });

    return response;
};

const DeleteCaseTypebyId = async (caseTypeId, auth) => {
    const response = await fetch(`${CaseTypes_API_URL}/Delete/${caseTypeId}`, {
        method: 'DELETE',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
    });

    return response;
};

const DeleteChecksTypebyId = async (checkTypeId, auth) => {
    const response = await fetch(`${ChecksTypes_API_URL}/Delete/${checkTypeId}`, {
        method: 'DELETE',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
    });

    return response;
};

const DeleteOtherDocumentbyId = async (otherDocumentId, auth) => {
    const response = await fetch(`${OtherDocumentsTypes_API_URL}/Delete/${otherDocumentId}`, {
        method: 'DELETE',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
    });

    return response;
};

const DeleteSortCodebyId = async (AccountantId, codeId, auth) => {
    const response = await fetch(`${SortCodes_API_URL}/Delete`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ AccountantId, codeId })
    });

    return response;
};

const DeleteSortCodeGroupById = async (AccountantId, Id, auth) => {
    const response = await fetch(`${SortCodesGroup_API_URL}/Delete`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ AccountantId, Id })
    });

    return response;
};

const UploadAccountCardsFileData = async (caseId, file, auth) => {
    const formData = new FormData();
    formData.append("CaseId", caseId);
    formData.append("File", file);

    const response = await fetch(`${AccountCards_API_URL}/Upload`, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${auth}`,
        },
        body: formData
    });

    return response;
};

const UploadFileData = async (type, AccountantId, file, auth) => {
    const formData = new FormData();
    formData.append("AccountantId", AccountantId);
    formData.append("File", file);

    const response = await fetch(`${API_URL}/${type}/Upload`, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${auth}`,
        },
        body: formData
    });

    return response;
};

const GetClientItemsByCaseId = async (CaseId, auth) => {
    const response = await fetch(`${ClientItems_API_URL}/ByCaseId/${CaseId}`, {
        method: 'GET',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
    });

    return response;
};

const AddClientItems = async (items, auth) => {
    const response = await fetch(`${ClientItems_API_URL}/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ Items: items }) // Items is an array of { CaseId, Makat, Name }
    });

    return response;
};


const UpdateClientItem = async (CaseId, OriginalMakat, Makat, Name, auth) => {
    const response = await fetch(`${ClientItems_API_URL}/Update`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, OriginalMakat, Makat, Name })
    });

    return response;
};

const GetSortCodeByName = async (sortCodeName, accountantId, token) => {

    try {
        const response = await fetch(`${SortCodes_API_URL}/GetSortCodeByName`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ sortCodeName, accountantId })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response;
    } catch (error) {
    }
};

const GetPalCodeCategoriesByAccountantId = async (AccountantId, auth) => {
    const response = await fetch(`${PalCodeCategories_API_URL}/ByAccountantId/${AccountantId}`, {
        method: 'GET',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
    });

    return response;
};

const AddPalCodeCategory = async (newPalCodeCategory, auth) => {
    const response = await fetch(`${PalCodeCategories_API_URL}/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...newPalCodeCategory })
    });

    return response;
};

const UpdatePalCodeCategory = async (updatedPalCodeCategory, auth) => {
    const response = await fetch(`${PalCodeCategories_API_URL}/Update`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...updatedPalCodeCategory })
    });

    return response;
};

const GetPalCodesByAccountantId = async (AccountantId, auth) => {
    const response = await fetch(`${PalCodes_API_URL}/ByAccountantId/${AccountantId}`, {
        method: 'GET',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
    });
    return response;
};

const AddPalCode = async (addPalCode, auth) => {
    const response = await fetch(`${PalCodes_API_URL}/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...addPalCode })
    });

    return response;
};

const UpdatePalCode = async (updatedPalCode, auth) => {
    const response = await fetch(`${PalCodes_API_URL}/Update`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...updatedPalCode })
    });

    return response;
};

const DeletePalCodeDelete = async (accountantId, PalCodeId, auth) => {
    const response = await fetch(`${PalCodes_API_URL}/Delete`, {
        method: 'Delete',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ accountantId, PalCodeId })
    });

    return response;
};

const DeletePalCodeCategoriesDelete = async (accountantId, PalCodeCategoryId, auth) => {
    const response = await fetch(`${PalCodeCategories_API_URL}/Delete`, {
        method: 'Delete',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ accountantId, PalCodeCategoryId })
    });

    return response;
};



const AccountCardPalCodeDetails = async (accountCardId, accountCardName, accountantId, palCode, caseId, startDate, endDate, isYearly, OnlyProcessed, token) => {
    try {
        const response = await fetch(`${AccountCards_API_URL}/accountCardPalcodeDetails`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ accountCardId, accountCardName, accountantId, palCode, caseId, startDate, endDate, isYearly, OnlyProcessed })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response;
    } catch (error) {
    }
};



const GetPalCodeAccountingGroupsByAccountantId = async (AccountantId, auth) => {
    const response = await fetch(`${PalCodeAccountingGroups_API_URL}/ByAccountantId/${AccountantId}`, {
        method: 'GET',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
    });

    return response;
};

const AddPalCodeAccountingGroups = async (addPalCodeAccountingGroups, auth) => {
    const response = await fetch(`${PalCodeAccountingGroups_API_URL}/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...addPalCodeAccountingGroups })
    });

    return response;
};

const UpdatePalCodeAccountingGroups = async (updatedPalCodeAccountingGroups, auth) => {
    const response = await fetch(`${PalCodeAccountingGroups_API_URL}/Update`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...updatedPalCodeAccountingGroups })
    });

    return response;
};

const DeletePalCodeAccountingGroups = async (accountantId, AccountingGroupName, auth) => {
    const response = await fetch(`${PalCodeAccountingGroups_API_URL}/Delete`, {
        method: 'Delete',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ accountantId, AccountingGroupName })
    });

    return response;
};

const GetPalcodesDetailsByAccountCard = async (AccountantId, PalCode, auth) => {
    const response = await fetch(`${AccountCards_API_URL}/GetPalcodesDetailsByAccountCard`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ AccountantId, PalCode })
    });

    return response;
};



const GetAllTBanksByCaseId = async (caseId, auth) => {
    try {
        const response = await fetch(`${TBanks_API_URL}/GetAllByCaseId/${caseId}`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${auth}`,
                "Content-Type": "application/json"
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch TBanks by CaseId');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching TBanks:', error);
        throw error; // Re-throw the error to handle it further up the call chain
    }
};

const AddTBank = async (CaseId, BankName, BankCode, BranchNumber, AccountNumber, CardID, auth) => {
    const response = await fetch(`${TBanks_API_URL}/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, BankName, BankCode, BranchNumber, AccountNumber, CardID })
    });

    return response;
};

export const DeleteTBank = async (caseId, accountNumber, bankCode, authToken) => {
    const response = await fetch(`${TBanks_API_URL}/Delete`, {
        method: 'DELETE',
        headers: {
            "Authorization": 'Bearer ' + authToken,
            'Content-Type': 'application/json' // Content type is JSON
        },
        body: JSON.stringify({ caseId, accountNumber, bankCode }) // Send the caseId, accountNumber, and bankCode
    });

    // Check if the request was successful
    return response;
};

const EditTBank = async (CaseId, OldBankCode, OldAccountNumber, BankName, BankCode, BranchNumber, AccountNumber, CardId, auth) => {

    const response = await fetch(`${TBanks_API_URL}/Update`, {
        method: 'PUT',  // Use PUT for updates
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CaseId,
            BankName,
            BankCode,
            BranchNumber,
            AccountNumber,
            OldBankCode,      // Old bank code for identifying the record to update
            OldAccountNumber,  // Old account number for identifying the record to update
            CardId
        })
    });

    return response;
};

const GetCollectionPage = async (caseId, auth, date = null) => {
    try {
        const response = await fetch(`${Ledgers_API_URL}/GetByCaseId`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + auth,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                caseId: caseId,
                date: date // Send date as provided, or null if not
            })
        });
        return response;
    } catch (error) {
        console.error('Failed to get Collection Page');
        throw error;
    }
};


const AddMonthlyCharge = async (ledgerId, startDate, endDate, amount, description, auth) => {
    const response = await fetch(`${Ledgers_API_URL}/monthlyCharge/create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ ledgerId, startDate, endDate, amount, description })
    });

    return response;
};

const AddOneTimeCharge = async (ledgerId, date, amount, description, auth) => {

    const response = await fetch(`${Ledgers_API_URL}/oneTimeCharge/create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ ledgerId, date, amount, description })
    });

    return response;
};

const AddPayment = async (ledgerId, type, date, amount, description, reference, auth) => {
    const response = await fetch(`${Ledgers_API_URL}/payment/create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ ledgerId, type, date, amount, description, reference })
    });

    return response;
};

// Edit Monthly Charge
const EditMonthlyCharge = async (id, ledgerId, startDate, endDate, amount, description, auth) => {
    const response = await fetch(`${Ledgers_API_URL}/monthlyCharge/update`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ id, ledgerId, startDate, endDate, amount, description })
    });
    return response;
};

// Edit One-Time Charge
const EditOneTimeCharge = async (id, ledgerId, date, amount, description, auth) => {
    const response = await fetch(`${Ledgers_API_URL}/oneTimeCharge/update`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ id, ledgerId, date, amount, description })
    });
    return response;
};

// Edit Payment
const EditPayment = async (id, ledgerId, type, date, amount, description, reference, auth) => {
    const response = await fetch(`${Ledgers_API_URL}/payment/update`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ id, ledgerId, type, date, amount, description, reference })
    });
    return response;
};

// Delete Monthly Charge
const DeleteMonthlyCharge = async (monthlyChargeId, auth) => {
    const response = await fetch(`${Ledgers_API_URL}/monthlyCharge/delete/${monthlyChargeId}`, {
        method: 'DELETE',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        }
    });
    return response;
};

// Delete One-Time Charge
const DeleteOneTimeCharge = async (oneTimeChargeId, auth) => {
    const response = await fetch(`${Ledgers_API_URL}/oneTimeCharge/delete/${oneTimeChargeId}`, {
        method: 'DELETE',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        }
    });
    return response;
};

// Delete Payment
const DeletePayment = async (paymentId, auth) => {
    const response = await fetch(`${Ledgers_API_URL}/payment/delete/${paymentId}`, {
        method: 'DELETE',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        }
    });
    return response;
};

const DownloadLedgerReport = async (caseId, date, auth) => {
    try {
        const response = await fetch(`${Ledgers_API_URL}/DownloadLedgerReport`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + auth,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                CaseId: caseId,
                Date: date
            })
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error(errorText);
            throw new Error(errorText);
        }

        const blob = await response.blob();

        // Create a URL for the PDF file
        const fileUrl = window.URL.createObjectURL(blob);

        // Create a temporary link to trigger the download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `תנועות-כספיות-${caseId}.pdf`); // Filename in Hebrew or desired format
        document.body.appendChild(link);
        link.click();

        // Cleanup by removing the link and revoking the object URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(fileUrl);
    } catch (error) {
        console.error("Error downloading ledger report:", error);
        throw error;
    }
};

const GetBalancesByCaseIds = async (accountantId, caseIds, auth, date = null) => {
    try {
        const response = await fetch(`${Ledgers_API_URL}/GetBalancesByCaseIds`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${auth}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ accountantId, caseIds, date })
        });

        if (!response.ok) {
            throw new Error(`Error fetching balances: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to get balances by case IDs:', error);
        return []; // Re-throw to handle further up the call chain if necessary
    }
};


const UploadAccountCardsFileDataRevhit = async (caseId, file, auth) => {
    const formData = new FormData();
    formData.append("CaseId", caseId);
    formData.append("File", file);

    const response = await fetch(`${AccountCards_API_URL}/UploadFromRevhit`, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${auth}`,
        },
        body: formData
    });

    return response;
};

const ExportAccountCards = async (CaseId, AccountantId, auth) => {
    try {
        const response = await fetch(`${AccountCards_API_URL}/ExportAccountCards`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${auth}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ CaseId, AccountantId })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const blob = await response.blob();
        const fileUrl = window.URL.createObjectURL(blob);

        // Create a temporary link to trigger the download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `Account_Cards_${CaseId}.xlsx`);
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(fileUrl);
    } catch (error) {
        console.error("Error exporting account cards:", error);
        throw error;
    }
};



const EnumsService = {
    GetAccountCardsByCaseId,
    GetAccountCardByCardId,
    GetCaseTypesByByAccountantId,
    GetChecksTypesByAccountantId,
    GetOtherDocumentsTypesByAccountantId,
    GetProvidersBycaseId,
    GetSortCodesByAccountantId,
    AddProvider,
    UpdateProvider,
    UpdateAccountCard,
    UpdateCaseType,
    UpdateCheckType,
    UpdateOtherDocumentsType,
    UpdateSortCode,
    AddAccountCard,
    AddCaseType,
    AddChecksType,
    AddOtherDocumentsType,
    AddSortCode,
    DeleteAccountCardsbyCaseId,
    DeleteProviderbyCaseId,
    DeleteCaseTypebyId,
    DeleteChecksTypebyId,
    DeleteOtherDocumentbyId,
    DeleteSortCodebyId,
    UploadAccountCardsFileData,
    UploadFileData,
    GetClientItemsByCaseId,
    AddClientItems,
    UpdateClientItem,
    GetSortCodesGroupByAccountantId,
    AddSortCodeGroup,
    UpdateSortCodeGroup,
    DeleteSortCodeGroupById,
    GetSortCodeByName,
    // PalCodeCategories functions
    GetPalCodeCategoriesByAccountantId,
    AddPalCodeCategory,
    UpdatePalCodeCategory,

    // PalCodes functions
    GetPalCodesByAccountantId,
    AddPalCode,
    UpdatePalCode,
    DeletePalCodeDelete,
    DeletePalCodeCategoriesDelete,
    //palcode details
    AccountCardPalCodeDetails,
    GetPalCodeAccountingGroupsByAccountantId,
    AddPalCodeAccountingGroups,
    UpdatePalCodeAccountingGroups,
    DeletePalCodeAccountingGroups,
    GetPalcodesDetailsByAccountCard,
    MergeAccountCards,
    EditInBulk,
    GetAllTBanksByCaseId,
    AddTBank,
    EditTBank,
    DeleteTBank,
    GetCollectionPage,
    AddMonthlyCharge,
    AddOneTimeCharge,
    AddPayment,
    EditMonthlyCharge,
    EditOneTimeCharge,
    EditPayment,
    DeleteMonthlyCharge,
    DeleteOneTimeCharge,
    DeletePayment,
    DownloadLedgerReport,
    GetBalancesByCaseIds,
    UpdateAccountCardFewDetails,
    GetOtherDocumentsCountsByCaseId,
    UploadAccountCardsFileDataRevhit,
    UpdateAccountCardCaseId,
    ExportAccountCards
};

export default EnumsService;