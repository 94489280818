import React, { useState, useEffect, useRef, useMemo,useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';

import { TextField, Button, InputAdornment, IconButton, Dialog } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { PuffLoader } from 'react-spinners';

import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import UpperPanelDetails from '../Widgets/UpperPanelDetails/UpperPanelDetails';

import ClientItemsService from '../../Services/clientItems.service';
import UserService from '../../Services/user.service';
import CustomDeleteConfirmationModal from '../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal';
import ClientItemDraggableDialog from './SubComponents/ClientItemDraggableDialog';
import ClientItemCreateModal from './SubComponents/ClientItemCreateModal';
import ClientItemDataGrid from './SubComponents/ClientItemDataGrid';
import { NavHistoryContext } from '../../Contexts/NavHistoryContext';
function ClientItems() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const { goBack } = useContext(NavHistoryContext);
    // Direction (RTL / LTR)
    const [direction, setDirection] = useState('rtl');
    useEffect(() => {
        // Example: if your language is 'en' => direction = 'ltr'
        if (i18n.language === 'en') {
            setDirection('ltr');
        } else {
            setDirection('rtl');
        }
    }, [i18n.language]);

    // Local states
    const [user, setUser] = useState(null);
    const [token, setToken] = useState('');
    const [client, setClient] = useState(null);

    // The main array of client items
    const [data, setData] = useState([]);

    // Searching
    const [searchQuery, setSearchQuery] = useState('');

    // Loading & Modals
    const [loading, setLoading] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    // We can store the item being edited in the draggable dialog
    const [editingItem, setEditingItem] = useState(null);
    const [openInlineEditModal, setOpenInlineEditModal] = useState(false);

    // Create-modal visibility
    const [createModalVisible, setCreateModalVisible] = useState(false);

    // If you allow row selection for bulk actions, you can track that here:
    const [selectedRows, setSelectedRows] = useState([]);

    // ===========================
    // 1) Load user & client data
    // ===========================
    useEffect(() => {
        (async () => {
            try {
                const currentUser = await UserService.getCurrentUser();
                const currentToken = await UserService.getCurrentToken();
                if (!currentUser || !currentToken) {
                    navigate('/login');
                    return;
                }
                setUser(currentUser);
                setToken(currentToken);

                // If the parent route passes a client or similar data, store it:
                if (location.state?.client) {
                    setClient(location.state.client);
                }

                // Then fetch the initial list of items
                await fetchClientItems(currentUser, currentToken, location.state?.client);
            } catch (err) {
                console.error('Error loading user data:', err);
                navigate('/login');
            }
        })();
    }, [location.state?.client, navigate]);

    // ===========================
    // 2) Fetch & Refresh
    // ===========================
    const fetchClientItems = async (userObj, userToken, clientObj) => {
        setLoading(true);
        try {
            if (!clientObj) return;

            // Example call to your service:
            const resp = await ClientItemsService.GetClientItemsByCaseId(
                clientObj.caseId,
                userToken
            );
            if (!resp.ok) {
                const errorText = await resp.text();
                toast.error(t('ErrorFetchingClientItems') + `: ${errorText}`);
            } else {
                const jsonData = await resp.json();
                setData(jsonData);
            }
        } catch (err) {
            console.error(err);
            toast.error(t('FailedToFetchClientItems'));
        }
        setLoading(false);
    };

    // ===========================
    // 3) Searching & Filtering
    // ===========================
    const filteredData = useMemo(() => {
        if (!searchQuery) return data;
        const lowerQuery = searchQuery.toLowerCase();
        return data.filter((item) => {
            return (
                item.makat?.toLowerCase().includes(lowerQuery) ||
                item.name?.toLowerCase().includes(lowerQuery) ||
                item.price?.toString().includes(lowerQuery)
            );
        });
    }, [data, searchQuery]);

    // ===========================
    // 4) Deletion
    // ===========================
    const handleDeleteClick = (item) => {
        setItemToDelete(item);
        setDeleteModalVisible(true);
    };
    const handleConfirmDelete = async () => {
        if (!itemToDelete) return;
        setLoading(true);
        try {
            const resp = await ClientItemsService.DeleteClientItem(
                itemToDelete.makat,
                client?.caseId,
                token
            );
            if (!resp.ok) {
                const errorTxt = await resp.text();
                toast.error(t('FailedToDeleteClientItem') + `: ${errorTxt}`);
            } else {
                toast.success(t('ClientItemDeletedSuccessfully'));
                // Refresh
                await fetchClientItems(user, token, client);
            }
        } catch (err) {
            console.error(err);
            toast.error(t('ErrorDeletingClientItem'));
        }
        setItemToDelete(null);
        setDeleteModalVisible(false);
        setLoading(false);
    };

    // ===========================
    // 5) Editing
    // ===========================
    const handleEdit = (item) => {
        setEditingItem(item);
        setOpenInlineEditModal(true);
    };
    const handleSaveEdit = async (updatedItem) => {
        // Save the updated item with your service
        setLoading(true);
        try {
            const resp = await ClientItemsService.UpdateClientItem(
                client.caseId,
                editingItem.makat,      // old makat
                updatedItem.makat,      // new makat
                updatedItem.name,
                parseFloat(updatedItem.price) || 0,
                token
            );
            if (!resp.ok) {
                const errorTxt = await resp.text();
                toast.error(t('FailedToUpdateClientItem') + `: ${errorTxt}`);
            } else {
                toast.success(t('ClientItemUpdatedSuccessfully'));
                await fetchClientItems(user, token, client);
            }
        } catch (error) {
            console.error(error);
            toast.error(t('ErrorUpdatingClientItem'));
        }
        setOpenInlineEditModal(false);
        setEditingItem(null);
        setLoading(false);
    };

    // Cancel editing
    const handleCloseEdit = () => {
        setOpenInlineEditModal(false);
        setEditingItem(null);
    };

    // ===========================
    // 6) Creating (multiple or single)
    // ===========================
    const handleCreate = () => {
        setCreateModalVisible(true);
    };
    const handleCloseCreateModal = () => {
        setCreateModalVisible(false);
    };
    const handleSaveCreate = async (itemsToAdd) => {
        // itemsToAdd is an array of objects with { makat, name, price }
        setLoading(true);
        try {
            const resp = await ClientItemsService.AddClientItems(
                itemsToAdd.map((item) => ({
                    CaseId: client.caseId,
                    Makat: item.makat,
                    Name: item.name,
                    Price: parseFloat(item.price) || 0,
                })),
                token
            );
            if (!resp.ok) {
                const errText = await resp.text();
                toast.error(t('FailedToAddClientItem') + `: ${errText}`);
            } else {
                toast.success(t('ClientItemAddedSuccessfully'));
                fetchClientItems(user, token, client);
            }
        } catch (err) {
            toast.error(t('ErrorAddingClientItem'));
            console.error(err);
        }
        setCreateModalVisible(false);
        setLoading(false);
    };

    // ===========================
    // 7) Table Row Selections
    // ===========================
    const handleSelectRows = (rowIds) => {
        setSelectedRows(rowIds);
    };

    // ===========================
    // 8) Render
    // ===========================
    const handleGoBack = () => {
        // If you want to navigate back or do something else:
        // navigate(-1);
        goBack();
    };

    return (
        <>
            <HamburgerMenu client={client} />

            <div className="template-page-withoutHeight">
                <div className="template-container">
                    <UpperPanelDetails client={client} />
                    {/* Top Bar with back button and main title */}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div
                            className="backIconBackground"
                            style={{ alignSelf: i18n.language === 'en' ? 'flex-start' : 'flex-end' }}
                            onClick={handleGoBack}
                        >
                            {/* Example Back Icon */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
                                <path
                                    d="M20 21.1667L14.8267 15.9933L20 10.82C20.52 10.3 20.52 9.45999 20 8.93999C19.48 8.41999 18.64 8.41999 18.12 8.93999L12 15.06C11.48 15.58 11.48 16.42 12 16.94L18.12 23.06C18.64 23.58 19.48 23.58 20 23.06C20.5067 22.54 20.52 21.6867 20 21.1667Z"
                                    fill="black"
                                    fillOpacity="0.9"
                                />
                            </svg>
                        </div>

                        <div className={`main-title ${direction}`}>
                            {t('DocumentItems')}
                        </div>
                    </div>

                    {/* Actions row: create new items button, search bar, etc. */}
                    <div className={`searchFilter-bar__container ${direction}`}>

                        <div className="create-selector" onClick={handleCreate}>
                            {t('AddClientItem')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('Search')}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="search-bar"
                            style={{ height: '55px' }}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                sx: {
                                    height: '55px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                    },
                                },
                                ...(direction === 'ltr'
                                    ? {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" style={{ marginRight: '10px' }}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }
                                    : {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton edge="start" style={{ marginLeft: '10px' }}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }),
                            }}
                        />
                    </div>

                    {/* DataGrid */}
                    <ClientItemDataGrid
                        data={filteredData}
                        direction={direction}
                        onEdit={handleEdit}
                        onDelete={handleDeleteClick}
                        onSelectRows={handleSelectRows}
                    />
                </div>

                {/* Delete Confirmation Modal */}
                <CustomDeleteConfirmationModal
                    isVisible={deleteModalVisible}
                    onClose={() => setDeleteModalVisible(false)}
                    onDelete={handleConfirmDelete}
                />

                {/* Draggable Inline Edit Modal */}
                {editingItem && openInlineEditModal && (
                    <ClientItemDraggableDialog
                        open={openInlineEditModal}
                        onClose={handleCloseEdit}
                        data={editingItem}
                        onSave={handleSaveEdit}
                        direction={direction}
                        t={t}
                    />
                )}

                {/* Create Modal */}
                {createModalVisible && (
                    <ClientItemCreateModal
                        open={createModalVisible}
                        onClose={handleCloseCreateModal}
                        onSave={handleSaveCreate}
                        direction={direction}
                        t={t}
                        existingItems={data}
                    />
                )}

                <ToastContainer />
                {loading && (
                    <div className="loader-container">
                        <PuffLoader size={100} loading={loading} />
                    </div>
                )}
            </div>
        </>
    );
}

export default ClientItems;
