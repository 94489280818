import React, { useState, useMemo } from "react";
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TablePagination, IconButton, Paper,
    Tooltip, TableSortLabel
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';

// Example header styling
const headerStyle = {
    backgroundColor: 'var(--foundation-blue-light-active, #BFC8FF)',
    color: "var(--Foundation-Blue-Normal, #304FFF)",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 'bold',
    height: '56px',
    minHeight: '50px',
    padding: "0 5px",
};

// Decide row styling based on parent/sub-row
const getRowSx = (row) => (row.isSubRow ? { backgroundColor: "#F7F9FC" } : {});

function AssetTable({
    assets,
    assetGroups,
    expandedRows,
    handleExpandClick,
    onRowClick,
    searchQuery
}) {
    const { t } = useTranslation();
    const groups = useMemo(() => groupAssetsByGroup(assets, assetGroups), [assets, assetGroups]);

    // Sorting state
    const [sortConfig, setSortConfig] = useState({ key: "", direction: "ascending", type: "string" });

    // Pagination state
    const [page, setPage] = useState(0);
    const [groupsPerPage, setGroupsPerPage] = useState(25);

    const handleChangePage = (_, newPage) => setPage(newPage);
    const handleChangeRowsPerPage = (event) => {
        setGroupsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // **FILTER & SORT DATA**
    const filteredAndSortedData = useMemo(() => {
        let filteredData = groups
            .map(group => {
                const query = searchQuery?.toLowerCase() || "";

                // Filter child assets
                const filteredChildren = group.children.filter(asset =>
                    asset.assetNumber === query ||
                    asset.assetName.toLowerCase().includes(query) ||
                    formatDate(asset.purchaseDate).includes(query) ||
                    asset.originalPrice.toString().includes(query) ||
                    asset.depreciationPercentage.toString().includes(query)
                );

                // Include the group if it matches or has matching children
                if (group.assetGroupName.toLowerCase().includes(query) || filteredChildren.length > 0) {
                    return { ...group, children: filteredChildren };
                }

                return null;
            })
            .filter(group => group !== null); // Remove null entries

        // **Sorting Logic**
        if (sortConfig.key) {
            filteredData.sort((a, b) => {
                const aValue = a[sortConfig.key] || "";
                const bValue = b[sortConfig.key] || "";

                if (sortConfig.type === "number") {
                    return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
                } else if (sortConfig.type === "date") {
                    return sortConfig.direction === "ascending"
                        ? new Date(aValue) - new Date(bValue)
                        : new Date(bValue) - new Date(aValue);
                } else {
                    return sortConfig.direction === "ascending"
                        ? aValue.localeCompare(bValue)
                        : bValue.localeCompare(aValue);
                }
            });
        }

        return filteredData;
    }, [groups, searchQuery, sortConfig]);


    const handleSortChange = (key, type) => {
        setSortConfig(prev => ({
            key,
            type,
            direction: prev.key === key && prev.direction === "ascending" ? "descending" : "ascending",
        }));
    };

    // Slice for pagination
    const currentRows = useMemo(() => {
        return filteredAndSortedData.slice(page * groupsPerPage, (page + 1) * groupsPerPage);
    }, [filteredAndSortedData, page, groupsPerPage]);


    return (
        <>
            <TableContainer component={Paper} style={{ height: "100%" }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            {["AssetName", "PurchaseDate", "OriginalPrice", "DepreciationPercentage"].map((col) => (
                                <TableCell key={col} sx={headerStyle} align="center">
                                    <TableSortLabel
                                        active={sortConfig.key === col}
                                        direction={sortConfig.direction}
                                        onClick={() => handleSortChange(col, col === "PurchaseDate" ? "date" : "string")}
                                    >
                                        {t(col)}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentRows.map((row) => (
                            <React.Fragment key={row.id}>
                                <Tooltip title={t('DoubleClickToEdit')} placement="top">
                                    <TableRow sx={getRowSx(row)} onDoubleClick={() => onRowClick(row.id, 'assetGroup')}>
                                        <TableCell colSpan={3} align="center" style={{ position: 'relative' }}>
                                            {row.children.length > 0 && (
                                                <IconButton size="small"
                                                    style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                                                    onClick={() => handleExpandClick(row.id)}>

                                                </IconButton>
                                            )}
                                            {row.id === "NoGroup" ? t('NoGroup') : row.assetGroupName}
                                        </TableCell>
                                        <TableCell align="center">{row.depreciationPercentage != null ? `${row.depreciationPercentage}%`: "-"}</TableCell>
                                    </TableRow>
                                </Tooltip>

                                {
                                    row.children.map((asset) => (
                                        <TableRow key={asset.id} sx={getRowSx(asset)} onClick={() => onRowClick(asset.id, 'asset')}>
                                            <TableCell align="center">{asset.assetName}</TableCell>
                                            <TableCell align="center">{formatDate(asset.purchaseDate)}</TableCell>
                                            <TableCell align="center">{asset.originalPrice}</TableCell>
                                            <TableCell align="center">{asset.depreciationPercentage + '%'}</TableCell>
                                        </TableRow>
                                    ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[25, 50, 100, 200]}
                component="div"
                count={filteredAndSortedData.length}
                rowsPerPage={groupsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t("RowsPerPage")}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t("of")} ${count}`}
            />
        </>
    );
}

// Helper: Group assets by assetGroupId
function groupAssetsByGroup(assets, assetGroups) {
    const grouped = {};

    assetGroups.forEach(group => {
        grouped[group.assetGroupId] = {
            id: group.assetGroupId,
            assetGroupName: group.name,
            depreciationPercentage: group.depreciationPercentage ? group.depreciationPercentage : 0,
            children: []
        };
    });

    grouped["NoGroup"] = {
        id: "NoGroup",
        assetGroupName: "No Group",
        children: []
    };

    assets.forEach(asset => {
        const groupId = asset.assetGroupId;
        if (groupId && grouped[groupId]) {
            grouped[groupId].children.push({
                id: asset.id,
                assetName: asset.name,
                purchaseDate: asset.purchaseDate,
                originalPrice: asset.originalPrice,
                depreciationPercentage: asset.depreciationPercentage,
                isSubRow: true
            });
        } else {
            grouped["NoGroup"].children.push({
                id: asset.id,
                assetName: asset.name,
                purchaseDate: asset.purchaseDate,
                originalPrice: asset.originalPrice,
                depreciationPercentage: asset.depreciationPercentage,
                isSubRow: true
            });
        }
    });

    // Return ALL groups, regardless of child count
    return Object.values(grouped);
};

// Helper function to format date
const formatDate = (dateString) => (!dateString ? "-" : new Date(dateString).toLocaleDateString());

export default AssetTable;
