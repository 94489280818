import React, { useState, useEffect, useRef, useContext } from 'react';
import {
    TextField,
    Autocomplete,
    FormControl,
    Select,
    MenuItem,
    Portal,
    Popper,
    Tooltip,
    IconButton,
    Button,
    FormControlLabel,
    Radio,

} from '@mui/material';
import Draggable from 'react-draggable';
import SubAccountantService from '../../Services/subAccountant.service';
import Iframe from 'react-iframe';
import { useTranslation } from 'react-i18next';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import CustomMonthDatePicker from '../Widgets/CustomDates/CustomMonthDatePicker/CustomMonthDatePicker';
import CustomDeleteConfirmationModal from '../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal';
import DocumentTransferDialog from '../DocumentTransferDialog/DocumentTransferDialog';
import SaveIcon from '@mui/icons-material/SaveAsRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import '../Document/Document.css';
import './EditModal.css';
import AddAccountCardModal from '../Widgets/AddAccountCardModal/AddAccountCardModal';
import EnumsService from '../../Services/enums.service';
import AutocompleteCardSelect from '../Widgets/AutocompleteCardSelect/AutocompleteCardSelect'
import RadioGroup from '@mui/material/RadioGroup';
import dayjs, { Dayjs } from 'dayjs';
import { styled } from '@mui/system';
import { calculateVatAmount, getVatByCaseType, getVatValue } from '../../Utils/GetVatValue';
import { ToastContainer, toast } from "react-toastify";
import Constants from '../../assests/Constants/constants';
import { PermissionsContext } from '../../Contexts/Permissions';

const StyledListItem = styled('li')({
    '&:hover': {
        backgroundColor: '#F5F5F6', // Hover color
        cursor: 'pointer', // Optional: Changes the cursor to pointer
    },
});
const StyledPopper = styled(Popper)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 'var(--Spacing-md, 8px)',
        background: 'var(--white, #FFF)',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.32)',
    },
    '& .MuiAutocomplete-listbox': {
        padding: theme.spacing(1),
    },
    '& .MuiAutocomplete-option': {
        borderRadius: 'var(--Spacing-sm, 4px)',
        '&[aria-selected="true"], &.Mui-focused, &:hover': {
            background: 'var(--orange, #F9AA2A) !important',
        },
    },
}));
function EditModalForSingleEntry(props) {
    const {
        editedItem,
        subAccountantOptions,
        NoDocumentsPdf,
        isRange,
        isClosedReportingMonth,
        accountCardOptions,
        handleChange,
        statusCheck,
        handleRadioChange,
        editedAddAccountCard,
        sortCodeIdOptions,
        handleUpdate,
        handleCancel,
        handleDeleteClick,
        client,
        token,
        setLoading,
        setEditedAddAccountCard,
        setEditedItem,
        palCodes,
        RefreshPage,
        showIframe = true,
        fetchEnums
    } = props;

    const { i18n, t } = useTranslation();
    // const direction = i18n.dir();
    const [container] = useState(() => document.createElement('div'));
    const nodeRef = useRef(null);
    const [showPdfIframe, setShowPdfIframe] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [creditDebitSearchTerm, setCreditDebitSearchTerm] = useState('');
    const [selectedAccountCard, setSelectedAccountCard] = useState(null);
    const [newAccountCard, setNewAccountCard] = useState({ accountCodeName: '', accountCodeId: "" });
    const [isEditAccountCard, setIsEditAccountCard] = useState(false);
    const [direction, setDirection] = useState('rtl');
    const { userTypeContext, permissionsState } = useContext(PermissionsContext);

    const [currentVat, setCurrentVat] = useState(0);
    const [beforeVatAmount, SetBeforeVatAmount] = useState(0);
    useEffect(() => {
        // Update direction based on the current language
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);

    const [subAccountants, setSubAccountants] = useState([]);
    const [subAccountantNewOptions, setSubAccountantNewOptions] = useState([]);
    const [accountant, setAccountant] = useState({});

    const fetchSubAccountants = async () => {
        const result2 = await SubAccountantService.GetSubAccountantByAccountantId(token, client.accountantId);
        const accountantResult = await SubAccountantService.GetAccountantById(token, client.accountantId);
        const enhancedAccountantResult = {
            ...accountantResult,
            subAccountantId: accountantResult.accountantId, // Add subAccountantId as same as accountantId
        };
        setAccountant(enhancedAccountantResult);

        if (result2) {
            const combinedSubAccountants = [enhancedAccountantResult, ...result2];

            // If client exists, add it to the combined list
            if (client) {
                combinedSubAccountants.push({
                    subAccountantId: client.caseId,
                    name: client.name,
                });
            }

            setSubAccountants(combinedSubAccountants);

            const combinedOptions = combinedSubAccountants.map((acc) => ({
                value: acc.subAccountantId,
                label: acc.name,
            }));

            setSubAccountantNewOptions(combinedOptions);
        } else {
            toast.error(t('fetchSubAccountantsDataError'));
        }
    }


    useEffect(() => {
        // Update direction based on the current language
        if (!subAccountantOptions) {
            fetchSubAccountants();

        } else {
            setSubAccountantNewOptions(subAccountantOptions);
        }
    }, [subAccountantOptions]);

    const [selectedAccount, setSelectedAccount] = useState(() => {
        if (!editedItem?.accountCodeName) return '';

        const selectedOption = accountCardOptions.find(option => option.cardId === editedItem.accountCodeId);
        return selectedOption ? selectedOption : null;
    });

    const [oldSelectedAccountCardItem, setOldSelectedAccountCardItem] = useState({
        cardId: "",
        caseId: "",
        accountCaseId: "",
        name: "",
        palCode: '',
        client: null
    });
    const [oldCreditDebitSelectedAccountCardItem, setOldCreditDebitSelectedAccountCardItem] = useState({
        creditAccount: "",
        debitAccount: "",
    });
    const [showAccountCardInputsEdit, setShowAccountCardInputsEdit] = useState(false);
    const [isAddAccountCard, setIsAddAccountCard] = useState(false);
    const [accountCardIdToDelete, setAccountCardIdToDelete] = useState('');
    const [palCode, setPalCode] = useState('');
    const [newCardId, setNewCardId] = useState(null);
    const [item, setItem] = useState({ ...editedItem });
    const [addAccountCardType, setAddAccountCardType] = useState('main');


    const [cardIdOption, setCardIdOption] = useState(-1);

    useEffect(() => {

        if (accountCardOptions && accountCardOptions.length > 0) {
            const maxCardId = Math.max(...accountCardOptions.slice(1).map(option => parseInt(option.cardId, 10)));
            setCardIdOption(maxCardId + 1);


        }
    }, [accountCardOptions]);

    useEffect(() => {
        setShowPdfIframe(showIframe)
    }, [showIframe]);

    useEffect(() => {
        document.body.appendChild(container);
        return () => {
            try {
                document.body.removeChild(container);
            } catch (e) {
                // Ignore
            }
        };
    }, [container]);

    useEffect(() => {
        let account = null;

        if (editedItem.accountCodeId) {
            account = accountCardOptions.find(account => account.cardId === editedItem.accountCodeId);
        }
        if (!account && editedItem.accountCodeName) {
            account = accountCardOptions.find(account => account.name === editedItem.accountCodeName);
        }
        if (!account && editedItem.accountCaseId) {
            account = accountCardOptions.find(account => account.caseId === editedItem.accountCaseId);
        }


        if (editedItem.docType === 'Incomes') {
            setPalCode("לקוחות");
        }
        else if (editedItem.docType === 'Expenses') {
            setPalCode("ספקים");

        }
        else {
            setPalCode("");
        }
        setSelectedAccount(account);
    }, [editedItem.uploadNumber]);


    useEffect(() => {

        if (accountCardOptions && accountCardOptions.length > 0 && newCardId) {

            const selectedOption = accountCardOptions.find(option => option.cardId === newCardId.cardId);

            if (newCardId.type === 'main') {
                handleMuiChange(null, selectedOption);
            }
            else {

                handleChange(newCardId.type, newCardId.cardId);
            }
            setNewCardId(null);
        }
    }, [accountCardOptions, newCardId]);


    useEffect(() => {
        if (editedItem.vat === 16 || editedItem.vat === 17) {
            setCurrentVat(getVatValue(editedItem.documentDate, false, true));
        } else {
            setCurrentVat(getVatValue(editedItem.documentDate, false, false));
        }
        SetBeforeVatAmount(
            Math.floor(editedItem?.amount - calculateVatAmount(editedItem?.amount, editedItem.vat, editedItem.documentDate))
        );
    }, [editedItem]);

    const handleMuiChange = (event, newValue) => {
        if (newValue && newValue.value1 === 'AddItem') {
            setAddAccountCardType('main');
            setEditedAddAccountCard(true);
            setOldSelectedAccountCardItem({
                cardId: editedItem.accountCodeId,
                caseId: client.caseId,
                accountCaseId: editedItem.accountCaseId,
                name: editedItem.accountCodeName,
                palCode: '',
                client: null
            });
            setEditedItem({ ...editedItem, accountCodeName: newValue.searchTerm || '', accountCodeId: cardIdOption, accountCaseId: '' });
        } else {
            setSelectedAccount(newValue);
            const selectedOption = accountCardOptions.find(option => option.cardId === (newValue ? newValue.cardId : ''));
            setEditedItem({
                ...editedItem,
                accountCodeName: selectedOption ? selectedOption.name : '',
                accountCodeId: selectedOption ? selectedOption.cardId : '',
                accountCaseId: selectedOption ? selectedOption.accountCaseId : '',
                ...(editedItem.docType === 'Incomes'
                    ? { debitAccount: selectedOption ? selectedOption.cardId : '' } :
                    editedItem.docType === 'Expenses' && { creditAccount: selectedOption ? selectedOption.cardId : '' })
            });
        }
    };

    const handleToggleIframe = () => {
        setShowPdfIframe((prev) => !prev);
    };
    const handleChangeAccount = (field, value) => {
        setEditedItem({ ...editedItem, [field]: value });
    }
    const handleCloseItem = () => {
        setPalCode('');
        setShowAccountCardInputsEdit(false);
        setEditedAddAccountCard(false);
        setSelectedAccount(oldSelectedAccountCardItem);
        handleChangeAccount('accountCodeId', oldSelectedAccountCardItem.cardId?.toString());
        handleChangeAccount('accountCodeName', oldSelectedAccountCardItem.name?.toString());
        handleChangeAccount('accountCaseId', oldSelectedAccountCardItem.accountCaseId?.toString());
    };

    // Input style
    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        },
    };

    const inputStyle = {
        style: {
            textAlign: direction === 'ltr' ? 'left' : 'right',
        },
    };

    if (!editedItem || Object.keys(editedItem).length === 0) {
        return null;
    }

    const formattedAccountCardOptions = accountCardOptions.map((option) => ({
        value: option.cardId,
        label: option.accountCaseId
            ? `${option.name} - ${option?.accountCaseId || ''}`
            : `${option.name}`,
        name: option.name,
        accountCaseId: option.accountCaseId || '',
        cardId: option.cardId,
        value1: option.value1,
        palCode: option.palCode,
        isEditable: option.isEditable
    }));

    const handleNoOptionClick = (searchTerm) => {


        // Clone the first item and add searchTerm as a property
        const newOption = {
            ...formattedAccountCardOptions[0],
            searchTerm: searchTerm
        };

        // Call handleMuiChange with the new option
        handleMuiChange(null, newOption);
    };

    const handleNoOptionCreditDebitClick = (searchTerm, type, documentType) => {

        const newOption = {
            ...formattedAccountCardOptions[0],
            searchTerm: searchTerm
        };
        handleCreditDebitForJournalEntry(newOption, type)
    };
    const handleChangeAfterAdd = (cardId, type) => {
        ///set type
        const newCard = {
            cardId,
            type
        }
        setNewCardId(newCard);
    }


    const handleCreditDebitForJournalEntry = (card, type) => {


        if (!card) {
            setEditedItem({ ...editedItem, [type]: '', });
            return;
        }

        if (card.value1 === 'AddItem') {
            setEditedAddAccountCard(true);

            // Save the old selected account card item based on the type (credit or debit)
            setOldCreditDebitSelectedAccountCardItem({
                [type]: editedItem[type], // Saves the current credit or debit account ID
            });
            setAddAccountCardType(type);
            setIsEditAccountCard(false);
            // Set the specific account type to an empty string
            setNewAccountCard({ accountCodeName: card.searchTerm || '', accountCodeId: cardIdOption })
            // setEditedItem({ ...editedItem, [type]: '', });
        } else {
            handleChange(type, card.cardId);
        }
    };

    const updateAccountFields = (newValue, accountType) => {

        if (newValue?.value1 === 'AddItem') {
            handleNoOptionCreditDebitClick('', accountType, editedItem.docType);
            return;
        }

        const isIncomesDebit = editedItem.docType === 'Incomes' && accountType === 'debitAccount';
        const isExpensesCredit = editedItem.docType === 'Expenses' && accountType === 'creditAccount';
        const updatedFields = {
            accountCodeId: isIncomesDebit || isExpensesCredit ? (newValue ? newValue.cardId : '') : '',
            accountCodeName: isIncomesDebit || isExpensesCredit ? (newValue ? newValue.name : '') : '',
            accountCaseId: isIncomesDebit || isExpensesCredit ? (newValue ? newValue.accountCaseId : '') : '',
        };

        // Update the edited item with the new fields
        setEditedItem((prev) => ({
            ...prev,
            [accountType]: newValue ? newValue.cardId : '',
            ...(isIncomesDebit || isExpensesCredit ? updatedFields : {}),
        }));
    };

    const EditAccountCard = (selectedItem, type) => {

        if (!selectedItem) {
            console.warn("No account card selected for editing.");
            return;
        }


        setPalCode(selectedItem.palCode);
        setNewAccountCard({ accountCodeName: selectedItem.name || '', accountCodeId: selectedItem.cardId, accountCaseId: selectedItem.accountCaseId || '' })
        setIsEditAccountCard(true);
        setEditedAddAccountCard(true);
        setAddAccountCardType(type);
    };



    return (
        <Portal container={container}>
            <Draggable handle=".handle" nodeRef={nodeRef}>
                <div
                    ref={nodeRef}
                    style={{
                        position: 'absolute',
                        top: '10%',
                        left: '20%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        padding: '16px',
                        minHeight: '76vh',
                        maxHeight: '90vh',
                        overflowY: 'auto',
                        // Adjust the width based on showIframe
                        minWidth: showPdfIframe ? '1200px' : '700px',
                        zIndex: 1300,
                        display: 'flex',
                        flexDirection: 'row',
                        direction: direction,
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)', // Adds shadow
                        border: '1px solid #304fff',
                    }}
                >
                    {/* Document (Iframe) */}

                    {/* Form Content */}
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        {editedItem?.processedBy && subAccountantNewOptions.length > 0 && (
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: '6px', // Position below the RadioGroup
                                    right: showPdfIframe ? '2.5%' : '2.5%', // Align to the left
                                    fontSize: '14px',
                                    padding: '2px 6px',
                                    whiteSpace: 'nowrap', // Prevent text from wrapping
                                    textOverflow: 'hidden', // Add "..." if text is too long
                                    maxWidth: 'fit-content', // Let it expand naturally
                                }}
                            >
                                {t('DocumentProcessedBy')}: {subAccountantNewOptions.find(option => option.value === editedItem.processedBy).label}
                            </div>
                        )}
                        <div
                            className="handle"
                            style={{
                                cursor: 'move',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                marginBottom: '16px',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Tooltip title={t('Exit')}>
                                <IconButton onClick={handleCancel}>
                                    <CancelIcon style={{ color: '#304FFF' }} />
                                </IconButton>
                            </Tooltip>

                            <div
                                className="edit-title-container"
                                style={{ display: 'flex', alignItems: 'center', gap: '10px', margin: '0 auto' }}
                            >
                             
                                <div className="edit-title">
                                    {t('Document')}    {editedItem.uploadNumber}
                                </div>
                            
                            </div>

                            <div>
                                <IconButton onClick={handleToggleIframe}>
                                    {showPdfIframe ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            </div>
                        </div>
                        {/* Modal Content */}
                        <div className={`form-container ${direction}`}>
                            {/* Row 1 */}
                            <div className="form-row">
                                {/* {

                                    showPdfIframe &&
                                    <div className="edit-form-row-item">
                                        <div className="edit-form-row-item-title">
                                            {type === 'Incomes' ? t('Client Name') : t('Provider Name')}
                                            <span className="required-asterisk">*</span>
                                        </div>
                                        <Autocomplete
                                            options={formattedAccountCardOptions}
                                            getOptionLabel={(option) => option.label}
                                            value={
                                                formattedAccountCardOptions.find(
                                                    (option) => option.value === editedItem.accountCodeId
                                                ) || null
                                            }
                                            onChange={handleMuiChange}
                                            onInputChange={(event, inputValue) => {
                                                // Store the input value to pass to the function if needed
                                                setSearchTerm(inputValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder={
                                                        type === 'Incomes'
                                                            ? t('Client Name')
                                                            : t('Provider Name')
                                                    }
                                                    variant="standard"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        ...inputPropsStyle,
                                                    }}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        ...inputStyle,
                                                    }}
                                                    required
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <li {...props}>{option.label}</li>
                                            )}
                                            // Custom Popper to handle display direction
                                            PopperComponent={(props) => (
                                                <Popper
                                                    {...props}
                                                    style={{
                                                        ...props.style,
                                                        direction: direction,
                                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.7)',
                                                    }}
                                                />
                                            )}
                                            noOptionsText={
                                                <Button
                                                    onClick={() => handleNoOptionClick(searchTerm)}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    {t('AddAccountCard')} "{searchTerm}"
                                                </Button>
                                            }
                                            disabled
                                        />
                                      
                                    </div>
                                } */}

                                {/* Account Case ID */}
                                <div className="edit-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('CompanyId')}
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <TextField
                                        fullWidth
                                        placeholder={t('CompanyId')}
                                        value={editedItem.accountCaseId || ''}
                                        onChange={(e) => handleChange('accountCaseId', e.target.value)}
                                        variant="standard"
                                        InputProps={inputPropsStyle}
                                        inputProps={inputStyle}
                                        disabled={showIframe}
                                    />
                                </div>
                                {/* MonthReportDate */}
                                <div className="edit-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('MonthReportDate')}
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <CustomMonthDatePicker
                                        date={
                                            editedItem.reportingMonthDate
                                                ? dayjs(new Date(editedItem.reportingMonthDate))
                                                : null
                                        }
                                        onDateChange={(date) => {
                                            if (date) {

                                                handleChange('reportingMonthDate', date.toISOString());
                                            }
                                        }}
                                        height="48px"
                                    />
                                </div>
                            </div>


                            {/* Row 2 */}
                            <div className="form-row">
                                {/* Reference */}
                                <div className="edit-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('Reference')}
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <TextField
                                        fullWidth
                                        placeholder={t('Reference')}
                                        value={editedItem.reference || ''}
                                        onChange={(e) => handleChange('reference', e.target.value)}
                                        variant="standard"
                                        InputProps={inputPropsStyle}
                                        inputProps={inputStyle}
                                        required
                                    />
                                </div>
                                {/* Document Date */}
                                <div className="edit-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('Document Date')}
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <CustomDatePicker
                                        date={
                                            editedItem.documentDate
                                                ? new Date(editedItem.documentDate)
                                                : null
                                        }
                                        onDateChange={(date) => {
                                            if (date) {
                                                handleChange('documentDate', date.toISOString());
                                            }
                                        }}
                                        height="48px"
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                {/* Confirmation Number */}
                                <div className="edit-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('ConfirmationNumber')}
                                    </div>
                                    <TextField
                                        fullWidth
                                        type='number'
                                        placeholder={t('ConfirmationNumber')}
                                        value={editedItem.confirmationNumber || ''}
                                        onChange={(e) =>
                                            handleChange('confirmationNumber', e.target.value)
                                        }
                                        variant="standard"
                                        InputProps={inputPropsStyle}
                                        inputProps={inputStyle}
                                        required
                                    />
                                </div>

                                {/* before vat */}
                                <div className="edit-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('AmountBeforeVAT')}
                                    </div>
                                    <TextField
                                        fullWidth
                                        placeholder={t('AmountBeforeVAT')}
                                        value={beforeVatAmount}
                                        disabled
                                        variant="standard"
                                        InputProps={inputPropsStyle}
                                        inputProps={inputStyle}
                                    />
                                </div>
                            </div>
                            {/* Row 3 */}
                            <div className="form-row">
                                {/* Upload Date */}
                                <div className="edit-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('Upload Date')}
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <CustomDatePicker
                                        date={
                                            editedItem.uploadDate
                                                ? new Date(editedItem.uploadDate)
                                                : null
                                        }
                                        onDateChange={(date) => {
                                            if (date) {
                                                handleChange('uploadDate', date.toISOString());
                                            }
                                        }}
                                        height="48px"
                                    />
                                </div>
                                {/* Vat */}
                                <div className="edit-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('VAT')} {currentVat * 100}%
                                    </div>
                                    <TextField
                                        fullWidth
                                        placeholder={t('VAT')}
                                        value={editedItem.amount - beforeVatAmount}
                                        variant="standard"
                                        disabled
                                        InputProps={inputPropsStyle}
                                        inputProps={inputStyle}
                                    />
                                </div>
                            </div>
                            {/* Row 4 */}
                            <div className="form-row">
                                {/* Description */}
                                <div className="edit-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('Description')}
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <TextField
                                        fullWidth
                                        placeholder={t('Description')}
                                        value={editedItem.description || ''}
                                        onChange={(e) => handleChange('description', e.target.value)}
                                        variant="standard"
                                        InputProps={inputPropsStyle}
                                        inputProps={inputStyle}
                                        required
                                    />
                                </div>
                                {/* Amount */}
                                <div className="edit-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('TotalAmountIncludingVAT')}
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <TextField
                                        fullWidth
                                        placeholder={t('Amount')}
                                        value={editedItem.amount || ''}
                                        onChange={(e) => handleChange('amount', e.target.value)}
                                        variant="standard"
                                        type="number"
                                        InputProps={inputPropsStyle}
                                        inputProps={inputStyle}
                                        required
                                    />
                                </div>
                            </div>
                            {/* Row 5 */}
                            <div className="form-row">

                                {/* Sort Code ID */}
                                <div className="edit-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('Code ID')}
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <Autocomplete
                                        options={sortCodeIdOptions}
                                        getOptionLabel={(option) =>
                                            `${option.sortCodeId} - ${option.sortCodeName}`
                                        }
                                        value={
                                            sortCodeIdOptions.find(
                                                (option) => option.sortCodeId === editedItem.sortCodeId
                                            ) || null
                                        }
                                        onChange={(event, newValue) => {
                                            const newSortCodeId = newValue
                                                ? newValue.sortCodeId
                                                : '';
                                            handleChange('sortCodeId', newSortCodeId);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={t('Code ID')}
                                                variant="standard"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    ...inputPropsStyle,
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    ...inputStyle,
                                                }}
                                                required
                                            />
                                        )}
                                        PopperComponent={(props) => (
                                            <StyledPopper
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    direction: direction,
                                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.7)',
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                {/* VAT */}
                                <div className="edit-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('VATPercentage')}
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <FormControl fullWidth variant="standard">
                                        <Select
                                            fullWidth
                                            value={
                                                client?.caseType && getVatByCaseType(client.caseType) !== undefined
                                                    ? getVatByCaseType(client.caseType) // Lock VAT if the caseType matches an exempt type
                                                    : parseInt(editedItem.vat) // Otherwise, use the current VAT value
                                            }
                                            onChange={(e) => {
                                                if (client?.caseType && getVatByCaseType(client.caseType) !== undefined) {
                                                    // Prevent change and show toast
                                                    toast.error(
                                                        `${t('VAT')} ${client.caseType}`,
                                                        { autoClose: 3000 }
                                                    );
                                                } else {
                                                    handleChange('vat', parseInt(e.target.value)); // Allow editing for non-exempt types
                                                }
                                            }}
                                            disableUnderline
                                            className="custom-input-box-sizing-toggle"
                                            sx={{
                                                height: '48px',
                                                padding: '0 10px',
                                                borderRadius: '8px',
                                                background: '#F5F5F6',
                                                border: '1px solid transparent',
                                                '&:focus-within': {
                                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                },
                                                textAlign: direction === 'ltr' ? 'right' : 'left',
                                            }}
                                            inputProps={{
                                                style: {
                                                    textAlign: direction === 'ltr' ? 'right' : 'left',
                                                },
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        direction: direction,
                                                    },
                                                },
                                            }}
                                            required
                                        >
                                            {Constants.VATPercentage.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option === 16 ? t('AccountingTax') : `${option}%`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>

                            </div>
                            {/* Row 6 */}
                            <div className="form-row">
                                {/* Debit Account */}
                                <div className="edit-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('Debit Account')}
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <Autocomplete
                                        options={formattedAccountCardOptions}
                                        getOptionLabel={(option) => `${option.cardId} - ${option.name}`}
                                        value={
                                            formattedAccountCardOptions.find((option) => option.cardId === editedItem.debitAccount) || null
                                        }
                                        onChange={(event, newValue) => {
                                            const newDebitAccount = newValue ? newValue.cardId : null;
                                            if (editedItem.docType === 'JournalEntries') {
                                                handleCreditDebitForJournalEntry(newValue, 'debitAccount');
                                            } else {
                                                updateAccountFields(newValue, 'debitAccount');
                                            }
                                        }}
                                        onInputChange={(event, inputValue) => {
                                            setCreditDebitSearchTerm(inputValue);
                                        }}
                                        noOptionsText={
                                            <Button
                                                onClick={() =>
                                                    handleNoOptionCreditDebitClick(creditDebitSearchTerm, 'debitAccount', editedItem.docType)
                                                }
                                                variant="contained"
                                                color="primary"
                                            >
                                                {t('AddAccountCard')} "{creditDebitSearchTerm}"
                                            </Button>
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={t('Debit Account')}
                                                variant="standard"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    ...inputPropsStyle,
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    ...inputStyle,
                                                }}
                                                required
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <Tooltip
                                                title={option.isEditable ? t("RightClickToEdit") : t("NotEditable")}
                                                placement="top"
                                            >
                                                <StyledListItem
                                                    {...props}

                                                    onContextMenu={(event) => {
                                                        if (!option.isEditable) {
                                                            event.preventDefault();
                                                            return;
                                                        }
                                                        event.preventDefault();
                                                        EditAccountCard(option, 'debitAccount');
                                                    }}
                                                >
                                                    {`${option?.cardId || ''} - ${option.name}`}
                                                </StyledListItem>
                                            </Tooltip>
                                        )}
                                        PopperComponent={(props) => (
                                            <StyledPopper
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    direction: direction,
                                                }}
                                            />
                                        )}
                                        disabled={editedItem.sortCodeId === '2'} // Dynamically disable the component
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                backgroundColor: editedItem.sortCodeId === '2' ? '#a9a9a9' : 'f5f5f6',
                                                cursor: editedItem.sortCodeId === '2' ? 'not-allowed' : 'pointer',
                                            },
                                            '& .Mui-disabled': {
                                                color: '#A9A9A9', // Gray out the text
                                            },
                                        }}
                                    />
                                </div>
                                {
                                    (userTypeContext?.toString() !== Constants.UserType.Client || permissionsState.Premium) &&
                                    <div className="edit-form-row-item" >
                                        <div className="edit-form-row-item-title" style={{ marginRight: '16px' }}>
                                            {t('status')}
                                            <span className="required-asterisk">*</span>
                                        </div>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-form-control-label-placement"
                                            name="position"
                                            defaultValue="top"
                                            onChange={handleRadioChange}
                                            sx={{
                                                height: '48px',
                                                padding: '0 10px',
                                                borderRadius: '8px 8px 0px 8px',
                                                background: '#F5F5F6',
                                                justifyContent: 'center',
                                                gap: '30px',
                                                border: '1px solid transparent',
                                                '&:focus-within': {
                                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                },
                                            }}

                                        >
                                            <FormControlLabel
                                                value="notDone"
                                                control={<Radio style={{ height: '48px', padding: '0 8px' }} />}
                                                label={t('unprocessed')}
                                                labelPlacement="top"
                                                checked={!statusCheck}
                                                disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                style={{ margin: 0, height: '48px' }}
                                            />
                                            <FormControlLabel
                                                value="done"
                                                control={<Radio style={{ height: '48px', padding: '0 8px' }} />}
                                                label={t('processed')}
                                                labelPlacement="top"
                                                checked={statusCheck}
                                                disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                style={{ margin: 0, height: '48px' }}
                                            />

                                        </RadioGroup>
                                    </div>
                                }

                            </div>
                            {/* Row 7*/}
                            <div className="form-row">
                                {/* Credit Account */}
                                {/* <div className="edit-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('MonthReportDate')}
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <CustomMonthDatePicker
                                        date={
                                            editedItem.reportingMonthDate
                                                ? dayjs(new Date(editedItem.reportingMonthDate))
                                                : null
                                        }
                                        onDateChange={(date) => {
                                            if (date) {

                                                handleChange('reportingMonthDate', date.toISOString());
                                            }
                                        }}
                                        height="48px"
                                    />
                                </div> */}

                                {/* Credit Account */}
                                <div className="edit-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('Credit Account')}
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <Autocomplete
                                        options={formattedAccountCardOptions}
                                        getOptionLabel={(option) => `${option.cardId} - ${option.name}`}
                                        value={
                                            formattedAccountCardOptions.find((option) => option.cardId === editedItem.creditAccount) || null
                                        }
                                        onChange={(event, newValue) => {
                                            const newCreditAccount = newValue ? newValue.cardId : null;
                                            if (editedItem.docType === 'JournalEntries') {
                                                handleCreditDebitForJournalEntry(newValue, 'creditAccount');
                                            } else {
                                                updateAccountFields(newValue, 'creditAccount');
                                            }
                                        }}
                                        onInputChange={(event, inputValue) => {
                                            setCreditDebitSearchTerm(inputValue);
                                        }}
                                        noOptionsText={
                                            <Button
                                                onClick={() =>
                                                    handleNoOptionCreditDebitClick(creditDebitSearchTerm, 'creditAccount', editedItem.docType)
                                                }
                                                variant="contained"
                                                color="primary"
                                            >
                                                {t('AddAccountCard')} "{creditDebitSearchTerm}"
                                            </Button>
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={t('Credit Account')}
                                                variant="standard"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    ...inputPropsStyle,
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    ...inputStyle,
                                                }}
                                                required
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <Tooltip
                                                title={option.isEditable ? t("RightClickToEdit") : t("NotEditable")}
                                                placement="top"
                                            >
                                                <StyledListItem
                                                    {...props}
                                                    onContextMenu={(event) => {
                                                        if (!option.isEditable) {
                                                            event.preventDefault();
                                                            return;
                                                        }
                                                        event.preventDefault();
                                                        EditAccountCard(option, 'creditAccount');
                                                    }}
                                                >
                                                    {`${option?.cardId || ''} - ${option.name}`}
                                                </StyledListItem>
                                            </Tooltip>
                                        )}
                                        PopperComponent={(props) => (
                                            <StyledPopper
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    direction: direction,
                                                }}
                                            />
                                        )}
                                        disabled={editedItem.sortCodeId === '1'} // Dynamically disable the component
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                backgroundColor: editedItem.sortCodeId === '1' ? '#a9a9a9' : 'f5f5f6',
                                                cursor: editedItem.sortCodeId === '1' ? 'not-allowed' : 'pointer',
                                            },
                                            '& .Mui-disabled': {
                                                color: '#A9A9A9', // Gray out the text
                                            },
                                        }}
                                    />
                                </div>



                                {/* Save and Cancel Buttons */}
                                <div className="edit-form-row-item" >
                                    <div className="edit-form-row-item-title" style={{ marginRight: '16px' }}>
                                        {t('Actions')}
                                    </div>
                                    <div style={{
                                        height: '48px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        justifyContent: 'center',
                                        gap: '15px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                        <Tooltip title={t('Save')}>
                                            <IconButton disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth} onClick={() => handleUpdate(editedItem)}>
                                                <SaveIcon style={{ color: '#304FFF' }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={t('Delete')}>
                                            <IconButton disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth} onClick={() => handleDeleteClick(editedItem.id)}>
                                                <DeleteIcon style={{ color: '#304FFF' }} />
                                            </IconButton>
                                        </Tooltip>
                                        {
                                            (userTypeContext?.toString() !== Constants.UserType.Client || permissionsState.Premium) &&
                                            <DocumentTransferDialog
                                                editedItem={editedItem}
                                                token={token}
                                                setLoading={setLoading}
                                                RefreshPage={RefreshPage}
                                                t={t}
                                                client={client}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* Save and Cancel Buttons */}
                            <div className="handle" style={{ display: 'block', height: '10px', width: '100%', marginTop: '10px' }}>
                            </div>
                        </div>
                    </div>
                    {showPdfIframe && (
                        <div
                            // className="iframe-container"
                            style={{
                                flex: 1,
                                // marginRight: direction === 'rtl' ? '16px' : '0',
                                // marginLeft: direction === 'ltr' ? '16px' : '0',
                            }}
                        >
                            <Iframe
                                url={
                                    editedItem.documentUrl
                                        ? `${editedItem.documentUrl}#page=1&view=FitH`
                                        : `${NoDocumentsPdf}#page=1&view=FitH`
                                }
                                width="100%"
                                height="100%"
                                id={editedItem.id}
                                position="relative"
                                title={editedItem.title}
                                allowFullScreen
                            />
                        </div>
                    )}
                </div>
            </Draggable>
            <CustomDeleteConfirmationModal
                isVisible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                onDelete={() => { }}
                confirmMessage={t('confirmDeleteMessage')}
                titleMessage={t('confirmDeleteTitle')}
            />
            <AddAccountCardModal
                accountCardOptions={accountCardOptions}
                open={editedAddAccountCard}
                onClose={handleCloseItem}
                editedItem={newAccountCard}
                isEdit={isEditAccountCard}
                isRange={isRange}
                isClosedReportingMonth={isClosedReportingMonth}
                palCodes={palCodes}
                propPalCode={palCode}
                setPalCode={setPalCode}
                direction={direction} // Pass direction for styling
                isAdd={!showAccountCardInputsEdit}
                client={client}
                token={token}
                setLoading={setLoading}
                handleChangeAfterAdd={handleChangeAfterAdd}
                fetchEnums={fetchEnums}
                type={addAccountCardType}
            />
        </Portal >
    );
}

export default EditModalForSingleEntry;
