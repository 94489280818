import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box, Button } from '@mui/material';
import { t } from 'i18next';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import PythonService from '../../../Services/pythonScripts.service';
import { beautifyNumber } from '../../../Utils/FormatNumber';
import TocIcon from '@mui/icons-material/Toc';
import { FormControlLabel, Checkbox, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { styled, darken, lighten } from "@mui/material/styles";
import * as XLSX from 'xlsx';
import JSON5 from 'json5';
import { useTranslation } from 'react-i18next';
import Constants from '../../../assests/Constants/constants';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '10px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};
const transactionsPerPage = 13;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
    ({ theme, width, height }) => ({
        marginBottom: "20px",
        "& .MuiToggleButton-root": {
            width: "120px",
            fontSize: "12px",
            fontWeight: "900",

            backgroundColor: "#ffffff", // Button background color
            transition: "background-color 0.3s, transform 0.3s", // Smooth transition effects
            "&:hover": {
                backgroundColor: "#e0e0e0", // Change background color on hover
                transform: "scale(1.05)", // Slightly increase size on hover
            },
            "&.Mui-selected": {
                backgroundColor: "#304FFF", // Selected button background color
                color: "#fff", // Selected button text color
                borderColor: "#304FFF", // Selected button border color
                "&:hover": {
                    backgroundColor: "#0069d9", // Darker shade on hover when selected
                },
            },
        },
    })
);



const TrialBalanceReportHtml = ({ open, handleClose, data, handleSelect, token, client, showAccountCardsWithZeros, setShowAccountCardsWithZeros }) => {

    class PalCodeAccountGroupDetails {
        constructor(name, count = 0, appeared = 0, totalInitDebit = 0, totalInitCredit = 0, totalDebit = 0, totalCredit = 0, totalBalance = 0) {
            this.name = name;
            this.count = count;
            this.appeared = appeared;
            this.totalInitDebit = totalInitDebit;
            this.totalInitCredit = totalInitCredit;
            this.totalDebit = totalDebit;
            this.totalCredit = totalCredit;
            this.totalBalance = totalBalance;
        }

        // Method to retrieve the object as JSON
        toJSON() {
            return {
                name: this.name,
                totalInitDebit: this.totalInitDebit,
                totalInitCredit: this.totalInitCredit,
                totalDebit: this.totalDebit,
                totalCredit: this.totalCredit,
                totalBalance: this.totalBalance
            };
        }
    }

    const [detailedTransactions, setDetailedTransactions] = useState(false);
    const [trialBalanceType, setTrialBalanceType] = useState("PalCodes");

    const [reportHtmlContent, setReportHtmlContent] = useState('');
    const [clientData, setClientData] = useState('');
    const [viewAccountCard, setViewAccountCard] = useState(false);
    const [viewPalcodes, setViewPalcodes] = useState(true);


    // Declare the total accumulators at the component level
    let totalInitDebitAccountReport = 0;
    let totalInitCreditAccountReport = 0;
    let totalDebitAccountReport = 0;
    let totalCreditAccountReport = 0;
    let totalBalanceReport = 0;
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const [showWithZeros, setShowWithZeros] = useState(true);

    const handleShowDetailsTransactions = (value) => {
        setDetailedTransactions(value);

    }

    const handleShow0CardsCheckbox = (value) => {
        setShowAccountCardsWithZeros(value);

    }

    const handleShowDetails = (value) => {
        setShowAccountCardsWithZeros(value);

    }

    const [showReport, setShowReport] = useState(false);
    const [profitAndLossNames, setProfitAndLossNames] = useState([]);

    const [accountingGroups, setAccountingGroups] = useState(null);
    const reportGenerated = useRef(false); // Ref to ensure generateReportHtml is called once
    let totalInitDebit = 0;
    let totalInitCredit = 0;
    let totalDebit = 0;
    let totalCredit = 0;
    let totalBalance1 = 0

    let profitAndLossTotalInitDebit = 0;
    let profitAndLossTotalInitCredit = 0;
    let profitAndLossTotalDebit = 0;
    let profitAndLossTotalCredit = 0;
    let profitAndLossTotalBalance1 = 0

    const initTotalValues = () => {
        totalInitDebitAccountReport = 0;
        totalInitCreditAccountReport = 0;
        totalDebitAccountReport = 0;
        totalCreditAccountReport = 0;
        totalBalanceReport = 0;

        totalInitDebit = 0;
        totalInitCredit = 0;
        totalDebit = 0;
        totalCredit = 0;
        totalBalance1 = 0;

        profitAndLossTotalInitDebit = 0;
        profitAndLossTotalInitCredit = 0;
        profitAndLossTotalDebit = 0;
        profitAndLossTotalCredit = 0;
        profitAndLossTotalBalance1 = 0
    }

    useEffect(() => {
        ;

        if (data) {
            const palCodeAccountGroupDict = {};
            let reachedProfitAndLoss = false;
            const newProfitAndLossNames = [];

            data.transaction.forEach(item => {
                const accountGroupName = item.palCodeAccountGroup;
                const palCodeType = item.palCodeType;

                if (palCodeType === Constants.ResultAccountName) {
                    reachedProfitAndLoss = true;
                    if (!newProfitAndLossNames.includes(accountGroupName)) {
                        newProfitAndLossNames.push(accountGroupName);
                    }
                } else if (reachedProfitAndLoss && accountGroupName !== 'NonPalCodeAccountGroup') {
                    if (!newProfitAndLossNames.includes(accountGroupName)) {
                        newProfitAndLossNames.push(accountGroupName);
                    }
                }

                if (!palCodeAccountGroupDict[accountGroupName]) {
                    palCodeAccountGroupDict[accountGroupName] = {
                        name: accountGroupName,
                        count: 0,
                        appeared: 0,
                        totalInitDebit: 0,
                        totalInitCredit: 0,
                        totalDebit: 0,
                        totalCredit: 0,
                        totalBalance: 0
                    };
                }
            });

            // Only update state if the profitAndLossNames array has changed
            if (JSON.stringify(newProfitAndLossNames) !== JSON.stringify(profitAndLossNames)) {
                setProfitAndLossNames(newProfitAndLossNames);
            }

            // Set the accounting groups
            setAccountingGroups(palCodeAccountGroupDict);
            setShowReport(true);
        }
    }, [data, showWithZeros]);

    // Call generateReportHtml once after accountingGroups is set
    useEffect(() => {

        generateReportHtml();
        if (showReport) {
            initTotalValues();
            generateReportHtml();
            setShowReport(false);
        }
    }, [showReport, showAccountCardsWithZeros, detailedTransactions, viewAccountCard, viewPalcodes]);




    useEffect(() => {
        setClientData(client);
    }, [client]);






    const handleRowClick = (event) => {
        try {

            // Decode the encoded JSON string
            const jsonString = decodeURIComponent(event.detail);


            // Parse the JSON string
            const transaction = JSON.parse(jsonString);


            // Proceed with your logic
            const accountCard = {
                cardId: transaction.accountCardId,
                name: transaction.accountCardName,
            };

            const palCode = {
                palCodeId: transaction.palCode,
            };

            handleSelect(accountCard, palCode);
        } catch (error) {
            // Handle any JSON parsing errors
            console.error("Failed to parse JSON from event.detail:", error, event.detail);
        }
    };


    useEffect(() => {


        // Attach event listener for the custom event
        window.addEventListener('rowClick', handleRowClick);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('rowClick', handleRowClick);
        };
    }, []);



    const generateReportHtml = () => {
        let filteredTransactions = data.transaction;
        if (!showWithZeros) {
            filteredTransactions = data.transaction.filter(tr => (tr.initCreditAccount !== 0 || tr.initDebitAccount !== 0));
        } else {
            filteredTransactions = data.transaction;
        }

        const uniquePalCodeCategories = [...new Set(filteredTransactions
            .map(tr => tr.palCodeCategory)
            .filter(category => category !== "NonPalCodeCategory"))];

        const uniquePalCodes = [...new Set(filteredTransactions
            .map(tr => tr.palCode)
            .filter(code => code !== "NonPalCode"))];

        // Pass the filtered transactions to generate the content
        let content = `
                <div class="upper-name-div">
                    <p class="first" style="font-size: 20px; margin-left:20px;">${data.clientName}</p>
                    <p class="first" style="font-size: 20px; margin-left:20px;">ח.פ. / ע.מ: ${data.clientCase}</p>
                </div>
                <div class="middle-details">
                    <div>
                        <p class="rt1" style="font-size: 20px; margin: 0;">דוח מאזן בוחן יתרות</p>
                        <div style="display: flex; gap: 20px;">
                            <p class="det-small" style="font-size: 20px; text-decoration: underline; font-weight: 900;">
                                מחודש ${data.startDate} עד חודש ${data.endDate}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="sum-details">
                    <div style="margin-right:40px;">
                        מסעיף: ${uniquePalCodeCategories[0]} - עד סעיף: ${uniquePalCodeCategories[uniquePalCodeCategories.length - 1]}
                    </div>
                    <div  style="margin-right:40px;">
                        מסיווג: ${uniquePalCodes[0]} - עד סיווג: ${uniquePalCodes[uniquePalCodes.length - 1]}
                    </div>
                </div>`;
        content += generateAccountingGroupClassificationSections(filteredTransactions, uniquePalCodeCategories, uniquePalCodes);
        // Define the final summary row to inject
        const finalSummaryRow = `
            <tr class="tr-final-row-palcode-Report">
                <td class="total-palcode">סה''כ רווח והפסד בדוח: </td>
                ${detailedTransactions ? `
                    <td class="td-last-sum">${beautifyNumber(profitAndLossTotalInitDebit)}</td>
                    <td class="td-last-sum">${beautifyNumber(profitAndLossTotalInitCredit)}</td>
                    <td class="td-last-sum">${beautifyNumber(profitAndLossTotalDebit)}</td>
                    <td class="td-last-sum">${beautifyNumber(profitAndLossTotalCredit)}</td>
                  ` : ''}
                <td class="td-last-sum">${beautifyNumber(profitAndLossTotalBalance1)}</td>
            </tr>
            <tr class="tr-final-row-palcode-Report">
                <td class="total-palcode">סה''כ בדוח: </td>
                  ${detailedTransactions ? `
                <td class="td-last-sum">${beautifyNumber(totalInitDebitAccountReport)}</td>
                <td class="td-last-sum">${beautifyNumber(totalInitCreditAccountReport)}</td>
                <td class="td-last-sum">${beautifyNumber(totalDebitAccountReport)}</td>
                <td class="td-last-sum">${beautifyNumber(totalCreditAccountReport)}</td>
                ` : ''}
                <td class="td-last-sum">${beautifyNumber(totalBalanceReport)}</td>
            </tr>
        `;



        // Split the content by 'pageStyle' to target the last one
        content = content.slice(0, -60);

        // Append finalSummaryRow
        content += finalSummaryRow;

        // Append the closing HTML structure
        content += `
            </tbody>
        </table>
    </div>
</body>
</html>`;
        const reportHtml = `
            <!DOCTYPE html>
            <html lang="he">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <style>
                *{
          box-sizing: border-box !important;
          }

          @media print {
            body {
                width: 100%;
                margin: 0;
                padding: 50px;
                margin-bottom: 100px !im;
            }

            .content-wrapper {
                page-break-before: avoid;
                page-break-after: avoid;
                page-break-inside: avoid;
            }

            .sum-details {
                page-break-before: avoid;
                page-break-after: avoid;
                page-break-inside: avoid;
            }

            .table {
                page-break-before: avoid;
                page-break-after: avoid;
                page-break-inside: auto;
                width: 100%;
                border-collapse: collapse;
            }

            .table thead {
                display: table-header-group;
                /* Ensure headers repeat */
            }

            .table tbody {
                display: table-row-group;
            }

            tr,
            td,
            th {
                page-break-inside: avoid;
                page-break-before: auto;
                page-break-after: auto;
            }

            .a4,
            .pageStyle {
                page-break-before: avoid;
                page-break-after: auto;
                page-break-inside: avoid;
            }

            .sum-details div {
                display: inline-block;
                width: auto;
                white-space: nowrap;
                text-align: right;
            }
        }
                html {
                         height: 0;
                         }
                    .accountingGroupClassification_accountingGroup_container {
                        display: -webkit-box;               /* Old syntax for older WebKit browsers */
                        display: -moz-box;                  /* Old syntax for Firefox */
                        display: -ms-flexbox;               /* Old syntax for IE10 */
                        display: -webkit-flex;              /* Newer WebKit syntax */
                        display: flex;                      /* Standard syntax */

                        -webkit-box-pack: justify;          /* Justify content for older WebKit browsers */
                        -moz-box-pack: justify;             /* Justify content for older Firefox */
                        -ms-flex-pack: justify;             /* Justify content for IE10 */
                        -webkit-justify-content: space-between; /* Newer WebKit syntax for justifying content */
                        justify-content: space-between;     /* Standard syntax */

                        width: 100%;
                        margin-top: 20px;
                    }

                    .titles-details {
                            display: -webkit-box;               /* Old syntax for older WebKit browsers */
                            display: -moz-box;                  /* Old syntax for Firefox */
                            display: -ms-flexbox;               /* Old syntax for IE10 */
                            display: -webkit-flex;              /* Newer WebKit syntax */
                            display: flex;                      /* Standard syntax */
                            
                            -webkit-box-align: center;          /* Align items for older WebKit browsers */
                            -moz-box-align: center;             /* Align items for older Firefox */
                            -ms-flex-align: center;             /* Align items for IE10 */
                            -webkit-align-items: center;        /* Align items for newer WebKit */
                            align-items: center;                /* Standard syntax */

                            -webkit-box-pack: justify;          /* Justify content for older WebKit browsers */
                            -moz-box-pack: justify;             /* Justify content for older Firefox */
                            -ms-flex-pack: justify;             /* Justify content for IE10 */
                            -webkit-justify-content: space-between; /* Justify content for newer WebKit */
                            justify-content: space-between;     /* Standard syntax */
                        }

                    h1 {
                        color: navy;
                        text-align: center;
                    }

                    p {
                        color: #333;
                        padding: 20px;
                        text-align: center;
                    }

                    .upper-name-div {
                        display: -webkit-box;              /* Old syntax for older WebKit browsers */
                        display: -moz-box;                 /* Old syntax for Firefox */
                        display: -ms-flexbox;              /* Old syntax for IE10 */
                        display: -webkit-flex;             /* Newer WebKit syntax */
                        display: flex;                     /* Standard syntax */
                        
                        -webkit-box-orient: horizontal;    /* Ensures horizontal layout in older WebKit */
                        -moz-box-orient: horizontal;       /* Ensures horizontal layout in older Firefox */
                        -webkit-box-direction: reverse;    /* Reverses direction for older WebKit */
                        -moz-box-direction: reverse;       /* Reverses direction for older Firefox */
                        -webkit-flex-direction: row-reverse; /* Flexbox reverse direction for WebKit */
                        -ms-flex-direction: row-reverse;   /* Flexbox reverse direction for IE10 */
                        flex-direction: row-reverse;       /* Standard syntax */

                        padding: 10px;
                        margin-right: 50px;
                        justify-content: flex-end;         /* Align items to the right */
                        text-align: right;                 /* Ensure text aligns to the right */
                        direction: rtl;                    /* Set text direction to right-to-left */
                    }


                    .upper-name-font {
                        font-weight: 800;
                    }


                    .middle-details {
                    display: -webkit-box;              /* Old syntax for older WebKit browsers */
                    display: -moz-box;                 /* Old syntax for Firefox */
                    display: -ms-flexbox;              /* Old syntax for IE10 */
                    display: -webkit-flex;             /* Newer WebKit syntax */
                    display: flex;                     /* Standard syntax */

                    -webkit-box-pack: center;          /* Center content for older WebKit browsers */
                    -moz-box-pack: center;             /* Center content for older Firefox */
                    -ms-flex-pack: center;             /* Center content for IE10 */
                    -webkit-justify-content: center;   /* Justify content to center for newer WebKit */
                    justify-content: center;           /* Standard syntax */

                    -webkit-box-align: center;         /* Align items for older WebKit browsers */
                    -moz-box-align: center;            /* Align items for older Firefox */
                    -ms-flex-align: center;            /* Align items for IE10 */
                    -webkit-align-items: center;       /* Align items to center for newer WebKit */
                    align-items: center;               /* Standard syntax */

                    height: 120px;
                    padding-right: 30px;
                    padding-left: 30px;
                }


                    .middle-under-details {
                            display: -webkit-box;              /* Old syntax for older WebKit browsers */
                            display: -moz-box;                 /* Old syntax for Firefox */
                            display: -ms-flexbox;              /* Old syntax for IE10 */
                            display: -webkit-flex;             /* Newer WebKit syntax */
                            display: flex;                     /* Standard syntax */

                            -webkit-box-align: center;         /* Align items for older WebKit browsers */
                            -moz-box-align: center;            /* Align items for older Firefox */
                            -ms-flex-align: center;            /* Align items for IE10 */
                            -webkit-align-items: center;       /* Align items to center for newer WebKit */
                            align-items: center;               /* Standard syntax */

                            height: 80px;
                            padding-right: 30px;
                        }

                    .rt1 {
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                        direction: rtl;
                        text-decoration: underline;
                    }

                    .rt2 {
                        margin: 0;
                        padding: 0;
                        padding-bottom: 10px;
                        font-size: 23px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                        text-decoration: underline;
                    }

                    .num-total {
                        margin: 0;
                        padding: 0;
                        padding-bottom: 10px;
                        font-size: 23px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                        position: relative;
                        display: inline-block;
                    }

                    .num-total::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        height: 2px;
                        background: black;
                        top: -1px;
                    }

                    .num-total::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        height: 2px;
                        background: repeating-linear-gradient(to right,
                                rgb(0, 0, 0),
                                rgb(0, 0, 0) 2px,
                                transparent 2px,
                                transparent 4px);
                        bottom: 10px;
                    }

                    .normal-num {
                        margin: 0;
                        padding: 0;
                        padding-bottom: 10px;
                        font-size: 23px;
                        font-style: normal;
                        line-height: normal;
                        position: relative;
                        direction: ltr;
                        display: inline-block;
                    }

                    .underlined {
                        margin: 0;
                        padding: 0;
                        padding-bottom: 10px;
                        font-size: 23px;
                        font-style: normal;
                        line-height: normal;
                        position: relative;
                        direction: ltr;
                        display: inline-block;
                    }

                    .a4 {
                        page-break-after: always;
                        page-break-inside: avoid;
                        width: 210mm;
                        min-height: 297mm;
                        margin: 0 auto;
                    }

                    .underlined::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        height: 1px;
                        background: rgb(0, 0, 0);
                        bottom: 10px;
                    }

                    .first {
                        margin: 0;
                        padding: 0;
                        padding-bottom: 10px;
                        font-size: 23px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                    }

                    .det {
                        margin: 0;
                        padding: 2px;
                        font-size: 23px;
                        font-style: normal;
                    }

                    .det-small {
                        margin: 0;
                        padding: 2px;
                        font-size: 18px;
                        font-style: normal;
                    }

                    .num {
                        margin: 0;
                        padding: 2px;
                        font-size: 23px;
                        font-style: normal;
                    }

                    .details-div {
                        width: 200px;
                        margin-right: 90px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                    }

                    .details-line {
                        width: 100%;
                        height: 0px;
                        border: 1px solid black;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    .period-div {
                        display: flex;
                        justify-content: flex-end;
                        align-items: start;
                        width: 95%;
                        margin-left: auto;
                        margin-right: auto;
                        padding: 10px;
                    }

                    .column_div {
                        width: 200px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                    }

                    .table {
                        width: auto !important;
                        direction: rtl;
                        /* Set text direction to right-to-left */
                        text-align: right;
                        page-break-inside: avoid;
                        margin-left: auto;
                        /* Push the table to the right */
                        margin-right: 0;
                        /* Ensure no margin on the right */
                    }

                    .tr-underline {
                        direction: rtl;
                        border-bottom: 1px;
                        border-color: #000000;
                    }

                    td {
                        font-size: 14px;
                    }

                    .td-last {
                        border-bottom: 1px;
                        border-color: #000000;
                    }

                    .td-last:hover {
                        background-color: #000000;
                    }

                    .td-last-sum {
                        width: 120px;
                        position: relative;
                        text-align: right;
                        font-weight: 1000;
                        padding-bottom: 8px;
                    }

                    .td-last-sum::before, 
                    .td-last-sum::after {
                        width: 120px;
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        border-bottom: 1px solid black;
                    }

                    .td-last-sum::before {
                        bottom: -2px;
                    }

                    .td-last-sum::after {
                        bottom: -8px;
                    }

                    .td-header {
                        position: relative;
                        text-align: right;
                        direction: rtl;
                        ${viewAccountCard ? "border-bottom: 1px solid black;" : "border-bottom: none;"}
                    }

                   .td-last-sum2 {
                        width: 180px;
                        position: relative;
                        text-align: right; /* Aligns text to the right */
                        direction: rtl; /* Ensures proper right-to-left alignment */
                    }

                    .td-last-sum2::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 2px;
                        z-index: 1;
                    }

                    .tr {
                        border-bottom: 1px solid #b2b2b2;
                        font-size: 16px;
                    }

                    .table th:first-child,
                    .table td:first-child {
                        width: 400px;
                        text-align: right;
                        /* Keep Hebrew text alignment */
                        word-wrap: break-word;
                    }

                    .table th:last-child,
                    .table td:last-child {
                        width: 50px !important;
                        white-space: nowrap; /* Prevents text from wrapping */
                        /* Let the second column adjust dynamically */
                        text-align: right;
                    }

                    .tr-final-row {
                        border-bottom: 1px solid #000000;
                        font-size: 16px;
                    }

                    .tr:hover {
                        background-color: #ddd;
                    }

                    .th,
                    td {
                        border: 0px solid black;
                        padding: 8px ;
                        text-align: center;
                    }

                    .overflowed {
                        text-align: right;
                        padding-right: 20px !important;
                    }

                    .td {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        border: 1px solid black;
                        padding: 8px;
                        text-align: center;
                    }

                    .number-of-transactions {
                        font-size: 18px;
                        font-weight: 1000;
                        text-decoration: underline;
                    }

                    .sums-of-transactions {
                        font-size: 20px;
                        font-weight: 1000;
                        text-align: right;
                    }

                    .number-total {
                        font-size: 16px;
                        font-weight: 1000;
                    }

                    .underline {
                        width: 100%;
                        border: 1px solid black;
                    }

                    .sum-details {
                        width: 100%;
                        display: -webkit-box;              /* Old syntax for older WebKit browsers */
                        display: -moz-box;                 /* Old syntax for Firefox */
                        display: -ms-flexbox;              /* Old syntax for IE10 */
                        display: -webkit-flex;             /* Newer WebKit syntax */
                        display: flex;                     /* Standard syntax */
                        
                        -webkit-box-orient: vertical;      /* Ensures column layout in older WebKit */
                        -moz-box-orient: vertical;         /* Ensures column layout in older Firefox */
                        -webkit-box-direction: normal;     /* Normal flow direction for older WebKit */
                        -moz-box-direction: normal;        /* Normal flow direction for older Firefox */
                        -webkit-flex-direction: column;    /* Flexbox column direction for WebKit */
                        -ms-flex-direction: column;        /* Flexbox column direction for IE10 */
                        flex-direction: column;            /* Standard syntax */
                        
                        -webkit-box-pack: start;           /* Align items to start for older WebKit */
                        -moz-box-pack: start;              /* Align items to start for older Firefox */
                        -ms-flex-pack: start;              /* Align items to start for IE10 */
                        -webkit-justify-content: flex-start; /* Justify content at the start for WebKit */
                        justify-content: flex-start;       /* Standard syntax */

                        -webkit-box-align: end;            /* Align items to end for older WebKit */
                        -moz-box-align: end;               /* Align items to end for older Firefox */
                        -ms-flex-align: end;               /* Align items to end for IE10 */
                        -webkit-align-items: flex-end;     /* Align items at the end for WebKit */
                        align-items: flex-end;             /* Standard syntax */
                        
                        margin-top: 40px;
                        margin-bottom: 20px;
                    }


                    .titles-details {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .palcodes-details {
                        font-size: 14px;
                    }

                    .total-palcode {
                        font-weight: bold;
                        font-size: 18px;
                        text-align: right; /* Ensures text is aligned to the right */
                        direction: rtl;
                    }
                   

                   .pageStyle {
                            width:210mm;
                            height:297mm;
                            margin-bottom: 20px;
                            background:red;
                            display: -webkit-box;               /* Old syntax for older WebKit browsers */
                            display: -moz-box;                  /* Old syntax for Firefox */
                            display: -ms-flexbox;               /* Old syntax for IE10 */
                            display: -webkit-flex;              /* Newer WebKit syntax */
                            display: flex;                      /* Standard syntax */
                            
                            -webkit-box-orient: vertical;       /* Ensures column layout in older WebKit */
                            -moz-box-orient: vertical;          /* Ensures column layout in older Firefox */
                            -webkit-box-direction: normal;      /* Normal flow direction for older WebKit */
                            -moz-box-direction: normal;         /* Normal flow direction for older Firefox */
                            -webkit-flex-direction: column;     /* Flexbox column direction for WebKit */
                            -ms-flex-direction: column;         /* Flexbox column direction for IE10 */
                            flex-direction: column;             /* Standard syntax */
                            
                            -webkit-box-pack: justify;          /* Justify content for older WebKit browsers */
                            -moz-box-pack: justify;             /* Justify content for older Firefox */
                            -ms-flex-pack: justify;             /* Justify content for IE10 */
                           

                            background-color: white;
                            padding: 20px;

                            page-break-before: auto;
                            page-break-after: auto;
                            page-break-inside: avoid;
                           
                            border: 1px solid #ccc;
                            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                        }

                       
                    .page-break {
                        margin-top: 50px;
                        page-break-before: always;
                        page-break-after: auto; /* Avoid unnecessary page breaks */
                        page-break-inside: avoid;
                    }
                    tr {                                            
                        page-break-inside: avoid;                      
                        page-break-after: avoid;                       
                        page-break-before: avoid;                      
                    } 

                    .hoverTr {
                        transition: transform 0.2s ease, box-shadow 0.1s ease;
                    }

                    .hoverTr:hover {
                        transform: translateY(-5px); /* Moves the row up by 5px */
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Adds a shadow for the 3D effect */
                    }

                   

                    .content-wrapper {
              
                            padding: 10px;
                            margin: 0;
                        }   
                </style>
            </head>
            <body>
                   <div class="content-wrapper">

                            ${content}
                    </div>
            </body>
            </html>
        `;

        setReportHtmlContent(reportHtml);
    };

    const updateNonPalCodeValues = (transactions) => {
        return transactions.map(transaction => {
            return {
                ...transaction,
                palCodeType: transaction.palCodeType === 'NonPalCodeType' ? t('NonPalCodeType') : transaction.palCodeType,
                palCodeCategory: transaction.palCodeCategory === 'NonPalCodeCategory' ? t('NonPalCodeCategory') : transaction.palCodeCategory,
                palCodeAccountGroup: transaction.palCodeAccountGroup === 'NonPalCodeAccountGroup' ? t('NonPalCodeAccountGroup') : transaction.palCodeAccountGroup,
                accountingGroupClassification: transaction.accountingGroupClassification === 'NonAccountingGroupClassifaction' ? t('NonAccountingGroupClassifaction') : transaction.accountingGroupClassification
            };
        });
    };



    const handleTrialBalanceTypeChange = async (event, newAlignment) => {
        // Prevent deselecting both options

        if (newAlignment === "AccountCards") {
            setViewAccountCard(true);
            setViewPalcodes(true);

        }
        if (newAlignment === "PalCodes") {
            setViewPalcodes(true);
            setViewAccountCard(false);

        }
        if (newAlignment === "Categories") {
            setViewPalcodes(false);
            setViewAccountCard(false);

        }
        if (newAlignment !== null) {
            setTrialBalanceType(newAlignment);
        }
    };

    const generateAccountingGroupClassificationSections = (transactions, uniquePalCodeCategories, uniquePalCodes) => {


        let content = '';
        const groupedByAccountingGroupClassification = groupBy(transactions, 'accountingGroupClassification');


        Object.keys(groupedByAccountingGroupClassification).forEach(accountingGroupClassification => {


            const transactionsForAccountingGroupClassification = groupedByAccountingGroupClassification[accountingGroupClassification];



            const groupedByPalCodeCategory = groupBy(transactionsForAccountingGroupClassification, 'palCodeCategory');



            const palCodeAccountGroup = transactionsForAccountingGroupClassification[0]?.palCodeAccountGroup || '';

            // Process each transaction and replace "Non" values with translations



            let headerHtml = `
               
               
            `;
            const categories = Object.keys(groupedByPalCodeCategory);
            Object.keys(groupedByPalCodeCategory).forEach((palCodeCategory, index) => {
                //    if (accountingGroupClassification === 'נכסים') {

                const currentCategory = groupedByPalCodeCategory[palCodeCategory];

                const nextCategoryKey = categories[index + 1];
                const nextCategory = groupedByPalCodeCategory[nextCategoryKey];

                // 
                // 
                // 
                // 
                // }

                let printAccountingGroup = false;
                printAccountingGroup = !nextCategory || (currentCategory[0]?.palCodeAccountGroup !== nextCategory[0]?.palCodeAccountGroup);

                const transactionsForPalCodeCategory = groupedByPalCodeCategory[palCodeCategory];

                const safeId = palCodeCategory;

                headerHtml = `
                 <div id="group-${safeId}" class="accountingGroupClassification_accountingGroup_container">
                    <div class="sums-of-transactions" style="text-decoration: underline; margin-right:40px">
                    ${accountingGroupClassification === 'NonAccountingGroupClassifaction'
                        ? t('NonAccountingGroupClassifaction')
                        : accountingGroupClassification} - ${palCodeAccountGroup === 'NonPalCodeAccountGroup'
                            ? t('NonPalCodeAccountGroup')
                            : transactionsForPalCodeCategory[0]?.palCodeAccountGroup || ''}
                    </div>
                </div>`;

                // ${headerHtml}
                // <div style="margin-right: 40px;">
                //     <div class="sums-of-transactions" style="margin-bottom: 10px; text-decoration: underline;">${palCodeCategory}</div>
                // </div>

                content += `
                        ${generatePalCodeTables(transactionsForPalCodeCategory, palCodeCategory, headerHtml, printAccountingGroup, transactionsForPalCodeCategory[0]?.palCodeAccountGroup || '')}
                `;
            });
        });

        return content;
    };

    const generatePalCodeTables = (transactionsForPalCodeCategory, palCodeCategory, headerHtml, printAccountingGroup, accountingGroupName) => {
        let content = '';
        const groupedByPalCode = groupBy(transactionsForPalCodeCategory, 'palCode');



        let transactionsProcessed = 0;
        let headerDisplayedForCurrentPage = false;

        // Start the table once at the beginning
        content += `
      
        <table class="table" style="margin-right: 40px; margin-top: 20px; padding: 0px 20px;" dir="rtl">
    <thead>
        <tr>
            <th colspan="6" style="text-align: right; padding: 10px;">
                ${headerHtml}
                <div style="margin-right: 40px;">
                        <div class="sums-of-transactions" style="margin-bottom: 10px; text-decoration: underline;">${palCodeCategory === 'NonPalCodeCategory'
                ? t('NonPalCodeCategory')
                : palCodeCategory}</div>
                    </div>
            </th>
        </tr>
        <tr class="tr">
            ${viewAccountCard ? `<th class="td-header">כרטיס חשבון</th>` : '<th class="td-header"></th>'}
            ${detailedTransactions ? `
            <th class="td-header">י. פ. חובה</th>
            <th class="td-header">י. פ. זכות</th>
            <th class="td-header">חובה</th>
            <th class="td-header">זכות</th>
            ` : ''}
            <th class="td-header">יתרה</th>
        </tr>
    </thead>
    <tbody id="table-body">
        `;

        const palCodes = Object.keys(groupedByPalCode);
        palCodes.forEach((palCode, index) => {
            const transactionsForPalCode = groupedByPalCode[palCode];

            // Add a row to divide different palCodes
            if (viewPalcodes) {
                content += `
                <tr>
                    <td class="overflowed" colspan="6">${palCode === 'NonPalCode'
                        ? t('NonPalCode')
                        : palCode}</td>
                </tr>`
            }

            ;

            if (viewAccountCard) {
                transactionsForPalCode
                    .filter(tr => showAccountCardsWithZeros || tr.balance !== 0) // Filter out transactions with balance = 0 if showAccountCardsWithZeros is false
                    .forEach(tr => {
                        const transactionDetail = encodeURIComponent(JSON.stringify(tr)); // Escape quotes for embedding JSON in HTML

                        content += `
                <tr class="hoverTr" onclick="window.parent.dispatchEvent(new CustomEvent('rowClick', { detail: '${transactionDetail}' }))" style="cursor:pointer">
                    <td class="td-last-sum2">${tr.accountCardId} ${tr.accountCardName}</td>
                    ${detailedTransactions ? `
                    <td class="td-last-sum2">${beautifyNumber(tr.initDebitAccount)}</td>
                    <td class="td-last-sum2">${beautifyNumber(tr.initCreditAccount)}</td>
                    <td class="td-last-sum2">${beautifyNumber(tr.debitAccount)}</td>
                    <td class="td-last-sum2">${beautifyNumber(tr.creditAccount)}</td>
                    ` : ''}
                    <td class="td-last-sum2">${beautifyNumber(tr.balance)}</td>
                </tr>
            `;

                        transactionsProcessed++;

                        // Check if we need to start a new page

                    });
            }


            // Add totals for the current PalCode
            content += generatePalCodeTotals(transactionsForPalCode, palCode);

            // If this is the last palCode in the current palCodeCategory, add the category totals
            if (index === palCodes.length - 1) {
                content += generatePalCodeCategoryTotals(transactionsForPalCodeCategory, palCodeCategory);


                if (printAccountingGroup) {
                    // 







                    content += `
                    <tr class="tr-final-row-palcode-category">
                        <td class="total-palcode">סה''כ:  ${accountingGroupName === 'NonPalCodeAccountGroup'
                            ? t('NonPalCodeAccountGroup')
                            : accountingGroupName}</td>
                          ${detailedTransactions ? `
                        <td class="td-last-sum">${beautifyNumber(totalInitDebit)}</td>
                        <td class="td-last-sum">${beautifyNumber(totalInitCredit)}</td>
                        <td class="td-last-sum">${beautifyNumber(totalDebit)}</td>
                        <td class="td-last-sum">${beautifyNumber(totalCredit)}</td>
                        ` : ''}
                        <td class="td-last-sum">${beautifyNumber(totalBalance1)}</td>

                    </tr>`;
                    if (profitAndLossNames.includes(accountingGroupName)) {
                        profitAndLossTotalInitDebit += totalInitDebit;
                        profitAndLossTotalInitCredit += totalInitCredit;
                        profitAndLossTotalDebit += totalDebit;
                        profitAndLossTotalCredit += totalCredit;
                        profitAndLossTotalBalance1 += totalBalance1;
                    }
                    totalInitDebit = 0;
                    totalInitCredit = 0;
                    totalDebit = 0;
                    totalCredit = 0;
                    totalBalance1 = 0
                }
            }
        });

        // Close the final table and pageStyle div
        content += `
                    </tbody>
                </table>
                `;

        // Add totals for the PalCodeCategory
        // content += `
        //     <div class="pageStyle">
        //     </div>
        // `;

        return content;
    };







    const generatePalCodeTotals = (transactions, palCode) => {
        if (viewPalcodes) {
            const totalInitDebitAccount = transactions.reduce((acc, tr) => acc + tr.initDebitAccount, 0);
            const totalInitCreditAccount = transactions.reduce((acc, tr) => acc + tr.initCreditAccount, 0);
            const totalDebitAccount = transactions.reduce((acc, tr) => acc + tr.debitAccount, 0);
            const totalCreditAccount = transactions.reduce((acc, tr) => acc + tr.creditAccount, 0);
            const totalBalance = transactions.reduce((acc, tr) => acc + tr.balance, 0);
            return `
            <tr class="tr-final-row-palcode">
                 <td class="total-palcode">סה''כ: ${palCode === 'NonPalCode' ? t('NonPalCode') : palCode}</td>
                 ${detailedTransactions ? `
                 <td class="td-last-sum">${beautifyNumber(totalInitDebitAccount)}</td>
                 <td class="td-last-sum">${beautifyNumber(totalInitCreditAccount)}</td>
                 <td class="td-last-sum">${beautifyNumber(totalDebitAccount)}</td>
                 <td class="td-last-sum">${beautifyNumber(totalCreditAccount)}</td>
                 ` : ''}
                 <td class="td-last-sum">${beautifyNumber(totalBalance)}</td>
             </tr>
         `;
        } else {
            return "";
        }

    };

    const generatePalCodeCategoryTotals = (transactions, palCodeCategory) => {
        const totalInitDebitAccount = transactions.reduce((acc, tr) => acc + tr.initDebitAccount, 0);
        const totalInitCreditAccount = transactions.reduce((acc, tr) => acc + tr.initCreditAccount, 0);
        const totalDebitAccount = transactions.reduce((acc, tr) => acc + tr.debitAccount, 0);
        const totalCreditAccount = transactions.reduce((acc, tr) => acc + tr.creditAccount, 0);
        const totalBalance = transactions.reduce((acc, tr) => acc + tr.balance, 0);

        if (transactions[0].palCodeAccountGroup === 'רכוש קבוע') {


        }

        const group = transactions[0].palCodeAccountGroup;
        // transactions.forEach(transaction => {
        //     setAccountingGroups(prevGroups => {
        //         const updatedGroup = { ...prevGroups[transaction.palCodeAccountGroup] };

        //         // Increment the 'appeared' count
        //         updatedGroup.appeared += 1;

        //         return {
        //             ...prevGroups,
        //             [group]: updatedGroup
        //         };
        //     });
        // });

        // setAccountingGroups(prevGroups => {
        //     const updatedGroup = { ...prevGroups[group] }; // Clone the current group's data
        //     
        //     if (group === 'רכוש קבוע') {
        //         

        //     }
        //     // Update the totals for the existing group
        //     updatedGroup.totalInitDebit += totalInitDebitAccount;
        //     updatedGroup.totalInitCredit += totalInitCreditAccount;
        //     updatedGroup.totalDebit += totalDebitAccount;
        //     updatedGroup.totalCredit += totalCreditAccount;
        //     updatedGroup.totalBalance += totalBalance;
        //     return {
        //         ...prevGroups,
        //         [group]: updatedGroup // Update the specific group in the state
        //     };
        // });
        totalInitDebit += totalInitDebitAccount;
        totalInitCredit += totalInitCreditAccount;
        totalDebit += totalDebitAccount;
        totalCredit += totalCreditAccount;
        totalBalance1 += totalBalance;
        // Accumulate to report totals
        totalInitDebitAccountReport += totalInitDebitAccount;
        totalInitCreditAccountReport += totalInitCreditAccount;
        totalDebitAccountReport += totalDebitAccount;
        totalCreditAccountReport += totalCreditAccount;
        totalBalanceReport += totalBalance;

        return `
            <tr class="tr-final-row-palcode-category">
                <td class="total-palcode">סה''כ: ${palCodeCategory === 'NonPalCodeCategory'
                ? t('NonPalCodeCategory')
                : palCodeCategory}</td>
                ${detailedTransactions ? `
                    <td class="td-last-sum">${beautifyNumber(totalInitDebitAccount)}</td>
                    <td class="td-last-sum">${beautifyNumber(totalInitCreditAccount)}</td>
                    <td class="td-last-sum">${beautifyNumber(totalDebitAccount)}</td>
                    <td class="td-last-sum">${beautifyNumber(totalCreditAccount)}</td>
                  ` : ''}
                  <td class="td-last-sum">${beautifyNumber(totalBalance)}</td>
            </tr>
        `;
    };

    const handleDownloadExcel = () => {
        if (!data || !data.transaction) return;

        // Define the header translations manually
        const headers = {
            accountCardId: t("AccountCard ID"),
            accountCardName: t("AccountCard Name"),
            palCode: t('BalanceClassificationMazni'),
            palCodeType: t("BalanceClassificationType"),
            palCodeCategory: t("BalanceClassificationCategory"),
            palCodeAccountGroup: t("AccountingGroup"),
            accountingGroupClassification: t("BalanceClassification"),
            initCreditAccount: t("OpeningBalanceCreditAccount"),
            initDebitAccount: t("OpeningBalanceDebitAccount"),
            creditAccount: t("Credit Account"),
            debitAccount: t("Debit Account"),
            balance: t("Balance")
        };

        // Transform the data, translating the headers
        const transformedData = data.transaction.map(transaction => {
            const transformedTransaction = {};
            for (const key in transaction) {
                if (headers[key]) {
                    transformedTransaction[headers[key]] = transaction[key];
                }
            }
            return transformedTransaction;
        });

        // Convert the transformed data to a worksheet
        const ws = XLSX.utils.json_to_sheet(transformedData);

        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Transactions');

        // Write the workbook and download it
        XLSX.writeFile(wb, `${data.clientName}_TransactionReport.xlsx`);
    };


    const handleDownloadPdf = async () => {
        const pageElements = document.querySelectorAll('.pageStyle');
        try {
            // let pdfContent = parseAndInsertPageBreaksNoDom(reportHtmlContent, data, showWithZeros);

            let pdfContent = reportHtmlContent;

            // Remove the border and box-shadow styles directly
            pdfContent = pdfContent.replace(/border: 1px solid #ccc;/g, '');
            pdfContent = pdfContent.replace(/box-shadow: 0px 0px 10px rgba\(0, 0, 0, 0.1\);/g, '');


            const response = await PythonService.convertHtmlToPdf(pdfContent, token);
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                // Set the filename using clientName and clientCase
                const fileName = `${data.clientName}-${data.clientCase}_דוח מאזן בוחן.pdf`;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                console.error('Failed to convert HTML to PDF:', response.status);
            }
        } catch (error) {
            console.error('Error converting HTML to PDF:', error);
        }
    };

    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write(reportHtmlContent);
        printWindow.document.close();
        printWindow.print();
    };

    useEffect(() => {
        // Suppose you have an array or can get all categories from data
        let filteredTransactions = data.transaction;


        if (!showWithZeros) {
            filteredTransactions = data.transaction.filter(tr => (tr.initCreditAccount !== 0 || tr.initDebitAccount !== 0));
        } else {
            filteredTransactions = data.transaction;
        }

        const allCategories = [...new Set(filteredTransactions
            .map(tr => tr.palCodeCategory)
            .filter(category => category !== "NonPalCodeCategory"))];


        allCategories.forEach((category) => {
            // Build the same ID you used above
            const safeId = category;
            const el = document.getElementById(`group-${safeId}`);
            if (el) {
                console.log(`Category "${category}" found at offsetTop=`, el.offsetTop);
            }
        });
    }, [reportHtmlContent, data.transaction]);

    // const parseAndInsertPageBreaksNoDom = (reportHtml, data, showWithZeros) => {
    //     // 1) Filter transactions if showWithZeros is false
    //     let filteredTransactions = data.transaction;
    //     if (!showWithZeros) {
    //         filteredTransactions = data.transaction.filter(
    //             (tr) => tr.initCreditAccount !== 0 || tr.initDebitAccount !== 0
    //         );
    //     }

    //     // 2) Gather all categories except "NonPalCodeCategory"
    //     const allCategories = [
    //         ...new Set(
    //             filteredTransactions
    //                 .map((tr) => tr.palCodeCategory)
    //                 .filter((cat) => cat !== 'NonPalCodeCategory')
    //         ),
    //     ];

    //     // We'll modify this string in-place
    //     let updatedHtml = reportHtml;
    //     let insertionOffset = 0;

    //     // Our page-break snippet



    //     // 3) For each category, find the container's opening & closing
    //     allCategories.forEach((category) => {
    //         const searchPattern = `id="group-${category}"`;

    //         // Find the container's <div id="group-<category>"> starting from insertionOffset
    //         const startIndex = updatedHtml.indexOf(searchPattern, insertionOffset);

    //         if (startIndex !== -1) {
    //             // Next, find this container's closing `</div>` 
    //             // (naively: the FIRST occurrence of </div> after startIndex)
    //             const closeDivIndex = updatedHtml.indexOf('</div>', startIndex);
    //             if (closeDivIndex !== -1) {
    //                 // Insert a page-break after `</div>` => which is 6 chars long
    //                 const insertPos = closeDivIndex + 6; // position right after `</div>`
    //                 updatedHtml =
    //                     updatedHtml.slice(0, insertPos) +
    //                     pageBreakDiv +
    //                     updatedHtml.slice(insertPos);

    //                 // Advance insertionOffset beyond the newly inserted snippet
    //                 insertionOffset = insertPos + pageBreakDiv.length;
    //             }
    //         }
    //     });

    //     return updatedHtml;
    // };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="account-card-report-modal-title" aria-describedby="account-card-report-modal-description">
            <Box sx={modalStyle} >

                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <Button variant="contained" onClick={handleDownloadPdf} startIcon={<DownloadIcon sx={{ ml: 1 }} />} sx={{ mt: 1, backgroundColor: '#025366', color: '#fff', '&:hover': { backgroundColor: '#1565c0' } }}>
                        {t('DownloadPDFFile')}
                    </Button>
                    <Button variant="contained" onClick={handlePrint} startIcon={<PrintIcon sx={{ ml: 1 }} />} sx={{ mt: 1, backgroundColor: '#025366', color: '#fff', '&:hover': { backgroundColor: '#1565c0' } }}>
                        {t('PrintReport')}
                    </Button>
                </div> */}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} className={`${direction}`}>
                    <div
                        className="pdf-view-upper"
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '10px',
                        }}
                    >
                        <div style={{ margin: '20px', textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={handleDownloadPdf}
                                startIcon={<DownloadIcon sx={{ ml: 1 }} />}
                                sx={{ mt: 1, backgroundColor: '#304FFF', height: '35px', color: '#fff', '&:hover': { backgroundColor: '#F9AA2A' } }}
                            >
                                {t('DownloadPDFFile')}
                            </Button>
                        </div>

                        <div style={{ margin: '20px', textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={handleDownloadExcel}
                                startIcon={<TocIcon sx={{ ml: 1 }} />}
                                sx={{ mt: 1, backgroundColor: '#304FFF', height: '35px', color: '#fff', '&:hover': { backgroundColor: '#F9AA2A' } }}
                            >
                                {t('DownloadExcelFile')}
                            </Button>
                        </div>

                        <div style={{ margin: '20px' }}>
                            <Button
                                variant="contained"
                                onClick={handlePrint}
                                startIcon={<PrintIcon />}
                                sx={{
                                    mt: 1,
                                    backgroundColor: '#304FFF',
                                    color: '#fff',
                                    '&:hover': { backgroundColor: '#1565c0' },
                                }}
                            >
                                {t('PrintReport')}
                            </Button>
                        </div>


                        <div style={{ margin: '20px', textAlign: 'center' }}>
                            <Checkbox
                                checked={showAccountCardsWithZeros}
                                onChange={(event) => handleShow0CardsCheckbox(event.target.checked)}
                                name="ShowResetCards"
                                color="primary"
                            />
                            <span>{t('ShowResetCards')}</span>
                        </div>

                        <div style={{ margin: '20px', textAlign: 'center' }}>
                            <Checkbox
                                checked={detailedTransactions}
                                onChange={(event) => handleShowDetailsTransactions(event.target.checked)}
                                name="Detailed"
                                color="primary"
                            />
                            <span>{t('Detailed')}</span>
                        </div>

                        <StyledToggleButtonGroup
                            value={trialBalanceType}
                            exclusive
                            onChange={handleTrialBalanceTypeChange}
                            aria-label="Platform"
                            sx={{ marginTop: "25px", marginRight: '20px' }}
                        >
                            <ToggleButton value="PalCodes">{t("ByBalanceClassification")}</ToggleButton>
                            <ToggleButton value="Categories">{t("ByCategory")}</ToggleButton>
                            <ToggleButton value="AccountCards">{t("ByAccountCard")}</ToggleButton>
                        </StyledToggleButtonGroup>
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: reportHtmlContent }} />
            </Box>
        </Modal>
    );
};

const groupBy = (arr, key) => {
    return arr.reduce((acc, item) => {
        if (!acc[item[key]]) {
            acc[item[key]] = [];
        }
        acc[item[key]].push(item);
        return acc;
    }, {});
};

export default TrialBalanceReportHtml;
