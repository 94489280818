import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { createPortal } from 'react-dom';
import Draggable from 'react-draggable';
import {
  MessageList,
  Input,
  ChatList,
  Button,
} from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PuffLoader from "react-spinners/PuffLoader";
import Lottie from 'lottie-react';
import TypingAnimation from '../../assests/images/Animations/typing.json';
import RecordingAnimation from '../../assests/images/Animations/recording.json';

import { FiSend, FiX } from 'react-icons/fi';
import { FaUserFriends, FaUsers } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
// Services (replace with your actual imports)
import AuthService from '../../Services/auth.service';
import ChatService from '../../Services/chat.conf.service';
import ChatApiService from '../../Services/chat.service';
import LabelService from '../../Services/labels.service';
import moment from 'moment-timezone';
// Example helper or avatar
import UserAvatar from '../Widgets/UserAvatar/UserAvatar';
import './Chat.css';
import ContactsList from './SubComponents/ContactsList';
import ChatHeader from './ChatHeader/ChatHeader';
import ChatInputBar from './SubComponents/ChatInputBar';
import ChatMessagesList from './SubComponents/ChatMessagesList/ChatMessagesList';
import { v4 as uuid } from 'uuid';
import Constants from '../../assests/Constants/constants';
import FilterOptionMenu from '../Widgets/FilterOptionMenu/FilterOptionMenu';
import LabelsList from './SubComponents/LabelsList';
import CustomDeleteConfirmationModal from '../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal';
import CreateLabelModal from './SubComponents/CreateLabelModal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { toast } from 'react-toastify';
import ManageLabelUsersModal from './ManageLabelUsersModal/ManageLabelUsersModal';
import SearchItem from './SubComponents/SearchItem';
const options = [
  { label: 'Users', value: 'users' },
  { label: 'Groups', value: 'labels' },
];

// Example usage with new options


export default function Chat({ isOpen, onClose, setOpenChat, onOpenManageLabelModal }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [container] = useState(() => document.createElement('div'));

  //--- State
  const {
    targetUserId: paramTargetUserId,
    targetUserType: paramTargetUserType,
    targetUserName: paramTargetUserName,
  } = location.state || {};

  // Basic chat states
  const [userId, setUserId] = useState('');
  const [userType, setUserType] = useState('');
  const [messages, setMessages] = useState([]);
  const [allowedUsers, setAllowedUsers] = useState([]);
  const [targetUserId, setTargetUserId] = useState('');
  const [targetUserType, setTargetUserType] = useState('');
  const [targetUserName, setTargetUserName] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const { i18n, t } = useTranslation();
  const [showManageLabelModal, setShowManageLabelModal] = useState(false);

  // Pagination states
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(20);
  const [hasMore, setHasMore] = useState(true);
  const [fetchedPages, setFetchedPages] = useState(new Set());
  const [lowestPageLoaded, setLowestPageLoaded] = useState(1);
  const [highestPageLoaded, setHighestPageLoaded] = useState(1);
  const [isInitComplete, setIsInitComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const direction = i18n.dir();
  // Refs to keep track of user info across re-renders
  const targetUserIdRef = useRef(targetUserId);
  const targetUserTypeRef = useRef(targetUserType);
  const targetUserNameRef = useRef(targetUserName);
  const currentUserIdRef = useRef(userId);
  const currentUserTypeRef = useRef(userType);

  // Typing / Online states
  const [isTyping, setIsTyping] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [isTargetRecording, setIsTargetRecording] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const typingTimeout = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  // Search & filter states
  const [viewMode, setViewMode] = useState('users');
  const [labels, setLabels] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [listSearchedText, setListSearchedText] = useState('');
  const [getAllForReceiver, setGetAllForReceiver] = useState(false);
  const audioChunksRef = useRef([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  // Text input
  const [inputValue, setInputValue] = useState('');
  const [showCreateLabelModal, setShowCreateLabelModal] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const [searchChatQuery, setSearchChatQuery] = useState('');
  const [listChatSearchedText, setListChatSearchedText] = useState('');

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      setListChatSearchedText(searchChatQuery.trim());
    }, 300);

    return () => clearTimeout(delaySearch);
  }, [searchChatQuery]);

  useEffect(() => {
    if (isSearchMode) {
      handleSearchSubmit()
    }
  }, [listChatSearchedText]);



  const changeForReceiver = (checked) => {
    setFetchedPages(new Set());
    setMessages([]);
    setGetAllForReceiver(checked);
    fetchMessages(1, false, checked);

  }
  const handleSearchSubmit = async () => {
    try {

      // Example: call an endpoint that returns a list of messages matching searchQuery
      const results = await ChatApiService.searchMessages(
        userId,
        userType,
        targetUserId,
        targetUserType,
        listChatSearchedText
      );
      // Suppose results come back as an array of objects:
      // { messageId, excerpt, pageNumber, senderId, messageDate, ... }

      setSearchResults(results);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectSearchResult = async (item) => {
    // 1) Clear current messages
    setFetchedPages(new Set());
    setMessages([]);

    // 2) Move pagination to the page where the match was found
    setLowestPageLoaded(item.pageNumber);
    setHighestPageLoaded(item.pageNumber);

    // 3) Fetch that page’s messages
    await fetchMessages(item.pageNumber);

    // 4) Close search mode
    onCancelSearch();
  };

  const handleSelectSearchResult2 = async (item) => {
    setFetchedPages(new Set());
    setMessages([]);
    setLowestPageLoaded(item.pageNumber);
    setHighestPageLoaded(item.pageNumber);
    await fetchMessages(item.pageNumber);

    // Exit search mode
    onCancelSearch();
  };

  const handleSearchPress = () => {
    setIsSearchMode(true);
    setSearchQuery('');
    setSearchResults([]);
  };

  const onCancelSearch = () => {
    setIsSearchMode(false);
    setSearchQuery('');
    setSearchResults([]);
  };

  const handleOpenModal = () => {
    setShowCreateLabelModal(true);
  };

  const handleCloseModal = () => {
    setShowCreateLabelModal(false);
  };

  const onLabelCreated = async (labelId, labelName) => {

    await refreshAllowedUsers(currentUserIdRef.current, currentUserTypeRef.current);
  };

  // For Draggable modal
  const draggableNodeRef = useRef(null);

  // ChatService ref
  const chatService = useRef(null);

  // File input ref (hidden input for attachments)
  const fileInputRef = useRef(null);

  const optionGroups = [
    {
      title: t('Status'),
      selectionType: 'single',
      options: [
        { id: 'read', label: t('Read') },
        { id: 'unread', label: t('Unread') },
        { id: 'all', label: t('All') },
      ],
    },
    {
      title: t('UserType'),
      selectionType: 'multi',
      options: [
        { id: 'business', label: t('Business') },
        { id: 'office', label: t('Office') },
      ],
    },
    {
      title: t('CaseType'),
      selectionType: 'multi',
      options: Constants.CaseTypeOptions.map((option) => ({
        id: option.value,
        label: option.name,
      })),
    },
    {
      title: t('AccountManagement'),
      selectionType: 'multi',
      options: Constants.AccountManagementOptions.map((option) => ({
        id: option.value,
        label: option.name,
      })),
    },
    {
      title: t('ReportingType'),
      selectionType: 'multi',
      options: Constants.ReportingTypeOptions.map((option) => ({
        id: option.value,
        label: option.name,
      })),
    },
  ];

  /**
   * ------------------------------
   *         Effect Hooks
   * ------------------------------
   */

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      try {
        document.body.removeChild(container);
      } catch (e) {
        // Ignore
      }
    };
  }, [container]);

  // Debounce the user list search
  useEffect(() => {
    const delaySearch = setTimeout(() => {
      setListSearchedText(searchQuery.trim());
    }, 300);
    return () => clearTimeout(delaySearch);
  }, [searchQuery]);

  // Initialize chat and connect to your chat service
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const currentUserTypeVal = await AuthService.getCurrentUserType();
        const currentUserIdVal = await AuthService.getCurrentUserTypeId();
        setUserId(currentUserIdVal);
        setUserType(currentUserTypeVal);
        chatService.current = new ChatService();
        await chatService.current.startConnection();

        // Subscribe to chat events
        chatService.current.subscribe('UserSend', handleUserSend);
        chatService.current.subscribe('MessageRead', handleMessageRead);
        chatService.current.subscribe('Typing', handleTypingEvent);
        chatService.current.subscribe('UserActive', handleUserActive);
        chatService.current.subscribe('IsRecording', handleIsRecordingEvent);

        // Fetch initial list of allowed users
        await refreshAllowedUsers(currentUserIdVal, currentUserTypeVal);
      } catch (err) {
        console.error('Error initializing chat:', err);
      }
      setLoading(false);
      setIsInitComplete(true);
    };

    init();

    // Cleanup
    return () => {
      if (chatService.current && chatService.current.isConnected) {
        chatService.current.unsubscribe('UserSend', handleUserSend);
        chatService.current.unsubscribe('MessageRead', handleMessageRead);
        chatService.current.unsubscribe('Typing', handleTypingEvent);
        chatService.current.unsubscribe('UserActive', handleUserActive);
        chatService.current.unsubscribe('IsRecording', handleIsRecordingEvent);
      }
    };
  }, []);

  // If the component is initialized and there's a target user passed from location
  useEffect(() => {
    if (isInitComplete && paramTargetUserId && paramTargetUserType && paramTargetUserName) {
      onUserSelect({
        userId: paramTargetUserId,
        userType: parseInt(paramTargetUserType, 10),
        userName: paramTargetUserName,
      });
    }
  }, [isInitComplete, paramTargetUserId, paramTargetUserType, paramTargetUserName]);

  // Whenever target user changes, reload messages
  useEffect(() => {

    if (targetUserId && targetUserType) {
      targetUserIdRef.current = targetUserId;
      targetUserTypeRef.current = targetUserType;
      targetUserNameRef.current = targetUserName;
      // Check if user is online (replace this with an actual call if needed)
      checkUserOnline(targetUserId);
      reloadMessages(false);
    }
  }, [targetUserId, targetUserType]);

  // Keep current user info in sync with refs
  useEffect(() => {
    currentUserIdRef.current = userId;
    currentUserTypeRef.current = userType;
  }, [userId, userType]);

  /**
   * ------------------------------
   *        Event Handlers
   * ------------------------------
   */
  const handleUserSend = async (senderId, senderType) => {
    const currentTargetUserId = targetUserIdRef.current;
    const currentTargetUserType = targetUserTypeRef.current;
    const currentSenderUserId = currentUserIdRef.current;
    const currentSenderUserType = currentUserTypeRef.current;


    // If message is from the currently selected user
    if (senderId === currentTargetUserId && senderType === currentTargetUserType) {
      await ChatApiService.readMessage(currentSenderUserId, currentSenderUserType, currentTargetUserId, currentTargetUserType, true);
      await reloadMessagesFor(currentSenderUserId, currentSenderUserType, currentTargetUserId, currentTargetUserType);

    } else {
      await refreshAllowedUsers(currentSenderUserId, currentSenderUserType);
    }
  };




  const handleMessageRead = (readerId, readerType, readTimestamp) => {
    const currentTargetUserId = targetUserIdRef.current;
    const currentTargetUserType = targetUserTypeRef.current;


    if (readerId === currentTargetUserId && readerType === currentTargetUserType) {
      // Convert readTimestamp to Israel time using moment or directly with Date
      const readTime = new Date(readTimestamp);

      setMessages((prevMessages) => {
        // Find the last message that matches the criteria
        const lastMessageIndex = prevMessages
          .slice()
          .reverse()
          .findIndex(
            (msg) =>
              msg.position === 'right' &&
              new Date(msg.date) <= readTime
          );

        // If no valid message is found, return previous messages unchanged
        if (lastMessageIndex === -1) return prevMessages;

        // Get the index of the last message in the original order
        const targetIndex = prevMessages.length - 1 - lastMessageIndex;

        return prevMessages.map((msg, index) => {
          if (index === targetIndex) {
            return {
              ...msg,
              receiverReadAt: readTime, // Add ReceiverReadAt to the last read message
            };
          }

          // Clear any previous `receiverReadAt` if a new one is being set
          return {
            ...msg,
            receiverReadAt: null,
          };
        });
      });
    }
  };


  const handleTypingEvent = (senderId, senderType) => {
    const currentTargetUserId = targetUserIdRef.current;
    const currentTargetUserType = targetUserTypeRef.current;
    if (senderId === currentTargetUserId && senderType === currentTargetUserType) {
      setIsTyping(true);
      if (typingTimeout.current) clearTimeout(typingTimeout.current);
      typingTimeout.current = setTimeout(() => setIsTyping(false), 3000);
    }
  };

  const handleIsRecordingEvent = (senderId, senderType, isRecording) => {
    const currentTargetUserId = targetUserIdRef.current;
    const currentTargetUserType = targetUserTypeRef.current;

    if (senderId === currentTargetUserId && senderType === currentTargetUserType) {
      setIsTargetRecording(isRecording);
    }
  };
  const deselectUser = () => {
    setMessages([]);
    setSelectedUser(null);
    setTargetUserId(null);
    setTargetUserType(null);
    setTargetUserName(null);
    setFetchedPages(new Set());
  };
  const handleUserActive = (userId, active) => {
    const currentTargetUserId = targetUserIdRef.current;
    if (userId === currentTargetUserId) {
      setIsOnline(active);
    }
  };

  const onUserSelect = async (item) => {

    // If the selected user is the same, trigger deselect and reselect
    if (item.userId === targetUserId && item.userType === targetUserType) {
      deselectUser();
      setTimeout(() => {
        selectUser(item);
      }, 0); // Short delay to allow deselection to take effect
    } else {
      selectUser(item);
    }
  };

  const onLabelSelect = (label) => {
    onOpenManageLabelModal(label, allowedUsers);
  };


  const onLabelDelete = async () => {
    try {
      if (itemToDelete) {
        await LabelService.deleteLabel(itemToDelete.labelId);
        const updatedLabels = labels.filter((l) => l.labelId !== itemToDelete.labelId); // Assuming you have a labels state
        setLabels(updatedLabels);
      }
    } catch (error) {
      console.error('Error deleting label:', error);
    } finally {
      setIsModalVisible(false);
      setItemToDelete(null);
    }
  };


  // Separate the user selection logic into its own function
  const selectUser = async (item) => {
    setTargetUserName(item.userName);
    setTargetUserId(item.userId);
    setTargetUserType(item.userType);
    setGetAllForReceiver(false)
    // Mark as selected user
    setSelectedUser({
      userId: item.userId,
      userType: item.userType,
      name: item.userName,
    });
    const isSelectedUserOnline = await ChatApiService.checkUserOnlineStatus(item.userId);
    setIsOnline(isSelectedUserOnline);
    // Read any unread messages
    await ChatApiService.readMessage(userId, userType, item.userId, item.userType);
    await refreshAllowedUsers(userId, userType);
  };

  /**
   * ------------------------------
   *          CRUD & APIS
   * ------------------------------
   */

  // Refresh the list of allowed users (or labels)
  const refreshAllowedUsers = async (uId, uType) => {
    if (!uId || !uType) return;
    try {

      if (uType.toString() !== '1') {

        // fetch labels
        const labelList = await LabelService.listLabels();
        setLabels(labelList);
      }

      // fetch allowed users
      const data = await ChatApiService.getAllowedUsers(uId, uType);
      data.sort((a, b) => b.unreadMessagesCount - a.unreadMessagesCount);
      setAllowedUsers(data);
    } catch (err) {
      console.error('Error fetching allowed users:', err);
    }
  };

  // Check if user is online (dummy - adapt to your actual logic)
  const checkUserOnline = async (uId) => {
    const isOnlineStatus = await ChatApiService.checkUserOnlineStatus(uId);
    setIsOnline(isOnlineStatus);
  };

  /**
   * ------------------------------
   *      Messages & Pagination
   * ------------------------------
   */

  const reloadMessages = async (checkPage = true) => {
    setFetchedPages(new Set());
    setPageNumber(1);
    setHasMore(true);
    setLowestPageLoaded(1);
    setHighestPageLoaded(1);
    await refreshAllowedUsers(currentUserIdRef.current, currentUserTypeRef.current);
    await fetchMessages(1, checkPage);
  };

  // If you need a separate function to reload by passing IDs explicitly
  const reloadMessagesFor = async (senderId, senderType, tId, tType) => {
    setPageNumber(1);
    setHasMore(true);
    setFetchedPages(new Set());
    setLowestPageLoaded(1);
    setHighestPageLoaded(1);
    await fetchMessages2(1, senderId, senderType, tId, tType);
  };

  const getSenderTitle = (senderId) => {
    const matchedUser = allowedUsers.find((user) => user.userId === senderId);
    return matchedUser ? matchedUser.userName : 'unknown';
  };

  // Main fetch messages
  // Updated fetchMessages
  const fetchMessages = async (page, checkPage = true, GetAllForReceiver = false) => {


    if (!targetUserId || !targetUserType) return;

    // Check if page already fetched
    if (checkPage && fetchedPages.has(page)) {
      return;
    }
    const senderName = await AuthService.getCurrentUserTypeName();
    try {
      const data = await ChatApiService.fetchMessages(userId, userType, targetUserId, targetUserType, page, pageSize, GetAllForReceiver);

      const fetchedMessages = data.messages.map(msg => {

        const message = {
          id: msg.id,
          position: msg.senderId === userId ? 'right' : 'left',
          type:
            msg.messageType === 1
              ? 'photo'
              : msg.messageType === 3
                ? 'audio'
                : msg.messageType === 2
                  ? 'file'
                  : 'text',
          text: msg.messageType === 0 ? msg.messageContent : '',
          date: new Date(msg.messageDate),
          // Use dateString to force a custom format:
          dateString: formatMessageDate(msg.messageDate),
          receiverReadAt: msg.receiverReadAt ? new Date(msg.receiverReadAt) : null,
          title: msg.senderId === userId ? senderName : GetAllForReceiver ? getSenderTitle(msg.senderId) : targetUserName,
          data: {},
        };


        // handle files/audios/photos
        // Handle multiple file URLs for photos, audio, and files
        if (msg.messageType === 1 && msg.fileUrls?.length > 0) {
          // For photos
          message.data = { uris: msg.fileUrls }; // Store all image URLs
        } else if (msg.messageType === 3 && msg.fileUrls?.length > 0) {
          // For audio
          message.data = { audioURLs: msg.fileUrls }; // Store all audio URLs
        } else if (msg.messageType === 2 && msg.fileUrls?.length > 0) {
          // For files
          message.data = { fileURLs: msg.fileUrls }; // Store all file URLs
        }

        return message;
      }).reverse();

      if (page < lowestPageLoaded) {
        setMessages(prev => [...prev, ...fetchedMessages]);
        setLowestPageLoaded(page);
      } else if (page > highestPageLoaded) {
        setMessages(prev => [...fetchedMessages, ...prev]);
        setHighestPageLoaded(page);
      } else {
        setMessages(fetchedMessages);
        setLowestPageLoaded(page);
        setHighestPageLoaded(page);
      }

      setFetchedPages(prevSet => new Set(prevSet).add(page));

      if (fetchedMessages.length < pageSize) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const fetchMessages2 = async (page, senderId, senderType, targetId, targetType) => {

    if (!targetId || !targetType) return;

    const senderName = await AuthService.getCurrentUserTypeName();
    try {
      const data = await ChatApiService.fetchMessages(senderId, senderType, targetId, targetType, page, pageSize, getAllForReceiver);

      const fetchedMessages = data.messages.map(msg => {

        const message = {
          id: msg.id,
          position: msg.senderId === currentUserIdRef.current ? 'right' : 'left',
          type:
            msg.messageType === 1
              ? 'photo'
              : msg.messageType === 3
                ? 'audio'
                : msg.messageType === 2
                  ? 'file'
                  : 'text',
          text: msg.messageType === 0 ? msg.messageContent : '',
          date: new Date(msg.messageDate),
          // Use dateString to force a custom format:
          dateString: formatMessageDate(msg.messageDate),
          receiverReadAt: msg.receiverReadAt || null,
          title: msg.senderId === currentUserIdRef.current ? senderName : targetUserName,
          data: {},
        };


        // handle files/audios/photos
        // Handle multiple file URLs for photos, audio, and files
        if (msg.messageType === 1 && msg.fileUrls?.length > 0) {
          // For photos
          message.data = { uris: msg.fileUrls }; // Store all image URLs
        } else if (msg.messageType === 3 && msg.fileUrls?.length > 0) {
          // For audio
          message.data = { audioURLs: msg.fileUrls }; // Store all audio URLs
        } else if (msg.messageType === 2 && msg.fileUrls?.length > 0) {
          // For files
          message.data = { fileURLs: msg.fileUrls }; // Store all file URLs
        }

        return message;
      }).reverse();

      if (page < lowestPageLoaded) {
        setMessages(prev => [...prev, ...fetchedMessages]);
        setLowestPageLoaded(page);
      } else if (page > highestPageLoaded) {
        setMessages(prev => [...fetchedMessages, ...prev]);
        setHighestPageLoaded(page);
      } else {
        setMessages(fetchedMessages);
        setLowestPageLoaded(page);
        setHighestPageLoaded(page);
      }

      setFetchedPages(prevSet => new Set(prevSet).add(page));

      if (fetchedMessages.length < pageSize) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleImageClick = (uri) => {

    window.open(uri, '_blank');
  };

  const handleDelete = (label) => {

    setItemToDelete(label);
    setIsModalVisible(true);
  };
  const handleCloseDelete = () => {
    setItemToDelete(null);
    setIsModalVisible(false);
  };
  // Scroll-to-load logic
  const handleScroll = ({ scrollTop, scrollHeight, clientHeight }) => {
    // Check if at the top

    

    if (scrollTop === 0) {
      loadOlderMessages();
    }

    // Check if at the bottom
    if (scrollTop + clientHeight >= scrollHeight) {
      loadNewerMessages();
    }
  };


  const loadOlderMessages = async () => {
    const nextPage = highestPageLoaded + 1;
    if (hasMore && !fetchedPages.has(nextPage)) {
      
      await fetchMessages(nextPage, true, getAllForReceiver);
      setHighestPageLoaded(nextPage);
    }
  };

  const loadNewerMessages = async () => {

    // Only load if `lowestPageLoaded` is greater than 1, 
    // meaning there is a page before the currently lowest loaded page
    if (lowestPageLoaded > 1) {
      const nextPage = lowestPageLoaded - 1;
      // Check if we haven't already fetched this page
      if (!fetchedPages.has(nextPage)) {
        await fetchMessages(nextPage, true, getAllForReceiver);
        setLowestPageLoaded(nextPage);
      }
    }
  };
  /**
   * ------------------------------
   *         Sending Messages
   * ------------------------------
   */

  // Basic text message send
  const handleSend = async () => {
    if (!inputValue.trim()) return;
    try {
      const sendingMsg = {
        _id: 'local-' + Date.now(),
        position: 'right',
        text: inputValue,
        type: 'text',
        date: new Date(),
        senderName: 'You',
      };

      // Show message optimistically in UI
      setMessages((prev) => [...prev, sendingMsg]);

      // Actually send message
      const senderName = await AuthService.getCurrentUserTypeName();
      const formData = new FormData();
      formData.append('SenderId', currentUserIdRef.current);
      formData.append('SenderName', senderName);
      formData.append('SenderType', currentUserTypeRef.current.toString());
      formData.append('ReceiverId', targetUserIdRef.current);
      formData.append('ReceiverType', targetUserTypeRef.current.toString());
      formData.append('MessageType', '0'); // text
      formData.append('MessageContent', inputValue);

      await ChatApiService.sendMessage(formData);

      // Notify server that a message was sent
      if (chatService.current && chatService.current.isConnected) {
        await chatService.current.notifyUserSend(
          targetUserIdRef.current,
          targetUserTypeRef.current,
        );
      }

      // Clear input
      setInputValue('');
    } catch (err) {
      console.error('Error sending message:', err);
      // Optionally mark the message as failed in UI
    }
  };

  // Trigger hidden file input
  const handleAttachFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // reset so onChange always fires
      fileInputRef.current.click();
    }
  };

  // Handle user selecting files
  const handleFileChange = async (e) => {
    const { files } = e.target;
    if (!files || files.length === 0) return;

    // For this example, we'll handle multiple files in a single message (adjust as needed).
    const senderName = await AuthService.getCurrentUserTypeName();
    const formData = new FormData();
    formData.append('SenderId', currentUserIdRef.current);
    formData.append('SenderName', senderName);
    formData.append('SenderType', currentUserTypeRef.current.toString());
    formData.append('ReceiverId', targetUserIdRef.current);
    formData.append('ReceiverType', targetUserTypeRef.current.toString());

    // We pick messageType based on the first file's MIME type (you can do more elaborate checks):
    let msgType = '2'; // default: file
    if (files[0].type.startsWith('image/')) {
      msgType = '1'; // image
    } else if (files[0].type.startsWith('audio/')) {
      msgType = '3'; // audio
    }

    formData.append('MessageType', msgType);
    formData.append('MessageContent', ''); // or any text

    // Append all selected files
    Array.from(files).forEach((file) => {
      formData.append('Files', file);
    });

    // Optimistic UI
    const localMessage = {
      _id: 'local-' + Date.now(),
      position: 'right',
      text:
        msgType === '1'
          ? 'Sending image...'
          : msgType === '3'
            ? 'Sending audio...'
            : 'Sending file(s)...',
      type: 'text',
      date: new Date(),
      senderName: 'You',
    };
    setMessages((prev) => [...prev, localMessage]);

    try {
      await ChatApiService.sendMessage(formData);

      // Notify server that a message was sent
      if (chatService.current && chatService.current.isConnected) {
        await chatService.current.notifyUserSend(
          targetUserIdRef.current,
          targetUserTypeRef.current,
        );
      }
    } catch (err) {
      console.error('Error uploading file(s):', err);
      // Optionally mark message as failed
    }
  };
  const formatMessageDate = (date) => {
    // If it’s older than a day, show `DD/MM HH:mm`
    // Otherwise, you might still show the same format or another. 
    // For simplicity, we'll *always* show "DD/MM HH:mm" in Israel time:
    return moment(date).tz('Asia/Jerusalem').format('DD/MM HH:mm');
  };

  /**
   * ------------------------------
   *        Searching / Filtering
   * ------------------------------
   */
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter the user list
  const filteredAllowedUsers = useMemo(() => {
    let filtered = allowedUsers;

    const trimmedSearchQuery = listSearchedText?.trim()?.toLowerCase();

    if (trimmedSearchQuery) {
      filtered = filtered.filter(user => {
        const nameMatch = user?.userName?.toLowerCase()?.includes(trimmedSearchQuery);
        const idMatch = user?.userId?.toLowerCase()?.includes(trimmedSearchQuery);
        const unreadMatch = user?.unreadMessagesCount?.toString().includes(trimmedSearchQuery);
        return nameMatch || idMatch || unreadMatch;
      });
    }

    if (selectedOptions.includes('read')) {
      filtered = filtered.filter(user => user?.unreadMessagesCount === 0);
    } else if (selectedOptions.includes('unread')) {
      filtered = filtered.filter(user => user?.unreadMessagesCount > 0);
    }

    if (selectedOptions.includes('business')) {
      filtered = filtered.filter(user => user.userType === 1);
    }

    if (selectedOptions.includes('office')) {
      filtered = filtered.filter(user => user.userType !== 1);
    }

    // Filter by multiple selected CaseTypeOptions
    const selectedCaseTypes = selectedOptions.filter(option =>
      Constants.CaseTypeOptions.some(o => o.value === option)
    );
    if (selectedCaseTypes.length > 0) {
      filtered = filtered.filter(user =>
        selectedCaseTypes.includes(user.caseType)
      );
    }

    // Filter by multiple selected AccountManagementOptions
    const selectedAccountManagements = selectedOptions.filter(option =>
      Constants.AccountManagementOptions.some(o => o.value === option)
    );
    if (selectedAccountManagements.length > 0) {
      filtered = filtered.filter(user =>
        selectedAccountManagements.includes(user.accountManagement)
      );
    }

    // Filter by multiple selected ReportingTypeOptions
    const selectedReportingTypes = selectedOptions.filter(option =>
      Constants.ReportingTypeOptions.some(o => o.value === option)
    );
    if (selectedReportingTypes.length > 0) {
      filtered = filtered.filter(user =>
        selectedReportingTypes.includes(user.reportingType)
      );
    }

    return filtered;
  }, [allowedUsers, listSearchedText, selectedOptions]);

  const filteredLabels = useMemo(() => {
    let filtered = labels;

    // Apply search query filter
    const trimmedSearchQuery = listSearchedText?.trim()?.toLowerCase();
    if (trimmedSearchQuery) {
      filtered = filtered.filter(label => {
        const nameMatch = label?.labelName?.toLowerCase()?.includes(trimmedSearchQuery);
        const userMatch = label?.users?.some(user =>
          user?.userName?.toLowerCase()?.includes(trimmedSearchQuery)
        );
        return nameMatch || userMatch;
      });
    }

    return filtered;
  }, [labels, listSearchedText]);
  const handleSendMessage = async (message) => {
    const senderName = await AuthService.getCurrentUserTypeName();
    const newMessage = {
      id: uuid(), // Temporary unique ID for the UI
      position: "right", // Since the sender is always the current user
      type: message.type || "text",
      text: message.text || "",
      date: moment().tz("Asia/Jerusalem").toDate(),
      dateString: formatMessageDate(moment().tz("Asia/Jerusalem").toDate()),
      title: senderName,
      data: {},
      sending: true, // Mark as sending
    };

    if (message.type === "photo") {
      newMessage.data = { uris: message.files?.map((file) => URL.createObjectURL(file)) || [] };
    } else if (message.type === "audio") {
      newMessage.data = { audioURLs: message.files?.map((file) => URL.createObjectURL(file)) || [] };
    } else if (message.type === "file") {

      newMessage.data = { fileURLs: message.files?.map((file) => `${URL.createObjectURL(file)}|${file.name}`) || [] };

    }

    // Add the message to the UI with a `sending` status
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    // Create FormData for the API
    const formData = new FormData();
    formData.append("SenderId", userId);
    formData.append("SenderName", senderName);
    formData.append("SenderType", userType);
    formData.append("ReceiverId", targetUserId);
    formData.append("ReceiverType", targetUserType);
    formData.append("MessageType", message.type === "text" ? "0" : message.type === "photo" ? "1" : message.type === "audio" ? "3" : "2");
    formData.append("MessageContent", message.text || "");

    if (message.files) {
      message.files.forEach((file) => {
        formData.append("Files", file, file.name);
      });
    }

    try {
      const response = await ChatApiService.sendMessage(formData);

      // Update the message in the UI to reflect success
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === newMessage.id
            ? { ...msg, id: response.id, sending: false, date: new Date(response.messageDate), dateString: formatMessageDate(response.messageDate) }
            : msg
        )
      );
    } catch (error) {
      console.error("Error sending message:", error);

      // Mark the message as failed
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === newMessage.id ? { ...msg, sending: "failed" } : msg
        )
      );
    }
  };


  const handleAttachFiles = (files) => {
    const formattedFiles = Array.from(files).map((file) => ({
      id: uuid(),
      file,
      name: file.name,
    }));

    // Send files as a message
    handleSendMessage({
      type: "file",
      files: formattedFiles.map((file) => file.file),
    });
  };


  const retrySendMessage = (failedMessage) => {
    // Retry the same message
    handleSendMessage({
      text: failedMessage.text,
      type: failedMessage.type,
      files: failedMessage.data?.uris || failedMessage.data?.audioURLs || failedMessage.data?.fileURLs,
    });
  };

  const handleRecordingStart = async () => {
    try {
      if (isRecording) {
        alert("Recording is already in progress.");
        return;
      }

      // Request microphone access
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        alert("Microphone access is not supported in your browser.");
        return;
      }

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);

      // Clear previous audio chunks
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data); // Store chunks in ref
        }
      };
      if (chatService.current) {
        chatService.current.isRecording(targetUserIdRef.current, targetUserTypeRef.current, true);
      }
      setRecorder(mediaRecorder); // Save recorder instance
      setIsRecording(true);
      mediaRecorder.start();
    } catch (error) {
      console.error("Error starting recording:", error);
      alert("Failed to start recording.");
      setIsRecording(false);
    }
  };

  const handleRecordingStop = async () => {
    try {
      if (!isRecording || !recorder) {
        alert("No recording is in progress.");
        return;
      }

      // Notify the recipient about recording stop
      if (chatService.current) {
        chatService.current.isRecording(targetUserIdRef.current, targetUserTypeRef.current, false);
      }

      recorder.stop();

      recorder.onstop = async () => {
        setIsRecording(false);

        // Create a Blob from audio chunks
        const audioBlob = new Blob(audioChunksRef.current, { type: "audio/mp4" });
        const audioUrl = URL.createObjectURL(audioBlob);

        // Get sender information
        const senderName = await AuthService.getCurrentUserTypeName();

        // Optimistic UI update with new message structure
        const newMessage = {
          id: uuid(), // Temporary unique ID for UI
          position: "right", // Always right for the sender
          type: "audio", // Type of the message
          text: "", // Audio messages have no text content
          date: moment().tz("Asia/Jerusalem").toDate(),
          dateString: formatMessageDate(moment().tz("Asia/Jerusalem").toDate()),
          title: senderName,
          data: { audioURLs: [audioUrl] }, // Store the audio URL
          sending: true, // Mark as sending
        };

        // Add the new message to the chat UI
        setMessages((prevMessages) => [...prevMessages, newMessage]);

        try {
          // Create FormData for API call
          const formData = new FormData();
          formData.append("SenderId", userId);
          formData.append("SenderName", senderName);
          formData.append("SenderType", userType);
          formData.append("ReceiverId", targetUserId);
          formData.append("ReceiverType", targetUserType);
          formData.append("MessageType", "3"); // Audio message type
          formData.append("MessageContent", "");

          const file = new File([audioBlob], `audio-${Date.now()}.mp4`, {
            type: "audio/mp4",
          });
          formData.append("Files", file);

          // Send the message via API
          const response = await ChatApiService.sendMessage(formData);

          // Update the message in the UI to reflect success
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === newMessage.id
                ? {
                  ...msg,
                  id: response.id,
                  sending: false,
                  date: new Date(response.messageDate),
                  dateString: formatMessageDate(response.messageDate),
                }
                : msg
            )
          );

        } catch (error) {
          console.error("Error sending audio message:", error);

          // Mark the message as failed
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === newMessage.id ? { ...msg, sending: "failed" } : msg
            )
          );
        }
      };
    } catch (error) {
      console.error("Error stopping recording:", error);
      alert("Failed to stop recording.");
    }
  };



  const handleCancelRecording = () => {
    try {
      if (!isRecording || !recorder) {
        alert("No recording is in progress.");
        return;
      }
      // Notify the recipient about recording stop
      if (chatService.current) {
        chatService.current.isRecording(targetUserIdRef.current, targetUserTypeRef.current, false);
      }
      recorder.stop();
      setIsRecording(false);
      setRecorder(null);
      audioChunksRef.current = []; // Clear recorded chunks
    } catch (error) {
      console.error("Error canceling recording:", error);
    }
  };


  /**
   * ------------------------------
   *       Draggable Modal
   * ------------------------------
   */
  if (!isOpen) {
    // Keep the component's hooks in place but don't render anything
    return createPortal(null, container);
  }





  // The actual UI of the chat (web-based)
  const modalContent = (
    <Draggable nodeRef={draggableNodeRef} handle=".handle" defaultPosition={{ x: 0, y: 0 }}>
      <div ref={draggableNodeRef} className={`chat-modal-container ${direction}`}>
        {/* HEADER */}
        <div className={`chat-modal-header handle`}>
          <div className="header-left-section">
            <span className="chat-app-title">{t('Chat')}</span>
          </div>
          <div className="header-right-section">
            <button className="close-btn" onClick={onClose}>
              <FiX size={18} />
            </button>
          </div>
        </div>

        {/* MAIN LAYOUT */}
        <div className="chat-page-layout">
          {/* LEFT SIDEBAR */}
          <div className="chat-page-sidebar">
            <div className="chat-page-sidebar-upper">

              <div className="sidebar-header">
                {(viewMode === 'users' && currentUserTypeRef.current?.toString() !== Constants.UserType.Client) && (
                  <FilterOptionMenu
                    optionGroups={optionGroups}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    height='48px'
                  />
                )}

                <TextField
                  fullWidth
                  placeholder={t('searchClient')}
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="search-bar"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                    sx: {
                      height: '48px',
                      padding: '0 10px',
                      borderRadius: '8px',
                      background: 'white',
                      border: '1px solid transparent',
                      '&:focus-within': {
                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                      },
                    },
                    ...(direction === 'ltr'
                      ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" style={{ marginRight: '10px' }}>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }
                      : {
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start" style={{ marginLeft: '10px' }}>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }),
                  }}
                  inputProps={{
                    style: {
                      textAlign: direction === 'ltr' ? 'left' : 'right',
                    },
                  }}
                />

              </div>
              {
                currentUserTypeRef.current?.toString() !== Constants.UserType.Client &&
                <div className={`sidebar-viewmode ${direction}`}>
                  <button
                    className={viewMode === 'users' ? 'active' : ''}
                    onClick={() => setViewMode('users')}
                  >
                    <FaUserFriends />
                    {t('Users')}
                  </button>
                  <button
                    className={viewMode === 'labels' ? 'active' : ''}
                    onClick={() => setViewMode('labels')}
                  >
                    <FaUsers />
                    {t('DistributionList')}
                  </button>
                </div>
              }

            </div>

            {viewMode === 'users' && (
              // <div className="chat-list-container">

              // </div>
              <ContactsList
                filteredAllowedUsers={filteredAllowedUsers}
                onUserSelect={onUserSelect}
                isLoading={false}
                t={t}
              />
            )}

            {viewMode === 'labels' && (
              <>

                <div className="create-label-modal-add-remove-container">
                  <div
                    className="create-label-modal-add-button"
                    onClick={handleOpenModal}
                  >
                    <AddCircleIcon className="create-label-modal-add-icon" />
                  </div>
                </div>
                <LabelsList
                  labels={filteredLabels} // Array of label objects
                  onLabelSelect={onLabelSelect} // Function to handle label selection
                  onLabelDelete={handleDelete} // Function to handle label deletion
                  translate={t} // Translation function
                  isLoading={loading} // Boolean for loading state
                />
              </>

            )}
          </div>

          {/* MAIN CHAT AREA */}

          <div className="chat-page-main">
            {isSearchMode ? (
              <>
                <ChatHeader
                  selectedUser={selectedUser}
                  isTyping={isTyping}
                  isTargetRecording={isTargetRecording}
                  isOnline={isOnline}
                  deselectUser={deselectUser}
                  handleSearchSubmit={handleSearchSubmit}
                  onCancelSearch={onCancelSearch}
                  isSearchMode={isSearchMode}
                  setIsSearchMode={setIsSearchMode}
                  searchChatQuery={searchChatQuery}
                  setSearchChatQuery={setSearchChatQuery}
                  getAllForReceiver={getAllForReceiver}
                  setGetAllForReceiver={changeForReceiver}
                  userType={currentUserTypeRef.current}
                />

                <div className="search-results">
                  {searchResults.length > 0 ? (
                    searchResults.map((item) => (
                      <SearchItem
                        key={item.messageId}
                        item={item}
                        currentUserId={currentUserIdRef.current}
                        targetName={targetUserNameRef.current}
                        onSelect={handleSelectSearchResult}
                      />
                    ))

                  ) : (
                    <p className="no-results">{t('NoResultForYourSearch')}</p>
                  )}

                </div>

              </>

            ) : selectedUser ? (
              <>
                <ChatHeader
                  selectedUser={selectedUser}
                  isTyping={isTyping}
                  isTargetRecording={isTargetRecording}
                  isOnline={isOnline}
                  deselectUser={deselectUser}
                  handleSearchSubmit={handleSearchSubmit}
                  onCancelSearch={onCancelSearch}
                  isSearchMode={isSearchMode}
                  setIsSearchMode={setIsSearchMode}
                  searchChatQuery={searchChatQuery}
                  setSearchChatQuery={setSearchChatQuery}
                  getAllForReceiver={getAllForReceiver}
                  setGetAllForReceiver={changeForReceiver}
                  userType={currentUserTypeRef.current}
                />

                <div className="chat-page-messages">
                  <ChatMessagesList
                    messages={messages}
                    containerHeight={650}
                    onClickImage={handleImageClick}
                    onScroll={handleScroll}
                    lowestPageLoaded={lowestPageLoaded}
                    highestPageLoaded={highestPageLoaded}
                    getAllForReceiver={getAllForReceiver}
                    refreshMessages={reloadMessages}
                  />
                </div>


                <ChatInputBar
                  handleSendMessage={handleSendMessage}
                  handleAttachFiles={(files) => handleAttachFiles(files)}
                  handleRecordingStart={handleRecordingStart}
                  handleRecordingStop={handleRecordingStop}
                  handleCancelRecording={handleCancelRecording}
                  setIsRecording={setIsRecording}
                  isRecording={isRecording}
                  isTyping={isTyping}
                  isTargetRecording={isTargetRecording}
                  chatService={chatService.current}
                  targetUserId={targetUserId}
                  targetUserType={targetUserType}
                />
              </>

            ) :
              (
                <div className="xtram-chat-title">
                  Xtram {t('Chat')}
                </div>
              )}
          </div>


          {loading && (
            <div className="loader-container2">
              <PuffLoader
                size={100}        // Larger size for better visibility
                loading={loading}
              />
            </div>
          )}

        </div>
        <CustomDeleteConfirmationModal
          isVisible={isModalVisible}
          onClose={handleCloseDelete}
          onDelete={onLabelDelete}
          confirmMessage={t('confirmDeleteMessage')}
          titleMessage={t('confirmDeleteTitle')}
        />
        <CreateLabelModal
          visible={showCreateLabelModal} // Boolean to control modal visibility
          onClose={() => setShowCreateLabelModal(false)} // Function to close the modal
          onLabelCreated={onLabelCreated}
          setLoading={setLoading}
          loading={loading}
        />
      </div>
    </Draggable >
  );

  // Render the chat portal
  return createPortal(modalContent, container);
}
