// File: Services/ClientItemsService.js
import config from '../config';
import selectedEnvironment from '../env';

const baseURL = config[selectedEnvironment].API_URL;
const ClientItems_API_URL = `${baseURL}/ClientItems`;

const GetClientItemsByCaseId = async (caseId, auth) => {
    const response = await fetch(`${ClientItems_API_URL}/ByCaseId/${caseId}`, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + auth,
            'Content-Type': 'application/json',
        },
    });
    return response;
};

const AddClientItems = async (items, auth) => {
    const response = await fetch(`${ClientItems_API_URL}/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ Items: items }) // Items is an array of { CaseId, Makat, Name }
    });

    return response;
};

const UpdateClientItem = async (caseId, originalMakat, newMakat, newName, newPrice, auth) => {
    // The "Update" endpoint expects a body with:
    // {
    //   "CaseId": string,
    //   "Makat": string,        // new or updated
    //   "Name": string,
    //   "Price": float,
    //   "OriginalMakat": string // important if Makat can change
    // }

    const body = {
        CaseId: caseId,
        Makat: newMakat,
        Name: newName,
        Price: newPrice,
        OriginalMakat: originalMakat,
    };

    const response = await fetch(`${ClientItems_API_URL}/Update`, {
        method: 'PUT',
        headers: {
            Authorization: 'Bearer ' + auth,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    return response;
};

const DeleteClientItem = async (makat, caseId, auth) => {
    const response = await fetch(`${ClientItems_API_URL}/Delete/${makat}/${caseId}`, {
        method: 'DELETE',
        headers: {
            Authorization: 'Bearer ' + auth,
            'Content-Type': 'application/json',
        },
    });
    return response;
};

const ClientItemsService = {
    GetClientItemsByCaseId,
    AddClientItems,
    UpdateClientItem,
    DeleteClientItem,
};

export default ClientItemsService;
