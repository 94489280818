import {
     Tooltip
} from '@mui/material';
import { beautifyNumber } from '../../../Utils/FormatNumber';
function VatTooltip({ vatObject, displayValue, beautifyNumber, label17, label18 }) {
    return (
        <Tooltip
            arrow
            placement="top"
            title={
                <div style={{ lineHeight: 1.5 }}>
                    <strong>{label17}:</strong> {parseInt(vatObject?.vat17 || 0)}<br />
                    <strong>{label18}:</strong> {parseInt(vatObject?.vat18 || 0)}
                </div>
            }
        >
            <div className="client-vat-amount">
                {beautifyNumber(displayValue)}
            </div>
        </Tooltip>
    );
}

export default VatTooltip;