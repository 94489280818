import React, { useEffect, useState, useRef, useContext } from 'react';
import {
    TextField,
    Autocomplete,
    RadioGroup,
    FormControlLabel,
    Radio,
    Popper,
    Tooltip,
    IconButton,
    Portal,
    Button,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell
} from '@mui/material';
import Draggable from 'react-draggable';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';
import SaveIcon from '@mui/icons-material/SaveAsRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/system';
import { PermissionsContext } from '../../../Contexts/Permissions';
import DocumentTransferDialog from '../../DocumentTransferDialog/DocumentTransferDialog';
import AddAccountCardModal from '../../Widgets/AddAccountCardModal/AddAccountCardModal';
import Iframe from 'react-iframe';
import Constants from '../../../assests/Constants/constants';
import addIcon from '../../../assests/images/Icons/addIcon.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FilePreview } from '../../../Utils/filePreview';
import CustomMonthDatePicker from '../../Widgets/CustomDates/CustomMonthDatePicker/CustomMonthDatePicker';
// IMPORTANT: set the workerSrc for react-pdf
import dayjs, { Dayjs } from 'dayjs';
import NoDocumentsPdf from "../../../assests/pdf/NoDocument.pdf";
import FilePreviewCarousel from './FilePreviewCarousel'
import { IoArrowBackCircle, IoArrowForwardCircle } from 'react-icons/io5';
// Helper function to render each thumbnail in the slider
const renderFilePreview = (fileUrl, idx, currentIndex, setDocIndex, t) => {
    if (idx === currentIndex) return null; // Hide the currently selected document

    return (
        <div key={idx} onClick={() => setDocIndex(idx)} style={{ cursor: 'pointer' }}>
            <FilePreview
                fileUrl={fileUrl}
                t={t}
                openPreview={() => setDocIndex(idx)} // Update document index on click
                width={100}
                height={100}
            />
        </div>
    );
};



const StyledListItem = styled('li')({
    '&:hover': {
        backgroundColor: '#F5F5F6',
        cursor: 'pointer',
    },
});

const StyledPopper = styled(Popper)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 'var(--Spacing-md, 8px)',
        background: 'var(--white, #FFF)',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.32)',
    },
    '& .MuiAutocomplete-listbox': {
        padding: theme.spacing(1),
    },
    '& .MuiAutocomplete-option': {
        borderRadius: 'var(--Spacing-sm, 4px)',
        '&[aria-selected="true"], &.Mui-focused, &:hover': {
            background: 'var(--orange, #F9AA2A) !important',
        },
    },
}));

// ============== STYLED TABLE COMPONENTS ==============
const StyledTableContainer = styled(TableContainer)({
    marginTop: '20px',
    width: '100%',
    boxShadow: 'none',
    borderRadius: 0,
    // Removed the fixed maxHeight: '250px' to allow more flexible scrolling.
    // If you still want to limit the height, you can do:
    // maxHeight: '400px',
    // overflowY: 'auto',
});

const StyledTable = styled(Table)({
    borderCollapse: 'collapse',
});

const StyledTableHead = styled(TableHead)({
    backgroundColor: '#BFC8FF',
    '& .MuiTableCell-head': {
        backgroundColor: '#BFC8FF',
        color: '#304FFF',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        textAlign: 'center',
        textTransform: 'uppercase',
        borderBottom: '2px solid #d3d3d3',
        position: 'sticky',
        top: 0,
    },
});

const StyledTableRow = styled(TableRow)({
    '&:hover': {},
});

const StyledTableCellHeader = styled(TableCell)({
    backgroundColor: '#BFC8FF',
    color: '#304FFF',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center',
    textTransform: 'uppercase',
    borderBottom: '2px solid #d3d3d3',
    position: 'sticky',
    top: 0,
});

const StyledTableCell = styled(TableCell)({
    borderTop: '1px solid rgba(191, 191, 193, 0.56)',
    backgroundColor: 'rgba(234, 237, 255, 0.32)',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    textAlign: 'center',
});

const PaymentAcceptanceCreateEditModal = ({
    openInvoiceCreatorModal,
    handleClose,
    t,
    itemEditing,
    addEditableItem,
    setPaymentDate,
    setReportingMonthDate,
    handleChangeAccountType,
    handleDescriptionChange,
    handleChangeOriginalTaxClearance,
    handleAddEditableItemChange,
    handleRadioChange,
    handleAddItem,
    handleItemChange,
    handleRemoveItem,
    accountCardOptions,
    handleSave,
    handleClickDoneCreation,
    buttonDisabled,
    isClosedReportingMonthAndIsRange,
    isClosedReportingMonth,
    isRange,
    paymentBeingEdited,
    setAccount,
    handleSaveEditAccountCard,
    handleAddAccountCard,
    handleCloseAddNewAccountCardDocument,
    handleAccountEdit,
    handleAccountDelete,
    handleMuiChange,
    companyId,
    accountCodeName,
    accountCodeId,
    palCode,
    setPalCode,
    customStyles,
    client,
    setLoading,
    handleTransferRefresh,
    token,
    statusCheck,
    selectedOption,
    type,
    direction,
    handlePrevious,
    handleNext,
    currentIndex,
    filteredAndSortedData,
    showIframe = true,
    fetchEnums,
    showType = { type: 'all', index: -1 },
    handleDeleteClick,
}) => {
    const { userTypeContext, permissionsState } = useContext(PermissionsContext);

    const nodeRef = useRef(null);
    const [container] = useState(() => document.createElement('div'));

    // Show/hide PDF iFrame
    const [showPdfIframe, setShowPdfIframe] = useState(true);

    // For the “Add Account Card” modal
    const [addNewAccountCardDocument, setAddNewAccountCardDocument] = useState(false);
    const [showAccountCardInputsEdit, setShowAccountCardInputsEdit] = useState(false);
    const [newAccountCard, setNewAccountCard] = useState({
        accountCodeName: '',
        accountCodeId: '',
        accountCaseId: '',
    });
    const [addAccountCardType, setAddAccountCardType] = useState('main');
    const [searchTerm, setSearchTerm] = useState('');
    const [cardIdOption, setCardIdOption] = useState(-1);

    // Flatten all relevant doc URLs, ignoring null/empty strings.
    const allDocUrls = React.useMemo(() => {
        const docArray = [];
        // push only if not null/undefined/empty
        if (addEditableItem?.receiptDocumentUrl) {
            docArray.push(addEditableItem.receiptDocumentUrl);
        }

        // For each check
        addEditableItem?.checks?.forEach((check) => {
            if (check.documentUrl) {
                docArray.push(check.documentUrl);
            }
        });
       
        return docArray;
    }, [addEditableItem]);

    // Index for the doc being viewed
    const [docIndex, setDocIndex] = useState(0);



    const handleToggleIframe = () => {
        setShowPdfIframe((prev) => !prev);
    };

    // MUI Autocomplete for the main “Client/Provider” field
    const handleMuiChangeProxy = (event, value) => {
        if (!value) {
            handleAddEditableItemChange('accountCodeId', null);
            return;
        }
        if (value.value1 === 'AddItem') {
            // open the AddAccountCardModal
            setAddNewAccountCardDocument(true);
            setShowAccountCardInputsEdit(false);
            // create new card with the typed input
            setNewAccountCard({
                accountCodeName: value.searchTerm || '',
                accountCodeId: cardIdOption,
                accountCaseId: '',
            });
        } else {
            handleMuiChange(event, value);
        }
    };

    // If user clicks "No options," we create an artificial option to trigger “AddItem”.
    const handleNoOptionClick = (searchTerm) => {
        if (formattedAccountCardOptions.length > 0) {
            const newVal = {
                ...formattedAccountCardOptions[0],
                value1: 'AddItem',
                searchTerm
            };
            handleMuiChangeProxy(null, newVal);
        }
    };

    // Once a new account is added, we want to select it automatically
    const [newCardId, setNewCardId] = useState(null);
    const handleChangeAfterAdd = (cardId, type) => {
        setNewCardId({ cardId, type });
    };

    useEffect(() => {
        if (accountCardOptions && accountCardOptions.length > 0 && newCardId) {
            const selectedOption = accountCardOptions.find((opt) => opt.cardId === newCardId.cardId);
            handleMuiChange(null, selectedOption);
            // close modal
            setAddNewAccountCardDocument(false);
            setNewCardId(null);
        }
    }, [accountCardOptions, newCardId]);

    useEffect(() => {
        document.body.appendChild(container);
        return () => {
            try {
                document.body.removeChild(container);
            } catch (err) {
                // ignore
            }
        };
    }, [container]);

    useEffect(() => {
        // find the max ID for creating new account
        if (accountCardOptions && accountCardOptions.length > 0) {
            const maxCardId = Math.max(...accountCardOptions.slice(1).map((option) => parseInt(option.cardId, 10)));
            setCardIdOption(maxCardId + 1);
        }
    }, [accountCardOptions]);

    const handleCloseItem = () => {
        setShowAccountCardInputsEdit(false);
        setAddNewAccountCardDocument(false);
    };

    const handleNextDoc = () => {
        if (docIndex < allDocUrls.length - 1) {
            setDocIndex(docIndex + 1);
        }
    };

    const handlePrevDoc = () => {
        if (docIndex > 0) {
            setDocIndex(docIndex - 1);
        }
    };

    // Keyboard navigation
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowRight') handleNextDoc();
            if (e.key === 'ArrowLeft') handlePrevDoc();
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [docIndex]);


    const inputPropsStyle = {
        disableUnderline: true,
        className: 'custom-box-sizing-border-box rtl-input',
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
            '& *': {
                boxSizing: 'border-box !important',
            },
        },
    };

    const inputStyle = {
        style: { textAlign: 'right' },
    };

    const formattedAccountCardOptions = accountCardOptions?.map((option) => ({
        value: option.cardId,
        label: option.name,
        name: option.name,
        accountCaseId: option.accountCaseId || '',
        cardId: option.cardId,
        value1: option.value1,
        palCode: option.palCode,
        isEditable: option.isEditable,
    }));

    // We unify all item arrays into one single array with a new "itemType" property
    const combinedItems = React.useMemo(() => {
        const checks = (addEditableItem?.checks || []).map((chk, idx) => ({ ...chk, itemType: 'checks', itemTypeIndex: idx }));
        const ccs = (addEditableItem?.creditCards || []).map((cc, idx) => ({ ...cc, itemType: 'creditCards', itemTypeIndex: idx }));
        const csh = (addEditableItem?.cash || []).map((c, idx) => ({ ...c, itemType: 'cash', itemTypeIndex: idx }));
        const bts = (addEditableItem?.bankTransactions || []).map((bt, idx) => ({ ...bt, itemType: 'bankTransactions', itemTypeIndex: idx }));
        return [...checks, ...ccs, ...csh, ...bts];
    }, [addEditableItem]);

    const renderTableRows = () => {
        return combinedItems.map((row, index) => {
            const isDisabled = !isRange ? addEditableItem?.isClosedReportingMonth : isClosedReportingMonth;
            return (
                <StyledTableRow key={row.id || `${row.itemType}-${index}`}>
                    {/* Type */}
                    <StyledTableCell>{t(row.itemType)}</StyledTableCell>

                    {/* CheckNumber or Description */}
                    <StyledTableCell>
                        {row.itemType === 'checks' ? (
                            <TextField
                                fullWidth
                                placeholder={t('CheckNumber')}
                                value={row.description || ''}
                                onChange={(e) => handleItemChange('checks', row.itemTypeIndex, 'description', e.target.value)}
                                variant="standard"
                                InputProps={inputPropsStyle}
                                inputProps={inputStyle}
                                disabled={isDisabled}
                            />
                        ) : (
                            <TextField
                                fullWidth
                                placeholder={t('Description')}
                                value={row.description || ''}
                                onChange={(e) => handleItemChange(row.itemType, row.itemTypeIndex, 'description', e.target.value)}
                                variant="standard"
                                InputProps={inputPropsStyle}
                                inputProps={inputStyle}
                                disabled={isDisabled}
                            />
                        )}
                    </StyledTableCell>

                    {/* BankName (only for checks) */}
                    <StyledTableCell>
                        {row.itemType === 'checks' ? (
                            <TextField
                                fullWidth
                                type="text"
                                placeholder={t('BankName')}
                                value={row.bankName || ''}
                                onChange={(e) => handleItemChange('checks', row.itemTypeIndex, 'bankName', e.target.value)}
                                variant="standard"
                                InputProps={inputPropsStyle}
                                inputProps={inputStyle}
                                disabled={isDisabled}
                            />
                        ) : (
                            <span>-</span>
                        )}
                    </StyledTableCell>

                    {/* BankCode (only for checks) */}
                    <StyledTableCell>
                        {row.itemType === 'checks' ? (
                            <TextField
                                fullWidth
                                type="number"
                                placeholder={t('BankCode')}
                                value={row.bankCode || ''}
                                onChange={(e) => handleItemChange('checks', row.itemTypeIndex, 'bankCode', e.target.value)}
                                variant="standard"
                                InputProps={inputPropsStyle}
                                inputProps={inputStyle}
                                disabled={isDisabled}
                            />
                        ) : (
                            <span>-</span>
                        )}
                    </StyledTableCell>

                    <StyledTableCell>
                        {row.itemType === 'checks' ? (
                            <TextField
                                fullWidth
                                type="number"
                                placeholder={t('BankBranchCode')}
                                value={row.bankBranchCode || ''}
                                onChange={(e) => handleItemChange('checks', row.itemTypeIndex, 'bankBranchCode', e.target.value)}
                                variant="standard"
                                InputProps={inputPropsStyle}
                                inputProps={inputStyle}
                                disabled={isDisabled}
                            />
                        ) : (
                            <span>-</span>
                        )}
                    </StyledTableCell>
                    <StyledTableCell>
                        {row.itemType === 'checks' ? (
                            <TextField
                                fullWidth
                                type="number"
                                placeholder={t('SupplierAccountNumber')}
                                value={row.supplierAccountNumber || ''}
                                onChange={(e) => handleItemChange('checks', row.itemTypeIndex, 'supplierAccountNumber', e.target.value)}
                                variant="standard"
                                InputProps={inputPropsStyle}
                                inputProps={inputStyle}
                                disabled={isDisabled}
                            />
                        ) : (
                            <span>-</span>
                        )}
                    </StyledTableCell>

                    {/* Amount */}
                    <StyledTableCell>
                        <TextField
                            fullWidth
                            type="number"
                            placeholder={t('Amount')}
                            value={row.amount || ''}
                            onChange={(e) => handleItemChange(row.itemType, row.itemTypeIndex, 'amount', e.target.value)}
                            variant="standard"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                            disabled={isDisabled}
                        />
                    </StyledTableCell>

                    {/* Payment Date */}
                    <StyledTableCell>
                        <CustomDatePicker
                            date={row.paymentDate ? new Date(row.paymentDate) : null}
                            onDateChange={(dateVal) => handleItemChange(row.itemType, row.itemTypeIndex, 'paymentDate', dateVal)}
                            height="40px"
                            disabled={isDisabled}
                        />
                    </StyledTableCell>

                    {/* Debit Account */}
                    <StyledTableCell>
                        <Autocomplete
                            options={formattedAccountCardOptions}
                            getOptionLabel={(option) => option.label}
                            value={formattedAccountCardOptions.find((opt) => opt.value === row.debitAccount) || null}
                            onChange={(e, selectedOption) => {
                                handleItemChange(
                                    row.itemType,
                                    row.itemTypeIndex,
                                    'debitAccount',
                                    selectedOption ? selectedOption.value : ''
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder={t('Debit Account')}
                                    InputProps={{ ...params.InputProps, ...inputPropsStyle }}
                                    inputProps={{ ...params.inputProps, ...inputStyle }}
                                />
                            )}
                            PopperComponent={(props) => (
                                <Popper
                                    {...props}
                                    style={{
                                        ...props.style,
                                        direction: direction,
                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
                                    }}
                                />
                            )}
                            disabled={isDisabled}
                        />
                    </StyledTableCell>

                    {/* Credit Account */}
                    <StyledTableCell>
                        <Autocomplete
                            options={formattedAccountCardOptions}
                            getOptionLabel={(option) => option.label}
                            value={formattedAccountCardOptions.find((opt) => opt.value === row.creditAccount) || null}
                            onChange={(e, selectedOption) => {
                                handleItemChange(
                                    row.itemType,
                                    row.itemTypeIndex,
                                    'creditAccount',
                                    selectedOption ? selectedOption.value : ''
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder={t('Credit Account')}
                                    InputProps={{ ...params.InputProps, ...inputPropsStyle }}
                                    inputProps={{ ...params.inputProps, ...inputStyle }}
                                />
                            )}
                            PopperComponent={(props) => (
                                <Popper
                                    {...props}
                                    style={{
                                        ...props.style,
                                        direction: direction,
                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
                                    }}
                                />
                            )}
                            disabled={isDisabled}
                        />
                    </StyledTableCell>

                    {/* Remove button */}
                    <StyledTableCell>
                        {!isDisabled && (
                            <Button
                                style={{ color: 'red', minWidth: '20px' }}
                                onClick={() => handleRemoveItem(row.itemType, row.itemTypeIndex)}
                            >
                                <DeleteIcon />
                            </Button>
                        )}
                    </StyledTableCell>
                </StyledTableRow>
            );
        });
    };

    const paymentTypeOptions = [
        { value: t('Approval'), label: t('Approval') },
        { value: t('Payment'), label: t('Payment') },
    ];

    const isDisabledForm = !isRange ? addEditableItem?.isClosedReportingMonth : isClosedReportingMonth;

    return (
        <Portal container={container}>
            <Draggable handle=".handle" nodeRef={nodeRef}>
                {/* 
                    Outer container: a COLUMN layout that can scroll.
                    1) The top row is a flex row with:
                       Left side: Receipt details
                       Right side: PDF preview
                    2) The table is below that entire row. 
                */}
                <div
                    ref={nodeRef}
                    style={{
                        position: 'absolute',
                        top: '10%',
                        left: '30%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        padding: '16px',
                        zIndex: 1300,
                        // Makes the entire modal scroll if content is too tall
                        maxHeight: '90vh',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                        border: '1px solid #304fff',
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: showPdfIframe ? '1200px' : '700px',
                        direction: direction,
                    }}
                >
                    {/* ===================== TOP ROW: Side-by-side ===================== */}
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        {/* ===================== LEFT SIDE (Details) ===================== */}
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                marginRight: '16px',
                            }}
                        >
                            {/* ---------- HEADER (Draggable handle) ---------- */}
                            <div
                                className="handle"
                                style={{
                                    cursor: 'move',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    marginBottom: '16px',
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Tooltip title={t('Exit')}>
                                    <IconButton onClick={handleClose}>
                                        <CancelIcon style={{ color: '#304FFF' }} />
                                    </IconButton>
                                </Tooltip>

                                {/* Next/previous doc controls (for itemEditing) */}
                                <div
                                    className="edit-title-container"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                        margin: '0 auto',
                                    }}
                                >
                                    {/* Next arrow (only shown when editing) */}
                                    {itemEditing && currentIndex !== filteredAndSortedData.length - 1 && (
                                        <div
                                            className={`backIconBackground ${currentIndex === filteredAndSortedData.length - 1
                                                ? 'pointer-events-disable'
                                                : ''
                                                }`}
                                            onClick={handleNext}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    d="M9.00047 15.875L12.8805 11.995L9.00047 8.11498C8.61047 7.72498 8.61047 7.09498 9.00047 6.70498C9.39047 6.31498 10.0205 6.31498 10.4105 6.70498L15.0005 11.295C15.3905 11.685 15.3905 12.315 15.0005 12.705L10.4105 17.295C10.0205 17.685 9.39047 17.685 9.00047 17.295C8.62047 16.905 8.61047 16.265 9.00047 15.875Z"
                                                    fill="black"
                                                    fillOpacity="0.9"
                                                />
                                            </svg>
                                        </div>
                                    )}

                                    {/* Title (always visible) */}
                                    <div className="edit-title">
                                        {itemEditing
                                            ? `${t('EditReceiptPayment')} ${addEditableItem.uploadNumber}`
                                            : t('ApprovalPaymentUpload')}
                                    </div>

                                    {/* Previous arrow (only shown when editing) */}
                                    {itemEditing && currentIndex !== 0 && (
                                        <div
                                            className={`backIconBackground ${currentIndex === 0 ? 'pointer-events-disable' : ''
                                                }`}
                                            onClick={handlePrevious}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="35"
                                                height="35"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    d="M14.9995 15.875L11.1195 11.995L14.9995 8.11498C15.3895 7.72498 15.3895 7.09498 14.9995 6.70498C14.6095 6.31498 13.9795 6.31498 13.5895 6.70498L8.99953 11.295C8.60953 11.685 8.60953 12.315 8.99953 12.705L13.5895 17.295C13.9795 17.685 14.6095 17.685 14.9995 17.295C15.3795 16.905 15.3895 16.265 14.9995 15.875Z"
                                                    fill="black"
                                                    fillOpacity="0.9"
                                                />
                                            </svg>
                                        </div>
                                    )}
                                </div>

                                {/* Toggle PDF visibility */}
                                {allDocUrls.length > 0 && (
                                    <IconButton onClick={handleToggleIframe}>
                                        {showPdfIframe ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                )}
                            </div>

                            {/* ---------- Receipt Details ---------- */}
                            <h3 style={{ marginBottom: '8px' }}>{t('ReceiptDetails')}</h3>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                {/* Payment Acceptance (Client/Provider) */}
                                <div style={{ width: '48%' }}>
                                    <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                                        {addEditableItem.paymentAcceptanceType === 'תקבול'
                                            ? t('Client Name')
                                            : t('Provider Name')}
                                        <span style={{ color: 'red' }}>*</span>
                                    </div>
                                    <Autocomplete
                                        options={formattedAccountCardOptions}
                                        getOptionLabel={(option) => option.label}
                                        value={
                                            formattedAccountCardOptions.find(
                                                (option) => option.value === addEditableItem.accountCodeId
                                            ) || null
                                        }
                                        onChange={handleMuiChangeProxy}
                                        onInputChange={(event, inputValue) => setSearchTerm(inputValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={
                                                    addEditableItem.paymentAcceptanceType === 'תקבול'
                                                        ? t('Client Name')
                                                        : t('Provider Name')
                                                }
                                                variant="standard"
                                                required
                                                InputProps={{ ...params.InputProps, ...inputPropsStyle }}
                                                inputProps={{ ...params.inputProps, ...inputStyle }}
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <Tooltip
                                                title={
                                                    option.isEditable
                                                        ? t('RightClickToEdit')
                                                        : t('NotEditable')
                                                }
                                                placement="top"
                                            >
                                                <StyledListItem
                                                    {...props}
                                                    onContextMenu={(event) => {
                                                        if (!option.isEditable) {
                                                            event.preventDefault();
                                                            return;
                                                        }
                                                        event.preventDefault();
                                                        setPalCode(option.palCode);
                                                        setNewAccountCard({
                                                            accountCodeName: option.name || '',
                                                            accountCodeId: option.cardId,
                                                            accountCaseId: option.accountCaseId || '',
                                                        });
                                                        setShowAccountCardInputsEdit(true);
                                                        setAddNewAccountCardDocument(true);
                                                    }}
                                                >
                                                    {`${option?.cardId || ''} - ${option.name}`}
                                                </StyledListItem>
                                            </Tooltip>
                                        )}
                                        PopperComponent={(props) => (
                                            <StyledPopper
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    direction: direction,
                                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.7)',
                                                }}
                                            />
                                        )}
                                        noOptionsText={
                                            <Button
                                                onClick={() => handleNoOptionClick(searchTerm)}
                                                variant="contained"
                                                color="primary"
                                            >
                                                {t('AddAccountCard')} "{searchTerm}"
                                            </Button>
                                        }
                                        disabled={isDisabledForm}
                                    />
                                </div>
                                {/* PaymentType */}
                                <div style={{ width: '48%' }}>
                                    <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                                        {t('PaymentType')}
                                        <span style={{ color: 'red' }}>*</span>
                                    </div>
                                    <Autocomplete
                                        options={paymentTypeOptions}
                                        getOptionLabel={(option) => option.label}
                                        value={
                                            paymentTypeOptions.find(
                                                (option) => option.value === addEditableItem.paymentAcceptanceType
                                            ) || null
                                        }
                                        onChange={(e, newValue) =>
                                            handleChangeAccountType(newValue ? newValue : '')
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                placeholder={t('SelectPaymentType')}
                                                InputProps={{ ...params.InputProps, ...inputPropsStyle }}
                                                inputProps={{ ...params.inputProps, ...inputStyle }}
                                            />
                                        )}
                                        PopperComponent={(props) => (
                                            <Popper
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    direction: direction,
                                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
                                                }}
                                            />
                                        )}
                                        disabled={isDisabledForm}
                                    />
                                </div>
                                {/* Document Date */}
                                <div style={{ width: '48%' }}>
                                    <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                                        {t('ReceiptPaymentDate')}
                                        <span style={{ color: 'red' }}>*</span>
                                    </div>
                                    <CustomDatePicker
                                        date={addEditableItem.date ? new Date(addEditableItem.date) : null}
                                        onDateChange={setPaymentDate}
                                        height="48px"
                                        disabled={isDisabledForm}
                                    />
                                </div>

                                {/* Reporting Month Date */}
                                {itemEditing && (
                                    <div style={{ width: '48%' }}>
                                        <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                                            {t('MonthReportDate')}
                                            <span style={{ color: 'red' }}>*</span>
                                        </div>
                                        <CustomMonthDatePicker
                                            date={
                                                addEditableItem.reportingMonthDate
                                                    ? dayjs(new Date(addEditableItem.reportingMonthDate))
                                                    : null
                                            }
                                            onDateChange={(date) => {
                                                if (date) {

                                                    setReportingMonthDate(date, 'reportingMonthDate');
                                                }
                                            }}
                                            height="48px"
                                            disabled={isDisabledForm}
                                        />
                                    </div>
                                )}
                                {/* Original Tax Clearance */}
                                <div style={{ width: '48%' }}>
                                    <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                                        {t('OriginalTaxClearance')}
                                    </div>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        placeholder={t('OriginalTaxClearance')}
                                        value={addEditableItem.withholdingTax || ''}
                                        onChange={handleChangeOriginalTaxClearance}
                                        variant="standard"
                                        InputProps={inputPropsStyle}
                                        inputProps={inputStyle}
                                        disabled={isDisabledForm}
                                    />
                                </div>

                                {/* Description */}
                                <div style={{ width: '48%' }}>
                                    <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                                        {t('ReceiptPaymentDescription')}
                                        <span style={{ color: 'red' }}>*</span>
                                    </div>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        placeholder={t('ReceiptPaymentDescription')}
                                        value={addEditableItem.description || ''}
                                        onChange={handleDescriptionChange}
                                        variant="standard"
                                        InputProps={inputPropsStyle}
                                        inputProps={inputStyle}
                                        disabled={isDisabledForm}
                                    />
                                </div>

                                {/* Additional fields if editing */}
                                {itemEditing && (
                                    <>
                                        {/* Amount (readOnly) */}
                                        <div style={{ width: '48%' }}>
                                            <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                                                {t('amount')}
                                            </div>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                placeholder={t('amount')}
                                                value={addEditableItem.amount || ''}
                                                onChange={(event) =>
                                                    handleAddEditableItemChange('amount', event.target.value)
                                                }
                                                variant="standard"
                                                disabled
                                                InputProps={{
                                                    ...inputPropsStyle,
                                                    readOnly: true,
                                                    style: {
                                                        backgroundColor: '#f2f2f2',
                                                        ...inputPropsStyle.sx,
                                                    },
                                                }}
                                                inputProps={inputStyle}
                                            />
                                        </div>
                                        {/* Confirmation Number */}
                                        {/* <div style={{ width: '48%' }}>
                                            <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                                                {t('ConfirmationNumber')}
                                            </div>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                placeholder={t('ConfirmationNumber')}
                                                value={addEditableItem.confirmationNumber || ''}
                                                onChange={(event) =>
                                                    handleAddEditableItemChange(
                                                        'confirmationNumber',
                                                        event.target.value
                                                    )
                                                }
                                                variant="standard"
                                                InputProps={inputPropsStyle}
                                                inputProps={inputStyle}
                                                disabled={isDisabledForm}
                                            />
                                        </div> */}
                                        {/* Debit or Credit Account (read-only) */}
                                        {/* <div style={{ width: '48%' }}>
                                            <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                                                {addEditableItem.paymentAcceptanceType === 'תשלום'
                                                    ? t('Debit Account')
                                                    : t('Credit Account')}
                                            </div>
                                            <TextField
                                                fullWidth
                                                placeholder={
                                                    addEditableItem.paymentAcceptanceType === 'תשלום'
                                                        ? t('Debit Account')
                                                        : t('Credit Account')
                                                }
                                                value={addEditableItem.accountCodeName || ''}
                                                variant="standard"
                                                InputProps={{
                                                    ...inputPropsStyle,
                                                    readOnly: true,
                                                    style: {
                                                        backgroundColor: '#f2f2f2',
                                                        ...inputPropsStyle.sx,
                                                    },
                                                }}
                                                inputProps={inputStyle}
                                            />
                                        </div> */}
                                        {/* Reference */}
                                        <div style={{ width: '48%' }}>
                                            <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                                                {t('Reference')}
                                            </div>
                                            <TextField
                                                fullWidth
                                                placeholder={t('Reference')}
                                                value={addEditableItem.reference || ''}
                                                onChange={(event) =>
                                                    handleAddEditableItemChange('reference', event.target.value)
                                                }
                                                variant="standard"
                                                InputProps={inputPropsStyle}
                                                inputProps={inputStyle}
                                                disabled={isDisabledForm}
                                            />
                                        </div>
                                        {/* Status (radio) */}
                                        <div className="form-row">
                                            {
                                                (userTypeContext?.toString() !== Constants.UserType.Client || permissionsState.Premium) &&
                                                <div className="edit-form-row-item" >
                                                    <div className="edit-form-row-item-title" style={{ marginRight: '16px' }}>
                                                        {t('status')}
                                                        <span className="required-asterisk">*</span>
                                                    </div>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-form-control-label-placement"
                                                        name="position"
                                                        defaultValue="top"
                                                        onChange={handleRadioChange}
                                                        sx={{
                                                            height: '48px',
                                                            padding: '0 10px',
                                                            borderRadius: '8px 8px 0px 8px',
                                                            background: '#F5F5F6',
                                                            justifyContent: 'center',
                                                            gap: '30px',
                                                            border: '1px solid transparent',
                                                            '&:focus-within': {
                                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                            },
                                                        }}

                                                    >
                                                        <FormControlLabel
                                                            value="notDone"
                                                            control={<Radio style={{ height: '48px', padding: '0 8px' }} />}
                                                            label={t('unprocessed')}
                                                            labelPlacement="top"
                                                            checked={!statusCheck}
                                                            disabled={isDisabledForm} style={{ margin: 0, height: '48px' }}
                                                        />
                                                        <FormControlLabel
                                                            value="done"
                                                            control={<Radio style={{ height: '48px', padding: '0 8px' }} />}
                                                            label={t('processed')}
                                                            labelPlacement="top"
                                                            checked={statusCheck}
                                                            disabled={isDisabledForm} style={{ margin: 0, height: '48px' }}
                                                        />

                                                    </RadioGroup>
                                                </div>
                                            }
                                        </div>

                                    </>
                                )}
                            </div>

                            {/* -------- Divider -------- */}
                            <div
                                style={{
                                    borderTop: '1px solid #000',
                                    margin: '20px 0',
                                    width: '90%',
                                }}
                            />

                            {/* ---------- ADD RECEIPT ITEMS SECTION ---------- */}
                            <h3 style={{ marginBottom: '8px' }}>{t('ReceiptItems')}</h3>

                            {/* Add-Item Buttons (if not closed) */}
                            {!isClosedReportingMonthAndIsRange && (
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '10px',
                                        marginBottom: '10px',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <Button
                                        onClick={() => handleAddItem('checks')}
                                        sx={{
                                            background: '#304FFF',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '6px',
                                            '&:hover': {
                                                background: '#1E40FF',
                                            }
                                        }}
                                    >
                                        {t('AddCheck')}
                                    </Button>
                                    <Button
                                        onClick={() => handleAddItem('creditCards')}
                                        sx={{
                                            background: '#304FFF',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '6px',
                                            '&:hover': {
                                                background: '#1E40FF',
                                            }
                                        }}
                                    >
                                        {t('AddCreditCard')}
                                    </Button>
                                    <Button
                                        onClick={() => handleAddItem('cash')}
                                        sx={{
                                            background: '#304FFF',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '6px',
                                            '&:hover': {
                                                background: '#1E40FF',
                                            }
                                        }}
                                    >
                                        {t('AddCash')}
                                    </Button>
                                    <Button
                                        onClick={() => handleAddItem('bankTransactions')}
                                        sx={{
                                            background: '#304FFF',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '6px',
                                            '&:hover': {
                                                background: '#1E40FF',
                                            }
                                        }}
                                    >
                                        {t('AddBankTransfer')}
                                    </Button>
                                </div>
                            )}
                        </div>

                        {/* ===================== RIGHT SIDE (PDF Preview) ===================== */}
                        {(showPdfIframe && allDocUrls.length > 0) && (
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    minHeight: '300px',
                                    padding: '10px',
                                    border: '1px solid #ddd',
                                    borderRadius: '8px',
                                    backgroundColor: '#f9f9f9',
                                    position: 'relative',
                                }}
                            >
                                {/* {allDocUrls.length > 1 && (
                                    <>
                                        <IoArrowBackCircle
                                            size={50}
                                            color={docIndex > 0 ? '#304FFF' : '#ccc'} // Disabled color
                                            onClick={handlePrevDoc}
                                            style={{
                                                position: 'absolute',
                                                left: '25px',
                                                top: '10%',
                                                transform: 'translateY(-50%)',
                                                cursor: docIndex > 0 ? 'pointer' : 'default',
                                                transition: 'opacity 0.3s ease-in-out',
                                                opacity: docIndex > 0 ? 1 : 0.5,
                                            }}
                                        />
                                        <IoArrowForwardCircle
                                            size={50}
                                            color={
                                                docIndex < allDocUrls.length - 1 ? '#304FFF' : '#ccc'
                                            } // Disabled color
                                            onClick={handleNextDoc}
                                            style={{
                                                position: 'absolute',
                                                right: '25px',
                                                top: '10%',
                                                transform: 'translateY(-50%)',
                                                cursor:
                                                    docIndex < allDocUrls.length - 1 ? 'pointer' : 'default',
                                                transition: 'opacity 0.3s ease-in-out',
                                                opacity: docIndex < allDocUrls.length - 1 ? 1 : 0.5,
                                            }}
                                        />
                                    </>
                                )} */}

                                {allDocUrls.length > 1 && (
                                    <FilePreviewCarousel
                                        allDocUrls={allDocUrls}
                                        docIndex={docIndex}
                                        setDocIndex={setDocIndex}
                                        t={t}
                                    />
                                )}

                                {/* Main Document View */}
                                <Iframe
                                    url={
                                        allDocUrls.length > 0
                                            ? `${allDocUrls[docIndex]}#page=1&view=FitH`
                                            : `${NoDocumentsPdf}#page=1&view=FitH`
                                    }
                                    width="100%"
                                    height="100%"
                                    id="receipt-pdf"
                                    title="Receipt PDF"
                                    allowFullScreen
                                    style={{
                                        marginTop: '10px',
                                        borderRadius: '8px',
                                        border: '1px solid #ddd',
                                        backgroundColor: '#fff',
                                    }}
                                />
                            </div>
                        )}
                    </div>

                    {/* ===================== BOTTOM SECTION: TABLE & FOOTER BUTTONS ===================== */}
                    <div style={{ width: '100%', marginTop: '20px' }}>
                        {/* Table Container */}
                        <StyledTableContainer component={Paper}>
                            <StyledTable stickyHeader>
                                <StyledTableHead>
                                    <TableRow>
                                        <StyledTableCellHeader>{t('Type')}</StyledTableCellHeader>
                                        <StyledTableCellHeader>
                                            {t('CheckNumber')} / {t('Description')}
                                        </StyledTableCellHeader>
                                        <StyledTableCellHeader>{t('BankName')}</StyledTableCellHeader>
                                        <StyledTableCellHeader>{t('BankCode')}</StyledTableCellHeader>
                                        <StyledTableCellHeader>{t('BankBranchCode')}</StyledTableCellHeader>
                                        <StyledTableCellHeader>{t('BankAccountNumber')}</StyledTableCellHeader>
                                        <StyledTableCellHeader>{t('Amount')}</StyledTableCellHeader>
                                        <StyledTableCellHeader>{t('DuePaymentDate')}</StyledTableCellHeader>
                                        <StyledTableCellHeader>{t('Debit Account')}</StyledTableCellHeader>
                                        <StyledTableCellHeader>{t('Credit Account')}</StyledTableCellHeader>
                                        <StyledTableCellHeader>{t('Actions')}</StyledTableCellHeader>
                                    </TableRow>
                                </StyledTableHead>
                                <TableBody>{renderTableRows()}</TableBody>
                            </StyledTable>
                        </StyledTableContainer>

                        {/* Footer Buttons */}
                        <div
                            className="button-container"
                            style={{ marginTop: '20px', display: 'flex', gap: '10px', alignItems: 'center' }}
                        >
                            {/* Save */}
                            <Tooltip title={t('Save')}>
                                {!(itemEditing && !paymentBeingEdited) && !isDisabledForm && (
                                    <IconButton
                                        onClick={itemEditing ? handleSave : handleClickDoneCreation}
                                        disabled={buttonDisabled}
                                    >
                                        <SaveIcon style={{ color: '#304FFF' }} />
                                    </IconButton>
                                )}
                            </Tooltip>

                            {/* Delete */}
                            {itemEditing && (
                                <Tooltip title={t('Delete')}>
                                    <IconButton onClick={() => handleDeleteClick(addEditableItem.id)}>
                                        <DeleteIcon style={{ color: '#304FFF' }} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {/* Transfer */}
                            {itemEditing &&
                                (userTypeContext?.toString() !== Constants.UserType.Client ||
                                    permissionsState.Premium) && (
                                    <DocumentTransferDialog
                                        editedItem={addEditableItem}
                                        token={token}
                                        setLoading={setLoading}
                                        RefreshPage={handleTransferRefresh}
                                        t={t}
                                        client={client}
                                    />
                                )}
                        </div>
                    </div>
                </div>
            </Draggable>

            {/* MODAL: AddAccountCardModal */}
            <AddAccountCardModal
                accountCardOptions={accountCardOptions}
                open={addNewAccountCardDocument}
                onClose={handleCloseItem}
                editedItem={newAccountCard}
                isEdit={showAccountCardInputsEdit}
                isRange={isRange}
                isClosedReportingMonth={isClosedReportingMonth}
                palCodes={Constants.AccountCardPalCode}
                propPalCode={palCode}
                setPalCode={setPalCode}
                direction={direction}
                isAdd={!showAccountCardInputsEdit}
                client={client}
                token={token}
                setLoading={setLoading}
                handleChangeAfterAdd={handleChangeAfterAdd}
                fetchEnums={fetchEnums}
                type={addAccountCardType}
            />
        </Portal>
    );
};

export default PaymentAcceptanceCreateEditModal;
