import React, { useState, useEffect, useMemo, useContext } from 'react';
import HamburgerMenu from '../../HamburgerMenu/HamburgerMenu.jsx'; // Adjust the import path as necessary
import { useLocation } from 'react-router-dom';
import UserService from '../../../Services/user.service.js';
import ClientService from '../../../Services/client.service.js';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from "react-toastify";
import { useSignalR } from '../../../Contexts/signalR.js';
import ClientDocumentCountService from '../../../Services/ClientDocumentCount.service.js';
import clientsIcon from "../../../assests/images/Main/clients.svg";
import subAccountantsIcon from "../../../assests/images/Main/subAccountants.svg";
import SubAccountantService from '../../../Services/subAccountant.service.js';
import loadingAnimation from "../../../assests/images/Animation.gif";
import Constants from '../../../assests/Constants/constants.js';
import CircularProgress from '@mui/material/CircularProgress';
import UpperPanelDetails from '../UpperPanelDetails/UpperPanelDetails.jsx';
import PuffLoader from "react-spinners/PuffLoader";
import { beautifyNumber } from '../../../Utils/FormatNumber.js';
import { TextField, IconButton, InputAdornment, Checkbox, FormControlLabel, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AutocompleteSelect from '../AutocompleteSelect/AutocompleteSelect.jsx';
import Select from 'react-select';
import ProfileSignatureDialog from '../ProfileSignatureDialog/ProfileSignatureDialog.jsx';
import './AddClient.css'; // Make sure to create and import your CSS file
import { PermissionsContext } from '../../../Contexts/Permissions';
import PermissionService from '../../../Services/permission.service.js';
import { el } from 'date-fns/locale';
import { validateEmail, validatePhoneNumber } from '../../../Utils/helpers.js';
import { NavHistoryContext } from '../../../Contexts/NavHistoryContext.js';
const AddClient = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { i18n, t } = useTranslation();
    const [user, setUser] = useState();
    const [token, setToken] = useState();
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const { client, isEdit } = location.state || {};
    const [subAccountants, setSubAccountants] = useState([]);
    const [direction, setDirection] = useState('rtl'); // Default to 'rtl'
    const { permissions, permissionsState, loading: permissionsLoading } = useContext(PermissionsContext);
    const { goBack } = useContext(NavHistoryContext);


    const translatedClientPaymentMethodOptions = Constants.clientPaymentMethodOptions.map(option => ({
        value: option.value,
        label: t(option.label),
        name: t(option.label)
    }));

    const translatedPackageOptions = Constants.packageOptions.map(option => ({
        value: option.value,
        label: t(option.label),
        name: t(option.label)
    }));

    const [formData, setFormData] = useState({
        bank: {
            bankCode: '',
            branchNumber: '',
            accountNumber: '',
            paymentMethod: 0
        },
        CaseID: '',
        ClientID: '',
        deductionsCase: '',
        ClientName: '',
        ClientPassword: '',
        ClientPhoneNumber: '',
        ClientLocation: '',
        ClientEmail: '',
        ClientWebsite: '',
        incomeTaxAdvancePercentage: '',
        useIncomeTaxAdvancePercentage: true,
        ClientReportingType: '',
        AccountManagement: '',
        CaseType: '',
        subAccId: '',
        incomeTaxAdvanceAmount: '',
        taxAssessor: '',
        signature: '',
        profilePicture: '',
        taxCreditPoints: 0,
        clientPaymentMethod: 0,
        package: Constants.packageOptionsEnum.Basic,
        advancedReportingType: "",
        officeIdentifyNumber: null


    });
    const allFieldsFilled = formData.CaseID &&
        formData.ClientID &&
        formData.ClientName &&
        (isEdit || formData.ClientPassword) && // Password required only when adding
        validatePhoneNumber(formData.ClientPhoneNumber) && // Use existing phone validation
        (!formData.ClientEmail || formData.ClientEmail === '' || validateEmail(formData.ClientEmail)) && // Use existing email validation
        formData.CaseType &&
        formData.ClientReportingType &&
        formData.advancedReportingType &&
        formData.AccountManagement;



    const accountingManagementType = useMemo(() => {
        return Constants.AccountManagementOptions?.map(option => ({
            value: option.name,
            label: option.name,
        }));
    }, [Constants.AccountManagementOptions]);

    const [accountOptionSelected, setAccountOptionSelected] = useState(() => {
        const savedValues = JSON.parse(localStorage.getItem('accountOptionSelected') || '[]');
        return savedValues;
    });

    const [openSignatureDialog, setOpenSignatureDialog] = useState(false);


    const [optionSelected, setOptionSelected] = useState(() => {
        const savedOptions = localStorage.getItem('optionSelected');
        return savedOptions ? JSON.parse(savedOptions) : [];
    });

    useEffect(() => {
        localStorage.setItem('optionSelected', JSON.stringify(optionSelected));
    }, [optionSelected]);



    const handleAccManagmentChange = (event, newValue) => {
        setAccountOptionSelected(newValue);
        localStorage.setItem('accountOptionSelected', JSON.stringify(newValue));
    };

    const handleSubAccChange = (event, newValue) => {
        setOptionSelected(newValue);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const isRtl = ['he', 'ar'].includes(i18n.language);
    const fetchSubAccountantsData = async (accountantId, token, isFirstFetch) => {
        try {
            setLoading(true);
            const data = permissionsState.ViewSubAccountantClients ? await SubAccountantService.GetSubAccountantByAccountantIdForClients(token, accountantId) : await SubAccountantService.GetSubAccountantByAccountantId(token, accountantId);
            if (isFirstFetch) {
                let transformedData = data.map(item => ({
                    label: item.name,
                    value: item.subAccountantId
                }));
                setSubAccountants(transformedData);
            } else {
                setSubAccountants([...subAccountants, ...data]);
            }
        } catch (error) {
            console.error(error);
            toast.error(t('fetchSubAccountantsDataError'));
        }
        setLoading(false);
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true)
                const user = await UserService.getCurrentUser();
                const token = await UserService.getCurrentToken();
                const userType = await UserService.getCurrentUserType();

                if (user && token) {
                    setUser(user);
                    setToken(token);
                    await fetchSubAccountantsData(user.accountantId, token, true);
                } else {
                    navigate("/login");
                }
            } catch (error) {
                navigate("/login");
            }
            setLoading(false)
        };
        fetchUserData();
    }, [permissionsState]);

    const handleChange = (field, value) => {

        setFormData(prevState => {
            const fields = field.split('.');
            let newState = { ...prevState };
            let current = newState;

            for (let i = 0; i < fields.length - 1; i++) {
                current[fields[i]] = { ...current[fields[i]] };
                current = current[fields[i]];
            }

            current[fields[fields.length - 1]] = value;

            return newState;
        });
    };



    const handleCheckboxChange = (event) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            useIncomeTaxAdvancePercentage: event.target.checked
        }));
    };

    const [constantClientDescription, setConstantClientDescription] = useState('');
    const [constantInvoiceDescription, setConstantInvoiceDescription] = useState('');

    const fetchInvoiceConstantDetails = async (caseId, token) => {
        try {
            const response = await ClientService.GetConstantInvoiceDetailsByCaseId(caseId, token);
            if (response.ok) {
                const result = await response.json();
                setConstantClientDescription(result.constantClientDescription);
                setConstantInvoiceDescription(result.constantInvoiceDescription);
            }
        } catch (error) {
            console.error("Error fetching constant details:", error);
        }
    };





    useEffect(() => {
        if (isEdit && client && token) {
            const fetchPackagePermission = async () => {
                try {
                    const hasPremiumPackage = await PermissionService.checkPermissionById(
                        client.caseId,
                        Constants.PermissionUserType.Client,
                        Constants.packageOptionsEnum.Premium,
                        token
                    );

                    setFormData({
                        bank: {
                            bankCode: client.bank?.bankCode || '',
                            branchNumber: client.bank?.branchNumber || '',
                            accountNumber: client.bank?.accountNumber || '',
                            paymentMethod: client.bank?.paymentMethod || 0
                        },
                        CaseID: client.caseId || '',
                        ClientID: client.clientId || '',
                        deductionsCase: client.deductionsCase || '',
                        ClientName: client.name || '',
                        ClientPassword: '', // Leave blank for security
                        ClientPhoneNumber: client.phoneNumber || '',
                        ClientLocation: client.location || '',
                        ClientEmail: client.email || '',
                        ClientWebsite: client.website || '',
                        incomeTaxAdvancePercentage: client.incomeTaxAdvancePercentage || '',
                        useIncomeTaxAdvancePercentage: client.useIncomeTaxAdvancePercentage,
                        ClientReportingType: client.reportingType || '',
                        AccountManagement: client.accountManagement || '',
                        CaseType: client.caseType || '',
                        subAccId: client.subAccountantId || '',
                        incomeTaxAdvanceAmount: client.incomeTaxAdvanceAmount || '',
                        taxAssessor: client.taxAssessor || '',
                        profilePicture: client.profilePicture || '',
                        signature: client.signature || '',
                        taxCreditPoints: client.taxCreditPoints || null,
                        clientPaymentMethod: client.clientPaymentMethod || null,
                        package: hasPremiumPackage ? Constants.packageOptionsEnum.Premium : Constants.packageOptionsEnum.Basic,
                        advancedReportingType: client.advancedReportingType,
                        officeIdentifyNumber: client.officeIdentifyNumber
                    });

                } catch (error) {
                    console.error("Error checking package permission:", error);
                }
            };

            fetchPackagePermission();
            fetchInvoiceConstantDetails(client.caseId, token);
        }
    }, [isEdit, client, token]);

    useEffect(() => {
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);


    // Example options for Select components
    const formattedCaseTypeOptions = Constants.CaseTypeOptions?.map(option => ({
        value: option.name,
        label: `${option.name}`,
        name: option.name,
    }));

    const formattedSubAccOptions = subAccountants?.map(option => ({
        value: option.value,
        label: `${option.label}`,
        name: option.label,
    }));

    const formattedAccountManagementOptions = Constants.AccountManagementOptions?.map(option => ({
        value: option.name,
        label: `${option.name}`,
        name: option.name,
    }));

    const formattedReportingTypeOptions = Constants.ReportingTypeOptions?.map(option => ({
        value: option.name,
        label: `${option.name}`,
        name: option.name,
    }));

    const formattedPaymentMethodOptions = Constants.PaymentMethod.map(option => ({
        value: option.value,
        label: t(option.name),
        name: option.name,
    }));

    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: '48px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: 'none',
            boxShadow: 'none',
            padding: '0 10px',
            textAlign: 'left',
            border: '1px solid transparent',
            boxSizing: 'border-box',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            textAlign: 'left',
        }),
        singleValue: (provided) => ({
            ...provided,
            textAlign: 'left',
            color: 'black',
        }),
        menu: (provided) => ({
            ...provided,
            textAlign: 'left',
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? '#F9AA2A' // Selected color remains the same
                : state.isFocused
                    ? '#FFD700' // New hover color (gold)
                    : null,
            color: 'black',
        }),
    };





    const handleClientSave = async () => {
        if (!allFieldsFilled && !isEdit) {
            toast.info(t('ErrorRequiredFields'));
        } else {
            setLoading(true);
            try {
                const officeIdentifyNumber =
                    formData?.officeIdentifyNumber
                        ? String(formData.officeIdentifyNumber).trim()
                        : null;
                const clientData = {
                    caseId: formData.CaseID,
                    accountantId: user.accountantId,
                    clientId: formData.ClientID,
                    name: formData.ClientName,
                    password: formData.ClientPassword,
                    phoneNumber: formData.ClientPhoneNumber,
                    caseType: formData.CaseType,
                    AccountManagement: formData.AccountManagement,
                    SubAccountantId: formData.subAccId,
                    IncomeTaxAdvancePercentage: parseFloat(formData.incomeTaxAdvancePercentage),
                    IncomeTaxAdvanceAmount: parseFloat(formData.incomeTaxAdvanceAmount),
                    UseIncomeTaxAdvancePercentage: formData.useIncomeTaxAdvancePercentage,
                    Website: formData.ClientWebsite,
                    Location: formData.ClientLocation,
                    Email: formData.ClientEmail,
                    ReportingType: formData.ClientReportingType,
                    Bank: {
                        bankCode: formData.bank.bankCode,
                        branchNumber: formData.bank.branchNumber,
                        accountNumber: formData.bank.accountNumber,
                        paymentMethod: formData.bank.paymentMethod
                    },
                    deductionsCase: formData.deductionsCase,
                    taxAssessor: formData.taxAssessor?.toString(),
                    taxCreditPoints: formData.taxCreditPoints,
                    clientPaymentMethod: formData.clientPaymentMethod,
                    advancedReportingType: formData.advancedReportingType,
                    officeIdentifyNumber: officeIdentifyNumber

                };

                if (isEdit) {
                    // Update existing client
                    const response = await ClientService.UpdateClient(token, clientData);
                    if (response?.ok) {
                        // Fetch the updated client data
                        const updatedClient = await ClientService.GetClient(token, clientData.caseId);

                        toast.success(t('CustomerUpdatedSuccessfully'));

                        // Navigate to '/client-details' with the updated client data
                        navigate('/client-details', { state: { client: updatedClient } });
                    } else {
                        toast.error(t('FailedToUpdateCustomer'));
                    }

                    const invoiceDetailsDto = {
                        caseId: clientData.caseId,
                        constantClientDescription,
                        constantInvoiceDescription,
                    };

                    await ClientService.CreateConstantInvoiceDetails(invoiceDetailsDto, token);
                    if (!permissionsState.ViewSubAccountantClients) {

                        if (formData.package === Constants.packageOptionsEnum.Premium) {
                            await PermissionService.assignPermission(clientData.caseId, Constants.PermissionUserType.Client, Constants.packageOptionsEnum.Premium, token)
                        }
                        else if (formData.package === Constants.packageOptionsEnum.Basic) {
                            await PermissionService.removePermission(clientData.caseId, Constants.PermissionUserType.Client, Constants.packageOptionsEnum.Premium, token)
                        }
                    }


                } else {
                    // Add new client
                    const invoiceDetailsDto = {
                        caseId: clientData.caseId,
                        constantClientDescription,
                        constantInvoiceDescription,
                    };

                    const response = await UserService.addClient(clientData, token);

                    if (response.ok) {
                        await ClientService.CreateConstantInvoiceDetails(invoiceDetailsDto, token);
                        if (!permissionsState.ViewSubAccountantClients) {

                            if (formData.package === Constants.packageOptionsEnum.Premium) {
                                await PermissionService.assignPermission(clientData.caseId, Constants.PermissionUserType.Client, Constants.packageOptionsEnum.Premium, token)
                            }
                            else if (formData.package === Constants.packageOptionsEnum.Basic) {
                                await PermissionService.removePermission(clientData.caseId, Constants.PermissionUserType.Client, Constants.packageOptionsEnum.Premium, token)
                            }
                        }
                        toast.success(t('CustomerAddedSuccessfully'));
                        navigate(-1); // Redirect to clients list
                    } else {
                        toast.error(t('FailedToAddCustomer'));
                    }
                }
            } catch (error) {
                console.error(error);
                toast.error(t('FailedToSaveCustomer'));
            }
            setLoading(false);
        }
    };


    const handleGoBack = () => {
        // navigate(-1);
        goBack();
    };




    return (
        <>
            <ProfileSignatureDialog
                open={openSignatureDialog}
                onClose={() => setOpenSignatureDialog(false)}
                onSave={(data) => {

                }}
                existingSignature={formData?.signature}         // if available
                existingProfilePicture={formData?.profilePicture}   // if available
                token={token}
                accountantId={user?.accountantId}
                caseId={formData?.CaseID}
            />

            <HamburgerMenu />
            {/* <Sidebar /> */}
            <div className="template-page">
                <div className="template-container">
                    <UpperPanelDetails client={client} />
                    <div className={`main-title ${direction}`}>{isEdit ? t('EditClient') : t('addClientTitle')}</div>

                    <div className='backIconBackground' style={{ alignSelf: i18n.language === 'en' ? 'flex-start' : 'flex-end' }} onClick={handleGoBack}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <path d="M20 21.1667L14.8267 15.9933L20 10.82C20.52 10.3 20.52 9.45999 20 8.93999C19.48 8.41999 18.64 8.41999 18.12 8.93999L12 15.06C11.48 15.58 11.48 16.42 12 16.94L18.12 23.06C18.64 23.58 19.48 23.58 20 23.06C20.5067 22.54 20.52 21.6867 20 21.1667Z" fill="black" fill-opacity="0.9" />
                        </svg>
                    </div>

                    <div className={`add-client-form ${direction}`}>
                        {/* Row 1: Case ID, Client ID */}
                        <div className="form-row">
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('CompanyId')}<span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    type="number"
                                    placeholder={t('CompanyId')}
                                    value={formData.CaseID}
                                    onChange={(e) => handleChange('CaseID', e.target.value)}
                                    className="add-client-input-style"
                                    variant="standard"
                                    required
                                    disabled={isEdit}
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                />
                            </div>

                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('enterClientID')}<span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    type="number"
                                    placeholder={t('enterClientID')}
                                    value={formData.ClientID}
                                    onChange={(e) => handleChange('ClientID', e.target.value)}
                                    className="add-client-input-style"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                    required
                                />
                            </div>
                        </div>
                        {/* Row 2: Deductions Case, Client Name */}
                        <div className="form-row">
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('DeductionsCase')}
                                </div>
                                <TextField
                                    fullWidth
                                    type="number"
                                    placeholder={t('DeductionsCase')}
                                    value={formData.deductionsCase}
                                    onChange={(e) => handleChange('deductionsCase', e.target.value)}
                                    className="add-client-input-style"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                />
                            </div>

                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('enterName')}<span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('enterName')}
                                    value={formData.ClientName}
                                    onChange={(e) => handleChange('ClientName', e.target.value)}
                                    className="add-client-input-style"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                    required
                                />
                            </div>
                        </div>
                        {/* Row 3: Email, Password */}
                        <div className="form-row">
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('BusinessIdentificationNumberInOffice')}
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('BusinessIdentificationNumberInOffice')}
                                    value={formData.officeIdentifyNumber}
                                    onChange={(e) => handleChange('officeIdentifyNumber', e.target.value)}
                                    className="add-client-input-style"
                                    variant="standard"
                                    name="unexpectedField_1234"
                                    type='number'
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                />
                            </div>
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('Email')}
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('Email')}
                                    value={formData.ClientEmail}
                                    type='email'
                                    onChange={(e) => handleChange('ClientEmail', e.target.value)}
                                    className="add-client-input-style"
                                    variant="standard"
                                    autoComplete="off"
                                    name="email-unique-field"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            autoComplete: "off",

                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                />
                            </div>
                            {!isEdit && (
                                <div className="form-row-item">
                                    <div className="form-row-item-title">
                                        {t('enterPassword')}<span className="required-asterisk">*</span>
                                    </div>
                                    <TextField
                                        fullWidth
                                        placeholder={t('enterPassword')}
                                        value={formData.ClientPassword}
                                        onChange={(e) => handleChange('ClientPassword', e.target.value)}
                                        className="add-client-input-style"
                                        variant="standard"
                                        autoComplete="new-password"
                                        type="password"
                                        InputProps={{
                                            disableUnderline: true,
                                            className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                            sx: {
                                                autoComplete: "new-password",
                                                height: '48px',
                                                padding: '0 10px',
                                                borderRadius: '8px',
                                                background: '#F5F5F6',
                                                border: '1px solid transparent',
                                                '&:focus-within': {
                                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                },
                                            },
                                        }}
                                        inputProps={{
                                            style: {
                                                textAlign: direction === 'ltr' ? 'left' : 'right',
                                            },
                                        }}
                                        required
                                    />
                                </div>
                            )}

                        </div>
                        {/* Row 4: Phone Number, Location */}
                        <div className="form-row">
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('enterPhoneNumber')}<span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('enterPhoneNumber')}
                                    value={formData.ClientPhoneNumber}
                                    type="number"
                                    onChange={(e) => handleChange('ClientPhoneNumber', e.target.value)}
                                    className="add-client-input-style"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                    required
                                />
                            </div>

                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('Location')}
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('Location')}
                                    value={formData.ClientLocation}
                                    onChange={(e) => handleChange('ClientLocation', e.target.value)}
                                    className="add-client-input-style"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        {/* Row 5: Website, pe shen*/}
                        <div className="form-row">
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('Website')}
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('Website')}
                                    value={formData.ClientWebsite}
                                    onChange={(e) => handleChange('ClientWebsite', e.target.value)}
                                    className="add-client-input-style full-width"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            width: '100%',
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                />
                            </div>
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('TaxAssessor')}<span className="required-asterisk">*</span>
                                </div>
                                <Select
                                    className="add-client-input-style"
                                    placeholder={t('TaxAssessor')}
                                    options={Constants.TaxAssessorOptions}
                                    isSearchable={true}
                                    onChange={(selectedOption) => {
                                        handleChange('taxAssessor', selectedOption.value);
                                    }}
                                    value={Constants.TaxAssessorOptions.find(option => option.value === formData.taxAssessor)}
                                    styles={customStyles}
                                />
                            </div>

                        </div>
                        {/* Row 6: Tax Advance Percentage */}
                        <div className="form-row">

                            {formData.useIncomeTaxAdvancePercentage ? (
                                <div className="form-row-item">

                                    <div className="form-row-item-title">
                                        {t('IncomeTaxAdvancePercentage')}
                                    </div>
                                    <TextField
                                        fullWidth
                                        placeholder={t('IncomeTaxAdvancePercentage')}
                                        value={formData.incomeTaxAdvancePercentage}
                                        onChange={(e) => handleChange('incomeTaxAdvancePercentage', e.target.value)}
                                        className="add-client-input-style full-width"
                                        variant="standard"
                                        type="number"
                                        InputProps={{
                                            disableUnderline: true,
                                            className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                            sx: {
                                                height: '48px',
                                                padding: '0 10px',
                                                borderRadius: '8px',
                                                background: '#F5F5F6',
                                                border: '1px solid transparent',
                                                '&:focus-within': {
                                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                },
                                            },
                                        }}
                                        inputProps={{
                                            style: {
                                                width: '100%',
                                                textAlign: direction === 'ltr' ? 'left' : 'right',
                                            },
                                        }}
                                    />

                                </div>
                            ) : (
                                <div className="form-row-item">
                                    <div className="form-row-item-title">
                                        {t('IncomeTaxAdvanceAmount')}
                                    </div>
                                    <TextField
                                        fullWidth
                                        placeholder={t('IncomeTaxAdvanceAmount')}
                                        value={formData.incomeTaxAdvanceAmount}
                                        onChange={(e) => handleChange('incomeTaxAdvanceAmount', e.target.value)}
                                        className="add-client-input-style full-width"
                                        variant="standard"
                                        type="number"
                                        InputProps={{
                                            disableUnderline: true,
                                            className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                            sx: {
                                                height: '48px',
                                                padding: '0 10px',
                                                borderRadius: '8px',
                                                background: '#F5F5F6',
                                                border: '1px solid transparent',
                                                '&:focus-within': {
                                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                },
                                            },
                                        }}
                                        inputProps={{
                                            style: {
                                                width: '100%',
                                                textAlign: direction === 'ltr' ? 'left' : 'right',
                                            },
                                        }}
                                    />

                                </div>
                            )}
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('IncomeTaxCreditPoints')}
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('IncomeTaxCreditPoints')}
                                    value={formData.taxCreditPoints}
                                    onChange={(e) => handleChange('taxCreditPoints', e.target.value)}
                                    className="add-client-input-style full-width"
                                    variant="standard"
                                    type="number"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            width: '100%',
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        {/* Checkbox under Row 6 */}
                        <div className="form-row">

                            <FormControlLabel
                                style={{ width: '37%' }}
                                control={
                                    <Checkbox
                                        checked={formData.useIncomeTaxAdvancePercentage}
                                        onChange={handleCheckboxChange}
                                        name="useIncomeTaxAdvancePercentage"
                                        color="primary"
                                    />
                                }
                                label={t('UseIncomeTaxAdvancePercentage')}
                            />

                            {isEdit && (
                                <button className={'addClient-button'} style={{ width: '25%', margin: '40px' }} onClick={() => setOpenSignatureDialog(true)}>
                                    <span>{t('EditSignatureOrProfilePicture')}</span>
                                </button>
                            )}

                        </div>
                        {/* Subtitle: Report settings and account management */}

                        <div className={`main-title ${direction}`} >
                            {t('ReportSettingsAndAccountManagement')}
                        </div>

                        {/* Row 7: Report type, Account management */}
                        <div className="form-row">
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('SelectClientReportType')}<span className="required-asterisk">*</span>
                                </div>
                                <Select
                                    className="add-client-input-style"
                                    placeholder={t('SelectClientReportType')}
                                    options={formattedReportingTypeOptions}
                                    isSearchable={true}
                                    onChange={(selectedOption) => {
                                        handleChange('ClientReportingType', selectedOption.name);
                                    }}
                                    value={formattedReportingTypeOptions.find(option => option.value === formData.ClientReportingType)}

                                    styles={customStyles}
                                />
                            </div>
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('AdvanceIncomeTaxReportType')}<span className="required-asterisk">*</span>
                                </div>
                                <Select
                                    className="add-client-input-style"
                                    placeholder={t('AdvanceIncomeTaxReportType')}
                                    options={formattedReportingTypeOptions}
                                    isSearchable={true}
                                    onChange={(selectedOption) => {
                                        handleChange('advancedReportingType', selectedOption.name);
                                    }}
                                    value={formattedReportingTypeOptions.find(option => option.value === formData.advancedReportingType)}

                                    styles={customStyles}
                                />
                            </div>
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('SelectAccountManagement')}<span className="required-asterisk">*</span>
                                </div>
                                <Select
                                    className="add-client-input-style"
                                    placeholder={t('SelectAccountManagement')}
                                    options={formattedAccountManagementOptions}
                                    isSearchable={true}
                                    onChange={(selectedOption) => {
                                        handleChange('AccountManagement', selectedOption.name);
                                    }}
                                    value={formattedAccountManagementOptions.find(option => option.value === formData.AccountManagement)}

                                    styles={customStyles}
                                />
                            </div>
                        </div>
                        {/* Row 8: Case type, Sub accountant */}
                        <div className="form-row">
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('enterCaseType')}<span className="required-asterisk">*</span>
                                </div>
                                <Select
                                    className="add-client-input-style"
                                    placeholder={t('enterCaseType')}
                                    options={formattedCaseTypeOptions}
                                    isSearchable={true}
                                    onChange={(selectedOption) => {
                                        handleChange('CaseType', selectedOption.label);
                                    }}
                                    value={formattedCaseTypeOptions.find(option => option.value === formData.CaseType)}

                                    styles={customStyles}
                                />
                            </div>
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('SubAccountant')}
                                </div>
                                <Select
                                    className="add-client-input-style"
                                    placeholder={t('SubAccountant')}
                                    options={formattedSubAccOptions}
                                    isSearchable={true}
                                    onChange={(selectedOption) => {
                                        handleChange('subAccId', selectedOption.value);
                                    }}
                                    value={formattedSubAccOptions.find(option => option.value === formData.subAccId)}

                                    styles={customStyles}
                                />
                            </div>
                        </div>
                        {/* New Row 9: Invoice Constant Details */}
                        <div className="form-row">
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('InvoiceDescription')}
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('InvoiceDescription')}
                                    value={constantClientDescription}
                                    onChange={(e) => setConstantClientDescription(e.target.value)}
                                    className="add-client-input-style full-width"
                                    variant="standard"
                                    multiline
                                    rows={4} // Default number of rows
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            padding: '10px', // Adjust padding for multi-line input
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            width: '100%',
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                />
                            </div>
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('InvoiceFooter')}
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('InvoiceFooter')}
                                    value={constantInvoiceDescription}
                                    onChange={(e) => setConstantInvoiceDescription(e.target.value)}
                                    className="add-client-input-style full-width"
                                    variant="standard"
                                    multiline
                                    rows={4} // Default number of rows
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            padding: '10px', // Adjust padding for multi-line input
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            width: '100%',
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        {/* Subtitle: Bank details */}
                        <div className={`main-title ${direction}`} >
                            {t('BankDetails')}
                        </div>
                        {/* Row 10: Bank code, Branch number */}
                        <div className="form-row">
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('BankCode')}
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('BankCode')}
                                    value={formData.bank.bankCode}
                                    onChange={(e) => handleChange('bank.bankCode', e.target.value)}
                                    className="add-client-input-style"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                />
                            </div>

                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('BranchNumber')}
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('BranchNumber')}
                                    value={formData.bank.branchNumber}
                                    onChange={(e) => handleChange('bank.branchNumber', e.target.value)}
                                    className="add-client-input-style"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        {/* Row 11: Account number, Payment method */}
                        <div className="form-row">
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('AccountNumber')}
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('AccountNumber')}
                                    value={formData.bank.accountNumber}
                                    onChange={(e) => handleChange('bank.accountNumber', e.target.value)}
                                    className="add-client-input-style"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                />
                            </div>
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('SelectPaymentMethod')}
                                </div>
                                <Select
                                    className="add-client-input-style"
                                    placeholder={t('SelectPaymentMethod')}
                                    options={formattedPaymentMethodOptions}
                                    isSearchable={true}
                                    onChange={(selectedOption) => handleChange('bank.paymentMethod', selectedOption.value)}
                                    value={formattedPaymentMethodOptions.find(option => option.value === formData.bank.paymentMethod)}
                                    styles={customStyles}
                                    menuPlacement="top"
                                />
                            </div>
                        </div>
                        {
                            !permissionsState.ViewSubAccountantClients &&
                            <>

                                <div className={`main-title ${direction}`} >
                                    {t('PackageAndPaymentSetupForXtram')}
                                </div>
                                {/* Row 10: Bank code, Branch number */}
                                <div className="form-row">
                                    <div className="form-row-item">
                                        <div className="form-row-item-title">
                                            {t('Payer')}
                                        </div>
                                        <Select
                                            className="add-client-input-style"
                                            placeholder={t('Payer')}
                                            options={translatedClientPaymentMethodOptions}
                                            isSearchable={false}
                                            onChange={(selectedOption) => {
                                                handleChange('clientPaymentMethod', selectedOption.value);
                                            }}
                                            value={translatedClientPaymentMethodOptions.find(option => option.value === formData.clientPaymentMethod)}
                                            styles={customStyles}
                                        />
                                    </div>

                                    <div className="form-row-item">
                                        <div className="form-row-item-title">
                                            {t('Package')}
                                        </div>
                                        <Select
                                            className="add-client-input-style"
                                            placeholder={t('Package')}
                                            options={translatedPackageOptions}
                                            isSearchable={false}
                                            onChange={(selectedOption) => {
                                                handleChange('package', selectedOption.value);
                                            }}
                                            value={translatedPackageOptions.find(option => option.value === formData.package)}
                                            styles={customStyles}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        {/* Add Button */}
                        <div className="form-row">
                            {!isEdit ? (
                                <button className={`addClient-button ${!allFieldsFilled ? "button-disabled" : ""}`} style={{ width: '524px', margin: '40px' }} onClick={handleClientSave} disabled={!allFieldsFilled}>
                                    <span>{t('save')}</span>
                                </button>

                            ) : (
                                <button className={`addClient-button ${!allFieldsFilled ? "button-disabled" : ""}`} style={{ width: '524px', margin: '40px' }} onClick={handleClientSave} disabled={!allFieldsFilled}>
                                    <span>{t('Update')}</span>
                                </button>
                            )}

                        </div>
                    </div>
                </div>
                <ToastContainer />
                {loading && (
                    <div className="loader-container">
                        <PuffLoader
                            size={100}        // Larger size for better visibility
                            loading={loading}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default AddClient;
