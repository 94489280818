import React, { useState, useEffect, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Checkbox,
  Button,
  Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { beautifyNumber } from '../../../Utils/FormatNumber';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';

const TransactionsTable = ({
  transactions,
  formatDate,
  handleEdit,
  handleSortChange,
  sortConfig,
  handleCheckboxChange,
  selectedTransactions,
  handleDeleteExternalRows
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const { i18n, t } = useTranslation();
  const direction = i18n.dir();

  useEffect(() => {
    setCurrentPage(0);
  }, [transactions]);

  const paginatedTransactions = useMemo(() => {
    const start = currentPage * rowsPerPage;
    const end = start + rowsPerPage;
    return transactions.slice(start, end);
  }, [transactions, currentPage, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const headerStyle = {
    backgroundColor: 'var(--foundation-blue-light-active, #BFC8FF)',
    color: 'var(--Foundation-Blue-Normal, #304FFF)',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 'bold',
    height: '56px',
    padding: '0',
    cursor: 'pointer',
  };

  return (
    <div className={`${direction}`} style={{ width: '100%', height: '55vh' }}>
      <TableContainer component={Paper} sx={{ height: '50vh', overflow: 'auto' }}>
        <Table aria-label="Transactions Table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                key={'isChecked'}
                align="center"
                className="table-headers"
                sx={headerStyle}
                onClick={() => handleSortChange('isChecked', 'isChecked')}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                  {selectedTransactions.length ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleDeleteExternalRows}
                      sx={{
                        backgroundColor: '#d32f2f',
                        color: '#fff',
                        '&:hover': { backgroundColor: '#c62828' },
                        fontSize: '0.9rem',
                      }}
                      startIcon={<DeleteIcon />}
                    >
                      {t('Delete')}
                    </Button>
                  ) : (
                    <>
                      {t('Actions')}
                      {sortConfig.key === 'isChecked' && (
                        sortConfig.direction === 'ascending' ? (
                          <ArrowUpwardIcon fontSize="small" />
                        ) : (
                          <ArrowDownwardIcon fontSize="small" />
                        )
                      )}
                    </>
                  )}
                </div>
              </TableCell>
              {[
                { key: 'transactionDate', label: t('date'), type: 'date' },
                { key: 'description', label: t('Description'), type: 'string' },
                { key: 'reference1', label: t('Reference'), type: 'string' },
                { key: 'debitAccount', label: t('Debit'), type: 'number' },
                { key: 'creditAccount', label: t('Credit'), type: 'number' },
                { key: 'balance', label: t('Balance'), type: 'number' },
                { key: 'matchNumber', label: t('MatchNumber'), type: 'number' }
              ].map((header) => (
                <TableCell
                  key={header.key}
                  align="center"
                  className="table-headers"
                  sx={headerStyle}
                  onClick={() => handleSortChange(header.key, header.type)}
                >
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                    {header.label}
                    {sortConfig.key === header.key && (
                      sortConfig.direction === 'ascending' ? (
                        <ArrowUpwardIcon fontSize="small" />
                      ) : (
                        <ArrowDownwardIcon fontSize="small" />
                      )
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedTransactions.map((transaction, index) => (
              <Tooltip key={transaction.id || index} title={t("DoubleClickToEdit")} arrow>
                <TableRow
                  style={{
                    backgroundColor: transaction.matchNumber !== -1 ? 'rgba(249, 170, 42, 0.1)' : 'inherit',
                    transition: 'transform 0.2s ease-in-out',
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.transform = 'translateY(-3px)')}
                  onMouseLeave={(e) => (e.currentTarget.style.transform = 'translateY(0)')}
                  onDoubleClick={() => handleEdit(transaction)}
                >
                  <TableCell align="center">
                    <Checkbox
                      disabled={transaction.matchNumber !== -1}
                      checked={selectedTransactions.includes(transaction.id)}
                      onChange={() => handleCheckboxChange(transaction)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {transaction.transactionDate ? formatDate(transaction.transactionDate) : '-'}
                  </TableCell>
                  <TableCell align="center">{transaction.description || '-'}</TableCell>
                  <TableCell align="center">{transaction.reference1 || '-'}</TableCell>
                  <TableCell align="center">{beautifyNumber(transaction.debitAccount)}</TableCell>
                  <TableCell align="center">{beautifyNumber(transaction.creditAccount)}</TableCell>
                  <TableCell align="center">{beautifyNumber(transaction.balance)}</TableCell>
                  <TableCell align="center">{transaction.matchNumber === -1 ? '-' : transaction.matchNumber}</TableCell>
                </TableRow>
              </Tooltip>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Controls */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={transactions.length}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('Rows per page')}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('more than')} ${to}`}`
        }
      />
    </div>
  );
};

export default TransactionsTable;
