import config from "../config";
import selectedEnvironment from "../env";
const API_URL = `${config[selectedEnvironment].API_URL}`;

const GetAllAssignments = async (authToken) => {
  const response = await fetch(`${API_URL}/Assignments/GetAll`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  return response;
};

const GetAssignmentById = async (id, authToken) => {
  const response = await fetch(`${API_URL}/Assignments/GetById/${id}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  return response;
};

const CreateAssignment = async (formData, authToken) => {
  const response = await fetch(`${API_URL}/Assignments/Create`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${authToken}`,
    },
    body: formData,
  });
  return response;
};

const UpdateAssignment = async (formData, authToken) => {
  const response = await fetch(`${API_URL}/Assignments/Update`, {
    method: "PUT",
    headers: {
      "Authorization": `Bearer ${authToken}`,
      // "Content-Type": "application/json",
    },
    body: formData,
  });
  return response;
};

const DeleteAssignment = async (id, accountantId, authToken) => {
  
  const response = await fetch(`${API_URL}/Assignments/Delete`, {
    method: "DELETE",
    headers: {
      "Authorization": `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ Id: id, AccountantId: accountantId }),
  });
  return response;
};

const GetAssignmentsByCaseId = async (caseId, accountantId, authToken) => {
  const response = await fetch(`${API_URL}/Assignments/GetByCaseId`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ CaseId: caseId, AccountantId: accountantId }),
  });
  return response;
};

const GetAssignmentsByReporterId = async (reporterId, accountantId, authToken) => {
  const response = await fetch(`${API_URL}/Assignments/GetByReporterId`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ReporterId: reporterId, AccountantId: accountantId }),
  });
  return response;
};

const GetAssignmentsByAccountantId = async (accountantId, authToken, startDate = null, endDate = null, caseId=null) => {
  const response = await fetch(`${API_URL}/Assignments/GetByAccountantId`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      AccountantId: accountantId,
      StartDate: startDate, 
      EndDate: endDate,     
      CaseId: caseId
    }),
  });
  return response;
};

const AddComment = async (formData, authToken) => {
  const response = await fetch(`${API_URL}/Assignments/AddComment`, {
      method: "POST",
      headers: {
          Authorization: `Bearer ${authToken}`,
          // No "Content-Type" header – it is automatically set when using FormData
      },
      body: formData, // Send FormData directly
  });
  return response;
};


const UpdateComment = async (commentData, authToken) => {
  const response = await fetch(`${API_URL}/Assignments/UpdateComment`, {
      method: "PUT",
      headers: {
          "Authorization": `Bearer ${authToken}`,
          "Content-Type": "application/json",
      },
      body: JSON.stringify(commentData),
  });
  return response;
};

const DeleteComment = async (commentId, accountantId, authToken) => {
  const response = await fetch(`${API_URL}/Assignments/DeleteComment`, {
      method: "DELETE",
      headers: {
          "Authorization": `Bearer ${authToken}`,
          "Content-Type": "application/json",
      },
      body: JSON.stringify({ Id: commentId, AccountantId: accountantId }),
  });
  return response;
};

const GetCommentById = async (commentId, authToken) => {
  const response = await fetch(`${API_URL}/Assignments/GetCommentById/${commentId}`, {
      method: "GET",
      headers: {
          "Authorization": `Bearer ${authToken}`,
          "Content-Type": "application/json",
      },
  });
  return response;
};

const GetCommentsByAssignmentId = async (assignmentId, accountantId, authToken) => {
  const response = await fetch(`${API_URL}/Assignments/GetCommentsByAssignmentId`, {
      method: "POST",
      headers: {
          "Authorization": `Bearer ${authToken}`,
          "Content-Type": "application/json",
      },
      body: JSON.stringify({ AssignmentId: assignmentId, AccountantId: accountantId }),
  });
  return response;
};

const UpdateAssignmentStatus = async (accountantId,assignmentId, newStatus, authToken) => {
  
  const response = await fetch(`${API_URL}/Assignments/UpdateStatus`, {
    method: "PUT",
    headers: {
      "Authorization": `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      AssignmentId: assignmentId,
      Status: newStatus,
      AccountantId: accountantId,
    }),
  });
  return response;
};

const GetClientAssignmentSummary = async (accountantId, authToken) => {
  try {
    const response = await fetch(`${API_URL}/Assignments/GetClientAssignmentsSummary/${accountantId}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const jsonData = await response.json(); // Parse the JSON response
    return jsonData; // Return the parsed JSON
  } catch (error) {
    console.error("Error fetching client assignment summary:", error);
    throw error; // Propagate the error for handling by the caller
  }
};

const GetUnfinishedAssignmentsByCaseId = async (caseId, authToken) => {
  try {
    const response = await fetch(`${API_URL}/Assignments/GetClientUnfinishedAssignments/${caseId}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const jsonData = await response.json(); // Parse the JSON response
    return jsonData; // Return the parsed JSON
  } catch (error) {
    console.error("Error fetching unfinished assignments by case ID:", error);
    throw error; // Propagate the error for handling by the caller
  }
};

const GetAllAssignmentCategories = async (accountantId, authToken) => {
  // Use a query-string param to pass accountantId
  const url = `${API_URL}/Assignments/GetAllAssignmentCategories?accountantId=${encodeURIComponent(accountantId)}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  return response;
};

const AddAssignmentCategory = async (accountantId, categoryName, authToken) => {
  // Example endpoint: /Assignments/CreateAssignmentCategory
  // Adjust the route & body structure to match your API
  const response = await fetch(`${API_URL}/Assignments/AddAssignmentCategory`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      AccountantId: accountantId,
      Name: categoryName
    }),
  });
  return response;
};

const UpdateAssignmentCategory = async (accountantId ,categoryId, newName, authToken) => {
  const response = await fetch(`${API_URL}/Assignments/UpdateAssignmentCategory`, {
    method: "PUT",
    headers: {
      "Authorization": `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      Id: categoryId,
      Name: newName,
      AccountantId: accountantId
    }),
  });

  return response;
};

const DeleteAssignmentCategory = async (accountantId, categoryId, authToken) => {
  const response = await fetch(`${API_URL}/Assignments/DeleteAssignmentCategory`, {
    method: "DELETE",
    headers: {
      "Authorization": `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      Id: categoryId,
      AccountantId: accountantId
    }),
  });

  return response;
};


const AssignmentService = {
  GetAllAssignments,
  GetAssignmentById,
  CreateAssignment,
  UpdateAssignment,
  DeleteAssignment,
  GetAssignmentsByCaseId,
  GetAssignmentsByReporterId,
  GetAssignmentsByAccountantId,
  AddComment,         
  UpdateComment,       
  DeleteComment,      
  GetCommentById,      
  GetCommentsByAssignmentId, 
  UpdateAssignmentStatus,
  GetClientAssignmentSummary,
  GetUnfinishedAssignmentsByCaseId,
  GetAllAssignmentCategories,
  AddAssignmentCategory,
  UpdateAssignmentCategory,
  DeleteAssignmentCategory

};

export default AssignmentService;
