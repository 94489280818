import axios from 'axios';
import config from '../config';
import selectedEnvironment from '../env';
import AuthService from './auth.service';
import Constants from '../assests/Constants/constants';
import Cookies from 'js-cookie';
const BASEAPI = config[selectedEnvironment].API_URL;
const API_URL = `${BASEAPI}/policies`;

const PolicyService = {
    /**
     * Fetch Privacy Policy
     * @returns {Promise<Object>}
     */
    getPolicy: async () => {
        try {
            const response = await axios.get(`${API_URL}/${Constants.PrivacyId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching policy:', error.response?.data || error.message);
            throw error;
        }
    },

    /**
     * Accept Privacy Policy
     * @returns {Promise<void>}
     */
    acceptPolicy: async () => {
        try {
            const userId = await AuthService.getCurrentUserTypeId();
            const userType = await AuthService.getCurrentUserType();
            const PolicyId = Constants.PrivacyId;

            // Get Device Info
            const device = navigator.userAgent;

            // Get Public IP
            const ipResponse4 = await fetch('https://api4.ipify.org?format=json'); // IPv4 only
            const ipData4 = await ipResponse4.json();
            const ip = ipData4?.ip || 'Unknown';


            // Prepare Payload
            const payload = {
                userId,
                userType,
                PolicyId,
                ip,
                device,
            };

            // Send Request
            const response = await axios.post(`${API_URL}/accept`, payload);

            if (response.data.success) {
                // Store acceptance in localStorage to prevent future API calls
                localStorage.setItem('privacyPolicyAccepted', 'true');
            }
        } catch (error) {
            console.error('Error accepting privacy policy:', error.response?.data || error.message);
            throw error;
        }
    },

    /**
     *  Securely Check if the User has Accepted the Privacy Policy
     * @returns {Promise<boolean>}
     */
    hasAcceptedPolicy: async () => {
        try {
            // 1) Gather needed info
            const userId = await AuthService.getCurrentUserTypeId();
            const userType = await AuthService.getCurrentUserType();
            const PolicyId = Constants.PrivacyId;

            // 2) Call the server to see if user has accepted
            const response = await axios.post(
                `${API_URL}/hasAccepted`,
                { PolicyId, userId, userType },
                { withCredentials: true } // ensures cookies are sent
            );

            const hasAccepted = response.data?.data?.hasAccepted || false;
            if (hasAccepted) {
                console.log("✅ User has accepted policy - Server confirmed and set cookie");
            }
            return hasAccepted;
        } catch (error) {
            console.error('Error checking policy acceptance:', error.response?.data || error.message);
            return false;
        }
    },
    /**
     * Logout and remove privacy_policy_accepted cookie from the server
     * @returns {Promise<void>}
     */
    logout: async () => {
        try {
            await axios.post(`${API_URL}/logout`, {}, { withCredentials: true });
            console.log('✅ Logged out and cookie cleared');
        } catch (error) {
            console.error('Error during logout:', error.response?.data || error.message);
        }
    }
};

export default PolicyService;
