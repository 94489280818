import React, { useState, useMemo, useEffect, memo } from 'react';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    IconButton,
    Collapse,
    Typography,
    Box,
    FormControlLabel,
    TablePagination,
} from '@mui/material';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enUS, arSA, he } from 'date-fns/locale'; // Import desired locales
import NoDocumentsPdf from '../../../assests/pdf/NoDocument.pdf';
import NoDocumentScanned from '../../../assests/images/svgs/NoDocumentScanned.svg';
import bankIcon from '../../../assests/images/bank-icon.png';
import creditCardIcon from '../../../assests/images/credit-card-icon.png';
import checkIcon from '../../../assests/images/check-icon.png';
import cashIcon from '../../../assests/images/cash-icon.png';
import { beautifyNumber } from '../../../Utils/FormatNumber';
// Translation function
import { useTranslation } from 'react-i18next';
import editIcon from '../../../assests/images/Icons/editIcon.svg';
import deleteIcon from '../../../assests/images/Icons/deleteIcon.svg';
import { Tooltip } from '@mui/material';
// Helper functions (ensure these are defined or imported in your project)
// Example placeholders:
const formatDate = dateString => {
    const date = new Date(dateString);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};
const headerStyle = {
    backgroundColor: 'var(--foundation-blue-light-active, #BFC8FF)',
    color: "var(--Foundation-Blue-Normal, #304FFF)",
    textAlign: "left",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 'bold',
    lineHeight: "normal",
    height: '56px',
    minHeight: '50px',
    padding: "0 5px",

}

const PaymentAcceptanceTable = ({
    filteredAndSortedData,
    subAccountantOptions,
    client,
    // isSelected,
    // handleSelectRow,
    isAllSelected,
    setIsAllSelected,
    handleEdit,
    handleDeleteClick,
    openPdfInNewTab,
    accountCardMap,
    handleSortChange,
    sortConfig,
    isClosedReportingMonth,
    isRange,
    direction,
    handleSelectRows,
    selectedRows,
    setSelectedRows,
    handleRightClickAction
}) => {
    const { i18n, t } = useTranslation(); // Initialize translation function
    const getAlignment = () => {
        return i18n.language === 'en' ? 'left' : 'right';
    };
    // Pagination state
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);

    // State for expanded rows
    const [openRows, setOpenRows] = useState({});
    const [internalSelectedRows, setInternalSelectedRows] = useState([]);

    const handleRightClick = (event, row) => {
        event.preventDefault(); // Prevent the default browser context menu
        const isIdInChecks = client.documentIds.checks.includes(row.id); // Determine if the ID is in checks
        const status = isIdInChecks ? 'done' : 'notDone'; // Determine the opposite status
        const id = row.id; // Extract the row ID

        // Call your function with id, status, and row
        handleRightClickAction(id, status, row);
    };

    const handleToggleRow = (rowId) => {
        setOpenRows((prevOpenRows) => ({
            ...prevOpenRows,
            [rowId]: !prevOpenRows[rowId],
        }));
    };

    
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const isSelected = (id) => selectedRows.indexOf(id) !== -1;

    const handleSelectRow = (event, id) => {

        setInternalSelectedRows((prevSelectedRows) => {
            // Toggle selection of the row ID
            const newSelectedRows = event.target.checked
                ? [...prevSelectedRows, id] // Add ID if checked
                : prevSelectedRows.filter((rowId) => rowId !== id); // Remove ID if unchecked

            handleSelectRows(newSelectedRows); // Send updated IDs to handleSelectRows
            return newSelectedRows; // Update state
        });
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            // Select all IDs
            const newSelectedRows = filteredAndSortedData.map((n) => n.id);
            setInternalSelectedRows(newSelectedRows);
            handleSelectRows(newSelectedRows); // Send all IDs to handleSelectRows
            setIsAllSelected(true);
        } else {
            // Deselect all IDs
            setInternalSelectedRows([]);
            handleSelectRows([]); // Send empty array to handleSelectRows
            setIsAllSelected(false);

        }
    };
    useEffect(() => {
        setCurrentPage(0);
    }, [filteredAndSortedData]);

    // Memoize the current page data
    const pageData = useMemo(() => {
        const start = currentPage * rowsPerPage;
        const end = start + rowsPerPage;
        return filteredAndSortedData.slice(start, end);
    }, [filteredAndSortedData, currentPage, rowsPerPage, selectedRows]);

    const memoizedRows = useMemo(
        () =>
            pageData.map((row) => (
                <Row
                    key={row.id}
                    subAccountantOptions={subAccountantOptions}
                    row={row}
                    open={!!openRows[row.id]}
                    handleToggleRow={() => handleToggleRow(row.id)}
                    isItemSelected={selectedRows.includes(row.id)}
                    handleSelectRow={handleSelectRow}
                    client={client}
                    handleEdit={handleEdit}
                    handleDeleteClick={handleDeleteClick}
                    openPdfInNewTab={openPdfInNewTab}
                    accountCardMap={accountCardMap}
                    formatDate={formatDate}
                    beautifyNumber={beautifyNumber}
                    isClosedReportingMonth={isClosedReportingMonth}
                    isRange={isRange}
                    t={t}
                    headerStyle={headerStyle}
                    getAlignment={getAlignment}
                    handleRightClick={handleRightClick}
                />
            )),
        [
            pageData,
            openRows,
            selectedRows,
            client,
            handleSelectRow,
            handleEdit,
            handleDeleteClick,
            openPdfInNewTab,
            accountCardMap,
            formatDate,
            beautifyNumber,
            isClosedReportingMonth,
            isRange,
            t,
        ]
    );

    return (
        <div className={`${direction}`} style={{ width: '100%', height: '65vh' }}>
            <TableContainer component={Paper} sx={{ height: '60vh', overflow: 'auto' }}>
                <Table aria-label="collapsible table" stickyHeader className="sticky-header" style={{ zIndex: '1' }}>
                    <TableHead sx={{ height: '20px' }}>
                        <TableRow>
                            {/* Table Headers */}
                            <TableCell sx={headerStyle} className="headelsrTH">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isAllSelected}
                                            onChange={(event) => handleSelectAllClick(event)}
                                        />
                                    }
                                    labelPlacement="start"
                                    sx={{
                                        marginLeft: 0,
                                        marginRight: '8px',
                                    }}
                                />
                            </TableCell>
                            <TableCell sx={headerStyle} className="headelsrTH">

                            </TableCell>
                            <TableCell

                                className="headerTH"
                                sx={headerStyle}
                                onClick={() => handleSortChange('debitAccountAmount', 'number')}
                            >
                                {t('Document')}
                            </TableCell>
                            <TableCell
                                className="headerTH"
                                sx={headerStyle}

                                onClick={() => handleSortChange('id', 'newest')}
                            >
                                {t('DocumentProcessed')}
                                {sortConfig.type === 'newest' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                            </TableCell>
                            <TableCell
                                className="headerTH"
                                sx={headerStyle}

                                onClick={() => handleSortChange('name', 'newest')}
                            >
                                {t('DocumentProcessedBy')}
                                {sortConfig.type === 'newest' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                            </TableCell>
                            <TableCell

                                className="headerTH"
                                sx={headerStyle}
                                onClick={() => handleSortChange('description', 'string')}
                            >
                                {t('Description')}
                                {sortConfig.key === 'description' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                            </TableCell>
                            <TableCell
                                sx={headerStyle}

                                className="headerTH"
                                onClick={() => handleSortChange('confirmationNumber', 'number')}
                            >
                                {t('ConfirmationNumber')}
                                {sortConfig.key === 'confirmationNumber' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                            </TableCell>
                            <TableCell

                                className="headerTH"
                                sx={headerStyle}
                                onClick={() => handleSortChange('reference', 'string')}
                            >
                                {t('Reference')}
                                {sortConfig.key === 'reference' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                            </TableCell>
                            <TableCell

                                className="headerTH"
                                sx={headerStyle}
                                onClick={() => handleSortChange('withholdingTax', 'number')}
                            >
                                {t('WithholdingTax')}
                                {sortConfig.key === 'withholdingTax' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                            </TableCell>
                            <TableCell

                                className="headerTH"
                                sx={headerStyle}
                                onClick={() => handleSortChange('amount', 'number')}
                            >
                                {t('Amount')}
                                {sortConfig.key === 'amount' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                            </TableCell>
                            <TableCell

                                className="headerTH"
                                sx={headerStyle}
                                onClick={() => handleSortChange('paymentAcceptanceType', 'string')}
                            >
                                {t('DocumentType')}
                                {sortConfig.key === 'paymentAcceptanceType' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                            </TableCell>
                            <TableCell

                                className="headerTH"
                                sx={headerStyle}
                                onClick={() => handleSortChange('debitCreditAccountAmount', 'number')}
                            >
                                {t('DebitCreditAccountAmount')}
                                {sortConfig.key === 'amount' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                            </TableCell>
                            <TableCell

                                className="headerTH"
                                sx={headerStyle}
                                onClick={() => handleSortChange('accountCodeName', 'string')}
                            >
                                {t('CheckTitle')}
                                {sortConfig.key === 'accountCodeName' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                            </TableCell>
                            <TableCell

                                className="headerTH"
                                sx={headerStyle}
                                onClick={() => handleSortChange('uploadDate', 'date')}
                            >
                                {t('Upload Date')}
                                {sortConfig.key === 'uploadDate' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                            </TableCell>
                            <TableCell
                                sx={{ ...headerStyle, maxWidth: '30px' }}

                                className="headerTH"
                                onClick={() => handleSortChange('uploadNumber', 'number')}
                            >
                                {t('UploadNumber')}
                                {sortConfig.key === 'uploadNumber' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                            </TableCell>
                            <TableCell
                                sx={{ ...headerStyle, maxWidth: '30px' }}

                                className="headerTH"

                            >

                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>{memoizedRows}</TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredAndSortedData.length}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('Rows per page')}
                labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('more than')} ${to}`}`
                }
            />
        </div>
    );
};

export default PaymentAcceptanceTable;

// Row Component
const Row = memo(
    ({
        row,
        open,
        subAccountantOptions,
        handleToggleRow,
        isItemSelected,
        handleSelectRow,
        client,
        handleEdit,
        handleDeleteClick,
        openPdfInNewTab,
        accountCardMap,
        formatDate,
        beautifyNumber,
        isClosedReportingMonth,
        isRange,
        t,
        headerStyle,
        getAlignment,
        handleRightClick
    }) => {
        const isIdInChecks = client.documentIds.checks.includes(row.id);
        
        return (
            <>

                <TableRow
                    selected={isItemSelected}
                    onDoubleClick={() => handleEdit(row)}
                    sx={{
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: '#f5f5f5', // highlight color
                        },
                    }}
                >
                    {/* Data Cells */}
                    {/* Checkbox Column */}
                    <TableCell align="center">
                        <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleSelectRow(event, row.id)}
                        // sx={{
                        //     color: '#E57C22',
                        //     '&.Mui-checked': {
                        //         color: '#E57C22',
                        //     },
                        // }}
                        />
                    </TableCell>

                    {/* Expand Icon Column */}
                    <TableCell align="left">
                        {row.checks || row.creditCards || row.cash || row.bankTransactions ? (
                            <IconButton aria-label="expand row" size="small" onClick={handleToggleRow}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        ) : null}
                    </TableCell>
                    <TableCell align="left">
                        { row.receiptDocumentUrl !== null ? (
                            <div className="iframe-container" onClick={() => openPdfInNewTab(row.receiptDocumentUrl)}>
                                <iframe
                                    onClick={() => openPdfInNewTab(row.receiptDocumentUrl)}
                                    className="zoom-iframe"
                                    src={row.receiptDocumentUrl}
                                    width="100px"
                                    height="50px"
                                    id={row.id}
                                    title={row.title}
                                    style={{ pointerEvents: 'none' }}
                                />
                            </div>
                        ) : (
                            <img
                                src={NoDocumentScanned}
                                alt="No Document Scanned"
                                width="100px"
                                height="50px"
                                onClick={() => openPdfInNewTab(row.receiptDocumentUrl)}
                                style={{ cursor: 'pointer' }}
                            />
                        )}
                    </TableCell>
                    <TableCell align="left" onContextMenu={(event) => handleRightClick(event, row)}>

                        {!isIdInChecks ? (
                            <p className='processed'
                                style={{
                                    cursor: 'context-menu',
                                }}
                            >
                                {t('processed')}
                            </p>
                        ) : (
                            <p className='unprocessed'
                                style={{
                                    cursor: 'context-menu',
                                }}
                            >
                                {t('unprocessed')}
                            </p>
                        )}
                    </TableCell>
                    <TableCell align="left">
                        {row.processedBy ? (
                            subAccountantOptions?.find(option => option.value === row.processedBy)?.label || '-'
                        ) : (
                            "-"
                        )}
                    </TableCell>
                    <TableCell align="left" sx={{ width: '100px', maxHeight: '50px', overflowY: 'auto', textAlign: 'left' }}>
                        {row?.description}
                    </TableCell>
                    <TableCell align="left">{row?.confirmationNumber?.toLocaleString()}</TableCell>
                    <TableCell align="left">{row?.reference}</TableCell>
                    <TableCell align="left" >
                        <p style={{ color: 'green', fontSize: '16px', fontWeight: 'bold' }}>
                            {row?.withholdingTax?.toLocaleString()}
                        </p>
                    </TableCell>
                    <TableCell align="left" >
                        <p style={{ color: 'green', fontSize: '16px', fontWeight: 'bold' }}>{beautifyNumber(row?.amount)}</p>
                    </TableCell>
                    <TableCell align="left">{row?.paymentAcceptanceType}</TableCell>
                    <TableCell align="left">
                        <p style={{ color: 'green', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>
                            {beautifyNumber(row?.debitCreditAccountAmount)}
                        </p>
                    </TableCell>
                    <TableCell align="left">  {accountCardMap[row.accountCodeId] || row.accountCodeName}</TableCell>
                    <TableCell align="left">
                        {new Date(row?.uploadDate).toISOString().split('T')[0]}
                    </TableCell>
                    <TableCell align="left">{row.uploadNumber}</TableCell>
                    {open ? (
                        <TableCell >
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                <button className='row-buttons' onClick={() => handleEdit(row)}>
                                    <img src={editIcon} alt="Edit" />
                                </button>
                                {/* {(isRange ? !row?.isClosedReportingMonth : !isClosedReportingMonth) && (
                                    <button className='row-buttons' onClick={() => handleDeleteClick(row.id)}>
                                        <img src={deleteIcon} alt="Delete" />
                                    </button>
                                )} */}
                            </div>
                        </TableCell>
                    ) : null}
                </TableRow>

                {/* Collapsible Content */}
                {(row.checks.length > 0 ||
                    row.creditCards.length > 0 ||
                    row.cash.length > 0 ||
                    row.bankTransactions.length > 0) && (
                        <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    {/* Checks */}
                                    {row.checks.length > 0 && (
                                        <Box sx={{ margin: 1, marginInlineEnd: 50 }} dir="rtl">
                                            <Typography
                                                variant="h4"
                                                gutterBottom
                                                component="div"
                                                className='main-title'
                                            >
                                                {t('Checks')}
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                gutterBottom
                                                component="div"

                                                style={{ fontWeight: 'bold', textAlign: 'right' }}
                                            >
                                                {row.paymentAcceptanceType === 'תקבול'
                                                    ? `${t('TotalDebitAccount')}: ${row.checks.reduce(
                                                        (total, check) => total + check.amount,
                                                        0
                                                    )} (${t('Debit Account')}: ${accountCardMap[row.checks[0].debitAccount] || ''})`
                                                    : `${t('TotalCreditAccount')}: ${row.checks.reduce(
                                                        (total, check) => total + check.amount,
                                                        0
                                                    )} (${t('Credit Account')}: ${accountCardMap[row.checks[0].creditAccount] || ''})`}
                                            </Typography>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        {/* Checks Table Headers */}
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('date')}
                                                        </TableCell>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('CheckNumber')}
                                                        </TableCell>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('amount')}
                                                        </TableCell>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('supplierAccountNumber')}
                                                        </TableCell>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('bankName')}
                                                        </TableCell>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('Debit Account')}
                                                        </TableCell>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('Credit Account')}
                                                        </TableCell>
                                                        {/* <TableCell  >
                                                            <IconButton onClick={() => handleEdit(row, { type: 'checks', index: -1 })}>
                                                                <img src={editIcon} alt="Edit" />
                                                            </IconButton>
                                                        </TableCell> */}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {row.checks.map((check) => (
                                                        <TableRow key={check.paymentDate}>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {formatDate(check.paymentDate)}
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {check.description}
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {check.amount}
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {check.supplierAccountNumber}
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {check.bankName}
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {accountCardMap[check.debitAccount] || ''}
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {accountCardMap[check.creditAccount] || ''}
                                                            </TableCell>

                                                            <TableCell
                                                                className="pdf-td-payment-acceptance"
                                                                onClick={() => openPdfInNewTab(check.documentUrl)}
                                                            >
                                                                {check.documentUrl ? (
                                                                    <>
                                                                        <div className="iframe-overlay-payment-acceptance"></div>
                                                                        <iframe
                                                                            src={
                                                                                check.documentUrl
                                                                                    ? `${check.documentUrl}#page=1`
                                                                                    : `${NoDocumentScanned}#page=1`
                                                                            }
                                                                            className="table-pdf"
                                                                            title={check.title}
                                                                            style={{ pointerEvents: 'none' }}
                                                                        ></iframe>
                                                                    </>
                                                                ) : (
                                                                    <img
                                                                        src={checkIcon}
                                                                        alt="Check Icon"
                                                                        style={{ height: '100%', width: '100%' }}
                                                                    />
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    )}

                                    {/* Credit Cards */}
                                    {row.creditCards.length > 0 && (
                                        <Box sx={{ margin: 1, marginInlineEnd: 50 }} dir="rtl">
                                            <Typography
                                                variant="h4"
                                                gutterBottom
                                                component="div"
                                                className='main-title'
                                            >
                                                {t('creditCards')}
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                gutterBottom
                                                component="div"

                                                style={{ fontWeight: 'bold', textAlign: 'right' }}
                                            >
                                                {row.paymentAcceptanceType === 'תקבול'
                                                    ? `${t('TotalDebitAccount')}: ${row.creditCards.reduce(
                                                        (total, card) => total + card.amount,
                                                        0
                                                    )} (${t('Debit Account')}: ${accountCardMap[row.creditCards[0].debitAccount] || ''})`
                                                    : `${t('TotalCreditAccount')}: ${row.creditCards.reduce(
                                                        (total, card) => total + card.amount,
                                                        0
                                                    )} (${t('Credit Account')}: ${accountCardMap[row.creditCards[0].creditAccount] || ''})`}
                                            </Typography>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('date')}
                                                        </TableCell>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('amount')}
                                                        </TableCell>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('Description')}
                                                        </TableCell>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('Debit Account')}
                                                        </TableCell>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('Credit Account')}
                                                        </TableCell>
                                                        {/* <TableCell  >
                                                            <IconButton onClick={() => handleEdit(row, { type: 'creditCards', index: -1 })}>
                                                                <img src={editIcon} alt="Edit" />
                                                            </IconButton>
                                                        </TableCell> */}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {row.creditCards.map((creditCard) => (
                                                        <TableRow key={creditCard.amount}>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {formatDate(creditCard.paymentDate)}
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {creditCard.amount}
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {creditCard.description}
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {accountCardMap[creditCard.debitAccount] || ''}
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {accountCardMap[creditCard.creditAccount] || ''}
                                                            </TableCell>
                                                            <TableCell
                                                                className="pdf-td-payment-acceptance"
                                                                onClick={() => openPdfInNewTab(creditCard.documentUrl)}
                                                            >
                                                                <img
                                                                    src={creditCardIcon}
                                                                    alt="Credit Card Icon"
                                                                    style={{ height: '100%', width: '100%' }}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    )}

                                    {/* Cash */}
                                    {row.cash.length > 0 && (
                                        <Box sx={{ margin: 1, marginInlineEnd: 50 }} dir="rtl">
                                            <Typography
                                                variant="h4"
                                                gutterBottom
                                                component="div"
                                                className='main-title'
                                            >
                                                {t('cash')}
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                gutterBottom
                                                component="div"

                                                style={{ fontWeight: 'bold', textAlign: 'right' }}
                                            >
                                                {row.paymentAcceptanceType === 'תקבול'
                                                    ? `${t('TotalDebitAccount')}: ${row.cash.reduce(
                                                        (total, cash) => total + cash.amount,
                                                        0
                                                    )} (${t('Debit Account')}: ${accountCardMap[row.cash[0].debitAccount] || ''})`
                                                    : `${t('TotalCreditAccount')}: ${row.cash.reduce(
                                                        (total, cash) => total + cash.amount,
                                                        0
                                                    )} (${t('Credit Account')}: ${accountCardMap[row.cash[0].creditAccount] || ''})`}
                                            </Typography>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('date')}
                                                        </TableCell>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('amount')}
                                                        </TableCell>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('Description')}
                                                        </TableCell>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('Debit Account')}
                                                        </TableCell>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('Credit Account')}
                                                        </TableCell>
                                                        {/* <TableCell  >
                                                            <IconButton onClick={() => handleEdit(row, { type: 'cash', index: -1 })}>
                                                                <img src={editIcon} alt="Edit" />
                                                            </IconButton>
                                                        </TableCell> */}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {row.cash.map((cashses) => (
                                                        <TableRow key={cashses.amount}>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {formatDate(cashses.paymentDate)}
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {cashses.amount}
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {cashses.description}
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {accountCardMap[cashses.debitAccount] || ''}
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {accountCardMap[cashses.creditAccount] || ''}
                                                            </TableCell>
                                                            <TableCell
                                                                className="pdf-td-payment-acceptance"
                                                                onClick={() => openPdfInNewTab(cashses.documentUrl)}
                                                            >
                                                                <img
                                                                    src={cashIcon}
                                                                    alt="Cash Icon"
                                                                    style={{ height: '100%', width: '100%' }}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    )}

                                    {/* Bank Transactions */}
                                    {row.bankTransactions.length > 0 && (
                                        <Box sx={{ margin: 1, marginInlineEnd: 50 }} dir="rtl">
                                            <Typography
                                                variant="h4"
                                                gutterBottom
                                                component="div"
                                                className='main-title'
                                            >
                                                {t('bankTransactions')}
                                            </Typography>

                                            {/* Grouped Totals */}
                                            {row.paymentAcceptanceType === 'תקבול'
                                                ? Object.values(
                                                    row.bankTransactions.reduce((acc, bankTransaction) => {
                                                        if (!acc[bankTransaction.debitAccount]) {
                                                            acc[bankTransaction.debitAccount] = [];
                                                        }
                                                        acc[bankTransaction.debitAccount].push(bankTransaction);
                                                        return acc;
                                                    }, {})
                                                ).map((group) => (
                                                    <Typography
                                                        variant="subtitle1"
                                                        gutterBottom
                                                        component="div"

                                                        style={{ fontWeight: 'bold', textAlign: 'right' }}
                                                    >
                                                        {`${t('TotalDebitAccount')}: ${group.reduce(
                                                            (total, bankTransaction) => total + bankTransaction.amount,
                                                            0
                                                        )} (${t('Debit Account')}: ${accountCardMap[group[0].debitAccount] || ''})`}
                                                    </Typography>
                                                ))
                                                : Object.values(
                                                    row.bankTransactions.reduce((acc, bankTransaction) => {
                                                        if (!acc[bankTransaction.creditAccount]) {
                                                            acc[bankTransaction.creditAccount] = [];
                                                        }
                                                        acc[bankTransaction.creditAccount].push(bankTransaction);
                                                        return acc;
                                                    }, {})
                                                ).map((group) => (
                                                    <Typography
                                                        variant="subtitle1"
                                                        gutterBottom
                                                        component="div"

                                                        style={{ fontWeight: 'bold', textAlign: 'right' }}
                                                    >
                                                        {`${t('TotalCreditAccount')}: ${group.reduce(
                                                            (total, bankTransaction) => total + bankTransaction.amount,
                                                            0
                                                        )} (${t('Credit Account')}: ${accountCardMap[group[0].creditAccount] || ''})`}
                                                    </Typography>
                                                ))}

                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('date')}
                                                        </TableCell>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('amount')}
                                                        </TableCell>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('Description')}
                                                        </TableCell>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('Debit Account')}
                                                        </TableCell>
                                                        <TableCell sx={headerStyle} style={{ textAlign: getAlignment() }} >
                                                            {t('Credit Account')}
                                                        </TableCell>
                                                        {/* <TableCell  >
                                                            <IconButton onClick={() => handleEdit(row, { type: 'bankTransactions', index: -1 })}>
                                                                <img src={editIcon} alt="Edit" />
                                                            </IconButton>
                                                        </TableCell> */}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {row.bankTransactions.map((bankTransaction) => (
                                                        <TableRow key={bankTransaction.amount}>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {formatDate(bankTransaction.paymentDate)}
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {bankTransaction.amount}
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {bankTransaction.description}
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {accountCardMap[bankTransaction.debitAccount] || ''}
                                                            </TableCell>
                                                            <TableCell style={{ textAlign: getAlignment() }} >
                                                                {accountCardMap[bankTransaction.creditAccount] || ''}
                                                            </TableCell>
                                                            <TableCell className="pdf-td-payment-acceptance">
                                                                <img
                                                                    src={bankIcon}
                                                                    alt="Bank Icon"
                                                                    style={{ height: '100%', width: '100%' }}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    )}
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    )}
            </>
        );
    },
    (prevProps, nextProps) => {
        // Only re-render if row or selection status has changed
        return (
            prevProps.row === nextProps.row &&
            prevProps.open === nextProps.open &&
            prevProps.isItemSelected === nextProps.isItemSelected
        );
    }
);
