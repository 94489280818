import dayjs from 'dayjs';
export const getInitials = (name) => {
    if (!name) return "N/A";
    return name
        .split(" ")
        .map((word) => word[0])
        .join("");
};


// export const getInitials = (name) => {
//     if (!name) return "N/A";
//     return name
//         .split(" ")
//         .map((word) => word[0].toUpperCase())
//         .join("");
// };


export const validateEmail = (email) => {
    if (!email || email.trim() === '') return true; // Allow empty email

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};



export const validatePhoneNumber = (phoneNumber) => {
    if (!phoneNumber || phoneNumber.toString().trim() === '') return true; // Allow empty phone number

    const cleanedNumber = phoneNumber.toString().trim();
    return cleanedNumber.length === 9 || cleanedNumber.length === 10;
};


export const getBiMonthlyRange = () => {
    const currentMonth = dayjs().month() + 1; // getMonth() returns 0-based index, so add 1
    let startMonth, endMonth;

    if (currentMonth >= 1 && currentMonth <= 2) {
        startMonth = 1;
        endMonth = 2;
    } else if (currentMonth >= 3 && currentMonth <= 4) {
        startMonth = 3;
        endMonth = 4;
    } else if (currentMonth >= 5 && currentMonth <= 6) {
        startMonth = 5;
        endMonth = 6;
    } else if (currentMonth >= 7 && currentMonth <= 8) {
        startMonth = 7;
        endMonth = 8;
    } else if (currentMonth >= 9 && currentMonth <= 10) {
        startMonth = 9;
        endMonth = 10;
    } else {
        startMonth = 11;
        endMonth = 12;
    }

    const year = dayjs().year();

    // Adjust for timezone offset to ensure accurate local time
    const adjustToDate = (date) => {
        const startOfMonth = dayjs(date).startOf('month');
        const offset = startOfMonth.utcOffset();
        return startOfMonth.add(offset, 'minute').toDate();
    };

    return {
        startDateBi: adjustToDate(`${year}-${startMonth}-01`),
        endDateBi: adjustToDate(dayjs(`${year}-${endMonth}-01`).endOf('month')),
    };
};


export function getBiMonthlyRangeByDate(date) {
    // For a “normal” Date object, consider getUTCFullYear() / getUTCMonth() 
    // if you’ve been dealing with all UTC logic. 
    // If not strictly in UTC, switch to getFullYear() / getMonth().
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth(); // 0-based: Jan = 0, Feb = 1, ...

    // Bi-monthly blocks in pairs (Jan-Feb, Mar-Apr, etc.)
    const biMonthlyPeriods = [
        { label: '1-2', value: 0 },   // Jan-Feb
        { label: '3-4', value: 2 },   // Mar-Apr
        { label: '5-6', value: 4 },   // May-Jun
        { label: '7-8', value: 6 },   // Jul-Aug
        { label: '9-10', value: 8 },  // Sep-Oct
        { label: '11-12', value: 10 } // Nov-Dec
    ];

    // Figure out which 2-month block 'month' falls into
    // e.g. month=0 or 1 => index=0 => block is 1-2, so startMonth=0, endMonth=1
    const index = Math.floor(month / 2); // 0..5
    const startMonth = biMonthlyPeriods[index].value;   // e.g. 0
    const endMonth = startMonth + 1;                    // e.g. 1

    // Construct start/end in UTC, inclusive of last second of end month
    // i.e. endMonth + 1 -> day 0 => last day of that next month’s “previous” day
    const startDate = new Date(Date.UTC(year, startMonth, 1));
    const endDate = new Date(Date.UTC(year, endMonth + 1, 0, 23, 59, 59, 999));

    return [startDate, endDate];
}
