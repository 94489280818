import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
/********************************
 * 1. Check if string is not empty
 ********************************/
export function isNotEmpty(value) {
    return value !== undefined && value !== null && String(value).trim() !== '';
}

/******************************************************
 * 2. Validate Israeli ID (T.Z.) using checksum logic
 *    Israeli IDs are typically up to 9 digits
 *    (leading zeros included up to 9),
 *    but we usually validate 8 digits + checksum.
 ******************************************************/
export function isValidIsraeliID(idNumber) {
    // if (!isNotEmpty(idNumber)) return false;

    let id = String(idNumber).trim();
    // Ensure at least 8 digits by prepending zeros
    while (id.length < 8) {
        id = '0' + id;
    }
    // Israeli ID logic
    let total = 0;
    for (let i = 0; i < id.length; i++) {
        let digit = parseInt(id.charAt(i), 10);
        // Multiply digit by 1 or 2
        digit = digit * (i % 2 === 0 ? 1 : 2);
        // If result is >=10, sum the digits (same as digit - 9)
        if (digit > 9) digit -= 9;
        total += digit;
    }
    // Valid if total mod 10 == 0
    return total % 10 === 0;
}

/***************************************************
 * 4. Validate Global Company IDs (example)
 *    Currently supports US EIN and generic numeric IDs
 ***************************************************/

// Validate US Employer Identification Number (EIN)
export function isValidUSEIN(ein) {
    const cleanedEIN = ein.trim();
    return /^\d{2}-\d{7}$/.test(cleanedEIN);
}

// Generic numeric international company ID (length 6 to 15 digits)
export function isValidGenericInternationalCompanyID(number) {
    const numericID = number.replace(/\D/g, '');
    return /^\d{6,15}$/.test(numericID);
}

// Wrapper for all global company ID checks
export function isValidGlobalCompanyID(value) {
    return (
        isValidUSEIN(value)
        // isValidGenericInternationalCompanyID(value) accepts all from 6 to 15
    );
}
/************************************************************
 * 3. Validate Israeli company number using checksum logic
 *    Must be 9 digits total (with leading zeros if necessary)
 ************************************************************/
export function isValidIsraeliCompanyNumber(companyNumber) {
    // if (!isNotEmpty(companyNumber)) return false;

    let num = String(companyNumber).trim();
    // Ensure 9 digits
    while (num.length < 9) {
        num = '0' + num;
    }

    let total = 0;
    for (let i = 0; i < 8; i++) {
        let digit = parseInt(num.charAt(i), 10);
        // Multiply alternately by 1 or 2
        digit = digit * (i % 2 === 0 ? 1 : 2);
        // Sum digits if >= 10
        if (digit > 9) digit -= 9;
        total += digit;
    }

    const checksum = parseInt(num.charAt(8), 10); // 9th digit
    return (total + checksum) % 10 === 0;
}

/****************************************************************************************
 * 4. Validate if the string is either a valid Israeli ID (T.Z.) or a valid company number
 *    This is especially useful if you allow "accountCaseId" to be either ID or company ID.
 ****************************************************************************************/
export function isValidIDOrCompanyID(value) {
    if (!isNotEmpty(value)) return true;
    // First, try ID
    if (isValidIsraeliID(value)) return true;
    // If not, try company number
    if (isValidIsraeliCompanyNumber(value)) return true;
    // global company verify
    if (isValidGlobalCompanyID(value)) return true;
    return false;
}

/**********************************************
 * 5. Check if reference is valid (not empty)
 **********************************************/
export function isValidReference(value) {
    return isNotEmpty(value);
}

/**************************************************************************************
 * 6. Check if document date is valid and within the last 6 months (example implementation)
 *    Adjust according to your exact requirement (could be within 180 days or not in future)
 **************************************************************************************/
/**
 * Checks if the difference between the document date and the reporting date is more than six months.
 * If the reporting type is bimonthly ('דו חודשי'), adjust the month accordingly.
 * 
 * @param {string} reportingMonthDate - The reporting month date in ISO format
 * @param {string} documentDate - The document date in ISO format
 * @param {string} reportingType - The reporting type (e.g., 'דו חודשי' for bimonthly)
 * @returns {boolean} - Returns true if the difference between the dates exceeds 6 months.
 */
export function isOlderThanSixMonths(reportingMonthDate, documentDate, reportingType) {
    if (!reportingMonthDate || !documentDate) return false;

    let reportingDate = dayjs.utc(reportingMonthDate).startOf('month');

    if (reportingType === 'דו חודשי') {
        // Extract the month number (1-based)
        const month = reportingDate.month() + 1;

        // Determine the start month of the bimonthly pair
        const startMonth = month % 2 === 0 ? month - 1 : month;

        // Adjust the reporting date to the first day of the correct month
        reportingDate = reportingDate.month(startMonth - 1).startOf('month');
    }

    
    // Convert documentDate to UTC start of the month
    const documentDateUTC = dayjs.utc(documentDate).startOf('month');

    // Compute the absolute difference in months
    const monthsDifference = Math.abs(documentDateUTC.diff(reportingDate, 'months'));


    // Check if the difference is greater than 6 months
    return monthsDifference > 6;
}

/************************************************************************************
 * 7. (Optional) Validate the entire invoice object before processing
 *    This ensures each critical field passes the above validations.
 *    Adjust to your real invoice data fields and logic.
 ************************************************************************************/
export function isValidInvoiceData(invoice) {
    if (!invoice) return false;

    // We assume the invoice object has: accountCaseId, reference, documentDate, etc.
    // Use any combination of the functions above to check what you consider “valid.”
    const { accountCaseId, reference, documentDate } = invoice;

    // Example checks:
    return (
        isValidIDOrCompanyID(accountCaseId) &&
        isValidReference(reference) &&
        isOlderThanSixMonths(documentDate)
    );
}
