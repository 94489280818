import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import Select from 'react-select';
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import enGB from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import UserService from '../../Services/user.service';
import '../OtherDocuments/OtherDocument.css';
import backIcon from '../../assests/images/left-arrow.png';
import EnumsService from '../../Services/enums.service';
import CustomDeleteConfirmationModal from '../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal'
import { ToastContainer, toast } from "react-toastify";
import DownloadIcon from '@mui/icons-material/Download';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useBack } from "use-back";
import NoDocumentsPdf from "../../assests/pdf/NoDocument.pdf";
import { useSignalR } from '../../Contexts/signalR';
import { Dropdown } from 'primereact/dropdown';
import 'primereact/resources/themes/saga-blue/theme.css';  // Optional: PrimeReact theme
import 'primereact/resources/primereact.min.css';           // PrimeReact CSS
import 'primeicons/primeicons.css';
import SaveIcon from '@mui/icons-material/Save'; // Save icon
import ClearIcon from '@mui/icons-material/Clear';
import AdvancedDropdown from '../AdvancedDropdown/AdvancedDropdown';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import MergeIcon from '@mui/icons-material/Merge';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CustomMonthDatePicker from '../Widgets/CustomDates/CustomMonthDatePicker/CustomMonthDatePicker';
import editIcon from '../../assests/images/Icons/editIcon.svg';
import PuffLoader from "react-spinners/PuffLoader";
import generateDepreciationReportHtml from "./SubComponents/AssetDepreciationReportHTML";




//table imports
import { Edit as EditIcon, Delete as DeleteIcon, Download as DownloadIconMui, Search as SearchIcon } from '@mui/icons-material';
import {
    Button, Dialog, DialogTitle, DialogContent, Box, DialogActions, Typography, TextField, Checkbox,
    InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem,
    ToggleButton, ToggleButtonGroup, Switch, CircularProgress, IconButton, Tooltip, FormControlLabel, Divider,
    Grid
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { beautifyNumber } from '../../Utils/FormatNumber';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import styled from '@mui/system/styled';
import UpperPanelDetails from '../Widgets/UpperPanelDetails/UpperPanelDetails';
import AutocompleteSelect from '../Widgets/AutocompleteSelect/AutocompleteSelect';
import CustomMonthYearIndividualDatePicker from '../Widgets/CustomDates/CustomMonthYearIndividualDatePicker/CustomMonthYearIndividualDatePicker';
import AssetService from '../../Services/assets.service';
import AssetTable from './SubComponents/AssetTable';
import EditAsset from './SubComponents/EditAsset';
import AddAsset from './SubComponents/AddAsset';
import AddAssetGroup from './SubComponents/AddAssetGroup';
import EditAssetGroup from './SubComponents/EditAssetGroup';
import DocumentService from '../../Services/documents.service';
import EditModalForSingleEntry from '../EditModal/EditModalForSingleEntry';
import AssetDepreciationReportHTML from './SubComponents/AssetDepreciationReportHTML';
import SubAccountantService from '../../Services/subAccountant.service';
import ChooseAsset from '../Widgets/ChooseAsset/ChooseAsset';
import Constants from '../../assests/Constants/constants';
import ClientService from '../../Services/client.service';
import ClientDocumentCountService from '../../Services/ClientDocumentCount.service';
import { width } from '@mui/system';
import { NavHistoryContext } from '../../Contexts/NavHistoryContext';
import SmallTransactionViewer from './SubComponents/SmallTransactionViewer';
registerLocale('en-GB', {
    ...enGB,
    localize: {
        ...enGB.localize,
        month: n => n + 1, // Display months as numbers starting from 1
    },
    formatLong: {
        ...enGB.formatLong,
        date: () => 'MM/yyyy', // Date format when the picker is not showing
    },
});

setDefaultLocale('en-GB'); // Set the default locale to use it
const StyledDialogTitle = styled(DialogTitle)({
    fontSize: '1.8rem',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#304FFF',
});

const StyledDialogContent = styled(DialogContent)({
    textAlign: 'center',
    padding: '20px 30px',
});

const StyledDialogActions = styled(DialogActions)({
    justifyContent: 'center',
    paddingBottom: '20px',
});

const StyledButton = styled(Button)({
    margin: '0 8px',
    padding: '10px 20px',
    fontSize: '1.1rem',
    textTransform: 'none',
    color: '#fff',
    backgroundColor: '#E57C22',
    '&:hover': {
        backgroundColor: '#d9731a',
    },
});
const PaymentTypeOptions = [
    { name: "CreditCard", value: "0" },
    { name: "Check", value: "1" },
    { name: "BankTransfer", value: "2" },
    { name: "Cash", value: "3" }
];



const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    width: '100%',
    height: '10vh',
    '& .MuiAutocomplete-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center"

    },
    '& *': {
        boxSizing: 'border-box !important',

    },
    '& .MuiDataGrid-columnHeaders': {
        // opacity: 0.7,
        backgroundColor: '#BFC8FF',
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#BFC8FF',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        color: '#304FFF',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    '& .MuiDataGrid-cell': {
        borderTop: '1px solid rgba(191, 191, 193, 0.56)',
        backgroundColor: 'rgba(234, 237, 255, 0.32)',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: 'rgba(191, 200, 255, 0.3)',
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-cell': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center"
    },
    '& .disableUser': {
        pointerEvents: 'none',
        opacity: 0.5,
    },
    // New styles for edit mode
    '& .MuiDataGrid-cell--editing': {
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '8px',
        paddingRight: '8px',
    },
    '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
        borderRight: 'none',
    },
    '& .MuiDataGrid-row--editing .MuiDataGrid-cell:not(:last-child)': {
        borderRight: '8px solid transparent',
    },
    '& .MuiDataGrid-cell--editing': {
        padding: 0,
        backgroundColor: 'inherit',
    },
}));

const OptionButton = styled(Button)({
    margin: '10px 8px',
    padding: '12px 25px',
    fontSize: '1.1rem',
    textTransform: 'none',
    color: '#304FFF',
    backgroundColor: '#f5f5f5',
    border: '1px solid #E57C22',
    width: 'auto',  // Allows the button to shrink when there are many buttons
    maxWidth: '200px',  // Set a maximum width to ensure consistency
    flexGrow: 1,  // Allows buttons to fill available space equally
    '&:hover': {
        backgroundColor: '#E57C22',
        color: '#fff',
    },
});



const ActionButton = styled(Button)({
    backgroundColor: '#E57C22', // Consistent background with other elements
    borderRadius: 4, // Same border-radius as MUI TextField and Switch
    color: 'white',
    height: 40, // Same height as other input elements
    padding: '0 16px', // Consistent padding
    fontSize: '0.875rem', // Matching font size with TextField
    textTransform: 'none',
    boxSizing: 'border-box',
    '&:hover': {
        backgroundColor: '#d9731a', // Slightly darker on hover
    },
    '&:disabled': {
        backgroundColor: '#E0E0E0',
        color: '#BDBDBD',
    },
});


function Assets() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const [user, setUser] = useState(null);
    const [token, setToken] = useState('');
    const [client, setClient] = useState(null);
    const [type, setType] = useState('');
    const location = useLocation();
    const { goBack } = useContext(NavHistoryContext);

    const [loading, setLoading] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'cardId', direction: 'descending', type: 'number' });
    const [isEditSingleEntryOpen, setIsEditSingleEntryOpen] = useState(false);
    const [vatEquipmentTransactions, setVatEquipmentTransactions] = useState([]);
    const [isTransactionViewerOpen, setIsTransactionViewerOpen] = useState(false);



    const [assets, setAssets] = useState([]);
    const [assetGroups, setAssetGroups] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [addGroupModalOpen, setAddGroupModalOpen] = useState(false);
    const [editGroupModalOpen, setEditGroupModalOpen] = useState(false);
    const [selectedAssetGroup, setSelectedAssetGroup] = useState(null);
    const [reportHtmlContent, setReportHtmlContent] = useState("");
    const [depreciationData, setDepreciationData] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');


    const [date, setDate] = useState(null);
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    // In your Assets component:
    const handleCloseReport = () => {
        setIsReportModalOpen(false);
    };



    //-------------------Edit Item---------------------------
    const [editedDocument, setEditedDocument] = useState({});
    const [editedAddAccountCard, setEditedAddAccountCard] = useState(false);
    const [accountCardOptions, setAccountCardOptions] = useState([]);
    const [creditAccountOptions, setCreditAccountOptions] = useState([]);
    const [debitAccountOptions, setDebitAccountOptions] = useState([]);
    const [clientItems, setClientItems] = useState([]);
    const [oldAccountName, setOldAccountName] = useState({});
    const [oldAccountCaseId, setOldAccountCaseId] = useState({});
    const [oldAccountCardId, setOldAccountCardId] = useState({});

    const [accountCardEditDownloadOptions, setAccountCardEditDownloadOptions] = useState([]);
    const [sortCodeIdOptions, setSortCodeIdOptions] = useState([]);
    const [statusCheck, setStatusCheck] = useState(false);
    const [isClosedReportingMonth, setIsClosedReportingMonth] = useState(false);
    const [subAccountantOptions, setSubAccountantOptions] = useState([]);
    const [fade, setFade] = useState(false);
    const [isFirstUserFetched, setIsFirstUserFetched] = useState(false);

    const [reportDate, setReportDate] = useState(() => new Date());



    //-------------------------------------------------------


    const handleEditGroupClick = (group) => {
        setSelectedAssetGroup(group);
        setEditGroupModalOpen(true);
    };

    const closeEditGroupModal = () => {
        setEditGroupModalOpen(false);
        setSelectedAssetGroup(null);
    };

    const handleAddGroupClick = () => setAddGroupModalOpen(true);
    const closeAddGroupModal = () => setAddGroupModalOpen(false);

    //account cards


    const fileInputRef1 = useRef(null); // Reference to the first hidden file input




    const [collectionPageData, setCollectionPageData] = useState({});
    const [startDate, setStartDate] = useState(() => {
        const storedDateString = localStorage.getItem('StartDate');
        return storedDateString ? new Date(storedDateString) : new Date();
    });;

    const [endDate, setEndDate] = useState(() => {
        const storedEndDateString = localStorage.getItem('EndDate');
        return storedEndDateString ? new Date(storedEndDateString) : new Date();
    });
    const assetsRef = useRef([]);
    const assetGroupsRef = useRef([]);


    const inputStyle = {
        style: {
            textAlign: direction === 'rtl' ? 'right' : 'left', // Align based on direction
            direction: direction === 'rtl' ? 'rtl' : 'ltr', // Set direction explicitly
        },
    };

    const fetchVatEquipmentTransactions = async (updatedStartDate, updatedEndDate) => {
        try {
            const vatEquipResponse = await AssetService.GetJournalEntriesAndExpensesWithVatEquipment(
                client?.caseId,
                client?.accountantId,
                updatedStartDate,
                updatedEndDate,
                token
            );
            

            if (vatEquipResponse.success) {
                toast.success(t("Account card deleted successfully"));

                setVatEquipmentTransactions(vatEquipResponse.data);
            }
        } catch (error) {
            console.error('Error fetching VAT Equipment Transactions:', error);
        }
    };


    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,

        sx: {
            height: '48px',
            width: '250px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        },
    };

    const fetchEnums = async (user, token, client) => {

        try {
            const storedDateString = localStorage.getItem('StartDate');
            const storedDate30th = new Date(startDate.getFullYear(), startDate.getMonth(), 25);

            const assetResponse = await AssetService.GetAllAssets(client?.caseId, token);
            const assetGroupResponse = await AssetService.GetAllAssetGroups(client?.caseId, token);
            const vatEquipResponse = await AssetService.GetJournalEntriesAndExpensesWithVatEquipment(
                client?.caseId,
                client?.accountantId,
                startDate.toISOString(),
                endDate.toISOString(),
                token
            );


            if (assetResponse.success && assetGroupResponse.success) {
                assetsRef.current = assetResponse.data;
                assetGroupsRef.current = assetGroupResponse.data;
                setAssets(assetResponse.data);
                setAssetGroups(assetGroupResponse.data);
                if (vatEquipResponse?.success) {
                    setVatEquipmentTransactions(vatEquipResponse.data);
                }
            } else {
                toast.error(t('FailedToFetchAssets'));
            }
        } catch (error) {
            console.error(error);
            toast.error(t('fetchEnumsError'), error);
            // Handle the error appropriately
            // setIsClientDataFetched(false)
        }
        await fetchEnumsForEditModal(user, token, client);
        setLoading(false);

    };

    //depreciation report logic
    const handleViewReport = async (date = null) => {

        if (!client) {
            toast.error("Client details missing");
            return;
        }
        setLoading(true);
        try {


            const selectedDate = date !== null ? date : reportDate; // Ensure the correct date is chosen


            const response = await AssetService.GetDepreciationDetails(client.caseId, selectedDate, token);
            setDate(new Date().toISOString());
            setLoading(false);

            if (response && response.assetGroups.length > 0) {
                setDepreciationData(response);
                setIsReportModalOpen(true);
            } else {
                toast.error(t("No depreciation data available"));
            }
        } catch (error) {
            setLoading(false);
            toast.error("Error fetching depreciation report");
        }
    };





    const [expanded, setExpanded] = useState(false);
    useEffect(() => {
        if (!expanded) {
            setExpandedRows(assetGroups.reduce((acc, group) => {
                acc[group.assetGroupId] = true;
                return acc;
            }, {}));
            setExpanded(true);
        }

    }, [assetGroups]);

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const currentUser = await UserService.getCurrentUser();
                const currentToken = await UserService.getCurrentToken();
                if (currentUser && currentToken) {
                    setUser(currentUser);
                    setToken(currentToken);

                    // Get client data from location.state
                    if (location.state && location.state.client && location.state.type) {
                        setType(location.state.type);
                        setClient(location.state.client);
                    }
                    await fetchEnums(currentUser, currentToken, location.state.client);
                    if (client) {
                        await handleViewReport();

                    }
                    // Fetch financial data for the current date on initial load only
                }
                else {
                    navigate("/login");
                }


            } catch (error) {
                // Handle error - maybe navigate to login or show a message
            }
            setLoading(false);
        };
        fetchUserData();
    }, [location.state.client]);

    const [cardIdOption, setCardIdOption] = useState(-1);



    const formatDate = dateString => {
        const date = new Date(dateString);
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };



    const handleSortChange = (key, type, Direction = '') => {
        let direction = Direction || 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }

        setSortConfig({ key, direction, type });
    };

    // table functions
    const headerStyle = {
        backgroundColor: '#f7f7f7',
        color: 'black',
        fontWeight: 'bold'
    }


    // useEffect(() => {
    //     // Calculate the width as 70% of the container width minus 20px
    //     const containerWidth = document.querySelector('.table-container').offsetWidth;
    //     const newWidth = (containerWidth * 0.7) - 20;
    //     setCalculatedWidth(newWidth + 'px');
    // }, []);

    const handleGoBack = () => {
        // Navigate back to the previous page using React Router
        if (client) {
            // handleBack();
            goBack()
        }
    };

    const [itemIdToDelete, setItemIdToDelete] = useState(null);
    const [deleteModalVisable, setDeleteModalVisable] = useState(null);

    const handleConfirmDelete = async () => {
        try {
            const response = await EnumsService.DeleteAccountCardsbyCaseId(client.caseId, itemIdToDelete.cardId, token);

            if (response.ok) {
                toast.success(t("Account card deleted successfully"));
                setDeleteModalVisable(false);
                fetchEnums(user, token, client); // Fetch updated enums after the delete action
            } else {
                const errorMessage = await response.text(); // Extract the error message from the response body
                if (errorMessage.includes("Account card can't be deleted, documents with account card exist")) {
                    toast.error(t('AccountCardCannotBeDeleted'));
                } else {
                    toast.error(t('TaskFailed'));
                }
            }

        } catch (error) {

            // Handle errors from the catch block (e.g., network issues or exceptions)
            if (error?.response?.data?.includes("Account card can't be deleted, documents with account card exist")) {
                toast.error(t('AccountCardCannotBeDeleted'));
            } else {
                toast.error(t('TaskFailed'));
            }
            console.error(t('TaskFailed'));
        }
    };
    const handleDeleteClick = (accountCard) => {
        setItemIdToDelete(accountCard);
        setDeleteModalVisable(true);
    };

    const clickedDisabled = (action) => {
        if (action === 'Edit') {
            toast.info(t('CannotEditThisAccountCard'));
        } else {
            toast.info(t('CannotDeleteAccountCard'));

        }
    };

    const clickedDisabledRow = () => {
        toast.info(t('LockedCardEditor'));
    };


    const formatDate3 = (value) => {

        if (!value) {
            return "-"
        }
        return dayjs(value).format('DD/MM/YYYY');
    };




    const [dialogOpen, setDialogOpen] = useState(false);





    const handleStartDateChange = async (date) => {
        const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        localStorage.setItem('StartDate', newDate)
        setStartDate(newDate);
    };

    const handleStartDateChangeNew = async (newValue, type) => {
        

        const newDate = new Date(newValue);
        let updatedDate;
        
        

        if (type === 'year') {
            updatedDate = new Date(Date.UTC(newDate.getFullYear(), startDate.getMonth(), 10));
        } else if (type === 'month') {
            updatedDate = new Date(Date.UTC(startDate.getFullYear(), newDate.getMonth(), 10));
        }


        


        localStorage.setItem('StartDate', updatedDate)
        setStartDate(updatedDate);
        fetchVatEquipmentTransactions(updatedDate, endDate);
    };

    const handleEndDateChangeNew = async (newValue, type) => {
        

        const newDate = new Date(newValue);
        let updatedDate;

        if (type === 'year') {
            updatedDate = new Date(Date.UTC(newDate.getFullYear(), endDate.getMonth(), 10));
        } else if (type === 'month') {
            updatedDate = new Date(Date.UTC(endDate.getFullYear(), newDate.getMonth(), 10));
        }

        

        localStorage.setItem('EndDate', updatedDate);
        setEndDate(updatedDate);
        fetchVatEquipmentTransactions(startDate, updatedDate);
    };




    const handleExpandClick = (groupId) => {
        setExpandedRows(prev => ({
            ...prev,
            [groupId]: !prev[groupId]
        }));
    };

    const handleRowClick = (id, type) => {
        if (type == 'asset') {
            const assetToSet = assetsRef.current.find(a => a.id === id);
            setSelectedAsset(assetToSet);
            setEditModalOpen(true);
        } else {
            const group = assetGroupsRef.current.find(a => a.assetGroupId === id);
            setSelectedAssetGroup(assetGroups.find(a => a.assetGroupId === id))
            setEditGroupModalOpen(true);
        }
    };



    const closeEditModal = () => {
        setAssignedDocument(null);
        setEditModalOpen(false);
        setSelectedAsset(null);
    };

    const [assignedDocument, setAssignedDocument] = useState(null);

    const handleAddClick = () => {


        setAssignedDocument(null);
        setSelectedAsset(null); // Clear selected asset for adding new
        setAddModalOpen(true);
    };

    const closeAddModal = () => {
        setAddModalOpen(false);
        setAssignedDocument(false);
    };

    const handleAddAsset = async (newAsset) => {
        try {


            const formattedAsset = {
                caseId: client?.caseId,
                assetNumber: newAsset.assetNumber?.toString(),
                name: newAsset.name,
                description: newAsset.description || null,
                purchaseDate: new Date(newAsset.purchaseDate).toISOString(),
                activationDate: new Date(newAsset.activationDate).toISOString(),
                originalPrice: parseFloat(newAsset.originalPrice),
                depreciationPercentage: parseFloat(newAsset.depreciationPercentage),
                assetGroupId: newAsset.assetGroupId
            };


            if (assignedDocument.type === Constants.EntryType.Expense) {
                formattedAsset.expenseId = assignedDocument.id
            } else {
                formattedAsset.journalEntryId = assignedDocument.id
            }

            const response = await AssetService.CreateAsset(formattedAsset, token);
            if (response.success) {
                toast.success(t('AssetAddedSuccessfully'));
                closeAddModal(); // Close the modal after adding
                fetchEnums(user, token, client);

            } else {
                toast.error(t('Failed to add asset'));
            }
        } catch (error) {
            console.error(error);
            toast.error(t('An error occurred while adding asset'));
        }
    };

    const viewAttachDocument = () => {
        setIsTransactionViewerOpen(true);
    }

    const handleDelete = async (asset) => {

        if (!asset) {
            toast.error(t("Invalid asset details for deletion"));
            return;
        }

        try {
            const response = await AssetService.DeleteAsset(client.caseId, asset, token);
            if (response.success) {
                toast.success(t("AssetDeletedSuccessfully"));
                fetchEnums(user, token, client);
            } else {
                toast.error(t("Failed to delete asset"));
            }
            setEditModalOpen(false);
        } catch (error) {
            console.error(error);
            toast.error(t("An error occurred while deleting asset"));
        }
    };


    const viewAssetDocument = async (asset) => {

        if (!asset) {
            console.error("No asset provided.");
            return;
        }

        let documentType = null;
        let documentId = null;

        if (asset.journalEntryId) { //journalEntryId
            documentType = "JournalEntries"; // Adjust based on actual API endpoint naming
            documentId = asset.journalEntryId;
        } else if (asset.expenseId) {
            documentType = "Expenses"; // Adjust based on actual API endpoint naming
            documentId = asset.expenseId;
        } else {
            toast.info(t('AssetDoesNotHaveAssociatedDocument'));
            return;
        }

        try {
            const response = await DocumentService.GetDocumentById(documentType, client.caseId, documentId, token);

            if (!response.ok) {
                throw new Error(`Failed to fetch document: ${response.status}`);
            }

            let documentData = {};
            documentData = await response.json();
            documentData.documentType = documentType;
            handleOpenEditSingleEntry(documentData);
        } catch (error) {
            console.error("Error fetching document:", error);
            console.error("errorFetchingData");
        }
    };

    const viewDocument = async (docId, type) => {

        let documentType = type;

        if (type === Constants.EntryType.Expense) {
            documentType = 'Expenses';
        } else if (type === Constants.EntryType.JournalEntry) {
            documentType = 'JournalEntries';
        } else {
            console.error('Unknown document type:', type);
            return; // Prevent further execution if the type is unknown
        }

        let documentId = docId;
        try {
            const response = await DocumentService.GetDocumentById(documentType, client.caseId, documentId, token);

            if (!response.ok) {
                throw new Error(`Failed to fetch document: ${response.status}`);
            }

            let documentData = {};
            documentData = await response.json();
            documentData.documentType = documentType;
            handleOpenEditSingleEntry(documentData);
        } catch (error) {
            console.error("Error fetching document:", error);
            console.error("errorFetchingData");
        }
    };

    //------------------------This section is for edit modal-------------------------------


    const [formattedPalCodeOptions, setFormattedPalCodeOptions] = useState([]);
    const [accountant, setAccountant] = useState({});
    const [subAccountants, setSubAccountants] = useState([]);
    const fetchEnumsForEditModal = async (user, token, client) => {
        setLoading(true);
        try {
            // Initiate all fetch requests in parallel
            const [
                codeIdResponse,
                accountsResponse,
                clientItemsResponse,
                palCodesResponse
            ] = await Promise.all([
                EnumsService.GetSortCodesByAccountantId(client.accountantId, token),
                EnumsService.GetAccountCardsByCaseId(client.caseId, token),
                EnumsService.GetClientItemsByCaseId(client.caseId, token),
                EnumsService.GetPalCodesByAccountantId(client.accountantId, token)
            ]);

            // const response1 = await ClientService.CheckIfMonthClosed(client.caseId, newDate, token);
            // if (response1.ok) {
            //     const isClosedReportingMonth = await response1.json();
            //     setIsClosedReportingMonth(isClosedReportingMonth);
            // }

            // Process sortCodeIdOptions if response is okay
            if (codeIdResponse.ok) {
                const codeIdData = await codeIdResponse.json();
                setSortCodeIdOptions(codeIdData); // Assuming codeIdData is already in the correct format
            }

            // Process account options if response is okay
            if (accountsResponse.ok) {
                const accountsData = await accountsResponse.json();
                setCreditAccountOptions(accountsData);
                setDebitAccountOptions(accountsData); // Assuming both options are the same
                setAccountCardEditDownloadOptions(accountsData);
                const accountCardOptions = [{ name: t('AddAccountCard'), value1: 'AddItem' }, ...accountsData];
                setAccountCardOptions(accountCardOptions);

            }

            // Process client items if response is okay
            if (clientItemsResponse.ok) {
                const clientItemsData = await clientItemsResponse.json();
                clientItemsData.unshift({ makat: 'add-item', name: t('AddItem') });
                setClientItems(clientItemsData);
            }

            // Process palCodes if response is okay
            if (palCodesResponse.ok) {
                const palCodesData = await palCodesResponse.json();
                const formattedPalCodes = palCodesData.map(palCode => ({
                    label: palCode.palCodeId,
                    value: palCode.palCodeId
                }));
                setFormattedPalCodeOptions(formattedPalCodes);
            }

            //For File Processing----------------------------------------------------------------------------
            const result2 = await SubAccountantService.GetSubAccountantByAccountantId(token, user.accountantId);
            const accountantResult = await SubAccountantService.GetAccountantById(token, user.accountantId);
            const enhancedAccountantResult = {
                ...accountantResult,
                subAccountantId: accountantResult.accountantId, // Add subAccountantId as same as accountantId
            };
            setAccountant(enhancedAccountantResult);
            if (result2) {
                const combinedSubAccountants = [enhancedAccountantResult, ...result2];

                // If client exists, add it to the combined list
                if (client) {
                    combinedSubAccountants.push({
                        subAccountantId: client.caseId,
                        name: client.name,
                    });
                }

                setSubAccountants(combinedSubAccountants);

                const combinedOptions = combinedSubAccountants.map((acc) => ({
                    value: acc.subAccountantId,
                    label: acc.name,
                }));

                setSubAccountantOptions(combinedOptions);
            } else {
                toast.error(t('fetchSubAccountantsDataError'));
            }

            setIsFirstUserFetched(true);

        } catch (error) {
            toast.error(t('enumFetchError'));
        } finally {
            setLoading(false);
        }
    };

    const handleAccountCardSave = async (AccountCodeId, AccountCodeName, CompanyId) => {

        let response2;
        if (type === 'Incomes') {

            response2 = await EnumsService.AddAccountCard(client.caseId, AccountCodeId, AccountCodeName, CompanyId, "לקוחות", token);

        } else {

            response2 = await EnumsService.AddAccountCard(client.caseId, AccountCodeId, AccountCodeName, CompanyId, "ספקים", token);
        }
        if (!response2.ok) {

            throw new Error(`HTTP error! status: ${response2.status}`);
        }
    };


    const RefreshPage = async () => {
        handleCancel();
        await fetchEnums(user, token, client);
    }




    const handleReopenEdit = async (item, index) => {
        await fetchEnums(user, token, client);
    };
    const [actionType, setActionType] = useState('delete');
    const [editedPopupItem, setEditedPopupItem] = useState(true);




    const handleEditDeleteClick = (itemId) => {
        setActionType('delete')
        setEditedPopupItem(false);
        setEditedDocument({});
        handleDeleteClick(itemId);
    };

    const handleCancel = () => {

        setEditedDocument({});
        setEditedAddAccountCard(false);
        setIsEditSingleEntryOpen(false);
    };

    const fetchDocumentsNotification = async (client, token) => {
        const documentCounts = await ClientDocumentCountService.getClientDocumentCountByCaseId(client.caseId, token);

        // Enrich clients with their total document counts
        const enrichedClient = {
            ...client,
            totalCounts: documentCounts.totalCounts || 0, // Default to 0 if no matching count is found
            documentCounts: documentCounts.documentCounts || {},
            documentIds: documentCounts.documentIds || {},
        };

        setClient(enrichedClient);
    }

    const handleDoneClick = async (itemId) => {
        setLoading(true);
        try {
            const response = await ClientDocumentCountService.removeDocumentByTypeAndId(client.caseId, client.accountantId, type, itemId, token);
            if (response.ok) {
                await fetchDocumentsNotification(client, token)
                toast.success(t('DocumentProcessed'), {
                    position: "top-left"
                });
            } else {
                const errorText = await response.text();
                toast.error(t('DocumentNotProcessed', { errorText }));
            }
        } catch (error) {
            toast.error(t('DocumentNotProcessed', { errorText: error.message }));
        }
        setLoading(false);
    };



    const handleNotDoneClick = async (itemId) => {
        setLoading(true);
        try {
            const response = await ClientDocumentCountService.addDocumentByTypeAndId(client.caseId, client.accountantId, type, itemId, token)
            if (response.ok) {
                await fetchDocumentsNotification(client, token)
                toast.success(t('DocumentProcessed'), {
                    position: "top-left"
                });
            } else {
                const errorText = await response.text();
                toast.error(t('DocumentNotProcessed', { errorText }));
            }
        } catch (error) {
            toast.error(t('DocumentNotProcessed', { errorText: error.message }));
        }
        setLoading(false);
    };


    const handleUpdate = async (itemId) => {
        // setEditedPopupItem(false);
        setLoading(true);
        const documentUrl = editedDocument.documentUrl;

        try {
            delete editedDocument.documentUrl;

            const response = await DocumentService.UpdateDocument(editedDocument.documentType, token, editedDocument);
            if (editedDocument.status == 'done') {
                await handleDoneClick(editedDocument.id);
                // handleSortChange('id', 'newest', 'descending');
            }
            else if (editedDocument.status == 'notDone') {
                await handleNotDoneClick(editedDocument.id)
            }

            if (editedAddAccountCard) {

                await handleAccountCardSave(editedDocument.accountCodeId, editedDocument.accountCodeName, editedDocument.accountCaseId);
                await fetchEnums(user, token, client);

            }

        } catch (error) {
            setEditedDocument({});
            toast.error(t("documentUpdateError"));
        }
        editedDocument.documentUrl = documentUrl;
        setEditedAddAccountCard(false);
        setLoading(false);
        // Refresh or update your data list
    };




    const handleConfirm = async () => {
        if (actionType === 'delete') {
            await handleConfirmDelete();
        }
    };

    const getConfirmMessage = () => {
        switch (actionType) {
            case 'closeMonth':
                return t('AreYouSureCloseMonth');
            case 'openMonth':
                return t('AreYouSureOpenMonth');
            default:
                return t('confirmDeleteMessage');

        }
    };


    const getTitleMessage = () => {
        switch (actionType) {

            case 'closeMonth':
                return t('ConfirmCloseMonth');
            case 'openMonth':
                return t('ConfirmOpenMonth');
            default:
                return t('confirmDeleteTitle');
        }
    };

    const handleEditAddAccountChange = (selectedOption, field) => {
        if (field === 'AccountCodeId') {
            type === 'Incomes' ? setEditedDocument({ ...editedDocument, accountCodeId: selectedOption, debitAccount: selectedOption }) : setEditedDocument({ ...editedDocument, accountCodeId: selectedOption, creditAccount: selectedOption });
        }
        else if (field === 'accountCodeName') {

            setEditedDocument({ ...editedDocument, accountCodeName: selectedOption })
        }
        else {
            setEditedDocument({ ...editedDocument, accountCaseId: selectedOption })

        }
    };
    const handleCloseEditAddNewAccountCardDocument = () => {
        setEditedDocument({ ...editedDocument, accountCodeName: oldAccountName, accountCodeId: oldAccountCardId, accountCaseId: oldAccountCaseId });
        setEditedAddAccountCard(false);
    };

    const [reportingMonthDateCreate, setReportingMonthDateCreate] = useState(new Date());


    const handleRadioChange = (e) => {
        if (e.target.value?.toString() === 'done') {
            if (client.accountManagement === Constants.AccountManagementOptions[0].name && (!editedDocument.sortCodeId || !editedDocument.creditAccount || !editedDocument.debitAccount)) { /// means "כפולה"
                toast.info(t('DocumentMustHaveSortCodeCreditDebitAccountToMarkAsProcessed'));
                return
            }
            else if (!editedDocument.sortCodeId) {
                toast.info(t('DocumentMustHaveSortCodeToMarkAsProcessed'));
                return
            }
            setStatusCheck(true);
        }
        else if (e.target.value?.toString() === 'notDone') {
            setStatusCheck(false);
        }
        setEditedDocument({ ...editedDocument, ["status"]: e.target.value });
    };

    const handleVatRadioChange = (e) => {
        setEditedDocument({ ...editedDocument, ["vat"]: parseFloat(e.target.value) });
    };

    const setReportingMonthDate = async (selectedDate, field) => {
        if (!selectedDate) {
            return;
        }
        const newDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), 10));
        const response1 = await ClientService.CheckIfMonthClosed(client.caseId, newDate, token);
        if (response1.ok) {
            const isClosedReportingMonth = await response1.json();
            if (isClosedReportingMonth) {
                toast.info(t('MonthReportAlreadyClosedTryAgain'));
                return
            }
            else {


                setEditedDocument({ ...editedDocument, [field]: newDate });
            }
        }
    }

    const CustomInput2 = React.forwardRef(({ value, onClick }, ref) => (
        <button className="edit-custom-input" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const handleDateChange = (e, field) => {
        setEditedDocument({ ...editedDocument, [field]: e });
    };

    const updateAccountFields = (newValue, accountType) => {


        const isIncomesDebit = editedDocument.docType === 'Incomes' && accountType === 'debitAccount';
        const isExpensesCredit = editedDocument.docType === 'Expenses' && accountType === 'creditAccount';

        const updatedFields = {
            accountCodeId: isIncomesDebit || isExpensesCredit ? (newValue ? newValue.cardId : '') : '',
            accountCodeName: isIncomesDebit || isExpensesCredit ? (newValue ? newValue.name : '') : '',
            accountCaseId: isIncomesDebit || isExpensesCredit ? (newValue ? newValue.accountCaseId : '') : '',
        };

        // Update the edited item with the new fields
        setEditedDocument((prev) => ({
            ...prev,
            [accountType]: newValue ? newValue.cardId : '',
            ...(isIncomesDebit || isExpensesCredit ? updatedFields : {}),
        }));
    };

    const handleChange = (field, value) => {
        const updatedValue = value;

        if (field === 'sortCodeId') {

            if (!value || !sortCodeIdOptions.some((option) => option.sortCodeId === value)) {
                // Set default values for the empty state
                setEditedDocument({
                    ...editedDocument,
                    vat: 100, // Default VAT
                    [field]: '',
                });
                return; // Exit early since there's no valid sortCodeId
            }

            let newVat = 100;
            const name = sortCodeIdOptions.find(
                (option) => option.sortCodeId === value
            )?.sortCodeName || ''

            // Extract the name without the parentheses
            const nameWithoutParentheses = name.replace(/\s*\(.*?\)\s*/, '').trim();

            // Check for a number within parentheses
            const match = name.match(/\((\d+)%?/);
            let customVat = match ? parseInt(match[1], 10) : null;


            const sortCode = sortCodeIdOptions.filter(sc => sc.sortCodeId === updatedValue)[0];

            // Determine the VAT based on the name without parentheses
            if (Constants.SortCodesWith66Vat.includes(nameWithoutParentheses)) {
                newVat = 66;
            } else if (Constants.SortCodesWithZeroVat.includes(nameWithoutParentheses) || sortCode?.sortCodeVatType === 0) {
                newVat = 0;

            } else if (Constants.SortCodesWith16Vat.includes(nameWithoutParentheses)) {
                newVat = 16;
            } else if (customVat !== null) {
                // If a custom VAT was found, use it
                newVat = customVat === 66 ? 66 : customVat;
            } else {
                // Default VAT if no special conditions are met
                newVat = 100;
            }


            const accountCard = accountCardOptions.find(
                (item) => item.cardId === (type === 'Incomes' ? sortCode.debitAccount : sortCode.creditAccount)
            );

            const debit = accountCardOptions.find(
                (item) => item.cardId === sortCode.debitAccount
            );

            const credit = accountCardOptions.find(
                (item) => item.cardId === sortCode.creditAccount
            );



            if (value === '1') { //hova
                setEditedDocument({
                    ...editedDocument,
                    vat: newVat,
                    [field]: updatedValue,
                    creditAccount: null,
                    debitAccount: debit ? debit.cardId : editedDocument?.debitAccount,
                    accountCodeName: accountCard ? accountCard.name : editedDocument.accountCodeName,
                    accountCodeId: accountCard ? accountCard.cardId : editedDocument.accountCodeId,
                    accountCaseId: accountCard ? accountCard.accountCaseId : editedDocument.accountCaseId,
                });
                return;
            }
            if (value === '2') { //z5ot
                setEditedDocument({
                    ...editedDocument,
                    vat: newVat,
                    [field]: updatedValue,
                    creditAccount: credit ? credit.cardId : editedDocument?.creditAccount,
                    debitAccount: null,
                    accountCodeName: accountCard ? accountCard.name : editedDocument.accountCodeName,
                    accountCodeId: accountCard ? accountCard.cardId : editedDocument.accountCodeId,
                    accountCaseId: accountCard ? accountCard.accountCaseId : editedDocument.accountCaseId,
                });
                return;
            }


            setEditedDocument({
                ...editedDocument,
                vat: newVat,
                [field]: updatedValue,
                creditAccount: credit ? credit.cardId : editedDocument?.creditAccount,
                debitAccount: debit ? debit.cardId : editedDocument?.debitAccount,
                accountCodeName: accountCard ? accountCard.name : editedDocument.accountCodeName,
                accountCodeId: accountCard ? accountCard.cardId : editedDocument.accountCodeId,
                accountCaseId: accountCard ? accountCard.accountCaseId : editedDocument.accountCaseId,
            });

        }
        else if (field === 'creditAccount' || field === 'debitAccount') {

            // Use the helper function to update account fields for credit or debit account
            const newValue = accountCardOptions.find(option => option.cardId === updatedValue) || null;
            updateAccountFields(newValue, field);
        }
        else {
            // For fields other than sortCodeId, just update the edited item
            setEditedDocument({
                ...editedDocument,
                [field]: updatedValue,
            });
        }
    };


    const [isRange, setIsRange] = useState(() => {
        const storedIsRange = localStorage.getItem('IsRange');
        return storedIsRange === "true";
    });;

    const [originalAccountCardId, setOriginalAccountCardId] = useState('');


    const handleAccountChange = (selectedOption, field, CustomDropdownFlag = false) => {
        if (field === 'debitAccount' || field === 'creditAccount') {
            const accountCardEditDownload = accountCardEditDownloadOptions.find(option => option.cardId === selectedOption.value);
            const name = accountCardEditDownload?.name;
            const accountCaseId = accountCardEditDownload?.accountCaseId;
            if (selectedOption.value < 0) {

                setOriginalAccountCardId(editedDocument.cardId)
            }
            type === 'Incomes' ? setEditedDocument({ ...editedDocument, accountCodeName: name, accountCodeId: selectedOption.value, debitAccount: selectedOption.value, accountCaseId: accountCaseId }) : setEditedDocument({ ...editedDocument, accountCodeName: name, accountCodeId: selectedOption.value, creditAccount: selectedOption.value, accountCaseId: accountCaseId });
        } else if (field === 'accountCodeId') {
            if (selectedOption.value1 === 'AddItem') {
                setEditedAddAccountCard(true);
                setOldAccountName(editedDocument.accountCodeName);
                setOldAccountCaseId(editedDocument.accountCaseId);
                setOldAccountCardId(editedDocument.accountCodeId);
                setEditedDocument({ ...editedDocument, accountCodeName: '', accountCodeId: '', accountCaseId: '' });
            }
            else {
                const accountCardEdit = accountCardEditDownloadOptions.find(option =>
                    option.cardId === (CustomDropdownFlag ? selectedOption.cardId : selectedOption.value)
                );
                const name = accountCardEdit?.name;
                const accountCaseId = accountCardEdit?.accountCaseId;
                const cardId = CustomDropdownFlag ? selectedOption.cardId : selectedOption.value;
                setEditedDocument({ ...editedDocument, accountCodeName: name, accountCodeId: cardId, debitAccount: cardId, accountCaseId: accountCaseId })
            }
        }
    };

    const handleOpenEditSingleEntry = (item) => {
        setEditedDocument(item);
        setIsEditSingleEntryOpen(true);
    };

    //----------------------------END EDIT MODAL SECTION-----------------------------------

    const handleAssignDocument = (row) => {

        setAssignedDocument(row);
        setIsTransactionViewerOpen(false);
    }


    return (
        <>

            <SmallTransactionViewer
                transactions={vatEquipmentTransactions}
                open={isTransactionViewerOpen}
                onClose={() => setIsTransactionViewerOpen(false)}
                viewDocument={viewDocument}
                handleAssignDocument={handleAssignDocument}
                assets={assets}
                startDate={startDate}
                endDate={endDate}
                setStartDate={handleStartDateChangeNew}
                setEndDate={handleEndDateChangeNew}
            />


            <EditAsset
                caseId={client?.caseId}
                open={editModalOpen}
                onClose={closeEditModal}
                asset={selectedAsset}
                assetGroups={assetGroups}
                refreshAssets={() => fetchEnums(user, token, client)}
                onDelete={handleDelete}
                onSave={() => fetchEnums(user, token, client)}
                viewDocument={viewAssetDocument}
                token={token}
            />
            <AddAsset
                open={addModalOpen}
                onClose={closeAddModal}
                refreshAssets={() => fetchEnums(user, token, client)}
                handleSave={handleAddAsset}
                viewAttachDocument={viewAttachDocument}
                assets={assets}
                assignedDocument={assignedDocument}
                assetGroups={assetGroups}
            />

            <AddAssetGroup
                open={addGroupModalOpen}
                onClose={closeAddGroupModal}
                caseId={client?.caseId}
                refreshAssetGroups={() => fetchEnums(user, token, client)}
                assetGroups={assetGroups}
                token={token}
            />

            <EditAssetGroup
                open={editGroupModalOpen}
                onClose={closeEditGroupModal}
                caseId={client?.caseId}
                assetGroup={selectedAssetGroup}
                refreshAssetGroups={() => fetchEnums(user, token, client)}
                onDelete={() => fetchEnums(user, token, client)}
                token={token}

            />


            {/* Depreciation Report Dialog */}
            <AssetDepreciationReportHTML
                open={isReportModalOpen}
                depreciationContent={depreciationData}
                client={client}
                date={reportDate}
                token={token}
                onRowClick={handleRowClick}
                onClose={handleCloseReport}
                reportDate={reportDate}
                handleEndDateChangeNew={handleEndDateChangeNew}
                setReportDate={setReportDate}


            />

            <EditModalForSingleEntry
                editedItem={editedDocument}
                fade={fade}
                subAccountantOptions={subAccountantOptions}
                NoDocumentsPdf={NoDocumentsPdf}
                t={t}
                handleAccountChange={handleAccountChange}
                isRange={isRange}
                isClosedReportingMonth={isClosedReportingMonth}
                accountCardOptions={accountCardOptions}
                handleChange={handleChange}
                handleDateChange={handleDateChange}
                CustomInput2={CustomInput2}
                setReportingMonthDate={setReportingMonthDate}
                handleVatRadioChange={handleVatRadioChange}
                statusCheck={statusCheck}
                handleRadioChange={handleRadioChange}
                type={type}
                editedAddAccountCard={editedAddAccountCard}
                handleCloseEditAddNewAccountCardDocument={handleCloseEditAddNewAccountCardDocument}
                handleEditAddAccountChange={handleEditAddAccountChange}
                sortCodeIdOptions={sortCodeIdOptions}
                debitAccountOptions={debitAccountOptions}
                creditAccountOptions={creditAccountOptions}
                handleUpdate={handleUpdate}
                handleCancel={handleCancel}
                handleDeleteClick={handleEditDeleteClick}
                client={client}
                token={token}
                setAccountCardOptions={setAccountCardOptions}
                setLoading={setLoading}
                setEditedAddAccountCard={setEditedAddAccountCard}
                setEditedItem={setEditedDocument}
                handleReopenEdit={handleReopenEdit}
                palCodes={formattedPalCodeOptions}
                RefreshPage={RefreshPage}
                handleAccountCardSave={handleAccountCardSave}
                fetchEnums={fetchEnums}
            />



            <CustomDeleteConfirmationModal
                isVisible={deleteModalVisable}
                onClose={() => setDeleteModalVisable(false)}
                onDelete={handleConfirm}
                confirmMessage={getConfirmMessage()}
                titleMessage={getTitleMessage()}
                type={actionType}
            />

            <div className="template-page-withoutHeight">
                <div className={`template-container ${direction}`}>
                    <UpperPanelDetails client={client} />

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <div className={`main-title ${direction}`}>{t(type)}</div>

                        <div className='backIconBackground' style={{ alignSelf: i18n.language === 'en' ? 'flex-start' : 'flex-end' }} onClick={handleGoBack}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M20 21.1667L14.8267 15.9933L20 10.82C20.52 10.3 20.52 9.45999 20 8.93999C19.48 8.41999 18.64 8.41999 18.12 8.93999L12 15.06C11.48 15.58 11.48 16.42 12 16.94L18.12 23.06C18.64 23.58 19.48 23.58 20 23.06C20.5067 22.54 20.52 21.6867 20 21.1667Z" fill="black" fill-opacity="0.9" />
                            </svg>
                        </div>
                    </div>
                    <div className={`title-back-container ${direction}`} style={{ gap: 10 }}>

                        <button onClick={() => handleAddClick(true)} className="create-Invoice-selector" >
                            <span>{t("AddAsset")}</span>
                            <AddCircleIcon fontSize="small" />
                        </button>

                        <button onClick={() => handleAddGroupClick()} className="create-Invoice-selector" >
                            <span>{t("AddAssetGroup")}</span>
                            <AddCircleIcon fontSize="small" />
                        </button>

                        <button onClick={() => handleViewReport()} className="create-Invoice-selector" >
                            <span>{t("DepreciationFormYA")}</span>
                        </button>

                        <button onClick={() => setIsTransactionViewerOpen(true)} className="create-Invoice-selector">
                            <span>{t("ViewEquipmentTransactions")}</span>
                        </button>
                        <TextField
                            fullWidth
                            placeholder={t('Search')}
                            value={searchQuery || ''}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            variant="standard"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                        />
                    </div>

                    <div className={`title-back-container ${direction}`} style={{ gap: 10, justifyContent: 'space-between', marginTop: '20px' }}>

                        <Typography variant="h4" >
                            {/* title of page */}

                        </Typography>



                    </div>
                    {/* current table */}
                    <div className='table-container' style={{ overflowX: 'hidden', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >

                        <AssetTable
                            assets={assets}
                            assetGroups={assetGroups}
                            expandedRows={expandedRows}
                            handleExpandClick={handleExpandClick}
                            onRowClick={handleRowClick}
                            searchQuery={searchQuery}
                            t={t}
                        />

                    </div>
                </div>

                <HamburgerMenu client={client} />

                <ToastContainer />
                {loading && (
                    <div className="loader-container">
                        <PuffLoader
                            size={100}        // Larger size for better visibility
                            loading={loading}
                        />
                    </div>
                )}
            </div >

        </>
    );
}


export default Assets;
