import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal, Backdrop, Fade } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import './HelpIcon.css';
const YOUTUBE_API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;


const helpVideos = {
    "global": [
        { title: "צ'אט", videoUrl: "https://www.youtube.com/watch?v=WEiVBfhfSNI" },
    ],
    "/Document": [
        { title: "הכנסות / הוצאות", videoUrl: "https://www.youtube.com/watch?v=MwoW1aV6AEw" },
        { title: "עריכת פקודת יומן הכנסות והוצאות", videoUrl: "https://www.youtube.com/watch?v=IXg4K7OtvG4" },
    ],
    "/OtherDocument": [
        { title: "מסמכים אחרים", videoUrl: "https://www.youtube.com/watch?v=y-o58cmWXv8" }
    ],
    "/client-details": [
        { title: "דף לקוח - סקירה", videoUrl: "https://www.youtube.com/watch?v=cWbWnQlVT0s" },
        { title: 'דיווח מע"מ', videoUrl: "https://www.youtube.com/watch?v=GLbYs5uEHJw" },
        { title: "דיווח מקדמות", videoUrl: "https://www.youtube.com/watch?v=fsjIQ8YsccM" },
        { title: "אחוז מקדמה מומלצת", videoUrl: "https://www.youtube.com/watch?v=HA3XPgCzDqQ" },
        { title: "ייבוא וייצוא נתונים", videoUrl: "https://www.youtube.com/watch?v=vqSWP09Yt7s" },
        { title: "דוחות", videoUrl: "https://www.youtube.com/watch?v=28Ehp-V0ffI" },
    ],
    "/Accountant-details": [
        { title: "סקירה על דף הרואה החשבון", videoUrl: "https://www.youtube.com/watch?v=_T8HSl0NBxk" },
        { title: "קטגוריה קוד מיון", videoUrl: "https://www.youtube.com/watch?v=ZJ5tBbxTNbs" },
        { title: "קוד מיון", videoUrl: "https://www.youtube.com/watch?v=8lEUbFxI8E8" },
        { title: 'קבוצה חשבונאית', videoUrl: "https://www.youtube.com/watch?v=koiYjrBIqk8" },
        { title: "קטגוריה סיווג מאזני", videoUrl: "https://www.youtube.com/watch?v=EPEL2Fu7zTM" },
        { title: "סיווג מאזני", videoUrl: "https://www.youtube.com/watch?v=oEud_K7UE88" },
        { title: "מסמכים אחרים", videoUrl: "https://www.youtube.com/watch?v=y-o58cmWXv8" },
    ],
    "/Main": [
        { title: "רשימת העסקים", videoUrl: "https://www.youtube.com/watch?v=Qmdt37sE2c0" },
    ],
    "/SubAccountants": [
        { title: "מנהל חשבונות", videoUrl: "https://www.youtube.com/watch?v=M1gXETZ2L_c" }
    ],
    "/ProfitAndLossReport": [
        { title: "דוח רווח והפסד", videoUrl: "https://www.youtube.com/watch?v=QMgDvfETkQA" }
    ],
    "/SortCodeReport": [
        { title: "דוח קוד מיון", videoUrl: "https://www.youtube.com/watch?v=hCYsdJ6sgCI" }
    ],
    // "/TransactionDocuments": [
    //     { title: "Managing Transaction Documents", videoUrl: "https://www.youtube.com/watch?v=abcd9202" }
    // ],
    "/PaymentAcceptance": [
        { title: "תקבולים / תשלומים", videoUrl: "https://www.youtube.com/watch?v=-IxIuiBGhKA" }
    ],
    "/LedgerReport": [
        { title: "דוח כרטסת", videoUrl: "https://www.youtube.com/watch?v=DsqcGcj1e3A" }
    ],
    "/AccountCardDashboard": [
        { title: "דוח כרטסת", videoUrl: "https://www.youtube.com/watch?v=DsqcGcj1e3A" }
    ],
    "/AccountCardsTable": [
        { title: "כרטיס חשבון", videoUrl: "https://www.youtube.com/watch?v=WHsZJNRh6yY" },
        { title: "כרטיסים קבועים במערכת", videoUrl: "https://www.youtube.com/watch?v=OgKDIW5q3n4" },
    ],
    "/JournalEntry": [
        { title: "פקודות יומן", videoUrl: "https://www.youtube.com/watch?v=XH_yp5FNeps" },
        { title: "עריכת פקודת יומן הכנסות והוצאות", videoUrl: "https://www.youtube.com/watch?v=IXg4K7OtvG4" },

    ],
    "/BankTransactions": [
        { title: "דף בנק והתאמת בנק", videoUrl: "https://www.youtube.com/watch?v=SqIlsfZPyh8" },
        { title: "התאמת כרטיסים", videoUrl: "https://www.youtube.com/watch?v=W-l6yKwTezE" },
    ],
    "/BankPages": [
        { title: "דף בנק והתאמת בנק", videoUrl: "https://www.youtube.com/watch?v=SqIlsfZPyh8" },
    ],
    "/AddClient": [
        { title: "הוספת עסק", videoUrl: "https://www.youtube.com/watch?v=tHFZALJtxIU" },
        { title: "עריכת עסק", videoUrl: "https://www.youtube.com/watch?v=OoA2Y5F4QAs" },
    ],
    "/CreateInvoice": [
        { title: "הפקת מסמכים", videoUrl: "https://www.youtube.com/watch?v=JN8tCjcdGbQ" },
        { title: "מספר הקצאה", videoUrl: "https://www.youtube.com/watch?v=zAL5er1iFEo" },
    ],
    "/ReceiptTax": [
        { title: "הפקת מסמכים", videoUrl: "https://www.youtube.com/watch?v=JN8tCjcdGbQ" },
        { title: "מספר הקצאה", videoUrl: "https://www.youtube.com/watch?v=zAL5er1iFEo" },
    ],
    "/CollectionPage": [
        { title: "ניהול גבייה", videoUrl: "https://www.youtube.com/watch?v=53NWQ9OdwUA" }
    ],
    "/CardMatching": [
        { title: "התאמת כרטיסים", videoUrl: "https://www.youtube.com/watch?v=W-l6yKwTezE" }
    ],
    "/Assignments": [
        { title: "משימות", videoUrl: "https://www.youtube.com/watch?v=fEsVuSfBZlI" }
    ],

};


const extractVideoId = (url) => {
    const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w-]{11})/);
    return match ? match[1] : null;
};

const fetchVideoDescription = async (videoId) => {
    try {
        const response = await fetch(
            `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${YOUTUBE_API_KEY}`
        );
        const data = await response.json();
        return data.items[0]?.snippet?.description || "Description not available";
    } catch (error) {
        console.error("Error fetching video description:", error);
        return "Failed to load description";
    }
};

const HelpIcon = () => {
    const { pathname } = useLocation();
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedTitle, setSelectedTitle] = useState(null);
    const [videoDescription, setVideoDescription] = useState(null);
    const direction = i18n.dir();
    const dropdownRef = useRef(null);

    const handleOpen = async (videoUrl, title) => {
        const videoId = extractVideoId(videoUrl);
        if (videoId) {
            setSelectedVideo(videoId);
            setSelectedTitle(title);
            setOpen(true);

            // Fetch description from YouTube API
            const description = await fetchVideoDescription(videoId);
            setVideoDescription(description);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedVideo(null);
        setSelectedTitle(null);
        setVideoDescription(null);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            {helpVideos.global.concat(helpVideos[pathname] || []).length > 0 && (
                <div ref={dropdownRef} className={`help-icon ${direction}`} onClick={toggleDropdown}>
                    <HelpOutlineIcon fontSize="large" />
                    {dropdownOpen && (
                        <div className={`help-dropdown sexy-dropdown ${direction}`}>
                            {helpVideos.global.map((video, index) => (
                                <div key={`global-${index}`} className="help-item sexy-item" onClick={() => handleOpen(video.videoUrl, video.title)}>
                                    {video.title}
                                </div>
                            ))}
                            {helpVideos[pathname] && helpVideos[pathname].map((video, index) => (
                                <div key={index} className="help-item sexy-item" onClick={() => handleOpen(video.videoUrl, video.title)}>
                                    {video.title}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={open}>
                    <div className="modal-content">
                        {selectedVideo && (
                            <>
                                <iframe
                                    width="560"
                                    height="315"
                                    src={`https://www.youtube.com/embed/${selectedVideo}`}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                                <div className="video-title">{selectedTitle}</div>
                                <div className="video-description">{videoDescription}</div>
                            </>
                        )}
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export default HelpIcon;