import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import Select from 'react-select';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enGB from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import DocumentService from '../../Services/documents.service';
import UserService from '../../Services/user.service';
import './TransactionDocuments.css';
import backIcon from '../../assests/images/left-arrow.png';
import calendarIcon from '../../assests/images/calendar.png';
import EnumsService from '../../Services/enums.service';
import CustomDeleteConfirmationModal from '../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal'
import { ToastContainer, toast } from "react-toastify";
import NoDocumentsPdf from "../../assests/pdf/NoDocument.pdf";
import { useSignalR } from '../../Contexts/signalR';
import SignaturePad from 'react-signature-canvas';
import ClientDocumentCountService from '../../Services/ClientDocumentCount.service';
import PuffLoader from "react-spinners/PuffLoader";

import cancelIcon from "../../assests/images/cancel.png";
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Iframe from 'react-iframe';
import ClientService from '../../Services/client.service';
import addIcon from "../../assests/images/plusO.png";
import loadingAnimation from "../../assests/images/Animation.gif";
import Constants from '../../assests/Constants/constants';
import Modal from '@mui/material/Modal';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DraggableModal from '../DraggableModal/DraggableModal';
import { TextField } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import DownloadIconMui from '@mui/icons-material/Download';
import Switch from '@mui/material/Switch';
import { beautifyNumber } from '../../Utils/FormatNumber';
import DateInput from '../DateInput/DateInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CustomMonthDatePicker from '../Widgets/CustomDates/CustomMonthDatePicker/CustomMonthDatePicker';
import UpperPanelDetails from '../Widgets/UpperPanelDetails/UpperPanelDetails';
import TransactionDocumentDataGridComponent from './SubComponents/TransactionDocumentDataGridComponent';
import { calculateVatAmount } from '../../Utils/GetVatValue';
import { NavHistoryContext } from '../../Contexts/NavHistoryContext';
import AutocompleteSelect from '../Widgets/AutocompleteSelect/AutocompleteSelect';
registerLocale('en-GB', {
    ...enGB,
    localize: {
        ...enGB.localize,
        month: n => n + 1, // Display months as numbers starting from 1
    },
    formatLong: {
        ...enGB.formatLong,
        date: () => 'MM/yyyy', // Date format when the picker is not showing
    },
});

setDefaultLocale('en-GB'); // Set the default locale to use it

function TransactionDocuments() {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const [modalVisible, setModalVisible] = useState(false);
    const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);


    const [invoiceModalChoiceVisible, setInvoiceModalChoiceVisible] = useState(false);
    const [invoiceModalChoiceSpecificVisible, setInvoiceModalChoiceSpecificVisible] = useState(false);
    const [invoiceCreatorType, setInvoiceCreatorType] = useState('');
    const { goBack } = useContext(NavHistoryContext);

    const [signatureReferenceInvoiceModalVisible, setSignatureReferenceInvoiceModalVisible] = useState(false);
    const [documentDate, setDocumentDate] = useState(new Date());
    const [documentCost, setDocumentCost] = useState('');
    const [documentVat, setDocumentVat] = useState(100);
    const [documentReference, setDocumentReference] = useState('');
    const [sortCodeId, setCodeId] = useState('');
    const [accountCodeName, setAccountCodeName] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [accountCodeId, setAccountCodeId] = useState('');
    const [description, setDescription] = useState('');
    const [documents, setDocuments] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [okMessage, setOkMessage] = useState('');
    const [user, setUser] = useState(null);
    const [token, setToken] = useState('');
    const [data, setData] = useState([]);
    const [client, setClient] = useState(null);
    const [type, setType] = useState('');
    const [sortByTitleAsc, setSortByTitleAsc] = useState(true); // Sort by title ascending by default
    const [sortByDateAsc, setSortByDateAsc] = useState(false); // Sort by title ascending by default
    const [searchQuery, setSearchQuery] = useState('');
    const [currentSort, setCurrentSort] = useState("date");
    const [sortByAmountAsc, setSortByAmountAsc] = useState(true); // Sort by amount ascending by default
    const [sortByNewest, setSortByNewest] = useState(true);
    const modalCreateDocumentRef = useRef(); // Reference to the modal content
    const modalInvoiceCreateDocumentRef = useRef();
    const signatureReferenceInvoiceModalVisibleRef = useRef();
    const [sortCodeIdOptions, setSortCodeIdOptions] = useState([]);
    const [accountCardOptions, setAccountCardOptions] = useState([]);
    const [accountCardEditDownloadOptions, setAccountCardEditDownloadOptions] = useState([]);
    const [clientItems, setClientItems] = useState([]);
    const [creditAccountOptions, setCreditAccountOptions] = useState([]);
    const [debitAccountOptions, setDebitAccountOptions] = useState([]);
    const [creditAccountOption, setCreditAccountOption] = useState('');
    const [debitAccountOption, setDebitAccountOption] = useState('');
    const location = useLocation();
    const [editingRow, setEditingRow] = useState(null);
    const [editedItem, setEditedItem] = useState({});
    const [editedAddAccountCard, setEditedAddAccountCard] = useState(false);
    const [originalAccountCardId, setOriginalAccountCardId] = useState('');
    const [sortDate, setSortDate] = useState(() => {
        const storedDateString = localStorage.getItem('Date');
        return storedDateString ? new Date(storedDateString) : new Date();
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [itemIdToDelete, setItemIdToDelete] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'he');
    const direction = i18n.dir();

    const [startDate, setStartDate] = useState(() => {
        const storedDateString = localStorage.getItem('StartDate');
        return storedDateString ? new Date(storedDateString) : new Date();
    });; // Start date for range
    const [endDate, setEndDate] = useState(() => {
        const storedDateString = localStorage.getItem('EndDate');
        return storedDateString ? new Date(storedDateString) : new Date();
    });; // End date for range


    const [isRange, setIsRange] = useState(() => {
        const storedIsRange = localStorage.getItem('IsRange');
        return storedIsRange === "true";
    });;
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [downloadStartDate, setDownloadStartDate] = useState(null); // Start date for range
    const [downloadEndDate, setDownloadEndDate] = useState(null);
    const [downloadAmount, setDownloadAmount] = useState();
    const [downloadAccountCodeId, setDownloadAccountCodeId] = useState([]);
    const [isDateEnabled, setIsDateEnabled] = useState(false);
    const { documentUpdate } = useSignalR();
    const [stautsCheck, setStautsCheck] = useState(false);
    const [isClosedReportingMonth, setIsClosedReportingMonth] = useState(false);
    const [isFirstUserFetched, setIsFirstUserFetched] = useState(false);
    const [actionType, setActionType] = useState('delete');

    // invoice Details // 
    const sigPad = useRef({});
    const [invoiceDescription, setInvoiceDescription] = useState('');
    const [invoiceReference, setInvoiceReference] = useState('');
    const [invoiceClientName, setInvoiceClientName] = useState('');
    const [invoiceClientCaseId, setInvoiceClientCaseId] = useState('');
    const [invoiceClientLocation, setInvoiceClientLocation] = useState('');
    const [invoiceClientEmail, setInvoiceClientEmail] = useState('');
    const [invoiceClientPhoneNumber, setInvoiceClientPhoneNumber] = useState('');
    const [invoiceSignature, setInvoiceSignature] = useState('');
    const [invoiceAccountCodeId, setInvoiceAccountCodeId] = useState('');
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [invoiceDate, setInvoiceDate] = useState(new Date());
    const [addNewItem, setAddNewItem] = useState(false);
    const [addNewAccountCard, setAddNewAccountCard] = useState(false);
    const [addNewAccountCardDocument, setAddNewAccountCardDocument] = useState(false);
    const [addNewSignatureReference, setAddNewSignatureReference] = useState(false);
    const [newItems, setNewItems] = useState([]); // To store new items
    const [addItemFlags, setAddItemFlags] = useState([]); // Array of booleans to track add new item mode per index
    const [priceIncludingVAT, setPriceIncludingVAT] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'uploadNumber', direction: 'descending', type: 'number' });
    const [documentTypes, setDocumentTypes] = useState([]);

    const [autoFilling, setAutoFilling] = useState(true);
    const tableContainerRef = useRef();
    const customScrollbarRef = useRef();
    const [focused, setFocused] = useState(false);

    const InvoiceModalTypes = [
        { controller: "Incomes", endPoint: "GenerateIncomePdf", type: "", TransactionDocumentType: "", TransactionDocumentTitle: "", title: "generateInvoice" },
        { controller: "Incomes", endPoint: "GenerateRefundIncomePdf", type: "", TransactionDocumentType: "", TransactionDocumentTitle: "", title: "generateCreditNote" },
        { controller: "TransactionDocuments", endPoint: "GenerateTransactionDocumentPdf", type: "quotation", TransactionDocumentType: "Quotation", TransactionDocumentTitle: "הצעת מחיר", title: "generateQuotation" },
        { controller: "TransactionDocuments", endPoint: "GenerateTransactionDocumentPdf", type: "order", TransactionDocumentType: "Order", TransactionDocumentTitle: "הזמנה", title: "generateOrder" },
        { controller: "TransactionDocuments", endPoint: "GenerateTransactionDocumentPdf", type: "transaction", TransactionDocumentType: "Transaction", TransactionDocumentTitle: "חשבון עסקה", title: "generateTransactionInvoice" },
    ]
    const handleOpenSpecificInvoiceCreator = (type) => {
        navigate('/CreateInvoice', {
            state: { client, type }
        });
    };

    const handleOpenSpecificReceiptCreator = (type) => {
        navigate('/ReceiptTax', {
            state: { client, type }
        });
    };

    const handleCustomScroll = (e) => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollLeft = e.target.scrollLeft;
        }
    };

    const handleTableScroll = (e) => {
        if (customScrollbarRef.current) {
            customScrollbarRef.current.scrollLeft = e.target.scrollLeft;
        }
    };

    const synchronizeScroll = () => {
        if (tableContainerRef.current && customScrollbarRef.current) {
            customScrollbarRef.current.scrollLeft = tableContainerRef.current.scrollLeft;

        }
    };

    const TransactionDocumentsEnum = [
        { value: 1, label: "הצעת מחיר" },
        { value: 2, label: "הזמנה" },
        { value: 3, label: "חשבון עסקה" },
        { value: 4, label: "חשבונית מס" },
        { value: 5, label: "חשבונית מס זיכוי" },
        { value: 6, label: 'חשבונית מס קבלה' },
        { value: 7, label: 'קבלה' },
    ];

    const handleSignatureChange = () => {
        if (sigPad.current) {
            const signatureImage = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
            setInvoiceSignature(signatureImage);
            setClient(prevClient => ({
                ...prevClient,
                signature: signatureImage
            }));
        }
    };

    const clearSignature = () => {
        if (sigPad.current) {
            sigPad.current.clear();
            setInvoiceSignature("");
        }
    };
    // Add a new item
    const handleAddItem = () => {
        const newItem = { makat: '', name: '', quantity: 1, price: null, discount: 0 };
        setInvoiceItems([...invoiceItems, newItem]);
    };

    const handleCloseAddItem = (index) => {
        const updatedFlags = [...addItemFlags];
        updatedFlags[index] = false;
        setAddItemFlags(updatedFlags);
    };

    // Update an existing item
    const handleItemChange = (index, field, value) => {
        if (addItemFlags[index]) {
            setNewItems(prevNewItems => {
                const newItemsCopy = [...prevNewItems];
                newItemsCopy[index] = {
                    ...newItemsCopy[index],
                    [field]: value,
                    caseId: client.caseId // Assuming caseId is available here
                };
                return newItemsCopy;
            });
        }
        if (field === "discount" && (parseInt(value) > 100 || parseInt(value) < 0)) {
            return;
        }
        setInvoiceItems(prevItems => {
            const updatedItems = prevItems.map((item, idx) => idx === index ? { ...item, [field]: value } : item);
            return updatedItems;
        });
    };


    // Remove an item
    const handleRemoveItem = (index) => {
        const newItems = invoiceItems.filter((_, idx) => idx !== index);
        setInvoiceItems(newItems);
    };


    const CloseReportingMonth = async (status) => {
        setLoading(true);

        try {
            const response = await ClientService.UpdateReportingMonth(client.caseId, sortDate, status, token);
            if (response.ok) {
                toast.success({
                    text1: status ? t('MonthReportClosedSuccess') : t('MonthReportOpenedSuccess'),
                });
                setIsClosedReportingMonth(status)
            } else {
                const errorText = await response.text();
                console.error(t('MonthReportNotClosed'), errorText);
                toast.error({
                    text1: status ? `${t('MonthReportNotClosed')}: ${errorText}` : `${t('MonthReportNotOpened')}: ${errorText}`,
                });
            }
        } catch (error) {
            console.error(t('MonthReportNotClosed'), error);
            toast.error({
                text1: status ? `${t('MonthReportNotClosed')}: ${error}` : `${t('MonthReportNotOpened')}: ${error}`,
            });
        } finally {
            setLoading(false); // Stop loading regardless of the outcome
            setIsModalVisible(false)
        }
    };

    const handleDeleteClick = (itemId) => {
        setItemIdToDelete(itemId);
        setIsModalVisible(true);
    };

    const handleDoneClick = async (itemId) => {
        setLoading(true);
        try {
            const response = await ClientDocumentCountService.removeDocumentByTypeAndId(client.caseId, client.accountantId, type, itemId, token);
            if (response.ok) {
                await fetchDocumentsNotification(client, token)
                toast.success(t('DocumentProcessed'));
            } else {
                const errorText = await response.text();
                toast.error(t('DocumentNotProcessed', { errorText }));
            }
        } catch (error) {
            toast.error(t('DocumentNotProcessed', { errorText: error.message }));
        }
        setLoading(false);
    };

    const handleNotDoneClick = async (itemId) => {
        setLoading(true);
        try {
            const response = await ClientDocumentCountService.addDocumentByTypeAndId(client.caseId, client.accountantId, type, itemId, token)
            if (response.ok) {
                await fetchDocumentsNotification(client, token)
                toast.success(t('DocumentProcessed'));
            } else {
                const errorText = await response.text();
                toast.error(t('DocumentNotProcessed', { errorText }));
            }
        } catch (error) {
            toast.error(t('DocumentNotProcessed', { errorText: error.message }));
        }
        setLoading(false);
    };

    const handleStartDateChange = async (date) => {
        const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        localStorage.setItem('StartDate', newDate)
        setStartDate(newDate);
        if (isRange) {
            await fetchDataOnDateRangeChange(new Date(newDate), new Date(endDate));
        }
    };

    const handleEndDateChange = async (date) => {
        const endDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        localStorage.setItem('EndDate', endDate)
        setEndDate(endDate);
        if (isRange) {
            await fetchDataOnDateRangeChange(new Date(startDate), new Date(endDate));
        }
    };

    const handleDownloadStartDateChange = (date) => {
        const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        setDownloadStartDate(newDate);
    };

    const handleDownloadEndDateChange = async (date) => {
        const endDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        setDownloadEndDate(endDate);
    };


    async function fetchDataOnDateRangeChange(startDate, endDate) {

        setLoading(true)
        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = endDate.toISOString();

        try {
            const response = await DocumentService.GetDocumentbyDateRange(type, client.caseId, formattedStartDate, formattedEndDate, token)
            if (response.ok) {
                const data = await response.json(); // Parse the JSON in the response
                data.result.forEach(item => {
                    item.vatAmount = calculateVatAmount(item?.amount, item.vat, item.documentDate);
                    item.netAmount = item?.amount - item.vatAmount;
                    // item.vatAmount = item.vat === 16 ? (item?.amount / 1.16) * 0.17 : ((item?.amount / 1.17) * 0.17 * (item?.vat / 100));
                    // item.netAmount = item?.amount - item.vatAmount;

                });
                //
                setData(data.result);

                // toast.success(t('documentFetchSuccess'));
            } else {
                const errorText = await response.text();
                console.error(errorText);
                toast.error(t('documentFetchError', { errorText }));
            }
        } catch (error) {
            console.error(error);
            toast.error(t('documentFetchError', { errorText: error.message }));
        }

        setLoading(false)
    }

    const handleConfirm = async () => {
        if (actionType === 'delete') {
            await handleConfirmDelete();
        } else if (actionType === 'closeMonth') {
            await CloseReportingMonth(true);
        } else if (actionType === 'openMonth') {
            await CloseReportingMonth(false);
        }
    };

    const getConfirmMessage = () => {
        switch (actionType) {
            case 'closeMonth':
                return t('AreYouSureCloseMonth');
            case 'openMonth':
                return t('AreYouSureOpenMonth');
            default:
                return t('confirmDeleteMessage');

        }
    };

    const getTitleMessage = () => {
        switch (actionType) {

            case 'closeMonth':
                return t('ConfirmCloseMonth');
            case 'openMonth':
                return t('ConfirmOpenMonth');
            default:
                return t('confirmDeleteTitle');
        }
    };

    const handleConfirmDelete = async () => {
        // Perform delete operation
        setLoading(true);
        try {
            const response = await DocumentService.DeleteDocument(type, itemIdToDelete, token);
            if (response.ok) {
                if (isRange) {
                    await fetchDataOnDateRangeChange(new Date(startDate), new Date(endDate))
                }
                else
                    await fetchDocuments(new Date(sortDate));

                // Show success toast
                toast.success(t("documentDeleteSuccess"));
            } else {
                // Handle any errors or display an error toast
                const errorResponse = await response.json();
                console.error('Error deleting document:', errorResponse);

                // Show error toast
                toast.error(t("documentDeleteError"));
            }
        } catch (error) {
            console.error('Error deleting document:', error);

            // Show error toast
            toast.error(t("documentDeleteError"));
        }
        setIsModalVisible(false);
        setLoading(false);
    };


    const handleEdit = (item) => {
        setEditingRow(item.id);
        setEditedItem({ ...item }); // create a copy of the item for editing
        let statusChecked = type == "Incomes" ? client.documentIds.incomes.includes(item.id) : client.documentIds.expenses.includes(item.id);
        setStautsCheck(!statusChecked);
    };

    const handleChange = (e, field) => {
        if (field === 'sortCodeId') {
            setEditedItem({ ...editedItem, [field]: e.target.value });


            let fieldName = e.target.name;
            const match = fieldName.match(/\(([^)]*\d+[^)]*)\)/);
            let customVat = null;

            if (match) {
                customVat = parseInt(match[1], 10);
            }

            const hasGas = e.target.name.includes("דלק");
            if (hasGas || customVat !== null) {
                if (customVat === 66) {
                    setEditedItem({ ...editedItem, ['vat']: 66, [field]: e.target.value });
                } else {
                    setEditedItem({ ...editedItem, ['vat']: customVat, [field]: e.target.value });
                }
            } else {
                setEditedItem({ ...editedItem, ['vat']: 100, [field]: e.target.value });
            }
        } else {
            setEditedItem({ ...editedItem, [field]: e.target.value });
        }
    };


    const handleRadioChange = (e) => {
        if (e.target.value == 'done') {

            if (!editedItem.sortCodeId) {
                toast.error();
                return
            }
            setStautsCheck(true);
        }
        else if (e.target.value == 'notDone') {
            setStautsCheck(false);
        }
        setEditedItem({ ...editedItem, ["status"]: e.target.value });
    };
    const handleVatRadioChange = (e) => {
        setEditedItem({ ...editedItem, ["vat"]: parseFloat(e.target.value) });
    };
    const handleDateChange = (e, field) => {
        setEditedItem({ ...editedItem, [field]: e });
    };
    const setReportingMonthDate = async (selectedDate, field) => {
        if (!selectedDate) {
            return;
        }
        const newDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), 10));
        const response1 = await ClientService.CheckIfMonthClosed(client.caseId, newDate, token);
        if (response1.ok) {
            const isClosedReportingMonth = await response1.json();
            if (isClosedReportingMonth) {
                toast.info(t('MonthReportAlreadyClosedTryAgain'));
                return
            }
            else {
                setEditedItem({ ...editedItem, [field]: newDate });
            }
        }
    }

    const handleChangeNew = (selectedOption) => {
        if (selectedOption.value1 === "AddItem") {
            setCreditAccountOption("");
            setDebitAccountOption("");
            setAccountCodeName("");
            setAccountCodeId("");
            setCompanyId("");
            setAddNewAccountCardDocument(true);
        }
        else {
            setAccountCodeName(selectedOption.value);
            setCompanyId(selectedOption.accountCaseId);
            const accountCard = accountCardOptions.find(option => option.name === selectedOption.value);
            setAccountCodeId(accountCard.cardId);
            type === 'Incomes' ? setDebitAccountOption(accountCard.cardId) : setCreditAccountOption(accountCard.cardId)
        }
    };

    const handleInvoiceAccount = (selectedOption) => {

        if (selectedOption.value1 === 'AddItem') {
            setAddNewAccountCard(true);
        } else {
            setInvoiceClientName(selectedOption.value);
            const accountCard = accountCardOptions.find(option => option.name === selectedOption.value);
            setInvoiceAccountCodeId(accountCard.cardId);
        }
    };

    const handleInvoiceItem = (index, selectedOption) => {
        if (selectedOption.value === 'add-item') {
            const updatedFlags = [...addItemFlags];
            updatedFlags[index] = true;
            setAddItemFlags(updatedFlags);
        } else {
            const accountCard = clientItems.find(option => option.makat === selectedOption.value);
            handleItemChange(index, 'makat', accountCard.makat);
            handleItemChange(index, 'name', accountCard.name);
            // Reset flag in case it was set
            const updatedFlags = [...addItemFlags];
            updatedFlags[index] = false;
            setAddItemFlags(updatedFlags);
        }
    };

    const handleUpdate = async (itemId) => {
        setLoading(true);
        try {
            delete editedItem.documentUrl;
            const response = await DocumentService.UpdateDocument(type, token, editedItem);
            if (editedItem.status == 'done') {
                await handleDoneClick(editedItem.id);
                handleSortChange('id', 'newest', 'descending');
            }
            else if (editedItem.status == 'notDone') {
                await handleNotDoneClick(editedItem.id)
            }

            if (editedAddAccountCard) {
                await handleAccountCardSave(editedItem.accountCodeId, editedItem.accountCodeName, editedItem.accountCaseId);
                await fetchEnums(token, client)
            }

            if (response.ok) {
                if (isRange) {
                    await fetchDataOnDateRangeChange(new Date(startDate), new Date(endDate))
                }
                else
                    await fetchDocuments(new Date(sortDate));
                // Show success toast
                toast.success(t("documentUpdateSuccess"));
            } else {
                const errorText = await response.text();
                // Handle error

                // Show error toast
                toast.error(t("documentUpdateError"));
            }
        } catch (error) {
            // Handle error

            // Show error toast
            toast.error(t("documentUpdateError"));
        }
        setEditingRow(null);
        setEditedItem({});
        setEditedAddAccountCard(false);
        setLoading(false);
        // Refresh or update your data list
    };


    const handleCancel = () => {
        setEditingRow(null);
        setEditedItem({});
        setEditedAddAccountCard(false);
    };


    const handleDocumentUpload = (event) => {
        setDocuments(Array.from(event.target.files));
    };

    const formatDate = dateString => {
        const date = new Date(dateString);
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };


    const closeCreateModal = (e) => {
        // Check if the clicked element is the same as the event handler's element
        if (modalCreateDocumentRef.current && !modalCreateDocumentRef.current.contains(e.target)) {
            handleClose();
        }
    }

    const closeCreateInvoiceModal = (e) => {
        // Check if the clicked element is the same as the event handler's element
        if (modalInvoiceCreateDocumentRef.current && !modalInvoiceCreateDocumentRef.current.contains(e.target)) {

            handleInvoiceClose();
        }
    }
    const closeSignatureInvoiceModal = (e) => {
        // Check if the clicked element is the same as the event handler's element
        if (signatureReferenceInvoiceModalVisibleRef.current && !signatureReferenceInvoiceModalVisibleRef.current.contains(e.target)) {
            handleSignatureReferenceClose();
        }
    }
    const handleClose = async () => {
        setModalVisible(false);
        setErrorMessage('');
        setOkMessage('');
        setDocumentCost("");
        setDocumentReference("")
        setCodeId("")
        setAccountCodeName("")
        setCreditAccountOption("")
        setDebitAccountOption("")
        setDescription("")
    }

    const handleInvoiceClose = async () => {
        setInvoiceDescription('');
        setInvoiceClientName('');
        setInvoiceClientCaseId('');
        setInvoiceClientLocation('');
        setInvoiceClientEmail('');
        setInvoiceAccountCodeId('');
        setInvoiceClientPhoneNumber('');
        setInvoiceItems([]);
        setInvoiceDate(new Date());
        setInvoiceSignature('');
        setInvoiceReference('');
        setAddNewAccountCard('');
        setPriceIncludingVAT(false);
        setInvoiceModalVisible(false);
        setInvoiceModalChoiceVisible(false);
        setInvoiceModalChoiceSpecificVisible(false);
    }

    const [clientSignatureNeeded, setClientSignatureNeeded] = useState(false);

    const handleSignatureReferenceClose = async () => {
        setInvoiceSignature('');
        setInvoiceReference('');
        setSignatureReferenceInvoiceModalVisible(false);
    }
    // new sign
    const handleSignatureReferenceSave = async () => {
        setSignatureReferenceInvoiceModalVisible(false);
        const updatedClient = {
            ...client,
            signature: invoiceSignature
        };
        setClient(updatedClient);


        setAddNewSignatureReference(true);
        setInvoiceModalVisible(true);
    }
    // const allFieldsFilled = sortCodeId && documentReference && documentCost && documentDate && creditAccountOption && debitAccountOption && accountCodeName && accountCodeId && description && photo
    const allFieldsFilled = true;


    const signatureReferenceFilled = (invoiceSignature && invoiceReference && clientSignatureNeeded) || (invoiceReference && !clientSignatureNeeded);


    const allClientItemFieldsFilled = invoiceClientName && invoiceClientCaseId && invoiceItems && invoiceItems.length > 0 && invoiceItems.every(item => {
        const hasRequiredFields = item.name && item.makat && item.price && item.discount >= 0; // Check if name, makat, and price are provided
        item.quantity = item.quantity || 1; // Default quantity to 1 if not provided
        item.discount = item.discount || 0; // Default discount to 1 if not provided
        return hasRequiredFields;
    });

    const handleSave = async () => {
        if (documents && documents.length > 0 && autoFilling) {
            handleOCRSave();
        } else {
            handleNormalSave();
        }
    };


    const handleNormalSave = async () => {
        if (!allFieldsFilled) {
            setErrorMessage(t('errorFieldsNotFilled'));
            return; // Stop execution if fields are not filled
        }
        setLoading(true);

        const formData = new FormData();
        formData.append('CaseId', client.caseId);
        formData.append('AccountantId', user.accountantId);
        formData.append('SortCodeId', sortCodeId)
        formData.append('Reference', documentReference)
        formData.append('Amount', documentCost);
        formData.append('DocumentDate', new Date(documentDate).toISOString());
        formData.append('ReportingMonthDate', new Date(sortDate).toISOString());
        formData.append('CreditAccount', type !== 'Incomes' && !creditAccountOption ? accountCodeId : creditAccountOption);
        formData.append('DebitAccount', type === 'Incomes' && !debitAccountOption ? accountCodeId : debitAccountOption);
        formData.append('AccountCodeName', accountCodeName);
        formData.append('AccountCodeId', accountCodeId);
        formData.append('AccountCaseId', companyId);
        documents.forEach(document => {
            formData.append("documents", document, document.name);
        });
        formData.append('DocumentType', type);
        formData.append('DocumentFileType', type);
        formData.append('Description', description)
        formData.append('VAT', documentVat)

        try {
            const response = await DocumentService.UploadDocument(type, formData, token);
            if (response.ok) {
                if (addNewAccountCardDocument) {
                    await handleAccountCardSave(accountCodeId, accountCodeName);
                    await fetchEnums(token, client);
                }
                toast.success(t('documentUploadSuccess'));
            } else {
                const errorText = await response.text();
                toast.error(t('documentUploadError', { errorText }));
            }
        } catch (error) {
            toast.error(t('documentUploadError', { errorText: 'Error uploading document' }));
        }
        setModalVisible(false);
        setDocumentCost("");
        setDocumentReference("");
        setCodeId("");
        setAccountCodeName("");
        setAccountCodeId("");
        setCreditAccountOption("");
        setDebitAccountOption("");
        setDescription("");
        setDocuments([])
        setAddNewAccountCardDocument(false);
        await fetchDocuments(new Date(sortDate));
        await fetchDocumentsNotification(client, token)
        setLoading(false);
    };



    const handleOCRSave = async () => {
        if (!allFieldsFilled) {
            setErrorMessage(t('errorFieldsNotFilled'));
            return; // Stop execution if fields are not filled
        }
        setLoading(true);

        const formData = new FormData();
        formData.append('CaseId', client.caseId);
        formData.append('AccountantId', user.accountantId);
        formData.append('ReportingMonthDate', new Date(sortDate).toISOString());
        formData.append('DocumentFileType', type);
        formData.append('VAT', documentVat)
        documents.forEach(document => {
            formData.append("documents", document, document.name);
        });


        try {
            const response = await DocumentService.UploadOCRDocument(type, formData, token);
            if (response.ok) {
                toast.success(t('documentUploadSuccess'));
            } else {
                const errorText = await response.text();
                toast.error(t('documentUploadError', { errorText }));
            }
        } catch (error) {
            toast.error(t('documentUploadError', { errorText: 'Error uploading document' }));
        }
        setModalVisible(false);
        setDocuments([])
        await fetchDocuments(new Date(sortDate));
        await fetchDocumentsNotification(client, token)
        await fetchEnums(token, client);
        setLoading(false);
    };

    const updateLocationState = async (newClientData) => {

        const updatedClientData = {
            ...location.state.client,
            ...newClientData
        };

        // Navigate with the updated client data and replace the current state
        setIsFirstUserFetched(false);
        navigate(location.pathname, {
            replace: true,
            state: { client: updatedClientData, type, sortDate }
        });
    };

    const handleSaveSignature = async () => {
        // const response1 = await ClientService.UpdateClient(token, { invoiceSignature });
        // if (!response1.ok) {
        //     throw new Error(`HTTP error! status: ${response1.status}`);
        // }

        const Client = await ClientService.GetClient(token, client.caseId);
        updateLocationState(Client);

    }
    const handleAccountCardSave = async (AccountCodeId, AccountCodeName) => {
        let response2;
        if (type === 'Incomes') {
            const response2 = await EnumsService.AddAccountCard(client.caseId, AccountCodeId, AccountCodeName, companyId, "לקוחות", token);
        } else {
            const response2 = await EnumsService.AddAccountCard(client.caseId, AccountCodeId, AccountCodeName, companyId, "ספקים", token);
        }
        if (!response2.ok) {
            throw new Error(`HTTP error! status: ${response2.status}`);
        }
    };

    const saveNewItemsToDatabase = async () => {
        // Filter out undefined items
        const filteredItems = newItems.filter(item => item !== undefined);

        // Assuming EnumsService.AddClientItems expects an array of items
        const response = await EnumsService.AddClientItems(filteredItems, token);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Reset newItems after saving
        setNewItems([]);
        // Optionally reset addItemFlags if necessary
        setAddItemFlags([]);

        return response;
    };
    //Now
    const handleCreateInvoice = async () => {
        // if (!allFieldsFilled) {
        //     setErrorMessage(t('errorFieldsNotFilled'));
        //     return; // Stop execution if fields are not filled
        // }
        setLoading(true);

        const invoice = {
            CaseId: client.caseId,
            AccountantId: client.accountantId,
            Name: client.name,
            Description: invoiceDescription,
            Reference: invoiceReference,
            ClientName: invoiceClientName,
            ClientCaseId: invoiceClientCaseId,
            PhoneNumber: client.phoneNumber,
            Email: client.email,
            Website: client.website,
            Location: client.location,
            ClientLocation: invoiceClientLocation,
            ClientEmail: invoiceClientEmail,
            ClientPhoneNumber: invoiceClientPhoneNumber,
            Date: invoiceDate,
            Signature: invoiceSignature,
            AccountCodeId: invoiceAccountCodeId,
            Items: invoiceItems,
            PriceIncludingVAT: priceIncludingVAT
        }
        // create inv

        try {
            // type -> invoiceCreatorType
            if (invoiceCreatorType === 'Quotation') {
                let modalTypeDict = InvoiceModalTypes[2];
                await DocumentService.CreateNewInvoice(modalTypeDict.controller, modalTypeDict.endPoint, invoice, modalTypeDict.type, modalTypeDict.TransactionDocumentTitle, modalTypeDict.TransactionDocumentType, token);

                const updatedClient = {
                    ...client,
                    quotationInvoicesNumbers: client.quotationInvoicesNumbers + 1
                };
                setClient(updatedClient);
            }
            if (invoiceCreatorType === 'Order') {
                let modalTypeDict = InvoiceModalTypes[3];
                await DocumentService.CreateNewInvoice(modalTypeDict.controller, modalTypeDict.endPoint, invoice, modalTypeDict.type, modalTypeDict.TransactionDocumentTitle, modalTypeDict.TransactionDocumentType, token);

                const updatedClient = {
                    ...client,
                    orderInvoicesNumbers: client.orderInvoicesNumbers + 1
                };
                setClient(updatedClient);
            }
            if (invoiceCreatorType === 'Transaction') {
                let modalTypeDict = InvoiceModalTypes[4];
                await DocumentService.CreateNewInvoice(modalTypeDict.controller, modalTypeDict.endPoint, invoice, modalTypeDict.type, modalTypeDict.TransactionDocumentTitle, modalTypeDict.TransactionDocumentType, token);

                const updatedClient = {
                    ...client,
                    invoicesNumbers: client.invoicesNumbers + 1
                };
                setClient(updatedClient);
            }
            toast.success(t('InvoiceCreated'));
        } catch (error) {
            console.error(error);
            toast.error(t('InvoiceNotCreated'));
        }
        await fetchEnums(token, client);
        if (!isRange)
            fetchDocuments(sortDate);
        else
            fetchDataOnDateRangeChange(startDate, endDate)
        handleInvoiceClose();
        const Client = await ClientService.GetClient(token, client.caseId);
        updateLocationState(Client);
        setLoading(false);
    };

    const fetchEnums = async (token, client) => {
        setLoading(true);
        try {
            // Fetching sortCodeIdOptions
            const codeIdResponse = await EnumsService.GetSortCodesByAccountantId(client.accountantId, token);
            if (codeIdResponse.ok) {
                const codeIdData = await codeIdResponse.json();
                setSortCodeIdOptions(codeIdData); // Assuming codeIdData is already in the correct format
            }


            // Fetching creditAccountOptions and debitAccountOptions
            const accountsResponse = await EnumsService.GetAccountCardsByCaseId(client.caseId, token);
            if (accountsResponse.ok) {
                const accountsData = await accountsResponse.json();
                setCreditAccountOptions(accountsData);
                setDebitAccountOptions(accountsData); // Assuming both options are the same
                setAccountCardEditDownloadOptions(accountsData);
                const accountCardOptions = [{ name: t('AddAccountCard'), value1: 'AddItem' }, ...accountsData];
                setAccountCardOptions(accountCardOptions);
            }
            const ClientItemsResponse = await EnumsService.GetClientItemsByCaseId(client.caseId, token);
            if (ClientItemsResponse.ok) {
                const ClientItemsData = await ClientItemsResponse.json();
                ClientItemsData.unshift({ makat: 'add-item', name: t('AddItem') });
                setClientItems(ClientItemsData);
            }

            setIsFirstUserFetched(true);
            // toast.success(t('enumFetchSuccess'));
        } catch (error) {
            console.error("Error fetching enum data:", error);
            toast.error(t('enumFetchError', { errorText: error.message }));
            // Handle the error appropriately
        }
        setLoading(false);
    };


    const fetchDocumentsNotification = async (client, token) => {
        const documentCounts = await ClientDocumentCountService.getClientDocumentCountByCaseId(client.caseId, token);

        // Enrich clients with their total document counts
        const enrichedClient = {
            ...client,
            totalCounts: documentCounts.totalCounts || 0, // Default to 0 if no matching count is found
            documentCounts: documentCounts.documentCounts || {},
            documentIds: documentCounts.documentIds || {},
        };
        setInvoiceSignature(enrichedClient.signature);
        setClient(enrichedClient);
    }
    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {

                const currentUser = await UserService.getCurrentUser();
                const currentToken = await UserService.getCurrentToken();
                if (currentUser && currentToken) {
                    setUser(currentUser);
                    setToken(currentToken);

                    if (location.state && location.state.client && location.state.type) {

                        setType(location.state.type);
                        await fetchDocumentsNotification(location.state.client, currentToken);
                        await fetchEnums(currentToken, location.state.client);
                    }
                    // Fetch financial data for the current date on initial load only
                }
                else {
                    navigate("/login");
                }


            } catch (error) {
                // Handle error - maybe navigate to login or show a message
            }

        };


        fetchUserData();
        setLoading(false);
    }, [location.state.client]);

    useEffect(() => {
        // To handle multiple updates efficiently, consider iterating over documentUpdate keys
        Object.keys(documentUpdate).forEach(updateCaseId => {
            const update = documentUpdate[updateCaseId];
            // Update clients with new totalCounts for the matching caseId

            if (client?.caseId === updateCaseId) { // Check if the client's caseId matches the update's caseId
                const updatedClients = {
                    ...client,
                    totalCounts: update.totalCounts, // Update totalCounts from documentUpdate
                    documentIds: update.documentsIds,
                    documentsCount: update.documentsCount
                };
                setClient(updatedClients); // Update the state with the modified lzist
            }

        });
    }, [documentUpdate]);

    const fetchDocuments = async (selectedDate) => {
        setLoading(true);
        const newDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), 10));
        try {
            const response = await DocumentService.GetDocumentbyDate(type, client.caseId, newDate, token)
            const response1 = await ClientService.CheckIfMonthClosed(client.caseId, newDate, token);
            if (response.ok && response1.ok) {
                const data = await response.json(); // Parse the JSON in the response
                const isClosedReportingMonth = await response1.json();
                setIsClosedReportingMonth(isClosedReportingMonth);
                data.forEach(item => {
                    item.vatAmount = calculateVatAmount(item?.amount, item.vat, item.documentDate);
                    item.netAmount = item?.amount - item.vatAmount;
                    // item.vatAmount = ((item?.amount / 1.17) * 0.17 * (item?.vat / 100));
                    // item.netAmount = item?.amount - ((item?.amount / 1.17) * 0.17 * (item?.vat / 100));
                });
                setData(data);
                // toast.success(t('documentFetchSuccess'));
            } else {
                const errorText = await response.text();
                console.error(errorText);
                toast.error(t('documentFetchError', { errorText }));
            }
        } catch (error) {
            console.error(error);
            toast.error(t('documentFetchError', { errorText: error.message }));
        }
        setLoading(false);
    };



    const handleDownloadDocuments = async () => {
        setLoading(true);
        try {
            const dto = {
                CaseId: client.caseId,
                Date: downloadStartDate,
                EndDate: downloadEndDate,
                AccountCodeIds: downloadAccountCodeId,
                Amount: downloadAmount
            };

            const blob = await DocumentService.DownloadCustomDocuments(type, token, dto); // Assuming you have token state or prop for authorization
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'documents.zip'); // or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            toast.success(t('documentDownloadSuccess'));
        } catch (error) {
            console.error('Error downloading documents:', error);
            toast.error(t('documentDownloadError', { errorText: error.message }));
        }
        setDownloadStartDate(null);
        setDownloadEndDate(null);
        setDownloadAmount();
        setDownloadAccountCodeId([]);
        setShowDownloadModal(false)
        setLoading(false);
    };


    useEffect(() => {
        if (isFirstUserFetched) {
            if (!isRange)
                fetchDocuments(sortDate);
            else
                fetchDataOnDateRangeChange(startDate, endDate)
        }
    }, [isFirstUserFetched]);

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button className={`document-custom-input ${isRange ? "document-custom-input-range" : ""}`} onClick={onClick} ref={ref}>
            {value}
        </button>
    ));


    const CustomInput1 = React.forwardRef(({ value, onClick }, ref) => (
        <button className="document-custom-input1" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const CustomInput2 = React.forwardRef(({ value, onClick }, ref) => (
        <button className="edit-custom-input" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const CustomDownloadInput = React.forwardRef(({ value, placeholderText, onClick }, ref) => {
        return (
            <button className="download-document-custom-input" onClick={onClick} ref={ref}>
                {value || placeholderText}
            </button>
        );
    });

    // const filteredAndSortedData = useMemo(() => {
    // const filteredData = data.filter((item) => {
    //     const query = searchQuery.toLowerCase();
    //     const amountString = item?.amount?.toString(); // Safely access item.amount
    //     return (
    //         item?.accountCodeName?.toLowerCase()?.includes(query) || // Safely access item.title
    //         amountString?.includes(query) ||
    //         formatDate(item?.documentDate)?.includes(query) ||
    //         item?.reference?.includes(query) ||
    //         item?.sortCodeId?.includes(query) ||
    //         item?.description?.includes(query) ||
    //         item?.uploadNumber?.toString()?.includes(query)
    //     );
    // });

    //     switch (currentSort) {
    //         case 'title':
    //             return filteredData.sort((a, b) =>
    //                 sortByTitleAsc ? a?.accountCodeName?.localeCompare(b?.accountCodeName) : b?.accountCodeName?.localeCompare(a?.accountCodeName)
    //             );
    //         case 'date':
    //             return filteredData.sort((a, b) => {
    //                 const dateA = new Date(a.documentDate);
    //                 const dateB = new Date(b.documentDate);
    //                 return sortByDateAsc ? dateA - dateB : dateB - dateA;
    //             });
    //         case 'amount':
    //             return filteredData.sort((a, b) => {
    //                 const amountA = parseFloat(a.amount);
    //                 const amountB = parseFloat(b.amount);
    //                 return sortByAmountAsc ? amountA - amountB : amountB - amountA;
    //             });
    // case 'newest': {
    //     const highlightedIds = type === 'Incomes' ? client.documentIds.incomes : client.documentIds.expenses;
    //     return filteredData.sort((a, b) => {
    //         const aIsHighlighted = highlightedIds.includes(a.id);
    //         const bIsHighlighted = highlightedIds.includes(b.id);

    //         if (aIsHighlighted && !bIsHighlighted) {
    //             return sortByNewest ? -1 : 1;
    //         } else if (!aIsHighlighted && bIsHighlighted) {
    //             return sortByNewest ? 1 : -1;
    //         } else {
    //             // Both are highlighted or neither are, sort by document date
    //             const dateA = new Date(a.documentDate);
    //             const dateB = new Date(b.documentDate);
    //             return sortByNewest ? dateB - dateA : dateA - dateB;
    //         }
    //     });
    // }


    //         default:
    //             return filteredData; // No sorting or default sorting logic
    //     }
    // }, [data, searchQuery, sortByTitleAsc, sortByDateAsc, sortByAmountAsc, sortByNewest, currentSort]);


    const handleSortCodeChange = (selectedOption) => {
        // Set the sortCodeId to the value from the selected option
        setCodeId(selectedOption.value);
        // Check for a number within parentheses
        const match = selectedOption.label.match(/\((\d+)\)/);
        let customVat = null;

        if (match) {
            customVat = parseInt(match[1], 10);
        }

        if (customVat != null) {
            if (customVat == '66') {
                let customVatFixed = 2 / 3;
                setDocumentVat(customVatFixed);
            } else {
                setDocumentVat(customVat);
            }
        } else {
            setDocumentVat(100);
        }
    };


    const handleSortChange = (key, type, Direction = '') => {
        let direction = Direction || 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction, type });
    };

    const filteredAndSortedData = useMemo(() => {
        if (data.length < 0) {
            return;
        }
        const sortedData = data.filter((item) => {
            const query = searchQuery.toLowerCase();
            const amountString = item?.amount?.toString(); // Safely access item.amount
            if (
                documentTypes.length > 0 &&
                !documentTypes.includes(item.transactionDocumentType)
            ) {
                return false;
            }

            return (
                item?.accountCodeName?.toLowerCase()?.includes(query) || // Safely access item.title
                amountString?.includes(query) ||
                formatDate(item?.documentDate)?.includes(query) ||
                item?.reference?.includes(query) ||
                item?.sortCodeId?.includes(query) ||
                item?.description?.includes(query) ||
                item?.uploadNumber?.toString()?.includes(query)
            );
        });
        if (sortConfig.key) {
            sortedData.sort((a, b) => {
                if (sortConfig.type === 'newest') {
                    const highlightedIds = type === 'Incomes' ? client.documentIds.incomes : client.documentIds.expenses;
                    const aIsHighlighted = highlightedIds.includes(a.id);
                    const bIsHighlighted = highlightedIds.includes(b.id);

                    if (sortConfig.direction === 'ascending') {
                        // Ascending: Non-highlighted (older) items first
                        if (!aIsHighlighted && bIsHighlighted) {
                            return -1; // 'a' should come before 'b' (a is older, hence "lower")
                        } else if (aIsHighlighted && !bIsHighlighted) {
                            return 1;  // 'b' should come before 'a' (b is older, hence "lower")
                        }
                    } else {
                        // Descending: Highlighted (newer) items first
                        if (aIsHighlighted && !bIsHighlighted) {
                            return -1; // 'a' should come before 'b' (a is newer, hence "higher")
                        } else if (!aIsHighlighted && bIsHighlighted) {
                            return 1;  // 'b' should come before 'a' (b is newer, hence "higher")
                        }
                    }
                } else {
                    const aValue = a[sortConfig.key] || ""; // Default to empty string if null or undefined
                    const bValue = b[sortConfig.key] || ""; // Default to empty string if null or undefined
                    if (sortConfig.type === 'number') {
                        return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
                    } else if (sortConfig.type === 'date') {
                        return sortConfig.direction === 'ascending' ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue);
                    } else { // default to string comparison
                        return sortConfig.direction === 'ascending' ? aValue?.localeCompare(bValue) : bValue?.localeCompare(aValue);
                    }
                }
            });
        }

        return sortedData;
    }, [data, searchQuery, sortConfig, documentTypes, type, client]);

    const handleGoBack = () => {
        // Navigate back to the previous page using React Router
        if (client) {
            // navigate('/client-details', { state: { client } });
            goBack()
        }
    };

    const handleAccountChange = (selectedOption, field) => {
        if (field === 'debitAccount' || field === 'creditAccount') {
            const name = accountCardEditDownloadOptions.find(option => option.cardId === selectedOption.value)?.name;
            if (selectedOption.value < 0) {

                setOriginalAccountCardId(editedItem.cardId)
            }
            type === 'Incomes' ? setEditedItem({ ...editedItem, accountCodeName: name, accountCodeId: selectedOption.value, debitAccount: selectedOption.value, accountCaseId: selectedOption.accountCaseId }) : setEditedItem({ ...editedItem, accountCodeName: name, accountCodeId: selectedOption.value, creditAccount: selectedOption.value, accountCaseId: selectedOption.accountCaseId });
        } else if (field === 'accountCodeName') {
            if (selectedOption.value1 === 'AddItem') {
                setEditedAddAccountCard(true);
                setEditedItem({ ...editedItem, accountCodeName: '', accountCodeId: '', accountCaseId: '' })
            }
            else {
                const cardId = accountCardEditDownloadOptions.find(option => option.name === selectedOption.value)?.cardId;
                type === 'Incomes' ? setEditedItem({ ...editedItem, accountCodeName: selectedOption.value, accountCodeId: cardId, debitAccount: cardId, accountCaseId: selectedOption.accountCaseId }) : setEditedItem({ ...editedItem, accountCodeName: selectedOption.value, accountCodeId: cardId, creditAccount: cardId, accountCaseId: selectedOption.accountCaseId });
            }
        }
    };

    const handleSetSortDate = (date) => {
        const newDate = new Date(date.getFullYear(), date.getMonth(), 10);
        localStorage.setItem('Date', newDate);
        setSortDate(newDate);
        fetchDocuments(newDate);
    }
    const closeDownloadModal = () => {
        setShowDownloadModal(false);
    };

    const handleRangeChange = async () => {
        !isRange ? await fetchDataOnDateRangeChange(new Date(startDate), new Date(endDate)) : await fetchDocuments(new Date(sortDate));
        setIsRange(!isRange);
        localStorage.setItem('IsRange', !isRange);
    }

    const handleOpenInvoiceCreator = () => {
        setInvoiceModalChoiceVisible(true);
    };



    const handleCloseAddNewAccountCardDocument = () => {
        setAccountCodeId("");
        setAccountCodeName("");
        setCompanyId("");
        setAddNewAccountCardDocument(false);
    };

    const handleCloseEditAddNewAccountCardDocument = () => {
        setEditedItem({ ...editedItem, accountCodeName: '', accountCodeId: '' })
        setEditedAddAccountCard(false);
    };

    const handleEditAddAccountChange = (selectedOption, field) => {

        if (field === 'AccountCodeId') {
            type === 'Incomes' ? setEditedItem({ ...editedItem, accountCodeId: selectedOption, debitAccount: selectedOption }) : setEditedItem({ ...editedItem, accountCodeId: selectedOption, creditAccount: selectedOption });
        }
        else if (field === 'AccountCodeName') {
            setEditedItem({ ...editedItem, accountCodeName: selectedOption })
        }
        else {
            setEditedItem({ ...editedItem, accountCaseId: selectedOption })

        }
    };

    const handlePriceIncludingVATChange = (event) => {
        setPriceIncludingVAT(event.target.value === 'true');
    };


    //table functions
    const headerStyle = {
        backgroundColor: '#f7f7f7',
        color: 'black',
        fontWeight: 'bold'
    }

    const [openedIframeUrl, setOpenedIframeUrl] = useState('');
    const [bigIframeModalOpen, setBigIframeModalOpen] = useState(false);
    const openPdfInNewTab = (pdfUrl) => {
        if (pdfUrl) {
            setOpenedIframeUrl(pdfUrl ? pdfUrl : NoDocumentsPdf);
            setBigIframeModalOpen(true);
        }
    };

    const handleCloseIframeModal = () => {
        setBigIframeModalOpen(false);
        setOpenedIframeUrl('');
    };


    return (
        <>
            {/* <Modal
                open={bigIframeModalOpen}
                onClose={handleClose}
                aria-labelledby="pdf-modal-title"
                aria-describedby="pdf-modal-description"

            >
                <div className="edit-modal">
                    <Box className="iframe-modal-style">
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseIframeModal}
                            sx={{
                                position: 'absolute',
                                right: -50,
                                top: 8,
                                color: 'black',
                                backgroundColor: 'white',
                                '&:hover': {
                                    backgroundColor: 'gray', // Change this to the desired hover color
                                },
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Iframe
                            src={openedIframeUrl}
                            width="100%"
                            height="100%"
                            style={{ border: 'none' }}
                            title="PDF Viewer"
                        />
                    </Box>
                </div>
            </Modal> */}

            <DraggableModal
                bigIframeModalOpen={bigIframeModalOpen}
                openedIframeUrl={openedIframeUrl}
                handleCloseIframeModal={handleCloseIframeModal}
            />
            <HamburgerMenu client={client} />

            <div className="template-page-withoutHeight">
                <div className="template-container">
                    <UpperPanelDetails client={client} />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <div className='backIconBackground' style={{ alignSelf: i18n.language === 'en' ? 'flex-start' : 'flex-end' }} onClick={handleGoBack}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M20 21.1667L14.8267 15.9933L20 10.82C20.52 10.3 20.52 9.45999 20 8.93999C19.48 8.41999 18.64 8.41999 18.12 8.93999L12 15.06C11.48 15.58 11.48 16.42 12 16.94L18.12 23.06C18.64 23.58 19.48 23.58 20 23.06C20.5067 22.54 20.52 21.6867 20 21.1667Z" fill="black" fill-opacity="0.9" />
                            </svg>
                        </div>
                        <div className={`main-title ${direction}`}>{t(type)}</div>
                    </div>
                    <div className={`title-back-container ${direction}`}>
                        {<div className="create-selector " onClick={handleOpenInvoiceCreator}>
                            {t('create')}
                        </div>}

                    </div>

                    <div className={`searchFilter-bar__container ${direction}`} >

                        <TextField
                            fullWidth
                            placeholder={t('searchClient')}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="search-bar"
                            style={{ height: '55px' }}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                sx: {
                                    height: '55px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                    },
                                },
                                ...(direction === 'ltr'
                                    ? {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" style={{ marginRight: '10px' }}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }
                                    : {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton edge="start" style={{ marginLeft: '10px' }}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }),
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                        <AutocompleteSelect
                            options={TransactionDocumentsEnum}
                            selectedValues={TransactionDocumentsEnum.filter(option => documentTypes.includes(option.value))}
                            onChange={(event, newValues) => {
                                const selected = newValues?.map(item => item.value) || [];
                                setDocumentTypes(selected);
                            }}
                            label={t('ChooseDocumentType')}
                            placeholder={t('ChooseDocumentType')}
                            isMultiple={true}
                        />
                        {isRange ? (
                            <>
                                <CustomMonthDatePicker
                                    onDateChange={(date) => handleStartDateChange(date)}
                                    date={dayjs(startDate)}
                                    label={t("StartDate")}
                                />

                                <FormControlLabel
                                    value={isRange}
                                    control={<Switch
                                        className="custom-input-box-sizing-toggle"
                                        color="primary"
                                        checked={isRange}
                                        onChange={handleRangeChange}
                                    />}
                                    label={t("DateRange")}
                                    labelPlacement="bottom"
                                />

                                <CustomMonthDatePicker
                                    onDateChange={(date) => handleEndDateChange(date)}
                                    date={dayjs(endDate)}
                                    label={t("EndDate")}
                                />
                            </>
                        ) : (
                            <>
                                <CustomMonthDatePicker
                                    onDateChange={(date) => handleSetSortDate(date)}
                                    date={dayjs(sortDate)}
                                    label={t("date")}
                                />

                                <FormControlLabel
                                    value={!isRange}
                                    control={<Switch
                                        className="custom-input-box-sizing-toggle"
                                        color="primary"
                                        checked={isRange}
                                        onChange={handleRangeChange}

                                    />}
                                    label={t("DateRange")}
                                    labelPlacement="bottom"
                                />
                            </>
                        )}
                    </div>

                    <TransactionDocumentDataGridComponent
                        data={filteredAndSortedData}
                        openPdfInNewTab={openPdfInNewTab}
                        direction={direction}
                    />

                   
                </div>

                <CustomDeleteConfirmationModal
                    isVisible={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                    onDelete={handleConfirm}
                    confirmMessage={getConfirmMessage()}
                    titleMessage={getTitleMessage()}
                />
                <ToastContainer />
                {loading && (
                    <div className="loader-container">
                        <PuffLoader
                            size={100}        // Larger size for better visibility
                            loading={loading}
                        />
                    </div>
                )}


                {invoiceModalChoiceVisible && (
                    <div className='create-modal-overlay' onClick={closeCreateInvoiceModal}>
                        <div className="modal-background" >
                            <div className='modal-Invoice-choice-content' ref={modalInvoiceCreateDocumentRef}>
                                <h1>{t('newDocument')}</h1>
                                <div className="create-selector" onClick={() => handleOpenSpecificInvoiceCreator('TaxInvoice')}>
                                    {t('TaxInvoice')}
                                </div>
                                <div className="create-selector" onClick={() => handleOpenSpecificReceiptCreator('ReceiptTax')}>
                                    {t('ReceiptTax')}
                                </div>
                                <div className="create-selector" onClick={() => handleOpenSpecificInvoiceCreator('CreditNote')}>
                                    {t('CreditNote')}
                                </div>
                                <div className="create-selector" onClick={() => handleOpenSpecificReceiptCreator('Receipt')}>
                                    {t('Receipt')}
                                </div>
                                <div className="create-selector" onClick={() => handleOpenSpecificInvoiceCreator('Quotation')}>
                                    {t('Quotation')}
                                </div>
                                <div className="create-selector" onClick={() => handleOpenSpecificInvoiceCreator('Order')}>
                                    {t('Order')}
                                </div>
                                <div className="create-selector" onClick={() => handleOpenSpecificInvoiceCreator('Transaction')}>
                                    {t('Transaction')}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {editedItem && Object.keys(editedItem).length > 0 && (
                    <div className="edit-modal"> {/* This should be styled appropriately */}
                        <div className="edit-modal-content">
                            <div className="edit-modal-content-details">
                                <div className="edit-modal-content-left-details" >
                                    <div className='edit-document-title'>{editedItem.uploadNumber} {t('Document')}</div>
                                    <div className='edit-pdf-td' >
                                        {/* <div className="iframe-overlay"></div> */}
                                        {/* <iframe
                                            src={`${editedItem.documentUrl}#page=1`}
                                            className='edit-table-pdf'
                                            title={editedItem.title}
                                        ></iframe> */}
                                        <Iframe
                                            url={editedItem.documentUrl ? `${editedItem.documentUrl}#page=1` : `${NoDocumentsPdf}#page=1`}
                                            width="100%"
                                            height="95%"
                                            id={editedItem.id}
                                            className="edit-table-pdf"
                                            display="initial"
                                            position="relative"
                                            title={editedItem.title}
                                        />
                                    </div>
                                </div>
                                <div className="edit-modal-content-right-details" >
                                    <div className='edit-modal-content-up-container'>
                                        <div className='edit-modal-content-left-container'>

                                            <div className='edit-document-input-container'>
                                                {
                                                    !editedAddAccountCard ?
                                                        <>

                                                            <Select
                                                                classNamePrefix="edit-my-select"
                                                                options={accountCardOptions.map(option => ({ value: option.name, label: option?.accountCaseId ? `${option.name} - ${option?.accountCaseId} ` : `${option.name} `, value1: option.value1, accountCaseId: option.accountCaseId || '' }))}
                                                                value={accountCardOptions
                                                                    .filter(option => option.name === editedItem.accountCodeName)
                                                                    .map(option => ({ value: option.name, label: option?.accountCaseId ? `${option.name} - ${option?.accountCaseId} ` : `${option.name} `, value1: option.value1, accountCaseId: option.accountCaseId || '' }))[0]}
                                                                onChange={(selectedOption) => handleAccountChange(selectedOption, 'accountCodeName')}
                                                                isDisabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                                placeholder={type === "Incomes" ? t('SelectClientName') : t('SelectProviderName')}
                                                            />
                                                            <div className='edit-document-input-name'>:{t('Client Name')}</div>
                                                        </>
                                                        :
                                                        <div className='items_Container'>
                                                            <button className='clientItemButton ' onClick={handleCloseEditAddNewAccountCardDocument}>
                                                                {t('ReturnToNamesList')}
                                                                <img src={backIcon} alt="Income" className="clientItem-icon clientItemListBack" />
                                                            </button>
                                                            <div className="input-field_Container ">
                                                                <input
                                                                    className="input-field input-field_Container_required-asterisk"
                                                                    type="number"
                                                                    placeholder={t('CompanyId')}
                                                                    value={editedItem.accountCaseId}
                                                                    onChange={(e) => handleEditAddAccountChange(e.target.value, 'accountCaseId')}
                                                                />
                                                                <div className='edit-document-input-name'>:{t('CompanyId')}</div>
                                                            </div>
                                                            <div className="input-field_Container ">
                                                                <input
                                                                    className="input-field input-field_Container_required-asterisk"
                                                                    type="text"
                                                                    placeholder={t('Card Name')}
                                                                    value={editedItem.accountCodeName}
                                                                    onChange={(e) => handleEditAddAccountChange(e.target.value, 'AccountCodeName')}
                                                                />
                                                                <div className='edit-document-input-name'>:{t('Card Name')}</div>
                                                            </div>
                                                            <div className="input-field_Container ">
                                                                <input
                                                                    className="input-field input-field_Container_required-asterisk"
                                                                    type="number"
                                                                    placeholder={t('Card ID')}
                                                                    value={editedItem.accountCodeId}
                                                                    onChange={(e) => handleEditAddAccountChange(e.target.value, 'AccountCodeId')}
                                                                />
                                                                <div className='edit-document-input-name'>:{t('Card ID')}</div>
                                                            </div>

                                                        </div>
                                                }
                                            </div>
                                            <div className='edit-document-input-container'>
                                                <input
                                                    type="text"
                                                    className='edit-input-field'
                                                    value={editedItem.reference || ''}
                                                    onChange={(e) => handleChange(e, 'reference')}
                                                    disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                />
                                                <div className='edit-document-input-name'>:{t('Reference')}</div>

                                            </div>

                                            <div className='edit-document-input-container'>
                                                <div className="edit-date-input-container">
                                                    <ReactDatePicker
                                                        selected={new Date(editedItem.uploadDate)}
                                                        onChange={(e) => handleDateChange(e, 'uploadDate')}
                                                        dateFormat="dd-MM-yyyy"
                                                        className="date-input"
                                                        customInput={<CustomInput2 />}
                                                        disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                    />
                                                    {/* <img src={calendarIcon} alt="Calendar" className="calendar-icon" /> */}

                                                </div>
                                                <div className='edit-document-input-name'>:{t('Upload Date')}</div>
                                            </div>
                                            <div className='edit-document-input-container'>
                                                <div className="edit-date-input-container">
                                                    <ReactDatePicker
                                                        selected={new Date(editedItem.documentDate)}
                                                        onChange={(e) => handleDateChange(e, 'documentDate')}
                                                        dateFormat="dd-MM-yyyy"
                                                        className="date-input"
                                                        customInput={<CustomInput2 />}
                                                        disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                    />
                                                    {/* <img src={calendarIcon} alt="Calendar" className="calendar-icon" /> */}

                                                </div>
                                                <div className='edit-document-input-name'>:{t('Document Date')}</div>
                                            </div>
                                            <div className='edit-document-input-container'>
                                                <div className="edit-date-input-container">
                                                    <ReactDatePicker
                                                        selected={new Date(editedItem.reportingMonthDate)}
                                                        onChange={(e) => setReportingMonthDate(e, 'reportingMonthDate')}
                                                        dateFormat="MM-yyyy"
                                                        className="date-input"
                                                        showMonthYearPicker
                                                        customInput={<CustomInput2 />}
                                                        disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                    />
                                                    {/* <img src={calendarIcon} alt="Calendar" className="calendar-icon" /> */}

                                                </div>
                                                <div className='edit-document-input-name'>:{t('MonthReportDate')}</div>
                                            </div>

                                        </div>
                                        <div className='edit-modal-content-right-container'>
                                            <div className='edit-document-input-container'>
                                                <Select
                                                    classNamePrefix="edit-my-select"
                                                    options={sortCodeIdOptions.map(option => ({ value: option.sortCodeId, label: `${option.sortCodeId} - ${option.sortCodeName}`, sortCodeName: option.sortCodeName }))}
                                                    value={sortCodeIdOptions
                                                        .filter(option => option.sortCodeId === editedItem.sortCodeId)
                                                        .map(option => ({ value: option.sortCodeId, label: `${option.sortCodeName} - ${option.sortCodeId}` }))[0]} // Ensure it's in the correct format
                                                    onChange={(selectedOption) => handleChange({ target: { value: selectedOption.value, name: selectedOption.sortCodeName } }, 'sortCodeId')}
                                                    placeholder={t("Select SortCode")}
                                                    isDisabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                />
                                                <div className='edit-document-input-name'>:{t('Code ID')}</div>

                                            </div>
                                            <div className='edit-document-input-container'>
                                                {type === 'Incomes' ?
                                                    (!editedAddAccountCard ?
                                                        <>
                                                            <Select
                                                                classNamePrefix="edit-my-select"
                                                                options={debitAccountOptions.map(option => ({ value: option.cardId, label: `${option.cardId} - ${option.name}` }))}
                                                                value={debitAccountOptions
                                                                    .filter(option => option.cardId === editedItem.debitAccount)
                                                                    .map(option => ({ value: option.cardId, label: `${option.cardId} - ${option.name}` }))[0]}
                                                                onChange={(selectedOption) => handleAccountChange(selectedOption, 'debitAccount')}
                                                                placeholder={t("Select AccountCard")}
                                                                isDisabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                            />
                                                            <div className='edit-document-input-name'>:{t('Debit Account')}</div>
                                                        </>
                                                        :
                                                        <div className="input-field_Container">
                                                            <input
                                                                className="input-field"
                                                                type="text"
                                                                placeholder={t('Debit Account')}
                                                                value={editedItem.accountCodeId}
                                                                disabled
                                                            />
                                                            <div className='edit-document-input-name'>:{t('Debit Account')}</div>
                                                        </div>
                                                    )
                                                    :
                                                    <>
                                                        <Select
                                                            classNamePrefix="edit-my-select"
                                                            options={debitAccountOptions.map(option => ({ value: option.cardId, label: `${option.cardId} - ${option.name}` }))}
                                                            value={debitAccountOptions
                                                                .filter(option => option.cardId === editedItem.debitAccount)
                                                                .map(option => ({ value: option.cardId, label: `${option.cardId} - ${option.name}` }))[0]}
                                                            onChange={(selectedOption) => handleChange({ target: { value: selectedOption.value } }, 'debitAccount')}
                                                            placeholder={t("Select AccountCard")}
                                                            isDisabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                        />
                                                        <div className='edit-document-input-name'>:{t('Debit Account')}</div>
                                                    </>
                                                }
                                            </div>
                                            <div className='edit-document-input-container'>
                                                {type !== 'Incomes' ?
                                                    (!editedAddAccountCard ?
                                                        <>
                                                            <Select
                                                                classNamePrefix="edit-my-select"
                                                                options={creditAccountOptions.map(option => ({ value: option.cardId, label: `${option.cardId} - ${option.name}` }))}
                                                                value={creditAccountOptions
                                                                    .filter(option => option.cardId === editedItem.creditAccount)
                                                                    .map(option => ({ value: option.cardId, label: `${option.cardId} - ${option.name}` }))[0]}
                                                                onChange={(selectedOption) => type === 'Incomes' ? handleChange({ target: { value: selectedOption.value } }, 'creditAccount') : handleAccountChange(selectedOption, 'creditAccount')}
                                                                placeholder={t("Select AccountCard")}
                                                                isDisabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}

                                                            />
                                                            <div className='edit-document-input-name'>:{t('Credit Account')}</div>
                                                        </> :
                                                        (
                                                            <div className="input-field_Container ">
                                                                <input
                                                                    className="input-field"
                                                                    type="text"
                                                                    placeholder={t('Credit Account')}
                                                                    value={editedItem.accountCodeId}
                                                                    disabled
                                                                />
                                                                <div className='edit-document-input-name'>:{t('Credit Account')}</div>
                                                            </div>
                                                        )
                                                    ) :
                                                    <>
                                                        <Select
                                                            classNamePrefix="edit-my-select"
                                                            options={creditAccountOptions.map(option => ({ value: option.cardId, label: `${option.cardId} - ${option.name}` }))}
                                                            value={creditAccountOptions
                                                                .filter(option => option.cardId === editedItem.creditAccount)
                                                                .map(option => ({ value: option.cardId, label: `${option.cardId} - ${option.name}` }))[0]}
                                                            onChange={(selectedOption) => type === 'Incomes' ? handleChange({ target: { value: selectedOption.value } }, 'creditAccount') : handleAccountChange(selectedOption, 'creditAccount')}
                                                            placeholder={t("Select AccountCard")}
                                                            isDisabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}

                                                        />
                                                        <div className='edit-document-input-name'>:{t('Credit Account')}</div>
                                                    </>
                                                }

                                            </div>

                                            <div className='edit-document-input-container'>
                                                <input
                                                    type="number"
                                                    className='edit-input-field'
                                                    value={editedItem.amount || ''}
                                                    onChange={(e) => handleChange(e, 'amount')}
                                                    disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                />
                                                <div className='edit-document-input-name'>:{t('Amount')}</div>

                                            </div>
                                            <div className='edit-document-input-container'>
                                                <div className='edit-checkBox-field'>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-form-control-label-placement"
                                                        name="position"
                                                        defaultValue="top"
                                                        onChange={handleRadioChange}
                                                    >
                                                        <FormControlLabel
                                                            value="notDone"
                                                            control={<Radio />}
                                                            label={t('unprocessed')}
                                                            labelPlacement="top"
                                                            checked={!stautsCheck}
                                                            disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}

                                                        />
                                                        <FormControlLabel
                                                            value="done"
                                                            control={<Radio />}
                                                            label={t('processed')}
                                                            labelPlacement="top"
                                                            checked={stautsCheck}
                                                            disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                                        />
                                                    </RadioGroup>
                                                </div>

                                                <div className='edit-document-input-name'>:{t('status')}</div>
                                            </div>


                                            <div className='edit-document-input-container'>
                                                <div className='edit-checkBox-field'>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-form-control-label-placement"
                                                        name="position"
                                                        defaultValue="top"
                                                        onChange={handleVatRadioChange}
                                                    >
                                                        <FormControlLabel
                                                            value={16}
                                                            control={<Radio />}
                                                            label={t('AccountingTax')}
                                                            labelPlacement="top"
                                                            checked={editedItem.vat === 16}
                                                            disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}

                                                        />
                                                        <FormControlLabel
                                                            value={100}
                                                            control={<Radio />}
                                                            label="100"
                                                            labelPlacement="top"
                                                            checked={editedItem.vat === 100}
                                                            disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}

                                                        />
                                                        <FormControlLabel
                                                            value={66}
                                                            control={<Radio />}
                                                            label="66"
                                                            labelPlacement="top"
                                                            checked={editedItem.vat === 66}
                                                            disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}

                                                        />
                                                        <FormControlLabel
                                                            value={25}
                                                            control={<Radio />}
                                                            label="25"
                                                            labelPlacement="top"
                                                            checked={editedItem.vat === 25}
                                                            disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}

                                                        />
                                                        <FormControlLabel
                                                            value={0}
                                                            control={<Radio />}
                                                            label="0"
                                                            labelPlacement="top"
                                                            checked={editedItem.vat === 0}
                                                            disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}

                                                        />
                                                    </RadioGroup>
                                                </div>

                                                <div className='edit-document-input-name'>:{t('VAT')}</div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='edit-modal-content-down-container'>

                                        <textarea
                                            // type="text"
                                            className='edit-description-input-field'
                                            value={editedItem.description || ''}
                                            onChange={(e) => handleChange(e, 'description')}
                                            disabled={!isRange ? isClosedReportingMonth : editedItem.isClosedReportingMonth}
                                        />
                                        <div className='edit-document-input-description'>:{t('Description')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='edit-buttons-container'>
                                {
                                    (isRange ? !editedItem?.isClosedReportingMonth : !isClosedReportingMonth) && (
                                        <button className='edit-button-save' onClick={handleUpdate}>
                                            {t('save')}
                                        </button>
                                    )
                                }
                                <button className='edit-button-cancel' onClick={handleCancel}>{t('close')}</button>
                            </div>

                        </div>
                    </div>
                )}
            </div>
        </>

    );
}


export default TransactionDocuments;
