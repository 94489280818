import React, { useState, useEffect, useRef } from 'react';
import {
    TextField,
    Tooltip,
    IconButton,
} from '@mui/material';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import SaveIcon from '@mui/icons-material/SaveAsRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system';
import AssetService from '../../../Services/assets.service';

const StyledContainer = styled('div')({
    position: 'absolute',
    top: '10%',
    left: '20%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '16px',
    minHeight: '40vh',
    maxHeight: '75vh',
    overflowY: 'auto',
    minWidth: '500px',
    zIndex: 9999,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
    border: '1px solid #304fff',
});

const EditAssetGroup = ({ open, onClose, caseId, assetGroup, refreshAssetGroups, onDelete }) => {
    const { t, i18n } = useTranslation();
    const nodeRef = useRef(null);
    const [direction, setDirection] = useState('rtl');
    const [editedAssetGroup, setEditedAssetGroup] = useState({
        caseId: caseId || "",
        assetGroupId: assetGroup?.assetGroupId || "",
        name: assetGroup?.name || "",
        description: assetGroup?.description || "",
        depreciationPercentage: assetGroup?.depreciationPercentage || "",
    });

    useEffect(() => {
        setDirection(i18n.language === 'he' || i18n.language === 'ar' ? 'rtl' : 'ltr');
    }, [i18n.language]);

    useEffect(() => {

        if (assetGroup) {
            setEditedAssetGroup(assetGroup);
        }
    }, [assetGroup, caseId]);

    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        },
    };

    const inputStyle = {
        style: {
            textAlign: direction === 'rtl' ? 'right' : 'left',
            direction: direction === 'rtl' ? 'rtl' : 'ltr',
        },
    };

    const handleChange = (field, value) => {
        setEditedAssetGroup((prev) => ({ ...prev, [field]: value }));
    };

    const handleSave = async () => {
        if (!editedAssetGroup.name || !editedAssetGroup.depreciationPercentage || !editedAssetGroup.assetGroupId) {
            toast.error(t('Please fill all required fields'));
            return;
        }

        const payload = {
            CaseId: caseId,
            AssetGroupId: editedAssetGroup.assetGroupId,
            Name: editedAssetGroup.name,
            Description: editedAssetGroup.description || "",
            DepreciationPercentage: parseFloat(editedAssetGroup.depreciationPercentage),
        };

        try {
            const response = await AssetService.UpdateAssetGroup(payload);
            if (response.success) {
                toast.success(t('Asset group updated successfully'));
                refreshAssetGroups();
                onClose();
            } else {
                toast.error(t('Failed to update asset group'));
            }
        } catch (error) {
            console.error(error);
            toast.error(t('An error occurred while updating asset group'));
        }
    };

    const handleDelete = async () => {
        try {
            const response = await AssetService.DeleteAssetGroup(caseId, editedAssetGroup.AssetGroupId);
            if (response.success) {
                toast.success(t('Asset group deleted successfully'));
                onDelete(editedAssetGroup.AssetGroupId);
                onClose();
            } else {
                toast.error(t('Failed to delete asset group'));
            }
        } catch (error) {
            console.error(error);
            toast.error(t('An error occurred while deleting asset group'));
        }
    };

    const titleStyle = {
        textAlign: 'right',
        width: '100%',
    };

    if (!open) return null;



    return (
        <Draggable handle=".handle" nodeRef={nodeRef}>
            <StyledContainer ref={nodeRef}>
                <div
                    className="handle"
                    style={{
                        cursor: 'move',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        marginBottom: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Tooltip title={t('Exit')}>
                        <IconButton onClick={onClose}>
                            <CancelIcon style={{ color: '#304FFF' }} />
                        </IconButton>
                    </Tooltip>

                    <div className="edit-title">{t('EditAssetGroup')}</div>

                    <div></div>
                </div>

                {/* Id */}
                <div className="form-row">
                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            <span className="required-asterisk">*</span>
                            {t('AssetGroupId')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('AssetGroupName')}
                            value={editedAssetGroup.assetGroupId}
                            onChange={(e) => handleChange('AssetGroupId', e.target.value)}
                            type='number'
                            variant="standard"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                        />
                    </div>
                </div>

                {/* Name */}
                <div className="form-row">
                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            <span className="required-asterisk">*</span>
                            {t('AssetGroupName')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('AssetGroupName')}
                            value={editedAssetGroup.name}
                            onChange={(e) => handleChange('name', e.target.value)}
                            variant="standard"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                        />
                    </div>
                </div>

                {/* Depreciation Percentage */}
                <div className="form-row">
                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            <span className="required-asterisk">*</span>
                            {t('DepreciationPercentage')}
                        </div>
                        <TextField
                            fullWidth
                            type="number"
                            placeholder={t('DepreciationPercentage')}
                            value={editedAssetGroup.depreciationPercentage}
                            onChange={(e) => handleChange('depreciationPercentage', e.target.value)}
                            variant="standard"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                        />
                    </div>
                </div>

                {/* Description */}
                <div className="form-row">
                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            {t('Description')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('Description')}
                            value={editedAssetGroup.description}
                            onChange={(e) => handleChange('description', e.target.value)}
                            variant="standard"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                        />
                    </div>
                </div>

                {/* Actions */}
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', gap: '10px' }}>
                    <Tooltip title={t('Save')}>
                        <IconButton onClick={handleSave}>
                            <SaveIcon style={{ color: '#304FFF' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Delete')}>
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon style={{ color: '#E57C22' }} />
                        </IconButton>
                    </Tooltip>
                </div>
            </StyledContainer>
        </Draggable>
    );
};

export default EditAssetGroup;
