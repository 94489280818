import React, { useState, useEffect, useRef, useMemo } from 'react';
import { styled } from '@mui/system';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import {
    IconButton,
    Tooltip,
    TextField,
    Autocomplete,
    Popper
} from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';

import { toast } from 'react-toastify';
import AssetService from '../../../Services/assets.service';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker'; 
import AutocompleteSelect from '../AutocompleteSelect/AutocompleteSelect';
import AddAssetGroup from '../../Assets/SubComponents/AddAssetGroup';

// 1) Import your AddAssetGroup modal

// For rendering Autocomplete items with hover style
const StyledListItem = styled('li')({
    '&:hover': {
        backgroundColor: '#F5F5F6',
        cursor: 'pointer',
    },
});

// Main container styling
const StyledContainer = styled('div')({
    position: 'absolute',
    top: '10%',
    left: '20%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '16px',
    minHeight: '60vh',
    maxHeight: '85vh',
    overflowY: 'auto',
    minWidth: '700px',
    zIndex: 1300,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
    border: '1px solid #304fff',
});

/** Minimal inline form for creating a new asset, side-by-side fields. */
function AddAssetInline({
    onClose,
    caseId,
    client,
    token,
    type,
    itemId,
    setShowAddAsset,
    // We pass all existing assets so we can populate the "Addition to asset" Autocomplete
    assets = [],
    assetGroups,
    fullItem,
    user,
    fetchEnums,
    setShowAddGroupModal
}) {
    const { t, i18n } = useTranslation();
    const nodeRef = useRef(null);
    const [direction, setDirection] = useState('rtl');

    const maxAssetNumber = assets.length > 0
        ? Math.max(...assets.map(asset => asset.assetNumber))
        : 0;

    // For the user-chosen "Addition to asset"
    const [selectedAdditionAsset, setSelectedAdditionAsset] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);

    const [newAsset, setNewAsset] = useState({
        name: fullItem.description,
        originalPrice: fullItem.amount,
        depreciationPercentage: '',
        purchaseDate: fullItem.documentDate,
        assetNumber: String(maxAssetNumber + 1),
    });

    useEffect(() => {
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);

    // Some textfield styling
    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        },
    };

    const inputStyle = {
        style: {
            textAlign: direction === 'rtl' ? 'right' : 'left',
            direction: direction === 'rtl' ? 'rtl' : 'ltr',
        },
    };

    const handleChange = (field, value) => {
        setNewAsset((prev) => ({ ...prev, [field]: value }));
    };

    /** If user picks an existing asset from "addition to asset," copy its serialNumber and depreciation. */
    const handleSelectAdditionAsset = (asset) => {
        setSelectedAdditionAsset(asset);
        if (asset && asset.assetNumber) {
            setNewAsset((prev) => ({ ...prev, assetNumber: asset.assetNumber }));
        }

        if (asset && asset.depreciationPercentage) {
            setNewAsset((prev) => ({ ...prev, depreciationPercentage: asset.depreciationPercentage }));
        }
    };

    // 2) We'll hold a state for "Add Group" modal if we want to open it from this form, but
    //    typically "Add Group" is used in the ChooseAsset or a top-level place. 
    //    If you want it here too, define something like:
    // const [showAddGroupModal, setShowAddGroupModal] = useState(false);

    //    Then you’d do the same trick as shown below. For now, we’ll do it just for the group selection:

    const handleSelectAssetGroup = (group) => {
        if (!group) return;
        
        
        // If user picked the "Add Group" item
        if (group.id === 'add_group_button') {
            // open some modal here if desired
            setShowAddGroupModal(true);
            return;
        }

        setSelectedGroup(group);
        // Normal group
        setNewAsset((prev) => ({
            ...prev,
            assetGroupId: group.assetGroupId,
            depreciationPercentage: group.depreciationPercentage
        }));
    };

    
    
    // 3) Combine the real groups with a special "Add Group" object
    const assetGroupsWithAdd = [
        ...assetGroups,
        { id: 'add_group_button', name: t('AddGroup'), depreciationPercentage: '' },
    ];

    
    

    const isSaveDisabled = useMemo(() => {
        return (
            !newAsset.name?.trim() ||
            !newAsset.originalPrice || isNaN(newAsset.originalPrice) ||
            !newAsset.depreciationPercentage || isNaN(newAsset.depreciationPercentage) ||
            !newAsset.purchaseDate
        );
    }, [newAsset]);

    // Called when user clicks "Save" in the Add-Asset form
    const handleSaveClick = async () => {
        if (!newAsset.name?.trim() || !newAsset.originalPrice || isNaN(newAsset.originalPrice) ||
            !newAsset.depreciationPercentage || isNaN(newAsset.depreciationPercentage) ||
            !newAsset.purchaseDate) {
            toast.error(t('PleaseFillAllRequiredFieldsCorrectly'));
            return;
        }

        try {
            if (!newAsset.name) {
                toast.error(t('PleaseEnterAssetName'));
                return;
            }

            const assetData = {
                ...newAsset,
                caseId: client.caseId, // Assuming `client.caseId` is available
            };

            // 1) Create the new asset
            const createdAsset = await AssetService.CreateAsset(assetData, token);
            if (!createdAsset || !createdAsset.data.id) {
                toast.error(t('AssetCreationFailed'));
                return;
            }
            toast.success(t('AssetCreatedSuccessfully'));

            // 2) Then attach the document to the newly created asset
            await AssetService.AddDocumentToAsset(
                caseId,
                createdAsset.data.id,
                type,  // e.g. "Expense", "Income", or "JournalEntry"
                itemId,
                token
            );

            // Refresh your data
            await fetchEnums(user, token, client);

            toast.success(t('DocumentAttachedSuccessfully'));
            onClose();
        } catch (error) {
            console.error('Error creating or attaching doc to new asset:', error);
            toast.error(error.message || t('AnErrorOccurred'));
        }
    };

    // A custom popper for the "Addition to asset" Autocomplete
    const StyledPopper = styled(Popper)(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: '8px',
            background: '#fff',
            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.32)',
        },
    }));

    return (
        <div
            style={{
                border: '1px solid #ccc',
                borderRadius: '8px',
                padding: '12px',
                marginTop: '12px',
            }}
        >
            <h3 style={{ margin: 0 }}>{t('AddAsset')}</h3>

            {/* 1) Row => AssetName + OriginalPrice */}
            <div
                className="form-row-2"
                style={{ display: 'flex', gap: '20px', marginTop: '12px' }}
            >
                <div className="edit-form-row-item" style={{ flex: 1 }}>
                    <label>{t('AssetName')}:</label>
                    <TextField
                        fullWidth
                        placeholder={t('AssetName')}
                        value={newAsset.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                        variant="standard"
                        InputProps={inputPropsStyle}
                        inputProps={inputStyle}
                    />
                </div>

                <div className="edit-form-row-item" style={{ flex: 1 }}>
                    <label>{t('OriginalPrice')}:</label>
                    <TextField
                        fullWidth
                        type="number"
                        value={newAsset.originalPrice}
                        onChange={(e) => handleChange('originalPrice', e.target.value)}
                        variant="standard"
                        InputProps={inputPropsStyle}
                        inputProps={inputStyle}
                    />
                </div>
            </div>

            {/* 2) Row => Depreciation + PurchaseDate */}
            <div
                className="form-row-2"
                style={{ display: 'flex', gap: '20px', marginTop: '12px' }}
            >
                <div className="edit-form-row-item" style={{ flex: 1 }}>
                    <label>{t('DepreciationPercentage')}:</label>
                    <TextField
                        fullWidth
                        type="number"
                        value={newAsset.depreciationPercentage}
                        onChange={(e) => handleChange('depreciationPercentage', e.target.value)}
                        variant="standard"
                        InputProps={inputPropsStyle}
                        inputProps={inputStyle}
                    />
                </div>

                <div className="edit-form-row-item" style={{ flex: 1 }}>
                    <label>{t('PurchaseDate')}:</label>
                    <CustomDatePicker
                        date={newAsset.purchaseDate}
                        onDateChange={(date) => handleChange('purchaseDate', date.toISOString())}
                        height="48px"
                    />
                </div>
            </div>

            {/* 3) Row => "Addition to asset" + serialNumber */}
            <div
                className="form-row-2"
                style={{ display: 'flex', gap: '20px', marginTop: '12px' }}
            >
                {/* "Addition to asset" Autocomplete */}
                <div className="edit-form-row-item" style={{ flex: 1 }}>
                    <label>{t('ChooseAssetIfAdditionToAsset')}</label>
                    <Autocomplete
                        options={assets}
                        getOptionLabel={(option) =>
                            `${option.name} - ${option.assetNumber || ''}`
                        }
                        value={selectedAdditionAsset || null}
                        onChange={(event, newVal) => handleSelectAdditionAsset(newVal)}
                        isOptionEqualToValue={(option, val) => option.id === val.id}
                        noOptionsText={<strong>{t('NoOptions')}</strong>}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={t('SelectAnAsset')}
                                variant="standard"
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                    sx: {
                                        height: '48px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        width: '100%',
                                        '&:focus-within': {
                                            border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                        },
                                    },
                                }}
                            />
                        )}
                        renderOption={(props, option) => (
                            <Tooltip title={t('RightClickToEdit')} placement="top">
                                <StyledListItem {...props}>
                                    {`${option.name} - ${option.assetNumber || ''}`}
                                </StyledListItem>
                            </Tooltip>
                        )}
                        PopperComponent={(props) => (
                            <StyledPopper
                                {...props}
                                style={{ ...props.style, direction }}
                            />
                        )}
                    />
                </div>

                {/* SerialNumber */}
                <div className="edit-form-row-item" style={{ flex: 1 }}>
                    <label>{t('SerialNumber')}:</label>
                    <TextField
                        fullWidth
                        value={newAsset.assetNumber}
                        onChange={(e) => handleChange('assetNumber', e.target.value)}
                        variant="standard"
                        InputProps={inputPropsStyle}
                        inputProps={inputStyle}
                    />
                </div>
            </div>

            {/* 4) Row => Choose Group + [Add Group] Option */}
            <div
                className="form-row-2"
                style={{ display: 'flex', gap: '20px', marginTop: '12px' }}
            >
                <div className="edit-form-row-item" style={{ flex: 1 }}>
                    <label>{t('ChooseGroup')}</label>
                    <Autocomplete
                        options={assetGroupsWithAdd}
                        getOptionLabel={(option) =>
                            option.id === 'add_group_button'
                                ? option.name // "AddGroup"
                                : `${option.name} - ${option.depreciationPercentage || ''}%`
                        }
                        value={selectedGroup || null}
                        onChange={(event, newVal) => handleSelectAssetGroup(newVal)}
                        isOptionEqualToValue={(option, val) => option.id === val.id}
                        noOptionsText={<strong>{t('NoOptions')}</strong>}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={t('SelectAssetGroup')}
                                variant="standard"
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                    sx: {
                                        height: '48px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        width: '100%',
                                        '&:focus-within': {
                                            border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                        },
                                    },
                                }}
                            />
                        )}
                        renderOption={(props, option) => (
                            <Tooltip
                                title={
                                    option.id === 'add_group_button'
                                        ? t('AddGroup')
                                        : t('')
                                }
                                placement="top"
                            >
                                <StyledListItem {...props}>
                                    {option.id === 'add_group_button'
                                        ? option.name
                                        : `${option.name} - ${option.depreciationPercentage || ''}%`}
                                </StyledListItem>
                            </Tooltip>
                        )}
                        PopperComponent={(props) => (
                            <StyledPopper
                                {...props}
                                style={{ ...props.style, direction }}
                            />
                        )}
                    />
                </div>

                <div className="edit-form-row-item" style={{ flex: 1 }}>
                    {/* You could add more fields or leave empty */}
                </div>
            </div>

            {/* Action buttons row */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '16px',
                    gap: '10px',
                }}
            >
                <button onClick={() => setShowAddAsset(false)} className="create-Invoice-selector-2-small">
                    <span>{t('Cancel')}</span>
                </button>

                <button
                    onClick={handleSaveClick}
                    className="create-Invoice-selector-2-small"
                    disabled={isSaveDisabled}
                    style={{
                        backgroundColor: isSaveDisabled ? '#ccc' : '#304FFF',
                        cursor: isSaveDisabled ? 'not-allowed' : 'pointer',
                    }}
                >
                    <span>{t('Save')}</span>
                    <SaveIcon fontSize="small" />
                </button>
            </div>

            {/* 5) If you want to show the "AddAssetGroup" modal FROM HERE, do it similarly: */}
            {/* 
            {showAddGroupModal && (
                <AddAssetGroup
                  open={showAddGroupModal}
                  onClose={() => setShowAddGroupModal(false)}
                  caseId={caseId}
                  token={token}
                  assetGroups={assetGroups}
                  refreshAssetGroups={() => {
                    fetchEnums(user, token, client);
                    setShowAddGroupModal(false);
                  }}
                />
            )}
            */}
        </div>
    );
}

function ChooseAsset({
    open = false,
    onClose,
    item,
    type,
    itemId,
    client,
    token,
    fullItem,
    saleEquipment = false,
    clickedAsset = null,
    user,
    fetchEnums,
    setShowAddGroupModal,
    assetGroups
}) {

    const { t, i18n } = useTranslation();
    const nodeRef = useRef(null);

    const [fetchedAssets, setFetchedAssets] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedAsset, setSelectedAsset] = useState(null);

    const [showAddAsset, setShowAddAsset] = useState(false);
    // 6) Also track if we want to show the "Add Group" modal from here:

    const [loading, setLoading] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        setIsSaveDisabled(!selectedAsset);
    }, [selectedAsset]);

  
    const [direction, setDirection] = useState('rtl');
    useEffect(() => {
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);

    // For styling the autocomplete popper
    const StyledPopper = styled(Popper)(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: '8px',
            background: '#fff',
            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.32)',
        },
    }));

    const handleOpenAddAsset = () => {
        if(type === "Incomes"){
            toast.info(t('CannotAddAssetToIncome'));
            return;
        }
        setShowAddAsset(true);
    };

    // On open, fetch assets & groups
    useEffect(() => {
        if (!open) return;
        const fetchData = async () => {
            setLoading(true);
            try {
                const [assetsResp] = await Promise.all([
                    AssetService.GetAllAssets(client?.caseId, token),
                ]);
                // Check if responses are valid
                if (assetsResp.success !== false ) {
                    setFetchedAssets(assetsResp.data);
                } else {
                    toast.error(t('FailedToFetchAssets'));
                }
            } catch (err) {
                console.error(err);
                toast.error(t('fetchEnumsError'), { position: 'top-center' });
            }
            setLoading(false);
        };
        fetchData();
    }, [open, client, token, t]);

    // Filter assets by selected group
    const [filteredAssets, setFilteredAssets] = useState([]);
    const [freeAssets, setFreeAssets] = useState([]);

    useEffect(() => {
        if (!selectedGroup) {
            // If no group is selected, show all assets
            setFilteredAssets(fetchedAssets);
            setFreeAssets(fetchedAssets.filter(asset => !asset.expenseId && !asset.journalEntryId));
        } else {
            // Filter assets that have the correct `assetGroupId`
            const filtered = fetchedAssets.filter(a => a.assetGroupId === selectedGroup.assetGroupId);
            setFilteredAssets(filtered);
            setFreeAssets(filtered.filter(asset => !asset.expenseId && !asset.journalEntryId));
        }
    }, [selectedGroup, fetchedAssets]);

    const handleSelectAsset = (asset) => {
        setSelectedAsset(asset);
    };

    const handleSaveSelection = async () => {
        try {
            if (!selectedAsset) {
                toast.error(t('NoAssetSelected'));
                return;
            }
            if (!saleEquipment) {
                await AssetService.AddDocumentToAsset(
                    client.caseId,
                    selectedAsset.id,
                    type,
                    itemId,
                    token
                );
            } else {
                // Mark the asset as ended
                await AssetService.SetEndDate(
                    client.caseId,
                    selectedAsset.id,
                    fullItem.documentDate,
                    token
                );
            }

            await fetchEnums(user, token, client);

            toast.success(t('DocumentAttachedSuccessfully'));
            onClose();
        } catch (error) {
            console.error('Error attaching doc to chosen asset');
            toast.error(t('AnErrorOccurred'));
        }
    };

    const handleSaveNewAsset = () => {
        setShowAddAsset(false);
        onClose();
    };

    if (!open) return null;

    return (
        <Draggable handle=".choose-asset-handle" nodeRef={nodeRef} >
            <StyledContainer ref={nodeRef}>
                {/* Draggable header */}
                <div
                    className="choose-asset-handle"
                    style={{
                        cursor: 'move',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        marginBottom: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div></div>
                    <div>{t('ChooseAssetTitle')}</div>
                    <Tooltip title={t('Exit')}>
                        <IconButton onClick={onClose}>
                            <CancelIcon style={{ color: '#304FFF' }} />
                        </IconButton>
                    </Tooltip>
                </div>

                <div style={{ marginBottom: '16px' }}>
                    <strong style={{ color: '#0f1042' }}>
                        {saleEquipment === false
                            ? t('YouChooseEquipmentVatPleaseSelectAssetToBeAddedFromAssetList')
                            : t('YouChooseSaleOfEquipmentPleaseSelectAssetToBeRemovedFromAssetList')}
                    </strong>
                </div>

                {loading && <div>{t('LoadingAssets')}...</div>}

                {/* If not adding new, show row with 2 autocompletes */}
                {!loading && !showAddAsset && (
                    <>
                        <div className="form-row-2" style={{ display: 'flex', gap: '20px' }}>
                            {/* Asset */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{ textAlign: 'right', width: '100%', marginBottom: '4px' }}
                                >
                                    <span className="required-asterisk">*</span>
                                    {t('Asset')}
                                </div>
                                <Autocomplete
                                    options={freeAssets}
                                    getOptionLabel={(option) => `${option.name} - ${option.assetNumber}`}
                                    value={selectedAsset || null}
                                    onChange={(event, newVal) => handleSelectAsset(newVal)}
                                    isOptionEqualToValue={(option, val) => option.id === val.id}
                                    noOptionsText={<strong>{t('NoOptions')}</strong>}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('SelectAnAsset')}
                                            variant="standard"
                                            InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                                className: `custom-input-box-sizing-toggle ${
                                                    direction === 'ltr' ? 'ltr-input' : 'rtl-input'
                                                }`,
                                                sx: {
                                                    height: '48px',
                                                    padding: '0 10px',
                                                    borderRadius: '8px',
                                                    background: '#F5F5F6',
                                                    border: '1px solid transparent',
                                                    width: '100%',
                                                    '&:focus-within': {
                                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <StyledListItem {...props}>
                                            {`${option.name} - ${option.assetNumber}`}
                                        </StyledListItem>
                                    )}
                                    PopperComponent={(props) => (
                                        <StyledPopper {...props} style={{ ...props.style, direction }} />
                                    )}
                                />
                            </div>

                            {/* AssetGroup */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{ textAlign: 'right', width: '100%', marginBottom: '4px' }}
                                >
                                    {t('FilterByAssetGroup')}
                                </div>

                                {/* 7) You could also add an “Add Group” button or logic here similarly */}
                                <Autocomplete
                                    options={assetGroups}
                                    getOptionLabel={(option) => `${option.assetGroupId} - ${option.name}`}
                                    value={selectedGroup || null}
                                    onChange={(event, newVal) => setSelectedGroup(newVal)}
                                    isOptionEqualToValue={(option, val) => option.assetGroupId === val.assetGroupId}
                                    noOptionsText={<strong>{t('NoOptions')}</strong>}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('SelectAssetGroup')}
                                            variant="standard"
                                            InputProps={{
                                                ...params.InputProps,
                                                disableUnderline: true,
                                                className: `custom-input-box-sizing-toggle ${
                                                    direction === 'ltr' ? 'ltr-input' : 'rtl-input'
                                                }`,
                                                sx: {
                                                    height: '48px',
                                                    padding: '0 10px',
                                                    borderRadius: '8px',
                                                    background: '#F5F5F6',
                                                    border: '1px solid transparent',
                                                    width: '100%',
                                                    '&:focus-within': {
                                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <StyledListItem {...props}>
                                            {`${option.assetGroupId} - ${option.name}`}
                                        </StyledListItem>
                                    )}
                                    PopperComponent={(props) => (
                                        <StyledPopper {...props} style={{ ...props.style, direction }} />
                                    )}
                                />
                            </div>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '10px',
                                marginTop: '16px',
                            }}
                        >
                            {/* Create New Asset */}
                            {!saleEquipment && (
                                <button
                                    onClick={() => handleOpenAddAsset()}
                                    className="create-Invoice-selector-2-small"
                                >
                                    <span>{t('CreateNewAsset')}</span>
                                    <AddCircleIcon fontSize="small" />
                                </button>
                            )}
                        </div>

                        {/* Save button */}
                        <button
                            onClick={handleSaveSelection}
                            className="create-Invoice-selector-2-small"
                            disabled={isSaveDisabled}
                            style={{
                                backgroundColor: isSaveDisabled ? '#ccc' : '#304FFF',
                                cursor: isSaveDisabled ? 'not-allowed' : 'pointer',
                                marginTop: '12px',
                            }}
                        >
                            <span>{t('Save')}</span>
                            <SaveIcon fontSize="small" />
                        </button>
                    </>
                )}

                {showAddAsset && (
                    <AddAssetInline
                        onClose={handleSaveNewAsset}
                        caseId={client.caseId}
                        token={token}
                        type={type}
                        itemId={itemId}
                        assets={fetchedAssets}
                        fullItem={fullItem}
                        client={client}
                        setShowAddAsset={setShowAddAsset}
                        user={user}
                        fetchEnums={fetchEnums}
                        assetGroups={assetGroups}
                        setShowAddGroupModal={setShowAddGroupModal}
                    />
                )}

                

               
            </StyledContainer>
        </Draggable>
    );
}

export default ChooseAsset;
