import React, { useState, useMemo } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    Paper,
    IconButton,
    Typography,
    Box,
    Divider,
    Button,
    TextField,
    InputAdornment,
    Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

// **Enhanced Styling**
const dialogBoxStyle = {
    bgcolor: 'background.paper',
    borderRadius: '12px',
    boxShadow: 24,
    zIndex: '1000 !important', // Ensure lower than AccountantDetailsModals
    overflow: 'hidden',
    maxWidth: '90%',
    width: '90%',
};
const stickyHeaderStyle = {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: '#f5f5f5',
};

const titleStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    p: 2,
    pb: 1,
    fontWeight: 'bold',
};

const tableHeadCellStyle = {
    fontWeight: '600',
    backgroundColor: '#f5f5f5',
};

const cellStyle = {
    fontSize: '0.9rem',
};

export default function DataTableDialog({
    open,
    onClose,
    data = [],
    title = 'Details',
    rowsPerPageOptions = [50, 70, 100], // Bigger page sizes
    defaultRowsPerPage = 50,
    searchPlaceholder = 'Search',
}) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [searchTerm, setSearchTerm] = useState('');
    const { i18n, t } = useTranslation();
    const direction = i18n.dir();
    
    // **Extract `onEdit` & `onDelete` handlers and remove them from the row data**
    const cleanedData = useMemo(() => {
        return data.map(({ onEdit, onDelete, ...rest }) => ({
            ...rest,
            actions: { onEdit, onDelete }, // Store callbacks in a separate key
        }));
    }, [data]);

    // **Derive Columns & Add "Actions" Column**
    const derivedColumns = useMemo(() => {
        if (!cleanedData.length) return [];
        const keys = Object.keys(cleanedData[0]).filter(key => key !== 'value' && key !== 'actions'); // Exclude "value" & "actions"
        return [...keys.map((key) => ({
            id: key,
            label: t(key), // Apply translation function
        })), { id: 'actions', label: t('Actions') }];
    }, [cleanedData, t]);

    // **Handle Search**
    const filteredData = useMemo(() => {
        if (!searchTerm.trim()) return cleanedData;

        const lowerSearch = searchTerm.trim().toLowerCase();

        return cleanedData.filter((row) => {
            return Object.entries(row).some(([key, value]) => {
                if (key === 'actions') return false; // Ignore actions column

                const keyMatch = key.toLowerCase().includes(lowerSearch); // Search in column name
                const valueMatch =
                    typeof value === 'string'
                        ? value.toLowerCase().includes(lowerSearch)
                        : typeof value === 'number' || typeof value === 'boolean'
                            ? String(value).toLowerCase().includes(lowerSearch)
                            : false; // Ignore non-primitive values

                return keyMatch || valueMatch; // Match in either key or value
            });
        });
    }, [cleanedData, searchTerm]);




    // **Paginated Data**
    const paginatedData = useMemo(() => {
        const startIndex = page * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        return filteredData.slice(startIndex, endIndex);
    }, [filteredData, page, rowsPerPage]);

    // **Pagination Handling**
    const handleClose = () => {
        setPage(0);
        setRowsPerPage(defaultRowsPerPage);
        setSearchTerm('');
        onClose();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: dialogBoxStyle }} sx={{ zIndex: 1000 }} disableEnforceFocus disableRestoreFocus>
            {/* Header */}
            <Box sx={titleStyle} className={`${direction}`}>
                <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
                    {t(title)}
                </Typography>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Divider />

            {/* Search Bar */}
            <Box sx={{ px: 2, py: 1 }} className={`${direction}`}>
                <TextField
                    placeholder={t(searchPlaceholder)}
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            {/* Table Container with Scrollable Content */}
            <DialogContent dividers sx={{ p: 0, maxHeight: '70vh', overflowY: 'auto' }} className={`${direction}`} >
                <TableContainer
                    dir={direction}
                    component={Paper}
                    sx={{ maxHeight: '55vh', overflowY: 'auto', boxShadow: 'none' }}
                >
                    <Table size="small" stickyHeader className="sticky-header">
                        <TableHead sx={{ position: 'sticky', top: 0, zIndex: 2, backgroundColor: '#f5f5f5' }}>
                            <TableRow>
                                {derivedColumns.map((col) => (
                                    <TableCell key={col.id} sx={{ ...cellStyle, ...tableHeadCellStyle }}>
                                        {col.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.length ? (
                                paginatedData.map((row, idx) => (
                                    <TableRow key={idx}>
                                        {derivedColumns.map((col) => (
                                            <TableCell key={col.id} sx={cellStyle}>
                                                {col.id === 'actions' ? (
                                                    <Box display="flex">
                                                        {row.actions.onEdit && (
                                                            <Tooltip title={t('Edit')}>
                                                                <IconButton
                                                                    onClick={(event) => {
                                                                        event.stopPropagation(); // Prevent dialog from closing
                                                                        row.actions.onEdit(row);
                                                                    }}
                                                                    size="small"
                                                                >
                                                                    <EditIcon color="primary" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                        {row.actions.onDelete && (
                                                            <Tooltip title={t('Delete')}>
                                                                <IconButton
                                                                    onClick={(event) => {
                                                                        event.stopPropagation(); // Prevent dialog from closing
                                                                        row.actions.onDelete(row);
                                                                    }}
                                                                    size="small"
                                                                >
                                                                    <DeleteIcon color="error" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </Box>
                                                ) : (
                                                    row[col.id] != null ? String(row[col.id]) : ''
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={derivedColumns.length} align="center">
                                        <Typography variant="body2" sx={{ color: 'gray' }}>
                                            {t('NoDataAvailable')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>

            {/* Pagination & Close Button */}
            <DialogActions sx={{ flexDirection: 'column', alignItems: 'stretch', mt: 1 }}>
                <TablePagination
                    component="div"
                    count={filteredData.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    labelRowsPerPage={t('Rows per page')}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('more than')} ${to}`}`
                    }
                />

            </DialogActions>
        </Dialog>
    );
}
