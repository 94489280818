export const handleDownloadTemplate = (file, fileName) => {
    if (!file) {
        console.error("File not found.");
        return;
    }

    const link = document.createElement("a");
    link.href = file;
    link.setAttribute("download", fileName || "download.xlsx"); // Set default filename if not provided
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
