import React, { useState, useRef, useEffect, useMemo, useContext } from 'react';
import HamburgerMenu from '../../HamburgerMenu/HamburgerMenu.jsx';
import UpperPanelDetails from '../UpperPanelDetails/UpperPanelDetails.jsx';
import SignaturePad from 'react-signature-canvas';
import ReactDatePicker from 'react-datepicker';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import './CreateInvoice.css';
import {
    Modal,
    TextField,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
    Popper,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';

import EnumsService from '../../../Services/enums.service.js';
import UserService from '../../../Services/user.service.js';
import AutocompleteSelect from '../AutocompleteSelect/AutocompleteSelect.jsx';
import cancelIcon from '../../../assests/images/Icons/cancelIcon.svg';
import SignatureReferenceModal from '../SignatureReferenceModal/SignatureReferenceModal.jsx';
import PuffLoader from "react-spinners/PuffLoader";
import TaxAuthorityService from '../../../Services/taxAuthority.service.js';
import DocumentService from '../../../Services/documents.service.js';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS
import Constants from '../../../assests/Constants/constants.js';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker.js';
import { beautifyNumber } from '../../../Utils/FormatNumber.js';
import ClientService from '../../../Services/client.service.js';
import { FileContext } from '../../../Contexts/FileContext.js';
import { getVatValue } from '../../../Utils/GetVatValue.js';
import { getIsraelInvoiceAmountLimit } from '../../../Utils/GetIsraelInvoiceAmountLimit.js';
import { validateEmail, validatePhoneNumber } from '../../../Utils/helpers.js';
import ClientItemsService from '../../../Services/clientItems.service.js';
// const InvoiceModalTypes = [
//     {
//         controller: 'PaymentAcceptances',
//         endPoint: 'GeneratePaymentAcceptanceDocumentPdf',
//         draftEndPoint: 'GeneratePaymentAcceptanceDocumentDraftPdf', // Assuming you have a draft endpoint
//         type: '',
//         TransactionDocumentType: '',
//         TransactionDocumentTitle: '',
//         title: 'invoiceReceipt',
//         screen: '/PaymentAcceptance',
//         type: 'PaymentAcceptance',
//     },
//     {
//         controller: 'PaymentAcceptances',
//         endPoint: 'GeneratePaymentAcceptanceDocumentPdf',
//         draftEndPoint: 'GeneratePaymentAcceptanceDocumentDraftPdf', // Assuming you have a draft endpoint
//         type: '',
//         TransactionDocumentType: '',
//         TransactionDocumentTitle: '',
//         title: 'generateCreditNote',
//         screen: '/PaymentAcceptance',
//         type: 'receipt',
//     },
// ];

const InvoiceModalTypes = [
    { controller: "Incomes", endPoint: "GenerateIncomePdf", draftEndPoint: "GenerateIncomeDraftPdf", type: "", TransactionDocumentType: "", TransactionDocumentTitle: "", title: "generateInvoice" },
    { controller: "Incomes", endPoint: "GenerateRefundIncomePdf", draftEndPoint: "GenerateRefundIncomeDraftPdf", type: "", TransactionDocumentType: "", TransactionDocumentTitle: "", title: "generateCreditNote" },
    { controller: "TransactionDocuments", endPoint: "GenerateTransactionDocumentPdf", draftEndPoint: "GenerateTransactionDocumentDraftPdf", type: "quotation", TransactionDocumentType: "Quotation", TransactionDocumentTitle: "הצעת מחיר", title: "generateQuotation" },
    { controller: "TransactionDocuments", endPoint: "GenerateTransactionDocumentPdf", draftEndPoint: "GenerateTransactionDocumentDraftPdf", type: "order", TransactionDocumentType: "Order", TransactionDocumentTitle: "הזמנה", title: "generateOrder" },
    { controller: "TransactionDocuments", endPoint: "GenerateTransactionDocumentPdf", draftEndPoint: "GenerateTransactionDocumentDraftPdf", type: "transaction", TransactionDocumentType: "Transaction", TransactionDocumentTitle: "חשבון עסקה", title: "generateTransactionInvoice" },
    {
        controller: 'PaymentAcceptances',
        endPoint: 'GeneratePaymentAcceptanceDocumentPdfV2',
        draftEndPoint: 'GeneratePaymentAcceptanceDocumentDraftPdf', // Assuming you have a draft endpoint
        TransactionDocumentType: '',
        TransactionDocumentTitle: 'חשבונית מס קבלה',
        title: 'invoiceReceipt',
        screen: '/PaymentAcceptance',
        type: 'PaymentAcceptances',
    },
    {
        controller: 'PaymentAcceptances',
        endPoint: 'GeneratePaymentAcceptanceDocumentPdfV2',
        draftEndPoint: 'GeneratePaymentAcceptanceDocumentDraftPdf', // Assuming you have a draft endpoint
        TransactionDocumentType: '',
        TransactionDocumentTitle: 'קבלה',
        title: 'receipt',
        screen: '/PaymentAcceptance',
        type: 'PaymentAcceptances',
    },
];

// ====== Styled components for the table styling ======
const StyledTableContainer = styled(TableContainer)({
    marginTop: '20px',
    width: '82%',
    boxShadow: 'none',
    borderRadius: 0,
});

const StyledTable = styled(Table)({
    borderCollapse: 'collapse',
});

const StyledTableHead = styled(TableHead)({
    backgroundColor: '#BFC8FF',
    '& .MuiTableCell-head': {
        backgroundColor: '#BFC8FF',
        color: '#304FFF',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        textAlign: 'center',
        textTransform: 'uppercase',
        borderBottom: '2px solid #d3d3d3',
        position: 'sticky',
        top: 0
    },
});

const StyledTableRow = styled(TableRow)({
    '&:hover': {
    },
});

const StyledTableCellHeader = styled(TableCell)({
    backgroundColor: '#BFC8FF',
    color: '#304FFF',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center',
    textTransform: 'uppercase',
    borderBottom: '2px solid #d3d3d3',
    position: 'sticky',
    top: 0
});

const StyledTableCell = styled(TableCell)({
    borderTop: '1px solid rgba(191, 191, 193, 0.56)',
    backgroundColor: 'rgba(234, 237, 255, 0.32)',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    textAlign: 'center',
    // Matches the default row height or margin/padding for your style
});

const CreateReceipt = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { files } = useContext(FileContext);

    const [accountCardOptions, setAccountCardOptions] = useState([]);
    const [sortCodeIdOptions, setSortCodeIdOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [creditAccountOptions, setCreditAccountOptions] = useState([]);
    const [debitAccountOptions, setDebitAccountOptions] = useState([]);
    const [addNewAccountCardDocument, setAddNewAccountCardDocument] = useState(false);
    const [accountCardEditDownloadOptions, setAccountCardEditDownloadOptions] = useState([]);
    const [direction, setDirection] = useState('rtl'); // Default to 'rtl'
    const [clientItems, setClientItems] = useState([]);
    const [isFirstUserFetched, setIsFirstUserFetched] = useState(false);
    const [token, setToken] = useState('');
    const [data, setData] = useState([]);
    const [client, setClient] = useState(null);
    const [type, setType] = useState(null);
    const [user, setUser] = useState(null);
    const [cardIdOption, setCardIdOption] = useState(-1);
    const [newItems, setNewItems] = useState([]); // To store new items
    const [addItemFlags, setAddItemFlags] = useState([true]); // Array of booleans to track add new item mode per index
    const [invoiceSignature, setInvoiceSignature] = useState('');
    const [invoiceReference, setInvoiceReference] = useState('');
    const [signatureReferenceFilled, setSignatureReferenceFilled] = useState(false);
    const [editedItem, setEditedItem] = useState({});

    const [authUrl, setAuthUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [resumeInvoiceCreation, setResumeInvoiceCreation] = useState(false);
    const [draftPdfModalVisible, setDraftPdfModalVisible] = useState(false);
    const [PdfModalVisible, setPdfModalVisible] = useState(false);
    const [draftPdfPath, setDraftPdfPath] = useState('');
    const [draftPdfBlob, setDraftPdfBlob] = useState(null);
    const [PdfBlob, setPdfBlob] = useState(null);
    const [isTaxAuthorityAuthModalVisible, setIsTaxAuthorityAuthModalVisible] = useState(false);
    const [invoiceModalType, setInvoiceModalType] = useState(0); // Default to 0 for TaxInvoice
    const sigPad = useRef(null);
    const [signatureReferenceInvoiceModalVisible, setSignatureReferenceInvoiceModalVisible] = useState(false);
    const [authModalVisible, setAuthModalVisible] = useState(false);
    const [authWindow, setAuthWindow] = useState(null);
    const [needSignature, setNeedSignature] = useState(false);
    const [needReference, setNeedReference] = useState(false);
    // const [documents, setDocuments] = useState([]);

    // const handleDocumentUpload = (event) => {
    //     const selectedFiles = Array.from(event.target.files);
    //     setDocuments(selectedFiles);
    // };

    const handleTaxAuthorityAuth = (authUrl) => {
        // Open the authentication URL in a new tab

        const authWindow = window.open(authUrl, '_blank');

        // Store the authWindow in state
        setAuthWindow(authWindow);

        // Start checking for authentication completion
        checkAuthCompletion(authWindow);
    };
    const [formData, setFormData] = useState({
        invoiceClientName: '',
        invoiceClientCaseId: '',
        invoiceAccountCodeId: '',
        invoiceClientEmail: '',
        invoiceClientPhoneNumber: '',
        invoiceClientLocation: '',
        invoiceDescription: '',
        priceIncludingVAT: true,
        invoiceDate: new Date(),
        reportingMonthDate: new Date(),
        invoiceItems: [],
        receiptItems: {
            checks: [],
            creditCards: [],
            cash: [],
            bankTransactions: [],
        },
        documents: [],
        forceNoTax: false,
    });

    const [VAT_RATE, setVatRate] = useState(getVatValue(formData?.invoiceDate));
    useEffect(() => {
        if (formData?.invoiceDate) {
            setVatRate(getVatValue(formData.invoiceDate));
        }
    }, [formData]);

    const isTaxExemptOrNoTax = useMemo(() => {
        if (!client) return false;
        const clientIsExempt = client.caseType && Constants.TaxExemptTypes.includes(client.caseType);
        return formData.forceNoTax || clientIsExempt;
    }, [formData.forceNoTax, client]);


    const showNoTaxCheckbox = useMemo(() => {
        if (!client) return false;
        const clientIsExempt = client.caseType && Constants.TaxExemptTypes.includes(client.caseType);
        return (invoiceModalType === 5) || clientIsExempt;
    }, [invoiceModalType, client]);

    // Compute the total invoice amount (skipping VAT if isTaxExemptOrNoTax)
    const computeTotalInvoiceAmount = () => {
        return formData.invoiceItems.reduce((sum, item) => {
            let price = parseFloat(item.price) || 0;
            const quantity = parseFloat(item.quantity) || 1;
            const discountPercent = parseFloat(item.discount) || 0;

            let baseAmount = price * quantity;
            let discountedPrice = baseAmount - (baseAmount * discountPercent) / 100;

            if (isTaxExemptOrNoTax) {
                // No VAT added
                return sum + discountedPrice;
            } else {
                // Regular logic
                if (formData.priceIncludingVAT) {
                    return sum + discountedPrice;
                } else {
                    return sum + (discountedPrice * (1 + VAT_RATE));
                }
            }
        }, 0);
    };
    const totalReceiptAmount = useMemo(() => {
        const { checks, creditCards, cash, bankTransactions } = formData.receiptItems;
        const sumAmounts = (items) => {
            return items.reduce((partial, i) => partial + (parseFloat(i.amount) || 0), 0);
        };
        return (
            sumAmounts(checks) +
            sumAmounts(creditCards) +
            sumAmounts(cash) +
            sumAmounts(bankTransactions) +
            parseFloat(formData.invoiceWithholdingTax || 0)
        );
    }, [formData.receiptItems.checks, formData.receiptItems.creditCards, formData.receiptItems.cash, formData.receiptItems.bankTransactions, formData.invoiceWithholdingTax]);
    const totalInvoiceAmount = computeTotalInvoiceAmount();
    const [isAmountAboveLimit, setIsAmountAboveLimit] = useState(totalInvoiceAmount >= getIsraelInvoiceAmountLimit(formData.invoiceDate));
    useEffect(() => {
        if (formData?.invoiceDate) {
            setIsAmountAboveLimit(totalInvoiceAmount >= getIsraelInvoiceAmountLimit(formData?.invoiceDate));
        }
    }, [formData]);


    // 1) Basic fields check
    const basicFieldsFilled = useMemo(() => {
        return (
            formData.invoiceClientName &&
            formData.invoiceClientPhoneNumber &&
            formData.reportingMonthDate &&
            formData.invoiceDate
        );
    }, [
        formData.invoiceClientName,
        formData.invoiceClientPhoneNumber,
        formData.reportingMonthDate,
        formData.invoiceDate,
    ]);

    // 2) Check invoice items (if needed)
    const invoiceItemsValid = useMemo(() => {
        // If we do NOT need to check invoice items (i.e. modalType = 6 only?),
        // we can just return true to avoid failing the overall condition.
        // But here, we DO check invoice items for modalType === 5 or "other" (not 6).
        // So let's check them whenever "invoiceModalType !== 6" is relevant.
        // We'll do a narrower check below to handle "5" vs. "others."

        // If we need to validate them:
        const hasItems = formData.invoiceItems.length > 0;
        const allFields = formData.invoiceItems.every(item =>
            item.name &&
            item.makat &&
            item.price !== null &&
            item.price !== undefined
        );

        return hasItems && allFields;
    }, [formData.invoiceItems]);

    // 3) Check receipt items (if needed)
    // (ALL sub-arrays must have valid amount and paymentDate)
    const receiptItemsValid = useMemo(() => {
        // If we do NOT need to check receipt items,
        // just return true so it doesn't fail the overall condition.
        // We only check them when modalType === 5 or === 6.

        const checksValid = (formData.receiptItems.checks ?? []).every(item =>
            item.amount !== null &&
            item.amount !== undefined &&
            item.paymentDate !== null &&
            item.paymentDate !== undefined
        );

        const creditCardsValid = (formData.receiptItems.creditCards ?? []).every(item =>
            item.amount !== null &&
            item.amount !== undefined &&
            item.paymentDate !== null &&
            item.paymentDate !== undefined
        );

        const cashValid = (formData.receiptItems.cash ?? []).every(item =>
            item.amount !== null &&
            item.amount !== undefined &&
            item.paymentDate !== null &&
            item.paymentDate !== undefined
        );

        const bankTransactionsValid = (formData.receiptItems.bankTransactions ?? []).every(item =>
            item.amount !== null &&
            item.amount !== undefined &&
            item.paymentDate !== null &&
            item.paymentDate !== undefined
        );

        return checksValid && creditCardsValid && cashValid && bankTransactionsValid;
    }, [
        formData.receiptItems.checks,
        formData.receiptItems.creditCards,
        formData.receiptItems.cash,
        formData.receiptItems.bankTransactions,
    ]);

    // 4) Amount-above-limit check
    const clientCaseIdCheck = useMemo(() => {
        return (!isAmountAboveLimit || formData.invoiceClientCaseId);
    }, [isAmountAboveLimit, formData.invoiceClientCaseId]);

    // Combine it all into one final condition
    const allClientItemFieldsFilled = useMemo(() => {
        // Must have the basic fields
        if (!basicFieldsFilled) return false;
        // Must pass the amount limit check
        if (!clientCaseIdCheck) return false;

        // Ensure phone number is valid
        if (!validatePhoneNumber(formData.invoiceClientPhoneNumber)) return false;

        // Ensure email is valid only if it's entered


        if ((formData.invoiceClientEmail) && !validateEmail(formData.invoiceClientEmail)) return false;

        // Logic for invoiceModalType
        switch (invoiceModalType) {
            case 5:
                // Must validate *both* invoiceItems + receiptItems
                return invoiceItemsValid && receiptItemsValid;

            case 6:
                // Must validate *only* receiptItems
                return receiptItemsValid;

            default:
                // For any other modalType, must validate only invoiceItems
                return invoiceItemsValid;
        }
    }, [
        formData,
        basicFieldsFilled,
        clientCaseIdCheck,
        invoiceModalType,
        invoiceItemsValid,
        receiptItemsValid,
    ]);


    const handleReceiptItemChange = (type, index, field, value) => {
        setFormData((prevState) => {
            const updatedItems = [...prevState.receiptItems[type]];
            updatedItems[index] = {
                ...updatedItems[index],
                [field]: value,
            };
            return {
                ...prevState,
                receiptItems: {
                    ...prevState.receiptItems,
                    [type]: updatedItems,
                },
            };
        });
    };

    const handleAddReceiptItem = (type) => {
        setFormData((prevState) => ({
            ...prevState,
            receiptItems: {
                ...prevState.receiptItems,
                [type]: [{ amount: '', description: '' }, ...prevState.receiptItems[type]], // Prepend the new item
            },
        }));
    };


    const handleRemoveReceiptItem = (type, index) => {
        setFormData((prevState) => {
            const updatedItems = prevState.receiptItems[type].filter((item, idx) => idx !== index);
            return {
                ...prevState,
                receiptItems: {
                    ...prevState.receiptItems,
                    [type]: updatedItems,
                },
            };
        });
    };

    const handleSignatureChange = () => {
        if (sigPad.current) {
            setInvoiceSignature(sigPad.current.getTrimmedCanvas().toDataURL('image/png'));
            setSignatureReferenceFilled(invoiceSignature && invoiceReference);
        }
    };

    const handleSignatureReferenceSave = async () => {
        if (needSignature && !invoiceSignature) {
            toast.error(t('ProvideSignature'));
            return;
        }
        if (needReference && !invoiceReference) {
            toast.error(t('ProvideSignature'));
            return;
        }
        setSignatureReferenceInvoiceModalVisible(false);
        // Proceed with next steps
    };

    const handleSignatureReferenceClose = async () => {
        setInvoiceSignature('');
        setInvoiceReference('');
        setSignatureReferenceInvoiceModalVisible(false);
    };

    const clearSignature = () => {
        sigPad.current.clear();
        setInvoiceSignature('');
        setSignatureReferenceFilled(false);
    };

    useEffect(() => {

        setFormData((prevFormData) => ({
            ...prevFormData,
            documents: files,

        }));
    }, [files]);

    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',');
        const mimeMatch = arr[0].match(/:(.*?);/);
        const mime = mimeMatch ? mimeMatch[1] : '';
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };


    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const currentUser = await UserService.getCurrentUser();
                const currentToken = await UserService.getCurrentToken();
                if (currentUser && currentToken) {
                    setUser(currentUser);
                    setToken(currentToken);

                    if (location.state && location.state.client && location.state.type) {
                        setClient(location.state.client);
                        setType(location.state.type);

                        // Map the location.state.type to the correct invoiceModalType index
                        const typeMapping = {
                            'TaxInvoice': 0,
                            'CreditNote': 1,
                            'Quotation': 2,
                            'Order': 3,
                            'TransactionInvoice': 4,
                            'ReceiptTax': 5,
                            'Receipt': 6,
                        };
                        // Handle the item passed in location.state.item
                        if (location.state.item) {
                            const itemData = location.state.item;
                            const documents = itemData.documents || [];
                            // Create File objects directly from document metadata
                            const fetchedDocuments = (itemData.documents || []).map((doc) => {
                                try {
                                    // Construct a File object using the metadata, assuming `doc.content` is the binary data if available
                                    const file = new File([doc.contentType], doc.fileName, {
                                        type: doc.contentType || 'application/octet-stream',
                                        lastModified: Date.now(),
                                    });

                                    return file;
                                } catch (error) {
                                    console.error('Error creating File object:', error);
                                    return null; // Skip the document if there's an error
                                }
                            });

                            // Filter out any null values in case some file creations failed
                            const validDocuments = fetchedDocuments.filter(file => file !== null);

                            // Update formData with the constructed documents
                            setFormData((prevFormData) => ({
                                ...prevFormData,
                                base64Files: itemData.base64Files,
                                documents: documents,
                                invoiceClientName: itemData.accountCodeName || '',
                                invoiceClientCaseId: itemData.accountCaseId || '',
                                invoiceAccountCodeId: itemData.accountCodeId || '',
                                invoiceDescription: itemData.description || '',
                                invoiceDate: itemData.date ? new Date(itemData.date) : new Date(),
                                reportingMonthDate: itemData.reportingMonthDate ? new Date(itemData.reportingMonthDate) : new Date(),
                                receiptItems: {
                                    checks: itemData.checks || [],
                                    creditCards: itemData.creditCards || [],
                                    cash: itemData.cash || [],
                                    bankTransactions: itemData.bankTransactions || [],
                                },
                            }));
                        }

                        const modalTypeIndex = typeMapping[location.state.type];

                        if (modalTypeIndex !== undefined) {
                            setInvoiceModalType(modalTypeIndex);
                        } else {
                            // Handle unknown type, maybe set a default or show an error
                            console.error('Unknown invoice type:', location.state.type);
                            // You can set a default or handle it as needed
                            setInvoiceModalType(0); // Default to 'taxInvoice' if unknown
                        }

                        await fetchEnums(currentUser, currentToken, location.state.client);
                    }
                } else {
                    navigate('/login');
                }
            } catch (error) {
                // Handle error - maybe navigate to login or show a message
                console.error('Error fetching user data:', error);
            }
            setLoading(false);
        };

        fetchUserData();
        // Include dependencies in the dependency array
    }, [location.state.client, location.state.type]);

    useEffect(() => {
        // Update direction based on the current language
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);

    const fetchEnums = async (user, token, client) => {
        setLoading(true);
        try {
            const [codeIdResponse, accountsResponse, clientItemsResponse] = await Promise.all([
                EnumsService.GetSortCodesByAccountantId(client.accountantId, token),
                EnumsService.GetAccountCardsByCaseId(client.caseId, token),
                EnumsService.GetClientItemsByCaseId(client.caseId, token),
            ]);

            if (codeIdResponse.ok) {
                const codeIdData = await codeIdResponse.json();
                setSortCodeIdOptions(codeIdData);
            }

            if (accountsResponse.ok) {
                const accountsData = await accountsResponse.json();
                setCreditAccountOptions(accountsData);
                setDebitAccountOptions(accountsData);
                setAccountCardEditDownloadOptions(accountsData);
                const accountCardOptions = [{ name: t('AddAccountCard'), value1: 'AddItem' }, ...accountsData];

                setAccountCardOptions(accountCardOptions);
            }

            if (clientItemsResponse.ok) {
                const clientItemsData = await clientItemsResponse.json();
                clientItemsData.unshift({ makat: 'add-item', name: t('AddItem') });
                setClientItems(clientItemsData);
            }

            setIsFirstUserFetched(true);
        } catch (error) {
            console.error('Error fetching enum data:', error);
            toast.error(t('enumFetchError', { errorText: error.message }));
        } finally {
            setLoading(false);
        }
    };

    const handleCloseAddNewAccountCardDocument = () => {
        setFormData((prev) => ({
            ...prev,
            accountCodeId: '',
            accountCaseId: '',
            accountCodeName: '',
        }));
        setAddNewAccountCardDocument(false);
    };

    // useEffect(() => {
    //     if (client) {
    //         if (client?.signature && client?.invoicesNumbers) {
    //             // setInvoiceModalVisible(true); // If you have an invoice modal
    //         } else {
    //             setSignatureReferenceInvoiceModalVisible(true);
    //         }
    //     }
    // }, [client]);

    const handleChange = (field, value) => {

        setFormData((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleItemChange = (index, field, value) => {
        setFormData((prevState) => {
            const updatedItems = [...prevState.invoiceItems];
            updatedItems[index][field] = value;
            return {
                ...prevState,
                invoiceItems: updatedItems,
            };
        });
    };

    const handleAddItem = () => {
        setAddItemFlags((prevFlags) => [...prevFlags, true]);
        const newItem = { makat: '', name: '', quantity: 1, price: 0, discount: 0, isNew: true };
        let newMakat = 1; // Default makat for the first item
        const allMakats = [
            ...clientItems.map(item => parseInt(item.makat, 10) || 0),
            ...formData.invoiceItems.map(item => parseInt(item.makat, 10) || 0)
        ];

        if (allMakats.length > 0) {
            // Find the largest makat among them
            const maxMakat = Math.max(...allMakats);
            newMakat = maxMakat + 1; // Increment the largest makat by 1
        }

        newItem.makat = newMakat.toString();
        setFormData((prevState) => ({
            ...prevState,
            invoiceItems: [...prevState.invoiceItems, newItem],
        }));
    };

    const handleRemoveItem = (index) => {
        setFormData((prevState) => {
            const updatedItems = prevState.invoiceItems.filter((item, idx) => idx !== index);
            return {
                ...prevState,
                invoiceItems: updatedItems,
            };
        });
        setAddItemFlags((prevFlags) => prevFlags.filter((flag, idx) => idx !== index));
    };


    const handleItemChangeMany = (index, updates) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            invoiceItems: prevFormData.invoiceItems.map((item, idx) =>
                idx === index
                    ? { ...item, ...updates }
                    : item
            )
        }));
    };

    const handleInvoiceItem = (index, selectedOption) => {

        if (selectedOption.value === 'add-item') {
            const updatedFlags = [...addItemFlags];
            updatedFlags[index] = true;
            setAddItemFlags(updatedFlags);

            let newMakat = 1;
            const allMakats = [
                ...clientItems.map(item => parseInt(item.makat, 10) || 0),
                ...formData.invoiceItems.map(item => parseInt(item.makat, 10) || 0)
            ];

            if (allMakats.length > 0) {
                // Find the largest makat among them
                const maxMakat = Math.max(...allMakats);
                newMakat = maxMakat + 1; // Increment the largest makat by 1
            }

            // Update the item fields
            // handleItemChange(index, 'makat', newMakat.toString());
            // handleItemChange(index, 'name', '');
            handleItemChangeMany(index, {
                makat: newMakat.toString(),
                name: '',
                itemId: null,
                isNew: true   // Now you can store the "new" state in the item itself
            });
            setAddItemFlags(updatedFlags);

        } else {
            const item = clientItems.find((option) => option.makat === selectedOption.value);

            // handleItemChange(index, 'makat', item.makat);
            // handleItemChange(index, 'name', item.name);
            // handleItemChange(index, 'price', item.price);

            // handleItemChange(index, 'isNew', false);
            handleItemChangeMany(index, {
                makat: item.makat,
                name: item.name,
                price: item.price,
                itemId: item.id,
                isNew: false
            });

            // Reset flag in case it was set
            const updatedFlags = [...addItemFlags];
            updatedFlags[index] = false;
            setAddItemFlags(updatedFlags);
        }
    };

    const handleAccountCodeChange = (selectedOption) => {
        if (selectedOption?.value1 === 'AddItem') {
            // Clear formData fields when adding a new item
            setFormData((prev) => ({
                ...prev,
                invoiceAccountCodeId: cardIdOption,
                invoiceClientCaseId: '',
                invoiceClientName: '',
            }));
            setAddNewAccountCardDocument(true);
        } else {
            // Set formData fields based on selected option
            const accountCard = accountCardOptions.find((option) => option.cardId === selectedOption?.value);

            setFormData((prev) => ({
                ...prev,
                invoiceAccountCodeId: selectedOption?.value || '',
                invoiceClientCaseId: selectedOption?.accountCaseId || '',
                invoiceClientName: accountCard ? accountCard.name : '',
                invoiceClientEmail: accountCard ? accountCard.email : '',
                invoiceClientLocation: accountCard ? accountCard.location : '',
                invoiceClientPhoneNumber: accountCard ? accountCard.phoneNumber : '',

            }));
        }
    };

    const formattedAccountCardOptions = accountCardOptions.map((option) => ({
        value: option.cardId,
        label: option.accountCaseId ? `${option.name} - ${option.accountCaseId}` : `${option.name}`,
        name: option.name,
        accountCaseId: option.accountCaseId || '',
        cardId: option.cardId,
        value1: option.value1,
    }));

    useEffect(() => {
        if (accountCardOptions && accountCardOptions.length > 0) {
            const maxCardId = Math.max(...accountCardOptions.slice(1).map((option) => parseInt(option.cardId, 10)));
            setCardIdOption(maxCardId + 1);
        }
    }, [accountCardOptions]);

    const handleCloseAddItem = (index) => {
        const updatedFlags = [...addItemFlags];
        updatedFlags[index] = false;
        setAddItemFlags(updatedFlags);
    };

    const handleAddMakatAndCheck = (index, value) => {
        // Check if the makat already exists in clientItems
        const existingItem = clientItems.find(item => item.makat === value);

        if (existingItem) {
            // Makat exists, alert the user or handle accordingly
            toast.error(t('MakatAlreadyExists'));
        } else {
            // Update the makat value
            handleItemChange(index, 'makat', value);
        }
    };


    const validateInvoiceData = () => {
        let valid = true;

        if (formData.invoiceClientEmail && !validateEmail(formData.invoiceClientEmail)) {
            toast.error(t('InvalidEmailAddress'));
            valid = false;
        }

        if (formData.invoiceClientPhoneNumber && !validateNumber(formData.invoiceClientPhoneNumber)) {
            toast.error(t('InvalidPhoneNumber'));
            valid = false;
        }

        if (formData.invoiceClientCaseId && !validateNumber(formData.invoiceClientCaseId)) {
            toast.error(t('InvalidCaseId'));
            valid = false;
        }

        if (invoiceModalType !== 6) {
            formData.invoiceItems.forEach((item) => {
                item.quantity = item.quantity?.toString().replace(/\s+/g, '') ?? '';
                item.price = item.price?.toString().replace(/\s+/g, '') ?? '';
                item.discount = item.discount?.toString().replace(/\s+/g, '') ?? '';

                if (item.quantity <= 0) {
                    toast.error(t('invalidQuantity'));
                    valid = false;
                    return;
                }

                if (isNaN(item.price) || !validateAmount(item.price.toString())) {
                    valid = false;
                    toast.error(t('invalidPrice'));
                    return;
                }

                if (isNaN(item.discount) || item.discount < 0 || item.discount > 100) {
                    valid = false;
                    toast.error(t('invalidDiscount'));
                    return;
                }
            });
        }

        if (invoiceModalType === 5 || invoiceModalType === 6) {
            const { checks, creditCards, cash, bankTransactions } = formData.receiptItems;

            const validateReceiptItems = (items, type) => {
                items.forEach((item, index) => {
                    if (!validateAmount(item.amount)) {
                        toast.error(t('InvalidAmountAtPosition', { type: type, index: index + 1 }));
                        valid = false;
                    }
                });
            };

            validateReceiptItems(checks, t('Checks'));
            validateReceiptItems(creditCards, t('Credit Cards'));
            validateReceiptItems(cash, t('Cash'));
            validateReceiptItems(bankTransactions, t('Bank Transactions'));
        }

        return valid;
    };



    const validateNumber = (number) => {
        const re = /^[0-9\b]+$/;
        return re.test(number);
    };

    const validateAmount = (amount) => {
        const re = /^\d+(\.\d{1,2})?$/;
        return re.test(amount);
    };




    const handleCreateDraftInvoice = async () => {
        setLoading(true);

        // Validate the invoice data
        let valid = validateInvoiceData();

        if (!valid) {
            setLoading(false);
            return;
        }

        // Compute total payment amount
        const sumPaymentAmounts = (arr) =>
            Array.isArray(arr) ? arr.reduce((sum, item) => sum + parseFloat(item.amount) || 0, 0) : 0;

        const { checks, creditCards, cash, bankTransactions } = formData.receiptItems;
        let totalPaymentAmount = 0;
        if (invoiceModalType === 5 || invoiceModalType === 6) {
            totalPaymentAmount =
                sumPaymentAmounts(checks) +
                sumPaymentAmounts(creditCards) +
                sumPaymentAmounts(cash) +
                sumPaymentAmounts(bankTransactions) +
                (parseFloat(formData.invoiceWithholdingTax) || 0); // Include invoiceWithholdingTax here
        }

        // Compute total invoice amount
        const VAT_RATE = getVatValue(formData.invoiceDate);
        const totalInvoiceAmount = formData.invoiceItems.reduce((sum, item) => {
            let price = parseFloat(item.price) || 0;
            price *= item.quantity || 1;
            const discountPercent = parseFloat(item.discount) || 0;
            const discountedPrice = price - (price * discountPercent) / 100;
            const priceWithVAT = formData.priceIncludingVAT ? discountedPrice : discountedPrice * (1 + VAT_RATE);
            return sum + priceWithVAT;
        }, 0);

        // Check if total payment amount matches total invoice amount (for Invoice Receipt)
        if (
            invoiceModalType === 5 &&
            (checks?.length > 0 || creditCards?.length > 0 || cash?.length > 0 || bankTransactions?.length > 0) &&
            totalPaymentAmount !== totalInvoiceAmount
        ) {
            toast.error(t('AmountMismatch'));
            setLoading(false);
            return;
        }

        // Build Receipt object if necessary
        let Receipt = {};
        if (invoiceModalType === 5 || invoiceModalType === 6) {
            const withholdingTaxValue = parseFloat(formData.invoiceWithholdingTax) || 0;
            Receipt = {
                WithholdingTax: withholdingTaxValue, // Include invoiceWithholdingTax
                checks: formData.receiptItems.checks,
                creditCards: formData.receiptItems.creditCards,
                cash: formData.receiptItems.cash,
                bankTransactions: formData.receiptItems.bankTransactions,
                Title: InvoiceModalTypes[invoiceModalType].TransactionDocumentTitle,
                IsInvoiceReceipt: invoiceModalType === 5
            };
        }

        // Build invoice object
        const invoice = {
            CaseId: client.caseId,
            AccountantId: client.accountantId,
            Name: client.name,
            Description: formData.invoiceDescription,
            Reference: invoiceReference,
            ClientName: formData.invoiceClientName,
            ClientCaseId: formData.invoiceClientCaseId,
            PhoneNumber: client.phoneNumber,
            Email: client.email,
            Website: client.website,
            Location: client.location,
            ClientLocation: formData.invoiceClientLocation,
            ClientEmail: formData.invoiceClientEmail,
            ClientPhoneNumber: formData.invoiceClientPhoneNumber,
            Date: formData.invoiceDate,
            Signature: invoiceSignature,
            AccountCodeId: formData.invoiceAccountCodeId.toString(),
            Items: invoiceModalType !== 6 ? formData.invoiceItems : undefined, // Exclude Items if invoiceModalType is 6
            PriceIncludingVAT: formData.priceIncludingVAT,
            ...(invoiceModalType === 5 || invoiceModalType === 6 ? Receipt : {}),
            ForceNoTax: formData.forceNoTax,
            // IsInvoiceReceipt:true
        };

        try {
            // Call the API to generate draft invoice
            const pdfBlob = await DocumentService.CreateDraftInvoice(
                InvoiceModalTypes[invoiceModalType].controller,
                InvoiceModalTypes[invoiceModalType].draftEndPoint,
                invoice,
                InvoiceModalTypes[invoiceModalType].type,
                InvoiceModalTypes[invoiceModalType].TransactionDocumentTitle,
                InvoiceModalTypes[invoiceModalType].TransactionDocumentType,
                token
            );

            setDraftPdfBlob(pdfBlob);
            setDraftPdfModalVisible(true);

            toast.success(t('DraftDocumentCreated'));
        } catch (error) {
            console.error('Error creating draft invoice:', error);
            toast.error(t('DraftDocumentDidNotCreated'));
        } finally {
            setLoading(false);
        }
    };


    const updateLocationState = async (newClientData, screen, type) => {

        const updatedClientData = {
            ...location.state.client,
            ...newClientData
        };

        navigate(-1, {
            replace: true, // Ensure the navigation replaces the current history entry
            state: { client: updatedClientData, type }, // Pass updated state
        });
    };
    const handleSaveSignature = async (screen, type) => {
        const Client = await ClientService.GetClient(token, client.caseId);
        updateLocationState(Client, screen, type);

    }

    const saveNewItemsToDatabase = async (invoiceItems) => {
        try {

            // Filter out new items that need to be saved
            const itemsToSave = invoiceItems.filter(item => item.isNew);

            // Map items to the format expected by the API
            const itemsData = itemsToSave.map(item => ({
                makat: item.makat,
                name: item.name,
                caseId: client.caseId,
                price: item.price
                // Include other necessary fields if needed
            }));

            // Call the API to save new items
            const response = await ClientItemsService.AddClientItems(itemsData, token);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            const createdItems = data.items || [];
            // Update clientItems state with the new items
            setClientItems(prevClientItems => [...prevClientItems, ...itemsToSave]);

            // Clear newItems array after saving
            setFormData(prevState => ({
                ...prevState,
                invoiceItems: prevState.invoiceItems.map(item => ({ ...item, isNew: false })),
            }));
            return createdItems;
            // Optionally, update the addItemFlags if needed
        } catch (error) {
            console.error('Error saving new items:', error);
            toast.error(t('ErrorSavingNewItems'));
        }
    };


    const handleCreateInvoice = async (isAuthCompleted = false) => {
        setLoading(true);

        // Validate the invoice data
        let valid = validateInvoiceData();

        if (!valid) {
            setLoading(false);
            return;
        }

        let newlyCreatedItems = [];
        if (formData.invoiceItems.some(item => item.isNew)) {
            newlyCreatedItems = await saveNewItemsToDatabase(formData.invoiceItems);
        }

        const updatedInvoiceItems = formData.invoiceItems.map((localItem) => {
            if (!localItem.isNew) {
                return localItem; // already had ID, so do nothing
            }

            // Find the matching newly created item by 'makat' or some unique field
            const matched = newlyCreatedItems.find(ci => ci.makat === localItem.makat);
            if (matched) {
                return {
                    ...localItem,
                    itemId: matched.id, // real DB ID
                    isNew: false,
                };
            }
            // If not found for some reason, just return the original
            return localItem;
        });

        // 4. Update your formData state so the items have real IDs
        setFormData(prev => ({
            ...prev,
            invoiceItems: updatedInvoiceItems
        }));
        // Check if the month is closed, if necessary
        if (invoiceModalType === 0 || invoiceModalType === 1 || invoiceModalType === 5) {
            try {
                const response = await UserService.CheckIfMonthClosed(client.caseId, formData.reportingMonthDate, token);
                if (response.ok) {
                    const isClosedReportingMonth = await response.json();
                    if (isClosedReportingMonth) {
                        toast.info(t('MonthReportAlreadyClosedTryAgain'));
                        setLoading(false);
                        return;
                    }
                }
            } catch (error) {
                console.error('Error checking if month is closed:', error);
                // Handle error if needed
            }
        }

        // Validate email, phone number, client case ID
        if (formData.invoiceClientEmail && !validateEmail(formData.invoiceClientEmail)) {
            toast.error(t('InvalidEmailAddress'));
            valid = false;
        }

        if (formData.invoiceClientPhoneNumber && !validateNumber(formData.invoiceClientPhoneNumber)) {
            toast.error(t('InvalidPhoneNumber'));
            valid = false;
        }

        if (formData.invoiceClientCaseId && !validateNumber(formData.invoiceClientCaseId)) {
            toast.error(t('InvalidCaseId'));
            valid = false;
        }

        // Validate invoice items
        formData.invoiceItems.forEach(item => {
            item.quantity = item.quantity?.toString().replace(/\s+/g, '') ?? '';
            item.price = item.price?.toString().replace(/\s+/g, '') ?? '';
            item.discount = item.discount?.toString().replace(/\s+/g, '') ?? '';

            if (item.quantity <= 0) {
                toast.error(t('invalidQuantity'));
                valid = false;
                return;
            }

            if (isNaN(item.price) || !validateAmount(item.price.toString())) {
                valid = false;
                toast.error(t('invalidPrice'));
                return;
            }

            if (isNaN(item.discount) || item.discount < 0 || item.discount > 100) {
                valid = false;
                toast.error(t('invalidDiscount'));
                return;
            }
        });

        // Validate amounts in receipt items
        const { checks, creditCards, cash, bankTransactions } = formData.receiptItems;

        if (checks && checks.length > 0) {
            for (const check of checks) {
                if (!validateAmount(check.amount)) {
                    toast.error(t('invalidCheckAmount'));
                    valid = false;
                    break;
                }
            }
        }

        if (creditCards && creditCards.length > 0) {
            for (const card of creditCards) {
                if (!validateAmount(card.amount)) {
                    toast.error(t('invalidCreditCardAmount'));
                    valid = false;
                    break;
                }
            }
        }

        if (cash && cash.length > 0) {
            for (const cashItem of cash) {
                if (!validateAmount(cashItem.amount)) {
                    toast.error(t('invalidCashAmount'));
                    valid = false;
                    break;
                }
            }
        }

        if (bankTransactions && bankTransactions.length > 0) {
            for (const transaction of bankTransactions) {
                if (!validateAmount(transaction.amount)) {
                    toast.error(t('invalidBankTransactionAmount'));
                    valid = false;
                    break;
                }
            }
        }

        if (!valid) {
            setLoading(false);
            return;
        }

        // Compute total payment amount
        const sumPaymentAmounts = (arr) =>
            Array.isArray(arr) ? arr.reduce((sum, item) => sum + parseFloat(item.amount) || 0, 0) : 0;

        let totalPaymentAmount = 0;
        if (invoiceModalType === 5 || invoiceModalType === 6) {
            totalPaymentAmount =
                sumPaymentAmounts(checks) +
                sumPaymentAmounts(creditCards) +
                sumPaymentAmounts(cash) +
                sumPaymentAmounts(bankTransactions) +
                (parseFloat(formData.invoiceWithholdingTax) || 0); // Include invoiceWithholdingTax here
        }

        // Compute total invoice amount

        const totalInvoiceAmount = computeTotalInvoiceAmount();

        // Check if total payment amount matches total invoice amount (for Invoice Receipt)
        if (
            invoiceModalType === 5 &&
            (checks?.length > 0 || creditCards?.length > 0 || cash?.length > 0 || bankTransactions?.length > 0) &&
            totalPaymentAmount !== totalInvoiceAmount
        ) {
            toast.error(t('AmountMismatch'));
            setLoading(false);
            return;
        }


        const isTaxExemptOrNoTax =
            (client?.caseType && Constants.TaxExemptTypes.includes(client.caseType)) ||
            formData.forceNoTax;

        // Compute total invoice amount without VAT
        let totalInvoiceAmountWithoutVAT = 0;
        if (!isTaxExemptOrNoTax) {
            // Then we do the normal logic of "without VAT"
            totalInvoiceAmountWithoutVAT = formData.invoiceItems.reduce((sum, item) => {
                let price = parseFloat(item.price) || 0;
                price *= item.quantity || 1;
                const discountPercent = parseFloat(item.discount) || 0;
                const discountedPrice = price - (price * discountPercent) / 100;
                // If priceIncludingVAT was true, back it out
                return formData.priceIncludingVAT
                    ? sum + (discountedPrice / (1 + VAT_RATE))
                    : sum + discountedPrice;
            }, 0);
        }

        const isNeedToAskAboutConfirmationNumber =
            invoiceModalType === 0 || invoiceModalType === 1 || invoiceModalType === 4 || invoiceModalType === 5;

        // If authentication is not completed and needed, request token
        if (
            !isTaxExemptOrNoTax && // <--- skip if we are exempt/noTax
            !isAuthCompleted &&
            isNeedToAskAboutConfirmationNumber &&
            totalInvoiceAmountWithoutVAT >= getIsraelInvoiceAmountLimit(formData.invoiceDate)
        ) {
            try {
                const tokenData = await TaxAuthorityService.requestTokenClient(client.caseId, token);

                if (tokenData?.authUrl) {
                    setAuthUrl(tokenData.authUrl);
                    // Open the auth modal and return
                    setAuthModalVisible(true);
                    setLoading(false);
                    return;
                }
            } catch (error) {
                console.error('Error fetching token:', error);
                toast.error(t('TaxAuthorityAuthFetchError'));
                setLoading(false);
                return;
            }
        }

        // Build Receipt object if necessary
        let Receipt = {};
        if (invoiceModalType === 5 || invoiceModalType === 6) {
            const withholdingTaxValue = parseFloat(formData.invoiceWithholdingTax) || 0;
            Receipt = {
                WithholdingTax: withholdingTaxValue, // Include invoiceWithholdingTax here
                IsInvoiceReceipt: invoiceModalType === 5,
                Title: InvoiceModalTypes[invoiceModalType].TransactionDocumentTitle,
            };
        }

        // Build the formData object
        const formDataToSend = new FormData();

        // Append fields to formDataToSend
        const newDocument = {
            CaseId: client.caseId,
            AccountantId: client.accountantId,
            Name: client.name,
            Description: formData.invoiceDescription,
            Reference: invoiceReference || "",
            ClientName: formData.invoiceClientName,
            ClientCaseId: formData.invoiceClientCaseId || "",
            PhoneNumber: client.phoneNumber,
            Email: client.email,
            Website: client.website,
            Location: client.location,
            ClientLocation: formData.invoiceClientLocation,
            ClientEmail: formData.invoiceClientEmail,
            ClientPhoneNumber: formData.invoiceClientPhoneNumber,
            Date: new Date(formData.invoiceDate).toISOString(),
            reportingMonthDate: new Date(formData.reportingMonthDate).toISOString(),
            Signature: invoiceSignature || "",
            AccountCodeId: formData.invoiceAccountCodeId.toString(),
            PriceIncludingVAT: formData.priceIncludingVAT,
            ForceNoTax: formData.forceNoTax,
            ...Receipt,
        };

        // Append each field to formData
        for (const key in newDocument) {
            if (newDocument[key] !== undefined && newDocument[key] !== null) {

                formDataToSend.append(key, newDocument[key]);
            }
        }

        // Append Items if necessary
        console.log('updatedInvoiceItems', updatedInvoiceItems);

        if (invoiceModalType !== 6 && updatedInvoiceItems && updatedInvoiceItems.length > 0) {
            const items = updatedInvoiceItems.map(item => ({
                makat: item.makat || "",
                name: item.name || "",
                quantity: item.quantity || 0,
                price: item.price || 0,
                discount: item.discount || 0,
                itemId: item.itemId || 0,
            }));
            formDataToSend.append('Items', JSON.stringify(items));
        }

        // Append receipt items if necessary
        const appendArrayField = (fieldName, dataArray) => {
            if (dataArray && dataArray.length > 0) {
                const formattedData = dataArray.map(item => ({
                    ...item,
                    paymentDate: item.paymentDate ? new Date(item.paymentDate).toISOString() : new Date().toISOString(),
                    amount: parseFloat(item.amount) || 0,
                }));
                formDataToSend.append(fieldName, JSON.stringify(formattedData));
            }
        };

        if (invoiceModalType === 5 || invoiceModalType === 6) {
            appendArrayField('Checks', formData.receiptItems.checks);
            appendArrayField('CreditCards', formData.receiptItems.creditCards);
            appendArrayField('Cash', formData.receiptItems.cash);
            appendArrayField('BankTransactions', formData.receiptItems.bankTransactions);
        }

        try {


            if (formData.documents && formData.documents.length > 0) {
                formData.documents.forEach((document) => {

                    formDataToSend.append('documents', document, document.name);
                });
            } else {
                console.error('No valid documents to upload.');
                // Optionally prompt the user to select files
            }

            setDraftPdfModalVisible(false);
            // Adjust the API call to use the function that accepts FormData
            const pdfBlob = await DocumentService.CreateInvoiceWithDocuments(
                InvoiceModalTypes[invoiceModalType].controller,
                InvoiceModalTypes[invoiceModalType].endPoint,
                formDataToSend,
                client.caseId,
                token
            );


            if (addNewAccountCardDocument) {
                await EnumsService.AddAccountCard(
                    client.caseId,
                    formData.invoiceAccountCodeId.toString(),
                    formData.invoiceClientName,
                    formData.invoiceClientCaseId,
                    'לקוחות',
                    token,
                    formData.invoiceClientPhoneNumber,
                    formData.invoiceClientLocation,
                    formData.invoiceClientEmail
                );
            }
            else {
                await EnumsService.UpdateAccountCardCaseId(client.caseId, formData.invoiceAccountCodeId.toString(), formData.invoiceClientCaseId, formData.invoiceClientEmail, formData.invoiceClientPhoneNumber, formData.invoiceClientLocation, token);
            }
            setPdfBlob(pdfBlob);
            setPdfModalVisible(true);

            toast.success(t('InvoiceCreated'));
            handleInvoiceClose();
        } catch (error) {
            //toChange
            console.error(error);
            const errorMessage = error.message || "";
            const errorCodeMatch = errorMessage.match(/Error Code (\d+):/); // Extracts the number after "Error Code"

            if (errorCodeMatch) {
                const errorCode = errorCodeMatch[1]; // Capture the error code
                toast.error(t(`TaxInvoiceError${errorCode}`)); // Use the dynamic translation key
            } else {
                toast.error(t('InvoiceNotCreated')); // Fallback message if no error code found
            }
        } finally {
            setLoading(false);
        }
    };


    const handleInvoiceClose = async () => {
        setFormData({
            invoiceClientName: '',
            invoiceClientCaseId: '',
            invoiceAccountCodeId: '',
            invoiceClientEmail: '',
            invoiceClientPhoneNumber: '',
            invoiceClientLocation: '',
            invoiceDescription: '',
            priceIncludingVAT: true,
            invoiceDate: new Date(),
            reportingMonthDate: new Date(),
            invoiceItems: [],
            receiptItems: {
                checks: [],
                creditCards: [],
                cash: [],
                bankTransactions: [],
            },
            documents: [],
            invoiceWithholdingTax: '',
            forceNoTax: false,
        });
        setAddItemFlags([]);
        setInvoiceSignature('');
        setInvoiceReference('');
        setAddNewAccountCardDocument(false);
        // setNeedReference(false);
        // setNeedSignature(false);
    };
    const handleReset = () => {
        // ... existing reset logic
        setNeedSignature(false);
        setNeedReference(false);
    };

    useEffect(() => {
        if (client) {

            let hasSignature = client.signature;
            let hasDocNumber = false;

            switch (invoiceModalType) {
                case 0: // taxInvoice
                case 5: // invoiceReceipt
                    hasDocNumber = client.invoicesNumbers && client.invoicesNumbers !== 0;
                    break;
                case 1: // creditNote
                    hasDocNumber = client.invoicesNumbers && client.invoicesNumbers !== 0 && client.refundInvoicesNumbers && client.refundInvoicesNumbers !== 0;
                    break;
                case 2: // quotation
                    hasDocNumber = client.quotationInvoicesNumbers && client.quotationInvoicesNumbers !== 0;
                    break;
                case 3: // order
                    hasDocNumber = client.orderInvoicesNumbers && client.orderInvoicesNumbers !== 0;
                    break;
                case 4: // transactionInvoice
                    hasDocNumber = client.transactionInvoicesNumbers && client.transactionInvoicesNumbers !== 0;
                    break;
                case 6: // receipt
                    hasDocNumber = client.receiptNumbers && client.receiptNumbers !== 0;
                    break;
                default:
                    hasDocNumber = false;
                    break;
            }

            if (hasSignature) {
                if (hasDocNumber) {
                    // Has signature and document number > 0, no need for signature or reference
                    setNeedSignature(false);
                    setNeedReference(false);
                    setSignatureReferenceInvoiceModalVisible(false);
                } else {
                    // Has signature but document number is zero or missing, need to collect reference only
                    setNeedSignature(false);
                    setNeedReference(true);
                    setSignatureReferenceInvoiceModalVisible(true);
                }
            } else {
                if (hasDocNumber) {
                    // No signature but document number > 0, need to collect signature only
                    setNeedSignature(true);
                    setNeedReference(false);
                    setSignatureReferenceInvoiceModalVisible(true);
                } else {
                    // No signature and document number is zero or missing, need to collect both signature and reference
                    setNeedSignature(true);
                    setNeedReference(true);
                    setSignatureReferenceInvoiceModalVisible(true);
                }
            }
        }
    }, [client, invoiceModalType]);

    const handleVatRadioChange = (e) => {
        setEditedItem({ ...editedItem, ["vat"]: parseFloat(e.target.value) });
    };

    // const handleTaxAuthorityAuth = (authUrl) => {
    //     const width = 600;
    //     const height = 600;
    //     const left = window.screen.width / 2 - width / 2;
    //     const top = window.screen.height / 2 - height / 2;
    //     const authWindow = window.open(authUrl, '_blank', `width=${width},height=${height},left=${left},top=${top}`);

    //     const receiveMessage = (event) => {
    //         if (event.origin !== window.location.origin) {
    //             return;
    //         }
    //         if (event.data === 'authComplete') {
    //             window.removeEventListener('message', receiveMessage);
    //             if (authWindow && !authWindow.closed) {
    //                 authWindow.close();
    //             }
    //             checkAuthCompletion();
    //         }
    //     };

    //     window.addEventListener('message', receiveMessage, false);
    // };

    const checkAuthCompletion = async (authWindow) => {
        setLoading(true);
        try {
            let attempts = 0;
            const maxAttempts = 25;
            while (attempts < maxAttempts) {
                // Check if the authWindow was closed by the user
                if (authWindow.closed) {
                    setLoading(false);
                    toast.error(t('Authentication window was closed by the user.'));
                    return;
                }

                const result = await TaxAuthorityService.checkAuthCompletionClient(client.caseId);
                if (result.completed) {
                    setLoading(false);
                    setResumeInvoiceCreation(true);
                    // Close the auth window
                    if (authWindow && !authWindow.closed) {
                        authWindow.close();
                    }
                    return;
                } else {
                    attempts += 1;
                    await new Promise((resolve) => setTimeout(resolve, 3000));
                }
            }
            setLoading(false);
            toast.error(t('tokenFetchError'));
            // Close the auth window on timeout
            if (authWindow && !authWindow.closed) {
                authWindow.close();
            }
        } catch (error) {
            console.error('Error checking auth completion:', error);
            setLoading(false);
            toast.error(t('tokenFetchError'));
            // Close the auth window on error
            if (authWindow && !authWindow.closed) {
                authWindow.close();
            }
        }
    };

    useEffect(() => {
        if (resumeInvoiceCreation) {
            handleCreateInvoice(true); // Proceed with invoice creation
        }
    }, [resumeInvoiceCreation]);

    const handleCloseDraftPdfModal = () => {
        setDraftPdfBlob(null);
        setDraftPdfModalVisible(false);
    };

    const handleClosePdfModal = () => {
        setPdfBlob(null);
        setPdfModalVisible(false);
        if (needReference || needSignature) {
            handleSaveSignature(InvoiceModalTypes[invoiceModalType].screen, InvoiceModalTypes[invoiceModalType].type);
        }
        else {
            updateLocationState(client, InvoiceModalTypes[invoiceModalType].screen, InvoiceModalTypes[invoiceModalType].type);
        }
        handleReset();
    };


    const onDateChange = async (selectedDate) => {
        if (selectedDate) {
            const newDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), 10));
            const response1 = await ClientService.CheckIfMonthClosed(client.caseId, newDate, token);
            if (response1.ok) {
                const isClosedReportingMonth = await response1.json();
                if (isClosedReportingMonth) {
                    toast.info(t('MonthReportAlreadyClosedTryAgain'))
                    // setDatePickerVisibility(false);
                    return
                }
                // Reset the time portion to avoid time zone offset issues
                selectedDate.setHours(0, 0, 0, 0);

                // Format the date manually or use toLocaleDateString for local date representation
                const year = selectedDate.getFullYear();
                const month = selectedDate.getMonth() + 1; // getMonth() is zero-based
                const day = selectedDate.getDate();

                // Ensure two digits for month and day
                const formattedDate = `${year}-${month?.toString().padStart(2, '0')}-${day?.toString().padStart(2, '0')}`;
                handleChange("invoiceDate", new Date(formattedDate));
                handleChange("reportingMonthDate", new Date(formattedDate));
            }
        }
    };

    const handleGoBack = () => {
        updateLocationState(client, InvoiceModalTypes[invoiceModalType].screen, InvoiceModalTypes[invoiceModalType].type)
    }
    const combinedReceiptItems = useMemo(() => {
        const checks = (formData.receiptItems?.checks || []).map((chk, idx) => ({ ...chk, itemType: 'checks', itemTypeIndex: idx }));
        const creditCards = (formData.receiptItems?.creditCards || []).map((cc, idx) => ({ ...cc, itemType: 'creditCards', itemTypeIndex: idx }));
        const cash = (formData.receiptItems?.cash || []).map((c, idx) => ({ ...c, itemType: 'cash', itemTypeIndex: idx }));
        const bankTransactions = (formData.receiptItems?.bankTransactions || []).map((bt, idx) => ({ ...bt, itemType: 'bankTransactions', itemTypeIndex: idx }));
        return [...checks, ...creditCards, ...cash, ...bankTransactions];
    }, [formData.receiptItems]);
    const renderReceiptRows = () => {
        return combinedReceiptItems.map((row, index) => {
            // We detect whether the row is disabled
            const isDisabled = false; // or use the logic from the code: !isRange ? addEditableItem?.isClosedReportingMonth : isClosedReportingMonth


            return (
                <StyledTableRow key={`${row.itemType}-${index}`}>
                    {/* [ADDED] Type */}
                    <StyledTableCell>{t(row.itemType)}</StyledTableCell>

                    {/* [ADDED] CheckNumber / Description */}
                    <StyledTableCell>
                        {row.itemType === 'checks' ? (
                            <TextField
                                fullWidth
                                placeholder={t('CheckNumber')}
                                value={row.description || ''}
                                onChange={(e) =>
                                    handleReceiptItemChange(row.itemType, row.itemTypeIndex, 'description', e.target.value)
                                }
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-box-sizing-border-box ${direction}`,
                                    sx: {
                                        height: '40px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid #304FFF',
                                        },
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                                disabled={isDisabled}
                            />
                        ) : (
                            <TextField
                                fullWidth
                                placeholder={t('Description')}
                                value={row.description || ''}
                                onChange={(e) =>
                                    handleReceiptItemChange(row.itemType, row.itemTypeIndex, 'description', e.target.value)
                                }
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-box-sizing-border-box ${direction}`,
                                    sx: {
                                        height: '40px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid #304FFF',
                                        },
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                                disabled={isDisabled}
                            />
                        )}
                    </StyledTableCell>

                    {/* [ADDED] BankName (checks only) */}
                    <StyledTableCell>
                        {row.itemType === 'checks' ? (
                            <TextField
                                fullWidth
                                type="text"
                                placeholder={t('BankName')}
                                value={row.bankName || ''}
                                onChange={(e) =>
                                    handleReceiptItemChange(row.itemType, row.itemTypeIndex, 'bankName', e.target.value)
                                }
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-box-sizing-border-box ${direction}`,
                                    sx: {
                                        height: '40px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid #304FFF',
                                        },
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                                disabled={isDisabled}
                            />
                        ) : (
                            <span>-</span>
                        )}
                    </StyledTableCell>

                    {/* [ADDED] BankCode (checks only) */}
                    <StyledTableCell>
                        {row.itemType === 'checks' ? (
                            <TextField
                                fullWidth
                                type="number"
                                placeholder={t('BankCode')}
                                value={row.bankCode || ''}
                                onChange={(e) =>
                                    handleReceiptItemChange(row.itemType, row.itemTypeIndex, 'bankCode', e.target.value)
                                }
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-box-sizing-border-box ${direction}`,
                                    sx: {
                                        height: '40px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid #304FFF',
                                        },
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                                disabled={isDisabled}
                            />
                        ) : (
                            <span>-</span>
                        )}
                    </StyledTableCell>

                    {/* [ADDED] BankBranchCode (checks only) */}
                    <StyledTableCell>
                        {row.itemType === 'checks' ? (
                            <TextField
                                fullWidth
                                type="number"
                                placeholder={t('BankBranchCode')}
                                value={row.bankBranchCode || ''}
                                onChange={(e) =>
                                    handleReceiptItemChange(row.itemType, row.itemTypeIndex, 'bankBranchCode', e.target.value)
                                }
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-box-sizing-border-box ${direction}`,
                                    sx: {
                                        height: '40px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid #304FFF',
                                        },
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                                disabled={isDisabled}
                            />
                        ) : (
                            <span>-</span>
                        )}
                    </StyledTableCell>

                    {/* [ADDED] SupplierAccountNumber (checks only) */}
                    <StyledTableCell>
                        {row.itemType === 'checks' ? (
                            <TextField
                                fullWidth
                                type="number"
                                placeholder={t('SupplierAccountNumber')}
                                value={row.supplierAccountNumber || ''}
                                onChange={(e) =>
                                    handleReceiptItemChange(row.itemType, row.itemTypeIndex, 'supplierAccountNumber', e.target.value)
                                }
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-box-sizing-border-box ${direction}`,
                                    sx: {
                                        height: '40px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid #304FFF',
                                        },
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                                disabled={isDisabled}
                            />
                        ) : (
                            <span>-</span>
                        )}
                    </StyledTableCell>

                    {/* [ADDED] Amount */}
                    <StyledTableCell>
                        <TextField
                            fullWidth
                            type="number"
                            placeholder={t('Amount')}
                            value={row.amount || ''}
                            onChange={(e) =>
                                handleReceiptItemChange(row.itemType, row.itemTypeIndex, 'amount', e.target.value)
                            }
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                className: `custom-box-sizing-border-box ${direction}`,
                                sx: {
                                    height: '40px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid #304FFF',
                                    },
                                },
                            }}
                            inputProps={{
                                onWheel: (e) => e.target.blur(),
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                            disabled={isDisabled}
                        />
                    </StyledTableCell>

                    {/* [ADDED] Date */}
                    <StyledTableCell>
                        <CustomDatePicker
                            date={row.paymentDate ? new Date(row.paymentDate) : null}
                            onDateChange={(date) => handleReceiptItemChange(row.itemType, row.itemTypeIndex, 'paymentDate', date)}
                            height="40px"
                            disabled={isDisabled}
                        />
                    </StyledTableCell>

                    {/* [ADDED] Remove button */}
                    <StyledTableCell>

                        {!isDisabled && (
                            <Button
                                style={{ color: 'red', minWidth: '20px' }}
                                onClick={() => handleRemoveReceiptItem(row.itemType, row.itemTypeIndex)}
                            >
                                <DeleteIcon />
                            </Button>
                        )}
                    </StyledTableCell>
                </StyledTableRow>
            );
        });
    };

    return (
        <>
            <HamburgerMenu client={client} />
            <div className="template-page">
                <div className="template-container">
                    <UpperPanelDetails client={client} />
                    <div className={`main-title ${direction}`}>{t(type)}</div>
                    <div
                        className="backIconBackground"
                        style={{ alignSelf: direction === 'ltr' ? 'flex-start' : 'flex-end' }}
                        onClick={handleGoBack}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <path
                                d="M20 21.1667L14.8267 15.9933L20 10.82C20.52 10.3 20.52 9.45999 20 8.93999C19.48 8.41999 18.64 8.41999 18.12 8.93999L12 15.06C11.48 15.58 11.48 16.42 12 16.94L18.12 23.06C18.64 23.58 19.48 23.58 20 23.06C20.5067 22.54 20.52 21.6867 20 21.1667Z"
                                fill="black"
                                fillOpacity="0.9"
                            />
                        </svg>
                    </div>
                    {/* Form Inputs Section */}
                    <div className={`add-client-form ${direction}`}>
                        {/* Invoice Client Name */}
                        {showNoTaxCheckbox && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.forceNoTax}
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                forceNoTax: e.target.checked,
                                                // optional reset priceIncludingVAT
                                                // priceIncludingVAT: e.target.checked ? false : prev.priceIncludingVAT
                                            }))
                                        }
                                        color="primary"
                                    />
                                }
                                label={t('InvoiceWithNoTax')}
                                sx={{
                                    marginTop: '10px',
                                    '.MuiFormControlLabel-label': { fontSize: '18px', fontWeight: 'bold' },
                                }}
                            />
                        )}
                        <div className="form-row">
                            {addNewAccountCardDocument ? (
                                <div className="items_Container">
                                    <button className="clientItemButton" style={{ justifyContent: 'center' }} onClick={handleCloseAddNewAccountCardDocument}>
                                        {t('ReturnToNamesList')}
                                    </button>



                                    <div className="form-row-item" style={{ width: "82%" }} >
                                        <div className="form-row-item-title">
                                            {t('Card Name')}<span className="required-asterisk">*</span>
                                        </div>
                                        <TextField
                                            fullWidth
                                            placeholder={t('Card Name')}
                                            value={formData.invoiceClientName || ''}
                                            onChange={(e) => handleChange('invoiceClientName', e.target.value)}
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true,
                                                className: `custom-box-sizing-border-box ${direction}`,
                                                sx: {
                                                    height: '48px',
                                                    padding: '0 10px',
                                                    borderRadius: '8px',
                                                    background: '#F5F5F6',
                                                    border: '1px solid transparent',
                                                    '&:focus-within': {
                                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                    },
                                                },
                                            }}
                                            inputProps={{
                                                style: {
                                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                                },
                                            }}
                                            required
                                        />
                                    </div >

                                    <div className="form-row-item" >
                                        <div className="form-row-item-title">
                                            {t('Card ID')}<span className="required-asterisk">*</span>
                                        </div>
                                        <TextField
                                            fullWidth
                                            placeholder={t('Card ID')}
                                            value={formData.invoiceAccountCodeId || ''}
                                            type="number"
                                            onBlur={(index, e) => handleAddMakatAndCheck(index, e.target.value)}
                                            onChange={(e) => handleChange('invoiceAccountCodeId', e.target.value)}
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true,
                                                className: `custom-box-sizing-border-box ${direction}`,
                                                sx: {
                                                    height: '48px',
                                                    padding: '0 10px',
                                                    borderRadius: '8px',
                                                    background: '#F5F5F6',
                                                    border: '1px solid transparent',
                                                    '&:focus-within': {
                                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                    },
                                                },
                                            }}
                                            inputProps={{
                                                style: {
                                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                                },
                                            }}
                                            required
                                        />
                                    </div >


                                </div>
                            ) : (
                                <div className="form-row-item">
                                    <div className="form-row-item-title">
                                        {t('Client Name')}
                                        <span className="required-asterisk">*</span>
                                    </div>

                                    <AutocompleteSelect
                                        options={formattedAccountCardOptions}
                                        value={formattedAccountCardOptions.find(option => option.value === formData.accountCodeId) || null}
                                        onChange={(e, newValue) => {
                                            handleAccountCodeChange(newValue);
                                        }}
                                        placeholder={t('Client Name')}
                                        isMultiple={false}
                                        width='100%'
                                    />
                                </div>
                            )}
                            <div className="form-row-item">
                                {t('EnterClientCaseId')}
                                <div className="form-row-item-title">
                                    {computeTotalInvoiceAmount() >= Constants.ISRAEL_INVOICE_AMOUNT_LIMIT && (
                                        <span className="required-asterisk">*</span>
                                    )}
                                </div>
                                <TextField
                                    placeholder={t('EnterClientCaseId')}
                                    value={formData.invoiceClientCaseId}
                                    onChange={(e) => handleChange('invoiceClientCaseId', e.target.value)}
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-box-sizing-border-box ${direction}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                    required
                                />
                            </div>
                        </div>

                        {/* Invoice Client Email */}
                        <div className="form-row">
                            <div className="form-row-item">
                                <div className="form-row-item-title">{t('EnterClientEmail')}</div>
                                <TextField
                                    fullWidth
                                    placeholder={t('EnterClientEmail')}
                                    value={formData.invoiceClientEmail || ''}
                                    onChange={(e) => handleChange('invoiceClientEmail', e.target.value)}
                                    variant="standard"
                                    InputProps={{
                                        className: 'custom-box-sizing-border-box',
                                        disableUnderline: true,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                />
                            </div>
                            <div className="form-row-item">

                                <div className="form-row-item-title">
                                    {t('PhoneNumber')}<span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('PhoneNumber')}
                                    value={formData.invoiceClientPhoneNumber || ''}
                                    onChange={(e) => handleChange('invoiceClientPhoneNumber', e.target.value)}
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-box-sizing-border-box ${direction}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                    required
                                />
                            </div>

                        </div>

                        {/* Invoice Client Location */}
                        <div className="form-row">
                            <div className="form-row-item">
                                <div className="form-row-item-title">{t('EnterClientLocation')}</div>
                                <TextField
                                    fullWidth
                                    placeholder={t('EnterClientLocation')}
                                    value={formData.invoiceClientLocation || ''}
                                    onChange={(e) => handleChange('invoiceClientLocation', e.target.value)}
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-box-sizing-border-box ${direction}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                />
                            </div>

                            {/* Invoice Description */}
                            <div className="form-row-item">
                                <div className="form-row-item-title">{t('enterDescription')}</div>
                                <TextField
                                    fullWidth
                                    placeholder={t('enterDescription')}
                                    value={formData.invoiceDescription || ''}
                                    onChange={(e) => handleChange('invoiceDescription', e.target.value)}
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-box-sizing-border-box ${direction}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-row-item">
                            <div className="form-row-item-title">{t('WithholdingTax')}</div>
                            <TextField
                                fullWidth
                                placeholder={t('WithholdingTax')}
                                type='number'
                                value={formData.invoiceWithholdingTax || ''}
                                onChange={(e) => handleChange('invoiceWithholdingTax', e.target.value)}
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-box-sizing-border-box ${direction}`,
                                    sx: {
                                        height: '48px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                        },
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                    onWheel: (e) => e.target.blur(),

                                }}
                            />
                        </div>
                        {/* Invoice Date */}
                        <div className="form-row-item">
                            <div className="form-row-item-title">
                                {t('Document Date')}<span className="required-asterisk">*</span>
                            </div>
                            <CustomDatePicker
                                date={formData.documentDate ? new Date(formData.documentDate) : null}
                                onDateChange={(date) => {
                                    if (date) {
                                        onDateChange(date);
                                    }
                                }}
                                height="48px"
                            />
                        </div>
                        {
                            (invoiceModalType !== 6) && (!formData.forceNoTax &&
                                !(
                                    client &&
                                    client.caseType &&
                                    Constants.TaxExemptTypes.includes(client.caseType)
                                )) &&
                            <>

                                {/* Price Including VAT */}
                                < div className="form-row">
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-form-control-label-placement"
                                        name="position"
                                        value={formData.priceIncludingVAT.toString()}
                                        onChange={(e) => handleChange('priceIncludingVAT', e.target.value === 'true')}
                                    >
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio />}
                                            label={t('PriceIncludingVAT')}
                                            labelPlacement="top"
                                            checked={formData.priceIncludingVAT}
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio />}
                                            label={t('PriceExcludingVAT')}
                                            labelPlacement="top"
                                            checked={!formData.priceIncludingVAT}
                                        />
                                    </RadioGroup>
                                </div>
                                <h2 style={{ marginBottom: '20px', textAlign: direction === 'ltr' ? 'left' : 'right' }}>{t('InvoiceDetails')}</h2>

                            </>
                        }
                        {
                            invoiceModalType !== 6 &&
                            <>
                                {/* Invoice Items */}
                                < StyledTableContainer component={Paper}>
                                    <StyledTable stickyHeader>
                                        <StyledTableHead>
                                            <TableRow>
                                                <StyledTableCellHeader>{t('ItemList')}</StyledTableCellHeader>
                                                <StyledTableCellHeader>{t('Makat')}</StyledTableCellHeader>
                                                <StyledTableCellHeader>{t('ItemDescription')}</StyledTableCellHeader>
                                                <StyledTableCellHeader>{t('Quantity')}</StyledTableCellHeader>
                                                <StyledTableCellHeader>{t('Price')}</StyledTableCellHeader>
                                                <StyledTableCellHeader>{t('Discount')}</StyledTableCellHeader>
                                                <StyledTableCellHeader>{t('Actions')}</StyledTableCellHeader>
                                            </TableRow>
                                        </StyledTableHead>
                                        <TableBody>
                                            {formData.invoiceItems.map((item, index) => (
                                                <StyledTableRow key={index}>
                                                    {/* 1) ITEM SELECTOR or RETURN BUTTON */}
                                                    <StyledTableCell>
                                                        {addItemFlags[index] ? (
                                                            <button
                                                                className="clientItemButton-small"
                                                                onClick={() => handleCloseAddItem(index)}
                                                            >
                                                                {t('ItemList')}
                                                            </button>
                                                        ) : (
                                                            <AutocompleteSelect
                                                                options={clientItems.map((option) => ({
                                                                    value: option.makat,
                                                                    label: option.name,
                                                                }))}
                                                                selectedValues={
                                                                    clientItems
                                                                        .filter((option) => option.name === item.name)
                                                                        .map((option) => ({
                                                                            value: option.makat,
                                                                            label: option.name,
                                                                        }))[0]
                                                                }
                                                                onChange={(e, newValue) => {
                                                                    handleInvoiceItem(index, newValue);
                                                                }}
                                                                placeholder={t('Item')}
                                                                isMultiple={false}
                                                                width="160px"
                                                            />
                                                        )}
                                                    </StyledTableCell>

                                                    {/* 2) MAKAT */}
                                                    <StyledTableCell>
                                                        {addItemFlags[index] ? (
                                                            <TextField
                                                                fullWidth
                                                                placeholder={t('Makat')}
                                                                value={item.makat}
                                                                type="number"
                                                                onChange={(e) => handleItemChange(index, 'makat', e.target.value)}
                                                                variant="standard"
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    className: `custom-box-sizing-border-box ${direction}`,
                                                                    sx: {
                                                                        height: '40px',
                                                                        padding: '0 10px',
                                                                        borderRadius: '8px',
                                                                        background: '#F5F5F6',
                                                                        border: '1px solid transparent',
                                                                        '&:focus-within': {
                                                                            border: '2px solid #304FFF',
                                                                        },
                                                                    },
                                                                }}
                                                                inputProps={{
                                                                    style: {
                                                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                                                    },
                                                                }}
                                                                required
                                                            />
                                                        ) : (
                                                            <span>{item.makat}</span>
                                                        )}
                                                    </StyledTableCell>

                                                    {/* 3) DESCRIPTION */}
                                                    <StyledTableCell>
                                                        {addItemFlags[index] ? (
                                                            <TextField
                                                                fullWidth
                                                                placeholder={t('ItemDescription')}
                                                                value={item.name}
                                                                onChange={(e) => handleItemChange(index, 'name', e.target.value)}
                                                                variant="standard"
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    className: `custom-box-sizing-border-box ${direction}`,
                                                                    sx: {
                                                                        height: '40px',
                                                                        padding: '0 10px',
                                                                        borderRadius: '8px',
                                                                        background: '#F5F5F6',
                                                                        border: '1px solid transparent',
                                                                        '&:focus-within': {
                                                                            border: '2px solid #304FFF',
                                                                        },
                                                                    },
                                                                }}
                                                                inputProps={{
                                                                    style: {
                                                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                                                    },
                                                                }}
                                                                required
                                                            />
                                                        ) : (
                                                            <span>{item.name}</span>
                                                        )}
                                                    </StyledTableCell>

                                                    {/* 4) QUANTITY */}
                                                    <StyledTableCell>
                                                        <TextField
                                                            fullWidth
                                                            placeholder={t('Quantity')}
                                                            value={item.quantity}
                                                            type="number"
                                                            onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                                                            variant="standard"
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                className: `custom-box-sizing-border-box ${direction}`,
                                                                sx: {
                                                                    height: '40px',
                                                                    padding: '0 10px',
                                                                    borderRadius: '8px',
                                                                    background: '#F5F5F6',
                                                                    border: '1px solid transparent',
                                                                    '&:focus-within': {
                                                                        border: '2px solid #304FFF',
                                                                    },
                                                                },
                                                            }}
                                                            inputProps={{
                                                                style: {
                                                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                                                },
                                                            }}
                                                            required
                                                        />
                                                    </StyledTableCell>

                                                    {/* 5) PRICE */}
                                                    <StyledTableCell>
                                                        <TextField
                                                            fullWidth
                                                            placeholder={t('Price')}
                                                            value={item.price}
                                                            type="number"
                                                            onChange={(e) => handleItemChange(index, 'price', e.target.value)}
                                                            variant="standard"
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                className: `custom-box-sizing-border-box ${direction}`,
                                                                sx: {
                                                                    height: '40px',
                                                                    padding: '0 10px',
                                                                    borderRadius: '8px',
                                                                    background: '#F5F5F6',
                                                                    border: '1px solid transparent',
                                                                    '&:focus-within': {
                                                                        border: '2px solid #304FFF',
                                                                    },
                                                                },
                                                            }}
                                                            inputProps={{
                                                                onWheel: (e) => e.target.blur(),
                                                                style: {
                                                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                                                },
                                                            }}
                                                            required
                                                        />
                                                    </StyledTableCell>

                                                    {/* 6) DISCOUNT */}
                                                    <StyledTableCell>
                                                        <TextField
                                                            fullWidth
                                                            placeholder={t('Discount')}
                                                            value={item.discount}
                                                            type="number"
                                                            onChange={(e) => handleItemChange(index, 'discount', e.target.value)}
                                                            variant="standard"
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                className: `custom-box-sizing-border-box ${direction}`,
                                                                sx: {
                                                                    height: '40px',
                                                                    padding: '0 10px',
                                                                    borderRadius: '8px',
                                                                    background: '#F5F5F6',
                                                                    border: '1px solid transparent',
                                                                    '&:focus-within': {
                                                                        border: '2px solid #304FFF',
                                                                    },
                                                                },
                                                            }}
                                                            inputProps={{
                                                                min: 0,
                                                                max: 100,
                                                                style: {
                                                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                                                },
                                                            }}
                                                            required
                                                        />
                                                    </StyledTableCell>

                                                    {/* 7) ACTIONS (REMOVE) */}
                                                    <StyledTableCell>
                                                        <IconButton
                                                            color="error"
                                                            onClick={() => handleRemoveItem(index)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </StyledTable>
                                </StyledTableContainer>

                                <button className='clientItemButton' style={{ width: '10%' }} onClick={handleAddItem}>
                                    {t('AddItem')}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M15.9993 2.66667C8.63935 2.66667 2.66602 8.64001 2.66602 16C2.66602 23.36 8.63935 29.3333 15.9993 29.3333C23.3594 29.3333 29.3327 23.36 29.3327 16C29.3327 8.64001 23.3594 2.66667 15.9993 2.66667ZM22.666 17.3333H17.3327V22.6667H14.666V17.3333H9.33268V14.6667H14.666V9.33334H17.3327V14.6667H22.666V17.3333Z" fill="#F9AA2A" />
                                    </svg>
                                </button>
                            </>
                        }
                        {/* Divider */}
                        <div
                            style={{
                                borderTop: '1px solid black',
                                margin: '20px 0',
                                width: '100%',
                            }}
                        ></div>
                        {/* Receipt Items Section */}
                        {(invoiceModalType === 5 || invoiceModalType === 6) && (
                            <>
                                <h2 style={{ margin: '30px 0 10px', textAlign: direction === 'ltr' ? 'left' : 'right' }}>
                                    {t('ReceiptItems')}
                                </h2>
                                <div style={{ display: 'flex', gap: '10px', marginBottom: '10px', flexWrap: 'wrap' }}>

                                    <Button
                                        onClick={() => handleAddReceiptItem('checks')}
                                        sx={{
                                            background: '#304FFF',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '6px',
                                            '&:hover': {
                                                background: '#1E40FF',
                                            }
                                        }}
                                    >
                                        {t('AddCheck')}
                                    </Button>
                                    <Button
                                        onClick={() => handleAddReceiptItem('creditCards')}
                                        sx={{
                                            background: '#304FFF',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '6px',
                                            '&:hover': {
                                                background: '#1E40FF',
                                            }
                                        }}
                                    >
                                        {t('AddCreditCard')}
                                    </Button>
                                    <Button
                                        onClick={() => handleAddReceiptItem('cash')}
                                        sx={{
                                            background: '#304FFF',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '6px',
                                            '&:hover': {
                                                background: '#1E40FF',
                                            }
                                        }}
                                    >
                                        {t('AddCash')}
                                    </Button>
                                    <Button
                                        onClick={() => handleAddReceiptItem('bankTransactions')}
                                        sx={{
                                            background: '#304FFF',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '6px',
                                            '&:hover': {
                                                background: '#1E40FF',
                                            }
                                        }}
                                    >
                                        {t('AddBankTransfer')}
                                    </Button>
                                </div>

                                <StyledTableContainer component={Paper} style={{ width: '100%' }}>
                                    <StyledTable stickyHeader>
                                        <StyledTableHead>
                                            <TableRow>
                                                <StyledTableCellHeader>{t('Type')}</StyledTableCellHeader>
                                                <StyledTableCellHeader>
                                                    {t('CheckNumber')} / {t('Description')}
                                                </StyledTableCellHeader>
                                                <StyledTableCellHeader>{t('BankName')}</StyledTableCellHeader>
                                                <StyledTableCellHeader>{t('BankCode')}</StyledTableCellHeader>
                                                <StyledTableCellHeader>{t('BankBranchCode')}</StyledTableCellHeader>
                                                <StyledTableCellHeader>{t('BankAccountNumber')}</StyledTableCellHeader>
                                                <StyledTableCellHeader>{t('Amount')}</StyledTableCellHeader>
                                                <StyledTableCellHeader>{t('DuePaymentDate')}</StyledTableCellHeader>
                                                <StyledTableCellHeader>{t('Actions')}</StyledTableCellHeader>
                                            </TableRow>
                                        </StyledTableHead>
                                        <TableBody>{renderReceiptRows()}</TableBody>
                                    </StyledTable>
                                </StyledTableContainer>
                            </>
                        )}

                        {/* Buttons */}
                        <div className="form-row">
                            {/* <div style={{ marginTop: '10px' }} className={`newDocument-file-upload-container `}>
                                <input
                                    type="file"
                                    id="file"
                                    className="input-file"
                                    multiple
                                    onChange={handleDocumentUpload}
                                />
                                <label htmlFor="file" className="newDocument-upload-label">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="upload-icon" viewBox="0 0 58 58" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M29 38.0625C30.001 38.0625 30.8125 37.251 30.8125 36.25V9.73298L34.874 14.4712C35.5253 15.2313 36.6696 15.3193 37.4296 14.6678C38.1896 14.0164 38.2776 12.8721 37.6261 12.1121L30.3761 3.65378C30.0319 3.25204 29.529 3.02084 29 3.02084C28.471 3.02084 27.9681 3.25204 27.624 3.65378L20.3739 12.1121C19.7224 12.8721 19.8104 14.0164 20.5705 14.6678C21.3305 15.3193 22.4747 15.2313 23.1262 14.4712L27.1875 9.73298V36.25C27.1875 37.251 27.999 38.0625 29 38.0625Z" fill="#F9AA2A" />
                                        <path d="M38.6673 21.75C36.9703 21.75 36.1218 21.75 35.5124 22.1572C35.2485 22.3336 35.0218 22.5602 34.8456 22.8241C34.4381 23.4336 34.4381 24.2822 34.4381 25.9792V36.25C34.4381 39.2529 32.0038 41.6875 29.0006 41.6875C25.9977 41.6875 23.5632 39.2529 23.5632 36.25V25.9792C23.5632 24.2822 23.5632 23.4335 23.1559 22.824C22.9796 22.5601 22.7531 22.3336 22.4893 22.1573C21.8797 21.75 21.0311 21.75 19.334 21.75C12.4986 21.75 9.08094 21.75 6.95746 23.8735C4.83398 25.9971 4.83398 29.4142 4.83398 36.2495V38.6662C4.83398 45.5015 4.83398 48.9191 6.95746 51.0427C9.08094 53.1662 12.4986 53.1662 19.334 53.1662H38.6673C45.5026 53.1662 48.9203 53.1662 51.0438 51.0427C53.1673 48.9191 53.1673 45.5015 53.1673 38.6662V36.2495C53.1673 29.4142 53.1673 25.9971 51.0438 23.8735C48.9203 21.75 45.5026 21.75 38.6673 21.75Z" fill="#F9AA2A" />
                                    </svg>
                                    <span className="upload-text">
                                        {documents?.length > 0 ? documents.map(doc => doc.name).join(', ') : t('uploadDocument')}
                                    </span>
                                </label>
                            </div> */}
                            <button
                                className={`addClient-button ${!allClientItemFieldsFilled ? 'button-disabled' : ''}`}
                                style={{ width: '524px', margin: '20px' }}
                                onClick={allClientItemFieldsFilled ? handleCreateDraftInvoice : null}
                                disabled={!allClientItemFieldsFilled}
                            >
                                <span>{t('CreateDraft')}{" "}{t(type)}</span>
                            </button>

                            <button
                                className={`createInvoice-amount-button `}
                                style={{ width: '524px', margin: '20px' }}
                            >
                                <span>
                                    {invoiceModalType === 6
                                        ? `${t('TotalReceiptAmount')}: ${beautifyNumber(totalReceiptAmount)}`
                                        : `${t('TotalInvoiceAmount')}: ${beautifyNumber(computeTotalInvoiceAmount())}`}
                                </span>
                            </button>
                        </div>
                    </div>

                    {/* Signature Reference Modal */}
                    <SignatureReferenceModal
                        isVisible={signatureReferenceInvoiceModalVisible}
                        onClose={handleSignatureReferenceClose}
                        onSave={handleSignatureReferenceSave}
                        invoiceReference={invoiceReference}
                        setInvoiceReference={setInvoiceReference}
                        invoiceSignature={invoiceSignature}
                        setInvoiceSignature={setInvoiceSignature}
                        direction={direction}
                        needSignature={needSignature}
                        needReference={needReference}
                        type={t(type)}
                    />

                    {/* Draft PDF Modal */}
                    <Modal
                        open={draftPdfModalVisible}
                        onClose={handleCloseDraftPdfModal}
                        aria-labelledby="draft-invoice-modal-title"
                        aria-describedby="draft-invoice-modal-description"
                    >
                        <div className="draft-pdf-modal-container">
                            {/* PDF preview */}
                            {draftPdfBlob && (
                                <iframe
                                    src={URL.createObjectURL(draftPdfBlob)}
                                    width="100%"
                                    height="100%"
                                    style={{ border: 'none' }}
                                    aria-label="Draft Invoice PDF"
                                />
                            )}

                            {/* Buttons */}
                            <div className="draft-pdf-button-container">
                                <button
                                    className={`create-invoice-button ${!allClientItemFieldsFilled ? 'button-disabled' : ''}`}
                                    onClick={allClientItemFieldsFilled ? () => handleCreateInvoice() : null}
                                    disabled={!allClientItemFieldsFilled}
                                >
                                    <span>{t(type)}</span>
                                </button>

                                <button className="close-invoice-button" onClick={handleCloseDraftPdfModal}>
                                    {t('close')}
                                </button>
                            </div>
                        </div>
                    </Modal>

                    {/* PDF Modal */}
                    <Modal
                        open={PdfModalVisible}
                        onClose={handleClosePdfModal}
                        aria-labelledby="draft-invoice-modal-title"
                        aria-describedby="draft-invoice-modal-description"
                    >
                        <div className="draft-pdf-modal-container">
                            {/* PDF preview */}
                            {PdfBlob && (
                                <iframe
                                    src={URL.createObjectURL(PdfBlob)}
                                    width="100%"
                                    height="100%"
                                    style={{ border: 'none' }}
                                    aria-label="Draft Invoice PDF"
                                />
                            )}

                            {/* Buttons */}
                            <div className="draft-pdf-button-container">
                                <button className="close-invoice-button" onClick={handleClosePdfModal}>
                                    {t('close')}
                                </button>
                            </div>
                        </div>
                    </Modal>

                </div>
                <ToastContainer />
            </div >
            {loading && (
                <div className="loader-container">
                    <PuffLoader
                        size={100}        // Larger size for better visibility
                        loading={loading}
                    />
                </div>
            )
            }
        </>
    );


};

export default CreateReceipt;
