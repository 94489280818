import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import {
    Modal,
    Box,
    Typography,
    Button,
    Tooltip,
    Skeleton,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { useTranslation } from 'react-i18next';
import { beautifyNumber } from '../../../Utils/FormatNumber';
import CustomMonthYearIndividualDatePicker from '../CustomDates/CustomMonthYearIndividualDatePicker/CustomMonthYearIndividualDatePicker';
import ReportsService from '../../../Services/reports.service';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
dayjs.extend(utc);

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
};

const FinancialModal = ({ client, startDate: propStartDate, endDate: propEndDate }) => {
    const { i18n, t } = useTranslation();
    const direction = i18n.dir();

    // Local date states
    const [startDate, setStartDate] = useState(
        propStartDate ? dayjs.utc(propStartDate) : dayjs().startOf('year')
    );
    const [endDate, setEndDate] = useState(
        propEndDate ? dayjs.utc(propEndDate).endOf('day') : dayjs().endOf('month')
    );

    // Local loading
    const [localLoading, setLocalLoading] = useState(false);

    // Data from the API
    const [data, setData] = useState({
        profit: 0,
        incomes: 0,
        estimatedTax: 0,
        recommendedAdvancePercentage: 0,
    });

    // Modal state
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Run-once ref to skip second mount in dev mode under React.StrictMode
    const didRunRef = useRef(false);
    const lastStartDateRef = useRef(propStartDate);
    const lastEndDateRef = useRef(propEndDate);
    /**
     * Utility: fetch data using the current or passed start/end.
     */
    const fetchData = async (start, end) => {
        if (!client?.caseId || !client?.accountantId) return;

        setLocalLoading(true);
        try {
            const response = await ReportsService.calculateTax(
                client.caseId,
                client.accountantId,
                start.format('YYYY-MM-DD'),
                end.format('YYYY-MM-DD')
            );
            setData(response);
        } catch (error) {
            console.error('Failed to fetch tax data:', error);
        } finally {
            setLocalLoading(false);
        }
    };

    /**
     * Effect #1: On mount (and if client changes), but only once (due to didRunRef).
     * This prevents the double call in dev mode under StrictMode.
     */

    useEffect(() => {
        // Prevent second call in StrictMode unless props changed
        const hasStartChanged = propStartDate !== lastStartDateRef.current;
        const hasEndChanged = propEndDate !== lastEndDateRef.current;

        if (didRunRef.current && !hasStartChanged && !hasEndChanged) return;
        didRunRef.current = true;

        console.log('Fetching data on mount or prop change:', client);

        if (client?.caseId && propStartDate && propEndDate) {
            const start = dayjs.utc(propStartDate);
            const end = dayjs.utc(propEndDate).endOf('day');
            fetchData(start, end);
        }

        // update last seen values
        lastStartDateRef.current = propStartDate;
        lastEndDateRef.current = propEndDate;
        setStartDate(dayjs.utc(propStartDate))
        setEndDate(dayjs.utc(propEndDate).endOf('day'))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client?.caseId, client?.accountantId, propStartDate, propEndDate]);

    /**
     * Effect #2: If propStartDate/propEndDate changes, sync local states and fetch once.
     */
    useEffect(() => {
        let newStart = startDate;
        let newEnd = endDate;
        let changed = false;

        if (propStartDate) {
            const propParsed = dayjs.utc(propStartDate);
            if (!propParsed.isSame(startDate, 'day')) {
                newStart = propParsed;
                changed = true;
            }
        }
        if (propEndDate) {
            const propParsedEnd = dayjs.utc(propEndDate).endOf('day');
            if (!propParsedEnd.isSame(endDate, 'day')) {
                newEnd = propParsedEnd;
                changed = true;
            }
        }

        // if (changed) {
        //   setStartDate(newStart);
        //   setEndDate(newEnd);
        //   if (client?.caseId && client?.accountantId) {
        //     fetchData(newStart, newEnd);
        //   }
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propStartDate, propEndDate]);

    /**
     * Date pickers: if user manually picks a new date, we update local states & fetch immediately.
     */
    const handleStartDateChangeNew = (newValue, type) => {
        const newDate = new Date(newValue);
        let updatedDate;
        if (type === 'year') {
            updatedDate = new Date(Date.UTC(newDate.getFullYear(), startDate.toDate().getMonth(), 10));
        } else if (type === 'month') {
            updatedDate = new Date(Date.UTC(startDate.toDate().getFullYear(), newDate.getMonth(), 10));
        }
        const dayjsDate = dayjs(updatedDate);
        setStartDate(dayjsDate);
        fetchData(dayjsDate, endDate);
    };

    const handleEndDateChangeNew = (newValue, type) => {
        const newDate = new Date(newValue);
        let updatedDate;
        if (type === 'year') {
            updatedDate = new Date(Date.UTC(newDate.getFullYear(), endDate.toDate().getMonth(), 10));
        } else if (type === 'month') {
            updatedDate = new Date(Date.UTC(endDate.toDate().getFullYear(), newDate.getMonth(), 10));
        }
        const dayjsDate = dayjs(updatedDate);
        setEndDate(dayjsDate);
        fetchData(startDate, dayjsDate);
    };

    // Derived values
    const existingAdvancePercentage = Math.max(
        0,
        client?.incomeTaxAdvancePercentage
    ).toFixed(1);
    const recommendedAdvancePercentage = Math.max(
        0,
        data.recommendedAdvancePercentage * 100
    ).toFixed(1);

    const showWarning = existingAdvancePercentage !== recommendedAdvancePercentage;

    return (
        <div className={direction}>
            <Tooltip title={t('RecommendedAdvancePercentage')}>
                <div
                    className="DataSummary-card incomeExpense-Hover"
                    style={{ width: '100%', maxWidth: '240px', cursor: 'pointer' }}
                    onClick={handleOpen}
                >
                    {localLoading ? (
                        <>
                            {/* Same layout, but with Skeletons */}
                            <div style={{ display: 'flex', width: 50, height: 50, margin: '0 auto' }}>
                                <Skeleton variant="circular" width={50} height={50} />
                            </div>

                            <div
                                className="DataSummary-card-titleData-container"
                                style={{ textAlign: 'center', marginTop: 10 }}
                            >
                                <div className="DataSummary-card-title">
                                    <Skeleton variant="text" width={180} height={24} />
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#ccc',
                                        marginTop: 5,
                                        justifyContent: 'center',
                                        gap: 8,
                                    }}
                                >
                                    <Skeleton variant="circular" width={20} height={20} />
                                    <Skeleton variant="text" width={150} height={20} />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div style={{ display: 'flex', width: 120, height: 120, margin: '0 auto' }}>
                                <CircularProgressbar
                                    value={recommendedAdvancePercentage}
                                    text={`${recommendedAdvancePercentage}%`}
                                    styles={buildStyles({
                                        textColor: '#333',
                                        textSize: '20px',
                                    })}
                                />
                            </div>

                            <div
                                className="DataSummary-card-titleData-container"
                                style={{ textAlign: 'center', marginTop: 10 }}
                            >
                                <div className="DataSummary-card-title">
                                    {t('RecommendedAdvancePercentage')}
                                </div>

                                {/* Conditional warning or success message */}
                                {showWarning ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: 'red',
                                            marginTop: 5,
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <WarningAmberIcon sx={{ marginRight: 1 }} />
                                        <Typography variant="body2">
                                            {t('WarningValuesMismatchBetweenRecommendedAndExistingPercentage')}
                                        </Typography>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: 'green',
                                            marginTop: 5,
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <CheckCircleIcon sx={{ marginRight: 1 }} />
                                        <Typography variant="body2">
                                            {t('ValuesMatchBetweenRecommendedAndExistingPercentage')}
                                        </Typography>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </Tooltip>

            {/* Modal */}
            <Modal open={open} onClose={handleClose}>
                <Box sx={modalStyle} className={direction}>
                    {showWarning && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 2,
                                color: 'error.main',
                            }}
                        >
                            <WarningAmberIcon />
                            <Typography variant="body2" color="error">
                                {t('WarningValuesMismatchBetweenRecommendedAndExistingPercentage')}
                            </Typography>
                        </Box>
                    )}

                    {/* Start Date */}
                    <Typography variant="body1" gutterBottom>
                        {t('StartDate')}
                    </Typography>
                    <CustomMonthYearIndividualDatePicker
                        year={startDate}
                        month={startDate}
                        onYearChange={(newYear) => handleStartDateChangeNew(newYear, 'year')}
                        onMonthChange={(newMonth) => handleStartDateChangeNew(newMonth, 'month')}
                        labelYear={t('StartYear')}
                        labelMonth={t('StartMonth')}
                        height="48px"
                        widthOuter="270px"
                        widthInner="120px"
                        flip={true}
                    />

                    {/* End Date */}
                    <Typography variant="body1" gutterBottom mt={2}>
                        {t('EndDate')}
                    </Typography>
                    <CustomMonthYearIndividualDatePicker
                        year={endDate}
                        month={endDate}
                        onYearChange={(newYear) => handleEndDateChangeNew(newYear, 'year')}
                        onMonthChange={(newMonth) => handleEndDateChangeNew(newMonth, 'month')}
                        labelYear={t('EndYear')}
                        labelMonth={t('EndMonth')}
                        height="48px"
                        widthOuter="270px"
                        widthInner="120px"
                        flip={true}
                    />

                    {/* Existing vs. Recommended Circulars */}
                    <div style={{ display: 'flex', justifyContent: 'center', gap: 20, margin: '20px auto' }}>
                        <div style={{ width: 150, height: 150 }}>
                            <CircularProgressbar
                                value={Math.max(0, client?.incomeTaxAdvancePercentage)}
                                text={`${Math.max(0, client?.incomeTaxAdvancePercentage).toFixed(1)}%`}
                                styles={buildStyles({
                                    pathColor: '#4caf50',
                                    textColor: '#333',
                                })}
                            />
                            <Typography variant="body1" textAlign="center" mt={2}>
                                {t('ExistingAdvancePercentage')}
                            </Typography>
                        </div>
                        <div style={{ width: 150, height: 150 }}>
                            <CircularProgressbar
                                value={Math.max(0, data.recommendedAdvancePercentage * 100)}
                                text={`${Math.max(0, data.recommendedAdvancePercentage * 100).toFixed(1)}%`}
                                styles={buildStyles({
                                    pathColor: '#4caf50',
                                    textColor: '#333',
                                })}
                            />
                            <Typography variant="body1" textAlign="center" mt={2}>
                                {t('RecommendedAdvancePercentage')}
                            </Typography>
                        </div>
                    </div>

                    {/* Financial data boxes */}
                    <Box mt={4} sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 10 }}>
                        {/* Profit */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                backgroundColor: '#f5f5f5',
                                padding: '16px',
                                borderRadius: '8px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Typography variant="h6" color="primary">
                                {t('MonthlyFinancialProfit')}:
                            </Typography>
                            <Typography variant="h5" color="text.secondary" fontWeight="bold">
                                {beautifyNumber(data.profit)}
                            </Typography>
                        </Box>

                        {/* Income */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                backgroundColor: '#f5f5f5',
                                padding: '16px',
                                borderRadius: '8px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Typography variant="h6" color="primary">
                                {t('MonthlyIncome')}:
                            </Typography>
                            <Typography variant="h5" color="text.secondary" fontWeight="bold">
                                {beautifyNumber(data.incomes)}
                            </Typography>
                        </Box>

                        {/* Estimated Tax */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                backgroundColor: '#f5f5f5',
                                padding: '16px',
                                borderRadius: '8px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Typography variant="h6" color="primary">
                                {t('EstimatedMonthlyTax')}:
                            </Typography>
                            <Typography variant="h5" color="text.secondary" fontWeight="bold">
                                {beautifyNumber(data.estimatedTax)}
                            </Typography>
                        </Box>
                    </Box>

                    {/* Close Button */}
                    <Box mt={4} textAlign="center">
                        <Button variant="outlined" onClick={handleClose}>
                            {t('Close')}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default FinancialModal;
