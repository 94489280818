// App.js
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Context Providers
import { SignalRProvider } from './Contexts/signalR.js';
import { FileProvider } from './Contexts/FileContext.js';
import { PermissionsProvider } from './Contexts/Permissions.js';
import { ChatProvider } from './Contexts/ChatProvider.js';
// Components
import Login from './Components/Login/Login.jsx';
import Main from './Components/Main/Main.jsx';
import ClientDetails from './Components/ClientDetails/ClientDetails.jsx';
import AccountantDetails from './Components/AccountantDetails/AccountantDetails.jsx';
import Document from './Components/Document/Document.jsx';
import TransactionDocuments from './Components/TransactionDocuments/TransactionDocuments.jsx';
import OtherDocument from './Components/OtherDocuments/OtherDocument.jsx';
import Checks from './Components/Checks/Check.js';
import PaymentAcceptance from './Components/PaymentAcceptance/PaymentAcceptance.jsx';
import SubAccountant from './Components/SubAccountants/SubAccountants.jsx';
import ErrorPage from './Components/ErrorPage/ErrorPage.js';
import ProfitAndLossReport from './Components/ProfitAndLossReport/ProfitAndLossReport.jsx';
import SortCodeReport from './Components/SortCodeReport/SortCodeReport.jsx';
import CollectionPage from './Components/CollectionPage/CollectionPage.jsx';
import ReportTracking from './Components/ReportTracking/ReportTracking.jsx';
import LedgerReport from './Components/LedgerReport/LedgerReport.jsx';
import AccountCardDashboard from './Components/AccountCardDashboard/AccountCardDashboard.jsx';
import AccountCardsTable from './Components/AccountCardsTable/AccountCardsTable.jsx';
import JournalEntry from './Components/JournalEntryies/JournalEntry.jsx';
import AddClient from './Components/Widgets/AddClient/AddClient.jsx';
import CreateInvoice from './Components/Widgets/CreateInvoice/CreateInvoice.jsx';
import CreateReceipt from './Components/Widgets/CreateInvoice/CreateReceipt.jsx';
import BankMatching from './Components/BankMatching/BankMatching.jsx';
import CardMatching from './Components/CardMatching/CardMatching.jsx';
import Assignments from './Components/Assignments/Assignments';
import ProtectedRoute from './Utils/ProtectedRoute.jsx';
import Unauthorized from './Components/Unauthorized/Unauthorized.jsx';
import BankPages from './Components/BankPages/BankPages.jsx';
import HelpIcon from './Components/Widgets/HelpIcon/HelpIcon.js';
// Styles and Themes
import './Services/translation.service';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeflex/primeflex.css';
import AuthService from './Services/auth.service.js';
import { StyledEngineProvider } from '@mui/material/styles';
import { PrimeReactProvider } from 'primereact/api';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import { arSD, heIL, enUS } from '@mui/x-data-grid/locales';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { NavHistoryProvider } from './Contexts/NavHistoryContext.js';
import 'react-notifications/lib/notifications.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Assets from './Components/Assets/Assets.jsx';
import PrivacyPolicyModal from './Components/Widgets/PrivacyPolicyModal/PrivacyPolicyModal.js';
import PolicyService from './Services/policy.service.js';
import ClientItems from './Components/ClientItems/ClientItems.jsx';
function App() {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'he');
  const [policyAccepted, setPolicyAccepted] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const initChatConf = async () => {
  //   await ChatService.InitChat();

  // }
  useEffect(() => {
    // Check for saved language in localStorage
    const currentLanguage = localStorage.getItem('language') || 'he'; // Default to 'en' if no language is stored

    i18n.changeLanguage(currentLanguage);
    // initChatConf();
  }, []);

  useEffect(() => {
    const currentLanguage = localStorage.getItem('language') || 'he';
    setSelectedLanguage(currentLanguage);
  }, [i18n.language]);

  useEffect(() => {
    // Check if user is logged in
    const checkUser = async () => {
      const user = await AuthService.getCurrentUserTypeId();
      setIsLoggedIn(!!user); // Set logged-in state
    };

    checkUser();
  }, []);

  useEffect(() => {
    // Fetch policy acceptance status
    const checkPolicy = async () => {
      if (!isLoggedIn) {
        setLoading(false);
        return; // No need to check policy if user isn't logged in
      }
      const accepted = await PolicyService.hasAcceptedPolicy();
      
      setPolicyAccepted(accepted);
      setLoading(false);
    };

    checkPolicy();
  }, [isLoggedIn]);


  const existingTheme = useTheme();

  const theme = useMemo(() => {
    let locale;
    let direction = 'ltr'; // Default direction

    switch (selectedLanguage) {
      case 'ar':
        locale = arSD;
        direction = 'rtl';
        break;
      case 'he':
        locale = heIL;
        direction = 'rtl';
        break;
      case 'en':
      default:
        locale = enUS;
        direction = 'ltr';
        break;
    }

    return createTheme({}, locale, existingTheme, { direction });
  }, [selectedLanguage, existingTheme]);

  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const cacheLtr = createCache({
    key: 'muiltr',
    stylisPlugins: [prefixer] // Removed rtlPlugin for strict LTR styling
  });
  const cache = useMemo(
    () =>
      createCache({
        key: selectedLanguage === 'en' ? 'muiltr' : 'muirtl',
        stylisPlugins:
          selectedLanguage === 'en' ? [prefixer] : [prefixer, rtlPlugin],
      }),
    [selectedLanguage]
  );




  return (
    <ChatProvider>
      <SignalRProvider>
        <FileProvider>
          <PrimeReactProvider>
            <StyledEngineProvider injectFirst>
              <CacheProvider value={cache}>
                <ThemeProvider theme={theme}>

                  <Router>
                    <NavHistoryProvider>
                      <PermissionsProvider>
                        {isLoggedIn && !policyAccepted && !loading && (
                          <PrivacyPolicyModal visible={!policyAccepted} onAccept={() => setPolicyAccepted(true)} />
                        )}
                        <div className="App">
                          <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/Document" element={<Document />} />
                            <Route path="/OtherDocument" element={<OtherDocument />} />
                            <Route path="/client-details" element={<ClientDetails />} />
                            {/* <Route path="/Accountant-details" element={<AccountantDetails />} /> */}
                            <Route
                              path="/Accountant-details"
                              element={
                                <ProtectedRoute unauthorizedPermission={["ViewSubAccountantClients"]} preventClient={true}>
                                  <AccountantDetails />
                                </ProtectedRoute>
                              }
                            />
                            <Route path="/Main" element={<Main />} />
                            <Route
                              path="/SubAccountants"
                              element={
                                <ProtectedRoute requireAccountant preventClient={true}>
                                  <SubAccountant />
                                </ProtectedRoute>
                              }
                            />
                            <Route path="/ProfitAndLossReport" element={<ProfitAndLossReport />} />
                            <Route path="/SortCodeReport" element={<SortCodeReport />} />
                            <Route path="/TransactionDocuments" element={<TransactionDocuments />} />
                            <Route path="/PaymentAcceptance" element={<PaymentAcceptance />} />
                            {/* <Route path="/ReportTracking" element={<ReportTracking />} /> */}
                            {/* <Route
                            path="/ReportTracking"
                            element={
                              <ProtectedRoute unauthorizedPermission="ViewSubAccountantClients">
                                <ReportTracking />
                              </ProtectedRoute>
                            }
                          /> */}
                            <Route path="/LedgerReport" element={<LedgerReport />} />
                            <Route path="/AccountCardDashboard" element={<AccountCardDashboard />} />
                            <Route path="/AccountCardsTable" element={<AccountCardsTable />} />
                            <Route path="/ClientItems" element={<ClientItems />} />


                            <Route path="/JournalEntry"
                              element={
                                <ProtectedRoute premiumClientOnly={true}>
                                  <JournalEntry />
                                </ProtectedRoute>
                              }
                            />

                            <Route path="/BankTransactions"
                              element={
                                <ProtectedRoute premiumClientOnly={true}>
                                  <BankMatching />
                                </ProtectedRoute>
                              }
                            />

                            <Route path="/BankPages"

                              element={
                                <ProtectedRoute premiumClientOnly={true}>
                                  <BankPages />
                                </ProtectedRoute>
                              }
                            />

                            <Route
                              path="/AddClient"
                              element={
                                <ProtectedRoute unauthorizedPermission={["CannotAddClient"]}>
                                  <AddClient />
                                </ProtectedRoute>
                              }
                            />

                          <Route
                            path="/Assets"
                            element={
                              <ProtectedRoute unauthorizedPermission={["CannotAddClient"]}>
                                <Assets />
                              </ProtectedRoute>
                            }
                          />

                            <Route path="/CreateInvoice" element={<CreateInvoice />} />
                            <Route path="/ReceiptTax" element={<CreateReceipt />} />
                            <Route
                              path="/CollectionPage"
                              element={
                                <ProtectedRoute unauthorizedPermission={["ViewSubAccountantClients"]} preventClient={true}>
                                  <CollectionPage />
                                </ProtectedRoute>
                              }
                            />
                            <Route path="/CardMatching"

                              element={
                                <ProtectedRoute premiumClientOnly={true}>
                                  <CardMatching />
                                </ProtectedRoute>
                              }
                            />

                            <Route path="/Assignments"

                              element={
                                <ProtectedRoute unauthorizedPermission={["ViewSubAccountantClients"]} preventClient={true}>
                                  <Assignments />
                                </ProtectedRoute>
                              }
                            />


                            {/* <Route path="/Chat" element={<Chat />} /> */}
                            <Route path="/" element={<Login />} />
                            <Route path="*" element={<ErrorPage />} />
                            <Route path="/unauthorized" element={<Unauthorized />} />

                          </Routes>
                          <HelpIcon />

                          <NotificationContainer />
                        </div>
                      </PermissionsProvider>
                    </NavHistoryProvider>
                  </Router>
                </ThemeProvider>
              </CacheProvider>
            </StyledEngineProvider>
          </PrimeReactProvider>
        </FileProvider>
      </SignalRProvider>
    </ChatProvider>
  );
}

export default App;
