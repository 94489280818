// NavHistoryContext.js
import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation, useNavigationType, NavigationType } from 'react-router-dom';

export const NavHistoryContext = createContext(null);

export const NavHistoryProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();
  const [historyStack, setHistoryStack] = useState([location]);  // start with current route

  // useRef to track initial render (to handle initial POP on page load)
  const initialLoad = useRef(true);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;  // skip stack update on initial load
    }

    if (navigationType === NavigationType.Push) {
      // Push new location onto custom stack
      setHistoryStack(prev => [...prev, location]);
    } else if (navigationType === NavigationType.Replace) {
      // Replace the top of the stack for replace navigations
      setHistoryStack(prev => {
        const newStack = [...prev];
        newStack[newStack.length - 1] = location;
        return newStack;
      });
    } else if (navigationType === NavigationType.Pop) {
      // Pop action (back/forward) – remove the top of the stack
      setHistoryStack(prev => prev.slice(0, -1));
    }
  }, [location, navigationType]);

  // Custom back navigation function
  const goBack = () => {
    if (historyStack.length > 1) {
      const prevEntry = historyStack[historyStack.length - 2];  // the page to go back to
      // Navigate to the previous meaningful page
      navigate(prevEntry.pathname + prevEntry.search, { state: prevEntry.state, replace: true });
      // Remove the current page from the custom stack
      setHistoryStack(prev => prev.slice(0, -1));
    } else {
      // No previous page in our stack – fallback (e.g. go home or do nothing)
      navigate('/Main', { replace: true });
    }
  };

  return (
    <NavHistoryContext.Provider value={{ historyStack, goBack }}>
      {children}
    </NavHistoryContext.Provider>
  );
};
