import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu.jsx";
import PuffLoader from "react-spinners/PuffLoader";
import Select, { components } from "react-select";
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGB from "date-fns/locale/en-GB";
import { useTranslation } from "react-i18next";
import UserService from "../../Services/user.service.js";
import { useBack } from "use-back";
import "../OtherDocuments/OtherDocument.css";
import "./BankMatching.css";
import backIcon from "../../assests/images/left-arrow.png";
import EnumsService from "../../Services/enums.service.js";
import CustomDeleteConfirmationModal from "../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal.js";
import { ToastContainer, toast } from "react-toastify";
import DownloadIcon from "@mui/icons-material/Download";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSignalR } from "../../Contexts/signalR.js";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/saga-blue/theme.css"; // Optional: PrimeReact theme
import "primereact/resources/primereact.min.css"; // PrimeReact CSS
import "primeicons/primeicons.css";
import SaveIcon from "@mui/icons-material/Save"; // Save icon
import ClearIcon from "@mui/icons-material/Clear";
import AdvancedDropdown from "../AdvancedDropdown/AdvancedDropdown.js";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import ClickableDenseTable from "./ClickAbleDenseTable/ClickAbleDenseTable.js";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Constants from "../../assests/Constants/constants.js";
import { DataGrid, useGridApiRef, GridToolbar } from "@mui/x-data-grid";
import { styled, darken, lighten } from "@mui/material/styles";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { arSD, heIL, enUS } from "@mui/x-data-grid/locales";
import CustomMonthDatePicker from "../Widgets/CustomDates/CustomMonthDatePicker/CustomMonthDatePicker.js";
import CustomDropdown from "../CustomDropdown/CustomDropdown.js";
import EditModal from "../EditModal/EditModal.js";
import AddOrEditBankDialog from "./AddOrEditBankDialog";
import AddExternalTransactionDialog from './AddExternalTransactionDialog';
import DenseTable from '../DenseTable/DenseTable';
import AccountAutocomplete from "../Widgets/AccountAutocomplete/AccountAutocomplete.js";
import MatchedTable from "./SubComponents/MatchedTable.js";
//table imports
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Download as DownloadIconMui,
  Search as SearchIcon,
  CancelRounded,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Typography,
  TextField,
  Checkbox,
  Tooltip,
  DialogContentText,
  InputAdornment,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Switch,
  CircularProgress,
  IconButton,
  Autocomplete,
  FormControlLabel,
  Card,
  CardContent,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { beautifyNumber } from "../../Utils/FormatNumber.js";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ImportDialog from "./ImportDialog.js";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DocumentService from "../../Services/documents.service.js";
import ExternalTransactionsService from "../../Services/externalTransaction.service.js";
import NoDocumentsPdf from "../../assests/pdf/NoDocument.pdf";
import ClientService from "../../Services/client.service.js";
import ClientDocumentCountService from "../../Services/ClientDocumentCount.service.js";
import EditPaymentAcceptanceModal from "../EditPaymentAcceptanceModal/EditPaymentAcceptanceModal.js";
import editIconImage from "../../assests/images/Icons/editIcon.svg";
import AddAccountCardModal from '../Widgets/AddAccountCardModal/AddAccountCardModal';
import { handleDownloadTemplate } from '../../Utils/handleDownloadTemplate';

import BottomDockBar from "./BottomDockBar.js";
import CustomMonthDatePickerMui from "../Widgets/CustomDates/CustomMonthDatePicker/CustomMonthDatePickerMui.js";
import UpperPanelDetails from "../Widgets/UpperPanelDetails/UpperPanelDetails.jsx";
import AutocompleteSelect from "../Widgets/AutocompleteSelect/AutocompleteSelect.jsx";
import PaymentAcceptanceCreateEditModal from "../PaymentAcceptance/SubComponents/PaymentAcceptanceCreateEditModal.jsx";
import CustomMonthYearIndividualDatePicker from "../Widgets/CustomDates/CustomMonthYearIndividualDatePicker/CustomMonthYearIndividualDatePicker.js";
import CustomYearDatePicker from "../Widgets/CustomDates/CustomYearDatePicker/CustomYearDatePicker.js";
import BankFile from "../../assests/templates/Xtram_Bank_Template.xlsx";
import { NavHistoryContext } from "../../Contexts/NavHistoryContext.js";
import { validateDocumentBeforeProcessing, validatePaymentAcceptanceItems } from '../../Utils/documentValidation'
import ChooseAsset from "../Widgets/ChooseAsset/ChooseAsset.js";
import AssetService from "../../Services/assets.service.js";
import AddAssetGroup from "../Assets/SubComponents/AddAssetGroup.js";
import { assertEachIs } from "pdf-lib";

registerLocale("en-GB", {
  ...enGB,
  localize: {
    ...enGB.localize,
    month: (n) => n + 1, // Display months as numbers starting from 1
  },
  formatLong: {
    ...enGB.formatLong,
    date: () => "MM/yyyy", // Date format when the picker is not showing
  },
});

setDefaultLocale("en-GB"); // Set the default locale to use it

// Create rtl cache
const cacheRtl = createCache({
  key: "data-grid-rtl-demo",
  stylisPlugins: [prefixer, rtlPlugin],
});

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
// Utility to generate n number of absolutely distinct colors
const generateDistinctColors = (n, alpha = 0.5) => {
  const colors = [];
  const goldenRatio = 0.618033988749895; // To generate distinct hues
  let hue = Math.random(); // Start with a random initial hue

  for (let i = 0; i < n; i++) {
    hue += goldenRatio; // Spread hues using golden ratio for maximum distinctness
    hue %= 1; // Keep hue within [0, 1] range
    const saturation = 50 + Math.random() * 50; // Saturation between 50% and 100%
    const lightness = 40 + Math.random() * 30; // Lightness between 40% and 70%

    // Add opacity to the color by switching to hsla
    colors.push(`hsla(${hue * 360}, ${saturation}%, ${lightness}%, ${alpha})`);
  }

  return colors;
};

// Usage example:
const numberOfColors = 50;
const matchColors = generateDistinctColors(numberOfColors);

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const StyledDataGrid = styled(DataGrid)(({ theme }) => {
  // Generate styles for matched rows as an object
  const matchedRowStyles = matchColors.reduce((styles, color, index) => {
    styles[`& .MuiDataGrid-row.super-app-theme--matched-${index}`] = {
      backgroundColor: color,
      "&:hover": {
        backgroundColor: color,
      },
    };
    return styles;
  }, {});

  return {
    "& *": {
      boxSizing: "border-box !important",
    },
    // Include the matched row styles
    ...matchedRowStyles,
    "& .super-app-theme--UnMatched": {
      backgroundColor: getBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
    "& .super-app-theme--Matched": {
      backgroundColor: getBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode
        ),
      },
    },
    // Style for main matched rows (Level 0)
    "& .super-app-theme--MainRow": {
      backgroundColor: theme.palette.background.paper,
      "&:hover": {
        backgroundColor: getBackgroundColor(
          theme.palette.action.hover,
          theme.palette.mode
        ),
      },
    },
    // Style for header rows (Level 1)
    "& .super-app-theme--Header": {
      backgroundColor: theme.palette.grey[200],
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: getBackgroundColor(
          theme.palette.grey[300],
          theme.palette.mode
        ),
      },
    },
    // Style for transaction sub-rows (Level 2)
    "& .super-app-theme--SubRow": {
      backgroundColor: theme.palette.grey[50],
      "&:hover": {
        backgroundColor: getBackgroundColor(
          theme.palette.action.hover,
          theme.palette.mode
        ),
      },
    },
    "& .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal": {
      display: "block !important",
    },
    "& .MuiDataGrid-columnHeaders": {
      // opacity: 0.7,
      backgroundColor: "#BFC8FF",
    },
    "& .MuiDataGrid-columnHeader": {
      backgroundColor: "#BFC8FF",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: "#304FFF",
      textAlign: "center",
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "normal",
    },
    "& .MuiDataGrid-cell": {
      borderTop: "1px solid rgba(191, 191, 193, 0.56)",
      backgroundColor: "rgba(234, 237, 255, 0.32)",
      whiteSpace: "normal",
      wordWrap: "break-word",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "rgba(191, 200, 255, 0.3)",
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-cell": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
});
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme, width, height }) => ({
    marginBottom: "20px",
    "& .MuiToggleButton-root": {
      width: "150px",

      backgroundColor: "#ffffff", // Button background color
      transition: "background-color 0.3s, transform 0.3s", // Smooth transition effects
      "&:hover": {
        backgroundColor: "#e0e0e0", // Change background color on hover
        transform: "scale(1.05)", // Slightly increase size on hover
      },
      "&.Mui-selected": {
        backgroundColor: "#304FFF", // Selected button background color
        color: "#fff", // Selected button text color
        borderColor: "#304FFF", // Selected button border color
        "&:hover": {
          backgroundColor: "#0069d9", // Darker shade on hover when selected
        },
      },
    },
  })
);

const formatMonthYearDate = (dateString) => {
  const date = new Date(dateString);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  return `${month}/${year}`;
};

// Utility function to convert DD-MM-YYYY to YYYY-MM-DD
const parseCustomDate = (dateStr) => {
  const [day, month, year] = dateStr.split("-");
  return `${year}-${month}-${day}`; // Return as YYYY-MM-DD
};

function BankMatching() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const [user, setUser] = useState(null);
  const [token, setToken] = useState("");
  const [client, setClient] = useState(null);
  const [type, setType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("language") || "he"
  );
  const [loading, setLoading] = useState(false);
  const [internalRows, setInternalRows] = useState([]);
  const [displayedRows, setDisplayedRows] = useState([]);
  const [externalRows, setExternalRows] = useState([]);
  const [isClientDataFetched, setIsClientDataFetched] = useState(false);
  const [shouldMatch, setShouldMatch] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "cardId",
    direction: "descending",
    type: "number",
  });
  const [focused, setFocused] = useState(false);
  const customScrollbarRef = useRef();
  const tableContainerRef = useRef();
  const [calculatedWidth, setCalculatedWidth] = useState("70%");
  // const [matchedDisplayedRows, setMatchedDisplayedRows] = useState([]);
  const [openRemoveMatchDialog, setOpenRemoveMatchDialog] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const apiRef = useGridApiRef();
  const [sortedTransactions, setSortedTransactions] = useState([]);
  const [cardForNewBank, setCardForNewBank] = useState(null);
  const [denseTableCreditDebitRows, setDenseTableCreditDebitRows] = useState([]);
  const [itemIdToDelete, setItemIdToDelete] = useState('');
  const [itemTypeToDelete, setItemTypeToDelete] = useState('');
  const handleChangeSetCardForNewBank = (value) => {


    if (value) {
      setCardForNewBank(value);
    } else {
      setCardForNewBank(null);
    }
  }


  //account cards
  const [accountCardsData, setAccountCardsData] = useState([]);
  const [startDate, setStartDate] = useState(() => {
    const storedDateString = localStorage.getItem("StartDate");
    return storedDateString
      ? new Date(storedDateString)
      : new Date(new Date().getFullYear(), 0, 1); // Start of the current year
  });

  const [endDate, setEndDate] = useState(() => {
    const storedDateString = localStorage.getItem("EndDate");
    return storedDateString
      ? new Date(storedDateString)
      : new Date(Date.UTC(new Date().getUTCFullYear(), 11, 30, 23, 59, 59)); // End of the current year
  });


  
  const [isMatchDate, setIsMatchDate] = useState(() => {
    const storedIsRange = localStorage.getItem("IsRange");
    return storedIsRange === "true";
  });

  const [fetchType, setFetchType] = useState("NotMatched");

  const handleFetchTypeChange = async (event, newAlignment) => {
    // Prevent deselecting both options
    if (newAlignment === "Matched") {
      await fetchMatchDataOnDateRangeChange(
        startDate,
        endDate,
        isMatchDate
      );
    } else {
      await fetchNotMatchDataOnDateRangeChange(
        startDate,
        endDate,
      );
    }
    if (newAlignment !== null) {
      setFetchType(newAlignment);
    }
  };

  const existingTheme = useTheme();
  useEffect(() => {
    const currentLanguage = localStorage.getItem("language") || "he";
    setSelectedLanguage(currentLanguage);
  }, [i18n.language]);


  useEffect(() => {
    if (!endDate || isNaN(new Date(endDate).getTime())) {
      // Set endDate to December 31 of the current year
      setEndDate(new Date(new Date().getFullYear(), 11, 31));
    }

    // Check if startDate is null, undefined, or an invalid date
    if (!startDate || isNaN(new Date(startDate).getTime())) {
      // Set startDate to January 1 of the current year
      setStartDate(new Date(new Date().getFullYear(), 0, 1));
    }

  }, [endDate, startDate, setEndDate, setStartDate]);


  const [oldSelectedAccountCardItem, setOldSelectedAccountCardItem] = useState({
    cardId: "",
    caseId: "",
    accountCaseId: "",
    name: "",
    palCode: "",
    client: null,
  });

  const fileInputRef1 = useRef(null); // Reference to the first hidden file input

  const handleCustomScroll = (e) => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollLeft = e.target.scrollLeft;
    }
  };

  const [denseTableHeader, setDenseTableHeader] = useState([
    t("BankNumber"),
    t("BankName"),
    t("BankBranch"),
    t("BankAccount"),
    t("AccountCardNumber"),
  ]);
  const [denseTableRows, setDenseTableRows] = useState([]);
  const [tBanksData, setTBanksData] = useState([]);

  const [processedCardsData, setProcessedCardsData] = useState([]);
  const [selectedFormattedAccountCard, setSelectedFormattedAccountCard] =
    useState(null);
  const [mainCard, setMainCard] = useState("");
  const [selectedCardId, setSelectedCardId] = useState("");
  const [selectedTBankIndex, setSelectedTBankIndex] = useState(-1); //used for dense table
  const [selectedTBank, setSelectedTBank] = useState("");
  // Inside your component's render/return method:
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const { goBack } = useContext(NavHistoryContext);



  const [matchData, setMatchData] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const handleOpenImportDialog = () => {
    if (!selectedTBank) {
      toast.info(t("YouNeedToSelectABankFirst"));
      return;
    }

    // Search for a matching bank entry in tBanks
    const bankEntry = tBanksData.find(
      (bank) => bank.cardId?.toString() === mainCard.cardId?.toString()
    );

    // If a matching bank entry is found and it has a corresponding URL
    if (bankEntry && Constants.BankUrls[parseInt(bankEntry.bankCode)]) {
      // Open the bank's URL in a new tab
      window.open(Constants.BankUrls[parseInt(bankEntry.bankCode)], "_blank");
    }

    // Open the dialog
    setOpenImportDialog(true);
  };

  const handleCloseImportDialog = () => {
    setOpenImportDialog(false);
  };

  const [externalTransactions, setExternalTransactions] = useState([]);
  const handleImport = async (file) => {
    setLoading(true);
    // selectedTBank is the selected bank
    const bankType = Constants.BankTypes[parseInt(selectedTBank.bankCode)];
    const accountCard = accountCardsData.find(
      (card) => card.cardId === selectedCardId
    );

    try {
      // Call the service method to import transactions
      const response =
        await ExternalTransactionsService.ImportExternalTransactions(
          client.caseId,
          selectedCardId,
          accountCard.name,
          bankType,
          file,
          token
        );

      if (response.ok) {
        toast.success(t("BankDataImportSuccessful"));
        await FetchData();
      } else {
        toast.error(t("BankDataImportFailed"));
      }
    } catch (error) {
      toast.error(t("BankDataImportFailed"));

      console.error("Error importing file:", error);
    }
    setLoading(false);
  };

  const [addBankTransactionDialog, setAddBankTransactionDialog] =
    useState(false);

  const handleCloseBankAddTransactionDialog = () => {
    setAddBankTransactionDialog(false);
    setNewBankEditableItem({
      caseId: '',
      accountCodeId: '',
      accountCodeName: '',
      description: '',
      reference: '',
      amount: '',
      transactionDate: new Date()
    });
  };

  const handleAddBankTransaction = async () => {
    try {


      const updatedBankItem = {
        ...newBankEditableItem,
        caseId: client.caseId,
        accountCodeId: mainCard.cardId,
        accountCodeName: mainCard.name,
      };



      const response = await ExternalTransactionsService.CreateTransaction(updatedBankItem, token);

      if (response.ok) {

        setNewBankEditableItem({
          caseId: '',
          accountCodeId: '',
          accountCodeName: '',
          description: '',
          reference: '',
          amount: '',
          transactionDate: new Date()
        });
        handleCloseBankAddTransactionDialog();
        await FetchData();
      } else {
        const errorMessage = await response.text();

        if (errorMessage === "A transaction with the same reference already exists.") {
          toast.error(t('TransactionWithSameReferenceExists'));
        }
        toast.error(t('TransactionWithSameReferenceExists'));
      }
    } catch (error) {

      toast.error(t('FailedToCreateBankCommand'));
    }
  };
  const changeNewBankTransaction = (field, value) => {
    setNewBankEditableItem(prevState => ({
      ...prevState,
      [field]: value
    }));

  };

  const [newBankEditableItem, setNewBankEditableItem] = useState({
    caseId: '',
    accountCodeId: '',
    accountCodeName: '',
    description: '',
    reference: '',
    amount: '',
    transactionDate: ''
  });

  const handleOpenBankAddTransactionDialog = () => {
    if (!selectedTBank) {
      toast.info(t("YouNeedToSelectABankFirst"));
      return;
    }
    setAddBankTransactionDialog(true);
  };

  //for opening create bank modal
  const [createBankModal, setCreateBankModal] = useState(false);
  const bankOptions = Object.keys(Constants.BankNames).map((key) => ({
    value: key,
    label: `${Constants.BankNames[key]} - ${key}`, // Format: BankName - BankNumber
  }));

  const [selectedBankForCreate, setSelectedBankForCreate] = useState(null);
  const [bankBranch, setBankBranch] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [selectedExternalRows, setSelectedExternalRows] = useState([]);
  const [selectedMatchedRows, setSelectedMatchedRows] = useState([]);
  const [selectedInternalRows, setSelectedInternalRows] = useState([]);
  const [expandedInternalRows, setExpandedInternalRows] = useState({});
  const [expandedExternalRows, setExpandedExternalRows] = useState({});
  const [externalSum, setExternalSum] = useState(0);
  const [internalSum, setInternalSum] = useState(0);
  const [createJournalEntries, setCreateJournalEntries] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [shouldCallHandleMatch2, setShouldCallHandleMatch2] = useState(false);
  const [shouldCallHandleMatchPair2, setShouldCallHandleMatchPair2] = useState(false);
  const [pairTransaction, setPairTransaction] = useState([]);
  // Matching
  const [matchedPairs, setMatchedPairs] = useState([]);
  const [matchCounter, setMatchCounter] = useState(0);
  const [formValues, setFormValues] = useState({
    confirmationNumber: "",
    accountCaseId: "",
    reference: "",
    description: "",
    documentDate: "",
    reportingMonthDate: new Date(),
    amount: "",
    vat: 0,
    sortCodeId: "3",
    creditAccount: "",
    debitAccount: "",
    sortCode: "3",
    accountCard: "",
  });

  const handleReportingMonthDateChange = (event) => {
    const value = event.target.value; // This will be in YYYY-MM format
    const [year, month] = value.split("-"); // Split into year and month

    // Reformat to MM/YYYY
    const formattedDate = `${month}/${year}`;
    setReportingMonthDate(formattedDate); // Update state with MM/YYYY format
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSelectChange = (field, newValue) => {
    if (newValue && (field === 'creditAccount' || field === 'debitAccount' || field === 'accountCard')) {
      if (newValue?.value1 === 'AddItem') {
        handleNoOptionCreditDebitClick('', field);
        return;
      }

      if (newValue?.cardId) {
        setFormValues((prev) => ({
          ...prev,
          [field]: newValue.cardId,
        }));
      } else {
        setFormValues((prev) => ({
          ...prev,
          [field]: newValue,
        }));
      }
      return;
    }
    if (field === 'sortCodeId') {

      if (!newValue || !sortCodeIdOptions.some((option) => option.value === newValue)) {
        // Set default values for the empty state

        setFormValues((prev) => ({
          ...prev,
          vat: 100, // Default VAT
          [field]: '',
        }));
        return; // Exit early since there's no valid sortCodeId
      }

      let newVat = 100;
      const name = sortCodeIdOptions.find(
        (option) => option.value === newValue
      )?.label || ''

      // Extract the name without the parentheses
      const nameWithoutParentheses = name.replace(/\s*\(.*?\)\s*/, '').trim();

      // Check for a number within parentheses
      const match = name.match(/\((\d+)%?/);
      let customVat = match ? parseInt(match[1], 10) : null;


      const sortCode = sortCodeIdOptions.filter(sc => sc.value === newValue)[0];

      // Determine the VAT based on the name without parentheses
      if (Constants.SortCodesWith66Vat.includes(nameWithoutParentheses)) {
        newVat = 66;
      } else if (Constants.SortCodesWithZeroVat.includes(nameWithoutParentheses)) {
        newVat = 0;
      } else if (Constants.SortCodesWith16Vat.includes(nameWithoutParentheses)) {
        newVat = 16;
      } else if (customVat !== null) {
        // If a custom VAT was found, use it
        newVat = customVat === 66 ? 66 : customVat;
      } else {
        // Default VAT if no special conditions are met
        newVat = 100;
      }
      setFormValues((prev) => ({
        ...prev,
        vat: newVat,
        [field]: newValue,
      }));
      return;
    }

    setFormValues((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };



  const sortCodeOptions = [
    { label: "Sort Code 1", value: 1 },
    { label: "Sort Code 2", value: 2 },
    // more options
  ];

  const CustomPalCode = (props) => {
    return (
      <components.Option {...props}>
        <div style={{ textAlign: "center", width: "100%" }}>{props.label}</div>
      </components.Option>
    );
  };

  // Edit Modal Vars
  const [editedItem, setEditedItem] = useState({});
  const [editedPopupItem, setEditedPopupItem] = useState(true);
  const [fade, setFade] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [transactions, setTransactions] = useState(null);
  const [dataForPdf, setDataForPdf] = useState(null);
  const [sortedDataForPdf, setSortedDataForPdf] = useState(null);
  const [transactionDetailsDictionary, setTransactionDetailsDictionary] =
    useState({});
  const [itemToDelete, setItemToDelete] = useState({});
  const [editedJournalItem, setEditedJournalItem] = useState(true);
  const [paymentModal, setPaymentModal] = useState(false);
  const [openInvoiceCreatorModal, setOpenInvoiceCreatorModal] = useState(false);
  const [showType, setShowType] = useState({ type: 'all', index: -1 });

  const [addEditableItem, setAddEditableItem] = useState({
    checks: [],
    creditCards: [],
    cash: [],
    bankTransactions: [],
    date: new Date(),
  });
  const [responseData, setResponseData] = useState({});
  const [accountCardEditDownloadOptions, setAccountCardEditDownloadOptions] =
    useState([]);
  const [originalAccountCardId, setOriginalAccountCardId] = useState("");
  const [editedAddAccountCard, setEditedAddAccountCard] = useState(false);
  const [isRange, setIsRange] = useState(false);
  const [isClosedReportingMonth, setIsClosedReportingMonth] = useState(false);
  const [accountCardOptions, setAccountCardOptions] = useState([]);
  const [accountCardOptionsForBank, setAccountCardOptionsForBank] = useState([]);
  const [editModalsAccountCardOptions, setEditModalsAccountCardOptions] =
    useState([]);

  const [sortCodeIdOptions, setSortCodeIdOptions] = useState([]);
  const [sortCodeIdOptionsForEditModals, setSortCodeIdOptionsForEditModals] =
    useState([]);

  const [statusCheck, setStatusCheck] = useState(false);
  const [creditAccountOptions, setCreditAccountOptions] = useState([]);
  const [debitAccountOptions, setDebitAccountOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [editingRow, setEditingRow] = useState(null);
  const [palCodeName, setPalCodeName] = useState(null);
  const [accountCardName, setAccountCardName] = useState(null);
  const [accountCardId, setAccountCardId] = useState(null);
  const [finalBalance, setFinalBalance] = useState("");
  const [isMonthly, setIsMonthly] = useState(false);
  const [firstRowDetails, setFirstRowDetails] = useState(null);
  const [palCodeDetails, setPalCodeDetails] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formattedPalCodeOptions, setFormattedPalCodeOptions] = useState([]);
  const [journalEntriesList, setJournalEntriesList] = useState([]);
  const [isJournalEntriesListOpen, setIsJournalEntriesListOpen] =
    useState(false);
  const [expandedMatchedRows, setExpandedMatchedRows] = useState({});


  // Handle expand/collapse
  const handleMatchedExpandClick = (id) => {
    setExpandedMatchedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const CustomInput1 = React.forwardRef(({ value, onClick }, ref) => (
    <button className="document-custom-input1" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  const handleAccountEdit = (option) => {
    setShowAccountCardInputsEdit(true);
    setAccountCodeName(option.name);
    setAccountCodeId(option.cardId);
    setCompanyId(option.caseId);
    const account = accountCardOptions.find(
      (account) => account.cardId === option.cardId
    );
    setPalCode(account ? account.palCode : "");
    setOldSelectedAccountCardItem({
      cardId: option.cardId,
      caseId: option.caseId,
      accountCaseId: option.accountCaseId,
      name: option.name,
      palCode: "",
      client: null,
    });
    setAddNewAccountCardDocument(true);
  };

  const CustomInput2 = React.forwardRef(({ value, onClick }, ref) => (
    <button className="edit-custom-input" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  const handleCancel = () => {
    setEditingRow(null);
    setEditedItem({});
    setEditedAddAccountCard(false);
  };
  const handleEditDeleteClick = (itemId) => {
    setEditedPopupItem(false);
    setEditedItem({});
    setPaymentModal(false);
    setAddEditableItem({
      checks: [],
      creditCards: [],
      cash: [],
      bankTransactions: [],
      date: new Date()
    });
    handleDeleteClick();
  };
  const handleDeleteClick = () => {
    setIsModalVisible(true);
  };

  const [bankTranToDelete, setBankTranToDelete] = useState(null);
  const handleDeleteClickBank = (transaction) => {
    setBankTranToDelete(transaction);
    setIsModalVisible(true);
  };

  const handleDoneClick = async (itemId, type) => {

    setLoading(true);
    try {
      const response =
        await ClientDocumentCountService.removeDocumentByTypeAndId(
          client.caseId,
          client.accountantId,
          type,
          itemId, token);
      if (response.ok) {
        // await fetchDocumentsNotification(client, token);
        toast.success(t("DocumentProcessed"), {
          position: "top-left",
        });
      } else {
        const errorText = await response.text();
        toast.error(t("DocumentNotProcessed", { errorText }));
      }
    } catch (error) {
      // toast.error(t('DocumentNotProcessed', { errorText: error.message }));
    }
    setLoading(false);
  };

  const handleNotDoneClick = async (itemId, type) => {


    setLoading(true);
    try {
      const response = await ClientDocumentCountService.addDocumentByTypeAndId(
        client.caseId,
        client.accountantId,
        type,
        itemId,
        token
      );
      if (response.ok) {
        // await fetchDocumentsNotification(client, token)
        toast.success(t("DocumentProcessed"), {
          position: "top-left",
        });
      } else {
        const errorText = await response.text();
        toast.error(t("DocumentNotProcessed", { errorText }));
      }
    } catch (error) {
      // toast.error(t('DocumentNotProcessed', { errorText: error.message }));
    }
    setLoading(false);
  };

  const handleUpdate = async (itemId) => {
    setLoading(true);
    let finalResponse = null;
    setEditedPopupItem(false);
    try {
      editedItem.accountCaseId = client.accountCaseId;
      delete editedItem.documentUrl; // Ensure the document URL is not part of the update if present


      if (editedItem.docType === "Incomes") {
        finalResponse = await DocumentService.UpdateDocument(
          "Incomes",
          token,
          editedItem
        );
      }
      if (editedItem.docType === "Expenses") {
        // If the income update fails, attempt the expense update
        finalResponse = await DocumentService.UpdateDocument(
          "Expenses",
          token,
          editedItem
        );
      }
      if (editedItem.docType === "JournalEntries") {
        finalResponse = await DocumentService.UpdateDocument(
          "JournalEntries",
          token,
          editedItem
        );
      }
      setTypeToPassToChoose(editedItem.docType);


      if (finalResponse && finalResponse.ok) {
        if (editedItem.status === "done") {

          await handleDoneClick(editedItem.id, editedItem.docType);
          // handleSortChange('id', 'newest', 'descending');
        } else if (editedItem.status === "notDone") {
          await handleNotDoneClick(editedItem.id, editedItem.docType);
        }

        if (editedAddAccountCard) {
          await handleAccountCardSave(
            editedItem.accountCodeId,
            editedItem.accountCodeName,
            editedItem.accountCaseId
          );
          await fetchEnums(user, token, client);
        }

        setWholeDataTransactions((prevData) => {
          const newData = { ...prevData };

          // Check if editedItem.id exists in the previous data
          if (newData[editedItem.id]) {
            // Delete the existing entry
            delete newData[editedItem.id];
          }

          // Return the updated object without the removed entry
          return newData;
        });

        toast.success(t("documentUpdateSuccess"));
      } else {
        const errorText = await finalResponse.text();
        toast.error(t("documentUpdateError") + ": " + errorText);
      }
    } catch (error) {
      // This block catches unexpected errors such as network issues, not HTTP errors which are handled above
      console.error("Unexpected error occurred: ", error);
      toast.error(t("documentUpdateError"));
    }
    setEditedItem({});
    setEditingRow(null);
    setEditedAddAccountCard(false);

    await fetchEnums(user, token, client);
    await FetchData();
    setLoading(false); // Always stop loading at the end of the operation
  };

  const handleEditAddAccountChange = (selectedOption, field) => {
    if (field === "AccountCodeId") {
      type === "Incomes"
        ? setEditedItem({
          ...editedItem,
          accountCodeId: selectedOption,
          debitAccount: selectedOption,
        })
        : setEditedItem({
          ...editedItem,
          accountCodeId: selectedOption,
          creditAccount: selectedOption,
        });
    } else if (field === "AccountCodeName") {
      setEditedItem({ ...editedItem, accountCodeName: selectedOption });
    } else {
      setEditedItem({ ...editedItem, accountCaseId: selectedOption });
    }
  };

  const handleCloseEditAddNewAccountCardDocument = () => {
    setEditedItem({ ...editedItem, accountCodeName: "", accountCodeId: "" });
    setEditedAddAccountCard(false);
  };

  const handleRadioChange = async (e) => {
    if (e.target.value?.toString() === 'done') {

      const isValid = await validateDocumentBeforeProcessing(editedItem, t, client);
      if (!isValid) return;

      // ✅ 6) Mark as processed
      setStatusCheck(true);
    }

    else if (e.target.value?.toString() === 'notDone') {
      setStatusCheck(false);
    }

    setEditedItem({ ...editedItem, status: e.target.value });
  };


  const handleRadioPAChange = async (e) => {
    if (e.target.value?.toString() === 'done') {
      const isValid = await validatePaymentAcceptanceItems(addEditableItem, t, client);
      if (!isValid) return;

      setStatusCheck(true);
    }
    else if (e.target.value?.toString() === 'notDone') {
      setStatusCheck(false);
    }
    setAddEditableItem({ ...addEditableItem, ["status"]: e.target.value });
  };
  const handleAccountCardSave = async (
    AccountCodeId,
    AccountCodeName,
    AccountCaseId
  ) => {
    const response2 = await EnumsService.AddAccountCard(
      client.caseId,
      AccountCodeId,
      AccountCodeName,
      AccountCaseId,
      token
    );
    if (!response2.ok) {
      throw new Error(`HTTP error! status: ${response2.status}`);
    }
  };

  const updateAccountFields = (newValue, accountType) => {


    const isIncomesDebit = editedItem.docType === 'Incomes' && accountType === 'debitAccount';
    const isExpensesCredit = editedItem.docType === 'Expenses' && accountType === 'creditAccount';

    const updatedFields = {
      accountCodeId: isIncomesDebit || isExpensesCredit ? (newValue ? newValue.cardId : '') : '',
      accountCodeName: isIncomesDebit || isExpensesCredit ? (newValue ? newValue.name : '') : '',
      accountCaseId: isIncomesDebit || isExpensesCredit ? (newValue ? newValue.accountCaseId : '') : '',
    };

    // Update the edited item with the new fields
    setEditedItem((prev) => ({
      ...prev,
      [accountType]: newValue ? newValue.cardId : '',
      ...(isIncomesDebit || isExpensesCredit ? updatedFields : {}),
    }));
  };


  const [saleEquipment, setSaleEquipment] = useState(false);
  const [dateForAsset, setDateForAsset] = useState(new Date().toISOString());
  const [typeToPassToChoose, setTypeToPassToChoose] = useState('');
  const [clickedAsset, setClickedAsset] = useState(null);


  const isDepreciationDocument = (editedItem, sortCodeId) => {

    const item = editedItem;
    const itemId = editedItem.id;
    if (!item) return false; // If the item is not found, allow processing
    setItemForAssets(item);
    setDateForAsset(item.documentDate);


    const sortCode = sortCodeIdOptions.find((option) => option.value === sortCodeId);
    if (!sortCode) return false; // If the sort code is not found, allow processing

    const isItemUsedInAssets = assets.some(
      (asset) => asset.expenseId === itemId || asset.journalEntryId === itemId
    );


    const name = sortCode.sortCodeName?.trim().replace(/\s+/g, ' ') || "";
    const saleEquipmentRegex = /(מכירה|מכירת).*ציוד|ציוד.*(מכירה|מכירת)/;
    if (sortCode.sortCodeId === Constants.SaleOfEquipment || saleEquipmentRegex.test(name)) {

      setSaleEquipment(true);
      return true;
    }


    setSaleEquipment(false);
    return Boolean(Constants.depreciationAssetSortCodeType[sortCode.sortCodeVatType]);
  };
  const [showChooseAsset, setShowChooseAsset] = useState(false);
  const [itemToAddToAsset, setItemToAddToAsset] = useState('');
  const [itemForAssets, setItemForAssets] = useState({});

  const handleAssetClicked = (asset, item) => {
    setItemToAddToAsset(item.id);
    setItemForAssets(item)
    setClickedAsset(asset);
    setShowChooseAsset(true);
  }

  const handleAssetClose = () => {
    setShowChooseAsset(false);
    setClickedAsset(null);
  }

  const handleChange = (field, value) => {

    const updatedValue = value;

    if (field === 'sortCodeId') {
      if (isDepreciationDocument(editedItem, value)) {
        setItemToAddToAsset(editedItem.id);
        setShowChooseAsset(true);
      }

      if (!value || !sortCodeIdOptions.some((option) => option.value === value)) {


        // Set default values for the empty state
        setEditedItem({
          ...editedItem,
          vat: 100, // Default VAT
          [field]: '',
        });
        return; // Exit early since there's no valid sortCodeId
      }

      let newVat = 100;
      const name = sortCodeIdOptions.find(
        (option) => option.sortCodeId === value
      )?.sortCodeName || ''

      // Extract the name without the parentheses
      const nameWithoutParentheses = name.replace(/\s*\(.*?\)\s*/, '').trim();

      // Check for a number within parentheses
      const match = name.match(/\((\d+)%?/);
      let customVat = match ? parseInt(match[1], 10) : null;


      const sortCode = sortCodeIdOptions.filter(sc => sc.value === updatedValue)[0];

      // Determine the VAT based on the name without parentheses
      if (Constants.SortCodesWith66Vat.includes(nameWithoutParentheses)) {
        newVat = 66;
      } else if (Constants.SortCodesWithZeroVat.includes(nameWithoutParentheses) || sortCode?.sortCodeVatType === 0) {
        newVat = 0;

      } else if (Constants.SortCodesWith16Vat.includes(nameWithoutParentheses)) {
        newVat = 16;
      } else if (customVat !== null) {
        // If a custom VAT was found, use it
        newVat = customVat === 66 ? 66 : customVat;
      } else {
        // Default VAT if no special conditions are met
        newVat = 100;
      }

      const accountCard = accountCardOptions.find(
        (item) => item.cardId === (type === 'Incomes' ? sortCode?.debitAccount : sortCode?.creditAccount)
      );
      setEditedItem({
        ...editedItem,
        vat: newVat,
        [field]: updatedValue,
        // [type === 'Incomes' ? 'debitAccount' : 'creditAccount']:
        //   editedItem[type === 'Incomes' ? 'debitAccount' : 'creditAccount']
        //     ? (accountCard ? accountCard.cardId : editedItem[type === 'Incomes' ? 'debitAccount' : 'creditAccount'])
        //     : editedItem[type === 'Incomes' ? 'debitAccount' : 'creditAccount'],
        accountCodeName: accountCard ? accountCard.name : editedItem.accountCodeName,
        accountCodeId: accountCard ? accountCard.cardId : editedItem.accountCodeId,
        accountCaseId: accountCard ? accountCard.accountCaseId : editedItem.accountCaseId,
      });

    }
    else if (field === 'creditAccount' || field === 'debitAccount') {

      // Use the helper function to update account fields for credit or debit account
      const newValue = accountCardOptions.find(option => option.cardId === updatedValue) || null;
      updateAccountFields(newValue, field);
    }
    else {
      // For fields other than sortCodeId, just update the edited item
      setEditedItem({
        ...editedItem,
        [field]: updatedValue,
      });
    }
  };

  const [reportingMonthDate, setReportingMonthDate] = useState(new Date());

  const handleVatRadioChange = (e) => {
    setEditedItem({ ...editedItem, ["vat"]: parseFloat(e.target.value) });
  };

  const handleDateChange = (e, field) => {
    setEditedItem({ ...editedItem, [field]: e });
  };

  const getAccountCardName = (accountId) => {
    const accountCard = formattedAccountCardOptions.find(
      (option) => option.cardId === accountId
    );
    return accountCard ? accountCard.name : "--"; // Fallback to 'Unknown Account' if not found
  };
  const getSortCodeName = (sortCodeId) => {
    const sortCode = sortCodeIdOptions.find(
      (option) => option.value === sortCodeId
    );
    return sortCode ? sortCode.label : "--"; // Fallback to 'Unknown Account' if not found
  };
  const handleReopenEdit = async (item, index) => {
    await fetchEnums(user, token, client);
    item.key = item.id;
    handleEdit(item);
  };

  const handleAccountChange = (selectedOption, field) => {
    if (field === "debitAccount" || field === "creditAccount") {
      const accountCardEditDownload = accountCardEditDownloadOptions.find(
        (option) => option.cardId === selectedOption.value
      );
      const name = accountCardEditDownload?.name;
      const accountCaseId = accountCardEditDownload?.accountCaseId;
      if (selectedOption.value < 0) {
        setOriginalAccountCardId(editedItem.cardId);
      }
      if (editedItem.docType === "Incomes") {
        setEditedItem({
          ...editedItem,
          accountCodeName: name,
          accountCodeId: selectedOption.value,
          debitAccount: selectedOption.value,
          accountCaseId: accountCaseId,
        });
      } else if (editedItem.docType === "Expenses") {
        setEditedItem({
          ...editedItem,
          accountCodeName: name,
          accountCodeId: selectedOption.value,
          creditAccount: selectedOption.value,
          accountCaseId: accountCaseId,
        });
      } else if (editedItem.docType === "JournalEntries") {
        setEditedItem({
          ...editedItem,
          [field]: selectedOption.value,
        });
      }
    } else if (field === "accountCodeName") {
      if (selectedOption.value1 === "AddItem") {
        setEditedAddAccountCard(true);
        setEditedItem({
          ...editedItem,
          accountCodeName: "",
          accountCodeId: "",
          accountCaseId: "",
        });
      } else {
        const accountCardEdit = accountCardEditDownloadOptions.find(
          (option) => option.cardId === selectedOption.value
        );
        const name = accountCardEdit?.name;
        const accountCaseId = accountCardEdit?.accountCaseId;
        const cardId = selectedOption.value;
        if (editedItem.docType === "Incomes") {
          setEditedItem({
            ...editedItem,
            accountCodeName: name,
            accountCodeId: cardId,
            debitAccount: cardId,
            accountCaseId: accountCaseId,
          });
        } else if (editedItem.docType === "Expenses") {
          setEditedItem({
            ...editedItem,
            accountCodeName: name,
            accountCodeId: cardId,
            creditAccount: cardId,
            accountCaseId: accountCaseId,
          });
        } else if (editedItem.docType === "JournalEntries") {
          setEditedItem({
            ...editedItem,
            accountCodeName: name,
            accountCodeId: cardId,
            accountCaseId: accountCaseId,
          });
        }
      }
    }
  };

  // Function to load the surrounding items (previous and next)
  const loadSurroundingItems = (rowIndex, type, transactionArray) => {
    return new Promise((resolve, reject) => {
      const updatedDictionary = { ...transactionDetailsDictionary };
      const promises = [];

      // Load the previous transaction if it exists and is not already loaded
      if (
        rowIndex > 0 &&
        !transactionDetailsDictionary[transactionArray[rowIndex - 1].id]
      ) {
        const previousPromise = DocumentService.GetDocumentById(
          transactionArray[rowIndex - 1].type,
          client.caseId,
          transactionArray[rowIndex - 1].id,
          token
        )
          .then((response) => (response.ok ? response.json() : null))
          .then((data) => {
            if (data) {
              data.docType = transactionArray[rowIndex - 1].type;
              updatedDictionary[transactionArray[rowIndex - 1].id] = data;
              setTransactionDetailsDictionary(updatedDictionary);
            }
          })
          .catch((error) => {
            console.error("Error fetching previous transaction:", error);
            reject(error);
          });

        promises.push(previousPromise);
      }

      // Load the next transaction if it exists and is not already loaded
      if (
        rowIndex < transactionArray.length - 1 &&
        !transactionDetailsDictionary[transactionArray[rowIndex + 1].id]
      ) {
        const nextPromise = DocumentService.GetDocumentById(
          transactionArray[rowIndex + 1].type,
          client.caseId,
          transactionArray[rowIndex + 1].id,
          token
        )
          .then((response) => (response.ok ? response.json() : null))
          .then((data) => {
            if (data) {
              data.docType = transactionArray[rowIndex + 1].type;
              updatedDictionary[transactionArray[rowIndex + 1].id] = data;
              setTransactionDetailsDictionary(updatedDictionary);
            }
          })
          .catch((error) => {
            console.error("Error fetching next transaction:", error);
            reject(error);
          });

        promises.push(nextPromise);
      }

      // Resolve when all promises are complete
      Promise.all(promises)
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  };



  const handleFinalizeEntries = async () => {
    setLoading(true);

    setIsJournalEntriesListOpen(false);
    try {
      const payload = journalEntriesList.map((entry) => ({
        caseId: client.caseId,
        accountantId: client.accountantId,
        sortCodeId: entry.sortCodeId,
        reference: entry.reference,
        description: entry.description,
        amount: Math.abs(entry.amount),
        documentDate: new Date(entry.documentDate).toISOString(),
        reportingMonthDate: reportingMonthDate
          ? new Date(reportingMonthDate).toISOString()
          : null,
        creditAccount: entry.creditAccount,
        debitAccount: entry.debitAccount,
        vat: entry.vat, // Assuming this is fixed
        isMadeFromBankMatch: true,
        addAsProcessed: true,
        externalId: entry.externalId, // Include externalId in the payload
      }));

      const response = await DocumentService.UploadDocumentPayloads(
        "JournalEntries",
        payload,
        token
      );

      if (response.ok) {
        // setShouldMatch(true); // Set flag to trigger handleMatch
        const data = await response.json(); // Parse the response as JSON
        const newJournalEntryIds = data.journalEntryIds; // Extract the new journal entry IDs
        toast.success(t("documentUploadSuccess"));

        const updatedSelectedInternalRows = [...selectedInternalRows, ...newJournalEntryIds];

        // Update the state for selectedInternalRows with new IDs
        setSelectedInternalRows(updatedSelectedInternalRows);


        const newJournalEntries = newJournalEntryIds.map((id) => ({
          id,
          type: "JournalEntries",
        }));

        // Update displayedRows by appending the new journal entries
        const updatedDisplayedRows = [...displayedRows, ...newJournalEntries];

        await handleMatch2(updatedSelectedInternalRows, selectedExternalRows, updatedDisplayedRows, externalRows)


        handleClearMatches();
        await FetchData();
      } else {
        const errorText = await response.text();
        toast.error(t("documentUploadError", { errorText }));
      }
    } catch (error) {
      toast.error(
        t("documentUploadError", { errorText: "Error uploading documents" })
      );
    }

    setLoading(false);
  };



  const setCurrentItem = async (transaction, rowIndex) => {
    try {
      let type = transaction.type1;

      let myItem;

      // Check if transactionDetailsDictionary has the item with the transaction ID
      if (transactionDetailsDictionary[transaction.id]) {
        myItem = transactionDetailsDictionary[transaction.id];
      } else {
        // If not, make an API call to fetch the item
        let response;
        if (transaction.type1 === "JournalEntry") {
          response = await DocumentService.GetDocumentById(
            "JournalEntries",
            client.caseId,
            transaction.id,
            token
          );
          type = transaction.type1;
        } else {
          response = await DocumentService.GetDocumentById(
            type,
            client.caseId,
            transaction.id,
            token
          );
        }

        if (response.ok) {
          myItem = await response.json();
          myItem.docType = type;
          // Store the fetched item in transactionDetailsDictionary with the transaction ID as key
          const updatedDictionary = { ...transactionDetailsDictionary };
          updatedDictionary[transaction.id] = myItem;
          setTransactionDetailsDictionary(updatedDictionary);
        } else {
          console.error("Error fetching document details");
          return; // Exit the function if the API call fails
        }
      }

      // Load the previous and next items asynchronously without await
      loadSurroundingItems(rowIndex, type, filteredAndSortedTransactions);

      if (!type.includes("PaymentAcceptances")) {
        if (transaction.type1 !== "JournalEntries") {
          setCurrentIndex(rowIndex);
          setItemToDelete({ id: transaction.id, type: transaction.docType });
          setEditedItem({ ...myItem });
          setEditedPopupItem(true);
          setEditedJournalItem(false);
          setPaymentModal(false);
        } else {
          setCurrentIndex(rowIndex);
          setItemToDelete({ id: transaction.id, type: transaction.docType });
          setEditedItem({ ...myItem });
          setEditedPopupItem(true);
          setEditedJournalItem(true);
          setPaymentModal(false);
        }
      } else {
        setCurrentIndex(rowIndex);
        setOpenInvoiceCreatorModal(true);
        setAddEditableItem({ ...myItem });
        setPaymentModal(true);
        setEditedJournalItem(false);
        setEditedPopupItem(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getItemAtIndex = (transactions, newIndex) => {
    // Convert the object to an array of its values
    const transactionsArray = Object.values(transactions);

    // Check if the index is within the bounds of the array
    if (newIndex >= 0 && newIndex < transactionsArray.length) {
      return transactionsArray[newIndex];
    } else {
      console.error("Index out of bounds");
      return null;
    }
  };

  const filteredAndSortedTransactions = useMemo(() => {
    if (!transactions || transactions.length === 0) {
      return [];
    }

    // Filtering the transactions based on searchQuery
    const filteredData = transactions.filter((transaction) => {
      const query = searchQuery.toLowerCase();
      return (
        transaction?.description?.toLowerCase().includes(query) ||
        transaction?.reference1?.toLowerCase().includes(query) ||
        transaction?.reference2?.toLowerCase().includes(query) ||
        transaction?.debitAccount?.toString().includes(query) ||
        transaction?.creditAccount?.toString().includes(query) ||
        transaction?.balance?.toString().includes(query) ||
        new Date(transaction.documentDate)
          .toLocaleDateString()
          .includes(query) ||
        new Date(transaction.uploadDate).toLocaleDateString().includes(query)
      );
    });

    // Sorting the filtered transactions based on sortConfig
    if (sortConfig.key) {
      filteredData.sort((a, b) => {
        const aValue = a[sortConfig.key] || ""; // Default to empty string if null or undefined
        const bValue = b[sortConfig.key] || ""; // Default to empty string if null or undefined

        if (sortConfig.type === "number") {
          return sortConfig.direction === "ascending"
            ? aValue - bValue
            : bValue - aValue;
        } else if (sortConfig.type === "date") {
          return sortConfig.direction === "ascending"
            ? new Date(aValue) - new Date(bValue)
            : new Date(bValue) - new Date(aValue);
        } else {
          // default to string comparison
          return sortConfig.direction === "ascending"
            ? aValue?.localeCompare(bValue)
            : bValue?.localeCompare(aValue);
        }
      });
    }
    const updatedDataForPdf = {
      ...dataForPdf,
      transactions: filteredData,
    };
    setSortedDataForPdf(updatedDataForPdf);

    return filteredData;
  }, [sortedTransactions, searchQuery, sortConfig]);

  const handleNext = () => {
    let newIndex = currentIndex;

    while (newIndex < sortedTransactions.length - 1) {
      newIndex += 1;
      const nextItem = sortedTransactions[newIndex];
      const currentItem = sortedTransactions[currentIndex];
      // Check if the next item has a different id
      if (nextItem.id !== currentItem.id) {
        handleEdit(nextItem);
        break; // Exit the loop once a different ID is found
      }
    }
  };

  const handlePrevious = () => {
    let newIndex = currentIndex;


    while (newIndex > 0) {
      newIndex -= 1;
      const previousItem = sortedTransactions[newIndex];
      const currentItem = sortedTransactions[currentIndex];

      // Check if the previous item has a different id
      if (previousItem.id !== currentItem.id) {
        handleEdit(previousItem);
        break;
      }
    }
  };

  const handleInternalExpandClick = (id) => {
    setExpandedInternalRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const InternalRows = [
    {
      id: 1,
      matchNumber: 1001,
      description: "Value 1",
      reference: "Value 2",
      documentDate: "2023-10-15", // Replace 'Value 3' with a valid date
      amount: 100, // Add amount field
      status: "Filled",
      subRows: [
        {
          id: 2,
          matchNumber: 2001,
          description: "Sub Value 1",
          reference: "Sub Value 2",
          documentDate: "2023-10-14", // Replace 'Sub Value 3' with a valid date
          amount: 50, // Add amount field
          status: "Filled",
        },
        {
          id: 3,
          matchNumber: 2002,
          description: "Sub Value 4",
          reference: "Sub Value 5",
          documentDate: "2023-10-13", // Replace 'Sub Value 6' with a valid date
          amount: 75, // Add amount field
          status: "PartiallyFilled",
        },
      ],
    },
    {
      id: 4,
      matchNumber: 1002,
      description: "Value 4",
      reference: "Value 5",
      documentDate: "2023-10-12", // Replace 'Value 6' with a valid date
      amount: 200, // Add amount field
      status: "Rejected",
    },
    // Add more rows if needed
  ];

  const InternalColumns = [
    // Data columns in reverse order for RTL
    {
      field: "description",
      headerName: t("Description"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "reference",
      headerName: t("Reference"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "documentDate",
      headerName: t("Document Date"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortComparator: (v1, v2, param1, param2) => {
        const date1 = new Date(parseCustomDate(v1));
        const date2 = new Date(parseCustomDate(v2));
        return date1 - date2;
      },
    },
    {
      field: "amount",
      headerName: t("Amount"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortComparator: (v1, v2, param1, param2) => {
        // Helper function to parse the amount string
        const parseAmount = (value) => {
          if (!value) return 0; // Return 0 for empty or null values

          // Check if the value has parentheses (indicating a negative number)
          const isNegative = value.includes("(");

          // Remove commas and parentheses, then convert to a float
          let cleanedValue = value.replace(/[(),]/g, "");

          // Convert to a float. If parsing fails, return 0 as fallback
          let amount = parseFloat(cleanedValue);
          if (isNaN(amount)) amount = 0;

          // Return negative if the value had parentheses
          return isNegative ? -amount : amount;
        };

        const amount1 = parseAmount(v1);
        const amount2 = parseAmount(v2);

        // Debugging: log the parsed amounts


        // Ensure consistent sorting: return -1, 0, or 1
        return amount1 === amount2 ? 0 : amount1 < amount2 ? -1 : 1;
      },
    },
    // 'actions' column before 'expand' column
    // {
    //   field: "actions",
    //   headerName: t("Actions"),
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   sortable: false,
    //   filterable: false,
    //   renderCell: (params) => {
    //     const isExpandable =
    //       params.row.subRows && params.row.subRows.length > 0;
    //     return !isExpandable ? (
    //       <IconButton size="small" onClick={() => handleEdit(params.row)}>
    //         <img src={editIconImage} alt="Edit" />
    //       </IconButton>
    //     ) : null;
    //   },
    // },
    // {
    //     field: 'expand',
    //     headerName: '',
    //     headerAlign: 'center',
    //     align: 'center',
    //     width: 50,
    //     sortable: false,
    //     filterable: false,
    //     renderCell: (params) => {
    //         const isExpandable = params.row.subRows && params.row.subRows.length > 0;
    //         return isExpandable ? (
    //             <IconButton
    //                 size="small"
    //                 onClick={() => handleInternalExpandClick(params.row.id)}
    //             >
    //                 {expandedInternalRows[params.row.id] ? <ExpandLess /> : <ExpandMore />}
    //             </IconButton>
    //         ) : null;
    //     },
    // },
  ];

  // Function to get the displayed rows based on expanded state
  const getDisplayedRows = () => {
    const result = [];

    const addRowAndSubRows = (row, level = 0) => {
      result.push({ ...row, level });
      if (expandedInternalRows[row.id] && row.subRows) {
        row.subRows.forEach((subRow) => {
          addRowAndSubRows(subRow, level + 1);
        });
      }
    };

    internalRows.forEach((row) => {
      addRowAndSubRows(row);
    });


    return result;
  };

  // useEffect to update displayedRows whenever internalRows or expandedInternalRows change
  useEffect(() => {


    const updatedDisplayedRows = getDisplayedRows();
    setDisplayedRows(updatedDisplayedRows);
  }, [internalRows]);

  const getFilteredSortedRows = () => {
    // Get the sorted rows
    const sortedRows = apiRef.current.getSortedRows();

    // Access the filteredRowsLookup from the grid state
    const filteredRowsLookup = apiRef.current.state.filter.filteredRowsLookup;

    if (filteredRowsLookup) {
      // Create a Set of IDs of rows that pass the filter
      const filteredRowIdsSet = new Set(
        Object.entries(filteredRowsLookup)
          .filter(([_, isVisible]) => isVisible)
          .map(([id]) => id)
      );

      // Filter the sorted rows to include only those that are in the filteredRowIdsSet
      const filteredSortedRows = sortedRows.filter((row) =>
        filteredRowIdsSet.has(row.id)
      );

      return filteredSortedRows;
    } else {
      // If no filtering is applied, return the sorted rows
      return sortedRows;
    }
  };

  const handleSortModelChange = () => {
    const sortedRows = getFilteredSortedRows();

    setSortedTransactions(sortedRows);
  };

  // const ExternalRows = [
  //     {
  //         id: 1,
  //         matchNumber: 1001,
  //         description: 'Value 1',
  //         reference: 'Value 2',
  //         transactionDate: '2023-10-15', // Replace 'Value 3' with a valid date
  //         amount: 100, // Add amount field
  //         status: 'Open',
  //         subRows: [
  //             {
  //                 id: 5,
  //                 matchNumber: 2001,
  //                 description: 'Sub Value 1',
  //                 reference: 'Sub Value 2',
  //                 transactionDate: '2023-10-14', // Replace 'Sub Value 3' with a valid date
  //                 amount: 50, // Add amount field
  //                 status: 'Filled',
  //             },
  //             {
  //                 id: 6,
  //                 matchNumber: 2002,
  //                 description: 'Sub Value 4',
  //                 reference: 'Sub Value 5',
  //                 transactionDate: '2023-10-13', // Replace 'Sub Value 6' with a valid date
  //                 amount: 75, // Add amount field
  //                 status: 'PartiallyFilled',
  //             },
  //         ],
  //     },
  //     {
  //         id: 2,
  //         matchNumber: 1002,
  //         description: 'Value 4',
  //         reference: 'Value 5',
  //         transactionDate: '2023-10-12', // Replace 'Value 6' with a valid date
  //         amount: 200, // Add amount field
  //         status: 'Filled',
  //     },
  //     {
  //         id: 3,
  //         matchNumber: 1003,
  //         description: 'Value 7',
  //         reference: 'Value 8',
  //         transactionDate: '2023-10-11', // Replace 'Value 9' with a valid date
  //         amount: 150, // Add amount field
  //         status: 'PartiallyFilled',
  //     },
  //     // Add more rows if needed
  // ];

  const ExternalColumns = [
    {
      field: "description",
      headerName: t("Description"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "reference",
      headerName: t("Reference"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "transactionDate",
      headerName: t("Document Date"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortComparator: (v1, v2, param1, param2) => {
        const date1 = new Date(parseCustomDate(v1));
        const date2 = new Date(parseCustomDate(v2));
        return date1 - date2;
      },
    },
    {
      field: "amount",
      headerName: t("Amount"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortComparator: (v1, v2, param1, param2) => {
        // Helper function to parse the amount string
        const parseAmount = (value) => {
          if (!value) return 0; // Return 0 for empty or null values

          // Check if the value has parentheses (indicating a negative number)
          const isNegative = value.includes("(");

          // Remove commas and parentheses, then convert to a float
          let cleanedValue = value.replace(/[(),]/g, "");

          // Convert to a float. If parsing fails, return 0 as fallback
          let amount = parseFloat(cleanedValue);
          if (isNaN(amount)) amount = 0;

          // Return negative if the value had parentheses
          return isNegative ? -amount : amount;
        };

        const amount1 = parseAmount(v1);
        const amount2 = parseAmount(v2);

        // Debugging: log the parsed amounts


        // Ensure consistent sorting: return -1, 0, or 1
        return amount1 === amount2 ? 0 : amount1 < amount2 ? -1 : 1;
      },
    },

    // {
    //     field: 'expand',
    //     headerName: '',
    //     width: 50,
    //     sortable: false,
    //     filterable: false,
    //     renderCell: (params) => {
    //         const isExpandable = params.row.subRows && params.row.subRows.length > 0;
    //         return isExpandable ? (
    //             <IconButton
    //                 size="small"
    //                 onClick={() => handleExternalExpandClick(params.row.id)}
    //             >
    //                 {expandedExternalRows[params.row.id] ? <ExpandLess /> : <ExpandMore />}
    //             </IconButton>
    //         ) : null;
    //     },
    // },
  ];
  const handleExternalExpandClick = (id) => {
    setExpandedExternalRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const getExternalDisplayedRows = () => {
    const result = [];

    const addRowAndSubRows = (row, level = 0) => {
      result.push({ ...row, level });
      if (expandedExternalRows[row.id] && row.subRows) {
        row.subRows.forEach((subRow) => {
          addRowAndSubRows(subRow, level + 1);
        });
      }
    };

    externalRows.forEach((row) => {
      addRowAndSubRows(row);
    });

    return result;
  };

  const externalDisplayedRows = getExternalDisplayedRows();

  // Function to handle sorting, collapse all rows before sorting, and restore after sorting
  const handleSort = (columnField) => {
    // Store the current expanded rows
    const previouslyExpandedRows = { ...expandedMatchedRows };

    // Collapse all rows before sorting
    setExpandedMatchedRows({});

    // Perform the sorting
    const sortedRows = [...getMatchedDisplayedRows()].sort((a, b) => {
      const valueA = a[columnField];
      const valueB = b[columnField];

      // Example of comparing values (you can modify it based on the field type)
      if (valueA === undefined) return 1;
      if (valueB === undefined) return -1;
      return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
    });

    // After sorting, update matchData with sorted rows
    setMatchData(sortedRows);

    // Restore the expanded rows after sorting
    setTimeout(() => {
      setExpandedMatchedRows(previouslyExpandedRows);
    }, 0);
  };

  // Matched Columns
  const MatchedColumns = [
    {
      field: "matchNumber",
      headerName: t("MatchNumber"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (!params.row.isSubRow ? params.value : ""),
      sortComparator: (v1, v2, param1, param2) => {
        const row1 = param1.row || {};
        const row2 = param2.row || {};

        if (row1.isSubRow && row2.isSubRow) {
          return 0;
        }
        if (row1.isSubRow && !row2.isSubRow) {
          return 1;
        }
        if (!row1.isSubRow && row2.isSubRow) {
          return -1;
        }

        const matchNumber1 = v1 !== undefined ? v1 : 0;
        const matchNumber2 = v2 !== undefined ? v2 : 0;
        return matchNumber1 === matchNumber2
          ? 0
          : matchNumber1 < matchNumber2
            ? -1
            : 1;
      },
    },
    {
      field: "transactionDate",
      headerName: t("TransactionDate"),
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const row = params.row;
        if (row.isSubRow && !row.isHeader) {
          return formatDate(row.documentDate);
        } else if (!row.isSubRow) {
          return formatDate(row.transactionDate);
        } else {
          return "";
        }
      },
      sortComparator: (v1, v2, param1, param2) => {
        const date1 = new Date(v1);
        const date2 = new Date(v2);
        return date1 - date2;
      },
    },
    {
      field: "matchDate",
      headerName: t("MatchDate"),
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        !params.row.isSubRow ? formatDate(params.value) : "",
      sortComparator: (v1, v2, param1, param2) => {
        const date1 = new Date(v1);
        const date2 = new Date(v2);
        return date1 - date2;
      },
    },
    {
      field: "description",
      headerName: t("Description"),
      width: 200,
      headerAlign: "center",
      align: "left",
      renderCell: (params) => {
        const row = params.row;
        const indentation = row.level * 20;
        if (row.isHeader) {
          return (
            <div
              style={{ paddingLeft: `${indentation}px`, fontWeight: "bold" }}
            >
              {t(row.transactionType)}
            </div>
          );
        } else if (row.isSubRow) {
          return (
            <div style={{ paddingLeft: `${indentation}px` }}>
              {row.description}
            </div>
          );
        } else {
          return "";
        }
      },
      sortable: true,
      sortComparator: () => 0, // Disable default sorting
    },
    {
      field: "reference",
      headerName: t("Reference"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const row = params.row;
        if (row.isSubRow && !row.isHeader) {
          return row.reference || "";
        } else {
          return "";
        }
      },
      sortable: true,
      sortComparator: () => 0, // Disable default sorting
    },
    {
      field: "documentDate",
      headerName: t("Document Date"),
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const row = params.row;
        if (row.isSubRow && !row.isHeader) {
          return formatDate(row.documentDate);
        } else {
          return "";
        }
      },
      sortable: true,
      sortComparator: () => 0, // Disable default sorting
    },
    // {
    //   field: "amount",
    //   headerName: t("Amount"),
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     const calculateTotalAmount = (transactions) => {
    //       return transactions.reduce((sum, transaction) => sum + (transaction.amount || 0), 0);
    //     };
    //     const row = params.row;
    //     if (row.isSubRow && !row.isHeader) {
    //       // Return the beautified amount for sub-rows
    //       return beautifyNumber(row.amount);
    //     } else {
    //       if (row.externalTransactions && row.externalTransactions.length > 0) {
    //         const totalAmount = calculateTotalAmount(row.externalTransactions);
    //         return beautifyNumber(totalAmount);
    //       } else if (row.internalTransactions && row.internalTransactions.length > 0) {
    //         const totalAmount = calculateTotalAmount(row.internalTransactions);
    //         return beautifyNumber(totalAmount);
    //       } else {
    //         return beautifyNumber(0);
    //       }
    //     }
    //   },
    //   valueGetter: (params) => {
    //     if (!params || !params.row) return 0; // Safeguard against undefined params or row

    //     const calculateTotalAmount = (transactions) => {
    //       return transactions.reduce((sum, transaction) => sum + (transaction.amount || 0), 0);
    //     };

    //     const row = params.row;

    //     if (row.isSubRow && !row.isHeader) {
    //       return row.amount || 0;
    //     } else {
    //       if (row.externalTransactions && row.externalTransactions.length > 0) {
    //         return calculateTotalAmount(row.externalTransactions);
    //       } else if (row.internalTransactions && row.internalTransactions.length > 0) {
    //         return calculateTotalAmount(row.internalTransactions);
    //       } else {
    //         return 0;
    //       }
    //     }
    //   },

    //   sortComparator: (v1, v2) => {

    //     // Convert beautified numbers back to raw numbers for comparison
    //     const parseNumber = (value) => {
    //       if (typeof value === 'string') {
    //         return parseFloat(
    //           value.replace(/[()]/g, '').replace(/,/g, '')
    //         ) * (value.includes('(') ? -1 : 1);
    //       }
    //       return value || 0;
    //     };
    //     const n1 = beautifyNumber(v1);
    //     const n2 = beautifyNumber(v2);
    //     const num1 = parseNumber(n1);
    //     const num2 = parseNumber(n2);
    //     return num1 - num2; // Standard ascending sort
    //   },
    // },

    {
      field: "amount",
      headerName: t("Amount"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const calculateTotalAmount = (transactions) => {
          return transactions.reduce((sum, transaction) => sum + (transaction.amount || 0), 0);
        };

        const row = params.row;
        if (row.isSubRow && !row.isHeader) {
          // Return the beautified amount for sub-rows
          return beautifyNumber(row.amount);
        } else {
          if (row.externalTransactions && row.externalTransactions.length > 0) {
            const totalAmount = calculateTotalAmount(row.externalTransactions);
            return beautifyNumber(totalAmount);
          } else if (row.internalTransactions && row.internalTransactions.length > 0) {
            const totalAmount = calculateTotalAmount(row.internalTransactions);
            return beautifyNumber(totalAmount);
          } else {
            return beautifyNumber(0);
          }
        }
      },
      valueGetter: (params) => {

        if (!params || !params.row) return 0; // Safeguard against undefined params or row

        const calculateTotalAmount = (transactions) => {
          return transactions.reduce((sum, transaction) => sum + (transaction.amount || 0), 0);
        };

        const row = params.row;

        if (row.isSubRow && !row.isHeader) {
          return row.amount || 0; // Return numeric value
        } else {
          if (row.externalTransactions && row.externalTransactions.length > 0) {
            return calculateTotalAmount(row.externalTransactions);
          } else if (row.internalTransactions && row.internalTransactions.length > 0) {
            return calculateTotalAmount(row.internalTransactions);
          } else {
            return 0;
          }
        }
      },
      // sortComparator: (v1, v2) => {

      //   // Direct numeric comparison since valueGetter returns numeric values
      //   return v1 - v2;
      // },
    },
    {
      field: "type",
      headerName: t("Type"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const row = params.row;
        if (row.isSubRow && !row.isHeader) {
          const typeParts = row.type.split("-"); // Split the type by '-'
          if (typeParts.length > 1) {
            // Translate the second part (Checks, etc.)
            const translatedSecondPart = t(typeParts[1]);
            return translatedSecondPart;
          } else {
            return t(row.type); // If there is no hyphen, just return the type
          }
        } else {
          return "";
        }
      },
      sortable: true,
      sortComparator: () => 0, // Disable default sorting
    },
    {
      field: "uploadNumber",
      headerName: t("UploadNumber"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const row = params.row;
        if (row.isSubRow && !row.isHeader) {
          return row.uploadNumber;
        } else {
          return "";
        }
      },
      sortable: true,
      sortComparator: () => 0, // Disable default sorting
    },
    {
      field: "actions",
      headerName: t("Actions"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const row = params.row;
        if (!row.isSubRow) {
          return (
            <Tooltip title={t("RemoveMatch")}>
              <IconButton
                size="small"
                onClick={() => handleRemoveMatchClick(row)}
              >
                <CancelRounded style={{ color: "#304FFF" }} />
              </IconButton>
            </Tooltip>
          );
        } else {
          return null;
        }
      },
    },
    {
      field: "expand",
      headerName: "",
      width: 50,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const row = params.row;
        if (!row.isSubRow) {
          const isExpandable =
            (row.externalTransactions && row.externalTransactions.length > 0) ||
            (row.internalTransactions && row.internalTransactions.length > 0);
          return isExpandable ? (
            <IconButton
              size="small"
              onClick={() => handleMatchedExpandClick(row.id)}
            >
              {expandedMatchedRows[row.id] ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          ) : null;
        } else {
          return null;
        }
      },
    },
  ];




  /**
  * Checks if a parent row's fields match the searchTerm
  * (matchNumber, description, reference, amount, transactionDate, matchDate, etc.).
  */
  const parentMatchesSearch = (row, lowerSearch) => {
    // 1) Description
    const desc = (row.description ?? "").toLowerCase();
    const descMatch = desc.includes(lowerSearch);

    // 2) Reference
    const ref = (row.reference ?? "").toLowerCase();
    const refMatch = ref.includes(lowerSearch);

    // 3) Match Number (use original searchTerm if you want numeric partial matches)
    const matchNumStr = row.matchNumber != null ? row.matchNumber.toString() : "";


    const matchNumMatch = matchNumStr.includes(lowerSearch);

    // 4) Amount (handle negative/positive)
    const amountStr = row.amount != null ? row.amount.toString().toLowerCase() : "";
    const amountMatch = amountStr.includes(lowerSearch);

    // 5) Transaction Date (treat the raw string "2024-07-22T00:00:00" as text)
    const transactionDateStr = (row.transactionDate ?? "").toLowerCase();
    const transactionDateMatch = formatDate(transactionDateStr).includes(lowerSearch);

    // 6) Match Date
    const matchDateStr = (row.matchDate ?? "").toLowerCase();
    const matchDateMatch = formatDate(matchDateStr).includes(lowerSearch);

    return (
      descMatch ||
      refMatch ||
      matchNumMatch ||
      amountMatch ||
      transactionDateMatch ||
      matchDateMatch
    );
  };

  /**
  * Checks if a sub-row (external or internal transaction) matches the searchTerm.
  * (description, reference, amount, etc.)
  */
  const subRowMatchesSearch = (tx, lowerSearch) => {
    const desc = (tx.description ?? "").toLowerCase();
    const descMatch = desc.includes(lowerSearch);

    const ref = (tx.reference ?? "").toLowerCase();
    const refMatch = ref.includes(lowerSearch);

    // For amount, also do case-insensitive matching (mainly for negative sign).
    const amountStr = tx.amount != null ? tx.amount.toString().toLowerCase() : "";
    const amountMatch = amountStr.includes(lowerSearch);

    // If sub-rows also had transactionDate, documentDate, etc., do similarly:
    // const docDateStr = (tx.documentDate ?? "").toLowerCase();
    // const docDateMatch = docDateStr.includes(lowerSearch);

    return descMatch || refMatch || amountMatch;
  };

  /**
   * getMatchedDisplayedRows:
   * 1. Sort parent rows (if sortField is a parent field).
   * 2. For each parent row:
   *    - Check if parent matches search.
   *    - Filter sub-rows for matches if parent is not a match
   *    - Only add parent + sub-rows if there's at least one match total
   */
  /**
  * getMatchedDisplayedRows:
  * 1. Sort parent rows (if sortField is a parent field).
  * 2. For each parent row:
  *    - Check if parent matches search (including matchDate, transactionDate).
  *    - Filter sub-rows for matches if parent doesn't match
  *    - Only add parent + sub-rows if there's at least one match total
  */
  /**
  * getMatchedDisplayedRows:
  * 1) Sort parent rows if the selected column is a parent field.
  * 2) For each parent row:
  *   - Check if parent matches the searchTerm.
  *   - Filter sub-rows for matches.
  *   - Keep the parent row if it or any sub-row matches (and expand if needed).
  *   - Flatten into finalRows (parent + optional header rows + sub-rows).
  */
  const getMatchedDisplayedRows = () => {
    // 1) Determine sort
    let sortField = null;
    let sortDirection = "asc";

    if (sortModel && sortModel.length > 0) {
      sortField = sortModel[0].field;  // e.g. "matchNumber"
      sortDirection = sortModel[0].sort; // "asc" or "desc"
    }

    const parentSortFields = ["matchNumber", "transactionDate", "matchDate"];
    const isParentSortField = parentSortFields.includes(sortField);

    // 2) Sort the parent rows if needed
    const sortedData = [...matchData].sort((a, b) => {
      if (isParentSortField && sortField) {
        const valueA = a[sortField];
        const valueB = b[sortField];
        if (valueA == null) return 1;
        if (valueB == null) return -1;
        if (valueA > valueB) return sortDirection === "asc" ? 1 : -1;
        if (valueA < valueB) return sortDirection === "asc" ? -1 : 1;
        return 0;
      }
      // Otherwise keep the original order
      return 0;
    });

    const finalRows = [];
    const lowerSearch = searchTerm.toLowerCase().trim();

    // 3) Build each parent + sub-rows
    sortedData.forEach((parent) => {
      // Copy external and internal arrays (to avoid mutating parent arrays)
      let externalCopy = parent.externalTransactions || [];
      let internalCopy = parent.internalTransactions || [];

      // 4) Sort sub-rows if needed (when sortField is a child-level field)
      if (!isParentSortField && sortField) {
        externalCopy = [...externalCopy].sort((a, b) => {
          const valA = a[sortField];
          const valB = b[sortField];
          if (valA == null) return 1;
          if (valB == null) return -1;
          if (valA > valB) return sortDirection === "asc" ? 1 : -1;
          if (valA < valB) return sortDirection === "asc" ? -1 : 1;
          return 0;
        });
        internalCopy = [...internalCopy].sort((a, b) => {
          const valA = a[sortField];
          const valB = b[sortField];
          if (valA == null) return 1;
          if (valB == null) return -1;
          if (valA > valB) return sortDirection === "asc" ? 1 : -1;
          if (valA < valB) return sortDirection === "asc" ? -1 : 1;
          return 0;
        });
      }

      // 5) Check for matches
      //    We only do these checks if there's an actual search term.
      //    If there's no searchTerm, everything automatically matches.
      let parentMatched = false;
      let anyExternalMatched = false;
      let anyInternalMatched = false;

      if (lowerSearch) {
        // Parent matched?
        parentMatched = parentMatchesSearch(parent, lowerSearch);

        // Do any children match?
        anyExternalMatched = externalCopy.some((tx) => subRowMatchesSearch(tx, lowerSearch));
        anyInternalMatched = internalCopy.some((tx) => subRowMatchesSearch(tx, lowerSearch));
      }

      // If there's no search term at all, we keep everything
      // Otherwise keep if the parent or any child matches
      const keepParent =
        !lowerSearch ||
        parentMatched ||
        anyExternalMatched ||
        anyInternalMatched;

      // 6) If we don't keep the parent, skip
      if (!keepParent) {
        return;
      }

      // 7) Push the parent row
      finalRows.push({
        ...parent,
        level: 0,
        isSubRow: false,
      });

      // 8) If expanded, push ALL children (because we want the "complete entity")
      if (expandedMatchedRows[parent.id]) {
        // External sub-rows
        if (externalCopy.length > 0) {
          finalRows.push({
            id: `${parent.id}-external-header`,
            parentId: parent.id,
            isSubRow: true,
            isHeader: true,
            level: 1,
            transactionType: t("BankCommands"),
          });
          externalCopy.forEach((tx) => {
            finalRows.push({
              ...tx,
              parentId: parent.id,
              isSubRow: true,
              isHeader: false,
              level: 2,
              transactionType: "External",
            });
          });
        }

        // Internal sub-rows
        if (internalCopy.length > 0) {
          finalRows.push({
            id: `${parent.id}-internal-header`,
            parentId: parent.id,
            isSubRow: true,
            isHeader: true,
            level: 1,
            transactionType: t("BookCommands"),
          });
          internalCopy.forEach((tx) => {
            finalRows.push({
              ...tx,
              parentId: parent.id,
              isSubRow: true,
              isHeader: false,
              level: 2,
              transactionType: "Internal",
            });
          });
        }
      }
    });

    return finalRows;
  };

  // Finally, memoize the result
  const matchedDisplayedRows = useMemo(() => {
    return getMatchedDisplayedRows();
    // Rerun if data, expansion, sorting, or the search term changes
  }, [matchData, expandedMatchedRows, sortModel, searchTerm]);


  // Handle Create Journal Entries checkbox
  const handleCreateJournalEntries = (checked) => {
    // Case 1: Selected rows from externalDisplayedRows
    const selectedRows = externalDisplayedRows.filter((row) =>
      selectedExternalRows.includes(row.id)
    );


    // Open the create journal entries dialog
    setCreateJournalEntries(checked);
    setIsCreateDialogOpen(true);

    if (!checked) {
      if (
        selectedExternalRows.length > 0
      ) {
        // Case 1: Both selectedInternalRows and selectedExternalRows are not empty

        const oldestRow = selectedRows.reduce((oldest, current) => {
          return new Date(parseCustomDate(current.transactionDate)) <
            new Date(parseCustomDate(oldest.transactionDate))
            ? current
            : oldest;
        }, selectedRows[0]); // Initialize with the first row

        // Get the oldest transactionDate and description
        const oldestDate = new Date(parseCustomDate(oldestRow.transactionDate))
          .toISOString()
          .split("T")[0];
        const oldestDescription = oldestRow.description;

        // Calculate the total sum
        const Sum = externalSum - internalSum;

        // Handle the logic for assigning the creditAccount or debitAccount based on externalSum
        if (Sum < 0) {
          handleSelectChange("creditAccount", selectedCardId); // Set creditAccount if externalSum is negative
        } else {
          handleSelectChange("debitAccount", selectedCardId); // Set debitAccount if externalSum is positive
        }

        // Set form values
        handleSelectChange("amount", Sum);
        handleSelectChange("reference", oldestRow.reference);
        handleSelectChange("description", oldestDescription);
        handleSelectChange("documentDate", oldestDate);
        handleSelectChange("reportingMonthDate", new Date(oldestDate));
      } else if (
        selectedInternalRows.length > 0 &&
        selectedExternalRows.length === 0
      ) {
        // Case 2: selectedInternalRows is not empty, selectedExternalRows is empty
        const filteredRows = displayedRows.filter((row) =>
          selectedInternalRows.includes(row.id)
        );

        const oldestRow = filteredRows.reduce((oldest, current) => {
          return new Date(parseCustomDate(current.documentDate)) <
            new Date(parseCustomDate(oldest.documentDate))
            ? current
            : oldest;
        }, filteredRows[0]); // Initialize with the first row

        // Get the oldest documentDate and description
        const oldestDate = new Date(parseCustomDate(oldestRow.documentDate))
          .toISOString()
          .split("T")[0];
        const oldestDescription = oldestRow.description;

        // Calculate the total sum
        const Sum = externalSum - internalSum;

        // Handle the logic for assigning the creditAccount or debitAccount based on externalSum
        if (Sum < 0) {
          handleSelectChange("creditAccount", selectedCardId); // Set creditAccount if externalSum is negative
        } else {
          handleSelectChange("debitAccount", selectedCardId); // Set debitAccount if externalSum is positive
        }

        // Set form values
        handleSelectChange("amount", Sum);
        handleSelectChange("description", oldestDescription);
        handleSelectChange("documentDate", oldestDate);
        handleSelectChange("reportingMonthDate", new Date(oldestDate));
      }
    } else {
      // Case 2: Checked is true (new behavior)
      // Reset form values
      setFormValues({
        confirmationNumber: "",
        accountCaseId: "",
        reference: "",
        description: "",
        documentDate: "",
        reportingMonthDate: new Date(),
        amount: "",
        vat: 0,
        sortCodeId: "3",
        creditAccount: "",
        debitAccount: "",
        sortCode: "3",
        accountCard: "",
      });
    }
  };


  const handleMatchEntries = async () => {
    const externalMatchedIds = matchedPairs.map((match) => match.externalId);
    const internalMatchedIds = matchedPairs.map((match) => match.internalId);

    // Prepare the DTO for all matches
    const matchDTOs = matchedPairs.map((pair) => {
      const externalRow = externalDisplayedRows.find(
        (row) => row.id === pair.externalId
      );
      const internalRow = displayedRows.find(
        (row) => row.id === pair.internalId
      );

      // Get the oldest transaction date from external rows
      const oldestTransaction = externalDisplayedRows.reduce(
        (oldest, current) => {
          return new Date(parseCustomDate(current.transactionDate)) <
            new Date(parseCustomDate(oldest.transactionDate))
            ? current
            : oldest;
        },
        externalDisplayedRows[0]
      );

      const oldestTransactionDate = oldestTransaction
        ? new Date(parseCustomDate(oldestTransaction.transactionDate))
        : new Date();

      return {
        CaseId: client.caseId,
        AccountCodeId: selectedCardId,
        TransactionDate: oldestTransactionDate.toISOString(),
        ExternalTransactionIds: [{ Id: pair.externalId }],
        InternalTransactions: [{ Id: pair.internalId, Type: internalRow.type }],
      };
    });

    try {
      // Call the API to create multiple matches
      await ExternalTransactionsService.createMultipleMatches(matchDTOs, token);

      handleClearMatches();
      await FetchData();
      toast.success(t("MatchesCreatedSuccessfully"));
    } catch (error) {
      console.error("Error creating matches:", error);
      toast.error(t("ErrorCreatingMatches"));
    }
  };

  // Define a set of colors for matched pairs
  const matchColors = [
    "#FFCDD2", // Light Red
    "#C8E6C9", // Light Green
    "#BBDEFB", // Light Blue
    "#FFF9C4", // Light Yellow
    "#D1C4E9", // Light Purple
    "#FFECB3", // Light Orange
    "#B2DFDB", // Light Teal
  ];

  // Function to get color based on matchId
  const getMatchColor = (matchId) => {
    const index = parseInt(matchId.split("-")[1], 10);
    return matchColors[index % matchColors.length];
  };

  // Function to handle automatic matching

  const computeSimilarity = (externalRow, internalRow, selectedColumns) => {
    let score = 1; // Start with full score assuming all selected columns match.

    // Iterate over the selected columns and check for exact match
    for (const column of selectedColumns) {
      let externalValue = externalRow[column];
      let internalValue = internalRow[column];

      // Special handling for 'amount' column
      if (column === "amount") {
        const tolerance = 0.01; // Tolerance for amount comparison
        const externalAmount = parseFloat(externalRow.amount1);
        const internalAmount = parseFloat(internalRow.amount1);

        if (isNaN(externalAmount) || isNaN(internalAmount)) {
          return 0; // If either amount is not a valid number, it's not a match.
        } else {
          const diff = Math.abs(externalAmount - internalAmount);
          if (diff > tolerance) {
            return 0; // If the amounts differ more than the tolerance, it's not a match.
          }
        }
      } else {
        // For other columns, perform case-insensitive string comparison
        externalValue = externalValue
          ? String(externalValue).toLowerCase()
          : "";
        internalValue = internalValue
          ? String(internalValue).toLowerCase()
          : "";

        if (externalValue !== internalValue) {
          return 0; // If any column doesn't match exactly, return 0 (not a match).
        }
      }
    }

    return score; // If all selected columns match, return the full score (1).
  };

  const handleAutoMatchHeaders = (selectedColumns) => {
    if (!selectedColumns.includes("amount")) {
      alert("Please include the Amount column in your selection.");
      return;
    }

    const matches = [];
    const updatedExternalRows = [...externalRows];
    const updatedInternalRows = [...internalRows];
    let matchIdCounter = matchCounter;

    // Collect potential matches with similarity scores
    const potentialMatches = [];

    externalRows.forEach((externalRow, extIndex) => {
      internalRows.forEach((internalRow, intIndex) => {
        const score = computeSimilarity(
          externalRow,
          internalRow,
          selectedColumns
        );

        if (score > 0) {
          potentialMatches.push({
            externalRow,
            internalRow,
            score,
            externalIndex: extIndex,
            internalIndex: intIndex,
          });
        }
      });
    });

    // Sort potential matches by descending similarity score
    potentialMatches.sort((a, b) => b.score - a.score);

    // Perform one-to-one matching
    const matchedExternalIds = new Set(); // To track matched external rows
    const matchedInternalIds = new Set(); // To track matched internal rows

    for (const match of potentialMatches) {
      const { externalRow, internalRow, score, externalIndex, internalIndex } =
        match;

      // Define a threshold for accepting matches
      const threshold = 0.5; // Adjust as needed

      if (
        !matchedExternalIds.has(externalRow.id) && // Check if the external row is already matched
        !matchedInternalIds.has(internalRow.id) && // Check if the internal row is already matched
        score >= threshold
      ) {
        const matchId = `match-${matchIdCounter++}`;
        const colorIndex =
          parseInt(matchId.split("-")[1], 10) % matchColors.length;
        const rowClassName = `super-app-theme--matched-${colorIndex}`;

        // Update external row
        updatedExternalRows[externalIndex] = {
          ...externalRow,
          matchId,
          rowClassName,
        };

        // Update internal row
        updatedInternalRows[internalIndex] = {
          ...internalRow,
          matchId,
          rowClassName,
        };

        matches.push({
          externalId: externalRow.id,
          internalId: internalRow.id,
          matchId,
        });

        // Mark rows as matched
        matchedExternalIds.add(externalRow.id);
        matchedInternalIds.add(internalRow.id);
      }
    }

    // Sort the rows so that matched rows are placed at the top
    const reorderRows = (rows) => {
      return rows.sort((a, b) => {
        if (a.matchId && !b.matchId) return -1; // a is matched, b is not
        if (!a.matchId && b.matchId) return 1; // b is matched, a is not
        return 0; // Both are matched or both are unmatched, keep original order
      });
    };

    const reorderedExternalRows = reorderRows(updatedExternalRows);
    const reorderedInternalRows = reorderRows(updatedInternalRows);

    setMatchedPairs(matches);
    setExternalRows(reorderedExternalRows);
    setInternalRows(reorderedInternalRows);

    setMatchCounter(matchIdCounter);

    // Update selection state
    const externalMatchedIds = matches.map((match) => match.externalId);
    const internalMatchedIds = matches.map((match) => match.internalId);

    setSelectedExternalRows(externalMatchedIds);
    setSelectedInternalRows(internalMatchedIds);
  };

  // Function to clear matches
  const handleClearMatches = () => {
    const clearedExternalRows = externalRows.map((row) => {
      const newRow = { ...row };
      delete newRow.matchId;
      delete newRow.rowClassName;
      return newRow;
    });

    const clearedInternalRows = internalRows.map((row) => {
      const newRow = { ...row };
      delete newRow.matchId;
      delete newRow.rowClassName;
      return newRow;
    });

    setExternalRows(clearedExternalRows);
    setInternalRows(clearedInternalRows);
    setSelectedExternalRows([]);
    setSelectedInternalRows([]);
    setMatchedPairs([]);
    setFormValues({
      confirmationNumber: "",
      accountCaseId: "",
      reference: "",
      description: "",
      documentDate: "",
      reportingMonthDate: "",
      amount: "",
      vat: 0,
      sortCodeId: "3",
      creditAccount: "",
      debitAccount: "",
      sortCode: "3",
      accountCard: "",
    });
  };

  // Function to handle Match action
  const handleMatch = async (selectedInternalRows) => {
    // This contains the list of selected external transaction IDs
    // This contains the list of selected internal transaction IDs

    // Filter the complete row objects from externalDisplayedRows based on selectedExternalRows IDs
    const selectedExternalRowsData = externalDisplayedRows.filter((row) =>
      selectedExternalRows.includes(row.id)
    );

    // Filter the complete row objects from internalDisplayedRows based on selectedInternalRows IDs
    const selectedInternalRowsData = displayedRows.filter((row) =>
      selectedInternalRows.includes(row.id)
    );

    // Extract external transactions and internal transactions from the filtered rows
    const externalTransactionIds = selectedExternalRowsData.map((row) => ({
      Id: row.id,
    }));
    const internalTransactions = selectedInternalRowsData.map((row) => ({
      Id: row.id,
      Type: row.type,
    }));

    // Find the row with the oldest transactionDate in selectedExternalRowsData
    const oldestTransaction = selectedExternalRowsData.reduce(
      (oldest, current) => {
        return new Date(parseCustomDate(current.transactionDate)) <
          new Date(parseCustomDate(oldest.transactionDate))
          ? current
          : oldest;
      },
      selectedExternalRowsData[0]
    );

    const oldestTransactionDate = oldestTransaction
      ? new Date(parseCustomDate(oldestTransaction.transactionDate))
      : new Date();

    // Prepare the DTO
    const matchDTO = {
      CaseId: client.caseId, // Replace with actual CaseId variable
      AccountCodeId: selectedCardId, // Replace with actual AccountCodeId variable
      TransactionDate: oldestTransactionDate.toISOString(), // Use the oldest transaction date
      ExternalTransactionIds: externalTransactionIds,
      InternalTransactions: internalTransactions,
    };

    try {
      // Send the request to create the match
      const response = await ExternalTransactionsService.CreateMatch(
        matchDTO,
        token
      ); // Pass auth token if necessary
      if (response.ok) {
        // Optionally refresh data or update UI
        toast.success(t("MatchCreatedSuccessfully"));
        handleClearMatches();
        await FetchData();
      } else {
        console.error("Failed to create match:", response.statusText);
        toast.error(t("ErrorCreatingMatch"));
      }
    } catch (error) {
      console.error("Error creating match:", error);
      toast.error(t("ErrorCreatingMatch"));
    }
  };

  const handleMatch2 = async (selectedInternalRows, selectedExternalRows, internalDisplayedRows = displayedRows, externalDisplayedRows = externalRows) => {
    // This contains the list of selected external transaction IDs
    // This contains the list of selected internal transaction IDs


    // Filter the complete row objects from externalDisplayedRows based on selectedExternalRows IDs
    const selectedExternalRowsData = externalDisplayedRows.filter((row) =>
      selectedExternalRows.includes(row.id)
    );

    // Filter the complete row objects from internalDisplayedRows based on selectedInternalRows IDs
    const selectedInternalRowsData = internalDisplayedRows.filter((row) =>
      selectedInternalRows.includes(row.id)
    );

    // Extract external transactions and internal transactions from the filtered rows
    const externalTransactionIds = selectedExternalRowsData.map((row) => ({
      Id: row.id,
    }));
    const internalTransactions = selectedInternalRowsData.map((row) => ({
      Id: row.id,
      Type: row.type,
    }));


    // Find the row with the oldest transactionDate in selectedExternalRowsData
    const oldestTransaction = selectedExternalRowsData.reduce(
      (oldest, current) => {
        return new Date(parseCustomDate(current.transactionDate)) <
          new Date(parseCustomDate(oldest.transactionDate))
          ? current
          : oldest;
      },
      selectedExternalRowsData[0]
    );

    const oldestTransactionDate = oldestTransaction
      ? new Date(parseCustomDate(oldestTransaction.transactionDate))
      : new Date();

    // Prepare the DTO
    const matchDTO = {
      CaseId: client.caseId, // Replace with actual CaseId variable
      AccountCodeId: selectedCardId, // Replace with actual AccountCodeId variable
      TransactionDate: oldestTransactionDate.toISOString(), // Use the oldest transaction date
      ExternalTransactionIds: externalTransactionIds,
      InternalTransactions: internalTransactions,
    };

    try {
      // Send the request to create the match
      const response = await ExternalTransactionsService.CreateMatch(
        matchDTO,
        token
      ); // Pass auth token if necessary
      if (response.ok) {
        // Optionally refresh data or update UI
        toast.success(t("MatchCreatedSuccessfully"));
        // handleClearMatches();
        // await FetchData();
      } else {
        console.error("Failed to create match:", response.statusText);
        toast.error(t("ErrorCreatingMatch"));
      }
    } catch (error) {
      console.error("Error creating match:", error);
      toast.error(t("ErrorCreatingMatch"));
    }
  };

  // Close dialog
  const handleCloseDialog = () => {
    setIsCreateDialogOpen(false);
    setFormValues({
      confirmationNumber: "",
      accountCaseId: "",
      reference: "",
      description: "",
      documentDate: "",
      reportingMonthDate: new Date(),
      amount: "",
      vat: 0,
      sortCodeId: "3",
      creditAccount: "",
      debitAccount: "",
      sortCode: "3",
      accountCard: "",
    });
  };




  const handleEndDateChangeNew = async (newValue) => {
    const newDate = new Date(newValue);

    const updatedEndDate = new Date(Date.UTC(newDate.getUTCFullYear(), 11, 30, 23, 59, 59));
    const updatedStartDate = new Date(Date.UTC(newDate.getUTCFullYear(), 0, 1, 0, 0, 0));

    // Save the updated date in localStorage for persistence
    localStorage.setItem('EndDate', updatedEndDate);
    localStorage.setItem('StartDate', updatedStartDate);

    setEndDate(updatedEndDate);
    setStartDate(updatedStartDate);
    if (fetchType === "Matched") {
      await fetchMatchDataOnDateRangeChange(
        updatedStartDate,
        updatedEndDate,
        isMatchDate
      );
    } else {
      await fetchNotMatchDataOnDateRangeChange(
        updatedStartDate,
        updatedEndDate,
      );
    }
  };

  const handleChangeCreateBank = (field, value) => {
    if (field === "bankBranch") {
      setBankBranch(value);
    } else if (field === "bankAccountNumber") {
      setBankAccountNumber(value);
    } else if (field === "itemName") {
      // setItemName(value);
    }
  };

  const handleRangeChange = async () => {
    if (fetchType === "Matched") {
      await fetchMatchDataOnDateRangeChange(
        startDate,
        endDate,
        !isMatchDate
      );
    } else {
      await fetchNotMatchDataOnDateRangeChange(
        startDate,
        endDate,
      );
    }
    setIsMatchDate(!isMatchDate);
    localStorage.setItem("IsRange", !isMatchDate);
  };

  async function fetchMatchDataOnDateRangeChange(
    startDate,
    endDate,
    isMatchDate
  ) {
    setLoading(true);
    const formattedStartDate = startDate;
    const formattedEndDate = endDate;

    try {
      const externalMatchResponse =
        await ExternalTransactionsService.GetMatchedbyDateRange(
          client.caseId,
          formattedStartDate,
          formattedEndDate,
          selectedCardId,
          isMatchDate,
          token
        );

      if (externalMatchResponse.ok) {
        const externalMatchData = await externalMatchResponse.json();

        // Update matchData state
        setMatchData(externalMatchData);
      }
    } catch (error) {
      console.error("Error fetching match data:", error);
    }

    setLoading(false);
  }
  const handleRemoveBank = async () => {
    try {
      const response = await EnumsService.DeleteTBank(
        client.caseId,
        bankAccountNumber,
        selectedBankForCreate.value,
        token
      );
      if (response.ok) {
        toast.success(t('BankDeletedSuccessfully'));
        fetchEnums(user, token, client);
        handleCloseCreateModal();
      } else {
        toast.error(t("BankFailedToDelete"));
      }
    } catch (error) {
      console.error("BankFailedToDelete");
    }
  };

  async function fetchNotMatchDataOnDateRangeChange(startDate, endDate) {
    setLoading(true);
    const formattedStartDate = startDate;
    const formattedEndDate = endDate;

    try {
      const internalResponse =
        await DocumentService.GetInternalByAccountCodeIdDateRange(
          client.caseId,
          formattedStartDate,
          formattedEndDate,
          selectedCardId,
          token
        );
      const externalResponse =
        await ExternalTransactionsService.GetNotMatchedbyDateRange(
          client.caseId,
          formattedStartDate,
          formattedEndDate,
          selectedCardId,
          token
        );

      if (internalResponse.ok && externalResponse.ok) {
        const internalData = await internalResponse.json();
        const externalData = await externalResponse.json();

        // Mapping internal rows
        const formattedInternalRows = internalData.map((row, index) => ({
          id: row.id,
          paymentAcceptanceId: row.paymentAcceptanceId,
          description: row.description,
          reference: row.reference,
          amount: beautifyNumber(row.amount),
          amount1: row.amount,
          documentDate: row.documentDate ? formatDate(row.documentDate) : '',
          subRows: row.subRows || [],  // Optional: Handle subRows if available
          type: row.type
        }));

        // Mapping external rows
        const formattedExternalRows = externalData.map((row) => ({
          id: row.id,
          description: row.description,
          reference: row.reference,
          amount: beautifyNumber(row.amount),
          amount1: row.amount,
          transactionDate: formatDate(row.transactionDate),
        }));

        setInternalRows(formattedInternalRows);
        setExternalRows(formattedExternalRows);
        setSortedTransactions(formattedInternalRows);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }

    setLoading(false);
  }

  const handleChangeBankForCreate = (value) => {
    setSelectedBankForCreate(value);
  };

  const handleOpenCreateModal = () => {
    setCreateBankModal(true);
  };

  const handleCloseCreateModal = () => {
    setCreateBankModal(false);
    setSelectedBankForCreate(null);
    setBankBranch("");
    setBankAccountNumber("");
    setIsEditingBank(false);
    setCardForNewBank(null)

  };

  const handleAddBank = async () => {
    try {
      const bankName =
        Constants.BankNames[parseInt(selectedBankForCreate.value)];
      const bankCode = selectedBankForCreate.value;

      if (!selectedBankForCreate || !bankBranch || !bankAccountNumber) {
        toast.error(t("PleaseFillAllFields"));
        return;
      }

      const response = await EnumsService.AddTBank(
        client.caseId,
        bankName,
        bankCode,
        bankBranch,
        bankAccountNumber,
        cardForNewBank.toString(),
        token
      );
      if (response.ok) {
        toast.success(t("BankAccountAddedSuccessfully"));
        fetchEnums(user, token, client);
        handleCloseCreateModal();
      } else {
        toast.error(t("FailedToAddBankAccount"));
      }
    } catch (error) {
      console.error(error);
      toast.error(t("ErrorAddingBankAccount"));
    }
  };
  const [oldBank, setOldBank] = useState(null);

  const handleEditBank = async () => {
    try {
      const bankName =
        Constants.BankNames[parseInt(selectedBankForCreate.value)];
      const bankCode = selectedBankForCreate.value;

      if (!selectedBankForCreate || !bankBranch || !bankAccountNumber) {
        toast.error(t("PleaseFillAllFields"));
        return;
      }

      if (!oldBank) {
        toast.error(t("OldBankDataNotFound"));
        return;
      }

      const response = await EnumsService.EditTBank(
        client.caseId, // Use the CaseId from the edited bank
        oldBank.bankCode, // Old bank code
        oldBank.accountNumber, // Old account number
        bankName, // New bank name
        bankCode, // New bank code
        bankBranch, // New branch number
        bankAccountNumber, // New account number
        cardForNewBank.toString(),
        token // Authorization token
      );

      if (response.ok) {
        toast.success(t("BankAccountUpdatedSuccessfully"));
        fetchEnums(user, token, client); // Fetch updated data
        handleCloseCreateModal(); // Close the modal
      } else {
        toast.error(t("FailedToUpdateBankAccount"));
      }
    } catch (error) {
      console.error(error);
      toast.error(t("ErrorUpdatingBankAccount"));
    }
  };

  const handleAddOrEditBank = () => {

    if (isEditingBank && editedBank) {
      handleEditBank();
    } else {
      handleAddBank();
    }
    handleCloseCreateModal(); // Close the modal after adding/editing
  };

  const [addingJournalEntryToMatch, setAddingJournalEntryToMatch] = useState(false);

  const handleConfirm = async () => {
    if (createJournalEntries === true) {

      // Case 2: Checked is true
      const selectedRows = externalDisplayedRows.filter((row) =>
        selectedExternalRows.includes(row.id)
      );
      const journalEntries = selectedRows.map((row) => {
        const amount = parseFloat(row.amount1);
        const isNegative = amount < 0;

        const transactionDate = new Date(parseCustomDate(row.transactionDate))
          .toISOString()
          .split("T")[0];
        const description = row.description;
        const reference = row.reference || "";

        return {
          amount: Math.abs(amount),
          creditAccount: isNegative ? selectedCardId : formValues.accountCard,
          debitAccount: isNegative ? formValues.accountCard : selectedCardId,
          description,
          reference,
          documentDate: transactionDate,
          reportingMonthDate: new Date(reportingMonthDate)
            .toISOString()
            .split("T")[0],
          sortCodeId: formValues.sortCode || null,
          externalId: row.id,
          vat: formValues.vat || 0
        };
      });


      // Show the journal entries as card list
      setJournalEntriesList(journalEntries);
      setIsJournalEntriesListOpen(true);

      // Close the initial dialog
      setIsCreateDialogOpen(false);
    } else {

      setIsCreateDialogOpen(false);
      await handleCreateJournalEntry();
    }
  };

  const returnProperVatBySortId = (mySortCodeId) => {
    let newVat = 100;

    const name = sortCodeIdOptions.find(
      (option) => option.value === mySortCodeId
    )?.label || '';
    // Extract the name without the parentheses

    const nameWithoutParentheses = name.replace(/\s*\(.*?\)\s*/, '').trim();
    // Check for a number within parentheses
    const match = name.match(/\((\d+)%?/);
    const customVat = match ? parseInt(match[1], 10) : null;

    const sortCode = sortCodeIdOptions.find(sc => sc.sortCodeId === mySortCodeId);
    // Determine the VAT based on the name without parentheses
    if (Constants.SortCodesWith66Vat.includes(nameWithoutParentheses)) {
      newVat = 66;
    } else if (Constants.SortCodesWithZeroVat.includes(nameWithoutParentheses) || sortCode?.sortCodeVatType === 0) {
      newVat = 0;
    } else if (Constants.SortCodesWith16Vat.includes(nameWithoutParentheses)) {
      newVat = 16;
    } else if (customVat !== null) {
      // If a custom VAT was found, use it
      newVat = customVat === 66 ? 66 : customVat;
    } else {
      // Default VAT if no special conditions are met
      newVat = 100;
    }
    return newVat; // Return the determined VAT
  };




  // const handleCreateJournalEntry = async () => {
  //   setLoading(true);
  //   // Prepare JSON payload
  //   const payload = {
  //     caseId: client.caseId,
  //     accountantId: client.accountantId,
  //     sortCodeId: formValues.sortCodeId,
  //     reference: formValues.reference,
  //     description: formValues.description,
  //     amount: Math.abs(formValues.amount),
  //     documentDate: new Date(formValues.documentDate).toISOString(),
  //     reportingMonthDate: new Date(formValues.reportingMonthDate).toISOString(),
  //     creditAccount: formValues.creditAccount,
  //     debitAccount: formValues.debitAccount,
  //     vat: returnProperVatBySortId(formValues.sortCodeId),
  //     addAsProcessed: true
  //   };

  //   try {
  //     const response = await DocumentService.UploadDocumentPayload(
  //       "JournalEntries",
  //       payload,
  //       token
  //     );
  //     if (response.ok) {
  //       const data = await response.json(); // Parse the response as JSON
  //       const newJournalEntryId = data.journalEntryId; // Extract the ID from the response

  //       // Create updated copies of the selected rows
  //       const updatedSelectedInternalRows = [...selectedInternalRows, newJournalEntryId];
  //       const updatedSelectedExternalRows = [...selectedExternalRows]; // Modify as needed

  //       // Update the state for selectedInternalRows
  //       setSelectedInternalRows(updatedSelectedInternalRows);
  //       await afterStateUpdate(updatedSelectedInternalRows);

  //       const response2 = await ClientDocumentCountService.removeDocumentByTypeAndId(client.caseId, client.accountantId, 'JournalEntries', data.journalEntryId, token);
  //       // setAddingJournalEntryToMatch(true);
  //       // setShouldMatch(true);


  //       setShouldCallHandleMatch2(true);

  //       toast.success(t("documentUploadSuccess"));
  //     } else {
  //       const errorText = await response.text();
  //       toast.error(t("documentUploadError", { errorText }));
  //       return false;
  //     }
  //   } catch (error) {
  //     toast.error(
  //       t("documentUploadError", { errorText: "Error uploading document" })
  //     );
  //     return false;
  //   }
  // };
  const [rawSortCodes, setRawSortCodes] = useState([]);
  const handleCreateJournalEntry = async () => {
    setLoading(true);

    // Prepare JSON payload
    const payload = {
      caseId: client.caseId,
      accountantId: client.accountantId,
      sortCodeId: formValues.sortCodeId,
      reference: formValues.reference,
      description: formValues.description,
      amount: Math.abs(formValues.amount),
      documentDate: new Date(formValues.documentDate).toISOString(),
      reportingMonthDate: formValues.reportingMonthDate
        ? new Date(formValues.reportingMonthDate).toISOString()
        : new Date(),
      creditAccount: formValues.creditAccount,
      debitAccount: formValues.debitAccount,
      vat: returnProperVatBySortId(formValues.sortCodeId),
      isMadeFromBankMatch: true,
      addAsProcessed: true,
    };

    try {
      const response = await DocumentService.UploadDocumentPayload(
        "JournalEntries",
        payload,
        token
      );

      if (response.ok) {
        const data = await response.json(); // Parse the response as JSON
        const newJournalEntryId = data.journalEntryId; // Extract the ID from the response

        // Update selectedInternalRows
        const updatedSelectedInternalRows = [...selectedInternalRows, newJournalEntryId];
        setSelectedInternalRows(updatedSelectedInternalRows);

        // Create new journal entry object
        const newJournalEntry = {
          id: newJournalEntryId,
          type: "JournalEntries",
        };

        // Update displayedRows with the new journal entry
        const updatedDisplayedRows = [...displayedRows, newJournalEntry];
        // setDisplayedRows(updatedDisplayedRows);

        // Call handleMatch2 with updated rows
        await handleMatch2(
          updatedSelectedInternalRows,
          selectedExternalRows,
          updatedDisplayedRows,
          externalRows
        );

        // Optionally clear matches and fetch updated data
        handleClearMatches();
        await FetchData();

        // Perform cleanup or additional logic
        await ClientDocumentCountService.removeDocumentByTypeAndId(
          client.caseId,
          client.accountantId,
          "JournalEntries",
          newJournalEntryId, token
        );

        toast.success(t("documentUploadSuccess"));
      } else {
        const errorText = await response.text();
        toast.error(t("documentUploadError", { errorText }));
        return false;
      }
    } catch (error) {
      toast.error(
        t("documentUploadError", { errorText: "Error uploading document" })
      );
      return false;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (addingJournalEntryToMatch) {
      if (shouldMatch && displayedRows.length > 0 && selectedInternalRows.length > 0) {
        handleMatch(selectedInternalRows); // Pass updated internal rows
        setShouldMatch(false); // Reset the flag after calling handleMatch
        setAddingJournalEntryToMatch(false);
      }
    } else {

      if (shouldMatch && displayedRows.length > 0) {
        handleMatch(selectedInternalRows); // Pass updated internal rows
        setShouldMatch(false); // Reset the flag after calling handleMatch
      }
    }
  }, [displayedRows, addingJournalEntryToMatch, shouldMatch]);

  // Step 3: Function to handle what happens after state update
  const afterStateUpdate = async (updatedRows) => {
    setFormValues({
      confirmationNumber: "",
      accountCaseId: "",
      reference: "",
      description: "",
      documentDate: "",
      reportingMonthDate: "",
      amount: "",
      vat: 0,
      sortCodeId: "3",
      creditAccount: "",
      debitAccount: "",
      sortCode: "3",
      accountCard: "",
    });

    // Fetch the data and wait for it to complete
    await FetchData();

    setLoading(false);
  };
  const selectedDenseTableRow = async (index) => {
    setSelectedTBank(tBanksData[index]);
    setSelectedTBankIndex(index);
    if (index !== -1 && tBanksData[index]) {
      setSelectedCardId(tBanksData[index].cardId);
    } else {
      console.warn('Invalid index or data not found');
    }

    const cardIndexForAdv = processedCardsData.findIndex(
      (item) => item.cardId === tBanksData[index].cardId
    );

    if (cardIndexForAdv !== -1) {
      // If cardId is found
      const updatedMainCard = {
        ...processedCardsData[cardIndexForAdv],
        index: cardIndexForAdv,
      }; // Add the index to mainCard
      setMainCard(updatedMainCard); // Set the updated mainCard with the index
      setSelectedFormattedAccountCard(processedCardsData[cardIndexForAdv]);
    } else {
      // setMainCard(-1);
      // toast.info(t('NonBankAccountCardSelected'));
    }
  };
  const [isEditingBank, setIsEditingBank] = useState(false);
  const [editedBank, setEditedBank] = useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);

  const handleOpenEditTBank = (index) => {
    const selectedBankData = tBanksData[index]; // Get the selected bank data from tBanksData

    setOldBank(selectedBankData);
    // Find the bank option that matches the selected bank's bankCode
    const selectedBank = bankOptions.find(
      (bank) => bank.value === selectedBankData.bankCode
    );

    setSelectedBankForCreate(selectedBank);
    setEditedBank(selectedBankData); // Set the entire bank data for editing

    // Set other values for editing
    setBankBranch(selectedBankData.branchNumber);
    setBankAccountNumber(selectedBankData.accountNumber);
    setCardForNewBank(selectedBankData.cardId);
    setIsEditingBank(true);

    setCreateBankModal(true); // Open the modal
  };

  useEffect(() => {
    if (selectedCardId && client) {
      FetchData()
    }
  }, [selectedCardId]);

  const FetchData = async () => {

    if (fetchType === "Matched") {
      await fetchMatchDataOnDateRangeChange(
        new Date(startDate),
        new Date(endDate),
        isMatchDate
      );
    } else {
      await fetchNotMatchDataOnDateRangeChange(
        new Date(startDate),
        new Date(endDate)
      );
    }
    const response = await ExternalTransactionsService.GetTransactionSummaryByCaseAndAccount(client.caseId, selectedCardId, token);
    if (response.ok) {
      const summary = await response.json();
      setDenseTableCreditDebitRows([{ balance: beautifyNumber(summary.balance), credit: beautifyNumber(summary.credit), debit: beautifyNumber(summary.debit) }]);
    } else {
      setDenseTableCreditDebitRows([{}]);
      const errorMessage = await response.text();
      console.error("Error fetching summary:", errorMessage);
    }
  };
  useEffect(() => {

    if (mainCard) {


      // setSelectedCardId(mainCard.cardId);
      const cardIndex = tBanksData.findIndex(
        (item) => item.cardId?.toString() === mainCard.cardId.toString()
      );

      if (cardIndex !== -1) {
        setSelectedTBankIndex(cardIndex);
      } else {
        setSelectedTBankIndex(-1);
        // toast.info(t('NonBankAccountCardSelected'));
      }
    }
  }, [mainCard]);

  // Calculate the sum of amounts from selected rows
  const calculateSum = (rows, selectedIds) => {
    return rows
      .filter((row) => selectedIds.includes(row.id))
      .reduce((sum, row) => sum + (row.amount1 || 0), 0);
  };

  // Effect to update sums when row selection changes
  useEffect(() => {
    const externalSumValue = calculateSum(
      externalDisplayedRows,
      selectedExternalRows
    );
    const internalSumValue = calculateSum(displayedRows, selectedInternalRows);
    setExternalSum(externalSumValue);
    setInternalSum(internalSumValue);
  }, [selectedExternalRows, selectedInternalRows]);


  const [assets, setAssets] = useState([]);


  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const [assetGroups, setAssetGroups] = useState([]);

  const handleOpenGroudAdd = () => {
    setShowAddGroupModal(true);
  }



  const fetchEnums = async (user, token, client) => {
    setLoading(true);
    try {
      const tBanks = await EnumsService.GetAllTBanksByCaseId(
        client.caseId,
        token
      );
      const accountCardsResponse = await EnumsService.GetAccountCardsByCaseId(
        client.caseId,
        token
      );
      const accountCardsData = await accountCardsResponse.json();


      const tBanksRows = tBanks.map((bank) => [
        bank.bankCode || "-",
        bank.bankName || "-",
        bank.branchNumber || "-",
        bank.accountNumber || "-",
        bank.cardId || "-",
      ]);

      const assetGroupResponse = await AssetService.GetAllAssetGroups(client?.caseId, token);
      if (assetGroupResponse.success) {
        setAssetGroups(assetGroupResponse.data);
      } else {
        toast.error(t('FailedToFetchAssets'));
      }

      //processed account cards data
      if (accountCardsData && accountCardsData.length > 0) {
        const transformedData = accountCardsData.map((card) => ({
          ...card,
          value: card.cardId, // Set value to cardId
          label: `${card.cardId} - ${card.name} `, // Set label to name
        }));
        setProcessedCardsData(transformedData);
      }
      const accountCardOptions = [{ name: t('AddAccountCard'), value1: 'AddItem' }, ...accountCardsData];
      setAccountCardOptionsForBank(accountCardOptions);
      setDenseTableRows(tBanksRows);
      setTBanksData(tBanks);
      setAccountCardsData(accountCardsData);

      // Fetching sortCodeIdOptions
      const codeIdResponse = await EnumsService.GetSortCodesByAccountantId(
        client.accountantId,
        token
      );
      if (codeIdResponse.ok) {
        const codeIdData = await codeIdResponse.json();


        setRawSortCodes(codeIdData);
        const sortCodeOptions = codeIdData.map((sortCode) => ({
          ...sortCode,
          label: sortCode.sortCodeName, // Display name in the dropdown
          value: sortCode.sortCodeId, // Use cardId as the value
        }));
        setSortCodeIdOptionsForEditModals(codeIdData);
        setSortCodeIdOptions(sortCodeOptions); // Assuming codeIdData is already in the correct format
      }

      const assetResponse = await AssetService.GetAllAssets(client?.caseId, token);
      if (assetResponse.success) {
        setAssets(assetResponse.data);
      } else {
        toast.error(t('FailedToFetchAssets'));
      }


      // Fetching creditAccountOptions and debitAccountOptions
      const accountsResponse = await EnumsService.GetAccountCardsByCaseId(
        client.caseId,
        token
      );
      if (accountsResponse.ok) {
        const accountsData = await accountsResponse.json();

        // Map accountsData to extract name and cardId
        const accountOptions = accountsData.map((account) => ({
          label: account.name, // Display name in the dropdown
          value: account.cardId, // Use cardId as the value
        }));



        setCreditAccountOptions(accountOptions);
        setDebitAccountOptions(accountOptions); // Assuming both options are the same
        setAccountCardEditDownloadOptions(accountOptions);

        const accountCardOptionsEdit = [
          { name: t("AddAccountCard"), value1: "AddItem" },
          ...accountsData,
        ];
        setEditModalsAccountCardOptions(accountCardOptionsEdit);

        setAccountCardOptions(accountCardOptionsEdit);
      }
      const palCodesResponse = await EnumsService.GetPalCodesByAccountantId(
        client.accountantId,
        token
      );
      // Process palCodes if response is okay
      if (palCodesResponse.ok) {
        const palCodesData = await palCodesResponse.json();
        const formattedPalCodes = palCodesData.map((palCode) => ({
          label: palCode.palCodeId,
          value: palCode.palCodeId,
        }));

        setFormattedPalCodeOptions(formattedPalCodes);
      }

      setIsClientDataFetched(true);
    } catch (error) {
      console.error(error);
      toast.error(t("fetchEnumsError"), error);
      setIsClientDataFetched(false);
    }
    setLoading(false);
  };

  const [bankFromBankPage, setBankFromBankPage] = useState(-1);
  const [previosPage, setPreviosPage] = useState(null);
  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const currentUser = await UserService.getCurrentUser();
        const currentToken = await UserService.getCurrentToken();
        if (currentUser && currentToken) {
          setUser(currentUser);
          setToken(currentToken);

          // Get client data from location.state
          if (location.state && location.state.client && location.state.type) {
            setType(location.state.type);
            setClient(location.state.client);

          }

          if (!isNaN(Number(location.state.selectedTBankIndex)) && bankFromBankPage === -1) {

            setBankFromBankPage(location.state.selectedTBankIndex);
          }

          if (location.state.previosPage) {
            setPreviosPage(location.state.previosPage);
          }

          await fetchEnums(currentUser, currentToken, location.state.client);
          // Fetch financial data for the current date on initial load only
        } else {
          navigate("/login");
        }
      } catch (error) {
        // Handle error - maybe navigate to login or show a message
      }
      setLoading(false);
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    if (bankFromBankPage !== -1 && denseTableRows) {
      setSelectedBankAccount(bankFromBankPage);
      const matchingBank = denseTableRows[bankFromBankPage]
      selectedDenseTableRow(bankFromBankPage);
    }
  }, [bankFromBankPage, denseTableRows, tBanksData]);



  const filteredAndSortedData = useMemo(() => {
    if (accountCardsData.length <= 0) {
      return [];
    }

    const query = searchQuery.toLowerCase();
    // Apply filtering and scoring
    if (accountCardsData.length <= 0) {
      return [];
    }
    const scoredData = accountCardsData
      .filter((item) => {
        // Basic filtering to remove irrelevant items
        return (
          item?.cardId?.toString()?.includes(query) ||
          item?.caseId?.toString()?.includes(query) ||
          item?.accountCaseId?.toString()?.includes(query) ||
          item?.name?.toLowerCase()?.includes(query) ||
          item?.palCode?.toLowerCase()?.includes(query) ||
          item?.client?.toString()?.includes(query) ||
          item?.phoneNumber?.toString()?.includes(query) ||
          item?.location?.toLowerCase()?.includes(query) ||
          item?.email?.toLowerCase()?.includes(query) ||
          item?.sourceDeductionAssociation?.toString()?.includes(query)
        );
      })
      .map((item) => {
        // Calculate a relevance score based on the match
        let score = 0;

        if (item?.cardId?.toString() === query)
          score += 10; // Exact match on cardId
        else if (item?.cardId?.toString()?.includes(query)) score += 5; // Partial match

        if (item?.caseId?.toString() === query)
          score += 10; // Exact match on caseId
        else if (item?.caseId?.toString()?.includes(query)) score += 5;

        if (item?.accountCaseId?.toString() === query) score += 10;
        else if (item?.accountCaseId?.toString()?.includes(query)) score += 5;

        if (item?.name?.toLowerCase() === query)
          score += 15; // Give more weight to name exact match
        else if (item?.name?.toLowerCase()?.includes(query)) score += 7;

        if (item?.palCode?.toLowerCase() === query) score += 10;
        else if (item?.palCode?.toLowerCase()?.includes(query)) score += 5;

        if (item?.client?.toString() === query) score += 10;
        else if (item?.client?.toString()?.includes(query)) score += 5;

        if (item?.phoneNumber?.toString() === query) score += 10;
        else if (item?.phoneNumber?.toString()?.includes(query)) score += 5;

        if (item?.location?.toLowerCase() === query) score += 8;
        else if (item?.location?.toLowerCase()?.includes(query)) score += 4;

        if (item?.email?.toLowerCase() === query) score += 10;
        else if (item?.email?.toLowerCase()?.includes(query)) score += 5;

        if (item?.sourceDeductionAssociation?.toString() === query) score += 10;
        else if (item?.sourceDeductionAssociation?.toString()?.includes(query))
          score += 5;

        // Return item along with its score
        return { ...item, score };
      });

    // Sort by relevance score (descending)
    const sortedData = scoredData.sort((a, b) => b.score - a.score);

    // Apply any additional sorting based on sortConfig
    if (sortConfig.key) {
      sortedData.sort((a, b) => {
        const aValue = a[sortConfig.key] || ""; // Default to empty string if null or undefined
        const bValue = b[sortConfig.key] || ""; // Default to empty string if null or undefined

        if (sortConfig.type === "number") {
          return sortConfig.direction === "ascending"
            ? aValue - bValue
            : bValue - aValue;
        } else if (sortConfig.type === "date") {
          return sortConfig.direction === "ascending"
            ? new Date(aValue) - new Date(bValue)
            : new Date(bValue) - new Date(aValue);
        } else {
          // default to string comparison
          return sortConfig.direction === "ascending"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }
      });
    }

    return sortedData;
  }, [accountCardsData, searchQuery, sortConfig]);

  const handleConfirmDelete = async () => {
    // Perform delete operation

    setLoading(true);
    setIsModalVisible(false);


    try {
      const response = await DocumentService.DeleteDocument(itemTypeToDelete, itemIdToDelete, token);
      if (response.ok) {

        await FetchData();
        toast.success(t("documentDeleteSuccess"), {
          position: "top-left"
        });
      } else {
        // Handle any errors or display an error toast
        const errorResponse = await response.json();
        console.error('Error deleting document:', errorResponse);

        // Show error toast
        toast.error(t("documentDeleteError"));
      }
    } catch (error) {
      console.error('Error deleting document:', error);

      // Show error toast
      toast.error(t("documentDeleteError"));
    }
    setLoading(false);
  };

  const handleConfirmDeletion = async () => {
    if (actionType === 'delete') {
      await handleConfirmDelete();
    }
    if (actionType === 'deleteExternal') {
      await deleteMultipleExternal();
    }
  };

  const getTitleMessage = () => {
    switch (actionType) {

      case 'closeMonth':
        return t('ConfirmCloseMonth');
      case 'openMonth':
        return t('ConfirmOpenMonth');
      default:
        return t('confirmDeleteTitle');
    }
  };
  const getConfirmMessage = () => {
    switch (actionType) {
      case 'closeMonth':
        return t('AreYouSureCloseMonth');
      case 'openMonth':
        return t('AreYouSureOpenMonth');
      default:
        return t('confirmDeleteMessage');

    }
  };

  useEffect(() => {
    // Calculate the width as 70% of the container width minus 20px
    const containerWidth =
      document.querySelector(".table-container").offsetWidth;
    const newWidth = containerWidth * 0.7 - 20;
    setCalculatedWidth(newWidth + "px");
  }, []);

  const { hasBack, handleBack } = useBack();

  const handleGoBack = () => {
    // Navigate back to the previous page using React Router
    if (client) {
      // const prevPage = previosPage || '/client-details';
      // navigate(prevPage, {
      //   state: { client, bankFromBankPage, type: 'BankPages' },
      // });
      // handleBack();
      goBack()
    }
  };

  const rowStyle = {
    padding: "6px",
    fontSize: "12px",
  };

  const headerStyle = {
    backgroundColor: "#ebebeb",
    padding: "8px",
    fontSize: "12px",
  };

  const [itemEditing, setItemEditing] = useState(true);
  const [addNewAccountCardDocument, setAddNewAccountCardDocument] =
    useState(false);
  const [companyId, setCompanyId] = useState("");
  const [accountCodeName, setAccountCodeName] = useState("");
  const [accountCodeId, setAccountCodeId] = useState("");

  const [modalVisible, setModalVisible] = useState(false);
  const [addNewAccountCard, setAddNewAccountCard] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const isClosedReportingMonthAndIsRange = !isRange
    ? isClosedReportingMonth
    : addEditableItem.isClosedReportingMonth;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [paymentAcceptanceItems, setPaymentAcceptanceItems] = useState([]);
  const [invoiceClientCaseId, setInvoiceClientCaseId] = useState("");
  const [accountType, setAccountType] = useState(null);
  const [description, setDescription] = useState("");
  const [showAccountCardInputsEdit, setShowAccountCardInputsEdit] =
    useState(false);
  const [acceptedWithholdingTax, setAcceptedWithholdingTax] = useState(0);
  const [palCode, setPalCode] = useState("");
  const [paymentWithholdingTax, setPaymentWithholdingTax] = useState(0);
  const [newItem, setNewItem] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [actionType, setActionType] = useState("delete");
  const [accountCardIdToDelete, setAccountCardIdToDelete] = useState("");
  const [paymentBeingEdited, setPaymentBeingEdited] = useState(true);

  ///add acccount card
  const [newCardId, setNewCardId] = useState(null);

  const [creditDebitSearchTerm, setCreditDebitSearchTerm] = useState('');
  const [addAccountCardType, setAddAccountCardType] = useState('main');
  const [isEditAccountCard, setIsEditAccountCard] = useState(false);
  const [newAccountCard, setNewAccountCard] = useState({ accountCodeName: '', accountCodeId: "" });
  const [cardIdOption, setCardIdOption] = useState(-1);
  const [selectedAccount, setSelectedAccount] = useState(() => {
    if (!editedItem?.accountCodeName) return '';

    const selectedOption = accountCardOptions.find(option => option.cardId === editedItem.accountCodeId);
    return selectedOption ? selectedOption : null;
  });
  useEffect(() => {
    if (accountCardOptions && accountCardOptions.length > 0) {
      const maxCardId = Math.max(...accountCardOptions.slice(1).map(option => parseInt(option.cardId, 10)));
      setCardIdOption(maxCardId + 1);
    }
  }, [accountCardOptions]);
  const handleChangeAfterAdd = (cardId, type) => {
    ///set type
    const newCard = {
      cardId,
      type
    }
    setNewCardId(newCard);
  }
  useEffect(() => {
    if (accountCardOptions && accountCardOptions.length > 0 && newCardId) {
      const selectedOption = accountCardOptions.find(option => option.cardId === newCardId.cardId);
      if (newCardId) {
        handleSelectChange(
          newCardId.type,
          selectedOption
        )
        setNewCardId(null);
      }
    }
  }, [accountCardOptions, newCardId]);



  const handleCloseItem = () => {
    setPalCode('');
    setShowAccountCardInputsEdit(false);
    setEditedAddAccountCard(false);
    setSelectedAccount(oldSelectedAccountCardItem);
    // handleChangeAccount('accountCodeId', oldSelectedAccountCardItem.cardId?.toString());
    // handleChangeAccount('accountCodeName', oldSelectedAccountCardItem.name?.toString());
    // handleChangeAccount('accountCaseId', oldSelectedAccountCardItem.accountCaseId?.toString());
  };
  const formattedAccountCardOptions = accountCardOptions.map((option) => ({
    value: option.cardId,
    label: option.accountCaseId
      ? `${option.name} - ${option?.accountCaseId || ''}`
      : `${option.name}`,
    name: option.name,
    accountCaseId: option.accountCaseId || '',
    cardId: option.cardId,
    value1: option.value1,
    palCode: option.palCode,
    isEditable: option.isEditable
  }));

  const handleCreditAccountCardForNewCommands = (card, type) => {
    if (!card) {
      return;
    }

    if (card.value1 === 'AddItem') {
      setEditedAddAccountCard(true);
      setAddAccountCardType(type);
      setIsEditAccountCard(false);
      setNewAccountCard({ accountCodeName: card.searchTerm || '', accountCodeId: cardIdOption })
    }
  };

  const handleNoOptionCreditDebitClick = (searchTerm, type) => {
    const newOption = {
      ...formattedAccountCardOptions[0],
      searchTerm: searchTerm
    };
    handleCreditAccountCardForNewCommands(newOption, type)
  };

  const checkIfExistingAccountCard = (
    accountCardOptions,
    cardId,
    accountCaseId,
    edit = false
  ) => {
    const existingAccountCard = accountCardOptions.filter(
      (card) =>
        (card.accountCaseId === accountCaseId && accountCaseId) ||
        (card.cardId === cardId && cardId)
    );

    const isExist =
      existingAccountCard &&
      (edit ? existingAccountCard.length > 1 : existingAccountCard.length > 0);

    if (isExist) {
      toast.info(
        t("AccountCardExists", { accountCodeId: existingAccountCard[0].cardId })
      );
      return true;
    }
  };

  const handleSaveEditAccountCard = async () => {
    if (
      checkIfExistingAccountCard(
        accountCardOptions,
        accountCodeId,
        companyId,
        true
      )
    ) {
      return;
    }
    const Item = addEditableItem;
    handleClose();
    try {
      setLoading(true);

      const response = await EnumsService.UpdateAccountCard(
        client.caseId,
        oldSelectedAccountCardItem.cardId,
        oldSelectedAccountCardItem.name,
        accountCodeId,
        accountCodeName,
        companyId,
        palCode,
        token
      );
      if (response.ok) {
        const newItem = {
          ...Item,
          accountCodeId: accountCodeId?.toString(),
          accountCodeName: accountCodeName?.toString(),
          accountCaseId: invoiceClientCaseId?.toString(),
        };
        setNewItem(newItem);
        let selectedAccountCard = {
          cardId: accountCodeId,
          caseId: client.caseId,
          accountCaseId: companyId,
          name: accountCodeName,
          palCode: palCode,
          client: null,
        };
        setAccountCardOptions((prevCards) => {
          const updatedCards = prevCards.filter(
            (card) => card.cardId !== selectedAccountCard.cardId
          );
          updatedCards.push(selectedAccountCard);
          setIsUpdated(true);
          return updatedCards;
        });
        // handleReopenEdit(editedItem);
        toast.success(t("CustomerSupplierUpdatedSuccessfully"));
        setSelectedOption(selectedAccountCard ? selectedAccountCard : null);
      } else {
        const errorText = await response.text();
        console.error("FailedToUpdateCustomerSupplier", errorText);
        toast.error(t("FailedToUpdateCustomerSupplier"));
      }
    } catch (error) {
      console.error("FailedToUpdateCustomerSupplier:", error);

      toast.error(t("FailedToUpdateCustomerSupplier"));
    }
    setLoading(false);
    setPalCode("");
    setAccountCodeId("");
    setAccountCodeName("");
    setAddNewAccountCardDocument(false);
    setShowAccountCardInputsEdit(false);
  };

  const handleAddAccountCard = async () => {
    if (
      checkIfExistingAccountCard(accountCardOptions, accountCodeId, companyId)
    ) {
      return;
    }
    const Item = addEditableItem;
    handleClose();
    try {
      setLoading(true);
      const response = await EnumsService.AddAccountCard(
        client.caseId,
        accountCodeId,
        accountCodeName,
        companyId,
        palCode,
        token
      );
      if (response.ok) {
        const accountCodeId = await response.json();
        let selectedAccountCard = {
          cardId: accountCodeId,
          caseId: client.caseId,
          accountCaseId: companyId,
          name: accountCodeName,
          palCode: palCode,
          client: null,
        };

        const newItem = {
          ...Item,
          accountCodeId: accountCodeId?.toString(),
          accountCodeName: accountCodeName?.toString(),
          accountCaseId: invoiceClientCaseId?.toString(),
        };
        setNewItem(newItem);
        setAccountCardOptions((prevCards) => {
          const updatedCards = [...prevCards, selectedAccountCard];
          setIsUpdated(true);
          return updatedCards;
        });

        toast.success(t("CustomerSupplierAddedSuccessfully"));
        setSelectedOption(selectedAccountCard ? selectedAccountCard : null);
      } else {
        const errorText = await response.text();
        console.error("Failed to edit user", errorText);
        toast.error(t("FailedToAddCustomerSupplier"));
      }
    } catch (error) {
      console.error("Error edit user:", error);
      toast.error(t("FailedToAddCustomerSupplier"));
    }
    setLoading(false);
    setPalCode("");
    setAccountCodeId("");
    setAccountCodeName("");
    setAddNewAccountCardDocument(false);
  };

  const onRefresh = async () => {
    setLoading(true);
    await fetchEnums(user, token, client);
  };

  const handleCloseAddNewAccountCardDocument = () => {
    setAccountCodeId("");
    setAccountCodeName("");
    setAddNewAccountCardDocument(false);
  };

  // const handleMuiChange = (e) => {
  //   const value = e.value;
  //   if (value.value1 === "AddItem") {
  //     setAccountCodeName("");
  //     setAccountCodeId("");
  //     setCompanyId("");
  //     setAddNewAccountCardDocument(true);
  //     setAddNewAccountCard(true);
  //   } else {
  //     setItemEditing(true);
  //     setAccountCodeName(value.name);
  //     setCompanyId(value.accountCaseId);
  //     // const accountCodeId = accountCardOptions.find(option => option.name === selectedOption.value);
  //     setAccountCodeId(value.cardId);
  //     setInvoiceClientCaseId(value.cardId);
  //     addEditableItem.accountCaseId = value.accountCaseId;
  //     addEditableItem.accountCodeId = value.cardId;
  //     addEditableItem.accountCodeName = value.name;
  //     // Conditionally set creditAccount or debitAccount based on paymentAcceptanceType
  //     if (addEditableItem.paymentAcceptanceType === "תקבול") {
  //       addEditableItem.creditAccount = value.name;
  //       setSelectedOption({
  //         ...value,
  //         disableCreditAccount: true,
  //         disableDebitAccount: false,
  //       });
  //     } else if (addEditableItem.paymentAcceptanceType === "תשלום") {
  //       addEditableItem.debitAccount = value.name;
  //       setSelectedOption({
  //         ...value,
  //         disableDebitAccount: true,
  //         disableCreditAccount: false,
  //       });
  //     }
  //   }
  // };

  const handleMuiChange = (event, value) => {

    if (value?.value1 === 'AddItem') {
      setAccountCodeName("");
      setAccountCodeId("");
      setCompanyId("");
      setAddNewAccountCardDocument(true);
      setAddNewAccountCard(true);
    }
    else {
      setItemEditing(true);
      const updatedFields = {
        accountCaseId: value.accountCaseId,
        accountCodeId: value.cardId,
        accountCodeName: value.name,
      };

      setAccountCodeName(value.name);
      setCompanyId(value.accountCaseId);
      setAccountCodeId(value.cardId);
      setInvoiceClientCaseId(value.cardId);

      // Update addEditableItem state
      setAddEditableItem(prevState => ({
        ...prevState,
        ...updatedFields,
      }));
      setSelectedOption({ ...value });

      // Call the function to update items
      updateAllItems(value, updatedFields);
    }
    // else {
    //     setItemEditing(true);
    //     setAccountCodeName(value.name);
    //     setCompanyId(value.accountCaseId);
    //     // const accountCodeId = accountCardOptions.find(option => option.name === selectedOption.value);
    //     setAccountCodeId(value.cardId);
    //     setInvoiceClientCaseId(value.cardId);
    //     addEditableItem.accountCaseId = value.accountCaseId;
    //     addEditableItem.accountCodeId = value.cardId;
    //     addEditableItem.accountCodeName = value.name;
    //     // Conditionally set creditAccount or debitAccount based on paymentAcceptanceType
    //     if (addEditableItem.paymentAcceptanceType === 'תקבול') {
    //         addEditableItem.creditAccount = value.name;
    //         setSelectedOption({ ...value, disableCreditAccount: true, disableDebitAccount: false });
    //     } else if (addEditableItem.paymentAcceptanceType === 'תשלום') {
    //         addEditableItem.debitAccount = value.name;
    //         setSelectedOption({ ...value, disableDebitAccount: true, disableCreditAccount: false });
    //     }
    // }


  };

  const updateAllItems = (value, updatedFields) => {
    const updatedItems = ['checks', 'bankTransactions', 'creditCards', 'cash'];
    const paymentType = addEditableItem.paymentAcceptanceType;

    updatedItems.forEach(itemType => {
      addEditableItem[itemType]?.forEach((item, index) => {
        // Determine the field to update
        const fieldToUpdate = paymentType === 'תקבול' ? 'creditAccount' : 'debitAccount';

        handleItemChange(itemType, index, fieldToUpdate, value.cardId);
      });
    });

    // Update addEditableItem with new account data
    setAddEditableItem(prevState => ({
      ...prevState,
      ...updatedFields,
    }));
  };

  const handleAccountDelete = (option) => {
    setActionType("deleteAccountCard");
    setAccountCardIdToDelete(option.cardId);
    setIsModalVisible(true);
    // handleAccountCardDeleteClick(option.cardId);
  };

  const handleChangeAccountType = (selectedOption) => {
    setAccountType(selectedOption.value);
    setAddEditableItem((prevState) => ({
      ...prevState,
      paymentAcceptanceType: selectedOption ? selectedOption.value : "",
    }));
  };
  const customStyles = {
    valueContainer: (base, state) => ({
      ...base,
      justifyContent: "center",
    }),
  };

  const setPaymentDate = async (date) => {
    const response1 = await ClientService.CheckIfMonthClosed(
      client.caseId,
      date,
      token
    );
    const isClosedReportingMonth = await response1.json();
    if (isClosedReportingMonth) {
      toast.error(t("MonthReportAlreadyClosedTryAgain"));
      return;
    }
    setAddEditableItem((prevItem) => ({
      ...prevItem,
      date: date.toISOString(),
    }));
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    addEditableItem.description = event.target.value;
  };
  const handleChangeOriginalTaxClearance = (event) => {
    const value = event.target.value;
    addEditableItem.withholdingTax = value;
    // setOriginalTaxClearance(value);
  };

  const handleRemoveItem = (type, index) => {
    let count = 0;

    // Update paymentAcceptanceItems
    const newItems = paymentAcceptanceItems.filter((item) => {
      if (item.type === type) {
        if (count === index) {
          count++;
          return false;
        }
        count++;
      }
      return true;
    });
    setPaymentAcceptanceItems(newItems);

    // Update addEditableItem
    setAddEditableItem((prevItem) => {
      const updatedTypeItems = prevItem[type].filter((_, idx) => idx !== index);
      return { ...prevItem, [type]: updatedTypeItems };
    });
  };

  const handleAddItem = (theType) => {
    const paymentType = addEditableItem.paymentAcceptanceType;

    const newItem = {
      type: theType,
      description: '',
      amount: '',
      paymentDate: new Date(),
      bankName: '',
      bankCode: '',
      bankBranchCode: '',
      supplierAccountNumber: '',
      bankTransactionsCode: Constants.PaymentAcceptanceConstantsAccountcards.GeneralBank,
      creditAccount: paymentType === 'תקבול' ? addEditableItem.accountCodeId || '' : '',
      debitAccount: paymentType === 'תשלום' ? addEditableItem.accountCodeId || '' : '',
    };

    // Update paymentAcceptanceItems
    setPaymentAcceptanceItems(prevItems => [...prevItems, newItem]);

    // Update addEditableItem
    setAddEditableItem(prevItem => ({
      ...prevItem,
      [theType]: [...prevItem[theType], newItem]
    }));

  };

  const handleAddEditableItemChange = (name, value) => {
    setAddEditableItem((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      setModalVisible(false);
      setOpenInvoiceCreatorModal(false);
      setLoading(true);
      // Prepare the data to be sent in the request body
      const updatedDocument = {
        id: addEditableItem.id,
        caseId: addEditableItem.caseId,
        accountCodeName: addEditableItem.accountCodeName,
        accountCodeId: addEditableItem.accountCodeId,
        reference: addEditableItem.reference,
        amount: addEditableItem.amount,
        uploadDate: addEditableItem.uploadDate,
        paymentAcceptanceType: addEditableItem.paymentAcceptanceType,
        description: addEditableItem.description,
        withholdingTax: addEditableItem.withholdingTax,
        accountCaseId: addEditableItem.accountCaseId,
        checks: addEditableItem.checks,
        creditCards: addEditableItem.creditCards,
        cash: addEditableItem.cash,
        bankTransactions: addEditableItem.bankTransactions,
        date: addEditableItem.date
          ? new Date(addEditableItem.date).toISOString()
          : new Date().toISOString(),
        reportingMonthDate: new Date(
          addEditableItem.reportingMonthDate
        ).toISOString(),
        confirmationNumber: addEditableItem.confirmationNumber,
        creditAccount: addEditableItem.creditAccount,
        creditAccountAmount: addEditableItem.creditAccountAmount,
        debitAccount: addEditableItem.debitAccount,
        debitAccountAmount: addEditableItem.debitAccountAmount,
      };

      // Call the UpdateDocument function from DocumentService
      const response = await DocumentService.UpdateDocument(
        "PaymentAcceptances",
        token,
        updatedDocument
      );

      if (response.ok) {
        // Handle successful update
        if (addEditableItem.status === "done") {
          await handleDoneClick(addEditableItem.id, addEditableItem.type);
        } else if (addEditableItem.status === "notDone") {
          await handleNotDoneClick(addEditableItem.id, addEditableItem.type);
        }
        toast.success(t("documentUpdateSuccess"));
        // await fetchAccountCardDashboard(startDate, endDate, processedFilesCheckbox);
      } else {
        // Handle any errors or display an error message
        toast.error(t("documentUpdateError"));
      }
    } catch (error) {
      console.error("documentUpdateError:", error);
      // Show an error toast message
      toast.error(t("documentUpdateError"));
    }
    await onRefresh();
    handleClose(); // Function to revert or exit the edit mode
    setLoading(false);
  };

  // const handleItemChange = (type, index, field, value) => {
  //   setPaymentAcceptanceItems((prevItems) => {
  //     let typeIndex = -1;
  //     const updatedItems = prevItems.map((item, idx) => {
  //       if (item.type === type) {
  //         typeIndex += 1;
  //         if (typeIndex === index) {
  //           return { ...item, [field]: value };
  //         }
  //       }
  //       return item;
  //     });

  //     // Update the corresponding field in addEditableItem

  //     setAddEditableItem((prevItem) => {
  //       const updatedTypeItems = prevItem[type].map((item, idx) => {
  //         if (idx === index) {
  //           return { ...item, [field]: value };
  //         }
  //         return item;
  //       });

  //       return { ...prevItem, [type]: updatedTypeItems };
  //     });

  //     return updatedItems;
  //   });
  // };


  const handleItemChange = (type, index, field, value) => {
    setPaymentAcceptanceItems(prevItems => {
      let typeIndex = -1;
      const updatedItems = prevItems.map((item, idx) => {
        if (item.type === type) {
          typeIndex += 1;
          if (typeIndex === index) {
            return { ...item, [field]: value };
          }
        }
        return item;
      });

      // Update the corresponding field in addEditableItem
      setAddEditableItem(prevItem => {
        const updatedTypeItems = prevItem[type].map((item, idx) => {
          if (idx === index) {
            return { ...item, [field]: value };
          }
          return item;
        });

        // Conditionally update the fields based on payment type and field
        const paymentType = addEditableItem.paymentAcceptanceType;
        const shouldUpdateFields =
          (paymentType === 'תקבול' && field === 'creditAccount') ||
          (paymentType === 'תשלום' && field === 'debitAccount');

        const matchingOption = accountCardOptions.find(
          option => option.cardId === value
        );

        const updatedFields = shouldUpdateFields && matchingOption
          ? {
            accountCaseId: matchingOption.accountCaseId || '',
            accountCodeId: matchingOption.cardId || '',
            accountCodeName: matchingOption.name || '',
          }
          : {};

        return { ...prevItem, ...updatedFields, [type]: updatedTypeItems };
      });

      return updatedItems;
    });
  };
  const setAccount = (field, input) => {
    //switch case on field
    switch (field) {
      case "companyId":
        setCompanyId(input);
        setInvoiceClientCaseId(input);

        break;
      case "accountCodeName":
        setAccountCodeName(input);

        break;
      case "accountCodeId":
        setAccountCodeId(input);

        break;
      default:
    }
  };
  const handleClose = async (clearDocuments = true) => {
    setOpenInvoiceCreatorModal(false);
    setPaymentModal(false);
    setAddEditableItem({
      checks: [],
      creditCards: [],
      cash: [],
      bankTransactions: [],
      date: new Date(),
    });
  };

  const [wholeDataTransactions, setWholeDataTransactions] = useState([]);


  const handleEdit = async (row) => {

    // Find the index of the current transaction
    const currentIndex = sortedTransactions.findIndex(
      (transaction) => transaction.id === row.id
    );

    if (currentIndex === -1) {
      console.error('Transaction not found in sortedTransactions');
      return; // Exit if the transaction is not found
    }

    setCurrentIndex(currentIndex);

    // Extract the overall type from the row
    const overAllType = row.type.split('-')[0].trim();

    // Helper function to fetch data for a given row
    const getDataForRow = async (row, overAllType) => {
      // Check if the data is already cached
      if (wholeDataTransactions[row.id]) {

        setItemIdToDelete(row.id);
        setItemTypeToDelete(row.type);
        return wholeDataTransactions[row.id];
      }

      try {
        const isPaymentAcceptance = row.type !== overAllType;
        const documentId = isPaymentAcceptance ? row.paymentAcceptanceId : row.id;

        // Fetch the document data
        const response = await DocumentService.GetDocumentById(
          overAllType,
          client.caseId,
          documentId,
          token
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch data for document ID: ${documentId}`);
        }

        const data = await response.json();
        const dataWithDocType = { ...data, docType: overAllType };

        setItemIdToDelete(data.id);
        setItemTypeToDelete(data.docType);
        // Cache the fetched data
        setWholeDataTransactions((prevData) => ({
          ...prevData,
          [row.id]: dataWithDocType,
        }));

        return dataWithDocType;
      } catch (error) {
        console.error('Error fetching data for row:', row.id, error);
        throw error;
      }
    };

    try {
      // Fetch the data for the current row
      const data = await getDataForRow(row, overAllType);
      setEditedItem(data);

      // Set the selected account card
      const selectedAccountCard = {
        cardId: accountCodeId,
        caseId: client.caseId,
        accountCaseId: companyId,
        name: accountCodeName,
        palCode: palCode,
        client: null,
      };
      setSelectedOption(selectedAccountCard);

      // Handle different cases based on the overall type
      switch (overAllType) {
        case 'Incomes':
        case 'Expenses':
          setEditedPopupItem(true);
          setPaymentModal(false);
          setEditedJournalItem(false);
          break;

        case 'PaymentAcceptances':
          handlePaymentAcceptance(row, data);
          break;

        case 'JournalEntries':
          setEditedPopupItem(true);
          setPaymentModal(false);
          setEditedJournalItem(true);
          break;

        default:
          // Fallback if the row type does not match any case
          setEditedPopupItem(false);
          setPaymentModal(false);
          setEditedJournalItem(false);
          break;
      }
    } catch (error) {
      console.error('Error during edit handling:', error);
      // Handle error (e.g., show a message to the user)
    }
  };

  // Helper function to handle PaymentAcceptances
  const handlePaymentAcceptance = (row, data) => {
    const itemType = row.type.split('-')[1].trim();
    const itemId = row.id;

    // Get the array corresponding to the itemType (e.g., 'checks', 'creditCards')
    const itemsArray = data[itemType];

    if (Array.isArray(itemsArray)) {
      // Find the index of the item with the matching ID
      const indexInArray = itemsArray.findIndex((item) => item.id === itemId);

      if (indexInArray !== -1) {
        setShowType({ type: itemType, index: indexInArray });
      } else {
        console.error('Item with the specified ID not found in the array.');
        setShowType({ type: itemType, index: -1 });
      }
    } else {
      console.error(`No array found for type "${itemType}" in data.`);
      setShowType({ type: itemType, index: -1 });
    }

    // Update state variables for the modal
    setOpenInvoiceCreatorModal(true);
    setAddEditableItem({ ...data });
    setPaymentModal(true);
    setEditedJournalItem(false);
    setEditedPopupItem(false);
    setItemEditing(true);
  };

  const handleRemoveMatchClick = (match) => {
    match.accountCardName = mainCard.name;

    setSelectedMatch(match);
    setOpenRemoveMatchDialog(true);
  };
  const handleCellDoubleClick = (params) => {
    handleEdit(params.row);
  };
  const handleConfirmRemove = async () => {
    try {
      const deleteDTO = {
        CaseId: selectedMatch.caseId,
        Id: selectedMatch.id,
      };

      // Call the delete service
      const response = await ExternalTransactionsService.DeleteMatch(
        deleteDTO,
        token
      );
      if (response.ok) {
        toast.success(t("MatchDeletedSuccessfully"));
        // Close the dialog after confirming
        await FetchData();
      } else {
        toast.error(t("ErrorDeletingMatch"));
      }
    } catch (error) {
      console.error("Error deleting match:", error);
      toast.error(t("ErrorDeletingMatch"));
    }
    setOpenRemoveMatchDialog(false);
  };

  const handleCloseRemoveMatchDialog = () => {
    setOpenRemoveMatchDialog(false);
  };
  const handleFormattedAccountCardChange = (event, newValue) => {
    setMainCard(newValue);
    setSelectedCardId(newValue?.cardId);
    setSelectedFormattedAccountCard(newValue);
  };

  const EditAccountCard = (selectedItem, type) => {

    if (!selectedItem) {
      console.warn("No account card selected for editing.");
      return;
    }


    setPalCode(selectedItem.palCode);
    setNewAccountCard({ accountCodeName: selectedItem.name || '', accountCodeId: selectedItem.cardId, accountCaseId: selectedItem.accountCaseId || '' })
    setIsEditAccountCard(true);
    setEditedAddAccountCard(true);
    setAddAccountCardType(type);
  };

  const clickedDeleteMultipleExternal = async () => {
    if (!selectedExternalRows || selectedExternalRows.length === 0) {
      console.error("No rows selected for deletion.");
      return;
    }
    setActionType('deleteExternal');
    setIsModalVisible(true);
  };


  const deleteMultipleExternal = async () => {
    if (!selectedExternalRows || selectedExternalRows.length === 0) {
      console.error("No rows selected for deletion.");
      return;
    }
    try {
      const response = await ExternalTransactionsService.DeleteMultipleExternalTransactions(client.caseId, selectedExternalRows, token);
      if (response.ok) {
        await FetchData();
      } else {
        console.error("Failed to delete transactions:", await response.text());
      }
      setActionType('delete');
      setIsModalVisible(false);
    } catch (error) {
      console.error("An error occurred while deleting transactions:", error);
      setActionType('delete');
      setIsModalVisible(false);
    }
  };




  return (

    <>
      <AddAccountCardModal
        accountCardOptions={accountCardOptions}
        open={editedAddAccountCard}
        onClose={handleCloseItem}
        editedItem={newAccountCard}
        isEdit={isEditAccountCard}
        isRange={isRange}
        isClosedReportingMonth={isClosedReportingMonth}
        palCodes={formattedPalCodeOptions}
        propPalCode={palCode}
        setPalCode={setPalCode}
        direction={direction} // Pass direction for styling
        isAdd={!showAccountCardInputsEdit}
        client={client}
        token={token}
        setLoading={setLoading}
        handleChangeAfterAdd={handleChangeAfterAdd}
        fetchEnums={fetchEnums}
        type={addAccountCardType}
      />
      {/* adding bank transaction */}
      <AddExternalTransactionDialog
        addBankTransactionDialog={addBankTransactionDialog}
        handleCloseBankAddTransactionDialog={handleCloseBankAddTransactionDialog}
        t={t}
        newBankEditableItem={newBankEditableItem}
        changeNewBankTransaction={changeNewBankTransaction}
        handleAddBankTransaction={handleAddBankTransaction}
      />
      <div className="template-page-withoutHeight">
        <div className={`template-container ${direction}`}>
          <UpperPanelDetails client={client} />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className={`main-title ${direction}`}>{t(type)}</div>
            <div
              className="backIconBackground"
              style={{
                alignSelf: i18n.language === "en" ? "flex-start" : "flex-end",
              }}
              onClick={handleGoBack}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M20 21.1667L14.8267 15.9933L20 10.82C20.52 10.3 20.52 9.45999 20 8.93999C19.48 8.41999 18.64 8.41999 18.12 8.93999L12 15.06C11.48 15.58 11.48 16.42 12 16.94L18.12 23.06C18.64 23.58 19.48 23.58 20 23.06C20.5067 22.54 20.52 21.6867 20 21.1667Z"
                  fill="black"
                  fill-opacity="0.9"
                />
              </svg>
            </div>
          </div>

          <AddOrEditBankDialog
            open={createBankModal}
            onClose={handleCloseCreateModal}
            isEditingBank={isEditingBank}
            selectedBankForCreate={selectedBankForCreate}
            bankOptions={bankOptions} // Add actual bank options
            handleChangeBankForCreate={handleChangeBankForCreate}
            bankBranch={bankBranch}
            handleChangeCreateBank={handleChangeCreateBank}
            bankAccountNumber={bankAccountNumber}
            cardForNewBank={cardForNewBank}
            processedCardsData={accountCardOptionsForBank}
            setCardForNewBank={handleChangeSetCardForNewBank}
            handleAddOrEditBank={handleAddOrEditBank}
            handleRemoveBank={handleRemoveBank}
            client={client}
            token={token}
            setLoading={setLoading}
            palCodes={formattedPalCodeOptions}
            fetchEnums={fetchEnums}
          />

          <div className={`searchFilter-bar__container ${direction}`}>
            {fetchType === "Matched" ? (
              isMatchDate ? (
                <>
                  {/* <CustomMonthDatePicker
                    onDateChange={(date) => handleStartDateChange(date)}
                    date={dayjs(startDate)}
                    label={t("StartDate")}
                  /> */}

                  <FormControlLabel
                    value={isMatchDate}
                    control={
                      <Switch
                        className="custom-input-box-sizing-toggle"
                        color="primary"
                        checked={isMatchDate}
                        onChange={handleRangeChange}
                      />
                    }
                    label={t("MatchDate")}
                    labelPlacement="bottom"
                  />
                  <CustomYearDatePicker
                    date={dayjs(endDate)}
                    onDateChange={(newYear) => handleEndDateChangeNew(newYear, 'year')}
                    labelMonth={t("EndYear")}
                  />
                </>
              ) : (
                <>
                  {/* <CustomMonthDatePicker
                    onDateChange={(date) => handleStartDateChange(date)}
                    date={dayjs(startDate)}
                    label={t("StartDate")}
                  /> */}

                  <FormControlLabel
                    value={!isMatchDate}
                    control={
                      <Switch
                        className="custom-input-box-sizing-toggle"
                        color="primary"
                        checked={isMatchDate}
                        onChange={handleRangeChange}
                      />
                    }
                    label={t("TransactionDate")}
                    labelPlacement="bottom"
                  />

                  <CustomYearDatePicker
                    date={dayjs(endDate)}
                    onDateChange={(newYear) => handleEndDateChangeNew(newYear, 'year')}
                    labelMonth={t("EndYear")}
                  />
                </>
              )
            ) : (
              <div style={{ columnGap: "10px", display: "flex" }}>
                {/* <CustomMonthDatePicker
                  onDateChange={(date) => handleStartDateChange(date)}
                  date={dayjs(startDate)}
                  label={t("StartDate")}
                /> */}
                <CustomYearDatePicker
                  date={dayjs(endDate)}
                  onDateChange={(newYear) => handleEndDateChangeNew(newYear, 'year')}
                  labelMonth={t("EndYear")}
                />
              </div>
            )}
          </div>

          <div className="document-Dense-container">
            <div
              style={{ display: "flex", flexDirection: "column", width: "30%" }}
            >
              <AutocompleteSelect
                options={processedCardsData}
                selectedValues={selectedFormattedAccountCard}
                onChange={handleFormattedAccountCardChange}
                label={t("Select AccountCard")}
                placeholder={t("Select AccountCard")}
                isMultiple={false}
                width="100%"
              />
              <Button
                variant="contained"
                onClick={() => handleOpenCreateModal()}
                startIcon={<AddCircleOutlineIcon sx={{ ml: 1 }} />}
                sx={{
                  backgroundColor: "#304FFF",
                  color: "#fff",
                  margin: "10px 0 0 0",
                  "&:hover": { backgroundColor: "#F9AA2A" },
                }}
              >
                {t("AddBank")}
              </Button>
            </div>
            <ClickableDenseTable
              headers={denseTableHeader}
              content={denseTableRows}
              styleFlag={false}
              numericrows={false}
              setSelectedRow={selectedDenseTableRow}
              selectedRowIndex={selectedTBankIndex}
              align="left"
              reverse={true}
              width="700px"
              openEdit={handleOpenEditTBank}
            />
            <DenseTable headers={[t('Balance'), t('Credit'), t('Debit')]}
              rows={denseTableCreditDebitRows} width='300px'
              headerStyle={{
                opacity: 0.6,
                background: 'var(--orange, #F9AA2A)',
                textAlign: 'center',
                minHeight: '50px'
              }}
              rowStyle={{
                borderTop: '1px solid rgba(191, 191, 193, 0.56)',
                background: 'var(--secondary-12, rgba(254, 247, 234, 0.12))',
                minHeight: '50px'
              }}
            />


          </div>
          <input
            type="file"
            ref={fileInputRef1}
            style={{ display: "none" }} // Hide the file input
          />
          {/* current table */}
          <div
            className="table-container"
            style={{
              overflowX: "visible",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StyledToggleButtonGroup
              value={fetchType}
              exclusive
              onChange={handleFetchTypeChange}
              aria-label="Platform"
            >
              <ToggleButton value="Matched">{t("Matched")}</ToggleButton>
              <ToggleButton value="NotMatched">{t("NotMatched")}</ToggleButton>
            </StyledToggleButtonGroup>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: "40px",
              }}
            >
              {fetchType === "Matched" ? (
                <div style={{ width: "100%", height: "60vh" }}>
                  <Box
                    sx={{
                      height: "60vh",
                      width: "100%",
                      boxSizing: "border-box",
                      backgroundColor: "#fff",
                    }}
                  >
                    {/* <StyledDataGrid
                      rows={matchedDisplayedRows}
                      columns={MatchedColumns}
                      pageSize={10}
                      rowsPerPageOptions={[10, 25, 50]}
                      checkboxSelection
                      disableRowSelectionOnClick
                      onRowSelectionModelChange={(ids) =>
                        setSelectedMatchedRows(ids)
                      }
                      getRowClassName={(params) => {
                        const row = params.row;
                        if (row.isHeader) return "super-app-theme--Header";
                        if (row.isSubRow && !row.isHeader)
                          return "super-app-theme--SubRow";
                        return "super-app-theme--MainRow";
                      }}
                      getRowId={(row) => row.id}
                      slots={{ toolbar: GridToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                      onSortModelChange={(model) => setSortModel(model)}
                    /> */}
                    <div className={`searchFilter-bar__container ${direction}`} style={{ marginBottom: '20px' }}>
                      {/* Search Field */}
                      <TextField
                        fullWidth
                        placeholder={t('Search')}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-bar"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                          sx: {
                            height: '55px',
                            padding: '0 10px',
                            borderRadius: '8px',
                            background: '#F5F5F6',
                            border: '1px solid transparent',
                            '&:focus-within': {
                              border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                            },
                          },
                          ...(direction === 'ltr'
                            ? {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }
                            : {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }),
                        }}
                        inputProps={{
                          style: {
                            textAlign: direction === 'ltr' ? 'left' : 'right',
                          },
                        }}
                      />


                    </div>
                    <MatchedTable
                      matchedDisplayedRows={matchedDisplayedRows}
                      expandedMatchedRows={expandedMatchedRows}
                      handleMatchedExpandClick={handleMatchedExpandClick}
                      handleRemoveMatchClick={handleRemoveMatchClick}
                      formatDate={formatDate}
                      beautifyNumber={beautifyNumber}
                      t={t}
                    />
                  </Box>
                </div>
              ) : (
                <>
                  <div style={{ width: "49.7%", height: "60vh" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "16px",
                        backgroundColor: "#ebebeb",
                        height: "40px",
                      }}
                    >
                      <div className="table-titles">{t("BookCommands")}</div>
                    </div>

                    <Box
                      dir="rtl"
                      sx={{
                        height: "100%",
                        width: "100%",
                        boxSizing: "border-box",
                        backgroundColor: "#fff",
                      }}
                    >
                      <StyledDataGrid
                        apiRef={apiRef}
                        rows={displayedRows}
                        columns={InternalColumns}
                        pageSize={10}
                        rowsPerPageOptions={[10, 25, 50]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        onRowSelectionModelChange={(ids) =>
                          setSelectedInternalRows(ids)
                        }
                        getRowClassName={(params) =>
                          params.row.rowClassName || ""
                        }
                        getRowId={(row) => row.id}
                        onCellEditCommit={(row) => handleEdit(row)}
                        rowSelectionModel={selectedInternalRows}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                        pagination
                        onSortModelChange={handleSortModelChange}
                        onFilterModelChange={handleSortModelChange}
                        onCellDoubleClick={handleCellDoubleClick}

                      />
                    </Box>
                  </div>

                  {/* <div style={{ width: '0.6%', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{
                                            width: '100%',
                                            height: '65vh',
                                            backgroundColor: '#f0f0f0',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>

                                        </div>
                                    </div> */}

                  <div style={{ width: "49.7%", height: "60vh" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "16px",
                        backgroundColor: "#ebebeb",
                        height: "40px",
                      }}
                    >
                      <div className="table-titles">{t("BankCommands")}</div>

                      <Button
                        variant="contained"
                        onClick={handleOpenBankAddTransactionDialog}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: !selectedTBank ? "#ccc" : "#304FFF",
                          color: !selectedTBank ? "#777" : "#fff",
                          "&:hover": {
                            backgroundColor: !selectedTBank
                              ? "#ccc"
                              : "#1565c0",
                          },
                          gap: "10px",
                        }}
                      >
                        <AddCircleOutlineIcon style={{ marginRight: "10px" }} />
                        {t("AddBankCommand")}
                      </Button>
                      <div style={{ display: 'flex', gap: '10px' }}>

                        <Tooltip title={t("DownloadTemplate")} arrow>
                          <Button
                            variant="contained"
                            onClick={() => handleDownloadTemplate(BankFile, "Xtram_Bank_Template.xlsx")}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "30px",
                              backgroundColor: "#304FFF",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#1565c0",
                              },
                              gap: "10px",
                              minWidth: "auto", // Ensures button doesn't stretch
                              // padding: "5px 10px",
                            }}
                          >
                            <DownloadIcon sx={{ width: "20px", height: "20px" }} />
                            {t("DownloadXtramTemplate")}
                          </Button>
                        </Tooltip>
                        <Button
                          variant="contained"
                          onClick={handleOpenImportDialog}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: !selectedTBank ? "#ccc" : "#304FFF",
                            color: !selectedTBank ? "#777" : "#fff",
                            "&:hover": {
                              backgroundColor: !selectedTBank
                                ? "#ccc"
                                : "#1565c0",
                            },
                            gap: "10px",
                          }}
                        >
                          <CloudUploadIcon />
                          {t("Import")}
                        </Button>
                      </div>

                    </div>

                    <Box
                      dir="rtl"
                      sx={{
                        height: "100%",
                        width: "100%",
                        boxSizing: "border-box",
                        backgroundColor: "#fff",
                      }}
                    >
                      <StyledDataGrid
                        rows={externalDisplayedRows}
                        columns={ExternalColumns}
                        pageSize={10}
                        rowsPerPageOptions={[10, 25, 50]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        onRowSelectionModelChange={(ids) =>
                          setSelectedExternalRows(ids)
                        }
                        getRowClassName={(params) =>
                          params.row.rowClassName || ""
                        }
                        getRowId={(row) => row.id}
                        onCellEditCommit={(row) => handleEdit(row)}
                        rowSelectionModel={selectedExternalRows}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                        pagination
                      />
                    </Box>
                  </div>
                </>
              )}
            </div>
          </div>
          <div style={{ margin: "120px 0 0 0" }}></div>
        </div>
        <HamburgerMenu client={client} />

        <ToastContainer />
        {loading && (
          <div className="loader-container">
            <PuffLoader
              size={100}
              loading={loading}
            />
          </div>
        )}
        <ImportDialog
          open={openImportDialog}
          onClose={handleCloseImportDialog}
          onImport={handleImport}
        />
        {!(editedItem && paymentModal) &&
          !(
            editedItem &&
            editedPopupItem &&
            Object.keys(editedItem).length > 0
          ) &&
          fetchType !== "Matched" && (
            <BottomDockBar
              columns={ExternalColumns}
              handleJournalEntries={handleCreateJournalEntries}
              handleMatchEntries={handleMatchEntries}
              handleAutoMatch={handleAutoMatchHeaders}
              handleMatch={handleMatch}
              handleClearMatches={handleClearMatches}
              externalSum={externalSum}
              internalSum={internalSum}
              matches={matchedPairs}
              selectedInternalRows={selectedInternalRows}
              selectedExternalRows={selectedExternalRows}
              handleDeleteExternalRows={clickedDeleteMultipleExternal}
            />
          )}
        {/* Dialog for Create Journal Entries with Inputs */}

        <Dialog
          open={isCreateDialogOpen}
          onClose={handleCloseDialog}
          maxWidth="md"
          fullWidth
          dir="rtl"
        >
          <DialogTitle style={{ textAlign: "right !important" }}>
            {" "}
            {t("CreateJournalEntries")}
          </DialogTitle>
          <DialogContent>
            {createJournalEntries === true ? (
              // Case 2: Checked is true
              <>
                <Typography variant="body1" gutterBottom>
                  {t("Select AccountCard")}
                </Typography>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ mt: 3 }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "16px",
                      width: "30%",
                      margin: "0 auto",
                    }}
                  >
                    {/* <Autocomplete
                      options={accountCardOptions}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("AccountCard")}
                          fullWidth
                          required
                          InputProps={{
                            ...params.InputProps,
                            className: "custom-input-box-sizing",
                          }}
                          sx={{
                            marginRight: "16px",
                            ".MuiInputBase-root": {
                              boxSizing: "border-box !important", // Apply box-sizing to the desired class
                            },
                          }}
                        />
                      )}
                      value={
                        accountCardOptions.find(
                          (option) => option.value === formValues.accountCard
                        ) || null
                      }
                      ListboxProps={{ dir: "rtl" }}
                      style={{
                        direction: "rtl",
                        boxSizing: "border-box !important",
                      }}
                      onChange={(event, newValue) =>
                        handleSelectChange(
                          "accountCard",
                          newValue ? newValue.value : ""
                        )
                      }
                    /> */}

                    <AccountAutocomplete
                      type="accountCard"
                      options={formattedAccountCardOptions}
                      formValues={formValues}
                      handleSelectChange={handleSelectChange}
                      editedItem={editedItem}
                      setCreditDebitSearchTerm={setCreditDebitSearchTerm}
                      handleNoOptionCreditDebitClick={handleNoOptionCreditDebitClick}
                      creditDebitSearchTerm={creditDebitSearchTerm}
                      t={t}
                      direction={direction}
                      EditAccountCard={EditAccountCard}
                      width="100%"
                    />
                  </div>
                  <Typography variant="body1" gutterBottom>
                    {t("Select SortCode")}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "16px",
                      width: "30%",
                      margin: "0 auto",
                    }}
                  >
                    <Autocomplete
                      options={sortCodeIdOptions}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Code ID")}
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            className: "custom-input-box-sizing",
                          }}
                          sx={{
                            marginRight: "16px",
                            ".MuiInputBase-root": {
                              boxSizing: "border-box !important", // Apply box-sizing to the desired class
                            },
                          }}
                        />
                      )}
                      value={
                        sortCodeIdOptions.find(
                          (option) => option.value === formValues.sortCode
                        ) || null
                      } // bind value
                      ListboxProps={{ dir: "rtl" }}
                      style={{
                        direction: "rtl",
                        boxSizing: "border-box !important",
                      }}
                      onChange={(event, newValue) =>
                        handleSelectChange(
                          "sortCode",
                          newValue ? newValue.value : ""
                        )
                      }
                    />
                  </div>
                  <Typography variant="body1" gutterBottom>
                    {t("SelectMonthReport")}
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "16px",
                      width: "30%",
                      margin: "0 auto",
                    }}
                  >
                    <CustomMonthDatePickerMui
                      Date={reportingMonthDate}
                      setDate={setReportingMonthDate}
                      label="MonthReportDate"
                    />
                  </div>
                </Box>
              </>
            ) : (
              <>
                <Typography variant="body1" gutterBottom>
                  {t("SelectedRowsTotalBankSum")}{" "}
                  {beautifyNumber(externalSum - internalSum)} {t("and")}
                  {createJournalEntries === true
                    ? t("CreateJournalEntryForEachRow")
                    : t("CreateJournalEntryForAllRows")}
                  .
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {t("FillDetailsToProceed")}
                </Typography>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ mt: 3 }}
                >
                  {/* First Row: Amount */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "16px",
                      width: "30%",
                      margin: "0 auto",
                    }}
                  >
                    <TextField
                      fullWidth
                      label={t("Amount")}
                      name="amount"
                      type="number"
                      onChange={handleInputChange}
                      sx={{ marginRight: "16px" }}
                      InputProps={{
                        className: "custom-input-box-sizing",
                      }}
                      value={formValues.amount}
                    />
                  </div>

                  {/* Second Row: Credit Account and Debit Account */}
                  <div
                    dir="rtl"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginBottom: "16px",
                      marginTop: "16px",
                    }}
                  >
                    {/* Debit Account Autocomplete */}
                    {/* <Autocomplete
                      options={debitAccountOptions}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Debit Account")}
                          fullWidth
                          required
                          sx={{
                            marginRight: "16px",
                            ".MuiInputBase-root": {
                              boxSizing: "border-box !important", // Apply box-sizing to the desired class
                            },
                          }}
                          InputProps={{
                            ...params.InputProps, // Spread the default InputProps
                            className: "custom-input-box-sizing",
                          }}
                        />
                      )}
                      value={
                        debitAccountOptions.find(
                          (option) => option.value === formValues.debitAccount
                        ) || null
                      } // bind value
                      ListboxProps={{ dir: "rtl" }}
                      style={{
                        direction: "rtl",
                        boxSizing: "border-box !important",
                      }}
                      sx={{ width: "30% !important" }}
                      onChange={(event, newValue) =>
                        handleSelectChange(
                          "debitAccount",
                          newValue ? newValue.value : ""
                        )
                      }
                      onInputChange={(event, inputValue) => {
                        // Store the input value to pass to the function if needed
                        setCreditDebitSearchTerm(inputValue);
                      }}
                      noOptionsText={
                        <Button
                          onClick={() => handleNoOptionCreditDebitClick(creditDebitSearchTerm, 'debitAccount', editedItem.docType)}
                          variant="contained"
                          color="primary"
                        >
                          {t('AddAccountCard')} "{creditDebitSearchTerm}"
                        </Button>
                      }
                    /> */}
                    <AccountAutocomplete
                      type="debitAccount"
                      options={formattedAccountCardOptions}
                      formValues={formValues}
                      handleSelectChange={handleSelectChange}
                      setCreditDebitSearchTerm={setCreditDebitSearchTerm}
                      handleNoOptionCreditDebitClick={handleNoOptionCreditDebitClick}
                      creditDebitSearchTerm={creditDebitSearchTerm}
                      editedItem={editedItem}
                      t={t}
                      direction={direction}
                      EditAccountCard={EditAccountCard}
                      sx={{ width: "30% !important" }}
                    />

                    {/* <Autocomplete
                      options={creditAccountOptions}
                      getOptionLabel={(option) => option.label}
                      sx={{ width: "30%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Credit Account")}
                          fullWidth
                          required
                          sx={{
                            marginRight: "16px",
                            ".MuiInputBase-root": {
                              boxSizing: "border-box !important", // Apply box-sizing to the desired class
                            },
                          }}
                          // sx={{ marginRight: '16px' }}
                          InputProps={{
                            ...params.InputProps, // Spread the default InputProps
                            className: "custom-input-box-sizing",
                          }}
                        />
                      )}
                      value={
                        creditAccountOptions.find(
                          (option) => option.value === formValues.creditAccount
                        ) || null
                      } // bind value
                      ListboxProps={{ dir: "rtl" }}
                      style={{ direction: "rtl" }}
                      onChange={(event, newValue) =>
                        handleSelectChange(
                          "creditAccount",
                          newValue ? newValue.value : ""
                        )
                      }
                      onInputChange={(event, inputValue) => {
                        // Store the input value to pass to the function if needed
                        setCreditDebitSearchTerm(inputValue);
                      }}
                      noOptionsText={
                        <Button
                          onClick={() => handleNoOptionCreditDebitClick(creditDebitSearchTerm, 'creditAccount', editedItem.docType)}
                          variant="contained"
                          color="primary"
                        >
                          {t('AddAccountCard')} "{creditDebitSearchTerm}"
                        </Button>
                      }
                    /> */}
                    <AccountAutocomplete
                      type="creditAccount"
                      options={formattedAccountCardOptions}
                      formValues={formValues}
                      handleSelectChange={handleSelectChange}
                      setCreditDebitSearchTerm={setCreditDebitSearchTerm}
                      handleNoOptionCreditDebitClick={handleNoOptionCreditDebitClick}
                      creditDebitSearchTerm={creditDebitSearchTerm}
                      editedItem={editedItem}
                      t={t}
                      direction={direction}
                      EditAccountCard={EditAccountCard}
                      sx={{ width: "30% !important" }}
                    />


                  </div>

                  {/* Third Row: Description and Reference */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginBottom: "16px",
                    }}
                  >
                    <TextField
                      fullWidth
                      label={t("Description")}
                      name="description"
                      onChange={handleInputChange}
                      InputProps={{
                        className: "custom-input-box-sizing",
                      }}
                      sx={{ width: "30%" }}
                      value={formValues.description}
                    />
                    <TextField
                      fullWidth
                      label={t("Reference")}
                      name="reference"
                      onChange={handleInputChange}
                      InputProps={{
                        className: "custom-input-box-sizing",
                      }}
                      sx={{ width: "30%" }}
                      value={formValues.reference}
                    />
                  </div>

                  {/* Fourth Row: Code ID and ValueDate */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "16px",
                    }}
                  >
                    <Autocomplete
                      options={sortCodeIdOptions}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Code ID")}
                          fullWidth
                          sx={{
                            marginRight: "16px",
                            ".MuiInputBase-root": {
                              boxSizing: "border-box !important", // Apply box-sizing to the desired class
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            className: "custom-input-box-sizing",
                          }}
                        />
                      )}
                      ListboxProps={{ dir: "rtl" }}
                      style={{ direction: "rtl" }}
                      sx={{ width: "30%" }}
                      onChange={(event, newValue) =>
                        handleSelectChange(
                          "sortCodeId",
                          newValue ? newValue.value : ""
                        )
                      }
                      value={
                        sortCodeIdOptions.find(
                          (option) => option.value === formValues.sortCodeId
                        ) || null
                      } // bind value
                    />
                    <div style={{ width: "30%", marginRight: "30px" }}>
                      <TextField
                        fullWidth
                        label={t("ValueDate")}
                        name="documentDate"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={handleInputChange}
                        InputProps={{
                          className: "custom-input-box-sizing",
                        }}
                        sx={{ width: "100%" }}
                        value={formValues.documentDate}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "16px",
                        width: "30%",
                        margin: "0 auto",
                      }}
                    >
                      <CustomMonthDatePickerMui
                        Date={formValues.reportingMonthDate} // Pass current value from formValues
                        setDate={(value) =>
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            reportingMonthDate: value, // Update the reportingMonthDate field
                          }))
                        } // Pass function to update formValues
                        label="MonthReportDate" // The label for the date picker
                      />
                    </div>
                  </div>
                </Box>
              </>
            )}
          </DialogContent>

          <div className="button-row-JournalEntryMatch">
            <button
              className="JournalEntryMatch-create-buttons"
              onClick={handleConfirm}
              disabled={
                createJournalEntries === true
                  ? !formValues.accountCard
                  : !formValues.debitAccount || !formValues.creditAccount
              }
              style={{
                backgroundColor:
                  createJournalEntries === true
                    ? !formValues.accountCard
                      ? "lightgray"
                      : "#304FFF" // Light gray when disabled, blue when enabled
                    : !formValues.debitAccount || !formValues.creditAccount
                      ? "lightgray"
                      : "#304FFF",
                color:
                  createJournalEntries === true
                    ? !formValues.accountCard
                      ? "#888"
                      : "#fff" // Gray text when disabled, white when enabled
                    : !formValues.debitAccount || !formValues.creditAccount
                      ? "#888"
                      : "#fff",
                cursor:
                  createJournalEntries === true
                    ? !formValues.accountCard
                      ? "not-allowed"
                      : "pointer"
                    : !formValues.debitAccount || !formValues.creditAccount
                      ? "not-allowed"
                      : "pointer",
                border: "none",
                padding: "10px 20px",
                borderRadius: "5px",
              }}
            >
              {t("Confirm")}
            </button>
            <button
              className="JournalEntryMatch-cancel-buttons"
              onClick={handleCloseDialog}
            >
              {t("close")}
            </button>
          </div>
        </Dialog>

        <Dialog
          open={isJournalEntriesListOpen}
          onClose={() => setIsJournalEntriesListOpen(false)}
          maxWidth="md"
          fullWidth
          dir="rtl"
        >
          <DialogTitle style={{ textAlign: "right !important" }}>
            {t("JournalEntriesPreview")}
          </DialogTitle>
          <DialogContent>
            {journalEntriesList.map((entry, index) => (
              <Card
                key={index}
                sx={{
                  mb: 2,
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Customize the shadow to give a 3D effect
                  borderRadius: "10px", // Add rounded corners
                  transition: "transform 0.2s", // Smooth transition on hover
                  "&:hover": {
                    transform: "translateY(-5px)", // Lift the card when hovered
                  },
                }}
              >
                <CardContent>
                  <Typography variant="h6">
                    {t("Entry")} #{index + 1}
                  </Typography>
                  <Typography variant="body1">
                    {t("amount")}: {beautifyNumber(entry.amount)}
                  </Typography>
                  <Typography variant="body1">
                    {t("Debit Account")}: {entry.debitAccount} (
                    {getAccountCardName(entry.debitAccount)})
                  </Typography>
                  <Typography variant="body1">
                    {t("Credit Account")}: {entry.creditAccount} (
                    {getAccountCardName(entry.creditAccount)})
                  </Typography>
                  <Typography variant="body1">
                    {t("description")}: {entry.description}
                  </Typography>
                  <Typography variant="body1">
                    {t("reference")}: {entry.reference}
                  </Typography>
                  <Typography variant="body1">
                    {t("Document Date")}: {entry.documentDate}
                  </Typography>
                  <Typography variant="body1">
                    {t("MonthReportDate")}:{" "}
                    {formatMonthYearDate(entry.reportingMonthDate)}
                  </Typography>
                  {entry.sortCodeId && (
                    <Typography variant="body1">
                      {t("SortCode")}: {entry.sortCodeId}{" "}
                      {getSortCodeName(entry.sortCodeId)}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            ))}
          </DialogContent>

          <div className="button-row-JournalEntryMatch">
            <button
              className="JournalEntryMatch-create-buttons"
              onClick={handleFinalizeEntries}
            >
              {t("create")}
            </button>
            <button
              className="JournalEntryMatch-cancel-buttons"
              onClick={() => setIsJournalEntriesListOpen(false)}
            >
              {t("close")}
            </button>
          </div>
        </Dialog>

        {selectedMatch && (
          <Dialog
            open={openRemoveMatchDialog}
            onClose={handleCloseRemoveMatchDialog}
            aria-labelledby="confirm-dialog-title"
            dir="rtl"
            aria-describedby="confirm-dialog-description"
            sx={{
              "& .MuiDialog-paper": {
                backgroundColor: "white",
                color: "black",
                borderRadius: "8px",
                padding: "20px",
              },
            }}
          >
            <DialogTitle
              id="confirm-dialog-title"
              sx={{ fontSize: "20px", color: "#E57C22" }}
            >
              {t("ConfirmRemoval")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="confirm-dialog-description"
                sx={{ color: "black", fontSize: "16px" }}
              >
                {t("ConfirmRemoveMatch")}
                <br />
                <strong> {t("CaseID")}:</strong> {selectedMatch.caseId}
                <br />
                <strong> {t("AccountCard Name")}:</strong>{" "}
                {selectedMatch.accountCardName}
                <br />
                <strong> {t("AccountCodeID")}:</strong>{" "}
                {selectedMatch.accountCodeId}
                <br />
                <strong> {t("TransactionDate")}:</strong>{" "}
                {formatDate(selectedMatch.transactionDate)}
                <br />
                <strong> {t("MatchDate")}:</strong>{" "}
                {formatDate(selectedMatch.matchDate)}
                <br />
                <strong> {t("MatchNumber")}:</strong>{" "}
                {selectedMatch.matchNumber}
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", padding: "16px" }}>
              <Button
                onClick={handleCloseRemoveMatchDialog}
                sx={{ color: "#E57C22", fontWeight: "bold" }}
              >
                {t("Cancel")}
              </Button>
              <Button
                onClick={handleConfirmRemove}
                sx={{
                  backgroundColor: "#E57C22",
                  color: "white",
                  fontWeight: "bold",
                  padding: "8px 16px",
                  borderRadius: "4px",
                  "&:hover": {
                    backgroundColor: "#d46d1e",
                  },
                }}
                autoFocus
              >
                {t("Remove")}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>

      <CustomDeleteConfirmationModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onDelete={handleConfirmDeletion}
        confirmMessage={getConfirmMessage()}
        titleMessage={getTitleMessage()}
      />

      {showChooseAsset && (
        <ChooseAsset
          date={dateForAsset}
          open={showChooseAsset}
          onClose={() => handleAssetClose()}
          type={editedItem?.docType} //or type
          itemId={itemToAddToAsset}
          client={client}
          token={token}
          onAssetChosen={(assetId) => {
            setShowChooseAsset(false);
          }}
          onCreateAsset={(newAsset) => {
            setShowChooseAsset(false);
          }}
          fullItem={itemForAssets}
          saleEquipment={saleEquipment}
          clickedAsset={clickedAsset}
          fetchEnums={fetchEnums}
          user={user}
          setShowAddGroupModal={handleOpenGroudAdd}
          assetGroups={assetGroups}

        />
      )}

      {showAddGroupModal && (
        <AddAssetGroup
          open={showAddGroupModal}
          onClose={() => setShowAddGroupModal(false)}
          caseId={client.caseId}
          token={token}
          assetGroups={assetGroups}
          refreshAssetGroups={() => {
            // Refresh the list
            fetchEnums(user, token, client);
            setShowAddGroupModal(false);
          }}
        />
      )}


      {editedItem && editedPopupItem && Object.keys(editedItem).length > 0 && (
        <EditModal
          editedItem={editedItem}
          fade={fade}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          currentIndex={currentIndex}
          filteredAndSortedData={sortedTransactions}
          NoDocumentsPdf={NoDocumentsPdf}
          t={t}
          handleAccountChange={handleAccountChange}
          isRange={isRange}
          isClosedReportingMonth={isClosedReportingMonth}
          accountCardOptions={editModalsAccountCardOptions}
          handleChange={handleChange}
          handleDateChange={handleDateChange}
          CustomInput2={CustomInput2}
          setReportingMonthDate={setReportingMonthDate}
          handleVatRadioChange={handleVatRadioChange}
          statusCheck={statusCheck}
          handleRadioChange={handleRadioChange}
          type={type}
          editedAddAccountCard={editedAddAccountCard}
          handleCloseEditAddNewAccountCardDocument={
            handleCloseEditAddNewAccountCardDocument
          }
          handleEditAddAccountChange={handleEditAddAccountChange}
          sortCodeIdOptions={sortCodeIdOptionsForEditModals}
          debitAccountOptions={debitAccountOptions}
          creditAccountOptions={creditAccountOptions}
          handleUpdate={handleUpdate}
          handleCancel={handleCancel}
          handleDeleteClick={handleEditDeleteClick}
          client={client}
          token={token}
          setAccountCardOptions={setAccountCardOptions}
          setLoading={setLoading}
          setEditedAddAccountCard={setEditedAddAccountCard}
          setEditedItem={setEditedItem}
          handleReopenEdit={handleReopenEdit}
          showIframe={!editedJournalItem}
          palCodes={formattedPalCodeOptions}
          fetchEnums={fetchEnums}
          RefreshPage={FetchData}
          assets={assets}
          assetClicked={handleAssetClicked}
        />
      )}

      {addEditableItem && paymentModal && (
        <PaymentAcceptanceCreateEditModal
          openInvoiceCreatorModal={openInvoiceCreatorModal}
          handleClose={handleClose}
          t={t}
          itemEditing={itemEditing}
          addNewAccountCardDocument={addNewAccountCardDocument}
          companyId={companyId}
          accountCodeName={accountCodeName}
          accountCodeId={accountCodeId}
          setAccount={setAccount}
          Constants={Constants}
          palCode={palCode}
          setPalCode={setPalCode}
          isRange={isRange}
          addEditableItem={addEditableItem} // renamed from editedItem
          isClosedReportingMonth={isClosedReportingMonth}
          handleSaveEditAccountCard={handleSaveEditAccountCard}
          handleAddAccountCard={handleAddAccountCard}
          handleCloseAddNewAccountCardDocument={
            handleCloseAddNewAccountCardDocument
          }
          accountCardOptions={accountCardOptions}
          selectedOption={selectedOption}
          handleMuiChange={handleMuiChange}
          handleEdit={handleEdit}
          handleAccountDelete={handleAccountDelete} // renamed from handleDelete
          handleChangeAccountType={handleChangeAccountType}
          customStyles={customStyles}
          setPaymentDate={setPaymentDate}
          setReportingMonthDate={setReportingMonthDate}
          handleDescriptionChange={handleDescriptionChange}
          handleChangeOriginalTaxClearance={handleChangeOriginalTaxClearance}
          handleAddEditableItemChange={handleAddEditableItemChange}
          handleItemChange={handleItemChange}
          handleRemoveItem={handleRemoveItem}
          showAccountCardInputsEdit={showAccountCardInputsEdit}
          statusCheck={statusCheck}
          handleRadioChange={handleRadioPAChange}
          handleSave={handleSave}
          buttonDisabled={buttonDisabled}
          paymentBeingEdited={paymentBeingEdited}
          CustomDropdown={CustomDropdown}
          type={type}
          direction={direction}
          setAddNewAccountCardDocument={setAddNewAccountCardDocument}
          isClosedReportingMonthAndIsRange={isClosedReportingMonthAndIsRange}
          handleAddItem={handleAddItem}
          CustomPalCode={CustomPalCode}
          handleAccountEdit={handleAccountEdit}
          handlePrevious={handlePrevious}
          currentIndex={currentIndex}
          filteredAndSortedData={filteredAndSortedTransactions}
          handleNext={handleNext}
          showType={showType}
          handleDeleteClick={handleEditDeleteClick}
          fetchEnums={fetchEnums}

        />
      )}
    </>
  );
}

export default BankMatching;
