import React, { useState, useEffect, useRef } from 'react';
import {
    TextField,
    Tooltip,
    IconButton,
    Portal
} from '@mui/material';
import Draggable from 'react-draggable';
import { styled } from '@mui/system';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled('div')({
    position: 'absolute',
    top: '10%',
    left: '20%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '16px',
    minHeight: '30vh',
    maxHeight: '80vh',
    overflowY: 'auto',
    minWidth: '500px',
    zIndex: 1300,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
    border: '1px solid #304fff',
});

function ClientItemDraggableDialog({ open, onClose, data, onSave, direction, t }) {
    const [formData, setFormData] = useState({});
    const nodeRef = useRef(null);

    useEffect(() => {
        if (data) {
            setFormData({ ...data });
        }
    }, [data]);

    const handleChange = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    const handleSave = () => {
        onSave(formData);
    };

    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        },
    };

    const inputStyle = {
        style: {
            textAlign: direction === 'rtl' ? 'right' : 'left',
            direction: direction === 'rtl' ? 'rtl' : 'ltr',
        },
    };

    if (!open) return null;

    return (
        <Portal>
            <Draggable handle=".draggable-handle" nodeRef={nodeRef}>
                <StyledContainer ref={nodeRef} style={{ direction }}>
                    {/* Header */}
                    <div
                        className="draggable-handle"
                        style={{
                            cursor: 'move',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            marginBottom: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Tooltip title={t('Exit')}>
                            <IconButton onClick={onClose}>
                                <CancelIcon style={{ color: '#304FFF' }} />
                            </IconButton>
                        </Tooltip>
                        <div>{t('EditClientItem')}: {formData.makat}</div>
                        <div />
                    </div>

                    {/* Form Fields */}
                    <div className="form-row" style={{ marginBottom: 16 }}>
                        <div className="edit-form-row-item">
                            <div className="edit-form-row-item-title" style={{ marginBottom: 4 }}>
                                {t('Makat')}
                            </div>
                            <TextField
                                fullWidth
                                value={formData.makat || ''}
                                onChange={(e) => handleChange('makat', e.target.value)}
                                variant="standard"
                                InputProps={inputPropsStyle}
                                inputProps={inputStyle}
                            />
                        </div>
                    </div>

                    <div className="form-row" style={{ marginBottom: 16 }}>
                        <div className="edit-form-row-item">
                            <div className="edit-form-row-item-title" style={{ marginBottom: 4 }}>
                                {t('Name')}
                            </div>
                            <TextField
                                fullWidth
                                value={formData.name || ''}
                                onChange={(e) => handleChange('name', e.target.value)}
                                variant="standard"
                                InputProps={inputPropsStyle}
                                inputProps={inputStyle}
                            />
                        </div>
                    </div>

                    <div className="form-row" style={{ marginBottom: 16 }}>
                        <div className="edit-form-row-item">
                            <div className="edit-form-row-item-title" style={{ marginBottom: 4 }}>
                                {t('Price')}
                            </div>
                            <TextField
                                fullWidth
                                type="number"
                                value={formData.price || ''}
                                onChange={(e) => handleChange('price', e.target.value)}
                                variant="standard"
                                InputProps={inputPropsStyle}
                                inputProps={inputStyle}
                            />
                        </div>
                    </div>

                    {/* Footer Actions */}
                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '16px', gap: '10px' }}>
                        <div onClick={onClose} className="edit-cancel-button">
                            {t('Cancel')}
                        </div>
                        <div onClick={handleSave} className="edit-save-button">
                            {t('Save')}
                        </div>
                    </div>
                </StyledContainer>
            </Draggable>
        </Portal>
    );
}

export default ClientItemDraggableDialog;
