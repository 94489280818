import React, { useState, useEffect, useRef } from 'react';
import {
    TextField,
    Tooltip,
    IconButton,
} from '@mui/material';
import Draggable from 'react-draggable';
import { styled } from '@mui/system';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled('div')({
    position: 'absolute',
    top: '10%',
    left: '20%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '16px',
    minHeight: '40vh',
    maxHeight: '75vh',
    overflowY: 'auto',
    minWidth: '500px',
    zIndex: 1300,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
    border: '1px solid #304fff',
});

function ClientItemCreateModal({ open, onClose, onSave, existingItems = [] }) {
    const { t, i18n } = useTranslation();
    const nodeRef = useRef(null);
    const [direction, setDirection] = useState('rtl');

    const [itemsToAdd, setItemsToAdd] = useState([]);

    useEffect(() => {
        setDirection(i18n.language === 'he' || i18n.language === 'ar' ? 'rtl' : 'ltr');
    }, [i18n.language]);


    useEffect(() => {
        if (existingItems) {
            let newMakatNumber = 1;
            if (existingItems.length > 0) {
                const maxMakat = Math.max(
                    ...existingItems.map((it) => parseInt(it.makat, 10) || 0)
                );
                newMakatNumber = maxMakat + 1;
            }
            setItemsToAdd([{ makat: newMakatNumber.toString(), name: '', price: '' }]);
        }
    }, [existingItems]);

    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        },
    };

    const inputStyle = {
        style: {
            textAlign: direction === 'rtl' ? 'right' : 'left',
            direction: direction === 'rtl' ? 'rtl' : 'ltr',
        },
    };

    const handleAddRow = () => {
        setItemsToAdd((prev) => {
            let newMakat = '1';
            if (prev.length > 0) {
                const maxMakat = Math.max(
                    ...prev.map((it) => parseInt(it.makat, 10) || 0)
                );
                newMakat = `${maxMakat + 1}`;
            }
            return [...prev, { makat: newMakat, name: '', price: '' }];
        });
    };

    const handleRemoveRow = (index) => {
        setItemsToAdd((prev) => prev.filter((_, i) => i !== index));
    };

    const handleChange = (index, field, value) => {
        setItemsToAdd((prev) => {
            const updatedItems = [...prev];
            updatedItems[index] = { ...updatedItems[index], [field]: value };
            return updatedItems;
        });
    };

    const handleSave = () => {
        onSave(itemsToAdd);
    };

    if (!open) return null;

    return (
        <Draggable handle=".handle" nodeRef={nodeRef}>
            <StyledContainer ref={nodeRef} style={{ direction }}>
                {/* Header */}
                <div
                    className="handle"
                    style={{
                        cursor: 'move',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        marginBottom: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Tooltip title={t('Exit')}>
                        <IconButton onClick={onClose}>
                            <CancelIcon style={{ color: '#304FFF' }} />
                        </IconButton>
                    </Tooltip>
                    <div className="edit-title">{t('AddClientItem')}</div>
                    <div />
                </div>

                {/* Items */}
                {itemsToAdd.map((item, index) => (
                    <div key={index} style={{ marginBottom: 24 }}>
                        {index > 0 && (
                            <hr
                                style={{
                                    border: '0.5px solid #ddd',
                                    marginBottom: 24,
                                }}
                            />
                        )}

                        <div className="form-row" style={{ marginBottom: 16 }}>
                            <div className="edit-form-row-item" style={{ marginBottom: 8 }}>
                                <TextField
                                    fullWidth
                                    placeholder={t('Makat')}
                                    value={item.makat}
                                    onChange={(e) => handleChange(index, 'makat', e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                />
                            </div>
                        </div>

                        <div className="form-row" style={{ marginBottom: 16 }}>
                            <div className="edit-form-row-item" style={{ marginBottom: 8 }}>
                                <TextField
                                    fullWidth
                                    placeholder={t('Name')}
                                    value={item.name}
                                    onChange={(e) => handleChange(index, 'name', e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                />
                            </div>
                        </div>

                        <div className="form-row" style={{ marginBottom: 16, display: 'flex', alignItems: 'center', gap: 8 }}>
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    placeholder={t('Price')}
                                    value={item.price}
                                    onChange={(e) => handleChange(index, 'price', e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                />
                            </div>
                            {itemsToAdd.length > 1 && (
                                <Tooltip title={t('RemoveItem')}>
                                    <IconButton onClick={() => handleRemoveRow(index)}>
                                        <DeleteIcon style={{ color: 'red' }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                ))}

                {/* Add Item Button */}
                <div
                    onClick={handleAddRow}
                    className="edit-add-button"
                    style={{
                        alignSelf: direction === 'rtl' ? 'flex-start' : 'flex-end',
                        marginBottom: 16,
                        cursor: 'pointer',
                        padding: '6px 16px',
                        backgroundColor: '#304FFF',
                        color: '#fff',
                        borderRadius: 8,
                        fontSize: 14,
                        fontWeight: 500,
                        width: 'fit-content',
                    }}
                >
                    {t('AddItem')}
                </div>

                {/* Footer Actions */}
                <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '16px', gap: '10px' }}>
                    <div onClick={onClose} className="edit-cancel-button">
                        {t('Cancel')}
                    </div>
                    <div onClick={handleSave} className="edit-save-button">
                        {t('Save')}
                    </div>
                </div>
            </StyledContainer>
        </Draggable>
    );
}

export default ClientItemCreateModal;
