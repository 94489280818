import React, { useState } from 'react';
import {
    Portal,
    Tooltip,
    IconButton,
    TextField,
    Checkbox,
    FormControlLabel,
    CircularProgress
} from '@mui/material';
import { styled } from '@mui/system';
import CancelIcon from '@mui/icons-material/Cancel';
import Select from 'react-select';

import Constants from '../../../assests/Constants/constants.js'; // Adjust path as needed
import UserService from '../../../Services/user.service.js';

/**
 * -- OVERLAY STYLES --
 * A fixed overlay covering the entire screen, with blurred background.
 */
const Overlay = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.3)',
    backdropFilter: 'blur(4px)',
    zIndex: 1300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

/**
 * -- MODAL CONTAINER STYLES --
 * Centers the modal content and preserves styling from your snippet.
 */
const StyledContainer = styled('div')({
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '16px',
    minHeight: '30vh',
    maxHeight: '80vh',
    overflowY: 'auto',
    minWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
    border: '1px solid #304fff',
});

/**
 * ClientRegisterModal
 *
 * PROPS:
 *  - open (bool) => controls modal visibility
 *  - onClose (function) => closes modal
 *  - t (function) => for i18n translations
 *  - setShowSuccessModal (function) => toggles success modal
 *  - direction (string) => "rtl" or "ltr" for text direction
 *  - toast (function) => for toast notifications
 */
function ClientRegisterModal({
    open,
    onClose,
    t,
    setShowSuccessModal,
    direction = 'ltr',
    toast,
}) {
    // --- EXACT state from your snippet ---
    const [addClientItem, setAddClientItem] = useState({
        clientName: '',
        caseID: '',
        clientID: '',
        officeIdentifyNumber: null,
        deductionsCase: '',
        taxCreditPoints: '2.25',
        clientPassword: '',
        clientPhoneNumber: '',
        clientLocation: '',
        clientEmail: '',
        clientWebsite: '',
        incomeTaxAdvancePercentage: '',
        incomeTaxAdvanceAmount: '',
        reportingType: '',
        advancedReportingType: '',
        accountManagement: '',
        caseType: '',
        subAccountantId: '',
        taxAssessor: '',
        clientPaymentMethod: 1,
        package: Constants.packageOptionsEnum.Basic,
    });

    // Focus states for potential styling
    const [focusState, setFocusState] = useState({});

    // Toggle for IncomeTaxAdvancePercentage
    const [useIncomeTaxAdvancePercentage, setUseIncomeTaxAdvancePercentage] = useState(true);

    // Searching/focus states (if needed)
    const [isReportFocus, setIsReportFocus] = useState(false);
    const [isAdvancedReportFocus, setIsAdvancedReportFocus] = useState(false);
    const [isAccountManagementFocus, setIsAccountManagementFocus] = useState(false);
    const [isCaseTypeFocus, setIsCaseTypeFocus] = useState(false);
    const [isSubAccFocus, setIsSubAccFocus] = useState(false);
    const [isTaxAssessorFocus, setIsTaxAssessorFocus] = useState(false);
    const [isPaymentFocus, setIsPaymentFocus] = useState(false);
    const [isPaymentMethodFocus, setIsPaymentMethodFocus] = useState(false);
    const [isPackageFocus, setIsPackageFocus] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    // Show spinner while saving
    const [isLoading, setIsLoading] = useState(false);

    /**
     * Check whether Save is enabled
     */
    const addClientEnabled = Boolean(
        addClientItem.clientName &&
        addClientItem.caseID &&
        addClientItem.clientID &&
        addClientItem.clientPassword &&
        addClientItem.clientPhoneNumber &&
        addClientItem.advancedReportingType &&
        addClientItem.reportingType &&
        addClientItem.accountManagement &&
        addClientItem.caseType &&
        addClientItem.package
    );

    const handleFocus = (field) => {
        setFocusState((prev) => ({ ...prev, [field]: true }));
    };
    const handleBlur = (field) => {
        setFocusState((prev) => ({ ...prev, [field]: false }));
    };

    const handleAddClientChange = (field, value) => {
        setAddClientItem((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    /**
     * Cancel => reset form + close
     */
    const handleAddClientCancel = () => {
        setFocusState({});
        setAddClientItem({
            clientName: '',
            caseID: '',
            clientID: '',
            officeIdentifyNumber: null,
            deductionsCase: '',
            taxCreditPoints: '2.25',
            clientPassword: '',
            clientPhoneNumber: '',
            clientLocation: '',
            clientEmail: '',
            clientWebsite: '',
            incomeTaxAdvancePercentage: '',
            incomeTaxAdvanceAmount: '',
            reportingType: '',
            advancedReportingType: '',
            accountManagement: '',
            caseType: '',
            subAccountantId: '',
            taxAssessor: '',
            clientPaymentMethod: 1,
            package: Constants.packageOptionsEnum.Basic,
        });
        onClose && onClose();
    };

    /**
     * On success => close + show success
     */
    const handleSuccessAddClient = () => {
        handleAddClientCancel();
        if (setShowSuccessModal) {
            setTimeout(() => {
                setShowSuccessModal(true);
            }, 500);
        }
    };

    /**
     * handleClientSave => main logic from snippet
     */
    const handleClientSave = async () => {
        if (!addClientEnabled) return;
        setIsLoading(true);

        // Build final data object
        const clientData = {
            caseId: addClientItem.caseID,
            accountantId: Constants.XtramAccountantId, // replaced user.accountantId
            clientId: addClientItem.clientID,
            name: addClientItem.clientName,
            password: addClientItem.clientPassword,
            phoneNumber: addClientItem.clientPhoneNumber,
            caseType: addClientItem.caseType,
            AccountManagement: addClientItem.accountManagement,
            SubAccountantId: addClientItem.subAccountantId,
            IncomeTaxAdvancePercentage: parseFloat(addClientItem.incomeTaxAdvancePercentage),
            IncomeTaxAdvanceAmount: parseFloat(addClientItem.incomeTaxAdvanceAmount),
            UseIncomeTaxAdvancePercentage: useIncomeTaxAdvancePercentage,
            Website: addClientItem.clientWebsite,
            Location: addClientItem.clientLocation,
            Email: addClientItem.clientEmail,
            ReportingType: addClientItem.reportingType,
            deductionsCase: addClientItem.deductionsCase,
            taxAssessor: addClientItem.taxAssessor?.toString(),
            taxCreditPoints: addClientItem.taxCreditPoints,
            clientPaymentMethod: addClientItem.clientPaymentMethod,
            advancedReportingType: addClientItem.advancedReportingType,
            PermissionsClient: addClientItem.package,
        };

        try {
            const response = await UserService.addClientForXtramAccountant(clientData);
            if (response.ok) {
                handleSuccessAddClient();
            } else {
                console.error(response);
                toast.error(
                    t ? t('failedToAddCustomer') : 'Failed to add customer'
                );
                handleAddClientCancel();
            }
        } catch (error) {
            console.error(error);
            toast.error(
                t ? t('clientSaveError') : 'Client save error'
            );
            handleAddClientCancel();
        }

        // Clear data
        setAddClientItem({});
        setIsLoading(false);
    };

    // If not open => don't render anything
    if (!open) return null;

    // Basic styling for MUI textfields
    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'
            }`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        },
    };

    const inputStyle = {
        style: {
            textAlign: direction === 'rtl' ? 'right' : 'left',
            direction: direction === 'rtl' ? 'rtl' : 'ltr',
        },
    };

    /**
     * Prepare react-select custom styles
     */
    const customSelectStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: '48px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: 'none',
            boxShadow: 'none',
            padding: '0 10px',
            textAlign: 'left',
            border: '1px solid transparent',
            boxSizing: 'border-box',
            '&:focus-within': {
                border: '2px solid #304FFF',
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            textAlign: 'left',
            color: 'black',
        }),
        menu: (provided) => ({
            ...provided,
            textAlign: 'left',
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? '#F9AA2A'
                : state.isFocused
                    ? '#FFD700'
                    : null,
            color: 'black',
        }),
    };

    // Convert constants to react-select-friendly arrays
    const reportingTypeOptions = (Constants.ReportingTypeOptions || []).map((opt) => ({
        value: opt.name,
        label: opt.name,
    }));

    const accountManagementOptions = (Constants.AccountManagementOptions || []).map(
        (opt) => ({
            value: opt.name,
            label: opt.name,
        })
    );

    const caseTypeOptions = (Constants.CaseTypeOptions || []).map((opt) => ({
        value: opt.name,
        label: opt.name,
    }));

    const packageOptions = (Constants.packageOptions || []).map((opt) => ({
        value: opt.value,
        label: t(opt.label),
    }));

    return (
        <Portal>
            {/* Full-screen Overlay with Blur */}
            <Overlay onClick={handleAddClientCancel}>
                {/* Prevent click on modal from closing */}
                <div onClick={(e) => e.stopPropagation()} style={{ direction }}>
                    <StyledContainer>
                        {/* Header */}
                        <div
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                marginBottom: '16px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                cursor: 'default', // no drag
                            }}
                        >
                            <Tooltip title={t ? t('Exit') : 'Exit'}>
                                <IconButton onClick={handleAddClientCancel}>
                                    <CancelIcon style={{ color: '#304FFF' }} />
                                </IconButton>
                            </Tooltip>
                            <div>{t ? t('Register') : 'Add Business'}</div>
                            <div />
                        </div>

                        {/* Row 1: caseID, clientID */}
                        <div
                            className="form-row"
                            style={{ marginBottom: 16, display: 'flex', gap: '16px' }}
                        >
                            {/* CaseID */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{ marginBottom: 4 }}
                                >
                                    {t ? t('CaseID') : 'CaseID'} *
                                </div>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    type="number"
                                    value={addClientItem.caseID}
                                    onChange={(e) => handleAddClientChange('caseID', e.target.value)}
                                    onFocus={() => handleFocus('caseID')}
                                    onBlur={() => handleBlur('caseID')}
                                    required
                                    InputProps={inputPropsStyle}
                                    inputProps={{ ...inputStyle.style, inputMode: 'numeric' }}
                                    placeholder={t ? t('CaseID') : 'CaseID'}
                                />
                            </div>

                            {/* ClientID */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{ marginBottom: 4 }}
                                >
                                    {t ? t('ClientId') : 'ClientID'} *
                                </div>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    type="number"
                                    value={addClientItem.clientID}
                                    onChange={(e) => handleAddClientChange('clientID', e.target.value)}
                                    onFocus={() => handleFocus('clientID')}
                                    onBlur={() => handleBlur('clientID')}
                                    required
                                    InputProps={inputPropsStyle}
                                    inputProps={{ ...inputStyle.style, inputMode: 'numeric' }}
                                    placeholder={t ? t('ClientId') : 'ClientId'}
                                />
                            </div>
                        </div>

                        {/* Row 3: clientName, clientPassword */}
                        <div
                            className="form-row"
                            style={{ marginBottom: 16, display: 'flex', gap: '16px' }}
                        >
                            {/* clientName */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{ marginBottom: 4 }}
                                >
                                    {t ? t('enterName') : 'Client Name'} *
                                </div>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    value={addClientItem.clientName}
                                    onChange={(e) => handleAddClientChange('clientName', e.target.value)}
                                    onFocus={() => handleFocus('clientName')}
                                    onBlur={() => handleBlur('clientName')}
                                    required
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle.style}
                                    placeholder={t ? t('enterName') : 'enterName'}
                                />
                            </div>

                            {/* clientPassword */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{ marginBottom: 4 }}
                                >
                                    {t ? t('enterPassword') : 'Client Password'} *
                                </div>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    type="password"
                                    value={addClientItem.clientPassword}
                                    onChange={(e) =>
                                        handleAddClientChange('clientPassword', e.target.value)
                                    }
                                    onFocus={() => handleFocus('clientPassword')}
                                    onBlur={() => handleBlur('clientPassword')}
                                    required
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle.style}
                                    placeholder={t ? t('enterPassword') : 'enterPassword'}
                                />
                            </div>
                        </div>

                        {/* Row 4: clientPhoneNumber, taxCreditPoints */}
                        <div
                            className="form-row"
                            style={{ marginBottom: 16, display: 'flex', gap: '16px' }}
                        >
                            {/* clientPhoneNumber */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{ marginBottom: 4 }}
                                >
                                    {t ? t('enterPhoneNumber') : 'Client Phone Number'} *
                                </div>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    type="number"
                                    value={addClientItem.clientPhoneNumber}
                                    onChange={(e) =>
                                        handleAddClientChange('clientPhoneNumber', e.target.value)
                                    }
                                    onFocus={() => handleFocus('clientPhoneNumber')}
                                    onBlur={() => handleBlur('clientPhoneNumber')}
                                    required
                                    InputProps={inputPropsStyle}
                                    inputProps={{ ...inputStyle.style, inputMode: 'numeric' }}
                                    placeholder={t ? t('enterPhoneNumber') : 'enterPhoneNumber'}
                                />
                            </div>

                            {/* taxCreditPoints */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{ marginBottom: 4 }}
                                >
                                    {t
                                        ? t('IncomeTaxCreditPoints')
                                        : 'Income Tax Credit Points'}
                                </div>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    type="number"
                                    value={addClientItem.taxCreditPoints}
                                    onChange={(e) =>
                                        handleAddClientChange('taxCreditPoints', e.target.value)
                                    }
                                    onFocus={() => handleFocus('taxCreditPoints')}
                                    onBlur={() => handleBlur('taxCreditPoints')}
                                    InputProps={inputPropsStyle}
                                    inputProps={{ ...inputStyle.style, inputMode: 'numeric' }}
                                    placeholder={
                                        t ? t('IncomeTaxCreditPoints') : 'IncomeTaxCreditPoints'
                                    }
                                />
                            </div>
                        </div>

                        {/* Row 5: clientEmail, clientLocation */}
                        <div
                            className="form-row"
                            style={{ marginBottom: 16, display: 'flex', gap: '16px' }}
                        >
                            {/* clientEmail */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{ marginBottom: 4 }}
                                >
                                    {t ? t('Email') : 'Client Email'}
                                </div>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    type="email"
                                    value={addClientItem.clientEmail}
                                    onChange={(e) =>
                                        handleAddClientChange('clientEmail', e.target.value)
                                    }
                                    onFocus={() => handleFocus('clientEmail')}
                                    onBlur={() => handleBlur('clientEmail')}
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle.style}
                                    placeholder={t ? t('Email') : 'Email'}
                                />
                            </div>

                            {/* clientLocation */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{ marginBottom: 4 }}
                                >
                                    {t ? t('Location') : 'Client Location'}
                                </div>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    value={addClientItem.clientLocation}
                                    onChange={(e) =>
                                        handleAddClientChange('clientLocation', e.target.value)
                                    }
                                    onFocus={() => handleFocus('clientLocation')}
                                    onBlur={() => handleBlur('clientLocation')}
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle.style}
                                    placeholder={t ? t('Location') : 'Location'}
                                />
                            </div>
                        </div>

                        {/* Row 6: clientWebsite + taxAssessor */}
                        <div
                            className="form-row"
                            style={{ marginBottom: 16, display: 'flex', gap: '16px' }}
                        >
                            {/* clientWebsite */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{ marginBottom: 4 }}
                                >
                                    {t ? t('Website') : 'Client Website'}
                                </div>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    value={addClientItem.clientWebsite}
                                    onChange={(e) =>
                                        handleAddClientChange('clientWebsite', e.target.value)
                                    }
                                    onFocus={() => handleFocus('clientWebsite')}
                                    onBlur={() => handleBlur('clientWebsite')}
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle.style}
                                    placeholder={t ? t('Website') : 'Website'}
                                />
                            </div>

                            {/* taxAssessor */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{ marginBottom: 4 }}
                                >
                                    {t ? t('TaxAssessor') : 'Tax Assessor'}
                                </div>

                                <Select
                                    className="add-client-input-style"
                                    placeholder={t ? t('TaxAssessor') : 'TaxAssessor'}
                                    options={Constants.TaxAssessorOptions}
                                    isSearchable={true}
                                    onChange={(selectedOption) => {
                                        handleAddClientChange('taxAssessor', selectedOption.value);
                                    }}
                                    value={Constants.TaxAssessorOptions.find(
                                        (option) => option.value === addClientItem.taxAssessor
                                    )}
                                    styles={customSelectStyles}
                                />
                            </div>
                        </div>

                        {/* Row 7: toggle + IncomeTaxAdvancePercentage or Amount */}
                        <div
                            className="form-row"
                            style={{ marginBottom: 16, display: 'flex', gap: '16px' }}
                        >
                            {/* UseIncomeTaxAdvancePercentage checkbox */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{ marginBottom: 4 }}
                                >
                                    {t
                                        ? t('UseIncomeTaxAdvancePercentage')
                                        : 'Use Income Tax Advance Percentage'}
                                </div>
                                <FormControlLabel
                                    style={{ marginTop: '-4px' }}
                                    control={
                                        <Checkbox
                                            checked={useIncomeTaxAdvancePercentage}
                                            onChange={() =>
                                                setUseIncomeTaxAdvancePercentage(
                                                    !useIncomeTaxAdvancePercentage
                                                )
                                            }
                                            color="primary"
                                        />
                                    }
                                    label=""
                                />
                            </div>

                            {/* If true => show IncomeTaxAdvancePercentage, else IncomeTaxAdvanceAmount */}
                            {useIncomeTaxAdvancePercentage ? (
                                <div className="edit-form-row-item" style={{ flex: 1 }}>
                                    <div
                                        className="edit-form-row-item-title"
                                        style={{ marginBottom: 4 }}
                                    >
                                        {t
                                            ? t('IncomeTaxAdvancePercentage')
                                            : 'Income Tax Advance %'}
                                    </div>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        type="number"
                                        value={addClientItem.incomeTaxAdvancePercentage}
                                        onChange={(e) =>
                                            handleAddClientChange(
                                                'incomeTaxAdvancePercentage',
                                                e.target.value
                                            )
                                        }
                                        onFocus={() => handleFocus('incomeTaxAdvancePercentage')}
                                        onBlur={() => handleBlur('incomeTaxAdvancePercentage')}
                                        InputProps={inputPropsStyle}
                                        inputProps={{ ...inputStyle.style, inputMode: 'numeric' }}
                                        placeholder={
                                            t
                                                ? t('IncomeTaxAdvancePercentage')
                                                : 'IncomeTaxAdvancePercentage'
                                        }
                                    />
                                </div>
                            ) : (
                                <div className="edit-form-row-item" style={{ flex: 1 }}>
                                    <div
                                        className="edit-form-row-item-title"
                                        style={{ marginBottom: 4 }}
                                    >
                                        {t
                                            ? t('IncomeTaxAdvanceAmount')
                                            : 'Income Tax Advance Amount'}
                                    </div>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        type="number"
                                        value={addClientItem.incomeTaxAdvanceAmount}
                                        onChange={(e) =>
                                            handleAddClientChange(
                                                'incomeTaxAdvanceAmount',
                                                e.target.value
                                            )
                                        }
                                        onFocus={() => handleFocus('incomeTaxAdvanceAmount')}
                                        onBlur={() => handleBlur('incomeTaxAdvanceAmount')}
                                        InputProps={inputPropsStyle}
                                        inputProps={{ ...inputStyle.style, inputMode: 'numeric' }}
                                        placeholder={
                                            t
                                                ? t('IncomeTaxAdvanceAmount')
                                                : 'IncomeTaxAdvanceAmount'
                                        }
                                    />
                                </div>
                            )}
                        </div>

                        {/* Row 8: reportingType, advancedReportingType */}
                        <div
                            className="form-row"
                            style={{ marginBottom: 16, display: 'flex', gap: '16px' }}
                        >
                            {/* reportingType - replaced with react-select */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{ marginBottom: 4 }}
                                >
                                    {t
                                        ? t('SelectClientReportType')
                                        : 'Client Reporting Type'} *
                                </div>
                                <Select
                                    styles={customSelectStyles}
                                    placeholder={
                                        t
                                            ? t('SelectClientReportType')
                                            : 'Select a report type...'
                                    }
                                    options={reportingTypeOptions}
                                    value={reportingTypeOptions.find(
                                        (o) => o.value === addClientItem.reportingType
                                    )}
                                    onChange={(selectedOption) =>
                                        handleAddClientChange('reportingType', selectedOption.value)
                                    }
                                    onFocus={() => setIsReportFocus(true)}
                                    onBlur={() => setIsReportFocus(false)}
                                />
                            </div>

                            {/* advancedReportingType - replaced with react-select */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{ marginBottom: 4 }}
                                >
                                    {t
                                        ? t('AdvanceIncomeTaxReportType')
                                        : 'Advance Income Tax Report Type'} *
                                </div>
                                <Select
                                    styles={customSelectStyles}
                                    placeholder={t ? t('AdvanceIncomeTaxReportType') : 'Choose...'}
                                    options={reportingTypeOptions}
                                    value={reportingTypeOptions.find(
                                        (o) => o.value === addClientItem.advancedReportingType
                                    )}
                                    onChange={(selectedOption) =>
                                        handleAddClientChange(
                                            'advancedReportingType',
                                            selectedOption.value
                                        )
                                    }
                                    onFocus={() => setIsAdvancedReportFocus(true)}
                                    onBlur={() => setIsAdvancedReportFocus(false)}
                                />
                            </div>
                        </div>

                        {/* Row 9: accountManagement, caseType */}
                        <div
                            className="form-row"
                            style={{ marginBottom: 16, display: 'flex', gap: '16px' }}
                        >
                            {/* accountManagement - replaced with react-select */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{ marginBottom: 4 }}
                                >
                                    {t ? t('AccountManagement') : 'Account Management'} *
                                </div>
                                <Select
                                    styles={customSelectStyles}
                                    placeholder={
                                        t ? t('SelectAccountManagement') : 'Choose manager...'
                                    }
                                    options={accountManagementOptions}
                                    value={accountManagementOptions.find(
                                        (o) => o.value === addClientItem.accountManagement
                                    )}
                                    onChange={(selectedOption) =>
                                        handleAddClientChange(
                                            'accountManagement',
                                            selectedOption.value
                                        )
                                    }
                                    onFocus={() => setIsAccountManagementFocus(true)}
                                    onBlur={() => setIsAccountManagementFocus(false)}
                                />
                            </div>

                            {/* caseType - replaced with react-select */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{ marginBottom: 4 }}
                                >
                                    {t ? t('CaseType') : 'Case Type'} *
                                </div>
                                <Select
                                    styles={customSelectStyles}
                                    placeholder={t ? t('CaseType') : 'Choose a case type...'}
                                    options={caseTypeOptions}
                                    value={caseTypeOptions.find(
                                        (o) => o.value === addClientItem.caseType
                                    )}
                                    onChange={(selectedOption) =>
                                        handleAddClientChange('caseType', selectedOption.value)
                                    }
                                    onFocus={() => setIsCaseTypeFocus(true)}
                                    onBlur={() => setIsCaseTypeFocus(false)}
                                />
                            </div>
                        </div>

                        {/* Row 10: package */}
                        <div
                            className="form-row"
                            style={{ marginBottom: 16, display: 'flex', gap: '16px' }}
                        >
                            {/* package - replaced with react-select */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{ marginBottom: 4 }}
                                >
                                    {t ? t('Package') : 'Package'} *
                                </div>
                                <Select
                                    styles={customSelectStyles}
                                    placeholder={t ? t('Package') : 'Choose package...'}
                                    options={packageOptions}
                                    value={packageOptions.find(
                                        (o) => o.value === addClientItem.package
                                    )}
                                    onChange={(selectedOption) =>
                                        handleAddClientChange('package', selectedOption.value)
                                    }
                                    onFocus={() => setIsPackageFocus(true)}
                                    onBlur={() => setIsPackageFocus(false)}
                                />
                            </div>
                        </div>

                        {/* FOOTER ACTIONS (Cancel + Save) */}
                        {isLoading ? (
                            <CircularProgress
                                size={24}
                                style={{ color: 'white', alignSelf: 'center' }}
                            />
                        ) : (
                            <div style={{ display: 'flex', gap: '10px', marginTop: '16px' }}>
                                {/* Cancel */}
                                <div
                                    onClick={handleAddClientCancel}
                                    style={{
                                        flex: 1,
                                        minWidth: '100px',
                                        cursor: 'pointer',
                                        backgroundColor: '#EEE',
                                        padding: '12px 20px',
                                        borderRadius: '8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontWeight: '500',
                                    }}
                                >
                                    {t ? t('Cancel') : 'Cancel'}
                                </div>

                                {/* Save */}
                                <div
                                    onClick={handleClientSave}
                                    style={{
                                        flex: 1,
                                        minWidth: '100px',
                                        cursor: addClientEnabled ? 'pointer' : 'not-allowed',
                                        backgroundColor: addClientEnabled ? '#304FFF' : '#AAA',
                                        color: '#FFF',
                                        padding: '12px 20px',
                                        borderRadius: '8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontWeight: '500',
                                    }}
                                >
                                    {t ? t('Save') : 'Save'}
                                </div>
                            </div>
                        )}
                    </StyledContainer>
                </div>
            </Overlay>
        </Portal>
    );
}

export default ClientRegisterModal;
