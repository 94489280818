import config from '../config';
import selectedEnvironment from '../env';
import { formatDate_MM_YYYY } from '../Utils/FormatDate';
const API_Clients_URL = `${config[selectedEnvironment].API_URL}/Clients`;
const API_ReportedTaxes_URL = `${config[selectedEnvironment].API_URL}/ReportedTaxes`;
// const API_Clients_URL = 'https://accounting-manager.com:5163/api/Clients';
// const API_ReportedTaxes_URL = 'https://accounting-manager.com:5163/api/ReportedTaxes';

const GetClients = async (accountantId, token, page, pageSize, isActive = true) => {
    try {
        const response = await fetch(`${API_Clients_URL}/GetClientsByAccountantId`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ accountantId, page, pageSize, isActive })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
        // return response.body;
    } catch (error) {
    }
};

const GetMinimalClients = async (accountantId, token, page, pageSize, isActive = true) => {
    try {
        const response = await fetch(`${API_Clients_URL}/GetMinimalClientsByAccountantId`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ accountantId, page, pageSize, isActive })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
        // return response.body;
    } catch (error) {
    }
};

// Function to download and handle a report file
const DownloadReport = async (CaseId, date, type, auth) => {
    try {
        // Make the HTTP request to get the file data
        const response = await fetch(`${API_Clients_URL}/Download${type}Report`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + auth,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ CaseId, date })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Get the file content from the response
        const fileContent = await response.blob();
        // Create a URL for the file
        const fileUrl = window.URL.createObjectURL(fileContent);

        // Create a temporary link and trigger the download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'report.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (err) {
        alert("Error downloading the report. Please try again.");
    }
};

const DownloadDATReport = async (CaseId, date, type, auth) => {
    try {
        // Make the HTTP request to get the file data
        const response = await fetch(`${API_Clients_URL}/Download${type}Report`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + auth,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ CaseId, date })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Get the file content from the response
        const fileContent = await response.blob();

        // Create a URL for the file
        const fileUrl = window.URL.createObjectURL(fileContent);

        // Create a temporary link and trigger the download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'MOVEIN.DAT'); // Set the correct file name here
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (err) {
        console.error("Error downloading the report: ", err);
        alert("Error downloading the report. Please try again.");
    }
};

const DownloadPCNReport = async (CaseId, date, endDate, auth) => {
    try {
        // Make the HTTP request to get the file data
        const response = await fetch(`${API_Clients_URL}/DownloadPCNReport`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + auth,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ CaseId, date, endDate })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Get the file content from the response
        const fileContent = await response.blob();

        // Create a URL for the file
        const fileUrl = window.URL.createObjectURL(fileContent);

        // Create a temporary link and trigger the download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'PCN874.txt'); // Set the correct file name here
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (err) {
        console.error("Error downloading the report: ", err);
        alert("Error downloading the report. Please try again.");
    }
};


const DownloadPCNPDF = async (CaseId, date, endDate, auth) => {
    try {
        // Make the HTTP request to get the file data
        const response = await fetch(`${API_Clients_URL}/DownloadPCNReportPDF`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + auth,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ CaseId, date, endDate })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Get the file content from the response
        const fileContent = await response.blob();

        // Create a URL for the file
        const fileUrl = window.URL.createObjectURL(fileContent);

        // Create a temporary link and trigger the download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'PCN874.pdf'); // Set the correct file name here
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (err) {
        console.error("Error downloading the report: ", err);
        alert("Error downloading the report. Please try again.");
    }
};



const UpdateClient = async (token, client) => {
    try {
        const response = await fetch(`${API_Clients_URL}/Update`, {
            method: 'PUT',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...client })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response;
    } catch (error) {
    }
};

const GetClient = async (token, caseId) => {
    try {
        const response = await fetch(`${API_Clients_URL}/${caseId}`, {
            method: 'GET',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
    }
};

const DeleteClient = async (token, caseId) => {
    try {
        const response = await fetch(`${API_Clients_URL}/Delete/${caseId}`, {
            method: 'DELETE',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response;
    } catch (error) {
    }
};

const GetClientCounts = async (token, accountantId) => {
    try {
        const response = await fetch(`${API_Clients_URL}/GetSumClientsByAccountantId/${accountantId}`, {
            method: 'GET',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
    }
};
const UpdateReportingMonth = async (CaseId, ReportingMonthDate, IsClosed, auth) => {
    const response = await fetch(`${API_Clients_URL}/UpdateReportingMonth`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, ReportingMonthDate, IsClosed })
    });

    return response;
};

const CheckIfMonthClosed = async (CaseId, ReportingMonthDate, auth) => {
    const response = await fetch(`${API_Clients_URL}/CheckMonthClosed`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, ReportingMonthDate })
    });

    return response;
};

const GetIncomeTaxAdvances = async (CaseId, date, checkType, auth) => {
    const response = await fetch(`${API_Clients_URL}/GetIncomeTaxAdvances`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, date, checkType })
    });

    return response;
};

const GetIncomeTaxAdvancesByDateRange = async (CaseId, date, endDate, checkType, auth) => {
    const response = await fetch(`${API_Clients_URL}/GetIncomeTaxAdvancesDateRange`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + auth,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ CaseId, date, endDate, checkType })
    });

    return response;
};

const GetClientUploads = async (token, caseId) => {

    try {
        const response = await fetch(`${API_Clients_URL}/GetTotalUploadsByCaseId/${caseId}`, {
            method: 'GET',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
        });

        
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
    }

};
const ProfitAndLossDetails = async (caseId, accountantId, date, endDate, token) => {
    try {
        const response = await fetch(`${API_Clients_URL}/ProfitAndLossDetails`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ caseId, accountantId, date, endDate })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response;
    } catch (error) {
    }
};

const ProfitAndLossDetailsYearly = async (caseId, accountantId, date, endDate, token) => {
    try {
        const response = await fetch(`${API_Clients_URL}/ProfitAndLossDetailsByYear`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ caseId, accountantId, date, endDate })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response;
    } catch (error) {
    }
};


const ProfitAndLossDetailsYearlyReport = async (caseId, accountantId, date, endDate, token) => {

    const response = await fetch(`${API_Clients_URL}/ProfitAndLossDetailsByYearReport`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ caseId, accountantId, date, endDate })
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Get the file content from the response
    const fileContent = await response.blob();

    // Create a URL for the file
    const fileUrl = window.URL.createObjectURL(fileContent);

    // Create a temporary link and trigger the download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', `${caseId}_${formatDate_MM_YYYY(date)}-${formatDate_MM_YYYY(endDate)}_דווח_רווח_הפסד.pdf`); // Set the correct file name here
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

};


const ProfitAndLossDetailsMonthlyReport = async (caseId, accountantId, date, endDate, token) => {
    const response = await fetch(`${API_Clients_URL}/ProfitAndLossDetailsByMonthlyReport`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ caseId, accountantId, date, endDate })
    });


    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Get the file content from the response
    const fileContent = await response.blob();

    // Create a URL for the file
    const fileUrl = window.URL.createObjectURL(fileContent);

    // Create a temporary link and trigger the download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', `${caseId}_${formatDate_MM_YYYY(date)}-${formatDate_MM_YYYY(endDate)}_דווח_רווח_הפסד.pdf`); // Set the correct file name here
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
};



const SortCodeDetails = async (caseId, accountantId, date, endDate, token, sortCodeId) => {
    try {
        const response = await fetch(`${API_Clients_URL}/SortCodeDetails`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ caseId, accountantId, date, endDate, sortCodeId })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response;
    } catch (error) {
    }
};

const ReportedTaxesDetails = async (caseId, startDate, endDate, isRange, token) => {
    try {
        const response = await fetch(`${API_ReportedTaxes_URL}/ByDateRange?caseId=${caseId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&isRange=${isRange}`, {
            method: 'GET',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
        });
        
        
        if (!response.ok) {

            throw new Error(`HTTP error! status: ${response.status}`);

        }
        
        const data = await response.json();
      
        
        return data;
    } catch (error) {
        console.error('Failed to fetch reported taxes details:', error);
        return null;
    }
};

const UpdateReportedTax = async (reportedTax, token) => {

    const { id, ...taxData } = reportedTax;

    const response = await fetch(`${API_ReportedTaxes_URL}/Update/${reportedTax.id}`, {
        method: 'PUT',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(taxData)
    });

    return response;
};


const CreateReportedTax = async (caseId, reportedTax, startDate, endDate, token) => {
    reportedTax.caseId = caseId;
    reportedTax.startDate = startDate;
    reportedTax.endDate = endDate;

    const response = await fetch(`${API_ReportedTaxes_URL}/Create`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reportedTax)
    });

    return response;
};




const SortCodeDetailsReport = async (caseId, accountantId, date, endDate, sortCodeId, token) => {

    const response = await fetch(`${API_Clients_URL}/SortCodeDetailsReport`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ caseId, date, endDate, accountantId, sortCodeId })
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Get the file content from the response
    const fileContent = await response.blob();

    // Create a URL for the file
    const fileUrl = window.URL.createObjectURL(fileContent);

    // Create a temporary link and trigger the download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'SortCodeREPORT.pdf'); // Set the correct file name here
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

};

const DownloadJournalEntryReport = async (CaseId, date, endDate, type, auth) => {
    try {
        // Make the HTTP request to get the file data
        const response = await fetch(`${API_Clients_URL}/DownloadJournalEntryReport`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + auth,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ CaseId, date, endDate, type })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Extract the Content-Disposition header
        const contentDisposition = response.headers.get('Content-Disposition');
        // Get the file content from the response
        const fileContent = await response.blob();
        // Create a URL for the file
        const fileUrl = window.URL.createObjectURL(fileContent);
        const link = document.createElement('a');
        // Create a temporary link and trigger the download
        link.href = fileUrl;
        // Regular expression to extract the filename
        // const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        // const matches = filenameRegex.exec(contentDisposition);
        // let filename = 'report.xlsx'; // Default filename

        // if (matches != null && matches[1]) {
        //     filename = matches[1].replace(/['"]/g, ''); // Remove quotes
        // }

        // // Get the file content from the response
        // const fileContent = await response.blob();

        // // Create a URL for the file
        // const fileUrl = window.URL.createObjectURL(fileContent);

        // // Create a temporary link and trigger the download
        // const link = document.createElement('a');
        // link.href = fileUrl;
        // link.setAttribute('download', 'פקודת יומן.txt');
        // document.body.appendChild(link);
        // link.click();
        // link.parentNode.removeChild(link);

        link.setAttribute('download', 'report.txt');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (err) {
        console.error(err);
        alert("Error downloading the report. Please try again.");
    }
};

const DownloadJournalEntryDatReport = async (CaseId, date, endDate, type, auth) => {
    try {

        // Make the HTTP request to get the file data
        const response = await fetch(`${API_Clients_URL}/DownloadJournalEntryDatReport`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + auth,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ CaseId, date, endDate, type })
        });



        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        // Extract the Content-Disposition header
        const contentDisposition = response.headers.get('Content-Disposition');

        // Regular expression to extract the filename
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        let filename = 'report.xlsx'; // Default filename

        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, ''); // Remove quotes
        }

        // Get the file content from the response
        const fileContent = await response.blob();

        // Create a URL for the file
        const fileUrl = window.URL.createObjectURL(fileContent);

        // Create a temporary link and trigger the download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (err) {
        console.error(err);
        alert("Error downloading the report. Please try again.");
    }
};

const DownloadAccountantCardReport = async (CaseId, date, type, auth) => {
    try {
        // Make the HTTP request to get the file data
        const response = await fetch(`${API_Clients_URL}/DownloadAccountantCardReport`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + auth,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ CaseId, date })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const fileContent = await response.blob();

        const fileUrl = window.URL.createObjectURL(fileContent);

        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'כרטיסי חשבון.txt');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (err) {
        console.error(err);
        alert("Error downloading the report. Please try again.");
    }
};


const DownloadAccountantCardReportDat = async (CaseId, date, type, auth) => {
    try {
        // Make the HTTP request to get the file data
        const response = await fetch(`${API_Clients_URL}/DownloadAccountantCardReportDat`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + auth,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ CaseId, date })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        // Extract the Content-Disposition header
        const contentDisposition = response.headers.get('Content-Disposition');


        // Regular expression to extract the filename
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        let filename = 'report.xlsx'; // Default filename

        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, ''); // Remove quotes
        }

        // Get the file content from the response
        const fileContent = await response.blob();

        // Create a URL for the file
        const fileUrl = window.URL.createObjectURL(fileContent);

        // Create a temporary link and trigger the download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (err) {
        console.error(err);
        alert("Error downloading the report. Please try again.");
    }
};

const VATReport = async (caseId, accountantId, Date, EndDate, token) => {

    const response = await fetch(`${API_Clients_URL}/VATReport`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ caseId, accountantId, Date, EndDate })
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Get the file content from the response
    const fileContent = await response.blob();

    // Create a URL for the file
    const fileUrl = window.URL.createObjectURL(fileContent);

    // Create a temporary link and trigger the download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'VATReport.pdf'); // Set the correct file name here
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

};


const WithholdingTaxReport = async (caseId, accountantId, Date, EndDate, token) => {

    const response = await fetch(`${API_Clients_URL}/WithholdingTaxReport`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ caseId, accountantId, Date, EndDate })
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Get the file content from the response
    const fileContent = await response.blob();

    // Create a URL for the file
    const fileUrl = window.URL.createObjectURL(fileContent);

    // Create a temporary link and trigger the download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'WithholdingTaxReport.pdf'); // Set the correct file name here
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

};

const WithholdingTaxReportTracking = async (accountantId, StartDate, EndDate, isRange, token) => {

    const response = await fetch(`${API_ReportedTaxes_URL}/DetailsByDateRange`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ accountantId, StartDate, EndDate, isRange })
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response;

};

const sendVatReport = async (reportedTax, token) => {

    const response = await fetch(`${API_ReportedTaxes_URL}/SendVatReporting`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reportedTax)
    });

    return response;
};

const sendAdvancesReport = async (reportedTax, token) => {

    const response = await fetch(`${API_ReportedTaxes_URL}/SendAdvancesReport`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reportedTax)
    });

    return response;
};

const GetClientsBySubAccountantId = async (accountantId, subAccountantId,token, page, pageSize, isActive = true) => {
    // Build query parameters
  const response = await fetch(`${API_Clients_URL}/GetClientsBySubAccountantId`, {
    method: 'POST',
    headers: {
      "Authorization": 'Bearer ' + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ accountantId, subAccountantId, page, pageSize, isActive })
  });
  const data = await response.json();
  return data;
};

const GetMinimalClientsBySubAccountantId = async (accountantId, subAccountantId,token, page, pageSize, isActive = true) => {
    // Build query parameters
  const response = await fetch(`${API_Clients_URL}/GetMinimalClientsBySubAccountantId`, {
    method: 'POST',
    headers: {
      "Authorization": 'Bearer ' + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ accountantId, subAccountantId, page, pageSize, isActive })
  });
  const data = await response.json();
  return data;
};


const CreateConstantInvoiceDetails = async (dto, token) => {
    try {
        const response = await fetch(`${API_Clients_URL}/CreateConstantInvoiceDetails`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(dto)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error creating Constant Invoice Details:', error);
        throw error;
    }
};

const GetConstantInvoiceDetailsByCaseId = async (caseId, token) => {
    try {
        
        const response = await fetch(`${API_Clients_URL}/GetConstantInvoiceDetailsByCaseId?CaseId=${caseId}`, { // Ensure 'CaseId' matches the backend DTO
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

       
        return response;
    } catch (error) {
        console.error('Error getting Constant Invoice Details by CaseId:', error);
        throw error;
    }
};


// 1) UploadProfilePhoto
const UploadProfilePhoto = async (token, accountantId, caseId, photoFile) => {
    // Prepare a FormData object
    const formData = new FormData();
    formData.append('AccountantId', accountantId);
    formData.append('CaseId', caseId);
    formData.append('Photo', photoFile); // The actual file

    try {
        const response = await fetch(`${API_Clients_URL}/UploadProfilePhoto`, {
            method: 'PUT',
            headers: {
                "Authorization": `Bearer ${token}`
                // Note: Do NOT set "Content-Type" here; 
                // fetch will automatically set the correct boundary for FormData
            },
            body: formData
        });

        // The server returns JSON with { message, url }
        return response; 
    } catch (error) {
        console.error("Error uploading profile photo:", error);
        throw error;
    }
};

// 2) UploadSignature
const UploadSignature = async (token, accountantId, caseId, signatureFile) => {
    // Prepare a FormData object
    const formData = new FormData();
    formData.append('AccountantId', accountantId);
    formData.append('CaseId', caseId);
    formData.append('Photo', signatureFile); // The actual file

    try {
        const response = await fetch(`${API_Clients_URL}/UploadSignature`, {
            method: 'PUT',
            headers: {
                "Authorization": `Bearer ${token}`
            },
            body: formData
        });


        // The server returns JSON with { message, url }
        return response;
    } catch (error) {
        console.error("Error uploading signature:", error);
        throw error;
    }
};

// In your ClientService file:

const DownloadUniformFile = async (dto, token) => {
    const response = await fetch(`${API_Clients_URL}/DownloadUniformFile`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(dto)
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Get the file content as a blob
    const blob = await response.blob();

    // Force a file download in the browser
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `UniformFile_${dto.caseId}.zip`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
};


const ClientService = {
    GetClients,
    DownloadReport,
    UpdateClient,
    GetClient,
    DeleteClient,
    DownloadDATReport,
    GetClientCounts,
    UpdateReportingMonth,
    CheckIfMonthClosed,
    GetIncomeTaxAdvances,
    GetClientUploads,
    ProfitAndLossDetails,
    ProfitAndLossDetailsYearly,
    ProfitAndLossDetailsYearlyReport,
    ProfitAndLossDetailsMonthlyReport,
    GetIncomeTaxAdvancesByDateRange,
    SortCodeDetails,
    SortCodeDetailsReport,
    DownloadJournalEntryReport,
    DownloadJournalEntryDatReport,
    DownloadAccountantCardReport,
    DownloadAccountantCardReportDat,
    ReportedTaxesDetails,
    UpdateReportedTax,
    CreateReportedTax,
    VATReport,
    WithholdingTaxReport,
    DownloadPCNReport,
    DownloadPCNPDF,
    WithholdingTaxReportTracking,
    sendVatReport,
    sendAdvancesReport,
    GetClientsBySubAccountantId,
    GetMinimalClients,
    GetMinimalClientsBySubAccountantId,
    CreateConstantInvoiceDetails,
    GetConstantInvoiceDetailsByCaseId,
    UploadProfilePhoto,
    UploadSignature,
    DownloadUniformFile
};

export default ClientService;