import React, {
    useState,
    useMemo,
    useEffect,
    useRef,
    useCallback
} from 'react';
import Draggable from 'react-draggable';
import ReactDOM from 'react-dom';

// Example placeholders for filtering & chat
import ChatInputBar from '../SubComponents/ChatInputBar';
import FilterOptionMenu from '../../Widgets/FilterOptionMenu/FilterOptionMenu';
// For icons, styling, etc.
import { FiX } from 'react-icons/fi';
import './ManageLabelUsersModal.css'; // optional, see example below
import { createPortal } from 'react-dom';
import Constants from '../../../assests/Constants/constants';
import { useTranslation } from 'react-i18next';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import SelectableUserAvatar from '../../Widgets/UserAvatar/SelectableUserAvatar';
import RemoveIcon from '@mui/icons-material/Remove'; // Minus icon
import AddIcon from '@mui/icons-material/Add'; // Plus icon
import LabelService from '../../../Services/labels.service';
import { toast } from 'react-toastify';
import ChatApiService from '../../../Services/chat.service';
import AuthService from '../../../Services/auth.service';
import { v4 as uuid } from 'uuid';
import moment from 'moment-timezone';
import ScheduledItem from './ScheduledItem';
import ScheduledMessagesService from '../../../Services/ScheduledMessages.service';
import ScheduledFormModal from './ScheduledFormModal';
import CustomDeleteConfirmationModal from '../../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal';
/**
 * Example usage:
 *
 * <ManageLabelUsersModal
 *   visible={showManageLabelModal}
 *   onClose={handleCloseManageLabelModal}
 *   onReopen={() => console.log("Reopened!")}
 *   label={selectedLabel}  // { labelId, labelName, users: [...] }
 *   userId="currentUserId"
 *   userType={1}
 *   allowedUsers={allAllowedUsers}
 * />
 */
const ManageLabelUsersModal = ({
    visible,
    onClose,
    onReopen,
    label,            // { labelId, labelName, users: [{ userId, userType, userName }, ...] }
    userId,
    userType,
    allowedUsers = [],
    setLoading,
    loading,
    onUsersUpdated
}) => {
    const [viewMode, setViewMode] = useState('existing');
    // 'existing' | 'nonExisting' | 'scheduled'
    const { i18n, t } = useTranslation();
    const direction = i18n.dir();
    const [scheduledMessages, setScheduledMessages] = useState([]);
    const [selectedScheduledMessage, setSelectedScheduledMessage] = useState(null);
    const [showScheduledModal, setShowScheduledModal] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [isAddSchedule, setIsAddSchedule] = useState(false); // <--- NEW

    const [recorder, setRecorder] = useState(null);
    const audioChunksRef = useRef([]);
    // Searching & filtering
    const [searchQuery, setSearchQuery] = useState('');
    const [listSearchedText, setListSearchedText] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);

    // “Select All” toggling
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [selectedCount, setSelectedCount] = useState(0);
    // Add/Remove sets
    const [selectedToAdd, setSelectedToAdd] = useState(new Set());
    const [selectedToRemove, setSelectedToRemove] = useState(new Set());
    const [newLabelName, setNewLabelName] = useState(label?.labelName || '');

    // Draggable + Portal
    const [container] = useState(() => document.createElement('div'));
    const draggableNodeRef = useRef(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    // Example filter groups
    const optionGroups = [
        {
            title: t('Status'),
            selectionType: 'single',
            options: [
                { id: 'read', label: t('Read') },
                { id: 'unread', label: t('Unread') },
                { id: 'all', label: t('All') },
            ],
        },
        {
            title: t('UserType'),
            selectionType: 'multi',
            options: [
                { id: 'business', label: t('Business') },
                { id: 'office', label: t('Office') },
            ],
        },
        {
            title: t('CaseType'),
            selectionType: 'multi',
            options: Constants.CaseTypeOptions.map((option) => ({
                id: option.value,
                label: option.name,
            })),
        },
        {
            title: t('AccountManagement'),
            selectionType: 'multi',
            options: Constants.AccountManagementOptions.map((option) => ({
                id: option.value,
                label: option.name,
            })),
        },
        {
            title: t('ReportingType'),
            selectionType: 'multi',
            options: Constants.ReportingTypeOptions.map((option) => ({
                id: option.value,
                label: option.name,
            })),
        },
    ];



    // Existing vs Non-Existing
    const labelUserIds = useMemo(() => (label?.users || []).map((u) => u.userId), [label]);

    // 1) Create a Set of "id-type" combos from the label
    const labelUserCombos = useMemo(() => {
        // Each label user becomes "userId-userType"
        return new Set((label?.users || []).map((u) => `${u.userId}-${u.userType}`));
    }, [label]);

    // 2) Filter existingUsers by checking if "id-type" combo is in that Set
    const existingUsers = useMemo(() => {
        return allowedUsers.filter((u) =>
            labelUserCombos.has(`${u.userId}-${u.userType}`)
        );
    }, [allowedUsers, labelUserCombos]);

    // 3) (Optional) Filter nonExistingUsers the same way
    const nonExistingUsers = useMemo(() => {
        return allowedUsers.filter((u) =>
            !labelUserCombos.has(`${u.userId}-${u.userType}`)
        );
    }, [allowedUsers, labelUserCombos]);


    // Debounce search
    useEffect(() => {
        const delay = setTimeout(() => {
            setListSearchedText(searchQuery.trim().toLowerCase());
        }, 300);
        return () => clearTimeout(delay);
    }, [searchQuery]);

    // Filter logic
    // const filteredUsers = useMemo(() => {
    //     const baseArray = viewMode === 'existing' ? existingUsers : nonExistingUsers;
    //     let filtered = baseArray;

    //     // Search
    //     if (listSearchedText) {
    //         filtered = filtered.filter((user) => {
    //             const nameMatch = user.userName?.toLowerCase()?.includes(listSearchedText);
    //             const idMatch = user.userId?.toLowerCase()?.includes(listSearchedText);
    //             const unreadMatch = user.unreadMessagesCount?.toString().includes(listSearchedText);
    //             return nameMatch || idMatch || unreadMatch;
    //         });
    //     }

    //     // Single "read/unread" filter
    //     if (selectedOptions.includes('read')) {
    //         filtered = filtered.filter((u) => u.unreadMessagesCount === 0);
    //     } else if (selectedOptions.includes('unread')) {
    //         filtered = filtered.filter((u) => (u.unreadMessagesCount || 0) > 0);
    //     }

    //     // "business" or "office"
    //     if (selectedOptions.includes('business')) {
    //         filtered = filtered.filter((u) => u.userType === 1);
    //     }
    //     if (selectedOptions.includes('office')) {
    //         filtered = filtered.filter((u) => u.userType !== 1);
    //     }

    //     // CaseType
    //     const selectedCaseTypes = selectedOptions.filter((o) =>
    //         Constants.CaseTypeOptions.some((opt) => opt.value === o)
    //     );
    //     if (selectedCaseTypes.length > 0) {
    //         filtered = filtered.filter((u) => selectedCaseTypes.includes(u.caseType));
    //     }

    //     // AccountManagement
    //     const selectedAMs = selectedOptions.filter((o) =>
    //         Constants.AccountManagementOptions.some((opt) => opt.value === o)
    //     );
    //     if (selectedAMs.length > 0) {
    //         filtered = filtered.filter((u) => selectedAMs.includes(u.accountManagement));
    //     }

    //     // ReportingType
    //     const selectedReportings = selectedOptions.filter((o) =>
    //         Constants.ReportingTypeOptions.some((opt) => opt.value === o)
    //     );
    //     if (selectedReportings.length > 0) {
    //         filtered = filtered.filter((u) => selectedReportings.includes(u.reportingType));
    //     }

    //     return filtered;
    // }, [
    //     existingUsers,
    //     nonExistingUsers,
    //     listSearchedText,
    //     selectedOptions,
    //     viewMode,
    // ]);

    const filteredUsers = useMemo(() => {
        const baseArray = viewMode === 'existing' ? existingUsers : nonExistingUsers;
        let filtered = baseArray;

        const trimmedSearchQuery = listSearchedText?.trim()?.toLowerCase();

        if (trimmedSearchQuery) {
            filtered = filtered.filter(user => {
                const nameMatch = user?.userName?.toLowerCase()?.includes(trimmedSearchQuery);
                const idMatch = user?.userId?.toLowerCase()?.includes(trimmedSearchQuery);
                const unreadMatch = user?.unreadMessagesCount?.toString().includes(trimmedSearchQuery);
                return nameMatch || idMatch || unreadMatch;
            });
        }

        if (selectedOptions.includes('read')) {
            filtered = filtered.filter(user => user?.unreadMessagesCount === 0);
        } else if (selectedOptions.includes('unread')) {
            filtered = filtered.filter(user => user?.unreadMessagesCount > 0);
        }

        if (selectedOptions.includes('business')) {
            filtered = filtered.filter(user => user.userType === 1);
        }

        if (selectedOptions.includes('office')) {
            filtered = filtered.filter(user => user.userType !== 1);
        }

        // Filter by multiple selected CaseTypeOptions
        const selectedCaseTypes = selectedOptions.filter(option =>
            Constants.CaseTypeOptions.some(o => o.value === option)
        );
        if (selectedCaseTypes.length > 0) {
            filtered = filtered.filter(user =>
                selectedCaseTypes.includes(user.caseType)
            );
        }

        // Filter by multiple selected AccountManagementOptions
        const selectedAccountManagements = selectedOptions.filter(option =>
            Constants.AccountManagementOptions.some(o => o.value === option)
        );
        if (selectedAccountManagements.length > 0) {
            filtered = filtered.filter(user =>
                selectedAccountManagements.includes(user.accountManagement)
            );
        }

        // Filter by multiple selected ReportingTypeOptions
        const selectedReportingTypes = selectedOptions.filter(option =>
            Constants.ReportingTypeOptions.some(o => o.value === option)
        );
        if (selectedReportingTypes.length > 0) {
            filtered = filtered.filter(user =>
                selectedReportingTypes.includes(user.reportingType)
            );
        }

        return filtered;
    }, [allowedUsers, listSearchedText, selectedOptions, viewMode]);


    const toggleUser = useCallback(
        (u) => {
            const isInLabel = labelUserIds.includes(u.userId);

            if (viewMode === 'existing' && isInLabel) {
                // removing
                setSelectedToRemove((prev) => {
                    const updated = new Set(prev);

                    const exists = [...updated].some(
                        (item) => item.userId === u.userId && item.userType === u.userType
                    );

                    if (exists) {
                        [...updated].forEach((item) => {
                            if (item.userId === u.userId && item.userType === u.userType) updated.delete(item);
                        });
                    } else {
                        updated.add({ userId: u.userId, userType: u.userType });
                    }
                    return updated;
                });
            } else if (viewMode === 'nonExisting' && !isInLabel) {
                // adding
                setSelectedToAdd((prev) => {
                    const updated = new Set(prev);
                    const exists = [...updated].some(
                        (item) => item.userId === u.userId && item.userType === u.userType
                    );
                    if (exists) {
                        [...updated].forEach((item) => {
                            if (item.userId === u.userId && item.userType === u.userType) updated.delete(item);
                        });
                    } else {
                        updated.add({ userId: u.userId, userType: u.userType });
                    }
                    return updated;
                });
            }
        },
        [labelUserIds, viewMode]
    );


    // Check if user is selected
    const isUserChecked = useCallback(
        (u) => {
            const inLabel = labelUserIds.includes(u.userId);
            const isInSelectedToAdd = [...selectedToAdd].some((sel) => sel.userId === u.userId && sel.userId === u.userId);
            const isInSelectedToRemove = [...selectedToRemove].some((sel) => sel.userId === u.userId && sel.userType === u.userType);

            if (inLabel && isInSelectedToRemove) return true;
            if (!inLabel && isInSelectedToAdd) return true;
            return false;
        },
        [labelUserIds, selectedToAdd, selectedToRemove]
    );

    // “Select all”
    const handleSelectAll = () => {
        if (selectAllChecked) {
            // unselect
            if (viewMode === 'existing') setSelectedToRemove(new Set());
            else if (viewMode === 'nonExisting') setSelectedToAdd(new Set());
        } else {
            // select
            const keys = filteredUsers.map((u) => `${u.userId}-${u.userType}`);
            if (viewMode === 'existing') setSelectedToRemove(new Set(keys));
            else if (viewMode === 'nonExisting') setSelectedToAdd(new Set(keys));
        }
        setSelectAllChecked(!selectAllChecked);
    };

    const getSelectedCount = useCallback(() => {

        if (viewMode === 'existing') {
            return selectedToRemove.size;
        }
        if (viewMode === 'nonExisting') {
            return selectedToAdd.size;
        }
        return 0;
    }, [viewMode, selectedToRemove, selectedToAdd]);

    useEffect(() => {
        if (visible && label && viewMode === 'scheduled') {
            fetchScheduledMessages(label.labelId);
        }
    }, [visible, label, viewMode]);

    useEffect(() => {
        const count = getSelectedCount();
        setSelectedCount(count);
    }, [getSelectedCount]);

    // “Save” changes (Add / Remove)
    const handleSaveChanges = async () => {
        if (viewMode === 'scheduled') {
            // we want to add a new schedule => open scheduled modal
            setSelectedScheduledMessage(null);
            setIsAddSchedule(true);
            setShowScheduledModal(true);
            return;
        }

        // otherwise handle membership changes
        onClose();
        setSelectedToAdd(new Set())
        setSelectedToRemove(new Set())
        setLoading(true);

        // rename label if needed
        if (label.labelName !== newLabelName && newLabelName.trim() !== '') {
            await LabelService.updateLabelName(label.labelId, newLabelName.trim());
        }


        // add newly selected
        if (Array.from(selectedToAdd).length > 0) {
            await LabelService.addUsersToLabel(label.labelId, selectedToAdd);
        }
        // remove newly unselected
        if (Array.from(selectedToRemove).length > 0) {
            await LabelService.removeUsersFromLabel(label.labelId, selectedToRemove);
        }

        await onUsersUpdated(label.labelId);
        setLoading(false);
        onReopen();
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // *** SCHEDULED MESSAGES LOGIC ***  
    // If user switches to scheduled tab => load scheduled messages
    const fetchScheduledMessages = async (labelId) => {
        try {
            setLoading(true);
            const data = await ScheduledMessagesService.getByLabel(labelId);
            setScheduledMessages(data || []);
        } catch (error) {
            console.error('Error fetching scheduled messages:', error);
        } finally {
            setLoading(false);
        }
    };

    // Usually we do a confirm step
    const onDeleteScheduledMessage = async (id) => {
        // do your call to delete
        // await ScheduledMessagesService.deleteScheduledMessage(id);
        // refetch
        fetchScheduledMessages(label.labelId);
    };

    // E.g. "Add scheduled" => open modal
    const handleAddScheduled = () => {
        setSelectedScheduledMessage(null);
        setIsAddSchedule(true);
        setShowScheduledModal(true);
    };

    // Editing existing schedule => isAddSchedule = false
    const openEditScheduledModal = (messageItem) => {

        // Helper function to convert TimeSpan to Israel time
        const toIsraelTimeForSendTime = (timeSpan) => {
            const israelTzOffset = 2 * 60 * 60 * 1000; // Israel Standard Time is UTC+2
            const utcDate = new Date(`1970-01-01T${timeSpan}Z`); // Use dummy date to parse time

            // Adjust for Daylight Saving Time (DST)
            const isDST = (date) => {
                const lastFridayOfMarch = new Date(date.getFullYear(), 2, 31 - new Date(date.getFullYear(), 2, 31).getDay(), 2, 0); // Last Friday of March
                const lastSundayOfOctober = new Date(date.getFullYear(), 9, 31 - new Date(date.getFullYear(), 9, 31).getDay(), 2, 0); // Last Sunday of October
                return date >= lastFridayOfMarch && date < lastSundayOfOctober;
            };

            const offset = isDST(utcDate) ? israelTzOffset + 3600000 : israelTzOffset; // Add DST adjustment
            const israelTime = new Date(utcDate.getTime() + offset);

            // Format to TimeSpan format (HH:mm:ss.SSS0000)
            const hours = String(israelTime.getHours()).padStart(2, '0');
            const minutes = String(israelTime.getMinutes()).padStart(2, '0');
            const seconds = String(israelTime.getSeconds()).padStart(2, '0');
            const milliseconds = String(israelTime.getMilliseconds()).padStart(3, '0').padEnd(7, '0');

            return `${hours}:${minutes}:${seconds}.${milliseconds}`;
        };

        // Helper function to convert UTC datetime to Israel local datetime
        const toIsraelDateTime = (utcDateTime) => {
            const israelTzOffset = 2 * 60 * 60 * 1000; // Israel Standard Time is UTC+2
            const utcDate = new Date(utcDateTime);

            // Adjust for Daylight Saving Time (DST)
            const isDST = (date) => {
                const lastFridayOfMarch = new Date(date.getFullYear(), 2, 31 - new Date(date.getFullYear(), 2, 31).getDay(), 2, 0); // Last Friday of March
                const lastSundayOfOctober = new Date(date.getFullYear(), 9, 31 - new Date(date.getFullYear(), 9, 31).getDay(), 2, 0); // Last Sunday of October
                return date >= lastFridayOfMarch && date < lastSundayOfOctober;
            };

            const offset = isDST(utcDate) ? israelTzOffset + 3600000 : israelTzOffset; // Add DST adjustment
            const israelDate = new Date(utcDate.getTime() + offset);

            return israelDate.toISOString(); // Keep ISO format
        };

        // Convert times to Israel time
        const updatedMessageItem = {
            ...messageItem,
            sendTime: toIsraelTimeForSendTime(messageItem.sendTime), // Convert sendTime
            nextRunTime: toIsraelDateTime(messageItem.nextRunTime), // Convert nextRunTime
        };


        setSelectedScheduledMessage(updatedMessageItem);
        setIsAddSchedule(false);
        setShowScheduledModal(true);
    };

    const handleDelete = (item) => {

        setItemToDelete(item);
        setIsModalVisible(true);
    };
    const handleCloseDelete = () => {
        setItemToDelete(null);
        setIsModalVisible(false);
    };

    const handleDeleteScheduledMessage = async (id) => {
        try {
            setLoading(true);
            await ScheduledMessagesService.deleteScheduledMessage(id);
            fetchScheduledMessages(label.labelId);
            handleCloseDelete()
        } catch (error) {
            console.error('Failed to delete scheduled message:', error);
        } finally {
            setLoading(false);
        }
    };
    // Scheduled messages UI
    const renderScheduled = (
        <div className="mlu-scheduled-section">
            {/* Instead of FlashList, we do a normal .map */}

            <button className='addScheduled' onClick={handleAddScheduled}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path d="M23.9993 17.3334H17.3327V24.0001C17.3327 24.7334 16.7327 25.3334 15.9993 25.3334C15.266 25.3334 14.666 24.7334 14.666 24.0001V17.3334H7.99935C7.26602 17.3334 6.66602 16.7334 6.66602 16.0001C6.66602 15.2667 7.26602 14.6667 7.99935 14.6667H14.666V8.00008C14.666 7.26675 15.266 6.66675 15.9993 6.66675C16.7327 6.66675 17.3327 7.26675 17.3327 8.00008V14.6667H23.9993C24.7327 14.6667 25.3327 15.2667 25.3327 16.0001C25.3327 16.7334 24.7327 17.3334 23.9993 17.3334Z" fill="white" />
                </svg>
            </button>
            {scheduledMessages.length === 0 ? (
                <div style={{ margin: 20, color: '#666' }}>{t('NoScheduledMessages')}</div>
            ) : (
                <div className="scheduled-list-container">
                    {scheduledMessages.map((item) => (
                        <ScheduledItem
                            key={item.id}
                            item={item}
                            translate={(str) => str}
                            onDelete={() => {
                                handleDelete(item);
                                // onDeleteScheduledMessage(item.id);
                            }}
                            onEdit={openEditScheduledModal}
                            openPreview={(uris, type) => alert('open preview with ' + uris)}
                        />
                    ))}
                </div>
            )}

        </div>
    );

    // Existing / NonExisting user list
    const renderUserList = (
        <div className="mlu-users-list">
            {filteredUsers.length === 0 && (
                <div className="mlu-empty">{t('NoUsersFound')}</div>
            )}
            {filteredUsers.map((u) => {
                const checked = isUserChecked(u);
                return (
                    <div
                        key={`${u.userId}-${u.userType}`}
                        className="mlu-user-item"
                        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                        onClick={() => toggleUser(u)}
                    >
                        {/* REPLACE the checkbox with SelectableUserAvatar */}
                        <SelectableUserAvatar
                            name={u.userName}
                            isSelected={checked}
                        // onToggle={() => toggleUser(u)}
                        />

                        {/* You can still display the user’s name or unread count next to the avatar */}
                        <div className='mlu-container'>
                            <span className="mlu-username">{u.userName}</span>
                            <span className="mlu-caseType">{u.caseType}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );

    const formatMessageDate = (date) => {
        // If it’s older than a day, show `DD/MM HH:mm`
        // Otherwise, you might still show the same format or another. 
        // For simplicity, we'll *always* show "DD/MM HH:mm" in Israel time:
        return moment(date).tz('Asia/Jerusalem').format('DD/MM HH:mm');
    };

    const handleSendMessage = async (message) => {
        if (!label) return; // Make sure we have a label

        // Build an optimistic message
        const senderName = await AuthService.getCurrentUserTypeName();
        const newMessage = {
            id: uuid(), // Temporary unique ID for UI
            position: "right",
            type: message.type || "text",
            text: message.text || "",
            date: moment().tz("Asia/Jerusalem").toDate(),
            dateString: formatMessageDate(moment().tz("Asia/Jerusalem").toDate()),
            title: senderName,
            data: {},
            sending: true,
        };

        // If it's a photo, audio, or file => add the relevant data
        if (message.type === "photo") {
            newMessage.data = { uris: message.files?.map((file) => URL.createObjectURL(file)) || [] };
        } else if (message.type === "audio") {
            newMessage.data = { audioURLs: message.files?.map((file) => URL.createObjectURL(file)) || [] };
        } else if (message.type === "file") {
            newMessage.data = { fileURLs: message.files?.map((file) => `${URL.createObjectURL(file)}|${file.name}`) || [] };
        }



        // Build the FormData
        const formData = new FormData();
        formData.append("SenderId", userId);
        formData.append("SenderName", senderName);
        formData.append("SenderType", userType);
        formData.append("ReceiverType", Constants.ReceiverType.Label);  // The crucial difference for sending to label
        formData.append("ReceiverId", label.labelId.toString());
        formData.append("LabelName", label.labelName); // If backend expects
        formData.append(
            "MessageType",
            message.type === "text"
                ? "0"
                : message.type === "photo"
                    ? "1"
                    : message.type === "audio"
                        ? "3"
                        : "2"
        );
        formData.append("MessageContent", message.text || "");

        // Append files if any
        if (message.files) {
            message.files.forEach((file) => {
                formData.append("Files", file, file.name);
            });
        }

        // Make the API call
        try {
            const response = await ChatApiService.sendLabelMessage(formData);

            // Optionally show a success toast
            toast.success(t('MessageSentSuccessfully'));

        } catch (error) {
            console.error("Error sending message to label:", error);

            toast.error(t('MessageSentFailed'));

        }
    };

    const handleAttachFiles = (files) => {
        const formattedFiles = Array.from(files).map((file) => ({
            id: uuid(),
            file,
            name: file.name,
        }));

        // We treat them as "file" type
        handleSendMessage({
            type: "file",
            files: formattedFiles.map((f) => f.file),
        });
    };

    const handleRecordingStart = async () => {
        try {
            if (isRecording) {
                alert("Recording already in progress.");
                return;
            }
            if (!navigator.mediaDevices?.getUserMedia) {
                alert("Microphone not supported in this browser.");
                return;
            }

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mediaRecorder = new MediaRecorder(stream);
            audioChunksRef.current = [];

            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };
            // If you have a chatService, to notify "isRecording":
            // chatService.current?.isRecording(label.labelId, Constants.ReceiverType.Label, true);

            setRecorder(mediaRecorder);
            setIsRecording(true);
            mediaRecorder.start();
        } catch (error) {
            console.error("Error starting recording:", error);
            alert("Failed to start recording.");
            setIsRecording(false);
        }
    };


    const handleRecordingStop = async () => {
        try {
            if (!isRecording || !recorder) {
                alert("No recording in progress.");
                return;
            }

            // chatService.current?.isRecording(label.labelId, Constants.ReceiverType.Label, false);
            recorder.stop();

            recorder.onstop = async () => {
                setIsRecording(false);

                const audioBlob = new Blob(audioChunksRef.current, { type: "audio/mp4" });
                const audioUrl = URL.createObjectURL(audioBlob);

                // Build an optimistic UI message
                const senderName = await AuthService.getCurrentUserTypeName();
                const newMessage = {
                    id: uuid(),
                    position: "right",
                    type: "audio",
                    text: "",
                    date: moment().tz("Asia/Jerusalem").toDate(),
                    dateString: formatMessageDate(moment().tz("Asia/Jerusalem").toDate()),
                    title: senderName,
                    data: { audioURLs: [audioUrl] },
                    sending: true,
                };

                // Send via FormData
                try {
                    const formData = new FormData();
                    formData.append("SenderId", userId);
                    formData.append("SenderName", senderName);
                    formData.append("SenderType", userType);
                    formData.append("ReceiverType", Constants.ReceiverType.Label);
                    formData.append("ReceiverId", label.labelId.toString());
                    formData.append("LabelName", label.labelName);
                    formData.append("MessageType", "3"); // Audio
                    formData.append("MessageContent", "");

                    const file = new File([audioBlob], `audio-${Date.now()}.mp4`, {
                        type: "audio/mp4",
                    });
                    formData.append("Files", file);

                    const response = await ChatApiService.sendLabelMessage(formData);

                    // Update success
                    toast.success(t('MessageSentSuccessfully'));

                } catch (error) {
                    console.error("Error sending audio to label:", error);
                    // Mark as failed
                    toast.error(t('MessageSentFailed'));

                }
            };
        } catch (error) {
            console.error("Error stopping recording:", error);
            alert("Failed to stop recording.");
        }
    };

    const handleCancelRecording = () => {
        try {
            if (!isRecording || !recorder) {
                alert("No recording in progress to cancel.");
                return;
            }
            // chatService.current?.isRecording(label.labelId, Constants.ReceiverType.Label, false);
            recorder.stop();
            setIsRecording(false);
            setRecorder(null);
            audioChunksRef.current = [];
        } catch (error) {
            console.error("Error canceling recording:", error);
        }
    };

    // Chat input bar for “existing” mode
    const renderChatBar = (

        <ChatInputBar
            handleSendMessage={handleSendMessage}
            handleAttachFiles={handleAttachFiles}
            handleRecordingStart={handleRecordingStart}
            handleRecordingStop={handleRecordingStop}
            handleCancelRecording={handleCancelRecording}
            setIsRecording={setIsRecording}
            isRecording={isRecording}
            isTyping={false}
            isTargetRecording={false}
            chatService={null}
            targetUserId={label?.labelId}
            targetUserType={Constants.ReceiverType.Label}
        />

    );

    // Portal attach logic
    useEffect(() => {
        if (visible) {
            document.body.appendChild(container);
        }
        return () => {
            try {
                document.body.removeChild(container);
            } catch (err) {
                // ignore
            }
        };
    }, [visible, container]);

    if (!visible) return createPortal(null, container);


    // Modal content
    const modalUI = (
        <Draggable
            nodeRef={draggableNodeRef}
            handle=".mlu-modal-header"
            defaultPosition={{ x: 0, y: 0 }}
        >
            <div className={`mlu-modal-wrapper ${direction}`} ref={draggableNodeRef}>
                {/* HEADER */}
                <div className="mlu-modal-header">
                    <div className="mlu-header-left">
                        <span className="mlu-title">{t('ManageDistributionListUsers')}</span>
                    </div>
                    <div className="mlu-header-right">
                        <button className="mlu-close-btn" onClick={onClose}>
                            <FiX size={18} />
                        </button>
                    </div>
                </div>

                {/* BODY */}
                <div className="mlu-modal-body">
                    {/* Label Name */}
                    <h3>{label?.labelName || '---'}</h3>

                    {/* If we’re in existing or nonExisting => show Filter + search */}


                    {/* View Mode Tabs */}
                    <div className="mlu-viewmode-bar">
                        <button
                            className={viewMode === 'existing' ? 'active' : ''}
                            onClick={() => {
                                setViewMode('existing');
                                setSelectedToAdd(new Set());
                                setSelectedToRemove(new Set());
                                setSelectAllChecked(false);
                            }}
                        >
                            {t('ExistingUsers')}
                        </button>
                        <button
                            className={viewMode === 'nonExisting' ? 'active' : ''}
                            onClick={() => {
                                setViewMode('nonExisting');
                                setSelectedToAdd(new Set());
                                setSelectedToRemove(new Set());
                                setSelectAllChecked(false);
                            }}
                        >
                            {t('NonExistingUsers')}
                        </button>
                        <button
                            className={viewMode === 'scheduled' ? 'active' : ''}
                            onClick={() => {
                                setViewMode('scheduled');
                                setSelectedToAdd(new Set());
                                setSelectedToRemove(new Set());
                                setSelectAllChecked(false);
                            }}
                        >
                            {t('ScheduledMessages')}
                        </button>
                    </div>
                    {(viewMode === 'existing' || viewMode === 'nonExisting') && (
                        <div className="mlu-filter-row">
                            <FilterOptionMenu
                                optionGroups={optionGroups}
                                selectedOptions={selectedOptions}
                                setSelectedOptions={setSelectedOptions}
                                height="48px"
                            />
                            <TextField
                                fullWidth
                                placeholder={t('SearchUsers')}
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className="search-bar"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                    sx: {
                                        height: '48px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                        },
                                    },
                                    ...(direction === 'ltr'
                                        ? {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end" style={{ marginRight: '10px' }}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }
                                        : {
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton edge="start" style={{ marginLeft: '10px' }}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }),
                                }}
                                inputProps={{
                                    style: {
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                            />
                        </div>
                    )}
                    {/* Select All & Save (only for existing or nonExisting) */}
                    {viewMode !== 'scheduled' && (
                        <div className="mlu-select-row">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    checked={selectAllChecked}
                                    onChange={handleSelectAll}
                                    sx={{
                                        color: '#F9AA2A',
                                        '&.Mui-checked': {
                                            color: '#F9AA2A',
                                        },
                                    }}
                                />
                                <Typography>
                                    {t('SelectAll')} ({selectedCount})
                                </Typography>
                            </div>
                            {
                                selectedCount > 0 &&
                                <button
                                    onClick={handleSaveChanges}
                                    className={`mlu-confirm-btn ${viewMode === 'existing' ? 'mlu-remove' : 'mlu-add'}`}
                                >
                                    {viewMode === 'existing' ? <RemoveIcon /> : <AddIcon />}
                                </button>
                            }

                        </div>
                    )}

                    {/* Main content: either scheduled or user list */}
                    {viewMode === 'scheduled' ? renderScheduled : renderUserList}

                    {/* If “existing”, show the Chat Input to send messages to label */}
                </div>
                {viewMode === 'existing' && renderChatBar}
                {showScheduledModal && (
                    <ScheduledFormModal
                        visible={showScheduledModal}
                        onClose={() => setShowScheduledModal(false)}
                        translate={t}
                        scheduledItem={selectedScheduledMessage}
                        isAddSchedule={isAddSchedule}
                        label={label}
                        userId={userId}
                        userType={userType}
                        onRefresh={fetchScheduledMessages}
                        setShowScheduledModal={setShowScheduledModal}
                    />
                )}
                <CustomDeleteConfirmationModal
                    isVisible={isModalVisible}
                    onClose={handleCloseDelete}
                    onDelete={() => handleDeleteScheduledMessage(itemToDelete.id)}
                    confirmMessage={t('confirmDeleteMessage')}
                    titleMessage={t('confirmDeleteTitle')}
                />
            </div>
        </Draggable>
    );

    return createPortal(modalUI, container);
};

export default ManageLabelUsersModal;
