import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { beautifyNumber } from '../../../Utils/FormatNumber';
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  width: '100%',
  '& .MuiAutocomplete-root': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& *': {
    boxSizing: 'border-box !important',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#BFC8FF',
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: '#BFC8FF',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: '#304FFF',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 'normal',
  },
  '& .MuiDataGrid-cell': {
    borderTop: '1px solid rgba(191, 191, 193, 0.56)',
    backgroundColor: 'rgba(234, 237, 255, 0.32)',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'rgba(191, 200, 255, 0.3)',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .disableUser': {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  // Edit mode styles
  '& .MuiDataGrid-cell--editing': {
    padding: 0,
    backgroundColor: 'inherit',
  },
  '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
    borderRight: 'none',
  },
  '& .MuiDataGrid-row--editing .MuiDataGrid-cell:not(:last-child)': {
    borderRight: '8px solid transparent',
  },
}));

const ClientItemDataGrid = forwardRef((props, ref) => {
  const { data, direction, onEdit, onDelete, onSelectRows } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setRows(data || []);
  }, [data]);

  // Let the parent unselect rows programmatically if needed
  useImperativeHandle(ref, () => ({
    deselectRows(ids) {
      setSelectedRows((prev) => prev.filter((rowId) => !ids.includes(rowId)));
    },
  }));

  const handleRowSelectionModelChange = (selection) => {
    setSelectedRows(selection);
    if (onSelectRows) {
      onSelectRows(selection);
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: t('Actions'),
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      // Typically we don't need sorting for action buttons
      sortable: false,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <IconButton>
              <EditIcon style={{ color: '#304FFF' }} />
            </IconButton>
          }
          label="Edit"
          onClick={() => onEdit(params.row)}
          showInMenu={false}
        />,
        <GridActionsCellItem
          icon={
            <IconButton>
              <DeleteIcon style={{ color: 'red' }} />
            </IconButton>
          }
          label="Delete"
          onClick={() => onDelete(params.row)}
          showInMenu={false}
        />,
      ],
    },
    {
      field: 'price',
      headerName: t('Price'),
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      type: 'number',       // Ensures numeric sorting
      sortable: true,       // Enable sorting
      valueFormatter: (params) => {
        return beautifyNumber(params || 0)
      },
    },
    {
      field: 'name',
      headerName: t('Name'),
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true, // Ensure sorting is enabled
    },
    {
      field: 'makat',
      headerName: t('Makat'),
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true, // Ensure sorting is enabled
    },
  ];

  // Reverse columns if needed for RTL
  const finalColumns = direction === 'rtl' ? [...columns].reverse() : columns;

  return (
    <div style={{ width: '100%', height: '60vh', direction, marginTop: '10px' }}>
      <StyledDataGrid
        rows={rows}
        columns={finalColumns}
        getRowId={(row) => row.id}
        pagination
        // Sorting is enabled by default, columns are set to 'sortable: true' where needed
        onRowDoubleClick={(params) => onEdit(params.row)}
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={handleRowSelectionModelChange}
      />
    </div>
  );
});

export default ClientItemDataGrid;
