import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box, Button, Checkbox } from '@mui/material';
import { t } from 'i18next';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import TocIcon from '@mui/icons-material/Toc';
import * as XLSX from 'xlsx';
import PythonService from '../../../Services/pythonScripts.service';
import { beautifyNumber } from '../../../Utils/FormatNumber';
import { useTranslation } from 'react-i18next';
import Constants from '../../../assests/Constants/constants';
/** Define the modal style */
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

/** Number of transactions per page before inserting a new .pageStyle block */
const transactionsPerPage = 13;

const ProfitAndLossReportHtml = ({
  open,
  handleClose,
  data,
  handleSelect,
  token,
  client,
  showAccountCardsWithZeros,      // If false => hide zero-balance items
  setShowAccountCardsWithZeros,
}) => {

  // Summation variables for the final rows
  let profitAndLossTotalInitDebit = 0;
  let profitAndLossTotalInitCredit = 0;
  let profitAndLossTotalDebit = 0;
  let profitAndLossTotalCredit = 0;
  let profitAndLossTotalBalance1 = 0;

  // If you also want a global "דוח" total (matching the snippet),
  // keep track of them here:
  let totalInitDebitAccountReport = 0;
  let totalInitCreditAccountReport = 0;
  let totalDebitAccountReport = 0;
  let totalCreditAccountReport = 0;
  let totalBalanceReport = 0;

  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  // The HTML content for the report
  const [reportHtmlContent, setReportHtmlContent] = useState('');

  // Client data reference if needed in the header
  const [clientData, setClientData] = useState('');

  // Keep track of which palCodeAccountGroups are in P&L
  const [profitAndLossNames, setProfitAndLossNames] = useState([]);

  // Trigger to force the generation of the report
  const [showReport, setShowReport] = useState(false);

  const [showCards, setCards] = useState(false);

  // To ensure we generate the report once per relevant change
  const reportGenerated = useRef(false);

  /**
   * Identify which "palCodeAccountGroup" belongs to P&L
   * We assume it begins at "הכנסות" and includes everything after,
   * until we hit "NonPalCodeAccountGroup".
   */
  useEffect(() => {
    if (data && data.transaction?.length > 0) {
      let reachedProfitAndLoss = false;
      const newProfitAndLossNames = [];

      data.transaction.forEach(item => {
        const groupName = item.palCodeAccountGroup;
        const palCodeType = item.palCodeType;

        if (palCodeType === Constants.ResultAccountName) {
          reachedProfitAndLoss = true;
          if (!newProfitAndLossNames.includes(groupName)) {
            newProfitAndLossNames.push(groupName);
          }
        } else if (
          reachedProfitAndLoss &&
          groupName !== 'NonPalCodeAccountGroup'
        ) {
          if (!newProfitAndLossNames.includes(groupName)) {
            newProfitAndLossNames.push(groupName);
          }
        }
      });

      setProfitAndLossNames(newProfitAndLossNames);
      setShowReport(true);
    }
  }, [data]);

  // Generate the report when triggered
  useEffect(() => {
    generateReportHtml();

    if (showReport) {
      // generateReportHtml();
      setShowReport(false);
    }
  }, [showReport, showAccountCardsWithZeros, showCards]);

  

  // Keep track of the client prop if needed
  useEffect(() => {
    setClientData(client);
  }, [client]);

  /** 
   * Listen for custom "rowClick" events; decode the JSON 
   * and call handleSelect with the relevant info
   */
  useEffect(() => {
    const handleRowClick = (event) => {
      try {
        const jsonString = decodeURIComponent(event.detail);
        const transaction = JSON.parse(jsonString);

        const accountCard = {
          cardId: transaction.accountCardId,
          name: transaction.accountCardName,
        };

        const palCode = {
          palCodeId: transaction.palCode,
        };

        handleSelect(accountCard, palCode);
      } catch (error) {
        console.error("Failed to parse JSON from event.detail:", error);
      }
    };

    window.addEventListener('rowClick', handleRowClick);
    return () => {
      window.removeEventListener('rowClick', handleRowClick);
    };
  }, [handleSelect]);

  /**
   * Utility: Group an array by a given key
   */
  const groupBy = (arr, key) => {
    return arr.reduce((acc, item) => {
      if (!acc[item[key]]) {
        acc[item[key]] = [];
      }
      acc[item[key]].push(item);
      return acc;
    }, {});
  };

  /**
   * 1) Filter for P&L only
   * 2) Possibly exclude zero-balance items
   * 3) Build the HTML using your snippet's style
   * 4) Split by <div class="pageStyle"> ... </div> to inject final row
   */
  const generateReportHtml = () => {
    // Filter to only the transactions that are in the discovered P&L group

    let filteredTransactions = data.transaction.filter(tr =>
      profitAndLossNames.includes(tr.palCodeAccountGroup)
    );

    // If user doesn't want zero-balance cards, remove them
    if (!showAccountCardsWithZeros) {
      filteredTransactions = filteredTransactions.filter(tr => {
        // Keep if it has any non-zero opening or activity
        return (
          tr.initDebitAccount !== 0 ||
          tr.initCreditAccount !== 0 ||
          tr.debitAccount !== 0 ||
          tr.creditAccount !== 0 ||
          tr.balance !== 0
        );
      });
    }

    // (Optional) Build unique categories/codes for display
    const uniquePalCodeCategories = [
      ...new Set(
        filteredTransactions
          .map(tr => tr.palCodeCategory)
          .filter(category => category !== "NonPalCodeCategory")
      ),
    ];

    const uniquePalCodes = [
      ...new Set(
        filteredTransactions
          .map(tr => tr.palCode)
          .filter(code => code !== "NonPalCode")
      ),
    ];

    let content = ` <div class="pageStyle"> <div class="upper-name-div">
              <p class="first" style="font-size: 20px; margin-left:20px;">${data.clientName}</p>
              <p class="first" style="font-size: 20px; margin-left:20px;">ח.פ. / ע.מ: ${data.clientCase}</p>
          </div>
          <div class="middle-details">
              <div>
                  <p class="rt1" style="font-size: 20px; margin: 0;">דוח רווח והפסד</p>
                  <div style="display: flex; gap: 20px;">
                      <p class="det-small" style="font-size: 20px; text-decoration: underline; font-weight: 900;">
                          מחודש ${data.startDate} עד חודש ${data.endDate}
                      </p>
                  </div>
              </div>
          </div>
          <div class="sum-details">
              <div class="palcodes-details" style="margin-right:40px;">
                  מסעיף: ${uniquePalCodeCategories[0] || ''} 
                  - עד סעיף: ${uniquePalCodeCategories[uniquePalCodeCategories.length - 1] || ''}
              </div>
              <div class="palcodes-details" style="margin-right:40px;">
                  מסיווג: ${uniquePalCodes[0] || ''} 
                  - עד סיווג: ${uniquePalCodes[uniquePalCodes.length - 1] || ''}
              </div>
          </div>`
    // Now build the content by grouping similarly to TrialBalance:
    content += generateAccountingGroupClassificationSections(
      filteredTransactions,
      uniquePalCodeCategories,
      uniquePalCodes
    );



    // Build the final row we want to inject
    const finalSummaryRow = `
      <tr class="tr-final-row-palcode-Report">
        <td class="total-palcode">סה''כ רווח והפסד בדוח: </td>
        <td class="td-last-sum-1">${beautifyNumber(profitAndLossTotalBalance1)}</td>
      </tr>
    `;
    // Split the content so we can inject the final row
    const lastIndex = content.lastIndexOf('<tr class="tr-final-row-palcode-category">');

    if (lastIndex !== -1) {
      // Find the closing </tr> tag after the last found occurrence
      const endIndex = content.indexOf('</tr>', lastIndex) + 5; // +5 to include '</tr>'

      // Remove only the last occurrence
      content = content.slice(0, lastIndex) + content.slice(endIndex);
    }

    content = content.slice(0, -31);

    content += finalSummaryRow + "</tbody></table></div>";

    // content = parts.join('');

    // Wrap in your large style block
    const reportHtml = `
      <!DOCTYPE html>
      <html lang="he">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <style>
          *{
          box-sizing: border-box !important;
          }
            html {
              height: 0;
              box-sizing: border-box !important;
            }
       
          @media print {
          .pageStyle {
            page-break-after: always !important;
            page-break-inside: avoid !important;
            border: none !important; /* Remove border */
            box-shadow: none !important; /* Remove box shadow */
            margin: 0 !important; /* Remove margins */
            padding: 0 !important; /* Remove padding */
            height: auto !important; /* Allow height to adjust */
          }

          @page {
            margin: 0; /* Removes margins to prevent unwanted spacing */
          }

          body {
            margin: 0; /* Ensures content spans fully without browser margins */
            padding: 0;
            font-size: 27px; /* Adjust font size for better fit */
          }

          .table {
            width: fit-content !important; /* Ensure table fits within the page */
            font-size: 24px; /* Adjust font size for better fit */
          }

          .upper-name-div, .middle-details, .sum-details {
            margin: 0 !important;
            padding: 0 !important;
          }

          .rt1, .rt2, .num-total, .normal-num, .underlined, .first, .det, .det-small, .num {
            font-size: 12px !important; /* Adjust font size for better fit */
          }

          .td-last-sum-1, .td-last-sum {
            font-size: 12px !important; /* Adjust font size for better fit */
          }

          .accountingGroupClassification_accountingGroup_container, .titles-details {
            margin: 0 !important;
            padding: 0 !important;
          }

          .sums-of-transactions {
            font-size: 12px !important; /* Adjust font size for better fit */
          }

          .palcodes-details {
            font-size: 12px !important; /* Adjust font size for better fit */
          }

          .total-palcode {
            font-size: 12px !important; /* Adjust font size for better fit */
          }

          .hoverTr {
            display: none; /* Hide hover effects in print */
          }

          .page-break {
            display: none; /* Hide page breaks in print */
          }
        }
.accountingGroupClassification_accountingGroup_container {
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;

              -webkit-box-pack: justify;
              -moz-box-pack: justify;
              -ms-flex-pack: justify;
              -webkit-justify-content: space-between;
              justify-content: space-between;

              width: 100%;
              margin-top: 20px;
            }
            .titles-details {
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              
              -webkit-box-align: center;
              -moz-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center;

              -webkit-box-pack: justify;
              -moz-box-pack: justify;
              -ms-flex-pack: justify;
              -webkit-justify-content: space-between;
              justify-content: space-between;
            }
            h1 {
              color: navy;
              text-align: center;
            }
            p {
              color: #333;
              padding: 20px;
              text-align: center;
            }
            .upper-name-div {
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              
              -webkit-box-orient: horizontal;
              -moz-box-orient: horizontal;
              -webkit-box-direction: reverse;
              -moz-box-direction: reverse;
              -webkit-flex-direction: row-reverse;
              -ms-flex-direction: row-reverse;
              flex-direction: row-reverse;

              padding: 10px;
              margin-right: 50px;
              justify-content: flex-end;
              text-align: right;
              direction: rtl;
            }
            .upper-name-font {
              font-weight: 800;
            }
            .middle-details {
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;

              -webkit-box-pack: center;
              -moz-box-pack: center;
              -ms-flex-pack: center;
              -webkit-justify-content: center;
              justify-content: center;

              -webkit-box-align: center;
              -moz-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center;

              height: 120px;
              padding-right: 30px;
              padding-left: 30px;
            }
            .middle-under-details {
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;

              -webkit-box-align: center;
              -moz-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center;

              height: 80px;
              padding-right: 30px;
            }
            .rt1 {
              font-size: 32px;
              font-style: normal;
              font-weight: 800;
              line-height: normal;
              direction: rtl;
              text-decoration: underline;
            }
            .rt2 {
              margin: 0;
              padding: 0;
              padding-bottom: 10px;
              font-size: 23px;
              font-style: normal;
              font-weight: 800;
              line-height: normal;
              text-decoration: underline;
            }
            .num-total {
              margin: 0;
              padding: 0;
              padding-bottom: 10px;
              font-size: 23px;
              font-style: normal;
              font-weight: 800;
              line-height: normal;
              position: relative;
              display: inline-block;
            }
            .num-total::before {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              height: 2px;
              background: black;
              top: -1px;
            }
            .num-total::after {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              height: 2px;
              background: repeating-linear-gradient(to right,
                      rgb(0, 0, 0),
                      rgb(0, 0, 0) 2px,
                      transparent 2px,
                      transparent 4px);
              bottom: 10px;
            }
            .normal-num {
              margin: 0;
              padding: 0;
              padding-bottom: 10px;
              font-size: 23px;
              font-style: normal;
              line-height: normal;
              position: relative;
              direction: ltr;
              display: inline-block;
            }
            .underlined {
              margin: 0;
              padding: 0;
              padding-bottom: 10px;
              font-size: 23px;
              font-style: normal;
              line-height: normal;
              position: relative;
              direction: ltr;
              display: inline-block;
            }
            .underlined::after {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              height: 1px;
              background: rgb(0, 0, 0);
              bottom: 10px;
            }
            .first {
              margin: 0;
              padding: 0;
              padding-bottom: 10px;
              font-size: 23px;
              font-style: normal;
              font-weight: 800;
              line-height: normal;
            }
            .det {
              margin: 0;
              padding: 2px;
              font-size: 23px;
              font-style: normal;
            }
            .det-small {
              margin: 0;
              padding: 2px;
              font-size: 18px;
              font-style: normal;
            }
            .td-last-sum-1{
              background-color: gray !important;
              color: white !important;
              font-size: 21px;
              font-weight: 800;
            }

            .num {
              margin: 0;
              padding: 2px;
              font-size: 23px;
              font-style: normal;
            }
            .details-div {
              width: 200px;
              margin-right: 90px;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
            }
            .details-line {
              width: 100%;
              height: 0px;
              border: 1px solid black;
              margin-left: auto;
              margin-right: auto;
            }
            .period-div {
              display: flex;
              justify-content: flex-end;
              align-items: start;
              width: 95%;
              margin-left: auto;
              margin-right: auto;
              padding: 10px;
            }
            .column_div {
              width: 200px;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
            }
        .table {
            width: auto;
            page-break-inside: avoid;
            margin-left: auto;
            /* Ensures it aligns to the right */
        }

        .table th:first-child,
        .table td:first-child {
            width: 450px;
            text-align: right;
            /* Keep Hebrew text alignment */
            word-wrap: break-word;
        }

        .table th:last-child,
        .table td:last-child {
            width: auto;
            /* Let the second column adjust dynamically */
            text-align: center;
        }
            .tr-underline {
              direction: rtl;
              border-bottom: 1px;
              border-color: #000000;
            }
            td {
              font-size: 14px;
            }
            .td-last {
              border-bottom: 1px;
              border-color: #000000;
            }
            .td-last:hover {
              background-color: #000000;
            }
            .td-last-sum {
              position: relative;
              text-align: center;
              font-weight: 1000;
              padding-bottom: 8px;
            }
            .td-last-sum::before, 
            .td-last-sum::after {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              border-bottom: 1px solid black;
            }
            .td-last-sum::before {
              bottom: -2px;
            }
            .td-last-sum::after {
              bottom: -8px;
            }
           
            .td-header-1 {
                position: relative;
                text-decoration: underline !important;
                text-align: right !important;
            }

            .td-header {
                position: relative;
                text-decoration: underline !important;
                text-align: center !important;
            }
            .td-last-sum2 {
              position: relative;
            }
            .td-last-sum2::after {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              height: 2px;
              z-index: 1;
            }
            .tr {
              border-bottom: 1px solid #b2b2b2;
              font-size: 16px;
            }
            .tr-final-row {
              border-bottom: 1px solid #000000;
              font-size: 16px;
            }
            .tr:hover {
              background-color: #ddd;
            }
            .th,
            td {
              border: 0px solid black;
              padding: 8px ;
              text-align: center;
            }
           .overflowed {
                text-align: right !important;
                padding-right: 20px !important;
                direction: rtl !important;
                white-space: normal;
            }
            .td {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              border: 1px solid black;
              padding: 8px;
              text-align: center;
            }
            .number-of-transactions {
              font-size: 18px;
              font-weight: 1000;
              text-decoration: underline;
            }
            .sums-of-transactions {
              font-size: 20px;
              font-weight: 1000;
              text-align: right;
            }
            .number-total {
              font-size: 16px;
              font-weight: 1000;
            }
            .underline {
              width: 100%;
              border: 1px solid black;
            }
            .sum-details {
              width: 100%;
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              
              -webkit-box-orient: vertical;
              -moz-box-orient: vertical;
              -webkit-box-direction: normal;
              -moz-box-direction: normal;
              -webkit-flex-direction: column;
              -ms-flex-direction: column;
              flex-direction: column;
              
              -webkit-box-pack: start;
              -moz-box-pack: start;
              -ms-flex-pack: start;
              -webkit-justify-content: flex-start;
              justify-content: flex-start;

              -webkit-box-align: end;
              -moz-box-align: end;
              -ms-flex-align: end;
              -webkit-align-items: flex-end;
              align-items: flex-end;
              
              margin-top: 40px;
              margin-bottom: 20px;
            }
            .titles-details {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
            .palcodes-details {
              font-size: 14px;
            }
            .total-palcode {
              font-weight: bold;
              font-size: 18px;
            }
            .pageStyle {
              margin-bottom: 20px;
              background:red;
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              
              -webkit-box-orient: vertical;
              -moz-box-orient: vertical;
              -webkit-box-direction: normal;
              -moz-box-direction: normal;
              -webkit-flex-direction: column;
              -ms-flex-direction: column;
              flex-direction: column;
              
              -webkit-box-pack: justify;
              -moz-box-pack: justify;
              -ms-flex-pack: justify;
              -webkit-justify-content: space-between;
              justify-content: space-between;

              background-color: white;
              padding: 20px;

              page-break-before: auto;
              page-break-after: auto;
              page-break-inside: avoid;
              width:100%;
              border: 1px solid #ccc;
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            }
            .page-break {
              margin-top: 50px;
              page-break-before: always;
              page-break-after: auto;
              page-break-inside: avoid;
            }
            tr {                                            
              page-break-inside: avoid;                      
              page-break-after: avoid;                       
              page-break-before: avoid;                      
            } 
            .hoverTr {
              transition: transform 0.2s ease, box-shadow 0.1s ease;
            }
            .hoverTr:hover {
              transform: translateY(-5px);
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
            }
          </style>
      </head>
      <body>
          ${content}
      </body>
      </html>
    `;

    setReportHtmlContent(reportHtml);
  };

  // -- Nested grouping logic, same as in TrialBalance, but we only pass P&L transactions:
  const generateAccountingGroupClassificationSections = (
    filteredTransactions,
    uniquePalCodeCategories,
    uniquePalCodes
  ) => {

    // group by 'accountingGroupClassification'
    const groupedByClassification = groupBy(filteredTransactions, 'accountingGroupClassification');
    let sectionContent = '';

    Object.keys(groupedByClassification).forEach(agClassification => {
      const transactionsForClass = groupedByClassification[agClassification];
      // group further by palCodeCategory
      const groupedByCategory = groupBy(transactionsForClass, 'palCodeCategory');

      // We'll build a header once per classification + palCodeAccountGroup
      // Typically the first transaction can give us the "palCodeAccountGroup"
      const palCodeAccountGroup = transactionsForClass[0]?.palCodeAccountGroup || '';

      // Render each palCodeCategory
      Object.keys(groupedByCategory).forEach(catKey => {
        const transactionsForCat = groupedByCategory[catKey];


        // Build the initial pageStyle, header, etc.
        const headerHtml = `
         
          <div class="accountingGroupClassification_accountingGroup_container">
              <div class="sums-of-transactions" style="text-decoration: underline; margin-right:40px">
                  ${palCodeAccountGroup === 'NonPalCodeAccountGroup'
            ? t('NonPalCodeAccountGroup')
            : palCodeAccountGroup
          }    -    ${agClassification === 'NonAccountingGroupClassifaction'
            ? t('NonAccountingGroupClassifaction')
            : agClassification
          }
  
              </div>
          </div>
        `;

        // Build the tables
        sectionContent += generatePalCodeTables(
          transactionsForCat,
          catKey,           // current palCodeCategory
          headerHtml,
          palCodeAccountGroup
        );
      });
    });

    return sectionContent;
  };

  const generatePalCodeTables = (
    transactionsForCategory,
    palCodeCategory,
    headerHtml,
    accountingGroupName
  ) => {
    let content = '';

    // group by palCode
    const groupedByPalCode = groupBy(transactionsForCategory, 'palCode');

    // Start table
    content += `
    <table class="table" style="margin-right: 40px; margin-top: 20px; padding: 0px 20px;" dir="rtl">
        <thead>
            <tr>
                <th colspan="2" style="text-align: right; padding: 10px;">
                    ${headerHtml}
                    <div style="margin-right: 40px;">
                        <div class="sums-of-transactions" style="margin-bottom: 10px; text-decoration: underline;">
                            ${palCodeCategory === 'NonPalCodeCategory' ? t('NonPalCodeCategory') : palCodeCategory}
                        </div>
                    </div>
                </th>
            </tr>
            <tr class="tr">
                ${showCards ? '<th class="td-header-1">כרטיס חשבון</th>' : '<th class="td-header-1"></th>'}
                <th class="td-header">יתרה</th>
            </tr>
        </thead>
        <tbody>
`;


    let rowsCount = 0;

    // For each palCode group
    const palCodes = Object.keys(groupedByPalCode);
    palCodes.forEach((palCode, index) => {
      const transactions = groupedByPalCode[palCode];

      // Insert a row for the palCode
      content += `
        <tr>
          <td class="overflowed" colspan="6">
            ${palCode === 'NonPalCode' ? t('NonPalCode') : palCode}
          </td>
        </tr>
      `;

      // Render each transaction
      transactions.forEach(tr => {
        // Accumulate P&L totals
        profitAndLossTotalInitDebit += tr.initDebitAccount;
        profitAndLossTotalInitCredit += tr.initCreditAccount;
        profitAndLossTotalDebit += tr.debitAccount;
        profitAndLossTotalCredit += tr.creditAccount;
        profitAndLossTotalBalance1 += tr.balance;

        // Accumulate total (the snippet also wants full totals)
        totalInitDebitAccountReport += tr.initDebitAccount;
        totalInitCreditAccountReport += tr.initCreditAccount;
        totalDebitAccountReport += tr.debitAccount;
        totalCreditAccountReport += tr.creditAccount;
        totalBalanceReport += tr.balance;

        const transactionDetail = encodeURIComponent(JSON.stringify(tr));

        if (showCards) {
          content += `
          <tr class="hoverTr" style="cursor:pointer"
              onclick="window.parent.dispatchEvent(new CustomEvent('rowClick', { detail: '${transactionDetail}' }))">
            <td class="td-last-sum2"> ${tr.accountCardId} ${tr.accountCardName}</td>
            <td class="td-last-sum2">${beautifyNumber(tr.balance)}</td>
          </tr>
        `;
        }


        rowsCount++;
        // Paginate if we reach transactionsPerPage
        if (rowsCount >= transactionsPerPage) {
          content += `
            </tbody>
            </table>
          </div>
          <div class="pageStyle">
                <table class="table" style="margin-right: 40px; margin-top: 20px; padding: 0px 20px 0 20px;" dir="rtl">
                    <thead>
                        <tr>
                            <th colspan="2" style="text-align: right; padding: 10px;">
                                ${headerHtml}
                                <div style="margin-right: 40px;">
                                    <div class="sums-of-transactions" style="margin-bottom: 10px; text-decoration: underline;">
                                        ${palCodeCategory}
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr class="tr">
                            <th class="td-header">כרטיס חשבון</th>
                            <th class="td-header">יתרה</th>
                        </tr>
                    </thead>
                    <tbody>
          `;
          rowsCount = 0;
        }
      });

      // Add a subtotal for this palCode
      content += generatePalCodeTotals(transactions, palCode);
    });

    // A total row for the entire palCodeCategory
    content += generatePalCodeCategoryTotals(transactionsForCategory, palCodeCategory, accountingGroupName);

    // Close the table and pageStyle
    content += `
          </tbody>
        </table>
    `;

    return content;
  };

  const generatePalCodeTotals = (transactions, palCode) => {
    const totalInitDebit = transactions.reduce((acc, t) => acc + t.initDebitAccount, 0);
    const totalInitCredit = transactions.reduce((acc, t) => acc + t.initCreditAccount, 0);
    const totalDebit = transactions.reduce((acc, t) => acc + t.debitAccount, 0);
    const totalCredit = transactions.reduce((acc, t) => acc + t.creditAccount, 0);
    const totalBalance = transactions.reduce((acc, t) => acc + t.balance, 0);

    return `
      <tr class="tr-final-row-palcode">
        <td class="total-palcode">סה''כ: ${palCode === 'NonPalCode' ? t('NonPalCode') : palCode} </td>
        <td class="td-last-sum">${beautifyNumber(totalBalance)}</td>
      </tr>
    `;
  };

  //bader version
  // const generatePalCodeCategoryTotals = (transactions, palCodeCategory, accountingGroupName) => {
  //   // Sum up the entire category
  //   const totalInitDebit = transactions.reduce((acc, t) => acc + t.initDebitAccount, 0);
  //   const totalInitCredit = transactions.reduce((acc, t) => acc + t.initCreditAccount, 0);
  //   const totalDebit = transactions.reduce((acc, t) => acc + t.debitAccount, 0);
  //   const totalCredit = transactions.reduce((acc, t) => acc + t.creditAccount, 0);
  //   const totalBalance = transactions.reduce((acc, t) => acc + t.balance, 0);

  //   // Return a single row with the category total
  //   return `
  //     <tr class="tr-final-row-palcode-category">
  //       <td class="total-palcode">סה''כ:### ${palCodeCategory === 'NonPalCodeCategory'
  //       ? t('NonPalCodeCategory')
  //       : palCodeCategory
  //     }</td>
  //       <td class="td-last-sum">${beautifyNumber(totalBalance)}</td>
  //     </tr>
  //   `;
  // };

  //new version
  const generatePalCodeCategoryTotals = (transactions, palCodeCategory, accountingGroupName) => {
    // Sum up the entire category
    const totalInitDebit = transactions.reduce((acc, t) => acc + t.initDebitAccount, 0);
    const totalInitCredit = transactions.reduce((acc, t) => acc + t.initCreditAccount, 0);
    const totalDebit = transactions.reduce((acc, t) => acc + t.debitAccount, 0);
    const totalCredit = transactions.reduce((acc, t) => acc + t.creditAccount, 0);
    const totalBalance = transactions.reduce((acc, t) => acc + t.balance, 0);



    // Base row for the palCodeCategory total
    let html = `
      <tr class="tr-final-row-palcode-category">
        <td class="total-palcode">
          סה''כ: ${palCodeCategory === 'NonPalCodeCategory'
        ? t('NonPalCodeCategory')
        : palCodeCategory}
        </td>
        <td class="td-last-sum">${beautifyNumber(totalBalance)}</td>
      </tr>
    `;

    // If the accounting group is 'הכנסות', add an additional row with its total.
    // Here, for demonstration purposes, we use the same total.
    // If you need a total across multiple categories, you'll need to compute that separately.
    const groupTransactions = data.transaction.filter(
      t => t.palCodeAccountGroup === accountingGroupName
    );

    // Compute the total balance for the group
    const groupTotalBalance = groupTransactions.reduce((acc, t) => acc + t.balance, 0);

    html += `
        <tr class="tr-final-row-palcode-category">
          <td class="total-palcode">סה''כ ${accountingGroupName}:</td>
          <td class="total-palcode">${beautifyNumber(groupTotalBalance)}</td>
        </tr>
        <tr class="tr-final-row-palcode-category">
          <td class="total-palcode">סה''כ רווח והפסד לאחר ${accountingGroupName}:</td>
          <td class="total-palcode">${beautifyNumber(profitAndLossTotalBalance1)}</td>
        </tr>
      `;

    return html;
  };

  /** 
   * Download only the P&L transactions to Excel 
   */
  const handleDownloadExcel = () => {
    if (!data || !data.transaction) return;

    // Filter to P&L
    const plTransactions = data.transaction.filter(tr =>
      profitAndLossNames.includes(tr.palCodeAccountGroup)
    );
    if (!plTransactions.length) return;

    // Headers
    const headers = {
      accountCardId: t("AccountCard ID"),
      accountCardName: t("AccountCard Name"),
      palCode: t("BalanceClassificationMazni"),
      palCodeType: t("BalanceClassificationType"),
      palCodeCategory: t("BalanceClassificationCategory"),
      palCodeAccountGroup: t("AccountingGroup"),
      accountingGroupClassification: t("BalanceClassification"),
      initCreditAccount: t("OpeningBalanceCreditAccount"),
      initDebitAccount: t("OpeningBalanceDebitAccount"),
      creditAccount: t("Credit Account"),
      debitAccount: t("Debit Account"),
      balance: t("Balance"),
    };

    // Transform
    const transformed = plTransactions.map((transaction) => {
      const row = {};
      for (const key in transaction) {
        if (headers[key]) {
          row[headers[key]] = transaction[key];
        }
      }
      return row;
    });

    // Create XLSX
    const ws = XLSX.utils.json_to_sheet(transformed);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'ProfitAndLoss');
    XLSX.writeFile(wb, `${data.clientName}_ProfitAndLossReport.xlsx`);
  };

  /**
   * Download PDF using your PythonService
   * Removes border / box-shadow just like in your snippet
   */
  const handleDownloadPdf = async () => {
    try {
      let pdfContent = reportHtmlContent;
      pdfContent = pdfContent.replace(/border: 1px solid #ccc;/g, '');
      pdfContent = pdfContent.replace(/box-shadow: 0px 0px 10px rgba\(0, 0, 0, 0.1\);/g, '');

      const response = await PythonService.convertHtmlToPdf(pdfContent, token);
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${data.clientName}-${data.clientCase}_ProfitAndLoss.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        console.error('Failed to convert HTML to PDF:', response.status);
      }
    } catch (error) {
      console.error('Error converting HTML to PDF:', error);
    }
  };

  /**
   * Print the HTML in a new window
   */
  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(reportHtmlContent);
    printWindow.document.close();
    printWindow.print();
  };

  /**
   * Toggle zero-balance accounts 
   */
  const handleShow0CardsCheckbox = (value) => {
    setShowAccountCardsWithZeros(value);
  };

  const handleShowCardsCheckbox = (value) => {
    setCards(value);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="profit-and-loss-modal-title"
      aria-describedby="profit-and-loss-modal-description"
    >
      <Box sx={modalStyle} >
        {/* Toolbar with PDF, Excel, Zeros, Print */}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} className={`${direction}`}>
          <div style={{ margin: '20px' }}>
            <Button
              variant="contained"
              onClick={handleDownloadPdf}
              startIcon={<DownloadIcon />}
              sx={{
                mt: 1,
                backgroundColor: '#304FFF',
                color: '#fff',
                '&:hover': { backgroundColor: '#F9AA2A' },
              }}
            >
              {t('DownloadPDFFile')}
            </Button>
          </div>

          <div style={{ margin: '20px' }}>
            <Button
              variant="contained"
              onClick={handleDownloadExcel}
              startIcon={<TocIcon />}
              sx={{
                mt: 1,
                backgroundColor: '#304FFF',
                color: '#fff',
                '&:hover': { backgroundColor: '#F9AA2A' },
              }}
            >
              {t('DownloadExcelFile')}
            </Button>
          </div>

          <div style={{ margin: '20px', display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={showAccountCardsWithZeros}
              onChange={(e) => handleShow0CardsCheckbox(e.target.checked)}
              name="ShowZeroBalance"
              color="primary"
            />
            <span>{t('ShowResetCards')}</span>
          </div>

          <div style={{ margin: '20px', display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={showCards}
              onChange={(e) => handleShowCardsCheckbox(e.target.checked)}
              name="ShowZeroBalance"
              color="primary"
            />
            <span>{t('ShowCards')}</span>
          </div>


          <div style={{ margin: '20px' }}>
            <Button
              variant="contained"
              onClick={handlePrint}
              startIcon={<PrintIcon />}
              sx={{
                mt: 1,
                backgroundColor: '#304FFF',
                color: '#fff',
                '&:hover': { backgroundColor: '#1565c0' },
              }}
            >
              {t('PrintReport')}
            </Button>
          </div>
        </div>

        {/* The actual rendered HTML */}
        <div
          dangerouslySetInnerHTML={{ __html: reportHtmlContent }}
          style={{ width: '95%', margin: '0 auto' }}
        />
      </Box>
    </Modal>
  );
};

export default ProfitAndLossReportHtml;
