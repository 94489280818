import React, { useEffect, useState, useCallback } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Constants from '../../../assests/Constants/constants';
import { styled } from '@mui/system';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';
import AddAccountCardModal from '../../Widgets/AddAccountCardModal/AddAccountCardModal';
import { useTranslation } from 'react-i18next';

import {
    Modal,
    TextField,
    Autocomplete,
    FormControl,
    FormControlLabel,
    IconButton,
    Popper,
    Button,
    Tooltip,
    Select,
    MenuItem,
} from '@mui/material';
import { getVatByCaseType } from '../../../Utils/GetVatValue';
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from 'react-dropzone';
import ClearIcon from '@mui/icons-material/Clear';
const StyledListItem = styled('li')({
    '&:hover': {
        backgroundColor: '#F5F5F6', // Hover color
        cursor: 'pointer', // Changes the cursor to pointer
    },
});

const StyledPopper = styled(Popper)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 'var(--Spacing-md, 8px)',
        background: 'var(--white, #FFF)',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.32)',
    },
    '& .MuiAutocomplete-listbox': {
        padding: theme.spacing(1),
    },
    '& .MuiAutocomplete-option': {
        borderRadius: 'var(--Spacing-sm, 4px)',
        '&[aria-selected="true"], &.Mui-focused, &:hover': {
            background: 'var(--orange, #F9AA2A) !important',
        },
    },
}));

const DropZoneContainer = styled('div')(({ isDragActive }) => ({
    // border: '2px dashed #ccc',
    width: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    background: isDragActive ? '#e0e0e0' : 'transparent',
    transition: 'background 0.3s ease-in-out',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
}));

const DragOverlayText = styled('div')({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#333',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: '10px 20px',
    borderRadius: '5px',
    display: 'none',
});
const FileList = styled('div')({
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});
const FileItem = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#f5f5f5',
    padding: '5px 10px',
    borderRadius: '5px',
    width: '100%',
    maxWidth: '300px',
    marginBottom: '5px',
});
const CreateDocumentModal = ({
    modalVisible,
    closeCreateModal,
    autoFilling,
    setAutoFilling,
    addNewAccountCardDocument,
    setAddNewAccountCardDocument,
    accountCardOptions,
    sortCodeIdOptions,
    creditAccountOptions,
    debitAccountOptions,
    type,
    formData,
    setFormData,
    handleSave,
    allFieldsFilled,
    documentDate,
    setDocumentDate,
    documentVat,
    setDocumentVat,
    handleDocumentUpload,
    documents,
    errorMessage,
    okMessage,
    client,
    token,
    setLoading,
    palCodes,
    fetchEnums,
    setDocuments
}) => {
    const { i18n, t } = useTranslation();

    const [creditDebitSearchTerm, setCreditDebitSearchTerm] = useState('');
    const [newAccountCard, setNewAccountCard] = useState({ accountCodeName: '', accountCodeId: '' });
    const [isEditAccountCard, setIsEditAccountCard] = useState(false);
    const [isAddAccountCard, setIsAddAccountCard] = useState(false);
    const [editedAddAccountCard, setEditedAddAccountCard] = useState(false);
    const [palCode, setPalCode] = useState('');
    const [newCardId, setNewCardId] = useState(null);
    const [addAccountCardType, setAddAccountCardType] = useState('main');
    const [cardIdOption, setCardIdOption] = useState(-1);
    const [isDragging, setIsDragging] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);

    // Default text direction
    const [direction, setDirection] = useState('rtl');
    useEffect(() => {
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);

    // Compute max Card ID
    useEffect(() => {
        if (accountCardOptions && accountCardOptions.length > 0) {
            const maxCardId = Math.max(
                ...accountCardOptions.slice(1).map((option) => parseInt(option.cardId, 10))
            );
            setCardIdOption(maxCardId + 1);
        }
    }, [accountCardOptions]);

    // Format account options
    const formattedAccountCardOptions = accountCardOptions.map((option) => ({
        value: option.cardId,
        label: option.accountCaseId
            ? `${option.name} - ${option.accountCaseId}`
            : `${option.name}`,
        name: option.name,
        accountCaseId: option.accountCaseId || '',
        cardId: option.cardId,
        value1: option.value1,
        isEditable: option.isEditable,
        palCode: option.palCode,
    }));
    // Format sort-code options
    const formattedSortCodeOptions = sortCodeIdOptions.map((option) => ({
        value: option.sortCodeId,
        label: `${option.sortCodeId} - ${option.sortCodeName}`,
        name: option.sortCodeName,
    }));

    // Basic input style
    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
            '& *': {
                boxSizing: 'border-box !important',
            },
        },
    };
    const inputStyle = {
        style: { textAlign: direction === 'ltr' ? 'left' : 'right' },
    };

    // Generic setter
    const handleChange = (field, value) => {
        if (value === null || value === undefined) return;
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    const onDrop = useCallback((acceptedFiles) => {
        setIsDragging(false);

        if (acceptedFiles.length > 0) {
            // Convert to a proper array
            const newDocuments = [...documents, ...acceptedFiles];

            // Ensure setDocuments receives an array
            setDocuments(newDocuments);
            handleDocumentUpload({ target: { files: newDocuments } });
        }
    }, [handleDocumentUpload, documents, setDocuments]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        onDragEnter: () => setIsDragging(true),
        onDragLeave: () => setIsDragging(false),
    });

    // Clear all selected files
    const clearSelectedFiles = () => {
        setDocuments([]);
        handleDocumentUpload({ target: { files: [] } });
    };

    // Remove a single file
    const removeFile = (index) => {
        const updatedFiles = [...documents];
        updatedFiles.splice(index, 1);
        setDocuments(updatedFiles);
        handleDocumentUpload({ target: { files: updatedFiles } });
    };

    // Handle sort-code selection
    const handleSortCodeChange = (value) => {
        if (!value) return;
        const selectedOption = sortCodeIdOptions.find((option) => option.sortCodeId === value);
        if (selectedOption) {
            const nameWithoutParentheses = selectedOption.sortCodeName
                .replace(/\s*\(.*?\)\s*/, '')
                .trim();
            const match = selectedOption.sortCodeName.match(/\((\d+)%?/);
            let customVat = match ? parseInt(match[1], 10) : null;

            const vat = Constants.SortCodesWith66Vat.includes(nameWithoutParentheses)
                ? 66
                : Constants.SortCodesWithZeroVat.includes(nameWithoutParentheses) ||
                    selectedOption.sortCodeVatType === 0
                    ? 0
                    : Constants.SortCodesWith16Vat.includes(nameWithoutParentheses)
                        ? 16
                        : customVat !== null
                            ? customVat === 66
                                ? 66
                                : customVat
                            : 100;

            const updatedData = {
                sortCodeId: selectedOption.sortCodeId,
                vat,
                creditAccount: selectedOption.creditAccount || '',
                debitAccount: selectedOption.debitAccount || '',
            };
            const accountCard = accountCardOptions.find(
                (item) =>
                    item.cardId ===
                    (type === 'Incomes' ? updatedData.debitAccount : updatedData.creditAccount)
            );
            if (accountCard) {
                updatedData.accountCodeName = accountCard.name;
                updatedData.accountCodeId = accountCard.cardId;
                updatedData.accountCaseId = accountCard.accountCaseId;
            } else {
                updatedData.accountCodeName = '';
                updatedData.accountCodeId = '';
                updatedData.accountCaseId = '';
            }
            setFormData((prev) => ({ ...prev, ...updatedData }));
        }
    };

    // Edit existing account card
    const EditAccountCard = (selectedItem, accountType) => {
        if (!selectedItem) {
            console.warn('No account card selected for editing.');
            return;
        }
        setPalCode(selectedItem.palCode);
        setNewAccountCard({
            accountCodeName: selectedItem.name || '',
            accountCodeId: selectedItem.cardId,
            accountCaseId: selectedItem.accountCaseId || '',
        });
        setIsEditAccountCard(true);
        setAddAccountCardType(accountType);
        setIsAddAccountCard(false);
        setEditedAddAccountCard(true);
    };

    // Update account fields in the form
    const updateAccountFields = (newValue, accountType) => {
        if (newValue?.value1 === 'AddItem') {
            handleNoOptionCreditDebitClick('', accountType);
            return;
        }
        const isIncomesDebit = accountType === 'debitAccount';
        const updatedFields = {
            accountCodeId: isIncomesDebit ? (newValue ? newValue.cardId : '') : '',
            accountCodeName: isIncomesDebit ? (newValue ? newValue.name : '') : '',
            accountCaseId: isIncomesDebit ? (newValue ? newValue.accountCaseId : '') : '',
        };

        setFormData((prev) => ({
            ...prev,
            [accountType]: newValue ? newValue.cardId : '',
            ...(isIncomesDebit ? updatedFields : {}),
        }));
    };

    // After user adds new account card
    const handleChangeAfterAdd = (cardId, accountType) => {
        const newCard = { cardId, type: accountType };
        setNewCardId(newCard);
    };
    useEffect(() => {
        if (accountCardOptions && accountCardOptions.length > 0 && newCardId) {
            const selectedOption = accountCardOptions.find(
                (option) => option.cardId === newCardId.cardId
            );
            updateAccountFields(selectedOption, newCardId.type);
            setNewCardId(null);
        }
    }, [accountCardOptions, newCardId]);

    // Journal Entry logic
    const handleCreditDebitForJournalEntry = (newValue, accountType) => {
        if (!newValue) {
            setFormData((prev) => ({ ...prev, [accountType]: '' }));
            return;
        }
        if (newValue.value1 === 'AddItem') {
            setIsAddAccountCard(true);
            setAddAccountCardType(accountType);
            setNewAccountCard({
                accountCodeName: newValue.searchTerm || '',
                accountCodeId: cardIdOption,
            });
            setEditedAddAccountCard(true);
            setIsEditAccountCard(false);
        } else {
            updateAccountFields(newValue, accountType);
        }
    };
    const handleNoOptionCreditDebitClick = (searchTerm, accountType) => {
        const newOption = {
            ...formattedAccountCardOptions[0],
            searchTerm: searchTerm,
            value1: 'AddItem',
        };
        handleCreditDebitForJournalEntry(newOption, accountType);
    };

    // For main account code field
    const handleAccountCodeChange = (selectedOption) => {
        if (!selectedOption) return;
        if (selectedOption?.value1 === 'AddItem') {
            setFormData((prev) => ({
                ...prev,
                accountCodeId: cardIdOption,
                accountCaseId: '',
                accountCodeName: '',
                creditAccount: '',
                debitAccount: '',
            }));
            setAddNewAccountCardDocument(true);
        } else {
            const accountCard = accountCardOptions.find(
                (option) => option.cardId === selectedOption?.value
            );
            setFormData((prev) => ({
                ...prev,
                accountCodeId: selectedOption?.value || '',
                accountCaseId: selectedOption?.accountCaseId || '',
                accountCodeName: accountCard ? accountCard.name : '',
                creditAccount: type !== 'Incomes' ? selectedOption.value : '',
                debitAccount: type === 'Incomes' ? selectedOption.value : '',
            }));
        }
    };
    const handleCloseAddNewAccountCardDocument = () => {
        setFormData((prev) => ({
            ...prev,
            accountCodeId: '',
            accountCaseId: '',
            accountCodeName: '',
            creditAccountOption: '',
            debitAccountOption: '',
        }));
        setAddNewAccountCardDocument(false);
    };

    return (
        <Modal open={modalVisible} onClose={closeCreateModal}>
            <div className="create-modal-overlay">
                <div className="modal-background">
                    <div className={`newDocument-modal-content ${direction}`}>
                        <div className={`modal-content-title ${direction}`}>
                            <h2>{t('newDocument')}</h2>
                            <div className="newDocument-container-button">
                                <button
                                    className={`newDocument-button ${autoFilling ? 'active' : ''}`}
                                    onClick={() => setAutoFilling(true)}
                                >
                                    {t('Automatic Filling')}
                                </button>
                                <button
                                    className={`newDocument-button ${!autoFilling ? 'active' : ''}`}
                                    onClick={() => setAutoFilling(false)}
                                >
                                    {t('Manual Filling')}
                                </button>
                            </div>
                        </div>

                        {/* MANUAL-FILLING FIELDS */}
                        {!autoFilling && (
                            <div className={`newDocument-container-items ${direction}`}>
                                {/* Row 1: SortCode Id / Debit Account */}
                                <div className="form-row">
                                    <div className="edit-form-row-item">
                                        <div className="edit-form-row-item-title">
                                            {t('SortCode Id')}
                                            <span className="required-asterisk">*</span>
                                        </div>
                                        <Autocomplete
                                            options={formattedSortCodeOptions}
                                            getOptionLabel={(option) => option.label}
                                            value={
                                                formattedSortCodeOptions.find(
                                                    (option) => option.value === formData.sortCodeId
                                                ) || null
                                            }
                                            onChange={(e, newValue) =>
                                                handleSortCodeChange(newValue ? newValue.value : '')
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        ...inputPropsStyle,
                                                    }}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        ...inputStyle,
                                                    }}
                                                    placeholder={t("SortCode Id")}
                                                />
                                            )}
                                            PopperComponent={(props) => (
                                                <StyledPopper
                                                    {...props}
                                                    style={{
                                                        ...props.style,
                                                        direction: direction,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="edit-form-row-item">
                                        <div className="edit-form-row-item-title">
                                            {t('Debit Account')}
                                            <span className="required-asterisk">*</span>
                                        </div>
                                        <Autocomplete
                                            options={formattedAccountCardOptions}
                                            getOptionLabel={(option) =>
                                                `${option.cardId} - ${option.name}`
                                            }
                                            value={
                                                formattedAccountCardOptions.find(
                                                    (option) =>
                                                        option.cardId === formData.debitAccount
                                                ) || null
                                            }
                                            onChange={(event, newValue) => {
                                                updateAccountFields(newValue, 'debitAccount');
                                            }}
                                            onInputChange={(event, inputValue) => {
                                                setCreditDebitSearchTerm(inputValue);
                                            }}
                                            noOptionsText={
                                                <Button
                                                    onClick={() =>
                                                        handleNoOptionCreditDebitClick(
                                                            creditDebitSearchTerm,
                                                            'debitAccount'
                                                        )
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    {t('AddAccountCard')} "{creditDebitSearchTerm}"
                                                </Button>
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder={t('Debit Account')}
                                                    variant="standard"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        ...inputPropsStyle,
                                                    }}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        ...inputStyle,
                                                    }}
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <Tooltip
                                                    title={
                                                        option.isEditable
                                                            ? t('RightClickToEdit')
                                                            : t('NotEditable')
                                                    }
                                                    placement="top"
                                                >
                                                    <StyledListItem
                                                        {...props}
                                                        onContextMenu={(event) => {
                                                            if (!option.isEditable) {
                                                                event.preventDefault();
                                                                return;
                                                            }
                                                            event.preventDefault();
                                                            EditAccountCard(option, 'debitAccount');
                                                        }}
                                                    >
                                                        {`${option?.cardId || ''} - ${option.name}`}
                                                    </StyledListItem>
                                                </Tooltip>
                                            )}
                                            PopperComponent={(props) => (
                                                <StyledPopper
                                                    {...props}
                                                    style={{
                                                        ...props.style,
                                                        direction: direction,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>

                                {/* Row 2: Credit Account / Amount */}
                                <div className="form-row">
                                    <div className="edit-form-row-item">
                                        <div className="edit-form-row-item-title">
                                            {t('Credit Account')}
                                            <span className="required-asterisk">*</span>
                                        </div>
                                        <Autocomplete
                                            options={formattedAccountCardOptions}
                                            getOptionLabel={(option) =>
                                                `${option.cardId} - ${option.name}`
                                            }
                                            value={
                                                formattedAccountCardOptions.find(
                                                    (option) =>
                                                        option.cardId === formData.creditAccount
                                                ) || null
                                            }
                                            onChange={(event, newValue) => {
                                                updateAccountFields(newValue, 'creditAccount');
                                            }}
                                            onInputChange={(event, inputValue) => {
                                                setCreditDebitSearchTerm(inputValue);
                                            }}
                                            noOptionsText={
                                                <Button
                                                    onClick={() =>
                                                        handleNoOptionCreditDebitClick(
                                                            creditDebitSearchTerm,
                                                            'creditAccount'
                                                        )
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    {t('AddAccountCard')} "{creditDebitSearchTerm}"
                                                </Button>
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder={t('Credit Account')}
                                                    variant="standard"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        ...inputPropsStyle,
                                                    }}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        ...inputStyle,
                                                    }}
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <Tooltip
                                                    title={
                                                        option.isEditable
                                                            ? t('RightClickToEdit')
                                                            : t('NotEditable')
                                                    }
                                                    placement="top"
                                                >
                                                    <StyledListItem
                                                        {...props}
                                                        onContextMenu={(event) => {
                                                            if (!option.isEditable) {
                                                                event.preventDefault();
                                                                return;
                                                            }
                                                            event.preventDefault();
                                                            EditAccountCard(option, 'creditAccount');
                                                        }}
                                                    >
                                                        {`${option?.cardId || ''} - ${option.name}`}
                                                    </StyledListItem>
                                                </Tooltip>
                                            )}
                                            PopperComponent={(props) => (
                                                <StyledPopper
                                                    {...props}
                                                    style={{
                                                        ...props.style,
                                                        direction: direction,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="edit-form-row-item">
                                        <div className="edit-form-row-item-title">
                                            {t('Amount')}
                                            <span className="required-asterisk">*</span>
                                        </div>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            value={formData.amount || ''}
                                            onChange={(e) => handleChange('amount', e.target.value)}
                                            variant="standard"
                                            InputProps={inputPropsStyle}
                                            inputProps={inputStyle}
                                            placeholder={t('Amount')}
                                        />
                                    </div>
                                </div>

                                {/* Row 3: Reference / Description */}
                                <div className="form-row">
                                    <div className="edit-form-row-item">
                                        <div className="edit-form-row-item-title">
                                            {t('enterReference')}
                                            <span className="required-asterisk">*</span>
                                        </div>
                                        <TextField
                                            fullWidth
                                            value={formData.reference || ''}
                                            onChange={(e) =>
                                                handleChange('reference', e.target.value)
                                            }
                                            variant="standard"
                                            InputProps={inputPropsStyle}
                                            inputProps={inputStyle}
                                            placeholder={t('enterReference')}
                                        />
                                    </div>
                                    <div className="edit-form-row-item">
                                        <div className="edit-form-row-item-title">
                                            {t('enterDescription')}
                                            <span className="required-asterisk">*</span>
                                        </div>
                                        <TextField
                                            fullWidth
                                            value={formData.description || ''}
                                            onChange={(e) =>
                                                handleChange('description', e.target.value)
                                            }
                                            variant="standard"
                                            InputProps={inputPropsStyle}
                                            inputProps={inputStyle}
                                            placeholder={t('enterDescription')}
                                        />
                                    </div>
                                </div>

                                {/* Row 4: Document Date */}
                                <div className="form-row">
                                    <div className="edit-form-row-item">
                                        <div className="edit-form-row-item-title">
                                            {t('Document Date')}
                                            <span className="required-asterisk">*</span>
                                        </div>
                                        <CustomDatePicker
                                            date={
                                                formData.documentDate
                                                    ? new Date(formData.documentDate)
                                                    : null
                                            }
                                            onDateChange={(date) => {
                                                if (date) {
                                                    handleChange('documentDate', date.toISOString());
                                                }
                                            }}
                                            height="48px"
                                        />
                                    </div>
                                    <div className="edit-form-row-item">
                                        <div className="edit-form-row-item-title">
                                            {t('VATPercentage')}
                                            <span className="required-asterisk">*</span>
                                        </div>
                                        <FormControl fullWidth variant="standard">
                                            <Select
                                                fullWidth
                                                value={
                                                    client?.caseType &&
                                                        getVatByCaseType(client.caseType) !== undefined
                                                        ? getVatByCaseType(client.caseType)
                                                        : parseInt(formData.vat) // Use the formData vat
                                                }
                                                onChange={(e) => {
                                                    if (
                                                        client?.caseType &&
                                                        getVatByCaseType(client.caseType) !== undefined
                                                    ) {
                                                        // Prevent change and show toast
                                                        toast.error(`${t('VAT')} ${client.caseType}`, {
                                                            autoClose: 3000,
                                                        });
                                                    } else {
                                                        handleChange('vat', parseInt(e.target.value));
                                                    }
                                                }}
                                                disableUnderline
                                                className="custom-input-box-sizing-toggle"
                                                sx={{
                                                    height: '48px',
                                                    padding: '0 10px',
                                                    borderRadius: '8px',
                                                    background: '#F5F5F6',
                                                    border: '1px solid transparent',
                                                    '&:focus-within': {
                                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                    },
                                                    textAlign: direction === 'ltr' ? 'right' : 'left',
                                                }}
                                                inputProps={{
                                                    style: {
                                                        textAlign: direction === 'ltr' ? 'right' : 'left',
                                                    },
                                                }}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            direction: direction,
                                                        },
                                                    },
                                                }}
                                                required
                                            >
                                                {Constants.VATPercentage.map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option === 16
                                                            ? t('AccountingTax')
                                                            : `${option}%`}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>

                                </div>
                            </div>
                        )}

                        {/* Always-shown (auto or manual) */}
                        <div className="newDocument-autoContainer">
                            {/* VAT changed to a Select-based field */}
                            {autoFilling &&
                                <div className="newDocument-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('VATPercentage')}
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <FormControl fullWidth variant="standard">
                                        <Select
                                            fullWidth
                                            value={
                                                client?.caseType &&
                                                    getVatByCaseType(client.caseType) !== undefined
                                                    ? getVatByCaseType(client.caseType)
                                                    : parseInt(formData.vat) // Use the formData vat
                                            }
                                            onChange={(e) => {
                                                if (
                                                    client?.caseType &&
                                                    getVatByCaseType(client.caseType) !== undefined
                                                ) {
                                                    // Prevent change and show toast
                                                    toast.error(`${t('VAT')} ${client.caseType}`, {
                                                        autoClose: 3000,
                                                    });
                                                } else {
                                                    handleChange('vat', parseInt(e.target.value));
                                                }
                                            }}
                                            disableUnderline
                                            className="custom-input-box-sizing-toggle"
                                            sx={{
                                                height: '48px',
                                                padding: '0 10px',
                                                borderRadius: '8px',
                                                background: '#F5F5F6',
                                                border: '1px solid transparent',
                                                '&:focus-within': {
                                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                },
                                                textAlign: direction === 'ltr' ? 'right' : 'left',
                                            }}
                                            inputProps={{
                                                style: {
                                                    textAlign: direction === 'ltr' ? 'right' : 'left',
                                                },
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        direction: direction,
                                                    },
                                                },
                                            }}
                                            required
                                        >
                                            {Constants.VATPercentage.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option === 16
                                                        ? t('AccountingTax')
                                                        : `${option}%`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            }

                            {/* <div
                                className={`newDocument-file-upload-container ${autoFilling ? 'input-field_Container_required-asterisk' : ''
                                    }`}
                            >
                                <input
                                    type="file"
                                    id="file"
                                    className="input-file"
                                    multiple
                                    onChange={handleDocumentUpload}
                                />
                                <label htmlFor="file" className="newDocument-upload-label">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="upload-icon"
                                        viewBox="0 0 58 58"
                                        fill="none"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M29 38.0625C30.001 38.0625 30.8125 37.251 30.8125 36.25V9.73298L34.874 14.4712C35.5253 15.2313 36.6696 15.3193 37.4296 14.6678C38.1896 14.0164 38.2776 12.8721 37.6261 12.1121L30.3761 3.65378C30.0319 3.25204 29.529 3.02084 29 3.02084C28.471 3.02084 27.9681 3.25204 27.624 3.65378L20.3739 12.1121C19.7224 12.8721 19.8104 14.0164 20.5705 14.6678C21.3305 15.3193 22.4747 15.2313 23.1262 14.4712L27.1875 9.73298V36.25C27.1875 37.251 27.999 38.0625 29 38.0625Z"
                                            fill="#F9AA2A"
                                        />
                                        <path
                                            d="M38.6673 21.75C36.9703 21.75 36.1218 21.75 35.5124 22.1572C35.2485 22.3336 35.0218 22.5602 34.8456 22.8241C34.4381 23.4336 34.4381 24.2822 34.4381 25.9792V36.25C34.4381 39.2529 32.0038 41.6875 29.0006 41.6875C25.9977 41.6875 23.5632 39.2529 23.5632 36.25V25.9792C23.5632 24.2822 23.5632 23.4335 23.1559 22.824C22.9796 22.5601 22.7531 22.3336 22.4893 22.1573C21.8797 21.75 21.0311 21.75 19.334 21.75C12.4986 21.75 9.08094 21.75 6.95746 23.8735C4.83398 25.9971 4.83398 29.4142 4.83398 36.2495V38.6662C4.83398 45.5015 4.83398 48.9191 6.95746 51.0427C9.08094 53.1662 12.4986 53.1662 19.334 53.1662H38.6673C45.5026 53.1662 48.9203 53.1662 51.0438 51.0427C53.1673 48.9191 53.1673 45.5015 53.1673 38.6662V36.2495C53.1673 29.4142 53.1673 25.9971 51.0438 23.8735C48.9203 21.75 45.5026 21.75 38.6673 21.75Z"
                                            fill="#F9AA2A"
                                        />
                                    </svg>
                                    <span className="upload-text">
                                        {documents?.length > 0
                                            ? documents.map((doc) => doc.name).join(', ')
                                            : t('uploadDocument')}
                                    </span>
                                </label>
                            </div> */}
                            <div className="newDocument-container-items">
                                {/* Drag and Drop File Upload */}
                                <div
                                    className={`newDocument-file-upload-container ${autoFilling ? 'input-field_Container_required-asterisk' : ''}`}
                                    {...getRootProps()}
                                >
                                    <DropZoneContainer isDragActive={isDragActive}>
                                        <input {...getInputProps()} />

                                        {/* Show message when dragging */}
                                        {isDragging && (
                                            <DragOverlayText style={{ display: 'block' }}>
                                                {t('DropFilesHere')}
                                            </DragOverlayText>
                                        )}

                                        {!isDragging && (
                                            <label className="newDocument-upload-label">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="upload-icon"
                                                    viewBox="0 0 58 58"
                                                    fill="none"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M29 38.0625C30.001 38.0625 30.8125 37.251 30.8125 36.25V9.73298L34.874 14.4712C35.5253 15.2313 36.6696 15.3193 37.4296 14.6678C38.1896 14.0164 38.2776 12.8721 37.6261 12.1121L30.3761 3.65378C30.0319 3.25204 29.529 3.02084 29 3.02084C28.471 3.02084 27.9681 3.25204 27.624 3.65378L20.3739 12.1121C19.7224 12.8721 19.8104 14.0164 20.5705 14.6678C21.3305 15.3193 22.4747 15.2313 23.1262 14.4712L27.1875 9.73298V36.25C27.1875 37.251 27.999 38.0625 29 38.0625Z"
                                                        fill="#F9AA2A"
                                                    />
                                                </svg>
                                                <span className="upload-text">
                                                    {documents?.length > 0
                                                        ? documents.map((doc) => doc.name).join(', ')
                                                        : t('DragDropFilesOrClick')}
                                                </span>
                                            </label>
                                        )}
                                    </DropZoneContainer>
                                </div>

                                {/* Display selected files with a remove button */}
                                {documents.length > 0 && (
                                    <FileList>
                                        {documents.map((doc, index) => (
                                            <FileItem key={index}>
                                                <span>{doc.name}</span>
                                                <IconButton onClick={() => removeFile(index)} size="small">
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            </FileItem>
                                        ))}
                                        <Button
                                            onClick={clearSelectedFiles}
                                            color="secondary"
                                            size="small"
                                            startIcon={<ClearIcon />}
                                        >
                                            {t('ClearAll')}
                                        </Button>
                                    </FileList>
                                )}
                            </div>

                        </div>

                        <div className="newDocument-button-container">
                            <div
                                className={`edit-save-button ${!allFieldsFilled ? 'disabled' : ''
                                    }`}
                                onClick={allFieldsFilled ? handleSave : null}
                            >
                                {t('save')}
                            </div>
                            <div className="edit-cancel-button" onClick={closeCreateModal}>
                                {t('close')}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Add Account Card Modal */}
                <AddAccountCardModal
                    accountCardOptions={accountCardOptions}
                    open={editedAddAccountCard}
                    onClose={() => setEditedAddAccountCard(false)}
                    editedItem={newAccountCard}
                    isEdit={isEditAccountCard}
                    direction={direction}
                    client={client}
                    token={token}
                    setLoading={setLoading}
                    handleChangeAfterAdd={handleChangeAfterAdd}
                    fetchEnums={fetchEnums}
                    type={addAccountCardType}
                    palCodes={palCodes}
                    propPalCode={palCode}
                    setPalCode={setPalCode}
                />
            </div>
        </Modal>
    );
};

export default CreateDocumentModal;
