// AssetDepreciationReportHTML.js
import { Button, styled } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import PythonService from "../../../Services/pythonScripts.service";
import { useTranslation } from 'react-i18next';
import Draggable from 'react-draggable';
import CustomYearDatePicker from "../../Widgets/CustomDates/CustomYearDatePicker/CustomYearDatePicker";
import CustomMonthYearIndividualDatePicker from "../../Widgets/CustomDates/CustomMonthYearIndividualDatePicker/CustomMonthYearIndividualDatePicker";
import dayjs, { Dayjs } from 'dayjs';
import CancelIcon from '@mui/icons-material/Cancel';



const StyledReportContainer = styled("div")({
    position: "absolute",
    top: "20%",
    left: "20%",
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "16px",
    minWidth: "700px",
    overflowY: "auto",
    maxHeight: "70vh",
    zIndex: 200,  // ensures it floats above other elements
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
    border: "1px solid #304FFF",
    display: "flex",
    flexDirection: "column",
});

const handleStyle = {
    cursor: "move",
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
};

export function generateDepreciationReportHtml(depreciationDetails, client, date) {
    if (!depreciationDetails || !depreciationDetails.assetGroups) {
        return "<p>No depreciation data available</p>";
    }

    // Format the date as dd/M/yyyy (e.g., 31/3/2025)
    let formattedTaxYear = date;
    try {
        if (date) {
            const d = new Date(date);
            if (!isNaN(d.getTime())) {
                const lastDayOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0);
                formattedTaxYear = `${lastDayOfMonth.getDate()}/${lastDayOfMonth.getMonth() + 1}/${lastDayOfMonth.getFullYear()}`;
            }
        }
        
    } catch (error) {
        formattedTaxYear = date;
    }

    // Initialize cumulative totals
    let totalOriginalPrice = 0;
    let totalChangeThisYear = 0;
    let totalDepreciableAssets = 0;
    let totalDepreciationUntilNow = 0;
    let totalDepreciationThisYear = 0;
    let totalReducedCost = 0;

    let content = `
    <!DOCTYPE html>
    <html lang="he">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
        
            @page {
                size: A4;
                margin: 20mm;
            }
            @media print {
                /* Make all text smaller when printing (70% of normal) */
                body, table, th, td, .main-title, .subtitle, .header-info, .header-left, .header-right {
                    font-size: 85% !important; /* Adjust as needed */
                }
                .pageStyle {
                    page-break-after: always;
                    page-break-inside: avoid;
                }
                html, body {
                    width: 210mm;
                    height: 297mm;
                    margin: 0;
                    padding: 0;
                }
                table, thead, tbody, tr, th, td {
                    page-break-inside: avoid;
                }
            }
                html, body {
                    margin: 10px 0 0 0 !important;
                    padding: 0 !important;
                    width: 100%;
                    height: 100%; 
                    }

            body {
                font-family: Arial, sans-serif;
                direction: rtl;
                text-align: right;
                margin: 0;
                padding: 16px;
                background-color: #f8f8f8;
            }
            
            .pageStyle {
                margin: 0 auto 20px auto;
                padding: 20px;
                width: 210mm;
            }

            /* TABLE STYLING */
            table {
                width: 100%;
                border-collapse: collapse;
                margin-top: 20px;
            }
            th, td {
                border: none;
                padding: 8px;
                text-align: right; /* Right-align cells */
                vertical-align: middle;
            }
            th {
                font-weight: bold;           
                text-decoration: underline;  
            }
            td {
                font-size: 0.9em; 
            }

            .bold-underlined {
                font-weight: bold;
                text-decoration: underline;
            }

            .hoverTr {
                transition: transform 0.2s ease, box-shadow 0.1s ease;
                cursor: pointer;
            }
            .hoverTr:hover {
                transform: translateY(-2px);
                background-color: #ffffff; 
                box-shadow: 0 4px 6px rgba(0,0,0,0.1);
            }

            .header-info {
                width: 50%;
                margin: 0 auto 20px auto;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                line-height: 1.5;
            }
            .header-left,
            .header-right {
                display: flex;
                flex-direction: column;
                width: 48%;
            }
            .report-header {
                margin-bottom: 20px;
            }
            .header-top {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                font-weight: bold;
                margin-bottom: 4px;
            }
            .main-title {
                font-size: 1.3em;
                margin: 8px 0 4px 0;
            }
            .main-title,
            .subtitle {
                text-align: center;
                color: black;
            }

            /* Make first column a bit wider (adjust if needed) */
            th:first-child {
                width: 15%;
            }
            td:first-child {
                width: 15%;
            }
        </style>
    </head>
    <body>
        <div class="header-info">
            <div class="header-left">
                <div>שם העסק : ${client.name}</div>
                <div>פקיד שומה : ${client.taxAssessor}</div>
                <div>שנת מס : ${formattedTaxYear}</div>
            </div>
            <div class="header-right">
                <div>מספר זהות: ${client.clientId}</div>
                <div>מספר תיק: ${client.caseId}</div>
            </div>
        </div>

        <div class="report-header">
            <div class="header-top">
                <span>מדינת ישראל/האוצר, אגף מס הכנסה ומס רכוש</span>
                <span>טופס 1342 (יא)</span>
            </div>
            <hr />
            <h2 class="main-title">תקנות מס הכנסה (פחת) לשנה שנסתיימה ב־/${formattedTaxYear}</h2>
            <p class="subtitle">
                פרטים על נכסים שניתן לגבם פחת (חוק התיאומים סעיף 3, סעיף 21 וכו')
            </p>
        </div>

        <table>
            <thead>
                <tr>
                    <th>רשימת הנכסים ותאורם</th>
                    <th>תאריך רכישה/שינוי/הפעלה</th>
                    <th>מחיר מקורי</th>
                    <th>שינוי השנה</th>
                    <th>נכסים בני פחת</th>
                    <th>אחוז פחת% נתבע השנה</th>
                    <th>פחת שנצבר</th>
                    <th>פחת השנה</th>
                    <th>עלות מופחתת</th>
                    <th>הערות (ראה מפתח בדף אחרון)</th>
                </tr>
            </thead>
            <tbody>
    `;

    // ============ RENDER GROUPS AND ASSETS ============
    depreciationDetails.assetGroups.forEach((group) => {
        const encodedDataGroup = window.btoa(unescape(encodeURIComponent(JSON.stringify(group))));


        // Check if groupId/groupName is missing
        const missingGroupInfo = !group.assetGroupId || !group.assetGroupName;
        let secondAndThirdCols;
        if (missingGroupInfo) {
            secondAndThirdCols = `
              <td style="font-weight:bold; text-decoration: underline;" colspan="2">
                פריטים ללא קבוצה
              </td>
            `;
        } else {
            secondAndThirdCols = `
              <td style="font-weight:bold; text-decoration: underline;">${group.assetGroupId}</td>
              <td style="font-weight:bold; text-decoration: underline;">${group.assetGroupName}</td>
            `;
        }

        // ROW for the group title
        content += `
          <tr class="hoverTr" 
           
            <td style="font-weight:bold; text-decoration: underline;">קבוצה</td>
            ${secondAndThirdCols}
            <!-- Fill columns to match total (10) if needed -->
            <td colspan="7"></td>
          </tr>
        `;

        // Initialize sums for the group
        let sumOriginalPrice = 0;
        let sumChangeThisYear = 0;
        let sumDepreciableAssets = 0;
        let sumDepreciationUntilNow = 0;
        let sumDepreciationThisYear = 0;
        let sumReducedCost = 0;

        // RENDER EACH ASSET ROW
        group.assets.forEach((asset) => {
            const encodedData = window.btoa(unescape(encodeURIComponent(JSON.stringify(asset))));

            // Add to group sums for numeric columns
            const originalPrice = asset.asset.originalPrice || 0;
            const changeThisYear = asset.changeThisYear || 0;
            const depreciableAssets = asset.asset.originalPrice || 0;  // or asset.depreciableAssets, depends on your real data
            const depreciationUntilNow = asset.depreciationUntilNow || 0;
            const depreciationThisYear = asset.depreciationThisYear || 0;
            const reducedCost = asset.reducedCost || 0;

            sumOriginalPrice += originalPrice;
            sumChangeThisYear += changeThisYear;
            sumDepreciableAssets += depreciableAssets;
            sumDepreciationUntilNow += depreciationUntilNow;
            sumDepreciationThisYear += depreciationThisYear;
            sumReducedCost += reducedCost;

            // Update cumulative totals
            totalOriginalPrice += originalPrice;
            totalChangeThisYear += changeThisYear;
            totalDepreciableAssets += depreciableAssets;
            totalDepreciationUntilNow += depreciationUntilNow;
            totalDepreciationThisYear += depreciationThisYear;
            totalReducedCost += reducedCost;

            // Render the asset row
            content += `
                <tr class="hoverTr" 
                    onclick="window.parent.dispatchEvent(new CustomEvent('rowClick', { detail: '${encodedData}' }))">
                    <td>${asset.asset.assetNumber || "—"} - ${asset.asset.name || "—"}</td>
                    <td>${asset.asset.activationDate
                    ? new Date(asset.asset.activationDate).toLocaleDateString("he-IL")
                    : "—"
                }</td>
                    <td>${originalPrice.toLocaleString()}</td>
                    <td>${changeThisYear.toLocaleString()}</td>
                    <td>${depreciableAssets.toLocaleString()}</td>
                    <td>${asset.depreciationPercentage || 0}%</td>
                    <td>${depreciationUntilNow.toLocaleString()}</td>
                    <td>${depreciationThisYear.toLocaleString()}</td>
                    <td>${reducedCost.toLocaleString()}</td>
                    <td>${asset.asset.description || ""}</td>
                </tr>
            `;
        });

        // ============ SUM ROW FOR THIS GROUP ============

        // We create a row that has a heavy top border, and columns aligned
        // to match the headings. Here, we put “סה״כ לקבוצה” in the first column, 
        // and fill numeric totals in the matching columns. 
        // For percentage columns, we’ll just put an empty cell.

        content += `
          <tr style="border-top: 2px solid black; font-weight:bold;">
            <!-- First two columns for labeling -->
            <td colspan="1">סה"כ לקבוצה</td>
            <td colspan="1">${group.assetGroupName ? group.assetGroupName : ''}</td>

            <!-- Then our numeric columns: -->
            <td>${sumOriginalPrice.toLocaleString()}</td>
            <td>${sumChangeThisYear.toLocaleString()}</td>
            <td>${sumDepreciableAssets.toLocaleString()}</td>
            <!-- The percentage column: leave it blank or put "-" -->
            <td>-</td>
            <td>${sumDepreciationUntilNow.toLocaleString()}</td>
            <td>${sumDepreciationThisYear.toLocaleString()}</td>
            <td>${sumReducedCost.toLocaleString()}</td>
            <!-- Last column is "הערות" - we can leave it empty -->
            <td></td>
          </tr>
        `;
    });

    // ============ FINAL TOTAL ROW ============

    content += `
          <tr style="border-top: 2px solid black; font-weight:bold;">
            <!-- First two columns for labeling -->
            <td colspan="1">סה"כ הכל</td>
            <td colspan="1"></td>

            <!-- Then our numeric columns: -->
            <td>${totalOriginalPrice.toLocaleString()}</td>
            <td>${totalChangeThisYear.toLocaleString()}</td>
            <td>${totalDepreciableAssets.toLocaleString()}</td>
            <!-- The percentage column: leave it blank or put "-" -->
            <td>-</td>
            <td>${totalDepreciationUntilNow.toLocaleString()}</td>
            <td>${totalDepreciationThisYear.toLocaleString()}</td>
            <td>${totalReducedCost.toLocaleString()}</td>
            <!-- Last column is "הערות" - we can leave it empty -->
            <td></td>
          </tr>
        `;

    // Close the table and document
    content += `
            </tbody>
        </table>
    </body>
    </html>`;

    return content;
}


/** This component uses the above function and displays the HTML **/
const AssetDepreciationReportHTML = ({ open, depreciationContent, client, date, token, onRowClick, onClose, reportDate, handleEndDateChangeNew, setReportDate }) => {
    const [reportHtmlContent, setReportHtmlContent] = useState("");
    const nodeRef = useRef(null);
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();


    useEffect(() => {
        

        if (depreciationContent) {
            // If we have data, generate the HTML
            setReportHtmlContent(generateDepreciationReportHtml(depreciationContent, client, date));
        } else {
            setReportHtmlContent("<p>No depreciation data available</p>");
        }
    }, [depreciationContent]);

    //for handling a row click
    useEffect(() => {
        const handleRowClick = (event) => {
            try {
                // event.detail should be base64-encoded JSON
                const encodedData = event.detail;
                const decoded = atob(encodedData);   // decode base64
                const parsed = JSON.parse(decoded);  // parse JSON

                // If it's a group row, maybe there's an 'assetGroupId'
                // If it's an asset row, maybe there's 'asset.id'
                // Adjust these to your data structure
                if (parsed.asset) {
                    const Id = parsed.asset.id
                    onRowClick(Id, 'asset');
                } else if (parsed.assetGroupId) {
                    onRowClick(parsed.assetGroupId, 'assetGroup');
                }
            } catch (error) {
                console.error("Error decoding rowClick detail:", error);
            }
        };

        // Attach once
        window.addEventListener("rowClick", handleRowClick);
        // Cleanup on unmount
        return () => {
            window.removeEventListener("rowClick", handleRowClick);
        };
    }, []);




    const handlePrint = () => {
        const printWindow = window.open("", "_blank");
        printWindow.document.write(reportHtmlContent);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };

    const handleDownloadPdf = async () => {
        try {
            // OPTIONAL: remove box shadows or other styles if you don't want them in PDF
            let pdfContent = reportHtmlContent;
            pdfContent = pdfContent.replace(/box-shadow: 0.*?;/g, "");
            pdfContent = pdfContent.replace(/border: 1px solid #ccc;/g, "");

            // Example call to your PythonService
            const response = await PythonService.convertHtmlToPdf(pdfContent, token);
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                // name your file
                link.setAttribute("download", `דו"ח פחת - ${client.name}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                console.error("Failed to convert HTML to PDF, status:", response.status);
            }
        } catch (err) {
            console.error("Error converting to PDF:", err);
        }
    };

    if (!open) return null;

    return (
        <Draggable handle=".report-handle" nodeRef={nodeRef}>
            <StyledReportContainer ref={nodeRef}>
                {/* Header bar as drag handle */}
                <div className="report-handle" style={handleStyle}>
                    <div>{t("DepreciationFormYA")}</div>
                    <button
                        onClick={onClose}
                        style={{
                            background: "transparent",
                            border: "none",
                            fontSize: "16px",
                            cursor: "pointer",
                            color: "#304FFF",
                        }}
                    >
                        <CancelIcon style={{ color: '#304FFF' }} />
                    </button>
                </div>

                {/* Action buttons */}
                <div
                    className="title-back-container"
                    style={{ gap: 10, justifyContent: "center", marginBottom: 16 }}
                >
                    <button onClick={handleDownloadPdf} className="create-Invoice-selector">
                        <span>{t("DownloadPDFFile")}</span>
                        <DownloadIcon fontSize="small" />
                    </button>

                    <button onClick={handlePrint} className="create-Invoice-selector">
                        <span>{t("DownloadExcelFile")}</span>
                        <PrintIcon fontSize="small" />
                    </button>
                    <div style={{ width: '250px' }}>
                        <CustomMonthYearIndividualDatePicker
                            year={dayjs(reportDate)}
                            month={dayjs(reportDate)}
                            onYearChange={(newYear) => {
                                const updatedDate = dayjs(reportDate).year(dayjs(newYear).year());
                                setReportDate(updatedDate);
                                handleEndDateChangeNew && handleEndDateChangeNew(updatedDate);
                            }}
                            onMonthChange={(newMonth) => {
                                const updatedDate = dayjs(reportDate).month(dayjs(newMonth).month());
                                setReportDate(updatedDate);
                                handleEndDateChangeNew && handleEndDateChangeNew(updatedDate);
                            }}
                            labelYear={t("Year")}
                            labelMonth={t("Month")}
                        />
                    </div>
                </div>

                {/* The HTML content */}
                <div dangerouslySetInnerHTML={{ __html: reportHtmlContent }} />
            </StyledReportContainer>
        </Draggable>
    );
};

export default AssetDepreciationReportHTML;
