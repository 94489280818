import React, { useState, useRef } from 'react';
import Draggable from 'react-draggable';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, IconButton, Tooltip, TablePagination
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DescriptionIcon from '@mui/icons-material/Description';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import CustomMonthYearIndividualDatePicker from '../../Widgets/CustomDates/CustomMonthYearIndividualDatePicker/CustomMonthYearIndividualDatePicker';

const SmallTransactionViewer = ({
    transactions = [],
    open = false,
    onClose = () => { },
    viewDocument,
    handleAssignDocument,
    assets,
    startDate,
    endDate,
    setStartDate,
    setEndDate
}) => {
    const { t } = useTranslation();
    const nodeRef = useRef(null);

    // Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (_, newPage) => setPage(newPage);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Handle Date Change
    const handleStartDateChangeNew = (newValue, type) => {
        setStartDate(newValue, type);
    };

    const handleEndDateChangeNew = (newValue, type) => {
        setEndDate(newValue, type);
    };

    if (!open) return null;

    return (
        <Draggable handle=".drag-handle" nodeRef={nodeRef}>
            <div
                ref={nodeRef}
                style={{
                    position: 'absolute',
                    top: '15%',
                    left: '30%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    padding: '16px',
                    minWidth: '800px',
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    zIndex: 1300,
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
                    border: '1px solid #304FFF'
                }}
            >
                {/* Header */}
                <div
                    className="drag-handle"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        cursor: 'move',
                        marginBottom: 8
                    }}
                >
                    <Tooltip title={t('Close')}>
                        <IconButton onClick={onClose}>
                            <CancelIcon style={{ color: '#304FFF' }} />
                        </IconButton>
                    </Tooltip>
                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                        {t('EquipmentPurchaseTransactions')}
                    </div>
                </div>

                {/* Date Pickers */}
                <div style={{ display: 'flex', justifyContent: 'center', gap: '16px', marginBottom: '16px' }}>
                    <CustomMonthYearIndividualDatePicker
                        year={dayjs(endDate)}
                        month={dayjs(endDate)}
                        onYearChange={(newYear) => handleEndDateChangeNew(newYear, 'year')}
                        onMonthChange={(newMonth) => handleEndDateChangeNew(newMonth, 'month')}
                        labelYear={t("EndYear")}
                        labelMonth={t("EndMonth")}
                        height="48px"
                        widthOuter='270px'
                        widthInner='120px'
                    />
                    <div
                        style={{
                            borderLeft: '1px solid #ccc',  // color/style of the divider
                            height: '48px',                // match the height of the date picker
                        }}
                    ></div>
                    <CustomMonthYearIndividualDatePicker
                        year={dayjs(startDate)}
                        month={dayjs(startDate)}
                        onYearChange={(newYear) => handleStartDateChangeNew(newYear, 'year')}
                        onMonthChange={(newMonth) => handleStartDateChangeNew(newMonth, 'month')}
                        labelYear={t("StartYear")}
                        labelMonth={t("StartMonth")}
                        height="48px"
                        widthOuter='270px'
                        widthInner='120px'
                    />
                </div>

                {/* Table */}
                <TableContainer component={Paper} style={{ maxHeight: '300px' }}>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>{t('Description')}</TableCell>
                                <TableCell>{t('Reference')}</TableCell>
                                <TableCell>{t('Amount')}</TableCell>
                                <TableCell>{t('documentDate')}</TableCell>
                                <TableCell>{t('Type')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactions.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((tx) => {
                                const assignedAsset = assets.find(asset => asset.expenseId === tx.id || asset.journalEntryId === tx.id);

                                return (
                                    <Tooltip
                                        key={tx.id}
                                        title={assignedAsset ? `${t('TransactionIsAssignedToAsset')}: ${assignedAsset.name}` : ''}
                                        arrow
                                    >
                                        <TableRow
                                            hover
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: assignedAsset ? '#D4EDDA' : 'inherit', // Green background if assigned
                                            }}
                                            onClick={() => handleAssignDocument(tx)}
                                        >
                                            <TableCell align="center">
                                                <Tooltip title={t('ViewDocument')}>
                                                    <IconButton
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            viewDocument(tx.id, tx.type);
                                                        }}
                                                    >
                                                        <DescriptionIcon style={{ color: '#304FFF' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>

                                            <TableCell align="center">{tx.description || '-'}</TableCell>
                                            <TableCell align="center">{tx.reference || '-'}</TableCell>
                                            <TableCell align="center">{tx.amount || 0}</TableCell>
                                            <TableCell align="center">
                                                {tx.documentDate ? new Date(tx.documentDate).toLocaleDateString() : '-'}
                                            </TableCell>
                                            <TableCell align="center">
                                                {tx.type === 1 ? t('Expense') : t('JournalEntry')}
                                            </TableCell>
                                        </TableRow>
                                    </Tooltip>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Pagination */}
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    count={transactions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('RowsPerPage')}
                />
            </div>
        </Draggable>
    );
};

export default SmallTransactionViewer;
