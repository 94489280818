// PermissionsContext.js
import React, { createContext, useState, useEffect } from 'react';
import UserService from '../Services/user.service.js';
import PermissionService from '../Services/permission.service.js';
import Constants from '../assests/Constants/constants.js';
import { useNavigate } from 'react-router-dom';
import AuthService from '../Services/auth.service.js';
export const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState([]);
  const [permissionsState, setPermissionsState] = useState({
    CannotAddClient: false,
    ViewSubAccountantClients: false,
    Premium:false,
  });
  const [loading, setLoading] = useState(true); // To track loading state
  const [user, setUser] = useState(null); // To store user data
  const [userTypeContext, setUserTypeContext] = useState(null); // To store user data
  const [token, setToken] = useState(null); // To store token
  const [isAccountant, setIsAccountant] = useState(false); // To store user type
  const navigate = useNavigate(); 

  // Function to reset permissions state
  const resetPermissionsState = () => {
    setPermissionsState({
      CannotAddClient: false,
      ViewSubAccountantClients: false,
      Premium:false,
    });
    setUser(null);
    setToken(null);
    setIsAccountant(false);
  };


  useEffect(() => {
    
    const fetchPermissions = async () => {
      try {
        const user = await UserService.getCurrentUser();
        const token = await UserService.getCurrentToken();
        const userType = await UserService.getCurrentUserType();

        if (user && token) {

          const userId = await AuthService.getCurrentUserTypeId();
          const userPermissonType = await AuthService.getCurrentUserPermissonType();
          const isAccountant = userType.toString() === '2'; // Adjust userType logic if needed
          setIsAccountant(isAccountant);
          
          const permissionsData = await PermissionService.GetPermissions(
            userId,
            userPermissonType,
            token
          );
          
          // Extract the names from the permission objects
          const permissionsNames = permissionsData.map(item => item.permission.name);

          // Set the permissions
          setPermissions(permissionsNames || []);

          // Initialize permissionsState
          const updatedPermissionsState = {};
          permissionsNames.forEach(permission => {
            updatedPermissionsState[permission] = true;
          });
          setPermissionsState(updatedPermissionsState);

          // Permissions fetched, set loading to false

          setUserTypeContext(userType);
          setUser(user);
          setToken(token);
          setLoading(false);

        } else {
          // Handle the case when user or token is not available
          navigate('/login');
        }
      } catch (error) {
        console.error("Error fetching permissions:", error);
        setLoading(false);
        navigate('/login');
      }
    };

    fetchPermissions();
  }, [navigate]);

  return (
    <PermissionsContext.Provider value={{
      permissions,
      permissionsState,
      loading,
      user,
      userTypeContext,
      token,
      isAccountant,
      setUser,
      resetPermissionsState
    }}>
      {children}
    </PermissionsContext.Provider>
  );
};
