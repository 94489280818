import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';

// Example placeholders
import ChatInputBar from '../SubComponents/ChatInputBar';   // For add mode
import PreviewModal from '../SubComponents/PreviewModal';   // For edit mode's "preview attachments"
import ScheduledMessagesService from '../../../Services/ScheduledMessages.service';
import AuthService from '../../../Services/auth.service';
import ChatApiService from '../../../Services/chat.service'; // If you use a ChatApi
import Select from "react-select";
import { useTranslation } from 'react-i18next';
import Constants from '../../../assests/Constants/constants';
import './ScheduledFormModal.css';

import {
    Box,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    MenuItem,
    InputLabel,
    Checkbox,
    TextField,
} from '@mui/material';
import { Margin } from '@mui/icons-material';

const formatToIsraelTime = (date) => {
    return moment(date).tz("Asia/Jerusalem").format("YYYY-MM-DDTHH:mm");
};

const toUTC = (date) => {
    return moment.tz(date, "Asia/Jerusalem").utc().toISOString();
};

const customSelectStyles = {
    control: (base, state) => ({
        ...base,
        height: "48px",
        padding: "0 10px",
        borderRadius: "8px",
        background: "#F5F5F6",
        border: state.isFocused
            ? "2px solid var(--Foundation-Blue-Normal, #304FFF)"
            : "1px solid transparent",
        boxShadow: state.isFocused ? "0 0 4px rgba(48, 79, 255, 0.3)" : "none",
        "&:hover": {
            borderColor: state.isFocused
                ? "var(--Foundation-Blue-Normal, #304FFF)"
                : "#D6D6D6",
        },
    }),
    menu: (base) => ({
        ...base,
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        zIndex: 9999, // Ensure it appears above modal
    }),
    option: (base, state) => ({
        ...base,
        padding: "10px",
        background: state.isFocused ? "#E6E6F9" : "#FFFFFF",
        color: "#000",
        "&:active": {
            background: "var(--Foundation-Blue-Normal, #304FFF)",
            color: "#FFF",
        },
    }),
};


// 1) Helper to get file extension from either local file or URI
function getFileExtension(fObj) {
    let fileName;

    if (fObj.file) {
        // Local File or Blob
        fileName = fObj.file.name;
    } else if (fObj.name) {
        // Remote URL
        // Decode the URL to handle special characters, then extract file name
        fileName = decodeURIComponent(fObj.name).split('?')[0];
    } else if (fObj.uri) {
        // In case the name property isn't set, fallback to uri
        fileName = decodeURIComponent(fObj.uri).split('?')[0].split('/').pop();
    }

    if (!fileName) return ''; // Fallback
    return fileName.split('.').pop().toLowerCase();
}

// 2) Reusable Preview Component
function renderFilePreview(fObj) {
    const ext = getFileExtension(fObj);
    const src = fObj.file ? URL.createObjectURL(fObj.file) : fObj.uri;

    // Identify file type by extension (or you can rely on fObj.type)
    const isImage = /(jpg|jpeg|png|gif|bmp|svg|webp)$/i.test(ext);
    const isPdf = ext === 'pdf';
    const isAudio = /(mp3|wav|ogg|m4a|mp4)$/i.test(ext);
    const isVideo = /(mp4|webm)$/i.test(ext);

    if (isImage) {
        // IMAGE
        return (
            <img
                src={src}
                alt={fObj.name}
                className="attachment-image"
            />
        );
    } else if (isPdf) {
        // PDF
        return (
            <iframe
                src={src}
                title={fObj.name}
                className="attachment-pdf"
            />
        );
    } else if (isAudio) {
        // AUDIO
        return (
            <audio controls src={src} className="attachment-audio">
                Your browser does not support the <code>audio</code> element.
            </audio>
        );
    } else if (isVideo) {
        // VIDEO
        return (
            <video controls src={src} className="attachment-video">
                Your browser does not support the <code>video</code> element.
            </video>
        );
    } else {
        // FALLBACK: Link to download/view in new tab
        return (
            <div className="attachment-file">
                <a
                    href={src}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="file-link"
                >
                    {fObj.name}
                </a>
            </div>
        );
    }
}


// Scheduling
const REPEAT_OPTIONS = [
    { label: 'None', value: 0 },
    { label: 'Daily', value: 1 },
    { label: 'Weekly', value: 2 },
    { label: 'Monthly', value: 3 },
];

const WEEKDAYS = [
    { label: 'Sunday', value: 0 },
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 },
    { label: 'Saturday', value: 6 },
];


const DAY_OPTIONS = Array.from({ length: 31 }, (_, i) => ({
    value: i + 1,
    label: i + 1,
}));

const REPEAT_COUNT_OPTIONS = Array.from({ length: 12 }, (_, i) => ({
    value: i + 1,
    label: i + 1,
}));
// Utility to format date for UI messages
function formatMessageDate(dateOrString) {
    return moment(dateOrString).tz('Asia/Jerusalem').format('DD/MM HH:mm');
}

export default function ScheduledFormModal({
    visible,
    onClose,
    label,              // Label with { labelId, labelName }
    scheduledItem,      // If editing
    onRefresh,
    userId,
    userType,

    // true => new schedule; false => editing existing
    isAddSchedule = false,
}) {
    /* ----------------- Chat-like local state (for Add) ----------------- */

    /* ----------------- Scheduling states (both modes) ----------------- */
    const [messageContent, setMessageContent] = useState('');
    const [messageType, setMessageType] = useState(0);
    const [fileKeysJson, setFileKeysJson] = useState(null);

    const [repeatInterval, setRepeatInterval] = useState(0);
    const [repeatCount, setRepeatCount] = useState(1);
    const [dayOfMonth, setDayOfMonth] = useState(null);
    const [dayOfWeek, setDayOfWeek] = useState(null);
    const [isActive, setIsActive] = useState(true);
    const [localDate, setLocalDate] = useState(() => formatToIsraelTime(new Date().toISOString()));
    const [chosenDate, setChosenDate] = useState(new Date());

    const { t, i18n } = useTranslation();
    const direction = i18n.dir();

    const WEEKDAYS_OPTIONS = WEEKDAYS.map((wd) => ({
        value: wd.value,
        label: t(wd.label),
    }));
    /* ----------------- Add or Edit Logic ----------------- */
    useEffect(() => {
        if (scheduledItem && !isAddSchedule) {
            // EDIT MODE
            setMessageContent(scheduledItem.messageContent || "");
            setMessageType(scheduledItem.messageType || 0);
            setRepeatInterval(scheduledItem.repeatInterval ?? 0);
            setRepeatCount(scheduledItem.repeatCount ?? 1);
            setDayOfMonth(scheduledItem.dayOfMonth ?? null);
            setDayOfWeek(scheduledItem.dayOfWeek ?? null);
            setIsActive(scheduledItem.isActive !== false);

            // Convert array of URLs to file objects for parsedAttachments
            if (Array.isArray(scheduledItem.fileKeys)) {
                const parsedFiles = scheduledItem.fileKeys.map((url) => ({
                    uri: url,
                    name: url.split("/").pop() || "file",
                    type: url.endsWith(".pdf") ? "application/pdf" : "application/octet-stream", // Detect type if needed
                    file: null, // No file blob available, URL is used for preview
                }));
                setParsedAttachments(parsedFiles);
            } else {
                setParsedAttachments([]);
            }

            // If we have nextRunTime in UTC from server, convert to local Israel time for display
            if (scheduledItem.nextRunTime) {
                const utcString = scheduledItem.nextRunTime; // e.g. "2025-01-06T13:00:00.000Z"
                // localDate: display in Israel time
                setLocalDate(formatToIsraelTime(utcString));
                // chosenDate: keep as UTC
                setChosenDate(utcString);
            } else {
                // Fallback if no nextRunTime
                const nowUTC = new Date().toISOString();
                setLocalDate(formatToIsraelTime(nowUTC));
                setChosenDate(nowUTC);
            }

            // Reset recording-related states
            setEditIsRecording(false);
            setEditRecorder(null);
            editAudioChunksRef.current = [];
        } else {
            // ADD MODE
            resetFields();
        }
    }, [scheduledItem, isAddSchedule, visible]);

    function resetFields() {
        setMessageContent("");
        setMessageType(0);
        setFileKeysJson(null);
        setRepeatInterval(0);
        setRepeatCount(1);
        setDayOfMonth(null);
        setDayOfWeek(null);
        setIsActive(true);

        const nowUTC = new Date().toISOString();
        setLocalDate(formatToIsraelTime(nowUTC));
        setChosenDate(nowUTC);
    }

    /* =====================================================
     * Recording Logic for Add Mode (ChatInputBar)
     * =====================================================*/
    const [isRecording, setIsRecording] = useState(false);
    const [recorder, setRecorder] = useState(null);
    const audioChunksRef = useRef([]);

    // For demonstration, if you have a "chatService":
    const chatService = useRef(null);
    const targetUserIdRef = useRef(null);
    const targetUserTypeRef = useRef(null);

    // ---------- handleRecordingStart (Add Mode) ----------
    const handleRecordingStart = async () => {
        try {
            if (isRecording) {
                alert("Recording is already in progress.");
                return;
            }
            if (!navigator.mediaDevices?.getUserMedia) {
                alert("Microphone access is not supported in your browser.");
                return;
            }

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mediaRecorder = new MediaRecorder(stream);

            audioChunksRef.current = [];

            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };


            setRecorder(mediaRecorder);
            setIsRecording(true);
            mediaRecorder.start();
        } catch (error) {
            console.error("Error starting recording:", error);
            alert("Failed to start recording.");
            setIsRecording(false);
        }
    };


    // ---------- handleRecordingStop (Add Mode) ----------
    const handleRecordingStop = async () => {
        try {
            if (!isRecording || !recorder) {
                alert("No recording is in progress.");
                return;
            }


            recorder.stop();
            recorder.onstop = async () => {
                setIsRecording(false);

                const audioBlob = new Blob(audioChunksRef.current, { type: "audio/mp4" });

                // Send the recorded audio immediately
                try {
                    const formData = new FormData();
                    const senderName = await AuthService.getCurrentUserTypeName();
                    const accountantId = await AuthService.getCurrentUserTypeAccountantId();
                    const finalDate = chosenDate;

                    formData.append('AccountantId', accountantId);
                    formData.append("SenderId", userId);
                    formData.append("SenderName", senderName);
                    formData.append("LabelName", label?.labelName || "");
                    formData.append("SenderType", userType.toString());
                    formData.append("LabelId", label.labelId.toString());
                    formData.append("MessageContent", ""); // No text content for audio
                    formData.append("MessageType", "3"); // Audio message type
                    formData.append("RepeatInterval", repeatInterval.toString());
                    formData.append("RepeatCount", repeatCount.toString());
                    if (dayOfMonth !== null) {
                        formData.append("DayOfMonth", dayOfMonth.toString());
                    }
                    if (dayOfWeek !== null) {
                        formData.append("DayOfWeek", dayOfWeek.toString());
                    }
                    formData.append("InitialRunTime", finalDate);

                    const file = new File([audioBlob], `audio-${Date.now()}.mp4`, { type: "audio/mp4" });
                    formData.append("Files", file);

                    await ScheduledMessagesService.createScheduledMessage(formData);
                    toast.success(t("AudioMessageSentSuccessfully"));
                    onClose();
                    onRefresh && onRefresh(label.labelId);
                } catch (error) {
                    console.error("Error sending audio message:", error);
                    toast.error(t("ErrorSendingAudioMessage"));
                }
            };
        } catch (error) {
            console.error("Error stopping recording:", error);
            alert("Failed to stop recording.");
        }
    };


    // ---------- handleCancelRecording (Add Mode) ----------
    const handleCancelRecording = () => {
        try {
            if (!isRecording || !recorder) {
                alert("No recording is in progress.");
                return;
            }
            recorder.stop();
            setIsRecording(false);
            setRecorder(null);
            audioChunksRef.current = [];
        } catch (error) {
            console.error("Error canceling recording:", error);
        }
    };

    // ---------- handleSendMessage (Add Mode) ----------
    const handleSendMessage = async (message) => {
        setMessageContent(message.text)
    };

    /* =====================================================
     * "handleCreateScheduled" for final Add  
     * "handleUpdateScheduled" for final Update
     * (both Add & Edit)
     * =====================================================*/


    const handleCreateScheduled = async () => {
        if (!label) {
            toast.error("No label selected");
            return;
        }

        if (!messageContent.trim() && parsedAttachments.length === 0) {
            toast.warning("Please enter text or attach files");
            return;
        }

        try {
            const formData = await prepareFormData();

            await ScheduledMessagesService.createScheduledMessage(formData);

            toast.success(t("ScheduledMessageCreatedSuccessfully"));
            onClose();
            onRefresh && onRefresh(label.labelId);
        } catch (err) {
            console.error("Error creating scheduled message:", err);
            toast.error(t("ErrorCreatingScheduledMessage"));
        }
    };

    const handleUpdateScheduled = async () => {
        if (!scheduledItem) {
            toast.error("No scheduled item to update");
            return;
        }

        try {
            const formData = await prepareFormData();
            formData.append("Id", scheduledItem.id.toString());

            await ScheduledMessagesService.updateScheduledMessage(formData);

            toast.success(t("ScheduledMessageUpdatedSuccessfully"));
            onClose();
            onRefresh && onRefresh(label.labelId);
        } catch (err) {
            console.error("Error updating scheduled message:", err);
            toast.error(t("ErrorUpdatingScheduledMessage"));
        }
    };

    // Common function to prepare FormData
    const prepareFormData = async () => {
        const finalDate = chosenDate;

        const formData = new FormData();
        const senderName = await AuthService.getCurrentUserTypeName();
        const accountantId = await AuthService.getCurrentUserTypeAccountantId();
        formData.append('AccountantId', accountantId);
        formData.append("SenderId", userId);
        formData.append("SenderName", senderName);
        formData.append("LabelName", label?.labelName || "");
        formData.append("SenderType", userType.toString());
        formData.append("LabelId", label.labelId.toString());
        formData.append("MessageContent", messageContent || t('NoMessageContent'));
        formData.append("MessageType", messageType.toString());
        formData.append("RepeatInterval", repeatInterval.toString());
        formData.append("RepeatCount", repeatCount.toString());
        formData.append("IsActive", isActive ? "true" : "false");

        if (dayOfMonth !== null) {
            formData.append("DayOfMonth", dayOfMonth.toString());
        }
        if (dayOfWeek !== null) {
            formData.append("DayOfWeek", dayOfWeek.toString());
        }

        formData.append("InitialRunTime", finalDate);

        // Attach parsedAttachments to the formData
        if (parsedAttachments.length > 0) {

            for (const fObj of parsedAttachments) {
                if (fObj.file) {
                    // Local file: Append directly
                    formData.append("Files", fObj.file, fObj.name || "file");
                } else if (fObj.uri) {
                    // Remote file: Download and append
                    const response = await fetch(fObj.uri);
                    if (!response.ok) {
                        console.error(`Failed to download file from ${fObj.uri}`);
                        continue;
                    }

                    // Get blob and create a new File object
                    const blob = await response.blob();
                    const fileName = decodeURIComponent(fObj.uri.split('?')[0].split('/').pop());
                    const downloadedFile = new File([blob], fileName, { type: fObj.type });

                    formData.append("Files", downloadedFile, fileName);
                }
            }
        }

        return formData;
    };

    // ------------- (Edit Mode) "PreviewModal" for new attachments -------------
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]); // Files user is about to attach in edit mode
    const [parsedAttachments, setParsedAttachments] = useState([]);

    // Start / Stop recording in edit mode
    const [editRecorder, setEditRecorder] = useState(null);
    const [editIsRecording, setEditIsRecording] = useState(false);
    const editAudioChunksRef = useRef([]);

    const handleEditStartRecording = async () => {
        if (editIsRecording) {
            // Stop
            if (!editRecorder) return;
            editRecorder.stop();
            editRecorder.onstop = async () => {
                setEditIsRecording(false);
                const audioBlob = new Blob(editAudioChunksRef.current, { type: "audio/mp4" });
                const file = new File([audioBlob], `audio-${Date.now()}.mp4`, { type: "audio/mp4" });
                setSelectedFiles((prev) => [...prev, file]);
                setShowPreviewModal(true);
            };
        } else {
            // Start
            if (!navigator.mediaDevices?.getUserMedia) {
                alert("Microphone not supported in this browser.");
                return;
            }
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const mediaRecorder = new MediaRecorder(stream);
                editAudioChunksRef.current = [];
                mediaRecorder.ondataavailable = (ev) => {
                    if (ev.data.size > 0) {
                        editAudioChunksRef.current.push(ev.data);
                    }
                };
                setEditRecorder(mediaRecorder);
                setEditIsRecording(true);
                mediaRecorder.start();
            } catch (err) {
                console.error("Failed to start recording (edit mode):", err);
                alert("Failed to start recording.");
                setEditIsRecording(false);
            }
        }
    };

    const handleEditCancelRecording = () => {
        if (editIsRecording && editRecorder) {
            editRecorder.stop();
        }
        setEditIsRecording(false);
        setEditRecorder(null);
        editAudioChunksRef.current = [];
    };

    const handleEditAttachFiles = (files) => {
        const parsedFiles = files.map((file) => ({
            name: file.name,
            type: file.type,
            file,
        }));
        setMessageType(2);
        setParsedAttachments((prev) => [...prev, ...parsedFiles]);
    };

    const handleEditSendMessage = (message) => {
        setMessageContent(message.text);
    };

    const handleRemoveAttachment = (index) => {
        setParsedAttachments((prev) => prev.filter((_, i) => i !== index));
    };
    const handleEditRecordingStart = async () => {
        if (editIsRecording) {
            alert("Recording is already in progress.");
            return;
        }
        if (!navigator.mediaDevices?.getUserMedia) {
            alert("Microphone access is not supported in your browser.");
            return;
        }

        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mediaRecorder = new MediaRecorder(stream);

        editAudioChunksRef.current = [];

        mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
                editAudioChunksRef.current.push(event.data);
            }
        };

        setEditRecorder(mediaRecorder);
        setEditIsRecording(true);
        mediaRecorder.start();
    };

    const handleEditRecordingStop = async () => {
        try {
            if (!editIsRecording || !editRecorder) {
                alert("No recording is in progress.");
                return;
            }

            editRecorder.stop();
            editRecorder.onstop = async () => {
                setEditIsRecording(false);

                const audioBlob = new Blob(editAudioChunksRef.current, { type: "audio/mp4" });

                // Send the recorded audio immediately
                try {
                    const formData = new FormData();
                    const senderName = await AuthService.getCurrentUserTypeName();
                    const accountantId = await AuthService.getCurrentUserTypeAccountantId();
                    const finalDate = chosenDate;

                    // Append update-specific fields
                    formData.append("Id", scheduledItem.id.toString());
                    formData.append("AccountantId", accountantId);
                    formData.append("SenderId", userId);
                    formData.append("SenderName", senderName);
                    formData.append("LabelName", label?.labelName || "");
                    formData.append("SenderType", userType.toString());
                    formData.append("LabelId", label.labelId.toString());
                    formData.append("MessageContent", ""); // No text content for audio
                    formData.append("MessageType", "3"); // Audio message type
                    formData.append("RepeatInterval", repeatInterval.toString());
                    formData.append("RepeatCount", repeatCount.toString());
                    if (dayOfMonth !== null) {
                        formData.append("DayOfMonth", dayOfMonth.toString());
                    }
                    if (dayOfWeek !== null) {
                        formData.append("DayOfWeek", dayOfWeek.toString());
                    }
                    formData.append("IsActive", isActive ? "true" : "false");

                    formData.append("InitialRunTime", finalDate);

                    // Append the recorded audio as a file
                    const file = new File([audioBlob], `audio-${Date.now()}.mp4`, { type: "audio/mp4" });
                    formData.append("Files", file);

                    await ScheduledMessagesService.updateScheduledMessage(formData);
                    toast.success(t("AudioMessageUpdatedSuccessfully"));
                    onClose();
                    onRefresh && onRefresh(label.labelId);
                } catch (error) {
                    console.error("Error updating audio message:", error);
                    toast.error(t("ErrorUpdatingAudioMessage"));
                }
            };
        } catch (error) {
            console.error("Error stopping recording:", error);
            alert("Failed to stop recording.");
        }
    };





    // "Attach File" for edit mode
    const handleEditAttachFile = (e) => {
        const newFiles = Array.from(e.target.files || []);
        if (!newFiles.length) return;
        setSelectedFiles((prev) => [...prev, ...newFiles]);
        setShowPreviewModal(true);
        e.target.value = null;
    };
    const handleAttachFiles = (files) => {
        // Handle attachments and update parsedAttachments
        const parsedFiles = files.map((file) => ({
            name: file.name,
            type: file.type,
            file,
        }));
        setMessageType(2);
        setParsedAttachments(parsedFiles);
    };
    // "Send" from PreviewModal in edit mode
    const handleSendFiles = () => {
        if (!selectedFiles.length) {
            setShowPreviewModal(false);
            return;
        }

        let existing = [];
        try {
            existing = JSON.parse(fileKeysJson || '[]');
        } catch {
            existing = [];
        }
        const updated = [...existing];

        selectedFiles.forEach((file) => {
            const name = file.name || `file-${Date.now()}`;
            const ext = name.split('.').pop()?.toLowerCase();
            const type = file.type || 'application/octet-stream';
            updated.push({
                uri: URL.createObjectURL(file),
                name,
                type,
            });
            // Decide messageType
            if (['mp3', 'm4a', 'aac', 'wav'].includes(ext)) setMessageType(3);
            else if (['jpg', 'jpeg', 'png', 'gif'].includes(ext)) setMessageType(1);
            else setMessageType(2);
        });

        setFileKeysJson(JSON.stringify(updated));
        setSelectedFiles([]);
        setShowPreviewModal(false);
    };

    const removeFile = (idx) => {
        const copy = [...selectedFiles];
        copy.splice(idx, 1);
        setSelectedFiles(copy);
    };

    const closePreviewModal = () => {
        setSelectedFiles([]);
        setShowPreviewModal(false);
    };

    // ------------- UI / Portal -------------
    const container = document.createElement('div');
    useEffect(() => {
        if (visible) {
            document.body.appendChild(container);
        }
        return () => {
            try {
                document.body.removeChild(container);
            } catch { }
        };
    }, [visible, container]);

    if (!visible) return null;


    /* ------------- Scheduling Fields ------------- */
    const schedulingFields = (
        <Box
            sx={{
                borderRadius: 2,
                backgroundColor: "#FCFCFF",
                display: "flex",
                flexDirection: "column",
                padding: 2,
                gap: 2,
                width: "100%",
                boxSizing: 'border-box !important'
            }}
        >
            {/* Repeat Interval */}
            <FormControl>
                <FormLabel
                    sx={{
                        color: "#304FFF",
                        fontFamily: "Montserrat",
                        fontSize: 16,
                        fontWeight: 500,
                    }}
                >
                    {t('RepeatInterval')}
                </FormLabel>
                <RadioGroup
                    value={repeatInterval}
                    onChange={(e) => setRepeatInterval(parseInt(e.target.value))}
                    row
                >
                    {REPEAT_OPTIONS.map((opt) => (
                        <FormControlLabel
                            key={opt.value}
                            value={opt.value}
                            control={
                                <Radio
                                    sx={{
                                        color: "#304FFF",
                                        "&.Mui-checked": {
                                            color: "#304FFF",
                                        },
                                    }}
                                />
                            }
                            label={t(opt.label)}
                        />
                    ))}
                </RadioGroup>
            </FormControl>

            {/* Dynamic Fields */}
            {repeatInterval === 0 && (
                <TextField
                    type="datetime-local"
                    label={t('PickDateTime')}
                    value={localDate} // Show local Israel time
                    onChange={(e) => {
                        const selectedLocalDate = e.target.value;
                        const selectedUTCDate = toUTC(selectedLocalDate); // Convert to UTC
                        setLocalDate(selectedLocalDate); // Update local display
                        setChosenDate(selectedUTCDate); // Update UTC value
                    }}
                    fullWidth
                    InputProps={{
                        disableUnderline: true,
                        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                        sx: {
                            height: '48px',
                            padding: '0 10px',
                            borderRadius: '8px',
                            background: '#F5F5F6',
                            border: '1px solid transparent',
                            '&:focus-within': {
                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                            },
                        },
                    }}
                />
            )}

            {repeatInterval === 1 && (
                <TextField
                    type="time"
                    label={t('PickTime')}
                    value={localDate} // Show local Israel time
                    onChange={(e) => {
                        const selectedTime = e.target.value; // Get the selected time
                        const today = new Date(); // Get today's date
                        const selectedLocalDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}T${selectedTime}`;
                        const selectedUTCDate = toUTC(selectedLocalDate); // Convert to UTC

                        setLocalDate(selectedTime); // Update local display with the time only
                        setChosenDate(selectedUTCDate); // Update UTC value
                    }}
                    fullWidth
                    InputProps={{
                        disableUnderline: true,
                        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                        sx: {
                            height: '48px',
                            padding: '0 10px',
                            borderRadius: '8px',
                            background: '#F5F5F6',
                            border: '1px solid transparent',
                            '&:focus-within': {
                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                            },
                        },
                    }}
                />
            )}

            {repeatInterval === 2 && (
                <>
                    <FormLabel>{t('PickWeekday')}</FormLabel>
                    <Select
                        options={WEEKDAYS_OPTIONS}
                        value={WEEKDAYS_OPTIONS.find((option) => option.value === dayOfWeek)}
                        onChange={(option) => setDayOfWeek(option.value)}
                        styles={customSelectStyles}
                        placeholder={t('PickWeekday')}
                    />
                    <TextField
                        type="time"
                        label={t('PickTime')}
                        value={localDate} // Show local Israel time
                        onChange={(e) => {
                            const selectedTime = e.target.value; // Get the selected time
                            const today = new Date(); // Get today's date
                            const selectedLocalDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}T${selectedTime}`;
                            const selectedUTCDate = toUTC(selectedLocalDate); // Convert to UTC

                            setLocalDate(selectedTime); // Update local display with the time only
                            setChosenDate(selectedUTCDate); // Update UTC value
                        }}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                            sx: {
                                height: '48px',
                                padding: '0 10px',
                                borderRadius: '8px',
                                background: '#F5F5F6',
                                border: '1px solid transparent',
                                '&:focus-within': {
                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                },
                            },
                        }}
                    />
                </>
            )}

            {repeatInterval === 3 && (
                <>
                    <FormLabel>{t('DayOfMonth')}</FormLabel>
                    <Select
                        options={DAY_OPTIONS}
                        value={DAY_OPTIONS.find((option) => option.value === dayOfMonth)}
                        onChange={(option) => setDayOfMonth(option.value)}
                        styles={customSelectStyles}
                        placeholder={t('DayOfMonth')}
                    />
                    <FormLabel>{t('RepeatCountMonths')}</FormLabel>
                    <Select
                        options={REPEAT_COUNT_OPTIONS}
                        value={REPEAT_COUNT_OPTIONS.find((option) => option.value === repeatCount)}
                        onChange={(option) => setRepeatCount(option.value)}
                        styles={customSelectStyles}
                        placeholder={t('RepeatCountMonths')}

                    />

                    <TextField
                        type="time"
                        label={t('PickTime')}
                        value={localDate} // Show local Israel time
                        onChange={(e) => {
                            const selectedTime = e.target.value; // Get the selected time
                            const today = new Date(); // Get today's date
                            const selectedLocalDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}T${selectedTime}`;
                            const selectedUTCDate = toUTC(selectedLocalDate); // Convert to UTC

                            setLocalDate(selectedTime); // Update local display with the time only
                            setChosenDate(selectedUTCDate); // Update UTC value
                        }}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                            sx: {
                                height: '48px',
                                padding: '0 10px',
                                borderRadius: '8px',
                                background: '#F5F5F6',
                                border: '1px solid transparent',
                                '&:focus-within': {
                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                },
                            },
                        }}
                    />
                </>
            )}

            {/* Active Checkbox */}
            {!isAddSchedule && (
                <Box display="flex" alignItems="center" gap={1}>
                    <Checkbox
                        checked={isActive}
                        onChange={() => setIsActive(!isActive)}
                        sx={{
                            color: "#304FFF",
                            "&.Mui-checked": {
                                color: "#304FFF",
                            },
                        }}
                    />
                    <FormLabel>{t('Active')}?</FormLabel>
                </Box>
            )}
        </Box>
    );


    // ------------- ADD MODE UI -------------
    const renderAddMode = (
        <div className="sfm-addmode-container">
            {/* Show scheduling fields */}
            {schedulingFields}
            <div className="message-container" style={{marginBottom:'20px'}}>
                <div className="message-content">
                    <p>{t('MessageContent')}:</p>
                    <div className="message-content-preview">
                        {messageContent || t('NoMessageContent')}
                    </div>
                </div>

            </div>
            {/* Possibly show attachments from ChatInputBar */}
            {parsedAttachments.length > 0 && (
                <div className="attachment-list">
                    <p>{t('Attachments')}:</p>
                    <div className="attachment-preview-container">
                        {parsedAttachments.map((fObj, i) => (
                            <div key={i} className="attachment-preview">
                                {/* Check for file type and render accordingly */}
                                {fObj.type.startsWith('image/') ? (
                                    <img
                                        src={URL.createObjectURL(fObj.file)}
                                        alt={fObj.name}
                                        className="attachment-image"
                                    />
                                ) : fObj.type === 'application/pdf' ? (
                                    <iframe
                                        src={URL.createObjectURL(fObj.file)}
                                        title={fObj.name}
                                        className="attachment-pdf"
                                    ></iframe>
                                ) : (
                                    <div className="attachment-file">
                                        <span className="file-name">{fObj.name}</span>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* Save / Cancel */}
            <div className="sfm-button-row">
                <button onClick={onClose} className="sfm-cancel-btn">
                    {t('Cancel')}
                </button>
                <button
                    onClick={handleCreateScheduled}
                    className={`sfm-save-btn ${!messageContent.trim() && parsedAttachments.length === 0 ? 'disabled' : ''}`}
                    disabled={!messageContent.trim() && parsedAttachments.length === 0} // Disable condition
                >
                    {t('Save')}
                </button>
            </div>

            {/* ChatInputBar => add text, files, recording */}
            <ChatInputBar
                handleSendMessage={handleSendMessage}
                handleAttachFiles={handleAttachFiles}
                handleRecordingStart={handleRecordingStart}
                handleRecordingStop={handleRecordingStop}
                handleCancelRecording={handleCancelRecording}
                setIsRecording={setIsRecording}
                isRecording={isRecording}
                isTyping={false}
                isTargetRecording={false}
                chatService={chatService}
                targetUserId={label?.labelId}
                targetUserType={Constants.ReceiverType.Label}
            />
        </div>
    );

    // ------------- EDIT MODE UI -------------
    const renderEditMode = (
        <div className="sfm-editmode-container">
            {/* 1) Scheduling fields (unchanged) */}
            {schedulingFields}

            {/* 2) Message Content */}
            <div className="message-container">
                <div className="message-content">
                    <p>{t('MessageContent')}:</p>
                    <div className="message-content-preview">
                        {messageContent || t('NoMessageContent')}
                    </div>
                </div>
            </div>

            {/* 3) Attachments */}
            {parsedAttachments.length > 0 && (
                <div className="attachment-list">
                    <p>{t("Attachments")}:</p>
                    <div className="attachment-preview-container">
                        {parsedAttachments.map((fObj, i) => (
                            <div key={i} className="attachment-container">
                                <div className="attachment-preview">
                                    {/* Use our helper to dynamically render a preview */}
                                    {renderFilePreview(fObj)}
                                </div>
                                <button
                                    className="remove-attachment-btn"
                                    onClick={() => handleRemoveAttachment(i)}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M15.2496 4.75843C14.9246 4.43343 14.3996 4.43343 14.0746 4.75843L9.99961 8.8251L5.92461 4.7501C5.59961 4.4251 5.07461 4.4251 4.74961 4.7501C4.42461 5.0751 4.42461 5.6001 4.74961 5.9251L8.82461 10.0001L4.74961 14.0751C4.42461 14.4001 4.42461 14.9251 4.74961 15.2501C5.07461 15.5751 5.59961 15.5751 5.92461 15.2501L9.99961 11.1751L14.0746 15.2501C14.3996 15.5751 14.9246 15.5751 15.2496 15.2501C15.5746 14.9251 15.5746 14.4001 15.2496 14.0751L11.1746 10.0001L15.2496 5.9251C15.5663 5.60843 15.5663 5.0751 15.2496 4.75843Z" fill="black" fill-opacity="0.8" />
                                    </svg>
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* 4) Save / Cancel */}
            <div
                className="sfm-button-row"
                style={repeatInterval === 3 ? { marginBottom: "150px" } : { marginBottom: "120px" }}
            >
                <button onClick={onClose} className="sfm-cancel-btn">
                    {t("Cancel")}
                </button>
                <button
                    onClick={handleUpdateScheduled}
                    className={`sfm-save-btn ${!messageContent.trim() && parsedAttachments.length === 0 ? "disabled" : ""
                        }`}
                    disabled={!messageContent.trim() && parsedAttachments.length === 0}
                >
                    {t("Save")}
                </button>
            </div>

            {/* 5) Chat Input Bar */}
            <ChatInputBar
                handleSendMessage={handleEditSendMessage}
                handleAttachFiles={handleEditAttachFiles}
                handleRecordingStart={handleEditRecordingStart}
                handleRecordingStop={handleEditRecordingStop}
                handleCancelRecording={handleEditCancelRecording}
                setIsRecording={setEditIsRecording}
                isRecording={editIsRecording}
                isTyping={false}
                isTargetRecording={false}
                chatService={chatService}
                targetUserId={label?.labelId}
                targetUserType={Constants.ReceiverType.Label}
            />
        </div>
    );



    // ------------- Final UI -------------
    const modalUI = (
        <div className="scheduled-modal-backdrop">
            <div className={`scheduled-modal-content ${direction}`}>
                <div className="scheduled-modal-header">
                    <h2>{scheduledItem && !isAddSchedule ? t('EditScheduledMessage') : t('CreateScheduledMessage')}</h2>
                    <button className="scheduled-modal-close-Item" onClick={onClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M15.2496 4.75843C14.9246 4.43343 14.3996 4.43343 14.0746 4.75843L9.99961 8.8251L5.92461 4.7501C5.59961 4.4251 5.07461 4.4251 4.74961 4.7501C4.42461 5.0751 4.42461 5.6001 4.74961 5.9251L8.82461 10.0001L4.74961 14.0751C4.42461 14.4001 4.42461 14.9251 4.74961 15.2501C5.07461 15.5751 5.59961 15.5751 5.92461 15.2501L9.99961 11.1751L14.0746 15.2501C14.3996 15.5751 14.9246 15.5751 15.2496 15.2501C15.5746 14.9251 15.5746 14.4001 15.2496 14.0751L11.1746 10.0001L15.2496 5.9251C15.5663 5.60843 15.5663 5.0751 15.2496 4.75843Z" fill="black" fill-opacity="0.8" />
                        </svg>
                    </button>
                </div>

                {/* Choose which UI to render */}
                {scheduledItem && !isAddSchedule ? renderEditMode : renderAddMode}
            </div>

            {/* The PreviewModal if user is attaching new files in Edit Mode */}
            <PreviewModal
                open={showPreviewModal}
                files={selectedFiles}
                onClose={closePreviewModal}
                onSend={handleSendFiles}
                onRemove={removeFile}
            />
        </div>
    );

    return createPortal(modalUI, container);
}
