import React, { useState } from 'react';
import { Modal, Box, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ClientService from '../../../Services/client.service';
import CustomMonthYearIndividualDatePicker from '../CustomDates/CustomMonthYearIndividualDatePicker/CustomMonthYearIndividualDatePicker';

// Helper to form a real date from separate year/month picks
function composeDate(yearDate, monthDate) {
  if (!yearDate || !monthDate) return null;
  const year = yearDate.getFullYear();
  const month = monthDate.getMonth(); // 0-11
  return new Date(year, month, 1, 0, 0, 0);
}

function DownloadUniformFile({ client, token, open, onClose }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  // For the "start" pickers:
  const [startYear, setStartYear] = useState(new Date());
  const [startMonth, setStartMonth] = useState(new Date());

  // For the "end" pickers:
  const [endYear, setEndYear] = useState(new Date());
  const [endMonth, setEndMonth] = useState(new Date());

  const handleDownload = async () => {
    if (!client?.caseId) {
      console.warn('No client or caseId found');
      return;
    }
    setLoading(true);

    try {
      // Combine the user’s chosen year & month for start & end 
      const startDate = composeDate(startYear, startMonth);
      const endDate   = composeDate(endYear, endMonth);

      if (!startDate || !endDate) {
        alert(t('InvalidDateSelection'));
        setLoading(false);
        return;
      }

      // Build request body
      const dto = {
        caseId: client.caseId,
        date: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };

      // Now call the ClientService method that performs the fetch & triggers download
      await ClientService.DownloadUniformFile(dto, token);

    } catch (error) {
      console.error(error);
      alert('Error downloading uniform file: ' + error.message);
    }
    setLoading(false);
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="uniform-file-modal">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: 360,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3,
        }}
      >
        <h2 id="uniform-file-modal" style={{ margin: 0 }}>
          {t('DownloadUniformFile')}
        </h2>
        <p style={{ margin: 0 }}>{t('SelectStartAndEndPeriod')}</p>

        {/* START PERIOD PICKERS */}
        <div style={{ width: '100%' }}>
          <CustomMonthYearIndividualDatePicker
            labelYear={t('StartYear')}
            labelMonth={t('StartMonth')}
            year={startYear}
            month={startMonth}
            onYearChange={(val) => setStartYear(val)}
            onMonthChange={(val) => setStartMonth(val)}
            flip={true}
          />
        </div>

        {/* END PERIOD PICKERS */}
        <div style={{ width: '100%' }}>
          <CustomMonthYearIndividualDatePicker
            labelYear={t('EndYear')}
            labelMonth={t('EndMonth')}
            year={endYear}
            month={endMonth}
            onYearChange={(val) => setEndYear(val)}
            onMonthChange={(val) => setEndMonth(val)}
            flip={true}
          />
        </div>

        {/* Loading spinner */}
        {loading && <CircularProgress />}

        {/* ACTIONS */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, width: '100%' }}>
          <Button onClick={onClose}>{t('Cancel')}</Button>
          <Button variant="contained" onClick={handleDownload}>
            {t('Download')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default DownloadUniformFile;
