import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './HamburgerMenu.css'; // Import the CSS styles
import logo from '../../assests/images/login/logo.svg';
import clients from '../../assests/images/Hamburger/clients.svg';
import accountants from '../../assests/images/Hamburger/accountants.svg';
import reports from '../../assests/images/Hamburger/reports.svg';
import home from '../../assests/images/Hamburger/home.svg';
import homeWhite from '../../assests/images/Hamburger/homeWhite.svg';
import profile from '../../assests/images/Hamburger/person.svg';
import logOut from '../../assests/images/Hamburger/logout.svg';
import salesIcon from '../../assests/images/Icons/sales1.svg';
import blueSalesIcon from '../../assests/images/Icons/sales.svg';
import bankIcon from '../../assests/images/Icons/bank.svg';
import crmIcon from '../../assests/images/Icons/crm.svg';
import accountingManagementIcon from '../../assests/images/Icons/accountingManagment1.svg';
import cardMatchingIcon from '../../assests/images/Icons/cardMatching.svg';
import PuffLoader from "react-spinners/PuffLoader";
import bankPageIcon from '../../assests/images/Icons/collection.svg';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChatIcon from '@mui/icons-material/Chat';

// Item Icons
import accountCardsDetailsIcon from '../../assests/images/Icons/accountCard.svg';
import incomesIcon from '../../assests/images/Icons/income.svg';
import expensesIcon from '../../assests/images/Icons/expense.svg';
import paymentAcceptanceIcon from '../../assests/images/Icons/paymentAcceptance.svg';
import journalEntryIcon from '../../assests/images/Icons/journalEntry.svg';
import otherDocumentIcon from '../../assests/images/Icons/otherDocument.svg';
import bankMatchingIcon from '../../assests/images/Icons/matching.svg';
import MatchingIcon from '../../assests/images/Icons/matching1.svg';
import ArchiveIcon from '../../assests/images/Icons/archive1.svg';
import sortCodeReportIcon from '../../assests/images/Icons/sortcodeReport.svg';
import accountCardsTableIcon from '../../assests/images/Icons/ledger.svg';
import ledgerReportIcon from '../../assests/images/Icons/balance.svg';
import referenceSequenceIcon from '../../assests/images/Icons/referenceCheck.svg';
import generateDocIcon from '../../assests/images/Icons/generateDocument.svg';
import collectionsIcon from '../../assests/images/Icons/collection.svg';
import assignmentIcon from '../../assests/images/Icons/assignment.svg';
import documentItemIcon from '../../assests/images/Icons/documentItem.svg';
import advancesReportIcon from '../../assests/images/Icons/reports.svg';
import pcnFileIcon from '../../assests/images/Icons/reports.svg';
import vatReportIcon from '../../assests/images/Icons/reports.svg';
import transactionDocumentsIcon from '../../assests/images/Icons/financial.svg';
import profitAndLossIcon from '../../assests/images/Icons/profitAndLoss.svg';
import reportsIcon from '../../assests/images/Icons/reports1.svg';
import reports1Icon from '../../assests/images/Icons/reports.svg';
import assetIcon from '../../assests/images/Icons/assetIcon.svg';

import { useNavigate } from 'react-router-dom';
import AuthService from '../../Services/auth.service';
import UserService from '../../Services/user.service';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import ClientService from '../../Services/client.service';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { formatDate_MM_YYYY } from '../../Utils/FormatDate';
import CustomMonthDatePicker from '../Widgets/CustomDates/CustomMonthDatePicker/CustomMonthDatePicker';
import AutocompleteSelect from '../Widgets/AutocompleteSelect/AutocompleteSelect';
import CustomYearDatePicker from '../Widgets/CustomDates/CustomYearDatePicker/CustomYearDatePicker';
import ReportsService from '../../Services/reports.service';
import CircularProgress from '@mui/material/CircularProgress';
import EnumsService from '../../Services/enums.service';
import { PermissionsContext } from '../../Contexts/Permissions';
// New imports for Radio Buttons
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Margin } from '@mui/icons-material';
import Chat from '../Chat/Chat';
import ManageLabelUsersModal from '../Chat/ManageLabelUsersModal/ManageLabelUsersModal';
import LabelService from '../../Services/labels.service';
import ChatApiService from '../../Services/chat.service';
import { playNotificationSound, showBrowserNotification } from '../../Utils/notificationUtils';
import { useChat } from '../../Contexts/ChatProvider';
import notificationSound from '../../assests/sounds/notification.mp3';
import useSound from 'use-sound';
import Constants from '../../assests/Constants/constants';
import Download856Modal from '../Widgets/Download856Modal/Download856Modal';
import DownloadUniformFile from '../Widgets/DownloadUniformFile/DownloadUniformFile';
const formatDate = (date) => {
    return dayjs(date).toDate();
};



function HamburgerMenu({ client }) {
    const { i18n, t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const { permissions, permissionsState, loading: permissionsLoading, user, userTypeContext, token, isAccountant, resetPermissionsState } = useContext(PermissionsContext);
    const { subscribe, unsubscribe } = useChat();
    const [play] = useSound(notificationSound, { volume: 0.5 });
    const direction = i18n.dir();
    const navigate = useNavigate();
    const [isClientMenuOpen, setIsClientMenuOpen] = useState(false);
    const [expandedSections, setExpandedSections] = useState({});
    const [subMenuItem, setSubMenuItem] = useState(null);
    const [isDateModalOpen, setIsDateModalOpen] = useState(false);
    const [withHoldingTaxModalOpen, setWithHoldingTaxModalOpen] = useState(false);
    const [withHoldingTaxType, setWithHoldingTaxType] = useState('');
    const [selectedReportType, setSelectedReportType] = useState('');
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [date, setDate] = useState(dayjs());
    const [isDateRange, setIsDateRange] = useState(false); // New state variable
    const [loading, setLoading] = useState(false);
    // const [token, setToken] = useState(null);
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const [isReferenceModalOpen, setIsReferenceModalOpen] = useState(false);
    const [accountVisibleInReference, setAccountVisibleInReference] = useState(false);
    const [selectedAccountCard, setSelectedAccountCard] = useState(null);
    const [selectedReferenceAccountCard, setSelectedReferenceAccountCard] = useState('');
    const [selectedWithholdingCard, setSelectedWithholdingCard] = useState('');
    const [userId, setUserId] = useState('');
    const [userType, setUserType] = useState('');
    const [AccountCardOptions, setAccountCardOptions] = useState([]);
    // const [isAccountant, setIsAccountant] = useState(false);
    const userIdRef = useRef(userId);
    const userTypeRef = useRef(userType);
    const [selectOtherDocumentsOptions, setSelectOtherDocumentsOptions] = useState([]);
    const [openChat, setOpenChat] = useState(false);
    const [chatKey, setChatKey] = useState(Date.now());
    const hasSubscribedRef = useRef(false);
    // This is the label we want to manage (or it can be selected from somewhere)
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [show856Modal, setShow856Modal] = useState(false);
    const [showUniformFileModal, setShowUniformFileModal] = useState(false);
    // Allowed users for the label
    const [allowedUsers, setAllowedUsers] = useState([]);
    // State to control ManageLabelUsersModal
    const [showManageLabelModal, setShowManageLabelModal] = useState(false);
    const [unreadChat, setUnreadChat] = useState(0);

    const fetchUnCountChat = useCallback(async (uId, uType) => {
        if (!uId || !uType) {
            console.warn('fetchUnCountChat skipped due to missing parameters:', { uId, uType });
            return;
        }

        try {
            const unreadChats = await ChatApiService.getUnreadCountSummary(uId, uType);

            setUnreadChat(unreadChats);
        } catch (error) {
            console.error('Error fetching unread chats:', error);
        }
    }, []);
    // Container for the portal
    const handleChatClose = () => {
        setOpenChat(false);
        setChatKey(Date.now()); // Update the key to remount Chat
    };



    useEffect(() => {
        userIdRef.current = userId;
        userTypeRef.current = userType;
    }, [userId, userType]);

    const openManageLabelUsersModal = (labelObj, users) => {
        // For example, store them in state
        setSelectedLabel(labelObj);
        setAllowedUsers(users || []);
        setShowManageLabelModal(true);
        setOpenChat(false)
        // Also, you could close the Chat if you want:
        // setOpenChat(false);
    };

    const [documentTypesCount, setDocumentTypesCount] = useState({});

    const setWithholdingTaxTypeFunc = (type) => {
        setWithHoldingTaxType(type);
        if (type === 'monthlyReport') {
            setIsDateRange(true);
        }
        else {
            setIsDateRange(false);
        }
    }

    const fetchEnums = async (token, client, isSetLoading = false) => {
        try {
            if (isSetLoading) {
                setLoading(true);
            }

            // Fetch account cards
            const accountsResponse = await EnumsService.GetAccountCardsByCaseId(
                client.caseId,
                token
            );

            const otherDocumentsResponse = await EnumsService.GetOtherDocumentsTypesByAccountantId(client.accountantId, token);
            const otherDocumentsData = await otherDocumentsResponse.json();
            setSelectOtherDocumentsOptions(otherDocumentsData);


            const documentTypesCountRespone = await EnumsService.GetOtherDocumentsCountsByCaseId(client.caseId, token);
            const documentTypesCountData = await documentTypesCountRespone.json();
            setDocumentTypesCount(documentTypesCountData);

            const accountsData = await accountsResponse.json();
            setAccountCardOptions(accountsData);
        } catch (error) {
            console.error('Error fetching enum data:', error);
            toast.error(t('fetchEnumsFailure', { errorText: error.message }));
        } finally {
            if (isSetLoading) {
                setLoading(false);
            }
        }
    };

    const formattedAccountCardOptions = AccountCardOptions?.map((option) => ({
        value: option.cardId,
        label: `${option.cardId} - ${option.name}`,
        cardId: option.cardId,
        name: option.name,
        companyId: option.accountCaseId,
        palCode: option.palCode,
    }));

    const formattedAccountSourceDeductionCardOptions = AccountCardOptions
        ?.filter((option) => option.sourceDeductionAssociation === 1) // Filter options
        .map((option) => ({
            value: option.cardId,
            label: `${option.cardId} - ${option.name}`,
            cardId: option.cardId,
            name: option.name,
            companyId: option.accountCaseId,
            palCode: option.palCode,
        }));

    useEffect(() => {
        // Check for saved language in localStorage
        const currentLanguage = localStorage.getItem('language') || 'he'; // Default to 'he' if no language is stored
        i18n.changeLanguage(currentLanguage);
    }, [i18n]);

    // useEffect(() => {
    //     const currentToken = UserService.getCurrentToken();
    //     if (currentToken) {
    //         setToken(currentToken);
    //     }
    // }, []);

    useEffect(() => {
        if (client && token) {
            fetchEnums(token, client, false);
        }
    }, [client, token]);

    const handleUserSend = useCallback(async (senderId, senderType) => {
        // Re-fetch unread counts

        await fetchUnCountChat(userIdRef.current, userTypeRef.current);

        // Play the sound
        play();

        if (!openChat) {
            showBrowserNotification(t('NewMessage'), t('YouHaveANewMessage'));
        }
    }, []);

    useEffect(() => {
        if (!hasSubscribedRef.current) {
            subscribe('UserSend', handleUserSend);
            hasSubscribedRef.current = true;
        }
        return () => {
            unsubscribe('UserSend', handleUserSend);
            hasSubscribedRef.current = false;
        };
    }, [subscribe, unsubscribe, handleUserSend]);

    useEffect(() => {

        if (userIdRef.current && userTypeRef.current) {
            fetchUnCountChat(userIdRef.current, userTypeRef.current);
        }
    }, [userIdRef.current, userTypeRef.current, openChat]);

    const handleMouseEnter = () => setIsOpen(true);
    const handleMouseLeave = () => setIsOpen(false);

    const handleLogout = async () => {
        try {
            resetPermissionsState()
            await AuthService.logout();
            navigate('/login');
        } catch (error) {
            // Optionally handle any errors
        }
    };

    const checkLogoutTime = async () => {
        try {
            const Token = await UserService.getCurrentToken();
            const validTokenDate = await AuthService.CheckTokenValidation(Token);
            if (!validTokenDate.isValid) {
                toast.info(t('YouNeedToLogInAgain'));
                handleLogout();
            }

            const currentUserTypeVal = await AuthService.getCurrentUserType();
            const currentUserIdVal = await AuthService.getCurrentUserTypeId();
            setUserId(currentUserIdVal);
            setUserType(currentUserTypeVal);
        } catch (error) {
            toast.info(t('YouNeedToLogInAgain'));
            handleLogout();
        }
    };

    useEffect(() => {
        checkLogoutTime();
    }, []);

    const handleGoClientDetails = () => {
        if (client) {
            navigate('/client-details', { state: { client } });
        }
    };

    // Toggle the expansion of a section
    const handleSectionClick = (sectionTitle) => {
        setSubMenuItem(null)
        setExpandedSections((prev) => ({
            ...prev,
            [sectionTitle]: !prev[sectionTitle],
        }));
    };

    const openDateModal = (reportType) => {
        setSelectedReportType(reportType);
        setIsDateModalOpen(true);
    };

    const openWithholdingTaxModel = (reportType) => {
        setSelectedReportType(reportType);
        setWithHoldingTaxModalOpen(true);
    };


    const closeDateModal = () => {
        setIsDateModalOpen(false);
        setSelectedReportType('');
    };

    const closeWithHoldingTaxModal = () => {
        setWithHoldingTaxModalOpen(false);
        setSelectedReportType('');
    };

    const handleDownloadWithholdingReportReport = async () => {
        if (withHoldingTaxType == '') {
            return
        }
        setLoading(true);
        setWithHoldingTaxModalOpen(false);
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);
        const formattedDate = formatDate(date);
        // Decide which dates to use based on isDateRange
        let start, end;
        if (isDateRange) {
            start = formattedStartDate;
            end = formattedEndDate;
        } else {
            start = formattedDate;
            end = formattedDate;
        }

        try {
            switch (withHoldingTaxType) {
                case 'yearlyCertificate':
                    await ReportsService.getSupplierWithholdingTaxCertificate(
                        client.caseId,
                        start,
                        client.accountantId,
                        selectedAccountCard?.value,
                        token
                    );
                    break;

                case 'yearlyReport':
                    await ReportsService.getSupplierWithholdingTaxSummaryYearly(
                        client.caseId,
                        start,
                        client.accountantId,
                        token
                    );
                    break;

                case 'monthlyReport':
                    await ReportsService.getSupplierWithholdingTaxSummary(
                        client.caseId,
                        start,
                        end,
                        client.accountantId,
                        token
                    );
                    break;

                default:
                    console.warn('Invalid withholding tax type');
                    break;
            }
        } catch (error) {
            console.error(error);
            toast.error(t('reportDownloadFailure'));
        }
        setLoading(false);
    };

    const handleDownloadReport = async () => {
        setIsDateModalOpen(false);
        setLoading(true);
        try {
            const formattedStartDate = formatDate(startDate);
            const formattedEndDate = formatDate(endDate);
            const formattedDate = formatDate(date);

            // Decide which dates to use based on isDateRange
            let start, end;
            if (isDateRange) {
                start = formattedStartDate;
                end = formattedEndDate;
            } else {
                start = formattedDate;
                end = formattedDate;
            }

            switch (selectedReportType) {
                case 'ProfitAndLoss':
                    await ClientService.ProfitAndLossDetailsYearlyReport(
                        client.caseId,
                        client.accountantId,
                        start,
                        end,
                        token
                    );
                    break;
                case 'WithholdingTax':
                    await ClientService.WithholdingTaxReport(
                        client.caseId,
                        client.accountantId,
                        start,
                        end,
                        token
                    );
                    break;
                case 'VAT':
                    await ClientService.VATReport(
                        client.caseId,
                        client.accountantId,
                        start,
                        end,
                        token
                    );
                    break;
                case 'PCN':
                    await ClientService.DownloadPCNReport(
                        client.caseId,
                        start,
                        end,
                        token
                    );
                    // Delay between downloads if necessary
                    await delay(500);
                    await ClientService.DownloadPCNPDF(
                        client.caseId,
                        start,
                        end,
                        token
                    );
                    break;
                case 'PCN':
                    await ClientService.DownloadPCNReport(
                        client.caseId,
                        start,
                        end,
                        token
                    );
                    // Delay between downloads if necessary
                    await delay(500);
                    await ClientService.DownloadPCNPDF(
                        client.caseId,
                        start,
                        end,
                        token
                    );
                    break;
                default:
                    break;
            }
            toast.success(t('reportDownloadSuccess'));
        } catch (error) {
            console.error(error);
            toast.error(t('reportDownloadFailure'));
        }
        setLoading(false);
    };

    // Reference Sequence Modal functions
    const openReferenceSequenceModal = () => {
        setIsReferenceModalOpen(true);
    };

    const closeReferenceSequenceModal = () => {
        setIsReferenceModalOpen(false);
        setAccountVisibleInReference(false);
        setSelectedAccountCard(null);
        setSelectedReferenceAccountCard('');
    };

    const handleAccountVisibleInReferenceChange = (event) => {
        setAccountVisibleInReference(event.target.checked);
    };

    const handleSelectedAccountCardChange = (event, value) => {
        setSelectedReferenceAccountCard(value?.value || '');
        setSelectedAccountCard(value);
    };

    const handleSelectedAccountCardChangeForWithholding = (event, value) => {
        setSelectedWithholdingCard(value?.value || '');
        setSelectedAccountCard(value);
    };

    const handleDownloadReferenceSequence = async () => {
        setLoading(true);
        setIsReferenceModalOpen(false);
        const formattedDate = formatDate(date);
        try {
            if (selectedReferenceAccountCard) {
                await ReportsService.getReferenceSequenceReport(
                    client.caseId,
                    formattedDate,
                    formattedDate,
                    selectedReferenceAccountCard,
                    token
                );
            } else {
                await ReportsService.getReferenceSequenceReport(
                    client.caseId,
                    formattedDate,
                    formattedDate,
                    '',
                    token
                );
            }
            toast.success(t('reportDownloadSuccess'));
        } catch (error) {
            console.error(error.message);

            if (error.message === 'לא קיים הכנסות בשנה ולקרטיס') {
                toast.error(t('reportDownloadFailure') + ': ' + error.message);
            } else {
                toast.error(t('reportDownloadFailure'));
            }
        }
        closeReferenceSequenceModal();
        setLoading(false);
    };

    // Navigation functions
    const viewAccountCardsTable = (type) => {
        navigate('/AccountCardsTable', { state: { type, client } });
    };

    const viewDocumentDetails = (type) => {
        navigate('/Document', {
            state: { type, client }, // Add unique key to state
        });
    };

    const onUsersUpdatedForLabel = async (labelId) => {
        try {
            // Fetch the list of labels
            const labelList = await LabelService.listLabels();
            // setLabels(labelList);

            // Find the label with the matching labelId
            const selectedLabel = labelList.find(label => label.labelId === labelId);

            // Set the selected label
            if (selectedLabel) {
                setSelectedLabel(selectedLabel);
            } else {
                console.warn(`Label with ID ${labelId} not found in labelList`);
                setSelectedLabel(null); // Clear selection if not found
            }
        } catch (error) {
            console.error("Error updating labels or setting selected label:", error);
        }
    };

    // const updateLocationState = async (newClientData) => {

    //     const updatedClientData = {
    //         ...location.state.client,
    //         ...newClientData
    //     };

    //     // Navigate with the updated client data and replace the current state
    //     setIsFirstUserFetched(false);
    //     navigate(location.pathname, {
    //         replace: true,
    //         state: { client: updatedClientData, type, sortDate }
    //     });
    // };

    const viewPaymentAcceptanceDetails = (type) => {
        navigate('/PaymentAcceptance', { state: { type, client } });
    };

    const viewJournalEntryDetails = (type) => {
        navigate('/JournalEntry', { state: { type, client } });
    };

    const viewOtherDocumentDetails = (type, subItem = null) => {
        navigate('/OtherDocument', { state: { type, client, subItem, key: new Date().getTime() } });
    };

    const viewProfitAndLossDetails = (type) => {
        navigate('/ProfitAndLossReport', { state: { type, client } });
    };

    const viewLedgerReport = (type, isProfitAndLoss = false) => {
        navigate('/LedgerReport', { state: { type, client, isProfitAndLoss } });
    };
    const viewAssignments = (type) => {
        navigate('/Assignments', { state: { type } });
    };


    const viewClientItems = (type) => {
        navigate('/ClientItems', { state: { type, client } });
    };
    const viewTransactionDocuments = (type) => {
        navigate('/TransactionDocuments', { state: { type, client } });
    };

    const viewBankTransactions = (type) => {
        navigate('/BankTransactions', { state: { type, client } });
    };

    const viewCollectionPage = (type) => {
        navigate('/CollectionPage', { state: { type, client } });
    };

    const viewCardMatching = (type) => {
        navigate('/CardMatching', { state: { type, client } });
    };

    const viewDocumentCreate = (screen, type) => {
        navigate(screen, { state: { client, type, } });
    };

    const viewBankPage = (type) => {
        navigate('/BankPages', { state: { type, client } });
    };

    const viewAssets = (type) => {
        navigate('/Assets', { state: { type, client } });
    };

    // Modified handleItemClick function
    const handleItemClick = (itemOnClick, sectionTitle) => () => {

        if (itemOnClick) {
            itemOnClick();
        }
        setExpandedSections((prev) => ({
            ...prev,
            [sectionTitle]: false,
        }));
    };

    const viewAssignmentsByClient = (type) => {
        navigate('/Assignments', { state: { type, client } });
    };

    // Define your items here
    const accountingItems = [
        {
            label: t('AccountCardsTable'),
            icon: accountCardsDetailsIcon,
            onClick: () => viewAccountCardsTable('AccountCardsTable'),
        },
        {
            label: t('Incomes'),
            icon: incomesIcon,
            onClick: () => viewDocumentDetails('Incomes'),
            count: client?.documentCounts?.incomes ?? 0,
        },
        {
            label: t('Expenses'),
            icon: expensesIcon,
            onClick: () => viewDocumentDetails('Expenses'),
            count: client?.documentCounts?.expenses ?? 0,
        },
        {
            label: t('PaymentAcceptance'),
            icon: paymentAcceptanceIcon,
            onClick: () => viewPaymentAcceptanceDetails('PaymentAcceptances'),
            count: client?.documentCounts?.checks ?? 0,
        },
        ...( // Conditionally include the JournalEntry item
            userTypeContext?.toString() !== Constants.UserType.Client || permissionsState.Premium
                ? [
                    {
                        label: t('JournalEntry'),
                        icon: journalEntryIcon,
                        onClick: () => viewJournalEntryDetails('JournalEntries'),
                        count: client?.documentCounts?.journalEntries ?? 0,
                    },
                ]
                : []
        ),
    ];


    const reportsItems = [
        {
            label: t('SortCodeReport'),
            icon: sortCodeReportIcon,
            onClick: () => viewProfitAndLossDetails('ProfitAndLossReport'),
        },
        {
            label: t('LedgerReport'),
            icon: accountCardsTableIcon,
            onClick: () => viewLedgerReport('LedgerReport'),
        },
        {
            label: t('ReportDetailedProfitAndLoss'),
            icon: accountCardsTableIcon,
            onClick: () => viewLedgerReport('ReportDetailedProfitAndLoss', true),
        },
        {
            label: t('ReferenceSequence'),
            icon: referenceSequenceIcon,
            onClick: openReferenceSequenceModal,
        },
        // {
        //     label: t('ProfitAndLossReport'),
        //     icon: profitAndLossIcon,
        //     onClick: () => openDateModal('ProfitAndLoss'),

        // },
        {
            label: t('ReportsForAuthority'),
            icon: reports1Icon,
            onClick: () => { },
            subItems: [
                {
                    label: t('AdvancesReport'),
                    icon: advancesReportIcon,
                    onClick: () => openDateModal('WithholdingTax'),
                },
                {
                    label: t('PCNFile'),
                    icon: pcnFileIcon,
                    onClick: () => openDateModal('PCN'),
                },
                {
                    label: t('VATReport'),
                    icon: vatReportIcon,
                    onClick: () => openDateModal('VAT'),
                },
                {
                    label: t('WithholdingTaxReports'),
                    icon: vatReportIcon,
                    onClick: () => openWithholdingTaxModel('VAT'),
                },
                {
                    label: t('856'),
                    icon: vatReportIcon,
                    onClick: () => setShow856Modal(true),
                },
                {
                    label: t('UniformFile'),
                    icon: vatReportIcon,
                    onClick: () => setShowUniformFileModal(true),
                },

            ],
        },
        ...( // Conditionally include the JournalEntry item
            userTypeContext?.toString() !== Constants.UserType.Client || permissionsState.Premium
                ? [
                    {
                        label: t('Assets'),
                        icon: assetIcon,
                        onClick: () => viewAssets("Assets"),

                        subItems: null

                    },
                ]
                : []
        ),
    ];

    const salesItems = [
        { label: t('DocumentItems'), icon: documentItemIcon, onClick: () => viewClientItems("DocumentItems")},
        { label: t('TransactionDocuments'), icon: transactionDocumentsIcon, onClick: () => viewTransactionDocuments("TransactionDocuments") },
        { label: t('TaxInvoice'), icon: transactionDocumentsIcon, onClick: () => viewDocumentCreate("/CreateInvoice", 'TaxInvoice') },
        { label: t('ReceiptTax'), icon: transactionDocumentsIcon, onClick: () => viewDocumentCreate('/ReceiptTax', 'ReceiptTax') },
        { label: t('CreditNote'), icon: transactionDocumentsIcon, onClick: () => viewDocumentCreate("/CreateInvoice", 'CreditNote') },
        { label: t('Receipt'), icon: transactionDocumentsIcon, onClick: () => viewDocumentCreate('/ReceiptTax', 'Receipt') },
        { label: t('Transaction'), icon: transactionDocumentsIcon, onClick: () => viewDocumentCreate("/CreateInvoice", 'Transaction') },
        { label: t('Quotation'), icon: transactionDocumentsIcon, onClick: () => viewDocumentCreate("/CreateInvoice", 'Quotation') },
        { label: t('Order'), icon: transactionDocumentsIcon, onClick: () => viewDocumentCreate("/CreateInvoice", 'Order') },
    ];

    const filteredSalesItems = salesItems.filter(item => {
        if (Constants.TaxExemptTypes.includes(client?.caseType)) {
            return item.label !== t('TaxInvoice') && item.label !== t('ReceiptTax') && item.label !== t('CreditNote');
        }
        return true;
    });

    const [parsedOtherDocuments, setParsedOtherDocuments] = useState({});
    const [parsedPermanentFiles, setParsedPermanentFiles] = useState([]);

    useEffect(() => {
        if (client && selectOtherDocumentsOptions && selectOtherDocumentsOptions.length > 0) {

            // Define the permanent file names
            const permanentFileNames = new Set([
                "מסמכי יסוד",
                "מוסדות",
                "הצהרת הון",
                "דוחות שנתיות",
                "חוזים"
            ]);

            // Split the options into PermanentFile and otherDocuments
            const permanentFiles = selectOtherDocumentsOptions.filter((doc) => permanentFileNames.has(doc.name));
            const otherDocuments = selectOtherDocumentsOptions.filter((doc) => !permanentFileNames.has(doc.name));

            // Parse PermanentFile options
            const parsedPermanentFiles = permanentFiles.map((doc) => ({
                label: doc.name,
                icon: otherDocumentIcon, // Use the same icon or customize per document if needed
                number: documentTypesCount[doc.name] || 0,
                onClick: () => viewOtherDocumentDetails("OtherDocuments", doc.name), // Use the updated client dynamically
            }));

            // Parse OtherDocuments options
            const parsedOtherDocuments = otherDocuments.map((doc) => ({
                label: doc.name,
                icon: otherDocumentIcon, // Use the same icon or customize per document if needed
                number: documentTypesCount[doc.name] || 0,
                onClick: () => viewOtherDocumentDetails("OtherDocuments", doc.name), // Use the updated client dynamically
            }));

            // Update the state for parsed documents
            setParsedOtherDocuments(parsedOtherDocuments);
            setParsedPermanentFiles(parsedPermanentFiles); // Ensure this state exists
        }

    }, [selectOtherDocumentsOptions, documentTypesCount, client]);

    const crmItems = [
        !permissionsState.ViewSubAccountantClients && {
            label: t('Collections'),
            icon: collectionsIcon,
            onClick: () => viewCollectionPage('CollectionPage'),
        },
        (!permissionsState.ViewSubAccountantClients && userTypeContext?.toString() !== Constants.UserType.Client) &&
        {
            label: t('Assignments'),
            icon: assignmentIcon,
            onClick: () => viewAssignmentsByClient('Assignments'),
            subItems: null

        },
    ].filter(Boolean);

    const bankItems = [
        { label: t('BankMatching'), icon: bankIcon, onClick: () => viewBankTransactions("BankMatching") },
        { label: t('CardMatching'), icon: cardMatchingIcon, onClick: () => viewCardMatching("CardMatching") },
        { label: t('BankPages'), icon: bankPageIcon, onClick: () => viewBankPage("BankPages") },

    ];

    const documentsItems = [
        {
            label: t('OtherDocuments'),
            icon: otherDocumentIcon,
            onClick: () => viewOtherDocumentDetails("OtherDocuments"),
            count: client?.documentCounts?.otherDocuments ?? 0,
            subItems: parsedOtherDocuments,
        },
        {
            label: t('PermanentFile'),
            icon: otherDocumentIcon,
            onClick: () => viewOtherDocumentDetails("OtherDocuments"),
            count: client?.documentCounts?.otherDocuments ?? 0,
            subItems: parsedPermanentFiles,
        },

    ];

    const clientMenuSections = [
        {
            title: t('AccountingManagement'),
            icon: accountingManagementIcon,
            items: accountingItems,
            disabled: false, // Always enabled
        },
        {
            title: t('Reports'),
            icon: reportsIcon,
            items: reportsItems,
            disabled: false, // Always enabled
        },
        {
            title: t('Sales'),
            icon: salesIcon,
            items: filteredSalesItems,
            disabled: false, // Always enabled
        },
        {
            title: t('CRM'),
            icon: crmIcon,
            items: crmItems,
            disabled: permissionsState.ViewSubAccountantClients || userTypeContext?.toString() === Constants.UserType.Client, // CRM should only be visible under specific conditions
        },
        {
            title: t('Matching'),
            icon: MatchingIcon,
            items: bankItems,
            disabled: userTypeContext?.toString() === Constants.UserType.Client && !permissionsState.Premium, // Disabled for non-premium clients
        },
        {
            title: t('Archive'),
            icon: ArchiveIcon,
            items: documentsItems,
            disabled: false, // Always enabled
        },
    ];

    const filteredClientMenuSections = clientMenuSections.reduce((result, section) => {
        // Remove the 'CRM' section if the condition matches
        if (
            section.title === t('CRM') &&
            (permissionsState.ViewSubAccountantClients || userTypeContext?.toString() === Constants.UserType.Client)
        ) {
            return result;
        }

        // Add section to the result with the existing or updated disabled property
        result.push({
            ...section,
            disabled: !!section.disabled, // Ensure disabled is a boolean
        });

        return result;
    }, []);

    // Modal styles
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 300,
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: 24,
        padding: 4,
    };

    const handleStartReferenceYearDate = async (date) => {
        setDate(date);
    }

    return (
        <>
            <div
                className={`sidebar ${isOpen ? 'open' : ''} ${direction}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className={`sidebar-header ${direction}`} onClick={() => navigate('/Main')}>
                    <img src={logo} alt="Logo" className="sidebar-logo" />
                </div>
                <div className="sidebar-items">
                    {client && (
                        <>
                            <div
                                className={`sidebar-item ${direction} sidebar-item-main`}
                                style={!isOpen ? { justifyContent: 'center' } : {}}
                                onClick={() => setIsClientMenuOpen(!isClientMenuOpen)}
                            >
                                <div className="sidebar-item-content">
                                    <img src={blueSalesIcon} alt="client" width={34} height={34} />
                                    {isOpen && <span>{client.name}</span>}
                                </div>
                                {isOpen && (
                                    <div className="sidebar-item-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="25"
                                            viewBox="0 0 24 25"
                                            fill="none"
                                            className={isClientMenuOpen ? 'rotated' : ''}
                                        >
                                            <path
                                                d="M7.41 8.79492L12 13.3749L16.59 8.79492L18 10.2049L12 16.2049L6 10.2049L7.41 8.79492Z"
                                                fill="#304FFF"
                                            />
                                        </svg>
                                    </div>
                                )}
                            </div>

                            {isClientMenuOpen && isOpen && (
                                <>
                                    {/* First item: Client Page */}
                                    <div
                                        className={`submenu-item ${direction}`}
                                        onClick={() => {
                                            handleGoClientDetails();
                                            setIsClientMenuOpen(false); // Close client menu after click
                                        }}
                                    >
                                        <div className="sidebar-item-content">
                                            <img src={profile} alt="client page" width={24} height={24} />
                                            <span>{t('ClientPage')}</span>
                                        </div>
                                    </div>

                                    {/* Render ClientActionCards for each section */}
                                    {filteredClientMenuSections.map((section, index) => (
                                        <div key={index} className={`submenu-section ${section.disabled ? 'disabled' : ''}`}>
                                            <div
                                                className={`submenu-item ${direction}`}
                                                onMouseEnter={() => !section.disabled && handleSectionClick(section.title)}
                                                onMouseLeave={() => !section.disabled && handleSectionClick(section.title)}
                                            // onClick={!section.disabled ? () => handleSectionClick(section.title) : undefined}
                                            >
                                                <div className="submenu-item-content">
                                                    <img src={section.icon} alt={section.title} width={24} height={24} />
                                                    <span>{section.title}</span>
                                                </div>
                                                {/* Arrow SVG */}
                                                <div className="submenu-item-arrow">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="25"
                                                        viewBox="0 0 24 25"
                                                        fill="none"
                                                        className={expandedSections[section.title] ? 'rotated' : ''}
                                                    >
                                                        <path
                                                            d="M7.41 8.79492L12 13.3749L16.59 8.79492L18 10.2049L12 16.2049L6 10.2049L7.41 8.79492Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </div>
                                                {/* Flyout Submenu */}
                                                {(expandedSections[section.title] && !section.disabled) && (
                                                    <div className={`flyout-submenu ${direction}`}>
                                                        <div className={`flyout-submenu-main ${direction}`}>
                                                            {section.items.map((item, idx) => (
                                                                <div
                                                                    key={idx}
                                                                    className={`flyout-submenu-item ${direction}`}
                                                                    // onClick={
                                                                    //     item.onClick
                                                                    //         ? handleItemClick(item.onClick, section.title)
                                                                    //         : undefined
                                                                    // }
                                                                    onClick={() => {
                                                                        if (item.subItems) {
                                                                            setSubMenuItem((prevItem) => {
                                                                                // If prevItem is not empty and matches the current item, reset it
                                                                                if (prevItem) {
                                                                                    return null;
                                                                                }
                                                                                // Otherwise, set it as the current item
                                                                                return item;
                                                                            });
                                                                        } else if (item.onClick) {
                                                                            handleItemClick(item.onClick, section.title)();
                                                                            setExpandedSections({});
                                                                            setSubMenuItem(null);
                                                                        }
                                                                    }}
                                                                >
                                                                    <div className={`flyout-submenu-item-main ${direction}`}>

                                                                        <div className="flyout-submenu-item-content">
                                                                            <img src={item.icon} alt={item.label} width={24} height={24} />
                                                                            <span>{item.label}</span>
                                                                        </div>
                                                                        {item.count !== null && item.count !== undefined && (
                                                                            <div className="item-notification-count">{item.count}</div>
                                                                        )}
                                                                    </div>
                                                                    {item.subItems && (
                                                                        <div
                                                                            className="submenu-item-arrow"
                                                                            dir={direction} // dynamically set the direction
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                className="submenu-arrow-icon"
                                                                            >
                                                                                <path
                                                                                    d="M8.59 16.59L13.17 12L8.59 7.41L10 6L16 12L10 18L8.59 16.59Z"
                                                                                    fill="#000"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    )}
                                                                    {/* SubSubItems */}
                                                                    {/* {item.subItems && (
                                                                    <div className={`flyout-subsubmenu ${direction}`}>
                                                                        {item.subItems.map((subItem, subIdx) => (
                                                                            <div
                                                                                key={subIdx}
                                                                                className={`flyout-subsubmenu-item ${direction}`}
                                                                                onClick={
                                                                                    subItem.onClick
                                                                                        ? handleItemClick(subItem.onClick, section.title)
                                                                                        : undefined
                                                                                }
                                                                            >
                                                                                <div className="flyout-subsubmenu-item-content">
                                                                                    <img
                                                                                        src={subItem.icon}
                                                                                        alt={subItem.label}
                                                                                        width={20}
                                                                                        height={20}
                                                                                    />
                                                                                    <span>{subItem.label}</span>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )} */}
                                                                </div>
                                                            ))}
                                                        </div>

                                                        {

                                                            subMenuItem && subMenuItem.subItems &&
                                                            <div className={`flyout-subsubmenu ${direction}`}>
                                                                {subMenuItem.subItems.map((subItem, subIdx) => (
                                                                    <div
                                                                        key={subIdx}
                                                                        className={`flyout-subsubmenu-item ${direction}`}
                                                                        onClick={
                                                                            subItem.onClick
                                                                                ? handleItemClick(subItem.onClick, section.title)
                                                                                : undefined
                                                                        }
                                                                        onMouseLeave={() => setSubMenuItem({})}
                                                                        onMouseEnter={() => setSubMenuItem(subMenuItem)}
                                                                    >
                                                                        <div className="flyout-subsubmenu-item-content" style={{ justifyContent: 'space-between' }}>
                                                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                                                <img
                                                                                    src={subItem.icon}
                                                                                    alt={subItem.label}
                                                                                    width={20}
                                                                                    height={20}
                                                                                />
                                                                                <span>{subItem.label}</span>
                                                                            </div>


                                                                            {subItem.number !== null && subItem.number !== undefined && (
                                                                                <div className="item-notification-count">{subItem.number}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>

                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </>
                    )}

                    {
                        userTypeContext?.toString() !== Constants.UserType.Client &&
                        <div
                            className={`sidebar-item ${(isOpen && !client) && 'sidebar-item-main'} ${direction}`}
                            style={!isOpen ? { justifyContent: 'center' } : {}}
                            onClick={() => navigate('/Main')}
                        >
                            <div className="sidebar-item-content">
                                <img
                                    src={client ? homeWhite : home}
                                    alt="home"
                                    width={24}
                                    height={24}
                                    className={(!isOpen && !client) ? 'icon-closed' : ''}

                                />
                                {isOpen && <span>{t('clientDashboard')}</span>}
                            </div>
                        </div>
                    }

                    {

                        isAccountant &&
                        <div
                            className={`sidebar-item ${direction}`}
                            style={!isOpen ? { justifyContent: 'center' } : {}}
                            onClick={() => navigate('/SubAccountants')}
                        >
                            <div className="sidebar-item-content">
                                <img src={accountants} alt="accountants" width={24} height={24} />
                                {isOpen && <span>{t('SubAccountantDashboard')}</span>}
                            </div>
                        </div>
                    }
                    {/* {
                        !permissionsState.ViewSubAccountantClients &&
                        <div
                            className={`sidebar-item ${direction}`}
                            style={!isOpen ? { justifyContent: 'center' } : {}}
                            onClick={() => navigate('/ReportTracking')}
                        >
                            <div className="sidebar-item-content">
                                <img src={reports} alt="reports" width={24} height={24} />
                                {isOpen && <span>{t('ReportTracking')}</span>}
                            </div>
                        </div>
                    } */}
                    {
                        (!permissionsState.ViewSubAccountantClients && userTypeContext?.toString() !== Constants.UserType.Client) &&
                        <div
                            className={`sidebar-item ${direction}`}
                            style={!isOpen ? { justifyContent: 'center' } : {}}
                            onClick={() => viewAssignments('Assignments')}
                        >
                            <div className="sidebar-item-content">
                                {/* <img src={reports} alt="reports" width={24} height={24} /> */}
                                <AssignmentIcon
                                    sx={{
                                        fontSize: 25,
                                    }}
                                />
                                {isOpen && <span>{t('Assignments')}</span>}
                            </div>
                        </div>
                    }
                    {
                        !permissionsState.ViewSubAccountantClients &&
                        <div
                            className={`sidebar-item ${direction}`}
                            style={!isOpen ? { justifyContent: 'center' } : {}}
                            onClick={() => setOpenChat(true)}
                        >
                            <div className="sidebar-item-content">
                                {/* <img src={reports} alt="reports" width={24} height={24} /> */}
                                <svg
                                    width="21"
                                    height="21"
                                    viewBox="0 0 21 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10.1666 20.5C15.6894 20.5 20.1666 16.0228 20.1666 10.5C20.1666 4.97715 15.6894 0.5 10.1666 0.5C4.64378 0.5 0.166626 4.97715 0.166626 10.5C0.166626 12.0997 0.542246 13.6116 1.21009 14.9525C1.38757 15.3088 1.44664 15.7161 1.34375 16.1006L0.748136 18.3267C0.489576 19.293 1.37364 20.177 2.33998 19.9185L4.56602 19.3229C4.95056 19.22 5.35784 19.2791 5.71416 19.4565C7.055 20.1244 8.56693 20.5 10.1666 20.5Z"
                                        fill={'white'}

                                    />
                                    <circle cx="7" cy="10" r="1" fill="black" />
                                    <circle cx="10.5" cy="10" r="1" fill="black" />
                                    <circle cx="14" cy="10" r="1" fill="black" />
                                </svg>
                                {isOpen && <span>{t('Chat')}</span>}
                                {unreadChat > 0 && (
                                    <div className="unread-bubble2">
                                        {unreadChat}
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    {
                        (!permissionsState.ViewSubAccountantClients && userTypeContext?.toString() !== Constants.UserType.Client) &&
                        <div
                            className={`sidebar-item ${direction}`}
                            style={!isOpen ? { justifyContent: 'center' } : {}}
                            onClick={() => navigate('/Accountant-details')}
                        >
                            <div className="sidebar-item-content">
                                <img src={profile} alt="profile" width={34} height={34} />
                                {isOpen && <span>{t('profile')}</span>}
                            </div>
                        </div>
                    }

                </div>
                <div className={`logout-item sidebar-item ${direction}`} onClick={handleLogout}>
                    <div className="sidebar-item-content">
                        <img src={logOut} alt="logout" width={24} height={24} />
                        {isOpen && <span>{t('logOut')}</span>}
                    </div>
                </div>
            </div>

            <Download856Modal
                open={show856Modal}
                onClose={() => setShow856Modal(false)}
                client={client}
                token={token}
            />

            <DownloadUniformFile
                open={showUniformFileModal}
                onClose={() => setShowUniformFileModal(false)}
                client={client}
                token={token}
            />


            {/* Date Selection Modal */}
            <Modal
                open={isDateModalOpen}
                onClose={closeDateModal}
                aria-labelledby="date-modal-title"
                aria-describedby="date-modal-description"
                sx={{ zIndex: 1000 }}
            >
                <Box sx={modalStyle} className={`modal-container ${direction}`}>
                    <h2 id="date-modal-title">{t('SelectDateRange')}</h2>

                    {/* Date Option Selection */}
                    <FormControl component="fieldset" sx={{ mb: 2 }}>
                        <FormLabel component="legend">{t('SelectDateOption')}</FormLabel>
                        <RadioGroup
                            aria-label="date-option"
                            name="date-option"
                            value={isDateRange ? 'dateRange' : 'singleDate'}
                            onChange={(event) => setIsDateRange(event.target.value === 'dateRange')}
                            row
                        >
                            <FormControlLabel
                                value="singleDate"
                                control={<Radio />}
                                label={t('SingleDate')}
                            />
                            <FormControlLabel
                                value="dateRange"
                                control={<Radio />}
                                label={t('DateRange')}
                            />
                        </RadioGroup>
                    </FormControl>

                    {isDateRange ? (
                        // For date range selection
                        <div className="date-picker-container">
                            <CustomMonthDatePicker
                                onDateChange={(date) => setStartDate(date)}
                                date={dayjs(startDate)}
                                label={t('StartDate')}
                                height="55px"
                            />
                            <CustomMonthDatePicker
                                onDateChange={(date) => setEndDate(date)}
                                date={dayjs(endDate)}
                                label={t('EndDate')}
                                height="55px"
                            />
                        </div>
                    ) : (
                        // For single date selection
                        <div className="date-picker-container">
                            <CustomMonthDatePicker
                                onDateChange={(date) => setDate(date)}
                                date={dayjs(date)}
                                label={t('date')}
                                height="55px"
                            />
                        </div>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button variant="contained" onClick={handleDownloadReport}>
                            {t('Download')}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* withHoldingTaxModalOpen */}
            <Modal
                open={withHoldingTaxModalOpen}
                onClose={closeWithHoldingTaxModal}
                aria-labelledby="date-modal-title"
                aria-describedby="date-modal-description"
                sx={{ zIndex: 1000 }}
            >
                <Box sx={modalStyle} className={`modal-container ${direction}`}>
                    <h2 id="date-modal-title">{t('SelectReportType')}</h2>

                    {/* Date Option Selection */}
                    <FormControl component="fieldset" sx={{ mb: 2 }}>
                        <FormLabel component="legend">{t('SelectReportType')}</FormLabel>
                        <RadioGroup
                            aria-label="date-option"
                            name="date-option"
                            value={withHoldingTaxType}
                            onChange={(event) => setWithholdingTaxTypeFunc(event.target.value)}
                            row
                        >
                            <FormControlLabel
                                value="yearlyCertificate"
                                control={<Radio />}
                                label={t('AnnualWithholdingTaxCertificate')}
                            />
                            <FormControlLabel
                                value="yearlyReport"
                                control={<Radio />}
                                label={t('AnnualWithholdingTaxReport')}
                            />
                            <FormControlLabel
                                value="monthlyReport"
                                control={<Radio />}
                                label={t('PeriodicWithholdingTaxReport')}
                            />
                        </RadioGroup>
                    </FormControl>

                    {isDateRange ? (
                        // For date range selection
                        <div className="date-picker-container">
                            <CustomMonthDatePicker
                                onDateChange={(date) => setStartDate(date)}
                                date={dayjs(startDate)}
                                label={t('StartDate')}
                                height="55px"
                            />
                            <CustomMonthDatePicker
                                onDateChange={(date) => setEndDate(date)}
                                date={dayjs(endDate)}
                                label={t('EndDate')}
                                height="55px"
                            />
                        </div>
                    ) : (
                        // For single date selection
                        <div className="date-picker-container">
                            <CustomYearDatePicker
                                onDateChange={(date) => setDate(date)}
                                date={dayjs(date)}
                                label={t("date")}
                            />
                        </div>
                    )}
                    {withHoldingTaxType === 'yearlyCertificate' ? (
                        <>
                            <div style={{ margin: '5px 0 5px 0' }}></div>
                            <AutocompleteSelect
                                options={formattedAccountSourceDeductionCardOptions}
                                selectedValues={selectedAccountCard}
                                onChange={handleSelectedAccountCardChangeForWithholding}
                                label={t("Select AccountCard")}
                                placeholder={t("Select AccountCard")}
                                isMultiple={false}
                                width="100%"
                            />
                        </>
                    ) : (
                        <></>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button
                            variant="contained"
                            onClick={handleDownloadWithholdingReportReport}
                            disabled={!withHoldingTaxType} // Disable the button if withHoldingTaxType is empty
                            style={{
                                backgroundColor: !withHoldingTaxType ? 'gray' : '', // Gray background when withHoldingTaxType is empty
                                color: !withHoldingTaxType ? '#fff' : '', // Optional: Set text color to white for better contrast
                            }}
                        >
                            {t('Download')}
                        </Button>
                    </Box>
                </Box>
            </Modal>


            {/* Reference Sequence Modal */}
            <Modal
                open={isReferenceModalOpen}
                onClose={closeReferenceSequenceModal}
                aria-labelledby="reference-modal-title"
                aria-describedby="reference-modal-description"
                sx={{ zIndex: 1000 }}
            >
                <Box className={`reference-sequence-modal ${direction}`}>
                    <strong style={{
                        color: "var(--black-90, rgba(0, 0, 0, 0.90))",
                        textAlign: "center",
                        fontSize: "24px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                    }}>
                        {t('DownloadReferenceSequenceReport')}
                    </strong>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={accountVisibleInReference}
                                    onChange={handleAccountVisibleInReferenceChange}
                                />
                            }
                            label={t('ByAccountCard')}
                        />

                        <CustomYearDatePicker
                            onDateChange={(date) => handleStartReferenceYearDate(date)}
                            date={dayjs(date)}
                            label={t("Year")}
                        />
                    </div>
                    {accountVisibleInReference && (
                        <AutocompleteSelect
                            options={formattedAccountCardOptions}
                            selectedValues={selectedAccountCard}
                            onChange={handleSelectedAccountCardChange}
                            label={t("Select AccountCard")}
                            placeholder={t("Select AccountCard")}
                            isMultiple={false}
                            width="100%"
                        />
                    )}
                    <Button
                        variant="contained"
                        onClick={handleDownloadReferenceSequence}
                        sx={{
                            width: '80%',
                            height: '44px',
                            mt: 1,
                            backgroundColor: '#304FFF',
                            borderRadius: '8px',
                            boxShadow: "0px 6px 6px 0px rgba(0, 0, 0, 0.08)",
                            color: '#fff',
                            '&:hover': { backgroundColor: '#1565c0' }
                        }}
                    >
                        {t('DownloadFile')}
                    </Button>
                </Box>
            </Modal>


            {loading && (
                <div className="loader-container">
                    <PuffLoader
                        size={100}
                        loading={loading}
                    />
                </div>
            )}
            {
                !permissionsState.ViewSubAccountantClients &&
                <Chat
                    key={chatKey} // Pass the dynamic key to force remount
                    isOpen={openChat}
                    onClose={handleChatClose}
                    setOpenChat={setOpenChat}
                    onOpenManageLabelModal={openManageLabelUsersModal}
                />
            }
            <ManageLabelUsersModal
                visible={showManageLabelModal}
                onClose={() => setShowManageLabelModal(false)}
                onReopen={() => {
                    // Re-fetch data or re-open after saving changes
                    setShowManageLabelModal(true);
                }}
                label={selectedLabel}       // object with { labelId, labelName, users: [...] }
                userId={userId}
                onUsersUpdated={onUsersUpdatedForLabel}
                userType={userType}
                allowedUsers={allowedUsers} // array of all users that can be added
                setLoading={setLoading}
                loading={loading}
            />
        </>
    );
}

export default HamburgerMenu;
