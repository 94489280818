import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    TextField,
    IconButton,
    Typography,
    Divider,
    Button,
} from "@mui/material";

// Icons
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EditIcon from "@mui/icons-material/Edit";

// Third-party libraries
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Make sure you include the Quill styles

// Your custom components (adjust paths as needed)
import CustomDatePicker from "../../CustomDatePicker/CustomDatePicker";
import FileView from "./FileView";
import UserAvatar from "../../Widgets/UserAvatar/UserAvatar";
// Your constants/enums (make sure this path is correct)
import Constants from "../../../assests/Constants/constants";
import AutocompleteSelect from "../../Widgets/AutocompleteSelect/AutocompleteSelect";
/**
 * Props:
 * @param {boolean} open - Whether the dialog is open
 * @param {function} onClose - Function to call when dialog should close
 * @param {boolean} isEditMode - If true, dialog is in "Edit" mode
 * @param {boolean} isAddMode - If true, indicates this is a new assignment (affects UI for deleting)
 * @param {Object} currentAssignment - The assignment data object
 * @param {function} setCurrentAssignment - Setter for updating assignment
 * @param {Array} subAccountants - Array of sub-accountant objects
 * @param {Array} categoryOptions - Array of category objects {value, label}
 * @param {Array} caseIdOptions - Array of client/case objects {value, label, [deductionsCase]}
 * @param {function} handleSaveAssignment - Called when user clicks "Save"
 * @param {function} handleEditAssignment - Called when user clicks "Edit" (if needed)
 * @param {function} handleCloseDialog - Called to close the dialog (often same as onClose)
 * @param {function} handleOpenModal - Possibly used to preview files (or any other modal action)
 * @param {function} handleOpenNewCategoryModal - Called if user picks "NEW_CATEGORY"
 * @param {function} changeOnAssignmentCategory - Updates the assignment's category
 * @param {function} changeOnAssignment - Updates the assignment's client/assignee info
 * @param {function} handleDropDocumentToAssignment - Drag/Drop doc into assignment description
 * @param {function} handleFileChange - When user selects a file for assignment
 * @param {function} handleRemoveDocumentFromAssignment - Remove attached doc from assignment
 * @param {function} handleDropDocumentToComment - Drag/Drop doc into comment
 * @param {function} handleCommentFileChange - When user selects a file for comment
 * @param {function} handleRemoveDocumentFromComment - Remove attached doc from comment
 * @param {function} handleUploadClick - Trigger hidden input for assignment upload
 * @param {Object} fileInputRefUpload - Ref to file input for assignment
 * @param {function} handleUploadClickComment - Trigger hidden input for comment upload
 * @param {Object} fileInputRefComment - Ref to file input for comment
 * @param {function} handleChangeCurrentDescription - Handler for assignment description Quill
 * @param {function} handleChangeNewCommentContent - Handler for new comment Quill
 * @param {Object} newComment - Current new-comment object { content, attachedDocuments, etc. }
 * @param {function} handleAddComment - Adds the new comment to assignment
 * @param {function} handleDeleteComment - Deletes an existing comment
 * @param {function} formatDate - Utility for formatting date (yyyy-mm-dd, etc.)
 * @param {function} formatDateWithHour - Utility for formatting date/time
 * @param {Object} user - The current user object
 * @param {function} setIsConfirmModalVisable - Sets state for a "confirm delete" modal
 * @param {function} handleDeleteAssignment - If you have a direct function to delete assignment
 * @param {boolean} dialogOpen - (Often same as `open`) whether the dialog is shown
 * @param {function} t - The i18n translation function
 */
const AssignmentDialog = ({
    open,
    onClose,

    /* Modes/States */
    isEditMode,
    isAddMode,

    /* Assignment Data */
    currentAssignment,
    setCurrentAssignment,

    /* Arrays/Data */
    subAccountants,
    categoryOptions,
    caseIdOptions,

    /* Parent Handlers */
    handleSaveAssignment,
    handleEditAssignment,
    handleCloseDialog,
    handleOpenModal,
    handleOpenNewCategoryModal,
    changeOnAssignmentCategory,
    changeOnAssignment,
    setIsConfirmModalVisable,
    handleDeleteAssignment, // If you directly delete

    /* File & Comment Handlers */
    handleDropDocumentToAssignment,
    handleFileChange,
    handleRemoveDocumentFromAssignment,
    handleDropDocumentToComment,
    handleCommentFileChange,
    handleRemoveDocumentFromComment,
    handleUploadClick,
    fileInputRefUpload,
    handleUploadClickComment,
    fileInputRefComment,

    /* Description & Comments */
    handleChangeCurrentDescription,
    handleChangeNewCommentContent,
    newComment,
    handleAddComment,
    handleDeleteComment,

    /* Helpers */
    formatDate,
    formatDateWithHour,

    /* Auth/User Data */
    user,

    /* i18n */
    t,
    direction
}) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth disableScrollLock>
            {/* ---------- Dialog Title ---------- */}
            <DialogTitle sx={{ textAlign: "left", position: "relative", fontWeight: "bold" }}>
                {isEditMode ? (
                    <>
                        <Box component="strong">:{t("AssignmentTitle")}</Box>
                        <TextField
                            value={currentAssignment?.summary || ""}
                            onChange={(e) =>
                                setCurrentAssignment({ ...currentAssignment, summary: e.target.value })
                            }
                            fullWidth
                            sx={{ direction: "ltr" }}
                        />
                    </>
                ) : (
                    <>
                        {currentAssignment?.summary || t("AssignmentDetails")} : {t("Assignment")}

                        <IconButton
                            onClick={handleEditAssignment}
                            sx={{ position: "absolute", top: 8, right: 8 }}
                        >
                            <EditIcon />
                        </IconButton>


                        {(currentAssignment?.reporterId === user?.accountantId && !user?.subAccountantId) && (
                            <IconButton
                                onClick={handleEditAssignment}
                                sx={{ position: "absolute", top: 8, right: 8 }}
                            >
                                <EditIcon />
                            </IconButton>
                        )}
                    </>
                )}
            </DialogTitle>

            {/* ---------- Dialog Content ---------- */}
            <DialogContent>
                <Box display="flex" flexDirection="column" gap={2} sx={{ textAlign: "left", direction: "rtl" }}>
                    {/* ---------- ROW 1: Reporter + Assignee ---------- */}
                    <Box display="flex" gap={2}>
                        {/* Right Column: Assignee */}
                        <Box flex={1}>
                            <Typography sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                                {isEditMode ? (
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                        <Box sx={{ direction: "ltr", marginBottom: "8px" }}>
                                            <Box component="strong" style={{ marginBottom: "50px" }}>
                                                {t("Assignee")}
                                            </Box>
                                            <AutocompleteSelect
                                                options={[
                                                    { value: "", label: t("NoAssignee") },
                                                    ...subAccountants.map((acc) => ({
                                                        value: acc.subAccountantId,
                                                        label: acc.name,
                                                    })),
                                                ]}
                                                selectedValues={
                                                    subAccountants
                                                        .map((acc) => ({ value: acc.subAccountantId, label: acc.name }))
                                                        .find((option) => option.value === currentAssignment?.assigneeId) || {
                                                        value: "",
                                                        label: t("NoAssignee"),
                                                    }
                                                }
                                                onChange={(event, values) =>
                                                    setCurrentAssignment({
                                                        ...currentAssignment,
                                                        assigneeId: values?.value || null,
                                                    })
                                                }
                                                placeholder={t("SelectAssignee")}
                                                isMultiple={false}
                                                width="300px"
                                                isDisabled={false}
                                                style={{ marginBottom: "20px" }}
                                            />
                                        </Box>
                                    </div>
                                ) : (
                                    <>
                                        <UserAvatar
                                            name={
                                                currentAssignment?.assigneeId
                                                    ? subAccountants.find(
                                                        (acc) => acc.subAccountantId === currentAssignment?.assigneeId
                                                    )?.name || t("NoAssignee")
                                                    : t("NoAssignee")
                                            }
                                            flip
                                            size={30}
                                        />
                                        <Box sx={{ fontSize: "1.25rem", marginTop: "8px" }}>:{t("Assignee")}</Box>
                                    </>
                                )}
                            </Typography>
                        </Box>

                        {/* Left Column: Reporter */}
                        <Box flex={1}>
                            <Typography sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                                {isEditMode ? (
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                        <Box sx={{ direction: "ltr", marginBottom: "8px" }}>
                                            <Box component="strong" style={{ marginBottom: "50px" }}>
                                                {t("Reporter")}
                                            </Box>
                                            <AutocompleteSelect
                                                options={subAccountants.map((acc) => ({
                                                    value: acc.subAccountantId,
                                                    label: acc.name,
                                                }))}
                                                selectedValues={
                                                    subAccountants
                                                        .map((acc) => ({ value: acc.subAccountantId, label: acc.name }))
                                                        .find((option) => option.value === currentAssignment?.reporterId) || null
                                                }
                                                onChange={(event, values) =>
                                                    setCurrentAssignment({
                                                        ...currentAssignment,
                                                        reporterId: values?.value || "",
                                                    })
                                                }
                                                placeholder={t("SelectReporter")}
                                                isMultiple={false}
                                                width="300px"
                                                isDisabled={false}
                                                style={{ marginBottom: "20px" }}
                                            />
                                        </Box>
                                    </div>
                                ) : (
                                    <>
                                        <UserAvatar
                                            name={
                                                subAccountants?.find((acc) => acc.subAccountantId === currentAssignment?.reporterId)
                                                    ?.name ||
                                                currentAssignment?.reporterId ||
                                                t("NoClient")
                                            }
                                            flip
                                            size={30}
                                        />
                                        <Box sx={{ fontSize: "1.25rem", marginTop: "8px" }}>:{t("Reporter")}</Box>
                                    </>
                                )}
                            </Typography>
                        </Box>
                    </Box>

                    {/* ---------- ROW 2: Status + Client Name ---------- */}
                    <Box display="flex" gap={2}>
                        {/* Status */}
                        <Box flex={1}>
                            <Typography sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                                {isEditMode ? (
                                    <Box>
                                        <Box component="strong">:{t("Status")}</Box>
                                        <Box sx={{ direction: "ltr" }}>
                                            <AutocompleteSelect
                                                options={[
                                                    { value: Constants.StatusEnum.ToDo, label: t("ToDo") },
                                                    { value: Constants.StatusEnum.InProgress, label: t("InProgress") },
                                                    { value: Constants.StatusEnum.Done, label: t("Done") },
                                                ]}
                                                selectedValues={{
                                                    value: currentAssignment?.status,
                                                    label: t(
                                                        Object.keys(Constants.StatusEnum).find(
                                                            (key) => Constants.StatusEnum[key] === currentAssignment?.status
                                                        )
                                                    ),
                                                }}
                                                onChange={(event, values) =>
                                                    setCurrentAssignment({
                                                        ...currentAssignment,
                                                        status: values?.value,
                                                    })
                                                }
                                                placeholder={t("SelectStatus")}
                                                isMultiple={false}
                                                width="300px"
                                                isDisabled={false}
                                            />
                                        </Box>
                                    </Box>
                                ) : (
                                    <>
                                        <Box
                                            sx={{
                                                fontSize: "1.25rem",
                                                marginTop: "8px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {t(
                                                Object.keys(Constants.StatusEnum).find(
                                                    (key) => Constants.StatusEnum[key] === currentAssignment?.status
                                                )
                                            ) || ""}
                                        </Box>
                                        <Box sx={{ fontSize: "1.25rem", marginTop: "8px" }}>:{t("Status")}</Box>
                                    </>
                                )}
                            </Typography>
                        </Box>

                        {/* Client */}
                        <Box flex={1}>
                            <Typography sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                                {isEditMode ? (
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                        <Box sx={{ direction: "ltr", marginBottom: "8px" }}>
                                            <Box component="strong" style={{ marginBottom: "50px" }}>
                                                {t("Client Name")}
                                            </Box>
                                            <AutocompleteSelect
                                                options={caseIdOptions.map((acc) => ({
                                                    value: acc.value,
                                                    label: acc.value ? `${acc.label} - ${acc.value}` : acc.label,
                                                }))}
                                                selectedValues={
                                                    caseIdOptions
                                                        .map((acc) => ({ value: acc.value, label: acc.label }))
                                                        .find(
                                                            (option) => option.value === (currentAssignment?.caseId)
                                                        ) || null
                                                }
                                                onChange={(event, values) => changeOnAssignment(values)}
                                                placeholder={t("SelectClient")}
                                                isMultiple={false}
                                                width="300px"
                                                isDisabled={false}
                                                style={{ marginBottom: "20px" }}
                                            />
                                        </Box>
                                    </div>
                                ) : (
                                    <>
                                        <UserAvatar
                                            name={
                                                caseIdOptions?.find((acc) => acc.value === currentAssignment?.caseId)?.label ||
                                                t("NoClient")
                                            }
                                            flip
                                            size={30}
                                            caseId={
                                                caseIdOptions?.find((acc) => acc.value === currentAssignment?.caseId)?.value ||
                                                null
                                            }
                                            deduction={
                                                caseIdOptions?.find((acc) => acc.value === currentAssignment?.caseId)
                                                    ?.deductionsCase || null
                                            }
                                        />
                                        <Box sx={{ fontSize: "1.25rem", marginTop: "8px" }}>:{t("Client Name")}</Box>
                                    </>
                                )}
                            </Typography>
                        </Box>
                    </Box>

                    {/* ---------- ROW 3: Created Date + Category ---------- */}
                    <Box display="flex" gap={2}>
                        {/* Created Date */}
                        <Box flex={1}>
                            <Typography sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                                {isEditMode ? (
                                    <Box sx={{ maxWidth: "300px" }}>
                                        <Box component="strong">:{t("CreationDate")}</Box>
                                        <CustomDatePicker
                                            date={currentAssignment?.createDate ? new Date(currentAssignment.createDate) : null}
                                            onDateChange={(date) => {
                                                if (date) {
                                                    setCurrentAssignment({
                                                        ...currentAssignment,
                                                        createDate: date.toISOString(), // Updates createDate when selected
                                                    });
                                                }
                                            }}
                                            height="48px"
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Box
                                            sx={{
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                fontSize: "1.25rem",
                                            }}
                                        >
                                            {formatDateWithHour(currentAssignment?.createDate) || ""}
                                        </Box>
                                        <Box sx={{ fontSize: "1.25rem" }}>:{t("CreationDate")}</Box>
                                    </>
                                )}
                            </Typography>
                        </Box>

                        {/* Category */}
                        <Box flex={1}>
                            <Typography sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                                {isEditMode ? (
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                        <Box sx={{ direction: "ltr", marginBottom: "8px" }}>
                                            <Box component="strong" style={{ marginBottom: "50px" }}>
                                                {t("Category")}
                                            </Box>
                                            <AutocompleteSelect
                                                options={categoryOptions.map((acc) => ({
                                                    value: acc.value,
                                                    label: acc.label,
                                                }))}
                                                selectedValues={
                                                    categoryOptions.find(
                                                        (option) => option.value === currentAssignment?.category
                                                    ) || null
                                                }
                                                onChange={(event, values) => {
                                                    if (values?.value === "NEW_CATEGORY") {
                                                        // Open the modal if "NEW_CATEGORY" is selected
                                                        handleOpenNewCategoryModal();
                                                        // Possibly set "NEW_CATEGORY" or skip
                                                        changeOnAssignmentCategory(values);
                                                    } else {
                                                        // Otherwise, update the selected category
                                                        changeOnAssignmentCategory(values);
                                                    }
                                                }}
                                                placeholder={t("SelectCategory")}
                                                isMultiple={false}
                                                width="300px"
                                                isDisabled={false}
                                                style={{ marginBottom: "20px" }}
                                            />
                                        </Box>
                                    </div>
                                ) : (
                                    <>
                                        <Box
                                            sx={{
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                fontSize: "1.25rem",
                                            }}
                                        >
                                            {categoryOptions?.find((acc) => acc.value === currentAssignment?.category)
                                                ?.label || t("NoCategory")}
                                        </Box>
                                        <Box sx={{ fontSize: "1.25rem" }}>:{t("Category")}</Box>
                                    </>
                                )}
                            </Typography>
                        </Box>
                    </Box>

                    <Divider sx={{ margin: "8px 0" }} />

                    {/* ---------- DESCRIPTION ---------- */}
                    <Typography variant="h6" sx={{ textAlign: "left", margin: "0 0 2px 0", fontWeight: "bold" }}>
                        :{t("Description")}
                    </Typography>
                    <Typography
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            gap: 1,
                            alignItems: "top",
                            ...(isEditMode
                                ? {}
                                : {
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    padding: "10px",
                                    minWidth: "100px",
                                }),
                        }}
                    >
                        {isEditMode ? (
                            <Box style={{ width: "96.7%" }}>
                                <div
                                    onDrop={handleDropDocumentToAssignment}
                                    onDragOver={(e) => e.preventDefault()}
                                    style={{ position: "relative" }}
                                >
                                    <IconButton
                                        sx={{
                                            position: "absolute",
                                            right: 8,
                                            top: 3,
                                            zIndex: 10,
                                        }}
                                        onClick={handleUploadClick}
                                    >
                                        <CloudUploadIcon />
                                    </IconButton>
                                    {/* Hidden file input */}
                                    <input
                                        ref={fileInputRefUpload}
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                    />
                                    <ReactQuill
                                        theme="snow"
                                        value={currentAssignment?.description || ""}
                                        onChange={handleChangeCurrentDescription}
                                        formats={[
                                            "bold",
                                            "italic",
                                            "underline",
                                            "strike",
                                            "blockquote",
                                            "list",
                                            "bullet",
                                            "indent",
                                            "direction",
                                            "align",
                                            "link",
                                            "image",
                                            "clean",
                                        ]}
                                        modules={{
                                            toolbar: [["bold", "italic", "underline", "strike"]],
                                        }}
                                        className="myCustomQuill"
                                        placeholder={t("TypeYourCommentHere")}
                                        style={{
                                            overflowY: "auto",
                                        }}
                                    />
                                    {/* Attached Documents for Assignment */}
                                    {currentAssignment?.attachedDocuments?.length ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                flexWrap: "nowrap",
                                                overflowX: "auto",
                                                borderRadius: "4px",
                                                width: "100%",
                                            }}
                                        >
                                            {currentAssignment.attachedDocuments.map((file, index) => (
                                                <FileView
                                                    key={index}
                                                    file={file}
                                                    onRemove={() => handleRemoveDocumentFromAssignment(index)}
                                                    onOpenModal={handleOpenModal}
                                                />
                                            ))}
                                        </div>
                                    ) : (
                                        <div />
                                    )}
                                </div>
                            </Box>
                        ) : (
                            <>
                                {/* Non-Edit Mode: Show HTML description */}
                                <Box
                                    sx={{
                                        "& ul, & ol": {
                                            paddingInlineStart: "20px",
                                            margin: 0,
                                            listStylePosition: "inside",
                                        },
                                        "& li": {
                                            textAlign: "inherit",
                                            direction: "inherit",
                                        },
                                    }}
                                    style={{
                                        direction: currentAssignment?.description?.includes("ql-direction-rtl") ? "rtl" : "ltr",
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: currentAssignment?.description || "",
                                    }}
                                />
                                {currentAssignment?.attachedDocuments?.length ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "8px",
                                            flexWrap: "nowrap",
                                            overflowX: "auto",
                                            width: "100%",
                                        }}
                                    >
                                        {currentAssignment.attachedDocuments.map((file, index) => (
                                            <FileView
                                                key={index}
                                                file={file}
                                                onRemove={() => handleRemoveDocumentFromAssignment(index)}
                                                isCreate={false}
                                                onOpenModal={handleOpenModal}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <div />
                                )}
                            </>
                        )}
                    </Typography>

                    {/* ---------- PRIORITY ---------- */}
                    <Typography sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                        {isEditMode ? (
                            <Box>
                                <Box component="strong">:{t("Priority")}</Box>
                                <Box sx={{ direction: "ltr" }}>
                                    <AutocompleteSelect
                                        options={[
                                            { value: Constants.PriorityEnum.Low, label: t("Low") },
                                            { value: Constants.PriorityEnum.High, label: t("High") },
                                            { value: Constants.PriorityEnum.Critical, label: t("Critical") },
                                        ]}
                                        selectedValues={{
                                            value: currentAssignment?.priority,
                                            label: t(
                                                Object.keys(Constants.PriorityEnum).find(
                                                    (key) => Constants.PriorityEnum[key] === currentAssignment?.priority
                                                )
                                            ),
                                        }}
                                        onChange={(event, values) =>
                                            setCurrentAssignment({
                                                ...currentAssignment,
                                                priority: values?.value,
                                            })
                                        }
                                        placeholder={t("SelectPriority")}
                                        isMultiple={false}
                                        width="300px"
                                        isDisabled={false}
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        fontSize: "1.25rem",
                                        marginTop: "8px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {t(
                                        Object.keys(Constants.PriorityEnum).find(
                                            (key) => Constants.PriorityEnum[key] === currentAssignment?.priority
                                        )
                                    ) || ""}
                                </Box>
                                <Box
                                    sx={{
                                        fontSize: "1.25rem",
                                        marginTop: "8px",
                                    }}
                                >
                                    :{t("Priority")}
                                </Box>
                            </>
                        )}
                    </Typography>

                    {/* ---------- DUE DATE ---------- */}
                    <Typography sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                        {isEditMode ? (
                            <Box sx={{ maxWidth: "300px" }}>
                                <Box component="strong">:{t("DueDate")}</Box>
                                <CustomDatePicker
                                    date={currentAssignment?.dueDate ? new Date(currentAssignment.dueDate) : null}
                                    onDateChange={(date) => {
                                        if (date) {
                                            setCurrentAssignment({
                                                ...currentAssignment,
                                                dueDate: date.toISOString(),
                                            });
                                        }
                                    }}
                                    height="48px"
                                />
                            </Box>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        fontSize: "1.25rem",
                                    }}
                                >
                                    {formatDate(currentAssignment?.dueDate) || ""}
                                </Box>
                                <Box sx={{ fontSize: "1.25rem" }}>:{t("DueDate")}</Box>
                            </>
                        )}
                    </Typography>

                    {/* ---------- COMMENTS (only when NOT in edit/add mode) ---------- */}
                    <Divider sx={{ margin: "8px 0" }} />
                    {(!isAddMode && !isEditMode) && (
                        <>
                            <Box>
                                <Typography variant="h6" sx={{ textAlign: "left", margin: "0 0 8px 0", fontWeight: "bold" }}>
                                    {t("Comments")}
                                </Typography>
                                {/* ReactQuill Editor for new comment */}
                                <div
                                    onDrop={handleDropDocumentToComment}
                                    onDragOver={(e) => e.preventDefault()}
                                    style={{ position: "relative" }}
                                >
                                    {/* Upload Icon */}
                                    <IconButton
                                        sx={{
                                            position: "absolute",
                                            right: 8,
                                            top: 3,
                                            zIndex: 10,
                                        }}
                                        onClick={handleUploadClickComment}
                                    >
                                        <CloudUploadIcon />
                                    </IconButton>
                                    {/* Hidden file input */}
                                    <input
                                        ref={fileInputRefComment}
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={handleCommentFileChange}
                                    />
                                    <ReactQuill
                                        theme="snow"
                                        value={newComment.content || ""}
                                        onChange={handleChangeNewCommentContent}
                                        formats={[
                                            "bold",
                                            "italic",
                                            "underline",
                                            "strike",
                                            "blockquote",
                                            "list",
                                            "bullet",
                                            "indent",
                                            "direction",
                                            "align",
                                            "link",
                                            "image",
                                            "clean",
                                        ]}
                                        modules={{
                                            toolbar: [["bold", "italic", "underline", "strike"]],
                                        }}
                                        className="myCustomQuill"
                                        placeholder={t("TypeYourCommentHere")}
                                        style={{
                                            overflowY: "auto",
                                        }}
                                    />

                                    {/* Add Comment Button + Attached Files */}
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            gap: "16px",
                                            overflow: "hidden",
                                            padding: "8px",
                                        }}
                                    >
                                        {/* Attached Documents Preview for new comment */}
                                        {newComment?.attachedDocuments?.length ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                    flexWrap: "nowrap",
                                                    overflowX: "scroll",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "4px",
                                                    width: "80%",
                                                }}
                                            >
                                                {newComment.attachedDocuments.map((file, index) => (
                                                    <FileView
                                                        key={index}
                                                        file={file}
                                                        onRemove={() => handleRemoveDocumentFromComment(index)}
                                                        onOpenModal={handleOpenModal}
                                                    />
                                                ))}
                                            </div>
                                        ) : (
                                            <div />
                                        )}
                                        {/* Button for adding a comment */}
                                        <div
                                            style={{
                                                width: "20%",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleAddComment(currentAssignment.id)}
                                            >
                                                {t("AddComment")}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Box>

                            {/* Existing Comments */}
                            {currentAssignment?.comments?.map((comment, index) => (
                                <Box
                                    key={index}
                                    p={2}
                                    border="1px solid #ccc"
                                    borderRadius="8px"
                                    mb={2}
                                    sx={{ direction: "rtl", position: "relative" }}
                                >
                                    {/* Commentor Name */}
                                    <Typography sx={{ textAlign: "right", position: "relative" }}>
                                        <div style={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "flex-end" }}>
                                            <strong>{comment.commentorName}</strong>
                                            <UserAvatar name={comment.commentorName} flip={true} withName={false} />
                                        </div>
                                    </Typography>

                                    {/* Comment Content */}
                                    <Box
                                        sx={{
                                            "& ul, & ol": {
                                                paddingInlineStart: "20px",
                                                margin: 0,
                                                listStylePosition: "inside",
                                            },
                                            "& li": {
                                                textAlign: "inherit",
                                                direction: "inherit",
                                            },
                                        }}
                                        style={{
                                            direction: comment.content.includes("ql-direction-rtl") ? "rtl" : "ltr",
                                        }}
                                        dangerouslySetInnerHTML={{ __html: comment.content }}
                                    />

                                    {/* Attached Docs */}
                                    {comment?.attachedDocuments?.length ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                flexWrap: "nowrap",
                                                overflowX: "auto",
                                                width: "80%",
                                            }}
                                        >
                                            {comment.attachedDocuments.map((file, idx) => (
                                                <FileView
                                                    key={idx}
                                                    file={file}
                                                    isCreate={false}
                                                    onRemove={() => handleRemoveDocumentFromComment(idx)}
                                                    onOpenModal={handleOpenModal}
                                                />
                                            ))}
                                        </div>
                                    ) : (
                                        <div />
                                    )}

                                    {/* Comment Date */}
                                    <Typography sx={{ textAlign: "right" }}>
                                        {formatDateWithHour(comment.commentDate)}
                                    </Typography>

                                    {/* Delete icon for comment owner */}
                                    {((!user?.subAccountantId && user?.accountantId === comment.commentorId) ||
                                        user?.subAccountantId === comment.commentorId) && (
                                            <IconButton
                                                onClick={() => handleDeleteComment(comment.id)}
                                                sx={{ position: "absolute", top: 8, right: 8 }}
                                                color="error"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                </Box>
                            ))}
                        </>
                    )}
                </Box>
            </DialogContent>

            {/* ---------- Dialog Actions ---------- */}
            <DialogActions sx={{ justifyContent: "center", gap: "20px", padding: "16px" }} className={`${direction}`}>
                {/* DELETE BUTTON (shown only in edit mode & not for new assignments) */}
                {isEditMode && !isAddMode && (
                    <Button
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() => setIsConfirmModalVisable(true)}
                        sx={{
                            fontWeight: "bold",
                            padding: "10px 20px",
                            fontSize: "14px",
                            border: "2px solid",
                            "&:hover": {
                                backgroundColor: "#ffebee",
                                borderColor: "#d32f2f",
                            },
                        }}
                    >
                        {t("Delete")}
                    </Button>
                )}

                {/* SAVE BUTTON (Styled as a modern primary button) */}
                {isEditMode && (
                    <Button
                        variant="contained"
                        onClick={handleSaveAssignment}
                        startIcon={<SaveIcon />}
                        sx={{
                            fontWeight: "bold",
                            padding: "10px 24px",
                            fontSize: "14px",
                            background: "linear-gradient(45deg, #007AFF 30%, #0051D2 90%)",
                            color: "#fff",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            "&:hover": {
                                background: "linear-gradient(45deg, #0051D2 30%, #003D91 90%)",
                            },
                        }}
                    >
                        {t("Save")}
                    </Button>
                )}

                {/* CLOSE BUTTON (Styled as a modern cancel button) */}
                <Button
                    variant="outlined"
                    color="inherit"
                    startIcon={<CloseIcon />}
                    onClick={handleCloseDialog}
                    sx={{
                        fontWeight: "bold",
                        padding: "10px 20px",
                        fontSize: "14px",
                        border: "2px solid #757575",
                        color: "#424242",
                        "&:hover": {
                            backgroundColor: "#f5f5f5",
                        },
                    }}
                >
                    {t("Close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AssignmentDialog;
