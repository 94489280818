import React, { useState, useEffect, useMemo } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import {
    Box,
    Typography,
    Checkbox,
    IconButton,
    FormControlLabel,
    Tooltip,
} from '@mui/material';
import {
    KeyboardArrowUp as KeyboardArrowUpIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Collapse, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

// Replace with your actual imports
import NoDocumentsPdf from '../../../assests/pdf/NoDocument.pdf';
import NoDocumentScanned from '../../../assests/images/svgs/NoDocumentScanned.svg';
import bankIcon from '../../../assests/images/bank-icon.png';
import creditCardIcon from '../../../assests/images/credit-card-icon.png';
import checkIcon from '../../../assests/images/check-icon.png';
import cashIcon from '../../../assests/images/cash-icon.png';

// Styled DataGrid component
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    width: '100%',
    '& .MuiAutocomplete-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '& *': {
        boxSizing: 'border-box !important',
    },
    '& .MuiDataGrid-columnHeaders': {
        // opacity: 0.7,
        backgroundColor: '#BFC8FF',
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#BFC8FF',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        color: '#304FFF',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    '& .MuiDataGrid-cell': {
        borderTop: '1px solid rgba(191, 191, 193, 0.56)',
        backgroundColor: 'rgba(234, 237, 255, 0.32)',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: 'rgba(191, 200, 255, 0.3)',
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-cell': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '& .disableUser': {
        pointerEvents: 'none',
        opacity: 0.5,
    },
    '& .super-app-theme--Header': {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        fontWeight: 'bold',
    },
    '& .super-app-theme--SubRow': {
        backgroundColor: 'rgba(234, 237, 255, 0.5)',
    },
    '& .super-app-theme--MainRow': {
        backgroundColor: 'rgba(234, 237, 255, 0.32)',
    },
}));

const PaymentAcceptanceDataGrid = (props) => {
    // Destructure props
    const {
        t,
        data,
        subAccountantOptions,
        client,
        isRange,
        isClosedReportingMonth,
        accountCardOptions,
        openPdfInNewTab,
        formatDate,
        beautifyNumber,
        Constants,
        handleDeleteClick,
        handleEdit,
        handleSelectRows,
        selectedRows,
        handleSelectAllClick,
        isAllSelected,
        isSelected,
        direction,
    } = props;

    const { i18n } = useTranslation();

    const [rows, setRows] = useState(data);
    const [expandedRows, setExpandedRows] = useState({});
    const [sortModel, setSortModel] = useState([]);

    // Map for accountCardOptions
    const accountCardMap = accountCardOptions.reduce((acc, option) => {
        acc[option.cardId] = option.name;
        return acc;
    }, {});

    // Handle row expand/collapse
    const handleExpandClick = (id) => {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [id]: !prevExpandedRows[id],
        }));
    };

    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    // Function to get displayed rows
    const getDisplayedRows = () => {
        const result = [];

        // Determine sorting field and direction
        let sortField = null;
        let sortDirection = 'asc'; // Default sort direction

        if (sortModel && sortModel.length > 0) {
            sortField = sortModel[0].field;
            sortDirection = sortModel[0].sort;
        }

        // Fields that are part of the parent rows
        const parentSortFields = [
            'description',
            'confirmationNumber',
            'reference',
            'withholdingTax',
            'amount',
            'paymentAcceptanceType',
            'debitCreditAccountAmount',
            'accountCodeName',
            'uploadDate',
            'uploadNumber',
        ];

        // Is the column being sorted a parent row field?
        const isParentSortField = parentSortFields.includes(sortField);

        // Sort parent rows if the selected column belongs to them
        const sortedData = [...rows].sort((a, b) => {
            if (isParentSortField && sortField) {
                const valueA = a[sortField];
                const valueB = b[sortField];

                if (valueA === undefined) return 1;
                if (valueB === undefined) return -1;

                if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
                if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
                return 0;
            } else {
                // Keep the original order if sorting by a sub-row field
                return 0;
            }
        });

        // Loop over the main rows
        sortedData.forEach((row) => {
            // Add main row
            result.push({ ...row, isSubRow: false });

            // Check if the row is expanded
            if (expandedRows[row.id]) {
                // Add subrows for checks, creditCards, cash, bankTransactions
                ['checks', 'creditCards', 'cash', 'bankTransactions'].forEach((key) => {
                    if (row[key] && row[key].length > 0) {
                        // Header for subrows
                        result.push({
                            id: `${row.id}-${key}-header`,
                            parentId: row.id,
                            isSubRow: true,
                            isHeader: true,
                            level: 1,
                            transactionType: t(key),
                        });

                        // Subrows
                        let sortedSubRows = [...row[key]];

                        if (!isParentSortField && sortField) {
                            sortedSubRows.sort((a, b) => {
                                const valueA = a[sortField];
                                const valueB = b[sortField];

                                if (valueA === undefined) return 1;
                                if (valueB === undefined) return -1;

                                if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
                                if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
                                return 0;
                            });
                        }

                        sortedSubRows.forEach((item, index) => {
                            result.push({
                                ...item,
                                id: `${row.id}-${key}-${index}`,
                                parentId: row.id,
                                isSubRow: true,
                                isHeader: false,
                                level: 2,
                                paymentAcceptanceType: key,
                                debitAccountName: accountCardMap[item.debitAccount] || '',
                                creditAccountName: accountCardMap[item.creditAccount] || '',
                            });
                        });
                    }
                });
            }
        });

        return result;
    };

    const displayedRows = useMemo(
        () => getDisplayedRows(),
        [rows, expandedRows, sortModel]
    );

    // Define columns
    const columns = [

        {
            field: 'expand',
            headerName: '',
            width: 50,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                const row = params.row;
                const hasSubRows =
                    (row.checks && row.checks.length > 0) ||
                    (row.creditCards && row.creditCards.length > 0) ||
                    (row.cash && row.cash.length > 0) ||
                    (row.bankTransactions && row.bankTransactions.length > 0);
                return hasSubRows ? (
                    <IconButton size="small" onClick={() => handleExpandClick(row.id)}>
                        {expandedRows[row.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                ) : null;
            },
        },

        {
            field: 'document',
            headerName: t('Document'),
            headerAlign: 'center',
            align: 'center',
            width: 150,
            renderCell: (params) => {
                const row = params.row;
                if (row.isSubRow && !row.isHeader) {
                    // Subrow document (e.g., checks, credit cards)
                    const iconMap = {
                        checks: checkIcon,
                        creditCards: creditCardIcon,
                        cash: cashIcon,
                        bankTransactions: bankIcon,
                    };
                    const icon = iconMap[row.paymentAcceptanceType] || null;

                    return (
                        <div
                            className="pdf-td-payment-acceptance"
                            onClick={() => openPdfInNewTab(row.documentUrl)}
                        >
                            {row.documentUrl ? (
                                <>
                                    <div className="iframe-overlay-payment-acceptance"></div>
                                    <iframe
                                        src={`${row.documentUrl}#page=1`}
                                        className="table-pdf"
                                        title={row.title}
                                        style={{ pointerEvents: 'none' }}
                                    ></iframe>
                                </>
                            ) : icon ? (
                                <img
                                    src={icon}
                                    alt="Icon"
                                    style={{ height: '100%', width: '100%' }}
                                />
                            ) : null}
                        </div>
                    );
                } else if (!row.isSubRow) {
                    // Main row document
                    return (
                        <div
                            className="iframe-container"
                            onClick={() => openPdfInNewTab(row.receiptDocumentUrl)}
                        >
                            {row.paymentAcceptanceType !== 'תשלום' &&
                                row.receiptDocumentUrl !== null ? (
                                <iframe
                                    className="zoom-iframe"
                                    src={row.receiptDocumentUrl}
                                    width="100px"
                                    height="50px"
                                    title={row.title}
                                    style={{ pointerEvents: 'none' }}
                                />
                            ) : (
                                <img
                                    src={NoDocumentScanned}
                                    alt="No Document Scanned"
                                    width="100px"
                                    height="50px"
                                    onClick={() => openPdfInNewTab(row.receiptDocumentUrl)}
                                    style={{ cursor: 'pointer' }}
                                />
                            )}
                        </div>
                    );
                } else {
                    // Header row
                    return null;
                }
            },
        },
        {
            field: 'processedBy',
            headerName: t('DocumentProcessedBy'),
            flex: 1,
            renderCell: (params) => {
                const accountant = subAccountantOptions.find(option => option.value === params.value);
                return accountant ? accountant.label : '-';
            }
        },
        {
            field: 'processed',
            headerName: t('DocumentProcessed'),
            headerAlign: 'center',
            align: 'center',
            width: 150,
            renderCell: (params) => {
                const row = params.row;
                if (!row.isSubRow) {
                    let isIdInChecks = client.documentIds.checks.includes(row.id);
                    return !isIdInChecks ? (
                        <p
                            style={{
                                color: 'green',
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >
                            {t('processed')}
                        </p>
                    ) : (
                        <p
                            style={{
                                color: 'red',
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >
                            {t('unprocessed')}
                        </p>
                    );
                } else {
                    return null;
                }
            },
        },
        {
            field: 'description',
            headerName: t('Description'),
            headerAlign: 'center',
            align: 'left',
            width: 200,
            renderCell: (params) => {
                const row = params.row;
                const indentation = row.level ? row.level * 20 : 0;

                if (row.isHeader) {
                    return (
                        <div style={{ paddingLeft: `${indentation}px`, fontWeight: 'bold' }}>
                            {row.transactionType}
                        </div>
                    );
                } else if (row.isSubRow) {
                    return <div style={{ paddingLeft: `${indentation}px` }}>{row.description}</div>;
                } else {
                    return (
                        <div style={{ maxHeight: '50px', overflowY: 'auto' }}>
                            {row.description}
                        </div>
                    );
                }
            },
            sortable: true,
            sortComparator: (v1, v2, param1, param2) => {
                const row1 = param1.row || {};
                const row2 = param2.row || {};

                if (row1.isSubRow && row2.isSubRow) {
                    return v1.localeCompare(v2);
                }
                if (row1.isSubRow && !row2.isSubRow) {
                    return 1;
                }
                if (!row1.isSubRow && row2.isSubRow) {
                    return -1;
                }

                return v1.localeCompare(v2);
            },
        },
        // Include the rest of the columns as previously defined
        {
            field: 'confirmationNumber',
            headerName: t('ConfirmationNumber'),
            headerAlign: 'center',
            align: 'center',
            width: 150,
            renderCell: (params) => {
                const row = params.row;
                if (!row.isSubRow) {
                    return <span>{row.confirmationNumber?.toLocaleString()}</span>;
                } else {
                    return null;
                }
            },
            sortable: true,
        },
        {
            field: 'reference',
            headerName: t('Reference'),
            headerAlign: 'center',
            align: 'center',
            width: 150,
            renderCell: (params) => {
                const row = params.row;
                if (!row.isSubRow) {
                    return <span>{row.reference}</span>;
                } else {
                    return null;
                }
            },
            sortable: true,
        },
        {
            field: 'withholdingTax',
            headerName: t('WithholdingTax'),
            headerAlign: 'center',
            align: 'center',
            width: 150,
            renderCell: (params) => {
                const row = params.row;
                if (!row.isSubRow) {
                    return (
                        <p
                            style={{
                                color: 'green',
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >
                            {row.withholdingTax?.toLocaleString()}
                        </p>
                    );
                } else {
                    return null;
                }
            },
            sortable: true,
        },
        {
            field: 'amount',
            headerName: t('Amount'),
            headerAlign: 'center',
            align: 'center',
            width: 150,
            renderCell: (params) => {
                const row = params.row;
                if (row.isSubRow && !row.isHeader) {
                    return (
                        <p
                            style={{
                                color: 'green',
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >
                            {beautifyNumber(row.amount)}
                        </p>
                    );
                } else if (!row.isSubRow) {
                    return (
                        <p
                            style={{
                                color: 'green',
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >
                            {beautifyNumber(row.amount)}
                        </p>
                    );
                } else {
                    return null;
                }
            },
            sortable: true,
            sortComparator: (v1, v2) => v1 - v2,
        },
        {
            field: 'paymentAcceptanceType',
            headerName: t('DocumentType'),
            headerAlign: 'center',
            align: 'center',
            width: 150,
            renderCell: (params) => {
                const row = params.row;
                if (!row.isSubRow) {
                    return <span>{row.paymentAcceptanceType}</span>;
                } else {
                    return null;
                }
            },
            sortable: true,
        },
        {
            field: 'debitCreditAccountAmount',
            headerName: t('DebitCreditAccountAmount'),
            headerAlign: 'center',
            align: 'center',
            width: 150,
            renderCell: (params) => {
                const row = params.row;
                if (!row.isSubRow) {
                    return (
                        <p
                            style={{
                                color: 'green',
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >
                            {beautifyNumber(row.debitCreditAccountAmount)}
                        </p>
                    );
                } else {
                    return null;
                }
            },
            sortable: true,
        },
        {
            field: 'accountCodeName',
            headerName: t('CheckTitle'),
            headerAlign: 'center',
            align: 'center',
            width: 150,
            renderCell: (params) => {
                const row = params.row;
                if (!row.isSubRow) {
                    return <span>{row.accountCodeName}</span>;
                } else {
                    return null;
                }
            },
            sortable: true,
        },
        {
            field: 'uploadDate',
            headerName: t('Upload Date'),
            headerAlign: 'center',
            align: 'center',
            width: 150,
            renderCell: (params) => {
                const row = params.row;
                if (!row.isSubRow) {
                    return <span>{new Date(row.uploadDate).toISOString().split('T')[0]}</span>;
                } else {
                    return null;
                }
            },
            sortable: true,
            sortComparator: (v1, v2) => new Date(v1) - new Date(v2),
        },
        {
            field: 'uploadNumber',
            headerName: t('UploadNumber'),
            headerAlign: 'center',
            align: 'center',
            width: 150,
            renderCell: (params) => {
                const row = params.row;
                if (!row.isSubRow) {
                    return <span>{row.uploadNumber}</span>;
                } else {
                    return null;
                }
            },
            sortable: true,
        },
        {
            field: 'actions',
            headerName: t('Actions'),
            width: 100,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                const row = params.row;
                if (!row.isSubRow && !row.isHeader) {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <IconButton size="small" onClick={() => handleEdit(row)}>
                                <EditIcon style={{ color: '#E57C22' }} />
                            </IconButton>
                            {(isRange ? !row.isClosedReportingMonth : !isClosedReportingMonth) && (
                                <IconButton size="small" onClick={() => handleDeleteClick(row.id)}>
                                    <DeleteIcon style={{ color: '#E57C22' }} />
                                </IconButton>
                            )}
                        </div>
                    );
                } else {
                    return null;
                }
            },
        },
    ];

    const CustomRow = (props) => {
        const { id, row, index, style } = props;
        const isExpanded = expandedRows[row.id];

        return (
            <div key={id} style={style}>
                {/* Main Row */}
                <div className={`MuiDataGrid-row ${getRowClassName({ row })}`}>
                    {columns.map((column) => (
                        <div
                            key={column.field}
                            className="MuiDataGrid-cell"
                            style={{ width: column.width, display: 'inline-block' }}
                        >
                            {column.renderCell
                                ? column.renderCell({ row, value: row[column.field], field: column.field })
                                : row[column.field]}
                        </div>
                    ))}
                </div>
                {/* Expanded Content */}
                {isExpanded && (
                    <div className="MuiDataGrid-row" style={{ backgroundColor: '#f9f9f9' }}>
                        <div className="MuiDataGrid-cell" style={{ width: '100%' }}>
                            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                {/* Render the subrows content as per your code */}
                                {(row.checks.length > 0 ||
                                    row.creditCards.length > 0 ||
                                    row.cash.length > 0 ||
                                    row.bankTransactions.length > 0) && (
                                        <Box sx={{ margin: 1 }}>
                                            {/* Checks */}
                                            {row.checks.length > 0 && (
                                                <Box sx={{ margin: 1 }} dir="rtl">
                                                    <Typography variant="h6" gutterBottom align="right">
                                                        {t('Checks')}
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom align="right" style={{ fontWeight: 'bold' }}>
                                                        {row.paymentAcceptanceType === 'תקבול'
                                                            ? `${t('TotalDebitAccount')}: ${row.checks.reduce(
                                                                (total, check) => total + check.amount,
                                                                0
                                                            )} (${t('Debit Account')}: ${accountCardMap[row.checks[0].debitAccount] || ''})`
                                                            : `${t('TotalCreditAccount')}: ${row.checks.reduce(
                                                                (total, check) => total + check.amount,
                                                                0
                                                            )} (${t('Credit Account')}: ${accountCardMap[row.checks[0].creditAccount] || ''})`}
                                                    </Typography>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                {/* Define your TableCells here */}
                                                                {/* ... */}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {row.checks.map((check) => (
                                                                <TableRow key={check.paymentDate}>
                                                                    {/* Define your TableCells here */}
                                                                    {/* ... */}
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            )}
                                            {/* Repeat similar blocks for Credit Cards, Cash, and Bank Transactions */}
                                            {/* ... */}
                                        </Box>
                                    )}
                            </Collapse>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    // Define getRowClassName
    const getRowClassName = (params) => {
        const row = params.row;
        if (row.isHeader) return 'super-app-theme--Header';
        if (row.isSubRow && !row.isHeader) return 'super-app-theme--SubRow';
        return 'super-app-theme--MainRow';
    };

    // Handle row selection
    const handleSelectionModelChange = (ids) => {
        handleSelectRows(ids);
    };

    return (
        <div className={`${direction}`} style={{ width: '100%', height: '60vh' }}>
            <StyledDataGrid
                rows={displayedRows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50]}
                checkboxSelection
                disableSelectionOnClick
                selectionModel={selectedRows}
                onSelectionModelChange={handleSelectionModelChange}
                getRowClassName={getRowClassName}
                getRowId={(row) => row.id}
                components={{
                    Toolbar: GridToolbar,
                    Row: CustomRow,
                }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                onSortModelChange={(model) => setSortModel(model)}
                sortingOrder={['asc', 'desc']}
            />
        </div>
    );
};

export default PaymentAcceptanceDataGrid;
