import React from 'react';

const InvoiceSelectionModal = ({ isOpen, onClose, selectedLanguage, handleOpenSpecificInvoiceCreator, isTaxExempt,t }) => {
    if (!isOpen) return null;

    return (
        <div className='create-modal-overlay' onClick={onClose}>
            <div className="modal-background">
                <div className={`modal-Invoice-content ${selectedLanguage === 'he' || selectedLanguage === 'ar' ? 'rtl-text' : ''}`}
                    style={{ width: '20%', height: '30%', gap: '10px' }}>
                    <h2 style={{ marginBottom: '30px' }}>{t('SelectDocumentType')}</h2>

                    {isTaxExempt ? (
                        <>
                            <div className="create-selector" onClick={() => handleOpenSpecificInvoiceCreator('Transaction')}>
                                {t('Transaction')}
                            </div>
                            <div className="create-selector" onClick={() => handleOpenSpecificInvoiceCreator('Receipt')}>
                                {t('Receipt')}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="create-selector" onClick={() => handleOpenSpecificInvoiceCreator('TaxInvoice')}>
                                {t('TaxInvoice')}
                            </div>
                            <div className="create-selector" onClick={() => handleOpenSpecificInvoiceCreator('CreditNote')}>
                                {t('CreditNote')}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default InvoiceSelectionModal;
