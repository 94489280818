import { useState, useEffect, useRef } from 'react';
import { IoArrowBackCircle, IoArrowForwardCircle } from 'react-icons/io5';
import { FilePreview } from '../../../Utils/filePreview';

const FilePreviewCarousel = ({ allDocUrls, docIndex, setDocIndex, t }) => {
    const [startIndex, setStartIndex] = useState(0);
    const scrollRef = useRef(null);
    const visibleFiles = 3; // Show 4 full previews
    const totalFiles = allDocUrls.length;

    // Function to scroll left
    const handleScrollLeft = () => {
        if (startIndex > 0) setStartIndex(startIndex - 1);
    };

    // Function to scroll right
    const handleScrollRight = () => {
        if (startIndex + visibleFiles < totalFiles) setStartIndex(startIndex + 1);
    };

    // Keyboard Navigation
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') handleScrollLeft();
            if (event.key === 'ArrowRight') handleScrollRight();
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [startIndex]);

    return (
        <div
            className="file-carousel-container"
            style={{
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                width: '100%',
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            {/* Next Button */}

            {startIndex + visibleFiles < totalFiles && (
                <IoArrowForwardCircle
                    size={45}
                    color="#304FFF"
                    onClick={handleScrollRight}
                    style={{ cursor: 'pointer' }}
                />
            )}
            {/* File Previews (Show 4 fully + 5th as shadow effect) */}
            <div
                ref={scrollRef}
                style={{
                    display: 'flex',
                    gap: '10px',
                    overflow: 'hidden',
                    width: '350px', // Adjusted for 4.5 items
                    position: 'relative',
                }}
            >
                {allDocUrls.slice(startIndex, startIndex + visibleFiles + 1).map((fileUrl, idx) => (
                    <div
                        key={startIndex + idx}
                        onClick={() => setDocIndex(startIndex + idx)}
                        style={{
                            cursor: 'pointer',
                            opacity: idx === visibleFiles ? 0.5 : 1, // Shadow effect for the 5th file
                            transform: idx === visibleFiles ? 'scale(0.9)' : 'scale(1)',
                            transition: 'opacity 0.3s ease, transform 0.3s ease',
                        }}
                    >
                        <FilePreview
                            fileUrl={fileUrl}
                            t={t}
                            openPreview={() => setDocIndex(startIndex + idx)}
                            width={idx === visibleFiles ? 80 : 100} // Smaller size for the 5th file
                            height={idx === visibleFiles ? 80 : 100}
                        />
                    </div>
                ))}
            </div>

            {/* Gradient Shadow Effect */}
            {startIndex + visibleFiles < totalFiles && (
                <div
                    style={{
                        position: 'absolute',
                        right: '50px',
                        top: '0',
                        bottom: '0',
                        width: '50px',
                        background: 'linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1))',
                        pointerEvents: 'none',
                    }}
                />
            )}

            {/* Previous Button */}
            {startIndex > 0 && (
                <IoArrowBackCircle
                    size={45}
                    color="#304FFF"
                    onClick={handleScrollLeft}
                    style={{ cursor: 'pointer' }}
                />
            )}
        </div>
    );
};

export default FilePreviewCarousel;
