/* ---------------------------
   The FilePreview component
   --------------------------- */
import { IoDocumentTextOutline } from 'react-icons/io5'; // For file icons

function extractFileDetails(fileUrl) {
    let fileName = '';
    if (fileUrl.startsWith('blob:') && fileUrl.includes('|')) {
        const parts = fileUrl.split('|');
        fileUrl = parts[0];   // e.g. "blob:http://..."
        fileName = parts[1];  // e.g. "myfile.jpg"
    } else {
        const parts = fileUrl.split('/');
        fileName = parts[parts.length - 1].split('?')[0] || 'Unknown file';
    }
    return { fileUrl, fileName };
}

function getFileExtension(fileName = '') {
    return fileName.split('.').pop().toLowerCase();
}

export function FilePreview({
    fileUrl: initialFileUrl,
    t,
    openPreview,
    width = 150,  // Default width
    height = 150  // Default height
}) {
    const { fileUrl, fileName } = extractFileDetails(initialFileUrl);
    const fileExt = getFileExtension(fileName).toLowerCase();

    const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'].includes(fileExt);
    const isPdf = fileExt === 'pdf';
    const isVideo = ['mp4', 'webm'].includes(fileExt);
    const isAudio = ['mp3', 'wav', 'ogg', 'm4a'].includes(fileExt);
    const isText = ['txt', 'csv'].includes(fileExt);

    const handleClickImage = () => {
        // If you want to open your custom preview modal:
        openPreview([fileUrl], 'auto');
    };

    const handleClickPdf = () => {
        // Use the custom preview or open in new tab, up to you
        openPreview([fileUrl], 'pdf');
    };

    const handleClickGeneric = () => {
        // Fallback method
        openPreview([fileUrl], 'auto');
    };

    return (
        <div className="file-item">
            {/* ---- IMAGE ---- */}
            {isImage && (
                <img
                    src={fileUrl}
                    alt={fileName}
                    className="file-preview image-preview"
                    onClick={handleClickImage}
                />
            )}

            {/* ---- PDF ---- */}
            {isPdf && (
                <div
                style={{
                    width: `${width}px`,
                    height: `${height}px`
                }}
                    className="file-preview pdf-preview"
                >

                    <iframe
                        title={`pdf-preview-${fileName}`}
                        src={fileUrl}
                        className="iframe-preview"
                    />
                    <div
                        className="chat-iframe-overlay"
                        onClick={handleClickPdf}
                        style={{ cursor: 'pointer' }}
                        title={t ? t('ClickToOpenPDF') : 'Click to open PDF'}
                    />
                </div>
            )
            }

            {/* ---- VIDEO ---- */}
            {
                isVideo && (
                    <video
                        controls
                        src={fileUrl}
                        className="file-preview video-preview"
                        onClick={handleClickGeneric}
                    >
                        {t ? t('BrowserDoesNotSupportVideo') : 'Your browser does not support the video element.'}
                    </video>
                )
            }

            {/* ---- AUDIO ---- */}
            {
                isAudio && (
                    <audio
                        controls
                        src={fileUrl}
                        className="file-preview audio-preview"
                    >
                        {t ? t('BrowserDoesNotSupportAudio') : 'Your browser does not support the audio element.'}
                    </audio>
                )
            }

            {/* ---- TEXT ---- */}
            {
                isText && (
                    <div
                        className="file-preview text-preview"
                        onClick={handleClickGeneric}
                        title={fileName}
                    >
                        <IoDocumentTextOutline size={30} color="#304FFF" />
                        <span className="file-name">{fileName}</span>
                    </div>
                )
            }

            {/* ---- FALLBACK (unsupported file types) ---- */}
            {
                !isImage && !isPdf && !isVideo && !isAudio && !isText && (
                    <div
                        className="file-preview unsupported-preview"
                        onClick={handleClickGeneric}
                    >
                        <IoDocumentTextOutline size={30} color="#304FFF" />
                        <span className="file-name">{fileName}</span>
                    </div>
                )
            }
        </div >
    );
}