const VAT_RATE = 0.17;
const TransactionDocuments = [
    null,               // Index 0 - intentionally left null
    "הצעת מחיר",       // Index 1
    "הזמנה",           // Index 2
    "חשבון עסקה",      // Index 3
    "חשבונית מס",      // Index 4
    "חשבונית מס זיכוי",      // Index 5
    'חשבונית מס קבלה',      // Index 6
    'קבלה'    // Index 7
];

const CheckTypeOptions = [
    { name: "תקבול", value: "תקבול" },
    { name: "תשלום", value: "תשלום" }
];

const CaseTypeOptions = [
    { name: "עוסק מורשה", value: "עוסק מורשה" },
    { name: "חברה", value: "חברה" },
    { name: "עמותה", value: "עמותה" },
    { name: "עוסק פטור", value: "עוסק פטור" },
];

const AccountManagementOptions = [
    { name: "כפולה", value: "כפולה" },
    { name: "חד צידית", value: "חד צידית" }
];

const ReportingTypeOptions = [
    { name: "חודשי", value: "חודשי" },
    { name: "דו חודשי", value: "דו חודשי" }
];
const ReportTrackingStatus = [
    '',
    'Reported',
    'Unreported'
];

const ReportTrackingType = [
    'VATReport',
    'AdvancesReport',
];

const PermissionUserType = {
    Accountant: 'Accountant',
    SubAccountant: 'SubAccountant',
    Client: 'Client',
};

const UserType = {
    Accountant: '2',
    SubAccountant: '8',
    Client: '1',
};

const UserTypeInt = {
    Accountant: 2,
    SubAccountant: 8,
    Client: 1,
};
// const AccountCardPalCode = [
//     {label: "ספקים", value: "ספקים"},
//     {label: "לקוחות", value: "לקוחות"}
// ];

const AccountCardPalCode = [
    { label: "בנקים", value: "בנקים" },
    { label: "מזומנים בקופה", value: "מזומנים בקופה" },
    { label: "פקדונות קצרי מועד", value: "פקדונות קצרי מועד" },
    { label: "לקוחות", value: "לקוחות" },
    { label: "חייבים מוסדות", value: "חייבים מוסדות" },
    { label: "חייבים שונים", value: "חייבים שונים" },
    { label: "מיסים שוטפים", value: "מיסים שוטפים" },
    { label: "מלאי סחורות", value: "מלאי סחורות" },
    { label: "השקעות במניות", value: "השקעות במניות" },
    { label: "קרקע", value: "קרקע" },
    { label: "מבנים", value: "מבנים" },
    { label: "ציוד", value: "ציוד" },
    { label: "כלי רכב", value: "כלי רכב" },
    { label: "רהוט", value: "רהוט" },
    { label: "ספקים", value: "ספקים" },
    { label: "זכאים מוסדות", value: "זכאים מוסדות" },
    { label: "זכאים אחרים", value: "זכאים אחרים" },
    { label: "הלוואות מבנקים", value: "הלוואות מבנקים" },
    { label: "הלוואות בעלי מניות", value: "הלוואות בעלי מניות" },
    { label: "הון מניות", value: "הון מניות" },
    { label: "קרנות הון", value: "קרנות הון" },
    { label: "עודפים יתרת רווח", value: "עודפים יתרת רווח" },
    { label: "מכירות סחורה", value: "מכירות סחורה" },
    { label: "הכנסות משרותים", value: "הכנסות משרותים" },
    { label: "מלאי פתיחה", value: "מלאי פתיחה" },
    { label: "קניות", value: "קניות" },
    { label: "מלאי סגירה", value: "מלאי סגירה" },
    { label: "הוצאות הפעלה", value: "הוצאות הפעלה" },
    { label: "הוצאות הנהלה וכלליות", value: "הוצאות הנהלה וכלליות" },
    { label: "הוצאות שכר", value: "הוצאות שכר" },
    { label: "הוצאות אחרות", value: "הוצאות אחרות" },
    { label: "הוצאות מיוחדות", value: "הוצאות מיוחדות" },
    { label: "הוצאות מימון", value: "הוצאות מימון" },
    { label: "הכנסות מימון", value: "הכנסות מימון" },
    { label: "רווח להעברה", value: "רווח להעברה" },
    { label: "ממכירות בארץ", value: "ממכירות בארץ" },
    { label: "הכנסות ממכירת מבנים כקבלן בונה", value: "הכנסות ממכירת מבנים כקבלן בונה" },
    { label: "ממכירות לחו\"ל", value: "ממכירות לחו\"ל" },
    { label: "הכנסות מבצוע עבודות כקבלן מבצע", value: "הכנסות מבצוע עבודות כקבלן מבצע" },
    { label: "הכנסות ממתן שירותים בארץ", value: "הכנסות ממתן שירותים בארץ" },
    { label: "הכנסות ממתן שירותים בחו\"ל", value: "הכנסות ממתן שירותים בחו\"ל" },
    { label: "הכנסות מצדדים קשורים", value: "הכנסות מצדדים קשורים" },
    { label: "הכנסות אחרות", value: "הכנסות אחרות" },
    { label: "עלות שכר עבודה ונילוות ששימשו למכירות ומתן השירותים", value: "עלות שכר עבודה ונילוות ששימשו למכירות ומתן השירותים" },
    { label: "עבודות חוץ וקבלני משנה ששימשו למכירות ומתן השירותים", value: "עבודות חוץ וקבלני משנה ששימשו למכירות ומתן השירותים" },
    { label: "קניות סחורה בארץ ששימשו למכירות ומתן השירותים", value: "קניות סחורה בארץ ששימשו למכירות ומתן השירותים" },
    { label: "קניות חומרי גלם בארץ ובחו\"ל ששימשו למכירות ומתן השירותים", value: "קניות חומרי גלם בארץ ובחו\"ל ששימשו למכירות ומתן השירותים" },
    { label: "קניות סחורה בחו\"ל ששימשו למכירות ומתן השירותים", value: "קניות סחורה בחו\"ל ששימשו למכירות ומתן השירותים" },
    { label: "הפרשי שער בגין קניות במטבע חוץ ששימשו למכירות ומתן השירותים", value: "הפרשי שער בגין קניות במטבע חוץ ששימשו למכירות ומתן השירותים" },
    { label: "הוצאות הפרשה לאחריות ששימשו למכירות ומתן השירותים", value: "הוצאות הפרשה לאחריות ששימשו למכירות ומתן השירותים" },
    { label: "קניות מצדדים קשורים ששימשו למכירות ומתן השירותים", value: "קניות מצדדים קשורים ששימשו למכירות ומתן השירותים" },
    { label: "קניות ועלויות אחרות ששימשו למכירות ומתן השירותים", value: "קניות ועלויות אחרות ששימשו למכירות ומתן השירותים" },
    { label: "מלאי בתחילת התקופה ששימש למכירות ומתן השירותים", value: "מלאי בתחילת התקופה ששימש למכירות ומתן השירותים" },
    { label: "מלאי בסוף התקופה ממכירות ומתן השירותים", value: "מלאי בסוף התקופה ממכירות ומתן השירותים" },
    { label: "מלאי תחילת תקופה - ששימש כעלויות ייצור", value: "מלאי תחילת תקופה - ששימש כעלויות ייצור" },
    { label: "שכר עבודה ונילוות  - ששימש כעלויות ייצור", value: "שכר עבודה ונילוות  - ששימש כעלויות ייצור" },
    { label: "עלות קרקעות ופיתוח אצל קבלן בונה - ששימש כעלויות ייצור", value: "עלות קרקעות ופיתוח אצל קבלן בונה - ששימש כעלויות ייצור" },
    { label: "עבודות חוץ וקבלני משנה - ששימשו כעלויות ייצור", value: "עבודות חוץ וקבלני משנה - ששימשו כעלויות ייצור" },
    { label: "הוצאות אחריות ותביעות - ששימשו כעלויות ייצור", value: "הוצאות אחריות ותביעות - ששימשו כעלויות ייצור" },
    { label: "הוצאות חרושת וחומרי בניה - ששימשו כעלויות ייצור", value: "הוצאות חרושת וחומרי בניה - ששימשו כעלויות ייצור" },
    { label: "הוצאות ציוד מתכלה - ששימשו כעלויות ייצור", value: "הוצאות ציוד מתכלה - ששימשו כעלויות ייצור" },
    { label: "הוצאות הובלה ואחסנה - ששימשו כעלויות ייצור", value: "הוצאות הובלה ואחסנה - ששימשו כעלויות ייצור" },
    { label: "הוצאות אריזה - ששימשו כעלויות ייצור", value: "הוצאות אריזה - ששימשו כעלויות ייצור" },
    { label: "הוצאות אחזקה ותיקונים - ששימשו כעלויות ייצור", value: "הוצאות אחזקה ותיקונים - ששימשו כעלויות ייצור" },
    { label: "הוצאות מחקר ופיתוח - ששימשו כעלויות ייצור", value: "הוצאות מחקר ופיתוח - ששימשו כעלויות ייצור" },
    { label: "הוצאות ביטוחים - ששימשו כעלויות ייצור", value: "הוצאות ביטוחים - ששימשו כעלויות ייצור" },
    { label: "אחזקת רכב והובלות - ששימשו כעלויות ייצור", value: "אחזקת רכב והובלות - ששימשו כעלויות ייצור" },
    { label: "דמי שכירות וניהול נכסים - ששימשו כעלויות ייצור", value: "דמי שכירות וניהול נכסים - ששימשו כעלויות ייצור" },
    { label: "מסים ואגרות - ששימשו כעלויות ייצור", value: "מסים ואגרות - ששימשו כעלויות ייצור" },
    { label: "הוצאות פחת  - ששימשו כעלויות ייצור", value: "הוצאות פחת  - ששימשו כעלויות ייצור" },
    { label: "הוצאות בגדי עבודה  - ששימשו כעלויות ייצור", value: "הוצאות בגדי עבודה  - ששימשו כעלויות ייצור" },
    { label: "עלויות יצור אחרות", value: "עלויות יצור אחרות" },
    { label: "מלאי סוף תקופה - מעלויות ייצור", value: "מלאי סוף תקופה - מעלויות ייצור" },
    { label: "שכר עבודה ונילוות - ששימשו כהוצאות מכירה", value: "שכר עבודה ונילוות - ששימשו כהוצאות מכירה" },
    { label: "הוצאות הובלה ואחסנה - ששימשו כהוצאות מכירה", value: "הוצאות הובלה ואחסנה - ששימשו כהוצאות מכירה" },
    { label: "עבודות חוץ לקבלני משנה - ששימשו כהוצאות מכירה", value: "עבודות חוץ לקבלני משנה - ששימשו כהוצאות מכירה" },
    { label: "הוצאות ביטוחים - ששימשו כהוצאות מכירה", value: "הוצאות ביטוחים - ששימשו כהוצאות מכירה" },
    { label: "עמולות ובונוסים לסוכנים עצמאים - ששימשו כהוצאות מכירה", value: "עמולות ובונוסים לסוכנים עצמאים - ששימשו כהוצאות מכירה" },
    { label: "(תמלוגים (זכויות הפצה - ששימשו כהוצאות מכירה", value: "(תמלוגים (זכויות הפצה - ששימשו כהוצאות מכירה" },
    { label: "הוצאות אריזה - ששימשו כהוצאות מכירה", value: "הוצאות אריזה - ששימשו כהוצאות מכירה" },
    { label: "הוצאות אחזקה ותיקונים - ששימשו כהוצאות מכירה", value: "הוצאות אחזקה ותיקונים - ששימשו כהוצאות מכירה" },
    { label: "הוצאות מחקר ופיתוח - ששימשו כהוצאות מכירה", value: "הוצאות מחקר ופיתוח - ששימשו כהוצאות מכירה" },
    { label: "הוצאות נסיעות - ששימשו כהוצאות מכירה", value: "הוצאות נסיעות - ששימשו כהוצאות מכירה" },
    { label: "אחזקת רכב והובלות - ששימשו כהוצאות מכירה", value: "אחזקת רכב והובלות - ששימשו כהוצאות מכירה" },
    { label: "דמי שכירות וניהול נכסים - ששימשו כהוצאות מכירה", value: "דמי שכירות וניהול נכסים - ששימשו כהוצאות מכירה" },
    { label: "הוצאות מכרזים, ירידים ותערוכות - ששימשו כהוצאות מכירה", value: "הוצאות מכרזים, ירידים ותערוכות - ששימשו כהוצאות מכירה" },
    { label: "הוצאות פחת - ששימשו כהוצאות מכירה", value: "הוצאות פחת - ששימשו כהוצאות מכירה" },
    { label: "הוצאות בגדי עבודה - ששימשו כהוצאות מכירה", value: "הוצאות בגדי עבודה - ששימשו כהוצאות מכירה" },
    { label: "הוצאות חשמל ומים - ששימשו כהוצאות מכירה", value: "הוצאות חשמל ומים - ששימשו כהוצאות מכירה" },
    { label: "עמלות וכרטיסי אשראי - ששימשו כהוצאות מכירה", value: "עמלות וכרטיסי אשראי - ששימשו כהוצאות מכירה" },
    { label: "פרסום וקידום מכירות - ששימשו כהוצאות מכירה", value: "פרסום וקידום מכירות - ששימשו כהוצאות מכירה" },
    { label: "הוצאות שונות נטו - ששימשו כהוצאות מכירה", value: "הוצאות שונות נטו - ששימשו כהוצאות מכירה" },
    { label: "שכר עבודה ונלוות - הנהלה וכלליות", value: "שכר עבודה ונלוות - הנהלה וכלליות" },
    { label: "ביטוחים - הנהלה וכלליות", value: "ביטוחים - הנהלה וכלליות" },
    { label: "עבודות חוץ וקבלני משנה - הנהלה וכלליות", value: "עבודות חוץ וקבלני משנה - הנהלה וכלליות" },
    { label: "עמלות ובונוסים לסוכנים - הנהלה וכלליות", value: "עמלות ובונוסים לסוכנים - הנהלה וכלליות" },
    { label: "הוצאות ציוד מתכלה - הנהלה וכלליות", value: "הוצאות ציוד מתכלה - הנהלה וכלליות" },
    { label: "שירותים מקצועיים - הנהלה וכלליות", value: "שירותים מקצועיים - הנהלה וכלליות" },
    { label: "הוצאות אריזה - הנהלה וכלליות", value: "הוצאות אריזה - הנהלה וכלליות" },
    { label: "הוצאות אחזקה ותיקונים - הנהלה וכלליות", value: "הוצאות אחזקה ותיקונים - הנהלה וכלליות" },
    { label: "הוצאות מחקר ופיתוח - הנהלה וכלליות", value: "הוצאות מחקר ופיתוח - הנהלה וכלליות" },
    { label: "נסיעות - הנהלה וכלליות", value: "נסיעות - הנהלה וכלליות" },
    { label: "אחזקת רכב והובלות - הנהלה וכלליות", value: "אחזקת רכב והובלות - הנהלה וכלליות" },
    { label: "דמי שכירות וניהול נכסים - הנהלה וכלליות", value: "דמי שכירות וניהול נכסים - הנהלה וכלליות" },
    { label: "מיסים ואגרות - הנהלה וכלליות", value: "מיסים ואגרות - הנהלה וכלליות" },
    { label: "פחת  - הנהלה וכלליות", value: "פחת  - הנהלה וכלליות" },
    { label: "חשמל ומים - הנהלה וכלליות", value: "חשמל ומים - הנהלה וכלליות" },
    { label: "שמירה וניקיון - הנהלה וכלליות", value: "שמירה וניקיון - הנהלה וכלליות" },
    { label: "השתלמות וספרות מקצועית - הנהלה וכלליות", value: "השתלמות וספרות מקצועית - הנהלה וכלליות" },
    { label: "חובות מסופקים ואבודים - הנהלה וכלליות", value: "חובות מסופקים ואבודים - הנהלה וכלליות" },
    { label: "פרסום וקידום מכירות - הנהלה וכלליות", value: "פרסום וקידום מכירות - הנהלה וכלליות" },
    { label: "כיבודים, מתנות, תרומות, קנסות - הנהלה וכלליות", value: "כיבודים, מתנות, תרומות, קנסות - הנהלה וכלליות" },
    { label: "הוצאות בגין צדדים קשורים - הנהלה וכלליות", value: "הוצאות בגין צדדים קשורים - הנהלה וכלליות" },
    { label: "דמי ניהול - הנהלה וכלליות", value: "דמי ניהול - הנהלה וכלליות" },
    { label: "הוצאות דואר ותקשורת - הנהלה וכלליות", value: "הוצאות דואר ותקשורת - הנהלה וכלליות" },
    { label: "נסיעות לחו\"ל - הנהלה וכלליות", value: "נסיעות לחו\"ל - הנהלה וכלליות" },
    { label: "הוצאות משפטיות - הנהלה וכלליות", value: "הוצאות משפטיות - הנהלה וכלליות" },
    { label: "משרדיות - הנהלה וכלליות", value: "משרדיות - הנהלה וכלליות" },
    { label: "בגדי עבודה - הנהלה וכלליות", value: "בגדי עבודה - הנהלה וכלליות" },
    { label: "שונות נטו וביטולי יתרות - הנהלה וכלליות" },
    { label: "הוצאות מימון לתאגידים בנקאיים", value: "הוצאות מימון לתאגידים בנקאיים" },
    { label: "הוצאות מימון לצדדים קשורים", value: "הוצאות מימון לצדדים קשורים" },
    { label: "הוצאות מימון במטבע חוץ", value: "הוצאות מימון במטבע חוץ" },
    { label: "הוצאות מימון בגין ספקים וזכאים", value: "הוצאות מימון בגין ספקים וזכאים" },
    { label: "הוצאות מימון הוצאות מימון לאחרים", value: "הוצאות מימון הוצאות מימון לאחרים" },
    { label: "הכנסות מימון מתאגידים בנקאיים", value: "הכנסות מימון מתאגידים בנקאיים" },
    { label: "הכנסות מימון מצדדים קשורים", value: "הנסות מימון מצדדים קשורים" },
    { label: "הכנסות מימון מהפרשי שער", value: "הכנסות מימון מהפרשי שער" },
    { label: "הכנסות מימון אחרות", value: "הכנסות מימון אחרות" },
    { label: "רווח הון ממימוש רכוש קבוע", value: "רווח הון ממימוש רכוש קבוע" },
    { label: "רווח הון אחר ושבח", value: "רווח הון אחר ושבח" },
    { label: "הכנסות מדמי ניהול", value: "הכנסות מדמי ניהול" },
    { label: "הכנסות מדמי ניהול מצדדים קשורים", value: "הכנסות מדמי ניהול מצדדים קשורים" },
    { label: "הכנסות מדיוידנד", value: "הכנסות מדיוידנד" },
    { label: "הכנסות מהשכרת מבנים וקרקעות", value: "הכנסות מהשכרת מבנים וקרקעות" },
    { label: "הכנסות מתמלוגים", value: "הכנסות מתמלוגים" },
    { label: "הכנסות אחרות שונות", value: "הכנסות אחרות שונות" },
    { label: "הפסד הון ממימוש רכוש קבוע", value: "הפסד הון ממימוש רכוש קבוע" },
    { label: "הפסד הון אחר", value: "הפסד הון אחר" },
    { label: "הפרשה לירידת ערך", value: "הפרשה לירידת ערך" },
    { label: "שונות", value: "שונות" },
    { label: "רווח/הפסד משותפות שלא נכללו בסעיפים אחרים", value: "רווח/הפסד משותפות שלא נכללו בסעיפים אחרים" },
    { label: "מסים שוטפים", value: "מסים שוטפים" },
    { label: "מסים נדחים", value: "מסים נדחים" },
    { label: "מסים בגין שנים קודמות", value: "מסים בגין שנים קודמות" },
    { label: "דיוידנד לחלוקה", value: "דיוידנד לחלוקה" },
    { label: "רווח להעברה", value: "רווח להעברה" },
    { label: "רווח/הפסד אקוויטי", value: "רווח/הפסד אקוויטי" },
    { label: "מזומנים בשקלים", value: "מזומנים בשקלים" },
    { label: "מזומנים במטבע חוץ", value: "מזומנים במטבע חוץ" },
    { label: "פקדונות לזמן קצר", value: "פקדונות לזמן קצר" },
    { label: "אחרות חוב ממשלתיות", value: "אחרות חוב ממשלתיות" },
    { label: "אגרות חוב קונצרניות", value: "אגרות חוב קונצרניות" },
    { label: "קרנות נאמנות", value: "קרנות נאמנות" },
    { label: "מניות של חברות ישראליות", value: "מניות של חברות ישראליות" },
    { label: "ניירות אחר סחירים אחרים", value: "ניירות אחר סחירים אחרים" },
    { label: "לקוחות בישראל", value: "לקוחות בישראל" },
    { label: "לקוחות בחו\"ל", value: "לקוחות בחו\"ל" },
    { label: "שטרות והמחאות לקבל", value: "שטרות והמחאות לקבל" },
    { label: "הכנסות לקבל", value: "הכנסות לקבל" },
    { label: "כרטיסי אשראי", value: "כרטיסי אשראי" },
    { label: "הפרשה לחובות מסופקים", value: "הפרשה לחובות מסופקים" },
    { label: "לקוחות אחרים", value: "לקוחות אחרים" },
    { label: "מקדמות לספקים ואחרים", value: "מקדמות לספקים ואחרים" },
    { label: "הוצאות מראש", value: "הוצאות מראש" },
    { label: "מס הכנסה מקדמות", value: "מס הכנסה מקדמות" },
    { label: "מוסדות ממשלתיים חייבים", value: "מוסדות ממשלתיים חייבים" },
    { label: "בעלי מניות חייבים", value: "בעלי מניות חייבים" },
    { label: "צדדים קשורים חייבים", value: "צדדים קשורים חייבים" },
    { label: "עובדים חייבים", value: "עובדים חייבים" },
    { label: "חייבים אחרים ויתרות חובה", value: "חייבים אחרים ויתרות חובה" },
    { label: "מסים נדחים לזמן קצר בגין עובדים", value: "מסים נדחים לזמן קצר בגין עובדים" },
    { label: "מסים נדחים לזמן קצר בגין הכנסות לקבל", value: "מסים נדחים לזמן קצר בגין הכנסות לקבל" },
    { label: "מסים נדחים לזמן קצר אחרים", value: "מסים נדחים לזמן קצר אחרים" },
    { label: "הלוואות שניתנו לצדדים קשורים", value: "הלוואות שניתנו לצדדים קשורים" },
    { label: "הלוואות שניתנו לאחרים", value: "הלוואות שניתנו לאחרים" },
    { label: "מלאי שיטחי מסחר ותעשיה למכירה", value: "מלאי שיטחי מסחר ותעשיה למכירה" },
    { label: "מלאי במחסני החברה", value: "מלאי במחסני החברה" },
    { label: "מלאי דירות", value: "מלאי דירות" },
    { label: "מלאי במחסני ערובה", value: "מלאי במחסני ערובה" },
    { label: "מלאי בניינים בהקמה ועבודות בביצוע-עלות ישירה", value: "מלאי בניינים בהקמה ועבודות בביצוע-עלות ישירה" },
    { label: "מלאי בדרך", value: "מלאי בדרך" },
    { label: "מלאי במשגור", value: "מלאי במשגור" },
    { label: "מלאי בתהליך", value: "מלאי בתהליך" },
    { label: "מלאי חומרי גלם", value: "מלאי חומרי גלם" },
    { label: "מלאי עבודות בביצוע לרבות עלויות שהוונו", value: "מלאי עבודות בביצוע לרבות עלויות שהוונו" },
    { label: "מלאי אחר", value: "מלאי אחר" },
    { label: "קרקע ובניינים", value: "קרקע ובניינים" },
    { label: "שיפורים במושכר", value: "שיפורים במושכר" },
    { label: "מכונות וציוד", value: "מכונות וציוד" },
    { label: "כלי רכב", value: "כלי רכב" },
    { label: "מחשבים וציוד עיבוד נתונים", value: "מחשבים וציוד עיבוד נתונים" },
    { label: "רהיטים ואביזרים", value: "רהיטים ואביזרים" },
    { label: "מלאי בסיסי", value: "מלאי בסיסי" },
    { label: "רכוש קבוע אחר", value: "רכוש קבוע אחר" },
    { label: "פחת שנצבר בניינים", value: "פחת שנצבר בניינים" },
    { label: "פחת שנצבר שיפורים במושכר", value: "פחת שנצבר שיפורים במושכר" },
    { label: "פחת שנצבר מכונות וציוד", value: "פחת שנצבר מכונות וציוד" },
    { label: "פחת שנצבר כלי רכב", value: "פחת שנצבר כלי רכב" },
    { label: "פחת שנצבר מחשבים וציוד עיבוד נתונים", value: "פחת שנצבר מחשבים וציוד עיבוד נתונים" },
    { label: "פחת שנצבר רהיטים ואביזרים", value: "פחת שנצבר רהיטים ואביזרים" },
    { label: "רכוש קבוע הפרשה לירידת ערך", value: "רכוש קבוע הפרשה לירידת ערך" },
    { label: "פחת שנצבר רכוש קבוע אחר", value: "פחת שנצבר רכוש קבוע אחר" },
    { label: "הוצאות מראש והלוואות לזמן ארוך", value: "הוצאות מראש והלוואות לזמן ארוך" },
    { label: "הוצאות מראש בגין שכירות לזמן ארוך", value: "הוצאות מראש בגין שכירות לזמן ארוך" },
    { label: "הוצאות מראש אחרות", value: "הוצאות מראש אחרות" },
    { label: "עלות השקעות בחברות מוחזקות", value: "עלות השקעות בחברות מוחזקות" },
    { label: "חלק החברה ברווח /פסד שנצב בחברות מוחזקות", value: "חלק החברה ברווח /פסד שנצבר בחברות מוחזקות" },
    { label: "דיבידנדים שחולקו ע\"י חברות מוחזקות", value: "דיבידנדים שחולקו ע\"י חברות מוחזקות" },
    { label: "הלוואות  לחברות מוחזקות", value: "הלוואות  לחברות מוחזקות" },
    { label: "שטרי הון בחברות מוחזקות", value: "שטרי הון בחברות מוחזקות" },
    { label: "השקעות בחברות אחרות", value: "השקעות בחברות אחרות" },
    { label: "הלוואות לחברות אחרות", value: "הלוואות לחברות אחרות" },
    { label: "השקעות בני\"ע סחירים של חברות אחרות", value: "השקעות בני\"ע סחירים של חברות אחרות" },
    { label: "שטרי הון של חברות אחרות", value: "שטרי הון של חברות אחרות" },
    { label: "השקעה בשותפות", value: "השקעה בשותפות" },
    { label: "השקעות אחרות", value: "השקעות אחרות" },
    { label: "מסים נדחים לזמן ארוך בגין התחייבות לפיצויים", value: "מסים נדחים לזמן ארוך בגין התחייבות לפיצויים" },
    { label: "מסים נדחים לזמן ארוך בגין רכוש קבוע", value: "מסים נדחים לזמן ארוך בגין רכוש קבוע" },
    { label: "מסים נדחים לזמן ארוך אחרים", value: "מסים נדחים לזמן ארוך אחרים" },
    { label: "מוניטין", value: "מוניטין" },
    { label: "הוצאות יסוד", value: "הוצאות יסוד" },
    { label: "פטנט וזכויות יוצרים", value: "פטנט וזכויות יוצרים" },
    { label: "הוצאות נדחות אחרות", value: "הוצאות נדחות אחרות" },
    { label: "בנקים ומשיכות יתר", value: "בנקים ומשיכות יתר" },
    { label: "הלוואות לזמן קצר", value: "הלוואות לזמן קצר" },
    { label: "חלויות שוטפות של הלוואות לזמן ארוך", value: "חלויות שוטפות של הלוואות לזמן ארוך" },
    { label: "הלוואות מתושבי חוץ", value: "הלוואות מתושבי חוץ" },
    { label: "הלוואות מצדדים קשורים", value: "הלוואות מצדדים קשורים" },
    { label: "הלוואות מאחרים", value: "הלוואות מאחרים" },
    { label: "ספקים בישראל", value: "ספקים בישראל" },
    { label: "ספקים בחו\"ל", value: "ספקים בחו\"ל" },
    { label: "שטרות והמחאות לפירעון", value: "שטרות והמחאות לפירעון" },
    { label: "ספקים ונותני שירותים אחרים", value: "ספקים ונותני שירותים אחרים" },
    { label: "עובדים", value: "עובדים" },
    { label: "הפרשות לחופשה והבראה", value: "הפרשות לחופשה והבראה" },
    { label: "מוסדות בגין עובדים", value: "מוסדות בגין עובדים" },
    { label: "מס הכנסה הפרשות בניכוי מקדמות", value: "מס הכנסה הפרשות בניכוי מקדמות" },
    { label: "מוסדות ממשלתיים אחרים", value: "מוסדות ממשלתיים אחרים" },
    { label: "הכנסות שכ\"ד מראש", value: "הכנסות שכ\"ד מראש" },
    { label: "מקדמות מלקוחות", value: "מקדמות מלקוחות" },
    { label: "הכנסות אחרות מראש", value: "הכנסות אחרות מראש" },
    { label: "הוצאות לשלם", value: "הוצאות לשלם" },
    { label: "צדדים קשורים זכאים", value: "צדדים קשורים זכאים" },
    { label: "בעלי מניות זכאים", value: "בעלי מניות זכאים" },
    { label: "הפרשה לאחריות ותיקונים", value: "הפרשה לאחריות ותיקונים" },
    { label: "הפרשה לתביעות", value: "הפרשה לתביעות" },
    { label: "הפרשות תלויות אחרות", value: "הפרשות תלויות אחרות" },
    { label: "בונוסים ומענקים לשלם", value: "בונוסים ומענקים לשלם" },
    { label: "דיבידינד לשלם", value: "דיבידינד לשלם" },
    { label: "זכאים אחרים ויתרות זכות", value: "זכאים אחרים ויתרות זכות" },
    { label: "מסים נדחים לזמן קצרבגין עובדים", value: "מסים נדחים לזמן קצרבגין עובדים" },
    { label: "מסים נדחים לזמן קצר בגין הפסדים להעברה", value: "מסים נדחים לזמן קצר בגין הפסדים להעברה" },
    { label: "מסים נדחים לזמן קצר, אחרים", value: "מסים נדחים לזמן קצר, אחרים" },
    { label: "התחייבויות לזמן ארוך מבנקים", value: "התחייבויות לזמן ארוך מבנקים" },
    { label: "הלוואות לזמן ארוך בניכוי חלויות שוטפות", value: "הלוואות לזמן ארוך בניכוי חלויות שוטפות" },
    { label: "הלוואות מצדדים קשורים", value: "הלוואות מצדדים קשורים" },
    { label: "הלוואות מתושבי חוץ", value: "הלוואות מתושבי חוץ" },
    { label: "הלוואות לזמן ארוך מאחרים", value: "הלוואות לזמן ארוך מאחרים" },
    { label: "שטרי הון לזמן ארוך", value: "שטרי הון לזמן ארוך" },
    { label: "אגרות חוב לזמן ארוך", value: "אגרות חוב לזמן ארוך" },
    { label: "התחייבויות אחרות לזמן ארוך", value: "התחייבויות אחרות לזמן ארוך" },
    { label: "עתודה לפיצויים", value: "עתודה לפיצויים" },
    { label: "יעודה לפיצויים", value: "יעודה לפיצויים" },
    { label: "הפרשות אחרות בשל סיום יחסי עובד-מעביד", value: "הפרשות אחרות בשל סיום יחסי עובד-מעביד" },
    { label: "עתודה למיסים נדחים לזמן ארוך בגין התחייבות לפיצויים", value: "עתודה למיסים נדחים לזמן ארוך בגין התחייבות לפיצויים" },
    { label: "עתודה למיסים נדחים לזמן ארוך בגין רכוש קבוע", value: "עתודה למיסים נדחים לזמן ארוך בגין רכוש קבוע" },
    { label: "עתודה למיסים נדחים לזמן ארוך אחרים", value: "עתודה למיסים נדחים לזמן ארוך אחרים" },
    { label: "הון מניות, הון בעל העסק, הון השותפות", value: "הון מניות, הון בעל העסק, הון השותפות" },
    { label: "פרמיה על מניות", value: "פרמיה על מניות" },
    { label: "קרנות הון", value: "קרנות הון" },
    { label: "תקבולים על חשבון מניות", value: "תקבולים על חשבון מניות" },
    { label: "תקבולים על חשבון אופציות", value: "תקבולים על חשבון אופציות" },
    { label: "עודפים יתרת רווח (הפסד) שנצבר", value: "עודפים יתרת רווח (הפסד) שנצבר" }
];
const PaymentMethod = [
    { name: "NonPaymentReport", value: 0 },
    { name: "AccountChargeAuthorization", value: 1 }
];

const SortCodesWithZeroVat = [
    "הכנסות פטורות",
    "ארנונה ומים",
    "מלאי סגירה",
    "מלאי פתיחה",
    "הוצאות פרטיות",
    "שכר עבודה",
    "הוצאות ביטוח לאומי",
    "הוצאות גמל",
    "הוצאות גמל פיצויים",
    "יציאה מהמלאי",
    "כניסה מהמלאי",
    "הוצאות פטורות",
    "חובה",
    "חובה/זכות",
    "זכות"
]

const SortCodesWith66Vat = [
    "הוצאות טלפון סלולרי",
    "הוצאות רכב",
    "טלפון סלולרי מע״מ",
    "הוצאות רכב פרטי"
]

const SortCodesWith16Vat = [
    "קניות הגדה",
    "מכירות הגדה",

]

const TaxAuthorityScopes = {
    "VatReport": 'VatReport',
    "CreationNumber": 'scope',
    "AdvancesPayment": "AdvancesPayment"
}

const TaxAuthorityRedirectUri = {
    "VatReport": 'http://127.0.0.1:5163/api/TaxAuthority/accountantCallback',
    "CreationNumber": 'https://www.accounting-manager.com:5163/api/TaxAuthority/callback'
}

const PalCodeType = [
    "תוצאתי",
    "מאזני",
    "מאזני רכוש קבוע",

]

const PalCodeClassification = [
    "נכסים",
    "התחיביות",
    "הון קרנות ועודפים",
    "הכנסות",
    "עלויות והוצאות",
    "יעוד הרווח",
    "התאמה למס"
]

const PaymentAcceptanceConstantsAccountcards = Object.freeze({
    GeneralCheck: '500',
    GeneralCash: '501',
    GeneralCreditCard: '502',
    GeneralBank: '515',
});

const SortCodeVatType = {
    0: 'NotParticipating',
    1: 'VatTransactions',
    2: 'VatExpenses',
    3: 'VatEquipment'
};


const SortCodeVatTypeValue = {
    'NotParticipating': 0,
    'VatTransactions': 1,
    'VatExpenses': 2,
    'VatEquipment': 3
};

const BankNames = {
    10: 'בנק לאומי',
    11: 'בנק דיסקונט',
    12: 'בנק הפועלים',
    17: 'בנק מרכנתיל',
    20: 'בנק מזרחי',
    100: 'תבנית Xtram'
};

const BankTypes = {
    10: 0,
    11: 4,
    17: 1,
    20: 2,
    12: 3,
    99: 99,
    100: 100
}

const BankUrls = {
    10: 'https://hb2.bankleumi.co.il/login',
    12: 'https://biz2.bankhapoalim.co.il/ng-portals/auth/he/biz-login/authenticate',
    17: 'https://start.telebank.co.il/login',
    20: 'https://www.mizrahi-tefahot.co.il/login/#/main/auth-page-he',
    11: 'https://start.telebank.co.il/login/#/LOGIN_PAGE_SME'
};

const PriorityEnum = {
    Low: 0,
    High: 1,
    Critical: 2
}

const PriorityEmojis = {
    Low: "⚪",
    High: "🟡",
    Critical: "⚠️",
};

const StatusEnum = {
    ToDo: 0,
    InProgress: 1,
    Done: 2
};

const TaxExemptTypes = [
    "עמותה", // Nonprofit Organization
    "עוסק פטור" // VAT Exempt Business
];

const ISRAEL_INVOICE_AMOUNT_LIMIT = 25000; // Set the appropriate value

const ReceiverType = {
    Client: 1,
    Accountant: 2,
    Label: 3,
    SubAccountant: 8,
};


const VATPercentage = [16, 100, 66, 25, 0];
const ResultAccountName = "תוצאתי";

const clientPaymentMethodOptions = [
    { value: 0, label: 'Accountant' },
    { value: 1, label: 'Business' },
];

const packageOptions = [
    { value: -1, label: 'Basic' },
    { value: 7, label: 'Premium' },
];

const packageOptionsEnum = {
    Basic: -1,
    Premium: 7,

};

const depreciationAssetSortCodeType = {
    3: "Equipment Vat",
};

const SaleOfEquipment = '901';


const TaxAssessorOptions = [
    { value: 1, label: 'טבריה' },
    { value: 2, label: 'עפולה' },
    { value: 4, label: 'צפת' },
    { value: 5, label: 'נצרת' },
    { value: 7, label: 'עכו' },
    { value: 10, label: 'חיפה' },
    { value: 17, label: 'חדרה' },
    { value: 21, label: 'נתניה' },
    { value: 23, label: 'כפר סבא' },
    { value: 24, label: 'פתח תקווה' },
    { value: 25, label: 'רמלה' },
    { value: 25, label: 'בית שמש' },
    { value: 26, label: 'רחובות' },
    { value: 31, label: 'תל אביב 1' },
    { value: 32, label: 'חולון' },
    { value: 34, label: 'תל אביב 4' },
    { value: 37, label: 'מפעלים גדולים' },
    { value: 38, label: 'תל אביב 3' },
    { value: 39, label: 'גוש דן' },
    { value: 41, label: 'ירושלים 1' },
    { value: 43, label: 'ירושלים 3' },
    { value: 45, label: 'ירושלים 2' },
    { value: 48, label: 'אוטונומיה' },
    { value: 50, label: 'אילת' },
    { value: 51, label: 'אשקלון' },
    { value: 51, label: 'אשדוד' },
    { value: 52, label: 'באר שבע' },
    { value: 56, label: 'מודיעין ארצי' },
    { value: 56, label: 'שומה ארצי' },
    { value: 88, label: 'הוצל"פ ירושלים' },
    { value: 92, label: 'הוצל"פ חיפה' },
    { value: 93, label: 'חקירות חיפה' },
    { value: 94, label: 'הוצל"פ תל אביב' },
    { value: 95, label: 'חקירות ירושלים' },
    { value: 98, label: 'חקירות תל אביב' },
    { value: 30, label: 'תל אביב 5' },
];

const PrivacyId = '43cf4d2f-50e3-4680-b757-a965e24d9b35';


const TaxAssessorOptionsString = [
    { value: '1', label: 'טבריה' },
    { value: '2', label: 'עפולה' },
    { value: '4', label: 'צפת' },
    { value: '5', label: 'נצרת' },
    { value: '7', label: 'עכו' },
    { value: '10', label: 'חיפה' },
    { value: '17', label: 'חדרה' },
    { value: '21', label: 'נתניה' },
    { value: '23', label: 'כפר סבא' },
    { value: '24', label: 'פתח תקווה' },
    { value: '25', label: 'רמלה' },
    { value: '25', label: 'בית שמש' },
    { value: '26', label: 'רחובות' },
    { value: '31', label: 'תל אביב 1' },
    { value: '32', label: 'חולון' },
    { value: '34', label: 'תל אביב 4' },
    { value: '37', label: 'מפעלים גדולים' },
    { value: '38', label: 'תל אביב 3' },
    { value: '39', label: 'גוש דן' },
    { value: '41', label: 'ירושלים 1' },
    { value: '43', label: 'ירושלים 3' },
    { value: '45', label: 'ירושלים 2' },
    { value: '48', label: 'אוטונומיה' },
    { value: '50', label: 'אילת' },
    { value: '51', label: 'אשקלון' },
    { value: '51', label: 'אשדוד' },
    { value: '52', label: 'באר שבע' },
    { value: '56', label: 'מודיעין ארצי' },
    { value: '56', label: 'שומה ארצי' },
    { value: '88', label: 'הוצל"פ ירושלים' },
    { value: '92', label: 'הוצל"פ חיפה' },
    { value: '93', label: 'חקירות חיפה' },
    { value: '94', label: 'הוצל"פ תל אביב' },
    { value: '95', label: 'חקירות ירושלים' },
    { value: '98', label: 'חקירות תל אביב' },
    { value: '30', label: 'תל אביב 5' },
];


const EntryType = {
    Expense: 1,
    JournalEntry: 2
};

const MaximumPageNumber = 15;

const deductionTypes = {
    1: "מקבלי ריבית ורווחי הצמדה, תשלומים בעד דמי השאלה, תשלומים בעד תמורת מכירת נייר ערך זר.",
    2: "מקבלי עמלות ביטוח.",
    3: "מקבלי שכר סופרים, אמנים, בוחנים, מרצים, מעניקי שירותי משרד פרטיים שאינם שכירים, דירקטורים.",
    5: "תשלומים בעד עבודה חקלאית או תוצרת חקלאית.",
    6: "מקבלי תשלומים בעד שירותים או נכסים/ תשלומים בעד עבודות בנייה והובלה/ תשלומים בעד עבודות גלישה, מחצבה, חשמל ואלקטרוניקה והובלה/ תשלומים בעד עבודות יהלומים או מסחר ביהלומים.",
    7: "תשלומים לתושב חוץ לפי סעיף 170 לפקודה שנכנסו נוכה לפקיד השומה ע\"י הנמנה.",
    8: "תשלומים לתושב חוץ לפי סעיף 170 לפקודה שנכנסו נוכה לפקיד השומה ע\"י הבנק.",
    11: "תשלום כדין מופקד גמל.",
    12: "החזר תשלום למעביד מקופת גמל לפיצויים.",
    13: "תשלומים בעד שכירות מקרקעין שניתן לנכות כהוצאה.",
    14: "תשלום מרקם השתלמות לעצמאי.",
    15: "תשלומים מהשתכרות או רווח שהתקבלו בחו\"ל, הגרלות ופעילות נוסעת פסים.",
    16: "ניכוי תשלומים בעד מטבעות וירטואליים.",
    18: "תשלום דיבידנד.",
    19: "רווח הון פידיון מניות/ אופציות לפי סעיף 102.",
    21: "הכנסה מהפקת חשמל במסלול פטור.",
    22: "הכנסה מהפקת חשמל במסלול מס מופחת.",
    52: "משיכה בניגוד להוראות סעיף 87 לפקודה – קופ\"ג לקצבה הפקדה לפי 10.1.2000 וקופ\"ג לגמלאים.",
    55: "מענק פרישה חייב במס.",
    57: "מענק פרישה עקב מוות פטור ממס.",
    58: "מענק פרישה חייב במס.",
    59: "מענק פרישה עקב מוות חייב במס.",
    60: "מענק פיצויים שחויב בשווי בעת ההפקדה (פטור).",
    61: "רווחים צבורים מהפקדות שחויבו בשווי בעת ההפקדה (15%).",
    62: "משיכה חייבת מקופ\"ג לפי הנחיה.",
    63: "משיכה של קרן ורווחים מקופת הגמל, שניתן להמשיך בפטור בגין הפקדות שבוצעו לפני שנת 2000 ומשיכה לפי סעיף 179.",
    64: "משיכה מקופת גמל בגין מוות לפי אישור.",
    65: "משיכה מקופת גמל לפי אישור פקיד שומה.",
    66: "משיכה מקופת גמל ללא אישור פקיד שומה.",
    67: "היוון קצבה מוכרת, חלק \"תשלומים פטורים\" (15%).",
    68: "היוון קצבה מוכרת, חלק \"מרכיב החיוב במס\".",
    75: "קרן השתלמות - משיכה מקןן ההשתלמות כסכומים שהוגדרו בתקנות מקן השתלמות לשכירים.",
    76: "קרן השתלמות - משיכה מקןן ההשתלמות שהוגדרו בתקנות מקן השתלמות לעצמאים.",
    77: "קרן השתלמות - משיכה מקןן ההשתלמות כסכומים שהוגדרו בתקנות מקן השתלמות (פטור).",
    78: "קרן השתלמות - משיכת הפקדה שאינה 'הפקדה מוטבת' בתוספת הפרשי הצמדה (פטור) בהתאם לסעיף 3(4)(א) לפקודה.",
    79: "קרן השתלמות - משיכת הפקדה שאינה 'הפקדה מוטבת' בהתאם לסעיף 3(4)(א) לפקודה.",
    80: "קרן השתלמות - משיכת רווחים צבורים מהפקדה שאינה 'הפקדה מוטבת' בהתאם לסעיף 3(4)(א) לפקודה.",
    82: "סכום שהועבר לבין הזוג לשעבר ללא ניכוי מס.",
    83: "סכום שהועבר לבין הזוג לשעבר בניכוי מס.",
    85: "העברת סכומים בגין ימי חופשה, מחלה או חופשה במהלך תקופת עבודה (40%/25%).",
    86: "העברת סכומים בגין ימי חופשה, מחלה או חג במועד סיום עבודה (40%/25%).",
    88: "החזר חד פעמי בגין קופת גמל להשקעה - 'ריבית ורווחים אחרים' (25%).",
    91: "קופת גמל פנסיה לעצמאים - משיכת סכומים במצב אבטלה (פטור).",
    92: "קופת גמל פנסיה לעצמאים - משיכת סכומים במצב אבטלה (לפי אישור).",
    95: "תשלום חד פעמי בגין אובדן כושר עבודה - סכום הקצבה ששולם בסכום חד פעמי.",
    96: "תשלום חד פעמי בגין אובדן כושר עבודה - החזר הוצאות ש\"ל והוצאות משפטיות.",
    97: "תשלום חד פעמי בגין אובדן כושר עבודה - החזר פרמיות."
};

const DeductionTypeOptions = [
    { key: 1, label: "מקבלי ריבית ורווחי הצמדה, תשלומים בעד דמי השאלה, תשלומים בעד תמורת מכירת נייר ערך זר.", value: 1 },
    { key: 2, label: "מקבלי עמלות ביטוח.", value: 2 },
    { key: 3, label: "מקבלי שכר סופרים, אמנים, בוחנים, מרצים, מעניקי שירותי משרד פרטיים שאינם שכירים, דירקטורים.", value: 3 },
    { key: 5, label: "תשלומים בעד עבודה חקלאית או תוצרת חקלאית.", value: 5 },
    { key: 6, label: "מקבלי תשלומים בעד שירותים או נכסים/ תשלומים בעד עבודות בנייה והובלה/ תשלומים בעד עבודות גלישה, מחצבה, חשמל ואלקטרוניקה והובלה/ תשלומים בעד עבודות יהלומים או מסחר ביהלומים.", value: 6 },
    { key: 7, label: "תשלומים לתושב חוץ לפי סעיף 170 לפקודה שנכנסו נוכה לפקיד השומה ע\"י הנמנה.", value: 7 },
    { key: 8, label: "תשלומים לתושב חוץ לפי סעיף 170 לפקודה שנכנסו נוכה לפקיד השומה ע\"י הבנק.", value: 8 },
    { key: 11, label: "תשלום כדין מופקד גמל.", value: 11 },
    { key: 12, label: "החזר תשלום למעביד מקופת גמל לפיצויים.", value: 12 },
    { key: 13, label: "תשלומים בעד שכירות מקרקעין שניתן לנכות כהוצאה.", value: 13 },
    { key: 14, label: "תשלום מרקם השתלמות לעצמאי.", value: 14 },
    { key: 15, label: "תשלומים מהשתכרות או רווח שהתקבלו בחו\"ל, הגרלות ופעילות נוסעת פסים.", value: 15 },
    { key: 16, label: "ניכוי תשלומים בעד מטבעות וירטואליים.", value: 16 },
    { key: 18, label: "תשלום דיבידנד.", value: 18 },
    { key: 19, label: "רווח הון פידיון מניות/ אופציות לפי סעיף 102.", value: 19 },
    { key: 21, label: "הכנסה מהפקת חשמל במסלול פטור.", value: 21 },
    { key: 22, label: "הכנסה מהפקת חשמל במסלול מס מופחת.", value: 22 },
    { key: 52, label: "משיכה בניגוד להוראות סעיף 87 לפקודה – קופ\"ג לקצבה הפקדה לפי 10.1.2000 וקופ\"ג לגמלאים.", value: 52 },
    { key: 55, label: "מענק פרישה חייב במס.", value: 55 },
    { key: 57, label: "מענק פרישה עקב מוות פטור ממס.", value: 57 },
    { key: 58, label: "מענק פרישה חייב במס.", value: 58 },
    { key: 59, label: "מענק פרישה עקב מוות חייב במס.", value: 59 },
    { key: 60, label: "מענק פיצויים שחויב בשווי בעת ההפקדה (פטור).", value: 60 },
    { key: 61, label: "רווחים צבורים מהפקדות שחויבו בשווי בעת ההפקדה (15%).", value: 61 },
    { key: 62, label: "משיכה חייבת מקופ\"ג לפי הנחיה.", value: 62 },
    { key: 63, label: "משיכה של קרן ורווחים מקופת הגמל, שניתן להמשיך בפטור בגין הפקדות שבוצעו לפני שנת 2000 ומשיכה לפי סעיף 179.", value: 63 },
    { key: 64, label: "משיכה מקופת גמל בגין מוות לפי אישור.", value: 64 },
    { key: 65, label: "משיכה מקופת גמל לפי אישור פקיד שומה.", value: 65 },
    { key: 66, label: "משיכה מקופת גמל ללא אישור פקיד שומה.", value: 66 },
    { key: 67, label: "היוון קצבה מוכרת, חלק \"תשלומים פטורים\" (15%).", value: 67 },
    { key: 68, label: "היוון קצבה מוכרת, חלק \"מרכיב החיוב במס\".", value: 68 },
    { key: 75, label: "קרן השתלמות - משיכה מקןן ההשתלמות כסכומים שהוגדרו בתקנות מקן השתלמות לשכירים.", value: 75 },
    { key: 76, label: "קרן השתלמות - משיכה מקןן ההשתלמות שהוגדרו בתקנות מקן השתלמות לעצמאים.", value: 76 },
    { key: 77, label: "קרן השתלמות - משיכה מקןן ההשתלמות כסכומים שהוגדרו בתקנות מקן השתלמות (פטור).", value: 77 },
    { key: 78, label: "קרן השתלמות - משיכת הפקדה שאינה 'הפקדה מוטבת' בתוספת הפרשי הצמדה (פטור) בהתאם לסעיף 3(4)(א) לפקודה.", value: 78 },
    { key: 79, label: "קרן השתלמות - משיכת הפקדה שאינה 'הפקדה מוטבת' בהתאם לסעיף 3(4)(א) לפקודה.", value: 79 },
    { key: 80, label: "קרן השתלמות - משיכת רווחים צבורים מהפקדה שאינה 'הפקדה מוטבת' בהתאם לסעיף 3(4)(א) לפקודה.", value: 80 },
    { key: 82, label: "סכום שהועבר לבין הזוג לשעבר ללא ניכוי מס.", value: 82 },
    { key: 83, label: "סכום שהועבר לבין הזוג לשעבר בניכוי מס.", value: 83 },
    { key: 85, label: "העברת סכומים בגין ימי חופשה, מחלה או חופשה במהלך תקופת עבודה (40%/25%).", value: 85 },
    { key: 86, label: "העברת סכומים בגין ימי חופשה, מחלה או חג במועד סיום עבודה (40%/25%).", value: 86 },
    { key: 88, label: "החזר חד פעמי בגין קופת גמל להשקעה - 'ריבית ורווחים אחרים' (25%).", value: 88 },
    { key: 91, label: "קופת גמל פנסיה לעצמאים - משיכת סכומים במצב אבטלה (פטור).", value: 91 },
    { key: 92, label: "קופת גמל פנסיה לעצמאים - משיכת סכומים במצב אבטלה (לפי אישור).", value: 92 },
    { key: 95, label: "תשלום חד פעמי בגין אובדן כושר עבודה - סכום הקצבה ששולם בסכום חד פעמי.", value: 95 },
    { key: 96, label: "תשלום חד פעמי בגין אובדן כושר עבודה - החזר הוצאות ש\"ל והוצאות משפטיות.", value: 96 },
    { key: 97, label: "תשלום חד פעמי בגין אובדן כושר עבודה - החזר פרמיות.", value: 97 }
];



const XtramAccountantId = "0539470430";
const Constants = {
    CheckTypeOptions,
    CaseTypeOptions,
    AccountManagementOptions,
    ReportingTypeOptions,
    AccountCardPalCode,
    TransactionDocuments,
    ReportTrackingStatus,
    ReportTrackingType,
    PaymentMethod,
    SortCodesWithZeroVat,
    SortCodesWith66Vat,
    TaxAuthorityScopes,
    TaxAuthorityRedirectUri,
    SortCodesWith16Vat,
    PalCodeType,
    PalCodeClassification,
    PaymentAcceptanceConstantsAccountcards,
    SortCodeVatType,
    BankNames,
    BankUrls,
    BankTypes,
    ISRAEL_INVOICE_AMOUNT_LIMIT,
    PermissionUserType,
    VAT_RATE,
    PriorityEnum,
    StatusEnum,
    PriorityEmojis,
    ReceiverType,
    TaxExemptTypes,
    VATPercentage,
    UserType,
    UserTypeInt,
    ResultAccountName,
    clientPaymentMethodOptions,
    packageOptions,
    packageOptionsEnum,
    depreciationAssetSortCodeType,
    SaleOfEquipment,
    TaxAssessorOptions,
    SortCodeVatTypeValue,
    PrivacyId,
    EntryType,
    MaximumPageNumber,
    DeductionTypeOptions,
    TaxAssessorOptionsString,
    XtramAccountantId
};


export default Constants;