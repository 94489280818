import React, { useContext } from 'react';
import { Button, Typography, Container, Box } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthService from '../../Services/auth.service';
import './Unauthorized.css'; // Import the CSS file
import logoImage from '../../assests/images/login/logo.svg';
import { NavHistoryContext } from '../../Contexts/NavHistoryContext';

function Unauthorized() {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const { goBack } = useContext(NavHistoryContext);

    const handleGoBack = () => {
        // navigate(-3); // Go back two steps in the history stack
        goBack()
    };

    const handleLogin = () => {
        AuthService.logout();
        navigate('/login'); // Navigate to the login page
    };

    return (
        <Box
            sx={{
                height: '100vh',
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            {/* Overlay */}
            <div className="unauthorized-background" />

            {/* Logo */}
            <img
                src={logoImage}
                alt="Logo"
                className={`${i18n.language === 'en' ? 'logo-ltr' : 'logo-rtl'}`}
            />

            {/* Content */}
            <Container
                maxWidth="sm"
                sx={{
                    position: 'relative',
                    zIndex: 1,
                    color: 'white',
                    textAlign: 'center',
                    pt: 15,
                }}
            >
                <LockOutlined sx={{ fontSize: 80, color: 'error.main', mb: 2 }} />
                <Typography variant="h4" gutterBottom>
                    {t("AccessDenied")}
                </Typography>
                <Typography variant="h5" gutterBottom>
                    {t("NoPermissions")}
                </Typography>
                <Box
                    mt={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleGoBack}
                        sx={{
                            px: 4,
                            py: 1.5,
                            fontSize: '1rem',
                            borderRadius: '30px',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.4)',
                            },
                        }}
                    >
                        {t("GoBack")}
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleLogin}
                        sx={{
                            px: 4,
                            py: 1.5,
                            fontSize: '1rem',
                            color: '#F9AA2A',
                            borderRadius: '30px',
                            borderWidth: '2px',
                            borderColor: '#F9AA2A',
                            background: 'white',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                background: 'white',
                            },
                        }}
                    >
                        {t("Login")}
                    </Button>
                </Box>
            </Container>
        </Box>
    );
}

export default Unauthorized;
