import React, { useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Tooltip,
    IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/SaveAsRounded';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';

const EditExternalTransactionDialog = ({
    editBankTransactionDialog,
    handleCloseBankEditTransactionDialog,
    editedTransaction,
    changeNewBankTransaction,
    handleAddBankTransaction,
    handleDelete
}) => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();

    useEffect(() => {
        if (!editedTransaction.transactionDate) {
            changeNewBankTransaction('transactionDate', dayjs());
        }
    }, [editBankTransactionDialog, editedTransaction]);

    const handleClose = () => {
        handleCloseBankEditTransactionDialog();
    }

    const isFormValid = !!(
        editedTransaction?.description &&
        editedTransaction.reference1 &&
        editedTransaction.transactionDate &&
        editedTransaction.amount
    );

    return (
        <Dialog
            open={editBankTransactionDialog}
            onClose={handleCloseBankEditTransactionDialog}
            aria-labelledby="form-dialog-title"
            
        >
            <div style={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                zIndex: 10, // Ensures it stays on top
            }}>
                <Tooltip title={t("Cancel")}>
                    <IconButton onClick={handleClose} aria-label="cancel">
                        <CancelIcon style={{ color: '#304FFF' }} />
                    </IconButton>
                </Tooltip>
            </div>

            <DialogTitle id="form-dialog-title" sx={{ textAlign: 'center' }}>
                {t("EditBankCommand")}
            </DialogTitle>

            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '16px',
                    textAlign: 'center',
                    minWidth: '300px'
                }}
                className={`${direction}`}
            >
                {/* Description */}
                <div className="form-row-item full-width">
                    <div className="form-row-item-title">
                        {t('Description')}<span className="required-asterisk">*</span>
                    </div>
                    <TextField
                        fullWidth
                        placeholder={t('Description')}
                        value={editedTransaction?.description || ''}
                        onChange={(e) => changeNewBankTransaction('description', e.target.value)}
                        className="add-client-input-style full-width"
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                            className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'
                                }`,
                            sx: {
                                height: '48px',
                                padding: '0 10px',
                                borderRadius: '8px',
                                background: '#F5F5F6',
                                border: '1px solid transparent',
                                '&:focus-within': {
                                    border: '2px solid #304FFF',
                                },
                            },
                        }}
                        inputProps={{
                            style: {
                                width: '100%',
                                textAlign: direction === 'ltr' ? 'left' : 'right',
                            },
                        }}
                        required
                    />
                </div>

                {/* Reference */}
                <div className="form-row-item full-width">
                    <div className="form-row-item-title">
                        {t('Reference')}<span className="required-asterisk">*</span>
                    </div>
                    <TextField
                        fullWidth
                        placeholder={t('Reference')}
                        value={editedTransaction?.reference1 || ''}
                        onChange={(e) => changeNewBankTransaction('reference', e.target.value)}
                        className="add-client-input-style full-width"
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                            className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'
                                }`,
                            sx: {
                                height: '48px',
                                padding: '0 10px',
                                borderRadius: '8px',
                                background: '#F5F5F6',
                                border: '1px solid transparent',
                                '&:focus-within': {
                                    border: '2px solid #304FFF',
                                },
                            },
                        }}
                        inputProps={{
                            style: {
                                width: '100%',
                                textAlign: direction === 'ltr' ? 'left' : 'right',
                            },
                        }}
                        required
                    />
                </div>

                {/* Transaction Date */}
                <div className="form-row-item full-width">
                    <div className="form-row-item-title">
                        {t('TransactionDate')}<span className="required-asterisk">*</span>
                    </div>
                    <CustomDatePicker
                        date={
                            editedTransaction.transactionDate
                                ? new Date(editedTransaction.transactionDate)
                                : null
                        }
                        onDateChange={(date) => {
                            if (date) {
                                changeNewBankTransaction('transactionDate', date);
                            }
                        }}
                        height="48px"
                    />
                </div>

                {/* Amount */}
                <div className="form-row-item full-width">
                    <div className="form-row-item-title">
                        {t('Amount')}<span className="required-asterisk">*</span>
                    </div>
                    <TextField
                        fullWidth
                        placeholder={t('Amount')}
                        value={editedTransaction.amount || ''}
                        onChange={(e) => changeNewBankTransaction('amount', e.target.value)}
                        className="add-client-input-style full-width"
                        variant="standard"
                        type="number"
                        InputProps={{
                            disableUnderline: true,
                            className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'
                                }`,
                            sx: {
                                height: '48px',
                                padding: '0 10px',
                                borderRadius: '8px',
                                background: '#F5F5F6',
                                border: '1px solid transparent',
                                '&:focus-within': {
                                    border: '2px solid #304FFF',
                                },
                            },
                        }}
                        inputProps={{
                            style: {
                                width: '100%',
                                textAlign: direction === 'ltr' ? 'left' : 'right',
                            },
                        }}
                        required
                    />
                </div>
            </DialogContent>

            {/* ===== Updated action buttons with IconButtons & Tooltips ===== */}
            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '16px',
                    textAlign: 'center',
                }}
            >
                <Tooltip title={t("Delete")}>
                    <IconButton onClick={handleDelete} aria-label="delete">
                        <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                </Tooltip>


                <Tooltip title={t("Edit")}>
                    <IconButton
                        onClick={handleAddBankTransaction}
                        aria-label="edit"
                        disabled={!isFormValid}
                        style={{
                            opacity: isFormValid ? 1 : 0.5,
                            cursor: isFormValid ? 'pointer' : 'not-allowed',
                            pointerEvents: isFormValid ? 'auto' : 'none',
                        }}
                    >
                        <SaveIcon style={{ color: '#304FFF' }} />
                    </IconButton>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
};

export default EditExternalTransactionDialog;
