import React from 'react';
import { Dialog, DialogContent, Button } from '@mui/material';
import Lottie from "lottie-react";
import passwordResetAnimation from '../../../assests/images/Animations/PasswordReset.json';
// ^ Adjust the path to wherever your Lottie file is
import { useTranslation } from 'react-i18next';

/**
 * Similar to PasswordChanged, but for "Client Created Successfully".
 *
 * PROPS:
 *  - visible (bool) => show/hide the dialog
 *  - onClose (function) => handle close or "BackToLogin"
 *  - t (function) => translation function
 */
export default function ClientCreatedSuccessModal({ visible, onClose, t }) {
    return (
        <Dialog
            open={visible}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        // If you want custom classes or styles, replicate your .module.css or inline
        >
            <DialogContent style={{ textAlign: 'center', padding: '24px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {/* Lottie Animation */}
                    <div style={{ marginBottom: '20px' }}>
                        <Lottie
                            animationData={passwordResetAnimation}
                            loop={false}
                            style={{ height: 300, width: 300 }}
                        />
                    </div>

                    {/* Text Messages */}
                    <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '8px' }}>
                        {t("ClientRegistered")}{" "}
                        <span style={{ color: "rgba(44, 216, 82, 1)", fontWeight: '600' }}>
                            {t("Successfully")}
                        </span>
                    </p>

                    <p style={{ fontSize: '14px', marginBottom: '24px', color: 'rgba(0,0,0,0.7)' }}>
                        {t("ClientRegisteredSuccessMessage")}
                    </p>

                    {/* Action Button */}
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={onClose}
                        style={{
                            backgroundColor: '#304FFF',
                            color: 'white',
                            textTransform: 'none',
                            borderRadius: '8px'
                        }}
                    >
                        {t("BackToLogin")}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}
