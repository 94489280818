import config from '../config';
import selectedEnvironment from '../env';
import AuthService from './auth.service';
const API_BASE = config[selectedEnvironment].API_URL;
const API_URL = `${API_BASE}/Reports`;
const REPORTS_API = `${API_BASE}/ReportedTaxes`; // or /api/ReportedTaxes
const AccountCards_API_URL = `${API_BASE}/AccountCards`;



const getReferenceSequenceReport = async (caseId, startDate, endDate, accountCardId = null, token) => {
    const response = await fetch(`${API_URL}/ReferenceSequenceReport`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CaseId: caseId,
            StartDate: startDate,
            EndDate: endDate,
            AccountCardCode: accountCardId
        })
    });

    if (!response.ok) {
        const errorText = await response.text();
        console.error(errorText);
        throw new Error(errorText);
    }

    const blob = await response.blob();

    // Create a URL for the file
    const fileUrl = window.URL.createObjectURL(blob);

    // Create a temporary link and trigger the download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', `ReferenceSequenceReport-${caseId}.pdf`); // Use a suitable filename
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link and revoking the object URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
};

const getSupplierWithholdingTaxSummary = async (caseId, startDate, endDate, accountantId, token) => {
    const response = await fetch(`${API_URL}/SupplierWithholdingTaxSummaryMonthly`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CaseId: caseId,
            AccountantId: accountantId,
            StartDate: startDate,
            EndDate: endDate
        })
    });

    if (!response.ok) {
        const errorText = await response.text();
        console.error(errorText);
        throw new Error(errorText);
    }

    const blob = await response.blob();

    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', `ניכוי-במקור-תקופתי-${caseId}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
};

const getSupplierWithholdingTaxSummaryYearly = async (caseId, startDate, accountantId, token) => {
    const response = await fetch(`${API_URL}/SupplierWithholdingTaxSummaryYearly`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CaseId: caseId,
            AccountantId: accountantId,
            StartDate: startDate
        })
    });

    if (!response.ok) {
        const errorText = await response.text();
        console.error(errorText);
        throw new Error(errorText);
    }

    const blob = await response.blob();

    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', `ניכוי-במקור-שנתי-${caseId}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
};


const getSupplierWithholdingTaxCertificate = async (caseId, startDate, accountantId, cardId = null, token) => {
    const response = await fetch(`${API_URL}/SupplierWithholdingTaxCertificate`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CaseId: caseId,
            AccountantId: accountantId,
            StartDate: startDate,
            CardId: cardId
        })
    });

    if (!response.ok) {
        const errorText = await response.text();
        console.error(errorText);
        throw new Error(errorText);
    }

    const contentType = response.headers.get("Content-Type");

    if (contentType === "application/zip") {
        const blob = await response.blob();
        const fileUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `ניכוי-במקור-אישור-${caseId}.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(fileUrl);
    } else if (contentType === "application/pdf") {
        const blob = await response.blob();
        const fileUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `ניכוי-במקור-אישור-${caseId}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(fileUrl);
    }
};

// ============ VAT Endpoints ============

// 1) Create a new VAT
const createReportedVat = async (vatData, token) => {
    const response = await fetch(`${REPORTS_API}/CreateVat`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(vatData),
    });
    return response;
};

// 2) Update an existing VAT
const updateReportedVat = async (id, vatData, token) => {
    const response = await fetch(`${REPORTS_API}/UpdateVat/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(vatData),
    });
    return response;
};

const deleteReportedVatByPeriod = async (vatData, token) => {
    const response = await fetch(`${REPORTS_API}/DeleteVatByPeriod`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(vatData),
    });
    return response;
};

// 3) Send the VAT to the Tax Authority
const sendVatReporting = async (sendVatDto, token) => {
    // POST /ReportedTaxes/SendVatReporting
    const response = await fetch(`${REPORTS_API}/SendVatReporting`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(sendVatDto),
    });
    return response;
};

// ============ Advances/Withholding Endpoints ============

// 4) Create a new Advance
const createReportedAdvance = async (advanceData, token) => {
    const response = await fetch(`${REPORTS_API}/CreateAdvance`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(advanceData),
    });
    return response;
};

// 5) Update an existing Advance
const updateReportedAdvance = async (id, advanceData, token) => {
    const response = await fetch(`${REPORTS_API}/UpdateAdvance/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(advanceData),
    });
    return response;
};

// 6) Send an Advance to the Tax Authority
const sendAdvancesReport = async (sendAdvDto, token) => {
    const response = await fetch(`${REPORTS_API}/SendAdvancesReport`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(sendAdvDto),
    });
    return response;
};

const getVatByDateRange = async (dto, token) => {
    // dto = { CaseId, StartDate, EndDate, IsRange }
    // POST /api/ReportedTaxes/GetVatByDateRange
    const response = await fetch(`${REPORTS_API}/GetVatByDateRange`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(dto),
    });
    return response; // you'll parse .json() in your React code
};

const getAdvanceByDateRange = async (dto, token) => {
    // POST /api/ReportedTaxes/GetAdvanceByDateRange
    const response = await fetch(`${REPORTS_API}/GetAdvanceByDateRange`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(dto),
    });
    return response;
};


// Checks if a VAT period already exists
// by posting to an endpoint like: POST /ReportedTaxes/IsVatPeriodReported
const isVatPeriodReported = async (caseId, startDate, endDate, token) => {
    // We'll assume you pass in actual date objects or ISO strings
    const dto = {
        CaseId: caseId,
        StartDate: startDate,
        EndDate: endDate
        // (if your controller requires an IsRange param, you can add it here)
    };

    const response = await fetch(`${REPORTS_API}/IsVatPeriodReported`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dto)
    });

    if (!response.ok) {
        const errorText = await response.text();
        console.error(errorText);
        throw new Error(`Error checking if VAT period is reported: ${errorText}`);
    }

    // The response should be a boolean: true or false
    const result = await response.json();
    return result;
};

// Checks if an Advance period already exists
// by posting to an endpoint like: POST /ReportedTaxes/IsAdvancePeriodReported
const isAdvancePeriodReported = async (caseId, startDate, endDate, token) => {
    const dto = {
        CaseId: caseId,
        StartDate: startDate,
        EndDate: endDate
    };

    const response = await fetch(`${REPORTS_API}/IsAdvancePeriodReported`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dto)
    });

    if (!response.ok) {
        const errorText = await response.text();
        console.error(errorText);
        throw new Error(`Error checking if Advance period is reported: ${errorText}`);
    }

    const result = await response.json();
    return result; // true or false
};

// Example optional: "createOrUpdateVat" that does a check first
const createOrUpdateVat = async (vatData, token) => {
    const { caseId, startDate, endDate } = vatData;

    // Call the isVatPeriodReported check
    const alreadyReported = await isVatPeriodReported(caseId, startDate, endDate, token);

    if (alreadyReported) {
        // If you want to do an update automatically, you'd need the ID of the existing record
        // But if your server auto-updates when it sees the same StartDate/EndDate, 
        // you can just call createReportedVat or do something else:
        console.log("VAT period is already reported, calling create anyway to let server handle it...");
    }

    return createReportedVat(vatData, token);
};

// Similarly, createOrUpdateAdvance...
const createOrUpdateAdvance = async (advanceData, token) => {
    const { caseId, startDate, endDate } = advanceData;

    const alreadyReported = await isAdvancePeriodReported(caseId, startDate, endDate, token);

    if (alreadyReported) {
        console.log("Advance period is already reported, calling create anyway...");
    }

    return createReportedAdvance(advanceData, token);
};


const deleteReportedAdvanceByPeriod = async (advanceData, token) => {
    const response = await fetch(`${REPORTS_API}/DeleteAdvanceByPeriod`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(advanceData),
    });
    return response;
};

const isVatPeriodReportedForAccountant = async (accountantId, date, token) => {
    if (!date) {
        return {};
    }
    const response = await fetch(`${REPORTS_API}/IsVatPeriodReportedForAccountant`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ accountantId, date })
    });

    if (!response.ok) {
        throw new Error('Failed to fetch VAT period data');
    }

    const data = await response.json();
    // data = { results: { "CASEID1": "reported"/"notReported"/"notReportedperiod", ... } }
    return data;
}

const isAdvancePeriodReportedForAccountant = async (accountantId, date, token) => {
    if (!date) {
        return {};
    }
    const response = await fetch(`${REPORTS_API}/IsAdvancePeriodReportedForAccountant`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ accountantId, date })
    });

    if (!response.ok) {
        throw new Error('Failed to fetch Advance period data');
    }

    const data = await response.json();
    return data;
}


const calculateTax = async (caseId, accountantId, startDate, endDate) => {
    const token = await AuthService.getCurrentToken();

    const response = await fetch(`${API_URL}/calculateTax`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ caseId, accountantId, startDate, endDate })
    });

    if (!response.ok) {
        throw new Error('Failed to fetch Advance period data');
    }

    const data = await response.json();
    return data;
}


const GetTrialBalanceDetails = async (caseId, accountantId, startDate, endDate, isYearly, token, onlyProcessed = false, isProfitAndLoss = false) => {
    try {
        const response = await fetch(`${AccountCards_API_URL}/trialBalanceDetails`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ caseId, accountantId, startDate, endDate, isYearly, onlyProcessed, isProfitAndLoss })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response;
    } catch (error) {
    }
};

const GetPalCodesDetails = async (caseId, accountantId, startDate, endDate, isYearly, token, onlyProcessed = false, isProfitAndLoss = false) => {
    try {
        const response = await fetch(`${AccountCards_API_URL}/palcodesDetails`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ caseId, accountantId, startDate, endDate, isYearly, onlyProcessed, isProfitAndLoss })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response;
    } catch (error) {
    }
};

const getYearlyVatReport = async (caseId, date, token) => {
    const response = await fetch(`${REPORTS_API}/GenerateYearlyVatReport`, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CaseId: caseId,
            Date: date
        })
    });

    if (!response.ok) {
        const errorText = await response.text();
        console.error(errorText);
        throw new Error(`Error generating yearly VAT report: ${errorText}`);
    }

    const blob = await response.blob();

    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', `דוח-מע״מ-שנתי-${caseId}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
};

const getYearlyAdvanceReport = async (caseId, accountantId, date, token) => {
    const response = await fetch(`${REPORTS_API}/GenerateYearlyAdvanceReport`, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CaseId: caseId,
            AccountantId: accountantId,
            Date: date
        })
    });

    if (!response.ok) {
        const errorText = await response.text();
        console.error(errorText);
        throw new Error(`Error generating yearly advance report: ${errorText}`);
    }

    const blob = await response.blob();

    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', `דוח-מקדמות-שנתי-${caseId}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
};

const getAccumulatedDifferenceInAdvance = async (caseId, accountantId, date, token) => {
    const response = await fetch(`${REPORTS_API}/GetAccumulatedDifferenceInAdvance`, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CaseId: caseId,
            AccountantId: accountantId,
            Date: date
        })
    });

    if (!response.ok) {
        const errorText = await response.text();
        console.error(errorText);
        throw new Error(`Error retrieving accumulated advance difference: ${errorText}`);
    }

    const data = await response.json();
    return data;
};

const getAccumulatedDifferenceInVat = async (caseId, date, token) => {
    const response = await fetch(`${REPORTS_API}/GetAccumulatedDifferenceInVat`, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CaseId: caseId,
            Date: date
        })
    });

    if (!response.ok) {
        const errorText = await response.text();
        console.error(errorText);
        throw new Error(`Error retrieving accumulated VAT difference: ${errorText}`);
    }

    const data = await response.json();
    return data;
};

const getJournalEntryDocumentation = async (caseId, startDate, endDate, documentType, token) => {
    const response = await fetch(`${API_URL}/JournalEntryDocumentation`, {
        method: 'POST',
        headers: {
            "Authorization": 'Bearer ' + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CaseId: caseId,
            StartDate: startDate,
            EndDate: endDate,
            DocumentType: documentType
        })
    });

    if (!response.ok) {
        const errorText = await response.text();
        console.error(errorText);
        throw new Error(`Error generating Journal Entry Documentation: ${errorText}`);
    }

    return await response.blob(); 
};

const download856Combined = async (requestBody, token) => {
    const response = await fetch(`${API_URL}/856CombinedDownload`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(requestBody),
    });
  
    if (!response.ok) {
      const msg = await response.text();
      throw new Error(msg || 'Error downloading combined file');
    }
  
    // Get the zip as a blob
    const blob = await response.blob();
    return blob;
  };

const ReportsService = {
    getReferenceSequenceReport,
    getSupplierWithholdingTaxSummary,
    getSupplierWithholdingTaxSummaryYearly,
    getSupplierWithholdingTaxCertificate,
    createReportedVat,
    updateReportedVat,
    sendVatReporting,
    createReportedAdvance,
    updateReportedAdvance,
    sendAdvancesReport,
    getVatByDateRange,
    getAdvanceByDateRange,
    isVatPeriodReported,
    isAdvancePeriodReported,
    createOrUpdateVat,
    createOrUpdateAdvance,
    deleteReportedVatByPeriod,
    deleteReportedAdvanceByPeriod,
    isVatPeriodReportedForAccountant,
    isAdvancePeriodReportedForAccountant,
    calculateTax,
    GetPalCodesDetails,
    GetTrialBalanceDetails,
    getYearlyVatReport,
    getYearlyAdvanceReport,
    getAccumulatedDifferenceInAdvance,
    getAccumulatedDifferenceInVat,
    getJournalEntryDocumentation,
    download856Combined
};

export default ReportsService;