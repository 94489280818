import React, { useState, useEffect, useRef } from 'react';
import {
    TextField,
    Tooltip,
    IconButton,
    Button,
    Autocomplete,
    Popper,
} from '@mui/material';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';
import { toast } from 'react-toastify';
import SaveIcon from '@mui/icons-material/SaveAsRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/system';
import AssetService from '../../../Services/assets.service';
import Constants from '../../../assests/Constants/constants';
import dayjs from 'dayjs';

const StyledContainer = styled('div')({
    position: 'absolute',
    top: '10%',
    left: '20%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '16px',
    minHeight: '60vh',
    maxHeight: '85vh',
    overflowY: 'auto',
    minWidth: '700px',
    zIndex: 1299,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
    border: '1px solid #304fff',
});

const StyledListItem = styled('li')({
    '&:hover': {
        backgroundColor: '#F5F5F6', // Hover color
        cursor: 'pointer', // Optional: Changes the cursor to pointer
    },
});



const StyledPopper = styled(Popper)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 'var(--Spacing-md, 8px)',
        background: 'var(--white, #FFF)',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.32)',
    },
    '& .MuiAutocomplete-listbox': {
        padding: theme.spacing(1),
    },
    '& .MuiAutocomplete-option': {
        borderRadius: 'var(--Spacing-sm, 4px)',
        '&[aria-selected="true"], &.Mui-focused, &:hover': {
            background: 'var(--orange, #F9AA2A) !important',
        },
    },
}));

const AddAsset = ({ open, onClose, onSave, handleSave, assets, viewAttachDocument, assignedDocument, assetGroups }) => {
    const { t, i18n } = useTranslation();
    const nodeRef = useRef(null);
    const [direction, setDirection] = useState('rtl');

    const [newAsset, setNewAsset] = useState({
        name: '',
        originalPrice: '',
        depreciationPercentage: '',
        purchaseDate: dayjs().format('YYYY-MM-DD'),
        activationDate: dayjs().format('YYYY-MM-DD'), // defaults to purchaseDate
        assetNumber: '',
        assetGroupId: '',
    });

    const [isSaveDisabled, setIsSaveDisabled] = useState(true); // State to manage save button disabled state

    useEffect(() => {
        // Update direction based on language
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);


    useEffect(() => {


        setNewAsset(prev => ({ ...prev, documentItSelf: assignedDocument }));
        if (assignedDocument) {
            if (assignedDocument?.type === Constants.EntryType.Expense) {
                setNewAsset(prev => ({ ...prev, expenseId: assignedDocument.id }));
                setNewAsset(prev => ({ ...prev, journalEntryId: null }));
            } else if (assignedDocument?.type === Constants.EntryType.JournalEntry) {
                setNewAsset(prev => ({ ...prev, journalEntryId: assignedDocument.id }));
                setNewAsset(prev => ({ ...prev, expenseId: null }));
            } else {
                console.error('Unknown document type:');
                return; // Prevent further execution if the type is unknown
            }
            setNewAsset(prev => ({
                ...prev,
                originalPrice: assignedDocument.amount.toString(),
                purchaseDate: assignedDocument.documentDate,
                activationDate: assignedDocument.documentDate,
                name: assignedDocument.description,
            }));

        }

    }, [assignedDocument]);

    // Calculate the default assetNumber when the component mounts or when assets change
    useEffect(() => {
        if (assets && assets.length > 0) {
            // Find the maximum assetNumber in the assets array
            const maxAssetNumber = Math.max(...assets.map(asset => asset.assetNumber || 0));
            // Set the default assetNumber to maxAssetNumber + 1
            setNewAsset(prev => ({ ...prev, assetNumber: maxAssetNumber + 1 }));
        } else {
            // If there are no assets, start with 1
            setNewAsset(prev => ({ ...prev, assetNumber: 1 }));
        }
    }, [assets]);

    // Validation function to check if required fields are filled
    const validateFields = () => {
        const { name, originalPrice, purchaseDate, depreciationPercentage } = newAsset;
        return (
            name?.trim() !== '' &&
            originalPrice?.toString().trim() !== '' &&
            purchaseDate !== null &&
            depreciationPercentage?.toString().trim() !== ''
        );
    };


    // Update validation state whenever newAsset changes
    useEffect(() => {
        setIsSaveDisabled(!validateFields());
    }, [newAsset]);

    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        },
    };

    const inputStyle = {
        style: {
            textAlign: direction === 'rtl' ? 'right' : 'left',
            direction: direction === 'rtl' ? 'rtl' : 'ltr',
        },
    };




    const handleChange = (field, value) => {
        if (!value) {
            return;
        }
        if (field === 'assetGroup') {
            
            


            const assetGId = value?.assetGroupId;
            setNewAsset(prev => ({ ...prev, ['depreciationPercentage']: value.depreciationPercentage }));
            setNewAsset(prev => ({ ...prev, ['assetGroupId']: assetGId }));
        } else {
            setNewAsset(prev => ({ ...prev, [field]: value }));
        }
    };

    if (!open) return null;

    const titleStyle = {
        textAlign: 'right',
        width: '100%',
    };

    return (
        <Draggable handle=".handle" nodeRef={nodeRef}>
            <StyledContainer ref={nodeRef}>
                <div
                    className="handle"
                    style={{
                        cursor: 'move',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        marginBottom: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Tooltip title={t('Exit')}>
                        <IconButton onClick={onClose}>
                            <CancelIcon style={{ color: '#304FFF' }} />
                        </IconButton>
                    </Tooltip>

                    <div className="edit-title">{t('AddAsset')}</div>
                    <div />

                </div>

                {/* Row 1 */}
                <div className="form-row-2">
                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            <span className="required-asterisk">*</span>
                            {t('AssetName')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('AssetName')}
                            value={newAsset.name}
                            onChange={(e) => handleChange('name', e.target.value)}
                            variant="standard"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                        />
                    </div>

                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            <span className="required-asterisk">*</span>
                            {t('OriginalPrice')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('OriginalPrice')}
                            type="number"
                            value={newAsset.originalPrice}
                            onChange={(e) => handleChange('originalPrice', e.target.value)}
                            variant="standard"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                        />
                    </div>
                </div>

                {/* Row 2 */}
                <div className="form-row-2">
                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            {t('DepreciationPercentage')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('DepreciationPercentage')}
                            type="number"
                            value={newAsset.depreciationPercentage}
                            onChange={(e) => handleChange('depreciationPercentage', e.target.value)}
                            variant="standard"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                        />
                    </div>

                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            <span className="required-asterisk">*</span>
                            {t('PurchaseDate')}
                        </div>
                        <CustomDatePicker
                            date={newAsset.purchaseDate}
                            onDateChange={(date) => handleChange('purchaseDate', date.toISOString())}
                            height="48px"
                        />
                    </div>
                </div>

                <div className="form-row-2">
                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            {t('SerialNumber')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('SerialNumber')}
                            value={newAsset.assetNumber} // Use assetNumber from state
                            onChange={(e) => handleChange('assetNumber', e.target.value)}
                            variant="standard"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                        />
                    </div>

                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            <span className="required-asterisk">*</span>
                            {t('ActivationDate')}
                        </div>
                        <CustomDatePicker
                            date={newAsset.activationDate}
                            onDateChange={(date) => handleChange('activationDate', date.toISOString())}
                            height="48px"
                        />
                    </div>
                </div>

                {/* row 4 */}
                <div className="form-row-2">
                    <div className="edit-form-row-item">

                        {/* Default state: Attach Document */}
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            {t('ChooseAssetIfAdditionToAsset')}
                        </div>
                        <Autocomplete
                            options={assets}
                            getOptionLabel={(option) => `${option.name} - ${option.assetNumber}`}
                            value={assets.find((option) => option.assetNumber === newAsset.assetNumber) || null}
                            onChange={(event, newValue) => {
                                if (!newValue) {
                                    setNewAsset(prev => ({ ...prev, assetNumber: '' }));
                                } else {
                                    handleChange('assetNumber', newValue.assetNumber);
                                }
                            }}

                            noOptionsText={
                                <strong>{t('NoOptions')}</strong>
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={t('Select Asset Group')}
                                    variant="standard"
                                    InputProps={{
                                        ...params.InputProps,
                                        ...inputPropsStyle,
                                    }}
                                />
                            )}
                            renderOption={(props, option) => (

                                <StyledListItem {...props}>
                                    {`${option.name} - ${option.assetNumber}`}
                                </StyledListItem>

                            )}
                            PopperComponent={(props) => (
                                <StyledPopper
                                    {...props}
                                    style={{
                                        ...props.style,
                                        direction: direction,
                                    }}
                                />
                            )}
                        />
                    </div>

                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            {t('AssetGroup')}
                        </div>
                        <Autocomplete
                            options={assetGroups}
                            getOptionLabel={(option) => `${option.assetGroupId} - ${option.name}`}
                            value={assetGroups.find((option) => option.assetGroupId === newAsset.assetGroupId) || null}
                            onChange={(event, newValue) => {
                                if (!newValue) {
                                    // When clearing the selection, reset assetGroupId
                                    setNewAsset(prev => ({ ...prev, assetGroupId: '', depreciationPercentage: '' }));
                                } else {
                                    handleChange('assetGroup', newValue);
                                }
                            }}

                            noOptionsText={
                                <strong>{t('NoOptions')}</strong>
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={t('Select Asset Group')}
                                    variant="standard"
                                    InputProps={{
                                        ...params.InputProps,
                                        ...inputPropsStyle,
                                    }}
                                />
                            )}
                            renderOption={(props, option) => (
                                <Tooltip
                                    title={t('RightClickToEdit')}
                                    placement="top"
                                >
                                    <StyledListItem {...props}>
                                        {`${option.assetGroupId} - ${option.name}`}
                                    </StyledListItem>
                                </Tooltip>
                            )}
                            PopperComponent={(props) => (
                                <StyledPopper
                                    {...props}
                                    style={{
                                        ...props.style,
                                        direction: direction,
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
                {/* row 5 */}
                <div className="form-row-2">
                    <div className="edit-form-row-item">
                        {newAsset.documentItSelf ? (
                            <>
                                {/* Show document type and amount instead of "Attach Document" label */}
                                <div className="edit-form-row-item-title" style={titleStyle}>
                                    {`${newAsset.documentItSelf.type === 1 ? t('Expense') : t('JournalEntry')} - ${newAsset.documentItSelf.amount}₪`}
                                </div>

                                {/* Button: Edit Attached Document */}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        height: '30px',  // Make the button smaller
                                        width: '90%',  // Reduce button width
                                        background: '#304FFF',
                                        color: '#FFF',
                                        borderRadius: '8px',
                                        textTransform: 'none',
                                        fontSize: '12px', // Smaller font
                                    }}
                                    onClick={() => viewAttachDocument()}
                                >
                                    {t('EditAttachedDocument')}
                                </Button>
                            </>
                        ) : (
                            <>
                                {/* Default state: Attach Document */}
                                <div className="edit-form-row-item-title" style={titleStyle}>
                                    {t('AttachDocument')}
                                </div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        height: '35px',
                                        width: '90%',
                                        background: '#304FFF',
                                        color: '#FFF',
                                        borderRadius: '8px',
                                        textTransform: 'none',
                                    }}
                                    onClick={() => viewAttachDocument()}
                                >
                                    {t('AttachDocument')}
                                </Button>
                            </>
                        )}
                    </div>

                    <div className="edit-form-row-item">

                    </div>
                </div>

                {/* Actions */}
                <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '16px', gap: '10px' }}>
                    <div onClick={onClose} className="edit-cancel-button">
                        {t('Cancel')}
                    </div>
                    <div
                        onClick={() => handleSave(newAsset)}
                        className="edit-save-button"
                        style={{
                            opacity: isSaveDisabled ? 0.5 : 1, // Gray out the button when disabled
                            pointerEvents: isSaveDisabled ? 'none' : 'auto', // Disable pointer events when disabled
                            cursor: isSaveDisabled ? 'not-allowed' : 'pointer', // Change cursor when disabled
                        }}
                    >
                        {t('Save')}
                    </div>
                </div>
            </StyledContainer>
        </Draggable>
    );
};

export default AddAsset;