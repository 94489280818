import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    IconButton,
    Autocomplete,
    Popper,
    Button,
    DialogActions,
    DialogContentText,
    Tooltip,
} from '@mui/material';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import EnumsService from '../../../Services/enums.service';
import { toast } from 'react-toastify';
import UserService from '../../../Services/user.service';
import { validateEmail, validatePhoneNumber } from '../../../Utils/helpers';
import { isValidIDOrCompanyID } from '../../../Utils/validationHelpers';
import Constants from '../../../assests/Constants/constants';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <div {...props} />
        </Draggable>
    );
}

const AddAccountCardModal = ({
    open,
    onClose,
    editedItem,
    handleEditAddAccountChange,
    isRange,
    isClosedReportingMonth,
    palCodes,
    propPalCode,
    direction,
    isAdd,
    client,
    token,
    setLoading,
    accountCardOptions,
    handleChangeAfterAdd,
    fetchEnums,
    type = 'main',
    isEdit,
    TrialBalanceSum = 0,
    // New option arrays for extra fields
    deductionTypeOptions = [],
    exactOccupationOptions = [],
    accountCardsOptions = null
}) => {
    const { t } = useTranslation();

    // --------------------------------------
    // Source deduction options
    // --------------------------------------
    const [sourceDeductionOptions] = useState([
        { key: 0, label: t('NotIncluded') },
        { key: 1, label: t('Included') }
    ]);

    // --------------------------------------
    // Basic data from editedItem
    // --------------------------------------
    const [accountCaseId, setAccountCaseId] = useState('');
    const [accountCodeName, setAccountCodeName] = useState('');
    const [oldCardId, setOldCardId] = useState('');
    const [oldName, setOldName] = useState('');
    const [oldAccountCaseId, setOldAccountCaseId] = useState('');
    const [accountCodeId, setAccountCodeId] = useState('');
    const [palCode, setPalCode] = useState('');

    // --------------------------------------
    // Duplicate detection
    // --------------------------------------
    const [isDuplicate, setIsDuplicate] = useState(false);
    const [duplicateCard, setDuplicateCard] = useState(null);

    useEffect(() => {
        if (!accountCodeId) {
            setIsDuplicate(false);
            setDuplicateCard(null);
            return;
        }

        const foundDuplicate = accountCardOptions.find(
            (card) => card.cardId === accountCodeId && card.cardId !== oldCardId
        );

        if (foundDuplicate) {
            setIsDuplicate(true);
            setDuplicateCard(foundDuplicate);
        } else {
            setIsDuplicate(false);
            setDuplicateCard(null);
        }
    }, [accountCodeId, accountCardOptions, oldCardId]);

    // --------------------------------------
    // Extra fields
    // --------------------------------------
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [sourceDeductionAssociation, setSourceDeductionAssociation] = useState('');
    const [exactOccupation, setExactOccupation] = useState('');
    const [deductionType, setDeductionType] = useState('');
    const [taxAssessor, setTaxAssessor] = useState('');
    const [deductionValidity, setDeductionValidity] = useState('');

    // --------------------------------------
    // Modal to handle existing accountCaseId
    // --------------------------------------
    const [showAccountCaseIdExistsModal, setShowAccountCaseIdExistsModal] = useState(false);

    // --------------------------------------
    // Toggle save button disabling
    // --------------------------------------
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    // --------------------------------------
    // Fetch full account card
    // --------------------------------------
    const fetchCard = async (caseId, cardId, token) => {
        try {
            
            const cardResponse = await EnumsService.GetAccountCardByCardId(caseId, cardId, token);

            if (cardResponse.ok) {
                const accountCard = await cardResponse.json();
                console.log('Fetched full account card:', accountCard);

                // Update local states with the extra fields (or fallback to '')
                setEmail(accountCard?.email || '');
                setLocation(accountCard?.location || '');
                setPhoneNumber(accountCard?.phoneNumber || '');
                setSourceDeductionAssociation(
                    accountCard?.sourceDeductionAssociation !== undefined
                        ? accountCard.sourceDeductionAssociation
                        : ''
                );
                setExactOccupation(accountCard?.exactOccupation || '');
                setDeductionType(accountCard?.deductionType || '');
                setTaxAssessor(accountCard?.taxAssessor || '');
                setDeductionValidity(accountCard?.deductionValidity || '');
            } else {
                console.warn('Failed to fetch account card.');
            }
        } catch (err) {
            console.error('Error while fetching account card:', err);
        }
    };

    // --------------------------------------
    // Effect: fill fields when open/edit
    // --------------------------------------
    useEffect(() => {
        if (editedItem) {
            console.log(editedItem);

            if (isEdit) {
                setOldCardId(editedItem.accountCodeId || editedItem.cardId || '');
                setOldName(editedItem.accountCodeName || editedItem.name || '');
                setOldAccountCaseId(editedItem.accountCaseId || '');
            }

            // Basic fields
            setAccountCaseId(editedItem.accountCaseId || '');
            setAccountCodeName(editedItem.accountCodeName || editedItem.name || '');
            setAccountCodeId(editedItem.accountCodeId || editedItem.cardId || '');
            setPalCode(propPalCode);
        }
    }, [editedItem, isEdit, propPalCode]);

    // --------------------------------------
    // Effect: if modal is open + isEdit + oldCardId -> fetch the card
    // --------------------------------------
    useEffect(() => {
        if (open && isEdit && oldCardId) {
            // You might already have client and token – make sure you have them
            fetchCard(client.caseId, oldCardId, token);
        }

        // If NOT edit (i.e. an Add flow) and the modal just opened, you might want to reset
        // the extra fields to empty, e.g.:
        if (open && !isEdit) {
            setEmail('');
            setLocation('');
            setPhoneNumber('');
            setSourceDeductionAssociation('');
            setExactOccupation('');
            setDeductionType('');
            setTaxAssessor('');
            setDeductionValidity('');
        }
    }, [open, isEdit, oldCardId, client.caseId, token]);

    // --------------------------------------
    // Validate required fields
    // --------------------------------------
    useEffect(() => {
        const isEmailValid = validateEmail(email);
        const isPhoneValid = validatePhoneNumber(phoneNumber);
        const isAccountCaseIdValid = isValidIDOrCompanyID(accountCaseId);

        const requiredFieldsFilled =
            accountCodeId?.toString().trim() !== '' &&
            accountCodeName?.trim() !== '' &&
            !isDuplicate &&
            // If you want to enforce valid email and phone at all times:
            isEmailValid &&
            isPhoneValid &&
            isAccountCaseIdValid;

        setIsSaveDisabled(!requiredFieldsFilled);
    }, [accountCodeId, accountCodeName, isDuplicate, email, phoneNumber, accountCaseId]);

    // --------------------------------------
    // Common input styles
    // --------------------------------------
    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        },
    };

    const inputStyle = {
        style: {
            textAlign: direction === 'ltr' ? 'left' : 'right',
        },
    };

    // --------------------------------------
    // PalCode options
    // --------------------------------------
    const formattedPalCodeOptions = palCodes?.map((option) => ({
        value: option.value,
        label: option.label,
    }));

    // --------------------------------------
    // Check for existing account card
    // --------------------------------------
    const checkIfExistingAccountCard = (
        accountCardOptions,
        cardId,
        accountCaseId,
        edit = false
    ) => {
        let existingAccountCardByCaseId = [];
        let existingAccountCardByCardId = [];

        if (accountCaseId) {
            existingAccountCardByCaseId = accountCardOptions.filter(
                (card) => card.accountCaseId === accountCaseId
            );
        }
        if (cardId) {
            existingAccountCardByCardId = accountCardOptions.filter(
                (card) => card.cardId === cardId
            );
        }

        const isAccountCaseIdExist =
            existingAccountCardByCaseId &&
            (edit
                ? existingAccountCardByCaseId.length > 1
                : existingAccountCardByCaseId.length > 0);

        const isCardIdExist =
            existingAccountCardByCardId &&
            (edit
                ? existingAccountCardByCardId.length > 1
                : existingAccountCardByCardId.length > 0);

        if (isAccountCaseIdExist) {
            setShowAccountCaseIdExistsModal(true);
            return true;
        }
        if (isCardIdExist) {
            toast.info(t('AccountCardExists', { accountCodeId: cardId }));
            return true;
        }
        return false;
    };

    // --------------------------------------
    // Proceed with "Add" after user decides
    // --------------------------------------
    const handleAddProceed = async () => {
        try {
            setLoading(true);
            const response = await EnumsService.AddAccountCard(
                client.caseId,
                accountCodeId.toString(),
                accountCodeName,
                accountCaseId,
                palCode,
                token,
                phoneNumber || null,
                location || null,
                email || null,
                sourceDeductionAssociation || null,
                true,
                exactOccupation || null,
                deductionType || null,
                taxAssessor || null,
                deductionValidity || null
            );
            if (response.ok) {
                const currentUser = await UserService.getCurrentUser();
                await fetchEnums(currentUser, token, client);
                handleChangeAfterAdd(accountCodeId.toString(), type);
                toast.success(t('CustomerSupplierAddedSuccessfully'));
            } else {
                const errorText = await response.text();
                console.error('Failed to add account card', errorText);
                toast.error(t('FailedToAddCustomerSupplier'));
            }
        } catch (error) {
            console.error('Error adding account card:', error);
            toast.error(t('FailedToAddCustomerSupplier'));
        }
        setLoading(false);
        onClose();
    };

    // --------------------------------------
    // "Add" handler
    // --------------------------------------
    const handleAdd = async () => {
        // Even though we disable the button, final safety check:
        if (!accountCodeId || !accountCodeName) {
            toast.error(t('NotAllRequiredFieldsAreFilled'));
            return;
        }

        if (checkIfExistingAccountCard(accountCardOptions, accountCodeId, accountCaseId)) {
            return;
        }
        await handleAddProceed();
    };

    // --------------------------------------
    // "Save" handler (edit)
    // --------------------------------------
    const handleSave = async () => {
        let isValid = true;

        // (1) Check required fields
        if (!accountCodeId || !accountCodeName) {
            toast.error(t('NotAllRequiredFieldsAreFilled'));
            isValid = false;
        }

        // (2) Validate email only if filled
        if (email && !validateEmail(email)) {
            toast.error(t('InvalidEmailAddress'));
            isValid = false;
        }

        // (3) Validate phone number only if filled
        if (phoneNumber && !validatePhoneNumber(phoneNumber)) {
            toast.error(t('InvalidPhoneNumber'));
            isValid = false;
        }

        // (4) Validate ID only if filled
        if (accountCaseId && !isValidIDOrCompanyID(accountCaseId)) {
            toast.error(t('InvalidCompanyOrIDNumber'));
            isValid = false;
        }

        if (isValid) {
            try {
                // We now use UpdateAccountCard for the full update
                const response = await EnumsService.UpdateAccountCard(
                    client.caseId,
                    oldCardId,
                    oldName,
                    accountCodeId,
                    accountCodeName,
                    accountCaseId,
                    palCode,
                    token,
                    phoneNumber || null,
                    location || null,
                    email || null,
                    sourceDeductionAssociation,
                    oldAccountCaseId || null,
                    true,
                    exactOccupation || null,
                    deductionType || null,
                    taxAssessor || null,
                    deductionValidity || null
                );

                if (response.ok) {
                    const currentUser = await UserService.getCurrentUser();
                    await fetchEnums(currentUser, token, client);
                    handleChangeAfterAdd(accountCodeId.toString(), type);
                    toast.success(t('CustomerSupplierUpdatedSuccessfully'));
                } else {
                    const errorText = await response.text();
                    console.error('Failed to update account card', errorText);
                    toast.error(t('FailedToUpdateCustomerSupplier'));
                }
            } catch (error) {
                console.error('Error updating account card:', error);
                toast.error(t('FailedToUpdateCustomerSupplier'));
            }
            setLoading(false);
            onClose();
        }
    };

    // --------------------------------------
    // JSX
    // --------------------------------------
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            maxWidth="20px"
        >
            <div className={`${direction}`} style={{ backgroundColor: 'white' }}>
                <DialogTitle
                    style={{ cursor: 'move', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    id="draggable-dialog-title"
                >
                    {!isEdit ? t('AddAccountCard') : t('EditAccountCard')}
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <div className={`form-container ${direction}`}>
                        {TrialBalanceSum ? (
                            <div className="edit-form-row-item-title" style={{ margin: '16px' }}>
                                <strong>{t('TotalAmount')}: </strong>
                                {TrialBalanceSum}
                            </div>
                        ) : null}

                        {/* Row 1: Card ID and Card Name */}
                        <div className="form-row">
                            {/* Card ID (required) */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div
                                    className="edit-form-row-item-title"
                                    style={{
                                        color: isDuplicate ? 'red' : 'inherit',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '100%',
                                        display: 'block',
                                    }}
                                >
                                    <Tooltip
                                        title={`${t('Card ID')} ${
                                            isDuplicate ? ` - ${t('DuplicateOf')}: ${duplicateCard?.name}` : ''
                                        }`}
                                        placement="top"
                                        arrow
                                    >
                                        {t('Card ID')}{' '}
                                        {isDuplicate && ` - ${t('DuplicateOf')}: ${duplicateCard?.name}`}
                                    </Tooltip>
                                    <span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('Card ID')}
                                    value={accountCodeId}
                                    InputProps={{
                                        ...inputPropsStyle,
                                        sx: {
                                            ...inputPropsStyle.sx,
                                            border: isDuplicate
                                                ? '4px solid red !important'
                                                : '1px solid transparent',
                                            '&:focus-within': {
                                                border: isDuplicate
                                                    ? '4px solid red !important'
                                                    : '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    onChange={(e) => setAccountCodeId(e.target.value)}
                                    variant="standard"
                                    inputProps={inputStyle}
                                    required
                                    type="number"
                                />
                            </div>

                            {/* Card Name (required) */}
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div className="edit-form-row-item-title">
                                    {t('Card Name')}
                                    <span className="required-asterisk">*</span>
                                </div>
                                <TextField
                                    fullWidth
                                    placeholder={t('Card Name')}
                                    value={accountCodeName}
                                    onChange={(e) => setAccountCodeName(e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                    required
                                />
                            </div>
                        </div>

                        {/* Row 2: PalCode and Source Deduction Association */}
                        <div className="form-row">
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div className="edit-form-row-item-title">{t('PalCode')}</div>
                                <Autocomplete
                                    options={formattedPalCodeOptions}
                                    getOptionLabel={(option) => option.label}
                                    value={
                                        formattedPalCodeOptions.find((option) => option.value === palCode) ||
                                        null
                                    }
                                    onChange={(event, newValue) => setPalCode(newValue ? newValue.value : '')}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('PalCode')}
                                            variant="standard"
                                            InputProps={{ ...params.InputProps, ...inputPropsStyle }}
                                            inputProps={{ ...params.inputProps, ...inputStyle }}
                                        />
                                    )}
                                    PopperComponent={(props) => (
                                        <Popper {...props} style={{ ...props.style, direction }} />
                                    )}
                                />
                            </div>
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div className="edit-form-row-item-title">
                                    {t('SourceDeductionAssociation')}
                                </div>
                                <Autocomplete
                                    options={sourceDeductionOptions}
                                    getOptionLabel={(option) => option.label}
                                    value={
                                        sourceDeductionOptions.find(
                                            (option) => option.key === sourceDeductionAssociation
                                        ) || null
                                    }
                                    onChange={(event, newValue) => {
                                        setSourceDeductionAssociation(newValue ? newValue.key : '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('SourceDeductionAssociation')}
                                            variant="standard"
                                            InputProps={{ ...params.InputProps, ...inputPropsStyle }}
                                            inputProps={{ ...params.inputProps, ...inputStyle }}
                                        />
                                    )}
                                    PopperComponent={(props) => (
                                        <Popper {...props} style={{ ...props.style, direction }} />
                                    )}
                                />
                            </div>
                        </div>

                        {/* Row 3: Email and Location */}
                        <div className="form-row">
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div className="edit-form-row-item-title">{t('Email')}</div>
                                <TextField
                                    fullWidth
                                    placeholder={t('Email')}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                    type="email"
                                />
                            </div>
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div className="edit-form-row-item-title">{t('Location')}</div>
                                <TextField
                                    fullWidth
                                    placeholder={t('Location')}
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                />
                            </div>
                        </div>

                        {/* Row 4: Phone Number and Company ID */}
                        <div className="form-row">
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div className="edit-form-row-item-title">{t('PhoneNumber')}</div>
                                <TextField
                                    fullWidth
                                    placeholder={t('PhoneNumber')}
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                    type="number"
                                />
                            </div>
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div className="edit-form-row-item-title">{t('CompanyId')}</div>
                                <TextField
                                    fullWidth
                                    placeholder={t('CompanyId')}
                                    value={accountCaseId}
                                    onChange={(e) => setAccountCaseId(e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                />
                            </div>
                        </div>

                        {/* Row 5: Exact Occupation and Deduction Type */}
                        <div className="form-row">
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div className="edit-form-row-item-title">{t('ExactOccupation')}</div>
                                <TextField
                                    fullWidth
                                    placeholder={t('ExactOccupation')}
                                    value={exactOccupation}
                                    onChange={(e) => setExactOccupation(e.target.value)}
                                    variant="standard"
                                    InputProps={inputPropsStyle}
                                    inputProps={inputStyle}
                                />
                            </div>
                            <div className="edit-form-row-item" style={{ flex: 1 }}>
                                <div className="edit-form-row-item-title">{t('DeductionType')}</div>
                                <Autocomplete
                                    options={deductionTypeOptions}
                                    getOptionLabel={(option) => option.label}
                                    value={
                                        deductionTypeOptions.find((option) => option.value === deductionType) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        setDeductionType(newValue ? newValue.value : '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('DeductionType')}
                                            variant="standard"
                                            InputProps={{ ...params.InputProps, ...inputPropsStyle }}
                                            inputProps={{ ...params.inputProps, ...inputStyle }}
                                        />
                                    )}
                                    PopperComponent={(props) => (
                                        <Popper {...props} style={{ ...props.style, direction }} />
                                    )}
                                />
                            </div>
                        </div>

                        {/* Row 6: Tax Assessor and Deduction Validity */}
                        <div className="form-row">
                            {/* Tax Assessor */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">{t('TaxAssessor')}</div>
                                <Autocomplete
                                    options={Constants.TaxAssessorOptionsString}
                                    getOptionLabel={(option) => `${option.value} - ${option.label}`}
                                    value={
                                        Constants.TaxAssessorOptionsString.find(
                                            (option) => option.value === taxAssessor
                                        ) || null
                                    }
                                    onChange={(event, newValue) => {
                                        setTaxAssessor(newValue ? newValue.value : null);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            InputProps={{
                                                ...params.InputProps,
                                                ...inputPropsStyle,
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                ...inputStyle,
                                            }}
                                        />
                                    )}
                                    PopperComponent={(props) => (
                                        <Popper
                                            {...props}
                                            style={{
                                                ...props.style,
                                                direction: direction,
                                            }}
                                        />
                                    )}
                                />
                            </div>

                            {/* Deduction Validity Date */}
                            <div className="edit-form-row-item">
                                <div className="edit-form-row-item-title">{t('DeductionValidity')}</div>
                                <CustomDatePicker
                                    date={deductionValidity ? new Date(deductionValidity) : null}
                                    onDateChange={(date) => {
                                        setDeductionValidity(date ? date.toISOString() : null);
                                    }}
                                    height="48px"
                                />
                            </div>
                        </div>

                        {/* Buttons */}
                        <div className="button-container">
                            <div onClick={onClose} className="edit-cancel-button">
                                {t('Cancel')}
                            </div>

                            {/* "Save" button with disabled/gray logic */}
                            <div
                                onClick={!isSaveDisabled ? (isEdit ? handleSave : handleAdd) : undefined}
                                className="edit-save-button"
                                style={{
                                    opacity: isSaveDisabled ? 0.5 : 1,
                                    pointerEvents: isSaveDisabled ? 'none' : 'auto',
                                    cursor: isSaveDisabled ? 'not-allowed' : 'pointer',
                                }}
                            >
                                {t('Save')}
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </div>

            {/* Dialog for existing accountCaseId confirmation */}
            {showAccountCaseIdExistsModal && (
                <Dialog
                    open={showAccountCaseIdExistsModal}
                    onClose={() => {
                        setShowAccountCaseIdExistsModal(false);
                    }}
                    aria-labelledby="account-case-id-exists-dialog-title"
                >
                    <div className={`${direction}`} style={{ backgroundColor: 'white' }}>
                        <DialogTitle id="account-case-id-exists-dialog-title">
                            {t('AccountIdAlreadyExists')}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>{t('ConfirmOpenAccountCard')}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setShowAccountCaseIdExistsModal(false);
                                }}
                                color="primary"
                            >
                                {t('No')}
                            </Button>
                            <Button
                                onClick={() => {
                                    setShowAccountCaseIdExistsModal(false);
                                    handleAddProceed();
                                }}
                                color="primary"
                            >
                                {t('Yes')}
                            </Button>
                        </DialogActions>
                    </div>
                </Dialog>
            )}
        </Dialog>
    );
};

export default AddAccountCardModal;
