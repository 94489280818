import config from "../config";
import selectedEnvironment from "../env";

const API_URL = `${config[selectedEnvironment].API_URL}`;

const handleResponse = async (response, errorMessage) => {
    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
    }
    return response.json();
};

const GetAllAssets = async (caseId, authToken) => {
    try {
        const response = await fetch(`${API_URL}/Assets/GetAssetsByCaseId/${encodeURIComponent(caseId)}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        return await handleResponse(response, "Failed to fetch assets");
    } catch (error) {
        console.error("Error in GetAllAssets:", error);
        throw error;
    }
};

const GetAssetById = async (caseId, assetId, authToken) => {
    try {
        const response = await fetch(`${API_URL}/Assets/GetAssetById`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ CaseId: caseId, AssetId: assetId }),
        });

        return await handleResponse(response, "Failed to fetch asset by ID");
    } catch (error) {
        console.error("Error in GetAssetById:", error);
        throw error;
    }
};

const GetAssetByNumber = async (caseId, assetNumber, authToken) => {
    try {
        const response = await fetch(`${API_URL}/Assets/GetAssetsByNumber`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ CaseId: caseId, AssetNumber: assetNumber }),
        });

        return await handleResponse(response, "Failed to fetch asset by number");
    } catch (error) {
        console.error("Error in GetAssetByNumber:", error);
        throw error;
    }
};

const CreateAsset = async (formData, authToken) => {
    try {
        const response = await fetch(`${API_URL}/Assets/CreateAsset`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        });

        return await handleResponse(response, "Failed to create asset");
    } catch (error) {
        console.error("Error in CreateAsset:", error);
        throw error;
    }
};

const UpdateAsset = async (formData, authToken) => {
    try {
        const response = await fetch(`${API_URL}/Assets/UpdateAsset`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        });

        return await handleResponse(response, "Failed to update asset");
    } catch (error) {
        console.error("Error in UpdateAsset:", error);
        throw error;
    }
};

const DeleteAsset = async (caseId, assetId, authToken) => {
    try {
        const response = await fetch(`${API_URL}/Assets/DeleteAsset`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ CaseId: caseId, AssetId: assetId }),
        });

        return await handleResponse(response, "Failed to delete asset");
    } catch (error) {
        console.error("Error in DeleteAsset:", error);
        throw error;
    }
};

// ---------------------------
//          ASSET GROUPS
// ---------------------------

const GetAllAssetGroups = async (caseId, authToken) => {
    try {
        const response = await fetch(`${API_URL}/Assets/GetAssetGroupsByCaseId/${encodeURIComponent(caseId)}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        return await handleResponse(response, "Failed to fetch asset groups");
    } catch (error) {
        console.error("Error in GetAllAssetGroups:", error);
        throw error;
    }
};

const GetAssetGroupById = async (caseId, assetGroupId, authToken) => {
    try {
        const response = await fetch(`${API_URL}/Assets/GetAssetGroupById`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ CaseId: caseId, AssetGroupId: assetGroupId }),
        });

        return await handleResponse(response, "Failed to fetch asset group by ID");
    } catch (error) {
        console.error("Error in GetAssetGroupById:", error);
        throw error;
    }
};

const CreateAssetGroup = async (formData, authToken) => {
    try {
        const response = await fetch(`${API_URL}/Assets/CreateAssetGroup`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        });

        return await handleResponse(response, "Failed to create asset group");
    } catch (error) {
        console.error("Error in CreateAssetGroup:", error);
        throw error;
    }
};

const UpdateAssetGroup = async (formData, authToken) => {
    try {
        const response = await fetch(`${API_URL}/Assets/UpdateAssetGroup`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        });

        return await handleResponse(response, "Failed to update asset group");
    } catch (error) {
        console.error("Error in UpdateAssetGroup:", error);
        throw error;
    }
};

const DeleteAssetGroup = async (caseId, assetGroupId, authToken) => {
    try {
        const response = await fetch(`${API_URL}/Assets/DeleteAssetGroup`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ CaseId: caseId, AssetGroupId: assetGroupId }),
        });

        return await handleResponse(response, "Failed to delete asset group");
    } catch (error) {
        console.error("Error in DeleteAssetGroup:", error);
        throw error;
    }
};

const GetDepreciationDetails = async (caseId, date, authToken) => {
    try {
        const response = await fetch(`${API_URL}/Assets/DepreciationDetails`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                CaseId: caseId,
                Date: new Date(date).toISOString().split("T")[0] // Ensuring correct date format
            }),
        });

        return await handleResponse(response, "Failed to fetch depreciation details");
    } catch (error) {
        console.error("Error in GetDepreciationDetails:", error);
        throw error;
    }
};

const AddDocumentToAsset = async (caseId, assetId, type, documentId, authToken) => {
    try {
        const response = await fetch(`${API_URL}/Assets/AddDocumentToAsset`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                CaseId: caseId,
                AssetId: assetId,
                Type: type,  // "Expense", "Income", or "JournalEntry"
                DocumentId: documentId
            }),
        });

        return await handleResponse(response, "Failed to add document to asset");
    } catch (error) {
        console.error("Error in AddDocumentToAsset:", error);
        throw error;
    }
};

const SetEndDate = async (caseId, assetId, sellDate, authToken) => {
    try {
        const response = await fetch(`${API_URL}/Assets/SetEndDate`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                CaseId: caseId,
                AssetId: assetId,
                SellDate: sellDate
            }),
        });

        return await handleResponse(response, "Failed to set end date for asset");
    } catch (error) {
        console.error("Error in SetEndDate:", error);
        throw error;
    }
};

const GetJournalEntriesAndExpensesWithVatEquipment = async (caseId, accountantId, startDate, endDate, authToken) => {
    try {
        const response = await fetch(`${API_URL}/Assets/GetJournalEntriesAndExpensesWithVatEquipment`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ CaseId: caseId, AccountantId: accountantId,StartDate: startDate, EndDate: endDate }),
        });

        return await handleResponse(response, "Failed to fetch journal entries and expenses with VatEquipment");
    } catch (error) {
        console.error("Error in GetJournalEntriesAndExpensesWithVatEquipment:", error);
        throw error;
    }
};

// Export AssetService object
const AssetService = {
    GetAllAssets,
    GetAssetById,
    GetAssetByNumber,
    CreateAsset,
    UpdateAsset,
    DeleteAsset,
    GetAllAssetGroups,
    GetAssetGroupById,
    CreateAssetGroup,
    UpdateAssetGroup,
    DeleteAssetGroup,
    GetDepreciationDetails,
    AddDocumentToAsset,
    SetEndDate,
    GetJournalEntriesAndExpensesWithVatEquipment
};

export default AssetService;
