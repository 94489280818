import React, { useState, useEffect, useRef } from 'react';
import {
    TextField,
    Tooltip,
    IconButton,
} from '@mui/material';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import SaveIcon from '@mui/icons-material/SaveAsRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/system';
import AssetService from '../../../Services/assets.service';

const StyledContainer = styled('div')({
    position: 'absolute',
    top: '10%',
    left: '20%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '16px',
    minHeight: '40vh',
    maxHeight: '75vh',
    overflowY: 'auto',
    minWidth: '500px',
    zIndex: 1300,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
    border: '1px solid #304fff',
});

const AddAssetGroup = ({ open, onClose, caseId, refreshAssetGroups, assetGroups, token }) => {
    const { t, i18n } = useTranslation();
    const nodeRef = useRef(null);
    const [direction, setDirection] = useState('rtl');
    const [newAssetGroup, setNewAssetGroup] = useState({
        CaseId: caseId || "",
        assetGroupId: "",
        name: "",
        Description: "",
        depreciationPercentage: "",
    });

    const [isSaveDisabled, setIsSaveDisabled] = useState(true); // State to manage save button disabled state

    useEffect(() => {
        setDirection(i18n.language === 'he' || i18n.language === 'ar' ? 'rtl' : 'ltr');
    }, [i18n.language]);

    // Calculate the default assetGroupId when the component mounts or when assetGroups change
    useEffect(() => {

        if (assetGroups && assetGroups.length > 0) {
            // Find the maximum assetGroupId in the assetGroups array
            const maxAssetGroupId = Math.max(...assetGroups.map(group => group.assetGroupId || 0));
            // Set the default assetGroupId to maxAssetGroupId + 1
            setNewAssetGroup(prev => ({ ...prev, assetGroupId: maxAssetGroupId + 1 }));
        } else {
            // If there are no asset groups, start with 1
            setNewAssetGroup(prev => ({ ...prev, assetGroupId: 1 }));
        }
    }, [assetGroups]);

    // Validation function to check if required fields are filled
    const validateFields = () => {

        const { assetGroupId, name, depreciationPercentage } = newAssetGroup;
        return assetGroupId !== '' && name.trim() !== '' && depreciationPercentage.trim() !== '';
    };

    // Update validation state whenever newAssetGroup changes
    useEffect(() => {
        setIsSaveDisabled(!validateFields());
    }, [newAssetGroup]);

    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },
        },
    };

    const inputStyle = {
        style: {
            textAlign: direction === 'rtl' ? 'right' : 'left',
            direction: direction === 'rtl' ? 'rtl' : 'ltr',
        },
    };

    const handleChange = (field, value) => {
        setNewAssetGroup((prev) => ({ ...prev, [field]: value }));
    };

    const handleSave = async () => {
        if (!validateFields()) {
            toast.error(t('Please fill all required fields'));
            return;
        }

        const payload = {
            caseId: caseId?.toString(),
            assetGroupId: newAssetGroup.assetGroupId?.toString() || "",
            name: newAssetGroup.name,
            description: newAssetGroup.description || "",
            depreciationPercentage: parseFloat(newAssetGroup.depreciationPercentage),
        };

        
        

        try {
            const response = await AssetService.CreateAssetGroup(payload, token);
            if (response.success) {
                toast.success(t('AssetGroupAddedSuccessfully'));
                refreshAssetGroups();
                onClose();
            } else {
                toast.error(t('FailedToAddAssetGroup'));
            }
        } catch (error) {
            console.error(error);
            toast.error(t('AnErrorOccurredWhileAddingAssetGroup'));
        }
    };


    const titleStyle = {
        textAlign: 'right',
        width: '100%',
    };

    if (!open) return null;

    return (
        <Draggable handle=".handle" nodeRef={nodeRef}>
            <StyledContainer ref={nodeRef}>
                <div
                    className="handle"
                    style={{
                        cursor: 'move',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        marginBottom: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Tooltip title={t('Exit')}>
                        <IconButton onClick={onClose}>
                            <CancelIcon style={{ color: '#304FFF' }} />
                        </IconButton>
                    </Tooltip>

                    <div className="edit-title">{t('AddAssetGroup')}</div>

                    <div></div>
                </div>

                {/* Id */}
                <div className="form-row">
                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            <span className="required-asterisk">*</span>
                            {t('AssetGroupId')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('AssetGroupId')}
                            value={newAssetGroup.assetGroupId}
                            onChange={(e) => handleChange('AssetGroupId', e.target.value)}
                            type='number'
                            variant="standard"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                        />
                    </div>
                </div>

                {/* Name */}
                <div className="form-row">
                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            <span className="required-asterisk">*</span>
                            {t('AssetGroupName')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('AssetGroupName')}
                            value={newAssetGroup.name}
                            onChange={(e) => handleChange('name', e.target.value)}
                            variant="standard"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                        />
                    </div>
                </div>

                {/* Depreciation Percentage */}
                <div className="form-row">
                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            <span className="required-asterisk">*</span>
                            {t('DepreciationPercentage')}
                        </div>
                        <TextField
                            fullWidth
                            type="number"
                            placeholder={t('DepreciationPercentage')}
                            value={newAssetGroup.depreciationPercentage}
                            onChange={(e) => handleChange('depreciationPercentage', e.target.value)}
                            variant="standard"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                        />
                    </div>
                </div>

                {/* Description */}
                <div className="form-row">
                    <div className="edit-form-row-item">
                        <div className="edit-form-row-item-title" style={titleStyle}>
                            {t('Description')}
                        </div>
                        <TextField
                            fullWidth
                            placeholder={t('Description')}
                            value={newAssetGroup.Description}
                            onChange={(e) => handleChange('Description', e.target.value)}
                            variant="standard"
                            InputProps={inputPropsStyle}
                            inputProps={inputStyle}
                        />
                    </div>
                </div>

                {/* Actions */}
                <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '16px', gap: '10px' }}>
                    <div onClick={onClose} className="edit-cancel-button">
                        {t('Cancel')}
                    </div>
                    <div
                        onClick={handleSave}
                        className="edit-save-button"
                        style={{
                            opacity: isSaveDisabled ? 0.5 : 1, // Gray out the button when disabled
                            pointerEvents: isSaveDisabled ? 'none' : 'auto', // Disable pointer events when disabled
                            cursor: isSaveDisabled ? 'not-allowed' : 'pointer', // Change cursor when disabled
                        }}
                    >
                        {t('Save')}
                    </div>
                </div>
            </StyledContainer>
        </Draggable>
    );
};

export default AddAssetGroup;