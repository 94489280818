import config from '../config';
import selectedEnvironment from '../env';
const API_URL = `${config[selectedEnvironment].API_URL}/TaxAuthority`;
const requestTokenClient = async (caseId,auth) => {
    try {
        const response = await fetch(`${API_URL}/tokenRequest`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + auth,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ CaseId: caseId }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching token:', error);
        throw error;
    }
};

const checkAuthCompletionClient = async (caseId) => {
    try {
        const response = await fetch(`${API_URL}/checkAuthCompletion?caseId=${caseId}`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error checking authentication completion:', error);
        throw error;
    }
};

const requestTokenAccountant = async (accountantId,scope,auth) => {
    try {
        const response = await fetch(`${API_URL}/tokenRequestAccountant`, {
            method: 'POST',
            headers: {
                "Authorization": 'Bearer ' + auth,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ accountantId,scope }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching token:', error);
        throw error;
    }
};

const checkAuthCompletionAccountant = async (accountantId,scope,auth) => {
    try {
        const response = await fetch(`${API_URL}/checkAccountantAuthCompletion?accountantId=${accountantId}&scope=${scope}`,{
            headers: {
                "Authorization": 'Bearer ' + auth,
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error checking authentication completion:', error);
        throw error;
    }
};

const getTokenDaysRemaining = async (caseId, auth) => {
    try {
     
        
        const response = await fetch(`${API_URL}/tokenDaysRemaining/${caseId}`, {  // <-- FIXED URL
            method: 'GET',
            headers: {
                "Authorization": 'Bearer ' + auth,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json(); // Assuming the response contains `{ daysRemaining: number }`
    } catch (error) {
        console.error('Error fetching token days remaining:', error);
        throw error;
    }
};

const TaxAuthorityService = {
    requestTokenClient,
    checkAuthCompletionClient,
    requestTokenAccountant,
    checkAuthCompletionAccountant,
    getTokenDaysRemaining
};

export default TaxAuthorityService;
